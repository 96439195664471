import styled from 'styled-components/macro';

export const NavBarWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 2001;

    width: 100%;
    height: 48px;

    color: #ebeaf1;

    background-color: ${props => (props.prospectMode ? 'var(--backgroundColorSecondary)' : 'var(--uiHeaderColor)')};
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);

    .hockeyTech & {
        background-color: var(--blackColor);
    }
`;

export const Brand = styled.div`
    cursor: pointer;
`;
