/* Created by iceberg-root on 02.02.2017 */

/* ============ Утилиты для работы с Redux ============ */

/**
 * @param prefix префикс, который будет добавлять созданный PrefixAdder
 * @returns {function(prefix: string): string} PrefixAdder, добавляющий префикс к redux-action
 */
export function makePrefixAdder(prefix) {
    return action => `${prefix}/${action}`;
}
/**
 * @param action redux-actions асинхронного запроса
 * @returns бандл, содержащий redux-actions жизненного цикла асинхнронного запроса
 */

export function makeBundle(action) {
    return {
        REQUEST: action + '_REQUEST',
        SUCCESS: action + '_SUCCESS',
        FAILURE: action + '_FAILURE',
        CANCELLED: action + '_CANCELLED'
    };
}
/**
 * тип redux-actions, обозначающий внутреннюю ошибку
 */

export const INTERNAL_ERROR = 'app/INTERNAL_ERROR';
