import { FAVORITE_ZONE_CHANGE } from '../../favorite-types';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { entriesTeamsSelector } from '../../../../../containers/widgets-stats/selectors/entries';
import { createSelector } from 'reselect';

const selector = entriesTeamsSelector;

const CARRIES_IN = 'CARRY_IN';
const DUMPS_IN = 'DUMP_IN';
const CARRIES_OUT = 'CARRY_OUT';
const DUMPS_OUT = 'DUMP_OUT';
const PASS_IN = 'PASS_IN';
const PASS_OUT = 'PASS_OUT';
const CONTROLLED_IN = 'CONTROLLED_IN';
const UNCONTROLLED_IN = 'UNCONTROLLED_IN';
const CONTROLLED_OUT = 'CONTROLLED_OUT';
const UNCONTROLLED_OUT = 'UNCONTROLLED_OUT';

const types = {
    CARRIES_IN,
    DUMPS_IN,
    CARRIES_OUT,
    DUMPS_OUT,
    PASS_IN,
    PASS_OUT,
    CONTROLLED_IN,
    UNCONTROLLED_IN,
    CONTROLLED_OUT,
    UNCONTROLLED_OUT
};

const favoriteType = FAVORITE_ZONE_CHANGE;

export const createControlledInSelector = () =>
    createSelector(
        entries => entries.data,
        data => ({ data: data.filter(e => e.type === CARRIES_IN || e.type === PASS_IN) })
    );

export const createUncontrolledInSelector = () =>
    createSelector(
        entries => entries.data,
        data => ({ data: data.filter(e => e.type === DUMPS_IN) })
    );

export const createControlledOutSelector = () =>
    createSelector(
        entries => entries.data,
        data => ({ data: data.filter(e => e.type === CARRIES_OUT || e.type === PASS_OUT) })
    );

export const createUncontrolledOutSelector = () =>
    createSelector(
        entries => entries.data,
        data => ({ data: data.filter(e => e.type === DUMPS_OUT) })
    );

export const createEntryTypeSelector = type =>
    createSelector(
        entries => entries.data,
        data => ({ data: data.filter(e => e.type === type) })
    );

const typeToSelector = {
    [CONTROLLED_IN]: createEpisodeIdWrapper(CONTROLLED_IN, createControlledInSelector()),
    [UNCONTROLLED_IN]: createEpisodeIdWrapper(UNCONTROLLED_IN, createUncontrolledInSelector()),
    [CONTROLLED_OUT]: createEpisodeIdWrapper(CONTROLLED_OUT, createControlledOutSelector()),
    [UNCONTROLLED_OUT]: createEpisodeIdWrapper(UNCONTROLLED_OUT, createUncontrolledOutSelector()),
    [PASS_IN]: createEpisodeIdWrapper(PASS_IN, createEntryTypeSelector(PASS_IN)),
    [PASS_OUT]: createEpisodeIdWrapper(PASS_OUT, createEntryTypeSelector(PASS_OUT)),
    [DUMPS_IN]: createEpisodeIdWrapper(DUMPS_IN, createEntryTypeSelector(DUMPS_IN)),
    [DUMPS_OUT]: createEpisodeIdWrapper(DUMPS_OUT, createEntryTypeSelector(DUMPS_OUT)),
    [CARRIES_IN]: createEpisodeIdWrapper(CARRIES_IN, createEntryTypeSelector(CARRIES_IN)),
    [CARRIES_OUT]: createEpisodeIdWrapper(CARRIES_OUT, createEntryTypeSelector(CARRIES_OUT))
};

function mapper(entries, type) {
    if (entries.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Entries');
    return typeToSelector[type](entries);
}

const getTime = ({ endTime, time }) => ({
    startTime: time,
    endTime,
    time,
    hasPadding: true,
    leftPadding: 3,
    rightPadding: 3
});

const typeToMediaTimeWrapper = Object.keys(typeToSelector)
    .map(key => ({ key, wrapper: createMediaTimeWrapper(getTime) }))
    .reduce((a, d) => {
        a[d.key] = d.wrapper;
        return a;
    }, {});

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const entries = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default entries;
