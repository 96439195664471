import styled, { css } from 'styled-components/macro';
import Checkbox from '../../../components/checkbox/labeled-checkbox';
import InputDateTime from '../../../components/input/input-date-time';
import FilterSelect from '../../../components/filter-select/filter-select';
import ReactSelect from '../../../components/input/select';
import InputTime from '../../../components/input/input-time';

const marginCss = css`
    margin: 0 0 5px;
`;

export const StyledFilterSelect = styled(FilterSelect)`
    & .fs-panel {
        width: 100%;
    }

    & .fs-label {
        width: 100%;

        font-weight: normal;
    }
    ${marginCss}
`;

export const StyledReactSelect = styled(ReactSelect)`
    position: relative;

    width: ${props => props.width};
    height: 24px;

    border-radius: 4px;
    ${marginCss}
    & .rs__option {
        padding: 5px;

        font-size: 14px;
    }

    & .rs__control {
        min-height: unset;
        padding: 0 8px;

        cursor: pointer;

        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }

    & .rs__value-container {
        justify-content: center;
    }

    & .rs__value-container > div {
        margin: 0;
        padding: 0;

        color: rgba(0, 0, 0, 0.9);
        font-size: 0.813em;
    }

    .darcula & .rs__value-container > div {
        color: rgba(255, 255, 255, 0.7);

        background-color: #2d3d4d;
    }

    & .rs__control--is-focused {
        background-color: var(--backgroundColorSecondary);

        & .rs__value-container > div {
            color: var(--optionsPrimaryColor);
        }
    }

    & .rs__indicator-separator {
        display: none;
    }

    & .rs__dropdown-indicator > svg {
        display: none;
    }

    & .rs__menu {
        z-index: 2000;
    }
`;

const hiddenArrowsCss = css`
    &::-webkit-inner-spin-button {
        display: none;
    }
`;

const fieldCss = css`
    width: 100%;
    height: 24px !important;

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    font-weight: normal;
    font-size: 0.75rem;
    line-height: 12px;

    text-align: center;

    .darcula & {
        color: rgba(255, 255, 255, 0.7);

        background-color: #2d3d4d;
    }

    ${marginCss}
    ${props => props.hideArrows && hiddenArrowsCss}
`;

export const StyledField = styled.input`
    ${fieldCss}
`;

export const StyledInputTime = styled(InputTime)`
    ${fieldCss}
`;

export const StyledInputDateTime = styled(InputDateTime)`
    ${fieldCss}
`;

export const StyledCheckBox = styled(Checkbox)`
    display: inline-block;

    width: 100%;

    color: var(--defaultColor);
    font-size: 0.75rem;

    user-select: none;

    ${marginCss}
`;

export const StyledTextArea = styled.textarea`
    flex-grow: 1;

    width: 100%;
    min-height: 40px;

    padding: 6px 8px;

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    font-weight: normal;
    font-size: 0.75rem;
    line-height: 12px;

    resize: none;

    .darcula & {
        color: rgba(255, 255, 255, 0.7);

        background-color: #2d3d4d;
    }

    ${marginCss}
`;

export const StyledLabel = styled.label`
    margin-top: 10px;
    margin-bottom: 0;

    color: rgba(0, 0, 0, 0.7);

    font-size: 0.813em;

    .darcula & {
        color: rgba(255, 255, 255, 0.7);
    }
`;
