import React from 'react';

const BellIcon = ({ width = '16', height = '20', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M5.55558 17.5366C5.55554 17.542 5.55518 17.5474 5.55518 17.5528C5.55518 18.9044 6.64997 20.0002 8.00047 20.0002C9.35097 20.0002 10.4458 18.9044 10.4458 17.5528C10.4458 17.5474 10.4454 17.542 10.4454 17.5366H5.55558Z"
            fill="currentColor"
        />
        <path
            d="M15.8073 14.9902L13.6065 11.7545C13.6065 10.764 13.6065 8.34372 13.6065 7.71763C13.6065 4.99517 11.6693 2.72583 9.09941 2.21443V1.10033C9.09936 0.492668 8.60717 0 7.99999 0C7.39281 0 6.90061 0.492668 6.90061 1.10033V2.21447C4.33067 2.72592 2.39351 4.99526 2.39351 7.71767C2.39351 8.78345 2.39351 11.1368 2.39351 11.7546L0.192739 14.9903C-0.0390793 15.3311 -0.0635946 15.7722 0.129026 16.1367C0.321647 16.5012 0.699794 16.7291 1.11175 16.7291H14.8882C15.3002 16.7291 15.6783 16.5011 15.871 16.1367C16.0636 15.7722 16.0391 15.331 15.8073 14.9902Z"
            fill="currentColor"
        />
    </svg>
);
export default BellIcon;
