/* eslint-disable eqeqeq */
import { createSelector } from 'reselect';
import isFunction from 'lodash/isFunction';

import { teamsOpponentPredicateSelector, teamsPredicateSelector } from 'selectors/predicates/team-predicates';

import { getCurrentGameId } from '../nodes';

export const getShots = (state, props) =>
    (props && props.shots) || (state.widgetsStats.filteredShots && state.widgetsStats.filteredShots.data) || null;

function getShotsFilteredByTeam(shots, teamPredicate, filterFn) {
    if (!shots) {
        return { processing: true };
    }

    const filteredShots = shots?.filter(shot => teamPredicate(shot.teamId, shot.gameId)) || [];

    if (!!filterFn) {
        if (isFunction(filterFn)) {
            return { data: filteredShots.filter(filterFn) };
        } else {
            //TODO: fix filtration - error from goalKeeperShotsSelector
            return { data: filteredShots };
        }
    }

    return { data: filteredShots };
}

function getShotsFilteredByPlayer(shots) {
    if (!shots) return { processing: true };
    return { data: shots.map(shot => ({ ...shot, individualEvent: true })) };
}

const getShotsFilteredByActiveGame = (shots, gameId) => {
    return shots && gameId ? { data: shots.filter(s => s.gameId === gameId) } : { processing: true };
};

const processingState = { processing: true };

export const filterSelector = (_, props) => props && props.filter;

export const shotsSelector = state =>
    state.widgetsStats.filteredShots ? state.widgetsStats.filteredShots : processingState;

export const gameShotsSelector = createSelector(
    getShots,
    getCurrentGameId,
    getShotsFilteredByActiveGame
);

export const fieldShotsSelector = createSelector(
    getShots,
    teamsPredicateSelector,
    getShotsFilteredByTeam
);

export const goalKeeperShotsSelector = createSelector(
    getShots,
    teamsOpponentPredicateSelector,
    filterSelector,

    (shots, opponents, filter) => {
        return getShotsFilteredByTeam(shots, opponents, filter);
    }
);

export const reportShotsSelector = (state, props) => {
    const shots = getShots(state, props);
    const teamPredicate = teamId =>
        props && props.reportFilter && props.reportFilter.teamIds && props.reportFilter.teamIds.includes(teamId);

    return getShotsFilteredByTeam(shots, teamPredicate);
};

export const individualShotsSelector = createSelector(
    getShots,
    getShotsFilteredByPlayer
);
