import React from 'react';
import { supportRequestNotifier } from 'containers/app/private/components/support-request';
import styled from 'styled-components/macro';

const Link = styled.span`
    color: #0275d8;

    cursor: pointer;
    &:hover {
        color: #014c8c;
        text-decoration: underline;
    }
`;

export const contentList = [
    <div>
        You can now order our personalized individual report that will help you evaluate your game performance and
        achieve your goals. We created this report with the help of professional scouts and agents, who knows what to
        look for in a player evaluation process.
        <br />
        What you will gain:
        <ul>
            <li>Ability to evaluate your game;</li>
            <li>Recognize your strength and weaknesses;</li>
            <li>A professional scouting report which you can show to scouts, agents, and coaches.</li>
        </ul>
    </div>,
    <div>
        Report includes:
        <ul>
            <li>Summary page (xG value, Shots, Shift length, Points);</li>
            <li>Passing data;</li>
            <li>Transitional data (exits, entries, players contribution, and the value of transitions);</li>
            <li>Shooting data (efficiency, xG, accuracy, and maps);</li>
            <li>On ice data (how the team plays when player is on the ice);</li>
            <li>Face-off data - if applicable</li>
        </ul>
        <br />
        Order now and receive the report in the next 48 hours!
    </div>,
    'By clicking "purchase", you agree to purchase TPE Individual 15 game report',
    'Only $40',
    <div>
        If you want any specific games be included in the report, please let us know by email, right after you purchase.
        <br />
        If you have any question, feel free to <Link onClick={supportRequestNotifier.notify}>ask</Link> us!
    </div>
];

export const successPageContent = {
    title: <div>Thanks for purchase!</div>,
    text: (
        <div>
            We have already started to prepare your report and it will be ready in 2 days. You will get the email
            notification with the report attached. Meanwhile, you can visit your TPE team portal page and take a look at
            your stats!
        </div>
    )
};

export const failurePageContent = {
    title: <div>Oops</div>,
    text: <div>Your payment was not accepted. Please check your credit card details and try again.</div>
};
