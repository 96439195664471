import { gameShotsSelector } from '../../../../../containers/widgets-stats/selectors/shots';
import {
    shots,
    createGoalsSelector,
    createScoringChancesSelector,
    createBlockedShotsSelector,
    createShotsOnGoalSelector,
    createShotsWithReboundSelector,
    createShotsWithoutReboundSelector
} from './shots';
import { FAVORITE_EVENT } from '../../favorite-types';
import { processingState, mapperErrorMessage, createEpisodeIdWrapper } from '../../episode-utils';
import { getTypeToMediaTimeWrapper } from '../helpers/episodes-selectors-helpers';

const selector = gameShotsSelector;

const shotTypeToGameShotType = typeId => `SINGLE_${typeId}`;

const SINGLE_GOALS = shotTypeToGameShotType(shots.types.GOALS);
const SINGLE_SCORE_ATTEMPTS = shotTypeToGameShotType(shots.types.SCORE_ATTEMPTS);
const SINGLE_SCORE_ATTEMPTS_ON_ICE = shotTypeToGameShotType(shots.types.SCORE_ATTEMPTS_ON_ICE);
const SINGLE_SCORING_CHANCES = shotTypeToGameShotType(shots.types.SCORING_CHANCES);
const SINGLE_SCORING_CHANCES_ON_ICE = shotTypeToGameShotType(shots.types.SCORING_CHANCES_ON_ICE);
const SINGLE_BLOCKED_SHOTS = shotTypeToGameShotType(shots.types.BLOCKED_SHOTS);
const SINGLE_SHOTS_ON_GOAL = shotTypeToGameShotType(shots.types.SHOTS_ON_GOAL);
const SINGLE_SHOTS_WITH_REBOUND = shotTypeToGameShotType(shots.types.SHOTS_WITH_REBOUND);
const SINGLE_SHOTS_WITHOUT_REBOUND = shotTypeToGameShotType(shots.types.SHOTS_WITHOUT_REBOUND);

const types = {
    SINGLE_GOALS,
    SINGLE_SCORE_ATTEMPTS,
    SINGLE_SCORE_ATTEMPTS_ON_ICE,
    SINGLE_SCORING_CHANCES,
    SINGLE_SCORING_CHANCES_ON_ICE,
    SINGLE_BLOCKED_SHOTS,
    SINGLE_SHOTS_ON_GOAL,
    SINGLE_SHOTS_WITH_REBOUND,
    SINGLE_SHOTS_WITHOUT_REBOUND
};

const typeToSelector = {
    [SINGLE_GOALS]: createEpisodeIdWrapper(SINGLE_GOALS, createGoalsSelector()),
    [SINGLE_SCORING_CHANCES]: createEpisodeIdWrapper(SINGLE_SCORING_CHANCES, createScoringChancesSelector()),
    [SINGLE_SCORING_CHANCES_ON_ICE]: createEpisodeIdWrapper(
        SINGLE_SCORING_CHANCES_ON_ICE,
        createScoringChancesSelector()
    ),
    [SINGLE_BLOCKED_SHOTS]: createEpisodeIdWrapper(SINGLE_BLOCKED_SHOTS, createBlockedShotsSelector()),
    [SINGLE_SHOTS_ON_GOAL]: createEpisodeIdWrapper(SINGLE_SHOTS_ON_GOAL, createShotsOnGoalSelector()),
    [SINGLE_SHOTS_WITH_REBOUND]: createEpisodeIdWrapper(SINGLE_SHOTS_WITH_REBOUND, createShotsWithReboundSelector()),
    [SINGLE_SHOTS_WITHOUT_REBOUND]: createEpisodeIdWrapper(
        SINGLE_SHOTS_WITHOUT_REBOUND,
        createShotsWithoutReboundSelector()
    ),
    [SINGLE_SCORE_ATTEMPTS]: createEpisodeIdWrapper(SINGLE_SCORE_ATTEMPTS, s => s),
    [SINGLE_SCORE_ATTEMPTS_ON_ICE]: createEpisodeIdWrapper(SINGLE_SCORE_ATTEMPTS_ON_ICE, s => s)
};

function mapper(gameShots, type) {
    if (gameShots.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Single Shots');
    return typeToSelector[type](gameShots);
}

const typeToMediaTimeWrapper = getTypeToMediaTimeWrapper(typeToSelector);

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const favoriteType = FAVORITE_EVENT;

const singleShots = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default singleShots;
