import React, { Component } from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import ReactSelect from 'react-select';
import stripDiacritics from 'lodash/deburr';
import { createSelector } from 'reselect';
import styled, { css } from 'styled-components/macro';

const normalize = str => {
    return stripDiacritics(str.toLowerCase());
};

export const localizeNameFilter = (option, filterValue = '') => {
    const { nameEn, nameRu, name } = option;
    const normalizedFilterValue = normalize(filterValue);
    const normalizesNameRu = normalize(nameRu || '');
    const normalizesNameEn = normalize(nameEn || '');
    const normalizedName = normalize(name || '');

    return normalizedFilterValue
        .split(' ')
        .every(
            subFilter =>
                normalizesNameRu.includes(subFilter) ||
                normalizesNameEn.includes(subFilter) ||
                normalizedName.includes(subFilter)
        );
};

const scrollBarStyles = css`
    & .rs__menu-list::-webkit-scrollbar {
        width: 6px;
    }

    & .rs__dropdown-indicator {
        padding: 0;
    }

    & .rs__value-container {
        padding: 2px;
    }

    & .rs__menu-list::-webkit-scrollbar-track {
        background: #f5f5f5;
    }

    & .rs__menu-list::-webkit-scrollbar-thumb {
        border-radius: 4px;

        background: silver;
    }

    & .rs__menu-list::-webkit-scrollbar-thumb:hover {
        background: gray;
    }

    .darcula & .rs__menu-list::-webkit-scrollbar-track {
        background: #394c5c;
    }

    .darcula & .rs__menu-list::-webkit-scrollbar-thumb {
        background: #2a3948;
    }

    .darcula & .rs__menu-list::-webkit-scrollbar-thumb:hover {
        background: #172a32;
    }
`;

const selectStyles = css`
    .darcula &,
    .darcula & .rs__input,
    .darcula & .rs__value-container,
    .darcula & .rs__single-value {
        color: rgba(255, 255, 255, 0.7);
    }

    .darcula & .rs__control,
    .darcula & .rs__menu-list {
        border: 1px solid rgba(0, 0, 0, 0.3);

        background-color: #2d3d4d;
    }

    .darcula & .rs__option:hover,
    .darcula & .rs__option--is-focused {
        background-color: rgb(41, 103, 176);
    }
`;

class DefaultSelect extends Component {
    valueSelector = createSelector(
        ({ value }) => value,
        ({ options }) => options,
        (value, options) => {
            return options.filter(o => value === o.value || (Array.isArray(value) && value.includes(o.value)));
        }
    );

    filterAdapter = createSelector(
        props => props.filterOption,
        filterOption => {
            return ({ data }, filterValue) => filterOption(data, filterValue);
        }
    );

    render() {
        const { value, filterOption: legacyFilterOption, ...rest } = this.props;
        const filterOption = legacyFilterOption && this.filterAdapter(this.props);

        return <ReactSelect value={this.valueSelector(this.props)} filterOption={filterOption} {...rest} />;
    }
}

const StyledCreatableSelect = styled(CreatableSelect)`
    ${selectStyles}
    ${scrollBarStyles}
`;

const StyledDefaultSelect = styled(DefaultSelect)`
    ${selectStyles}
    ${scrollBarStyles}
`;

const SelectWrapper = ({ creatable, ...rest }) => {
    const Component = creatable ? StyledCreatableSelect : StyledDefaultSelect;

    return <Component classNamePrefix="rs" {...rest} />;
};

export default SelectWrapper;
