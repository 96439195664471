import { FAVORITE_EVENT } from '../../favorite-types';
import {
    createEpisodeIdWrapper,
    createMediaTimeWrapper,
    mapperErrorMessage,
    processingState
} from '../../episode-utils';
import { createSelector } from 'reselect';
import { getTimeForMediaTimeWrapperType } from '../helpers/episodes-selectors-helpers';
import { PENALTY_EVENT_TYPES } from '../constants/episodes-selectors-constants';

const RECOVERY = 'RECOVERY';
const GIVEAWAY = 'GIVEAWAY';
const TAKEAWAY = 'TAKEAWAY';
const FACE_OFF = 'FACE_OFF';
const PENALTY = 'PENALTY';

const types = { RECOVERY, GIVEAWAY, TAKEAWAY, FACE_OFF, PENALTY };

const selector = state => (state.widgetsStats.events ? state.widgetsStats.events : processingState);

const gameRecoveriesSelector = createEpisodeIdWrapper(
    RECOVERY,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.recovery || e.reboundRecovery) })
    )
);

const gameGiveawaysSelector = createEpisodeIdWrapper(
    GIVEAWAY,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.giveaway) })
    )
);

const gameTakeawaysSelector = createEpisodeIdWrapper(
    TAKEAWAY,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.takeaway) })
    )
);

const gameFaceoffsSelector = createEpisodeIdWrapper(
    FACE_OFF,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.eventType === 'FACE_OFF') })
    )
);

const penaltiesSelector = createEpisodeIdWrapper(
    PENALTY,
    createSelector(
        events => events.data,
        data => ({ data: data?.filter(e => PENALTY_EVENT_TYPES.includes(e.eventType)) || [] })
    )
);

function mapper(gameEvents, type) {
    if (gameEvents.processing) return processingState;
    switch (type) {
        case RECOVERY:
            return gameRecoveriesSelector(gameEvents);
        case GIVEAWAY:
            return gameGiveawaysSelector(gameEvents);
        case TAKEAWAY:
            return gameTakeawaysSelector(gameEvents);
        case FACE_OFF:
            return gameFaceoffsSelector(gameEvents);
        case PENALTY:
            return penaltiesSelector(gameEvents);
        default:
            throw mapperErrorMessage(type, 'Events');
    }
}

const getPenaltyTime = ({ time }) => ({
    startTime: time,
    endTime: time,
    time,
    hasPadding: true,
    leftPadding: 25,
    rightPadding: 3
});

const typeToMediaTimeWrapper = {
    [RECOVERY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [GIVEAWAY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [TAKEAWAY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [FACE_OFF]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [PENALTY]: createMediaTimeWrapper(getPenaltyTime)
};

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const favoriteType = FAVORITE_EVENT;

const events = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default events;
