import { FAVORITE_EVENT } from '../../favorite-types';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { createSelector } from 'reselect';
import { individualEventsSelector } from '../../../../../containers/widgets-stats/selectors/events';
import { getTimeForMediaTimeWrapperType } from '../helpers/episodes-selectors-helpers';

const PERSONAL_SINGLE_GIVEAWAY = 'PERSONAL_SINGLE_GIVEAWAY';
const PERSONAL_SINGLE_TAKEAWAY = 'PERSONAL_SINGLE_TAKEAWAY';

const types = { PERSONAL_SINGLE_GIVEAWAY, PERSONAL_SINGLE_TAKEAWAY };

const selector = individualEventsSelector;

const gameGiveawaysSelector = createEpisodeIdWrapper(
    PERSONAL_SINGLE_GIVEAWAY,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.giveaway) })
    )
);

const gameTakeawaysSelector = createEpisodeIdWrapper(
    PERSONAL_SINGLE_TAKEAWAY,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.takeaway) })
    )
);

function mapper(gameEvents, type) {
    if (gameEvents.processing) return processingState;
    switch (type) {
        case PERSONAL_SINGLE_GIVEAWAY:
            return gameGiveawaysSelector(gameEvents);
        case PERSONAL_SINGLE_TAKEAWAY:
            return gameTakeawaysSelector(gameEvents);
        default:
            throw mapperErrorMessage(type, 'Single Events');
    }
}

const typeToMediaTimeWrapper = {
    [PERSONAL_SINGLE_GIVEAWAY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [PERSONAL_SINGLE_TAKEAWAY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType)
};

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const favoriteType = FAVORITE_EVENT;

const individualEvents = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default individualEvents;
