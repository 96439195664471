import { FAVORITE_EVENT } from '../../favorite-types';
import { processingState, mapperErrorMessage, createEpisodeIdWrapper } from '../../episode-utils';
import { goalKeeperShotsSelector } from '../../../../../containers/widgets-stats/selectors/shots';
import {
    shots,
    createGoalsSelector,
    createScoringChancesSelector,
    createBlockedShotsSelector,
    createMissedShotsSelector,
    createShotsOnGoalSelector,
    createShotsWithReboundSelector,
    createShotsWithoutReboundSelector
} from './shots';
import { getTypeToMediaTimeWrapper } from '../helpers/episodes-selectors-helpers';

const shotTypeToGoaltenderShotType = typeId => `GOALTENDERS_${typeId}`;

const GOALTENDERS_BLOCKED_SHOTS = shotTypeToGoaltenderShotType(shots.types.BLOCKED_SHOTS);
const GOALTENDERS_SCORING_CHANCES = shotTypeToGoaltenderShotType(shots.types.SCORING_CHANCES);
const GOALTENDERS_SCORE_ATTEMPTS = shotTypeToGoaltenderShotType(shots.types.SCORE_ATTEMPTS);
const GOALTENDERS_GOALS = shotTypeToGoaltenderShotType(shots.types.GOALS);
const GOALTENDERS_SHOTS_ON_GOAL = shotTypeToGoaltenderShotType(shots.types.SHOTS_ON_GOAL);
const GOALTENDERS_SHOTS_WITH_REBOUND = shotTypeToGoaltenderShotType(shots.types.SHOTS_WITH_REBOUND);
const GOALTENDERS_SHOTS_WITHOUT_REBOUND = shotTypeToGoaltenderShotType(shots.types.SHOTS_WITHOUT_REBOUND);
const GOALTENDERS_MISSED_SHOTS = shotTypeToGoaltenderShotType(shots.types.MISSED_SHOTS);

const types = {
    GOALTENDERS_BLOCKED_SHOTS,
    GOALTENDERS_SCORING_CHANCES,
    GOALTENDERS_SCORE_ATTEMPTS,
    GOALTENDERS_GOALS,
    GOALTENDERS_SHOTS_ON_GOAL,
    GOALTENDERS_SHOTS_WITH_REBOUND,
    GOALTENDERS_SHOTS_WITHOUT_REBOUND,
    GOALTENDERS_MISSED_SHOTS
};

const selector = goalKeeperShotsSelector;

const favoriteType = FAVORITE_EVENT;

const typeToSelector = {
    GOALTENDERS_BLOCKED_SHOTS: createEpisodeIdWrapper(GOALTENDERS_BLOCKED_SHOTS, createBlockedShotsSelector()),
    GOALTENDERS_SCORING_CHANCES: createEpisodeIdWrapper(GOALTENDERS_SCORING_CHANCES, createScoringChancesSelector()),
    GOALTENDERS_GOALS: createEpisodeIdWrapper(GOALTENDERS_GOALS, createGoalsSelector()),
    GOALTENDERS_SHOTS_ON_GOAL: createEpisodeIdWrapper(GOALTENDERS_SHOTS_ON_GOAL, createShotsOnGoalSelector()),
    GOALTENDERS_SHOTS_WITH_REBOUND: createEpisodeIdWrapper(
        GOALTENDERS_SHOTS_WITH_REBOUND,
        createShotsWithReboundSelector()
    ),
    GOALTENDERS_SHOTS_WITHOUT_REBOUND: createEpisodeIdWrapper(
        GOALTENDERS_SHOTS_WITHOUT_REBOUND,
        createShotsWithoutReboundSelector()
    ),
    GOALTENDERS_MISSED_SHOTS: createEpisodeIdWrapper(GOALTENDERS_MISSED_SHOTS, createMissedShotsSelector()),
    GOALTENDERS_SCORE_ATTEMPTS: createEpisodeIdWrapper(GOALTENDERS_SCORE_ATTEMPTS, s => s)
};

function mapper(shotsData, type) {
    if (shotsData.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Goaltenders Shots');
    return typeToSelector[type](shotsData);
}

const typeToMediaTimeWrapper = getTypeToMediaTimeWrapper(typeToSelector);

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const goaltendersShots = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default goaltendersShots;
