import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
/**
 * Метка, указывающая на то, что объект стоит исключить из проверки на эквивалентность аргументов селектора
 * @type {Symbol}
 */

const skipEqualityMark = Symbol('Skip Equality Mark');
/**
 * @param selector селектор, который необходимо пометить,
 *        как не учавствующий в процессе сравнения
 * @return селектор возвращающий результат переданного селектора помеченный {@link skipEqualityMark}
 */

export function markSelector(selector) {
    return createSelector(
        selector,
        unmarked => ({ ...unmarked, [skipEqualityMark]: true })
    );
}

const hasSkipEqualityMark = selector => selector && selector.hasOwnProperty(skipEqualityMark);
/**
 * factory-метод создающий селектор, пропускающий аргументы помеченные {@link skipEqualityMark}
 */

export const createSkipMarkedArgsSelector = createSelectorCreator(
    defaultMemoize,
    (o1, o2) => o1 === o2 || (hasSkipEqualityMark(o1) && hasSkipEqualityMark(o2))
);
