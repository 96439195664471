import { makePrefixAdder } from '../utils/type-utils';
import { STORAGE_THEME } from '../../utils/api/auth-utils';

export const themes = {
    CLASSIC: 'classic',
    DARCULA: 'darcula',
    HOCKEY_TECH: 'hockeyTech'
};

const addPrefixTo = makePrefixAdder('PORTAL_THEME');
const SWITCH_THEME = addPrefixTo('SWITCH_THEME');

const switchTheme = themeType => {
    return { type: SWITCH_THEME, payload: themeType };
};

const defaultState = {
    theme: sessionStorage.getItem(STORAGE_THEME) || localStorage.getItem(STORAGE_THEME) || themes.CLASSIC
};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case SWITCH_THEME:
            return {
                theme: action.payload
            };
        default:
            return state;
    }
}

export const selector = state => state.theme.theme;

const switchToClassic = () => {
    return switchTheme(themes.CLASSIC);
};

const switchToDarcula = () => {
    return switchTheme(themes.DARCULA);
};

export const actions = {
    switchToClassic,
    switchToDarcula
};
