import { createSelector } from 'reselect';
import { fetchedGamesSelector } from '../games/games-selector';
import isEmpty from 'lodash/isEmpty';
import { fetchedSeasonsSelector } from '../seasons/seasons-selector';

export const teamsFromGamesSelector = createSelector(
    fetchedGamesSelector,
    state => state.statsFilter.teamIds,
    state => state.entities.teams,
    (games, teamIds, teams) => {
        if (!games.length) {
            return [];
        }

        if (isEmpty(teamIds)) {
            return [games[0].homeTeam];
        }

        const teamId = teamIds[0];

        return [teams[teamId]];
    }
);

export const teamsBySeasonSelector = createSelector(
    fetchedSeasonsSelector,
    fetchedGamesSelector,
    state => state.statsFilter.uploadedGames,
    state => state.statsFilter.publishedGames,
    state => state.statsFilter.inProgressGames,
    state => state.statsFilter.underReviewGames,
    (seasons, games, uploadedGames, publishedGames, inProgressGames, underReviewGames) => {
        const gamesBySeasons = seasons.map(season =>
            [
                ...(games ?? []),
                ...(uploadedGames ?? []),
                ...(publishedGames ?? []),
                ...(inProgressGames ?? []),
                ...(underReviewGames ?? [])
            ].filter(g => g.seasonId === season.id)
        );

        if (!gamesBySeasons.length) {
            return [];
        }

        const teams = [].concat(...gamesBySeasons[0].map(game => [game.homeTeam, game.awayTeam]));
        return [...new Set(teams)];
    }
);

export const teamIdsSelector = createSelector(
    state => state.statsFilter.teamIds,
    teamIds => teamIds ?? []
);
