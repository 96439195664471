import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { selector as designSelector, actions as designActions } from '../../redux/modules/design';
import { actions as preferencesActions } from '../../redux/modules/preferences';
import { selector as themeSelector, themes } from '../../redux/modules/theme';
import { isReport as isReportSelector } from '../../containers/product-initializer/product-module';
import cx from 'classnames';
import { isColorSchemeToggled, isBrandedByHockeyTech } from '../../selectors/user/user-features-selector';
import GlobalStyle from './private/global-style';
import { syncThemeWithStorage, syncDesignWithStorage } from './private/theme-container-helpers';

const { switchToStable, switchToMarketing } = designActions;

const { allowAccessAnalystToAdmin, denyAccessAnalystToAdmin } = preferencesActions;

function mapStateToProps(state) {
    const report = isReportSelector(state);
    const colorSchemeIsToggled = isColorSchemeToggled(state);

    const theme = isBrandedByHockeyTech(state) ? themes.HOCKEY_TECH : themeSelector(state);
    const design = designSelector(state);

    return {
        theme: !report && theme,
        design: !report && design,
        colorSchemeIsToggled
    };
}

class ThemeContainer extends Component {
    setBodyClassNames() {
        const { theme, design } = this.props;
        document.body.className = cx(theme, design);
    }

    componentDidMount() {
        const { switchToMarketing, switchToStable, allowAccessAnalystToAdmin, denyAccessAnalystToAdmin } = this.props;

        try {
            this.setBodyClassNames();

            Object.defineProperty(window, 'marketing', {
                get: switchToMarketing
            });

            Object.defineProperty(window, 'standard', {
                get: switchToStable
            });

            Object.defineProperty(window, 'analystGodModeOn', {
                get: () => {
                    localStorage.setItem('accessAnalystToAdmin', 'true');
                    allowAccessAnalystToAdmin();
                    return null;
                }
            });

            Object.defineProperty(window, 'analystGodModeOff', {
                get: () => {
                    localStorage.removeItem('accessAnalystToAdmin');
                    denyAccessAnalystToAdmin();
                    return null;
                }
            });
        } catch (e) {
            /*eslint-disable*/
            console.error(e);
            /*eslint-enable*/
        }
    }

    componentDidUpdate(prevProps) {
        const { theme, design } = this.props;
        this.setBodyClassNames();

        if (theme !== prevProps.theme) {
            syncThemeWithStorage(theme);
        }

        if (design !== prevProps.design) {
            syncDesignWithStorage(design);
        }
    }

    render() {
        const { theme, colorSchemeIsToggled, children } = this.props;

        return (
            <Fragment>
                <GlobalStyle appTheme={theme} isColorSchemeToggled={colorSchemeIsToggled} />
                {children}
            </Fragment>
        );
    }
}

ThemeContainer = connect(
    mapStateToProps,
    {
        switchToMarketing,
        switchToStable,
        allowAccessAnalystToAdmin,
        denyAccessAnalystToAdmin
    }
)(ThemeContainer);

export { ThemeContainer as default };
