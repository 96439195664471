import { makePrefixAdder } from '../utils/type-utils';
import { getIndexer } from '../../utils/helpers/number-utils';

const addPrefixTo = makePrefixAdder('NOTIFICATIONS');
const NOTIFY = addPrefixTo('NOTIFY');
const DESTROY_NOTIFICATION = addPrefixTo('DESTROY_NOTIFICATION');

export const notificationTypes = {
    ERROR: 'error',
    SUCCESS: 'success',
    INFO: 'info'
};

const getId = getIndexer();

export const notify = (content, type = notificationTypes.INFO) => {
    return { type: NOTIFY, payload: { content, type, id: getId() } };
};

export const destroy = id => {
    return { type: DESTROY_NOTIFICATION, payload: { id } };
};

const defaultState = {
    notifications: []
};

export function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case NOTIFY:
            return {
                notifications: [...state.notifications, payload]
            };
        case DESTROY_NOTIFICATION:
            return {
                notifications: state.notifications.filter(n => n.id !== payload.id)
            };
        default:
            return state;
    }
}

export const selector = state => state.notifications.notifications;
