/* eslint-disable eqeqeq,no-new-func */
import { makePrefixAdder } from '../utils/type-utils';
import { createSelector } from 'reselect';
import IntlMessageFormat from 'intl-messageformat';
import en from '../../translation/en';
import ru from '../../translation/ru';
import zh from '../../translation/zh';
import isString from 'lodash/isString';
import { hasTemporaryKey, STORAGE_LANGUAGE } from '../../utils/api/auth-utils';
import { isDevelopment } from '../../utils/helpers/envirioment-utils';

IntlMessageFormat.__addLocaleData({
    locale: 'en',
    pluralRuleFunction: function(n, ord) {
        let s = String(n).split('.'),
            i = s[0],
            v0 = !s[1],
            i10 = i.slice(-1),
            i100 = i.slice(-2);
        if (ord) return 'other';
        return v0 && i10 === 1 && i100 !== 11
            ? 'one'
            : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)
            ? 'few'
            : (v0 && i10 === 0) || (v0 && i10 >= 5 && i10 <= 9) || (v0 && i100 >= 11 && i100 <= 14)
            ? 'many'
            : 'other';
    }
});

const DEFAULT_LANGUAGE = 'en';
const messages = {
    en,
    ru,
    zh
};
const addPrefixTo = makePrefixAdder('locale');
const LANGUAGE_CHANGE = addPrefixTo('LANGUAGE_CHANGE');
export const types = {
    LANGUAGE_CHANGE
};

function changeLanguage(language) {
    if (hasTemporaryKey()) {
        sessionStorage.setItem(STORAGE_LANGUAGE, language);
    } else {
        localStorage.setItem(STORAGE_LANGUAGE, language);
    }
    return {
        type: LANGUAGE_CHANGE,
        language
    };
}

export const actions = {
    changeLanguage
};

export const languageSelector = state => state.locale.language;

const messageSelector = (state, props) => props;

function formatTemplate(template, values) {
    const substitution = new Function('values', `return \`${template}\``);
    let result;

    try {
        result = substitution(values);
    } catch (e) {
        // todo extract to helper method
        if (isDevelopment()) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        result = '';
    }

    return result;
}
/**
 * @return {String} Format message according to language and specified message
 */

const formatMessageWrapper = (lang, options) => {
    try {
        return formatMessage(lang, options);
    } catch (e) {
        try {
            return formatMessage('en', options);
        } catch (e) {
            if (isDevelopment()) {
                throw e;
            }
            return options.id;
        }
    }
};

function formatMessage(lang, { id, values, options, defaultMessage, language }) {
    const derivedLanguage = language || lang;
    const translation = messages[derivedLanguage] && messages[derivedLanguage][id];

    const messagesValue = (id && translation) || defaultMessage;

    if (!isString(messagesValue) && !(messagesValue && isString(messagesValue.message))) {
        throw new Error(`Wrong template for message with id=${id}`);
    }

    const template = isString(messagesValue) ? messagesValue : messagesValue.message;
    const message = formatTemplate(template, values);
    return !!values ? new IntlMessageFormat(message, derivedLanguage, options).format(values) : message;
}
/**
 * @return {String} The title if message contains title value, otherwise `undefined`
 */

function formatTitle(language, { id }) {
    const message = id && (messages[language] || {})[id];
    return message && isString(message.title) ? message.title : undefined;
}
/**
 * @return {Function} Selector that return message and title
 */

export const makeGetFormattedMessageProps = () => {
    return createSelector(
        [languageSelector, messageSelector],
        (lang, props) => ({
            message: formatMessageWrapper(lang, props),
            title: formatTitle(lang, props)
        })
    );
};
export const makeGetFormattedMessage = () => {
    return createSelector(
        [languageSelector, messageSelector],
        formatMessageWrapper
    );
};
export const getFormatMessageFunction = createSelector(
    languageSelector,
    language => {
        return props => formatMessageWrapper(language, props);
    }
);
const storedLanguage =
    sessionStorage.getItem(STORAGE_LANGUAGE) ||
    localStorage.getItem(STORAGE_LANGUAGE) ||
    navigator.language ||
    navigator.userLanguage ||
    DEFAULT_LANGUAGE;
const languageWithoutGroup = storedLanguage.substr(0, 2);
const validLanguage = languageWithoutGroup in messages ? languageWithoutGroup : DEFAULT_LANGUAGE;
const initialState = {
    language: validLanguage
};
const moduleLocale = (state = initialState, action) => {
    switch (action.type) {
        case LANGUAGE_CHANGE:
            return {
                language: action.language
            };

        default:
            return state;
    }
};
export default moduleLocale;

const languageIsChineseSelector = state => !!state.locale && !!state.locale.language && state.locale.language === 'zh';

export const languageSelectorForRecaptcha = state => (languageIsChineseSelector(state) ? 'zh-CN' : DEFAULT_LANGUAGE);
