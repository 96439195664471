export const symbolIsNonASCII = symbol => {
    if (symbol === '') {
        return true;
    }
    const regex = /([^\u0041-\u005A\u0061-\u007A]+)/g;

    const wrongSymbolFind = [...symbol].find(char => {
        return !char.match(regex);
    });

    return !wrongSymbolFind;
};

export const symbolsIsCyrillic = symbols => {
    if (symbols === '') {
        return true;
    }
    const regex = /([^\u0410-\u0451]+)/g;

    const symbolFind = [...symbols].find(char => {
        return !char.match(regex);
    });

    return !!symbolFind;
};

export const symbolIsEng = symbol => {
    if (symbol === '') {
        return true;
    }
    const regex = /([^\u0041-\u005A\u0061-\u007A]+)/g;

    const wrongSymbolFind = [...symbol].find(char => {
        return !char.match(regex);
    });

    return !!wrongSymbolFind;
};
