import React from 'react';
import styled from 'styled-components/macro';
import { desktopMediaCondition, mobileMediaCondition } from '../../../utils/helpers/device-utils';
import { DESKTOP_RES } from '../../../constants/device-parameters';

const Content = styled.div`
    flex: 1;
    @media ${mobileMediaCondition} {
        overflow: hidden;
    }
`;

export const AppContent = ({ children, ...props }) => <Content {...props}>{children}</Content>;

const AppLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${props => (!!props.maxWidthContainer ? `${props.maxWidthContainer}px!important` : '100%')};
    min-height: 100vh !important;

    background: ${props =>
        props.prospectExchangeAccount ? 'var(--backgroundColorSecondary)' : 'var(--backgroundColor)'};
    @media print {
        .layout {
            background: #fff;
        }
    }
    @media ${desktopMediaCondition} {
        min-width: ${props => (!!props.maxWidthContainer ? '100%' : `${DESKTOP_RES.minWidthPx}px`)};
        min-height: ${`${DESKTOP_RES.minHeightPx}px`};
    }
`;

export default AppLayout;
