import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import { createSelector } from 'reselect';
import ScrollArea from '../scroll-area/scroll-area';
import Search from '../svg-icons/search';
import FormattedMessage from '../formatted-message';
import PropsFormatter, { tr } from '../../containers/props-formatter';
import styles from './filter-select.module.css';
import EllipsisText from '../ellipsis-text/ellipsis-text';
import {
    calculateOptionsOffset,
    countScrolledDelimiters,
    GROUP_DELIMITER_HEIGHT,
    MAX_RENDERED_OPTIONS,
    OPTION_HEIGHT,
    sliceOptions
} from './filter-select-helpers';
import isNumber from 'lodash/isNumber';
import DeviceSelector from '../device-selector/device-selector';
import StyledModal from '../modal/styled-modal';
import { css } from 'styled-components/macro';

const mobileContentCss = css`
    padding: 0;
`;

class Option extends PureComponent {
    handleClick = () => {
        const { onClick, option, selected, disabled } = this.props;
        !disabled && onClick(option, !selected);
    };

    optionContentRef = React.createRef();

    state = { optionContent: null };

    componentDidMount() {
        this.setState({ optionContent: this.optionContentRef?.current?.textContent });
    }

    render() {
        const { optionPresentation, option, selected, ignored, bordered, width, disabled } = this.props;
        const className = cx({
            [styles.ignored]: ignored,
            [styles.bordered]: bordered,
            [styles.selected]: selected,
            [styles.disabled]: disabled
        });

        const optionWidth = isNumber(width) ? `${width - 30}px` : 'calc(100% - 30px)';

        return (
            <li
                onClick={this.handleClick}
                className={className}
                title={
                    typeof optionPresentation(option) === 'string'
                        ? optionPresentation(option)
                        : this.state.optionContent || ''
                }
            >
                <EllipsisText ref={this.optionContentRef} width={optionWidth}>
                    {optionPresentation(option)}
                </EllipsisText>
            </li>
        );
    }
}

class MetaOption extends PureComponent {
    isActive = createSelector(
        props => props.metaOption,
        props => props.options,
        props => props.values,
        props => props.optionKey,
        (metaOption, options, values, optionKey) => {
            const valuesSet = new Set(values);
            return options
                .filter(option => metaOption.predicate(option))
                .every(option => {
                    const key = optionKey ? optionKey(option) : option;
                    return valuesSet.has(key);
                });
        }
    );

    handleClick = () => {
        const { metaOption, onClick } = this.props;
        const selected = !this.isActive(this.props);

        onClick(metaOption.predicate, selected);
    };

    render() {
        const { metaOption } = this.props;
        const active = this.isActive(this.props);
        return (
            <div onClick={this.handleClick} className={cx(styles.metaOption, { [styles.metaOptionActive]: active })}>
                {metaOption.presentation}
            </div>
        );
    }
}

const propTypes = {
    /*Option presentation*/
    optionPresentation: PropTypes.func.isRequired,

    onOptionClick: PropTypes.func.isRequired,
    onClickOutside: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    onDeselectAll: PropTypes.func.isRequired,

    /*Predicate which handle autocomplete filtration*/
    optionFilterPredicate: PropTypes.func
};

class FilterSelectPanel extends PureComponent {
    static propTypes = propTypes;

    state = {
        optionsOffset: 0
    };

    panel = React.createRef();
    searchInput = React.createRef();

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        document.addEventListener('touchstart', this.handleClickOutside, true);
        const { autocomplete } = this.props;
        if (autocomplete && this.searchInput.current) this.searchInput.current.focus();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('touchstart', this.handleClickOutside, true);
    }

    handleClickOutside = e => {
        if (e.target !== this.panel.current && !this.panel.current.contains(e.target)) {
            this.props.onClickOutside();
        }
    };

    handleFilterChange = e => {
        this.changeFilterValue(e.target.value);
    };

    changeFilterValue = debounce(value => {
        this.setState({ filter: value });
    }, 300);

    deselectAll = () => {
        const { onDeselectAll } = this.props;
        onDeselectAll(this.filteredFlatOptions(this.props, this.state));
    };

    selectAll = () => {
        const { onSelectAll } = this.props;
        onSelectAll(this.filteredFlatOptions(this.props, this.state));
    };

    handleMetaOptionClick = (predicate, selected) => {
        const { onSelectAll, onDeselectAll } = this.props;
        const options = this.flatOptions(this.props);
        if (selected) {
            onSelectAll(options.filter(predicate));
        } else {
            onDeselectAll(options.filter(predicate));
        }
    };

    sortedOptions = createSelector(
        props => props.options,
        props => props.optionsSorted,
        props => props.optionsSortingPredicate,
        (options, optionsSorted, optionsSortingPredicate) => {
            if (!optionsSorted) return options;
            return [...(options ?? [])].sort(optionsSortingPredicate);
        }
    );

    flatOptions = createSelector(
        this.sortedOptions,
        props => props.optionsGrouped,
        (options, optionsGrouped) => {
            return optionsGrouped ? [].concat(...options) : options;
        }
    );

    filteredFlatOptions = createSelector(
        this.flatOptions,
        props => props.optionFilterPredicate,
        (props, state) => state.filter,
        (options, filterPredicate, value) =>
            filterPredicate && options
                ? options.filter(option => filterPredicate(option, value))
                : options
                ? options
                : []
    );

    filteredGroupedOptions = createSelector(
        this.sortedOptions,
        props => props.optionFilterPredicate,
        (props, state) => state.filter,
        (options, filterPredicate, value) => {
            const filtered =
                filterPredicate && Boolean(value)
                    ? options.map(group => group.filter(option => filterPredicate(option, value)))
                    : options;
            return filtered.filter(group => Boolean(group.length));
        }
    );

    filteredOptions = (props, state) =>
        props.optionsGrouped ? this.filteredGroupedOptions(props, state) : this.filteredFlatOptions(props, state);

    renderMetaOptions() {
        const { metaOptions, values, optionKey } = this.props;
        const options = this.flatOptions(this.props);

        if (!Array.isArray(metaOptions)) return;

        //strengthSubFilters
        return (
            <div className={styles.metaOptionsContainer}>
                {metaOptions.map(option => {
                    return (
                        <MetaOption
                            key={option.name}
                            optionKey={optionKey}
                            onClick={this.handleMetaOptionClick}
                            metaOption={option}
                            values={values}
                            options={options}
                        />
                    );
                })}
            </div>
        );
    }

    renderFixedGroup = () => {
        const { optionsGrouped } = this.props;
        const filteredOptions = this.filteredOptions(this.props, this.state);
        const selectedGroup = filteredOptions.length ? filteredOptions[0] : [];
        const slicedGroups = sliceOptions(
            filteredOptions.slice(1, filteredOptions.length),
            optionsGrouped,
            this.getOptionsOffset()
        );
        const secondGroup = slicedGroups.length ? slicedGroups[0] : [];
        const className = cx({
            [styles.bordered]: true
        });

        return (
            <>
                <ScrollArea key={1} autoHeight autoHeightMax={189}>
                    <div style={{ height: this.getGroupHeight(selectedGroup), overflow: 'hidden' }}>
                        <ul className={styles.menu}>{this.renderOptionsGroup(selectedGroup)}</ul>
                    </div>
                </ScrollArea>
                <div className={className} />
                <ScrollArea key={2} autoHeight autoHeightMax={189} onScrollFrame={this.handleScroll}>
                    <div style={{ height: this.getGroupHeight(secondGroup), overflow: 'hidden' }}>
                        <ul
                            className={styles.menu}
                            style={{ transform: `translateY(${this.getPixelOffsetForGroup(secondGroup)}px)` }}
                        >
                            {this.renderOptionsGroup(secondGroup)}
                        </ul>
                    </div>
                </ScrollArea>
            </>
        );
    };

    renderFlatOptions = (options, bordered = false) => {
        const {
            width,
            values,
            ignoredValues,
            onOptionClick,
            optionKey,
            optionPresentation,
            isOptionDisabled
        } = this.props;
        const uniqueValues = new Set(values);
        const uniqueIgnoredValues = ignoredValues ? new Set(ignoredValues) : new Set();
        return options.map((option, index) => {
            const key = optionKey ? optionKey(option) : option;
            const selected = uniqueValues.has(key);
            const ignored = uniqueIgnoredValues.has(key);
            const disabled = isOptionDisabled && isOptionDisabled(option);
            return (
                <Option
                    key={`Options_${index}`}
                    option={option}
                    optionPresentation={optionPresentation}
                    bordered={bordered && index === options.length - 1}
                    width={width}
                    ignored={ignored}
                    selected={selected}
                    onClick={onOptionClick}
                    disabled={disabled}
                />
            );
        });
    };

    renderOptionsGroup = group => {
        return this.renderFlatOptions(group, false);
    };

    renderOptions() {
        const { optionsGrouped } = this.props;
        const filteredOptions = this.filteredOptions(this.props, this.state);

        const slicedOptions = sliceOptions(filteredOptions, optionsGrouped, this.getOptionsOffset());

        if (!optionsGrouped) return this.renderFlatOptions(slicedOptions);

        return [].concat(
            ...slicedOptions.map((group, index) => {
                const groupIsNotLast = index !== filteredOptions.length - 1;
                const unslicedGroup = filteredOptions[index];
                const groupLastOptionIsNotSliced = unslicedGroup[unslicedGroup.length - 1] === group[group.length - 1];

                return this.renderFlatOptions(group, groupIsNotLast && groupLastOptionIsNotSliced);
            })
        );
    }

    renderSearchPanel() {
        return (
            <div className={styles.searchPanel}>
                <Search />
                <PropsFormatter placeholder={tr('filter.search.placeholder')}>
                    <input
                        type="text"
                        className={styles.searchInput}
                        ref={this.searchInput}
                        onChange={this.handleFilterChange}
                    />
                </PropsFormatter>
            </div>
        );
    }

    renderControls() {
        return (
            <div className={styles.controls}>
                <div onClick={this.selectAll} className={styles.selectAll}>
                    <FormattedMessage id="filter.selectAll" />
                </div>
                <div onClick={this.deselectAll} className={styles.deselectAll}>
                    <FormattedMessage id="filter.deselectAll" />
                </div>
            </div>
        );
    }

    handleScroll = debounce(values => {
        this.setState({ optionsOffset: calculateOptionsOffset(values.scrollTop) });
    }, 100);

    getOptionsCount() {
        const { optionsGrouped } = this.props;
        const filteredOptions = this.filteredOptions(this.props, this.state);
        if (!optionsGrouped) {
            return !!filteredOptions ? filteredOptions.length : 0;
        } else {
            return filteredOptions.reduce((acc, group) => acc + group.length, 0);
        }
    }

    getGroupHeight = group => group.length * OPTION_HEIGHT;

    getPanelHeight() {
        const { optionsGrouped } = this.props;
        if (!optionsGrouped) {
            return this.getOptionsCount() * OPTION_HEIGHT;
        } else {
            const filteredOptions = this.filteredOptions(this.props, this.state);
            const groupDelimiters = filteredOptions.reduce(
                (acc, group, index, arr) => acc + Number(group.length !== 0 && index !== arr.length - 1),
                0
            );
            return this.getOptionsCount() * OPTION_HEIGHT + groupDelimiters * GROUP_DELIMITER_HEIGHT;
        }
    }

    getOptionsOffsetGroup = group => {
        const { optionsOffset } = this.state;
        return Math.max(0, Math.min(optionsOffset, group.length - MAX_RENDERED_OPTIONS));
    };

    getOptionsOffset() {
        const { optionsOffset } = this.state;
        return Math.max(0, Math.min(optionsOffset, this.getOptionsCount() - MAX_RENDERED_OPTIONS));
    }

    getPixelOffsetForGroup = group => {
        const optionsOffset = this.getOptionsOffsetGroup(group);
        return optionsOffset * OPTION_HEIGHT;
    };

    getPixelOffset() {
        const { optionsGrouped } = this.props;
        const optionsOffset = this.getOptionsOffset();

        if (!optionsGrouped) {
            return optionsOffset * OPTION_HEIGHT;
        }

        const filteredOptions = this.filteredOptions(this.props, this.state);
        const scrolledGroups = countScrolledDelimiters(filteredOptions, optionsOffset);

        return optionsOffset * OPTION_HEIGHT + scrolledGroups * GROUP_DELIMITER_HEIGHT;
    }

    renderDropList(deviceClassName) {
        const { width, hasControls, autocomplete, className, fixedGroups } = this.props;

        return (
            <div
                ref={this.panel}
                style={{ minWidth: width }}
                className={cx(styles.content, className, deviceClassName)}
            >
                {autocomplete && this.renderSearchPanel()}
                {this.renderMetaOptions()}
                {fixedGroups ? (
                    this.renderFixedGroup()
                ) : (
                    <ScrollArea autoHeight autoHeightMax={189} onScrollFrame={this.handleScroll}>
                        <div style={{ height: this.getPanelHeight(), overflow: 'hidden' }}>
                            <ul className={styles.menu} style={{ transform: `translateY(${this.getPixelOffset()}px)` }}>
                                {this.renderOptions()}
                            </ul>
                        </div>
                    </ScrollArea>
                )}
                {hasControls && this.renderControls()}
            </div>
        );
    }

    render() {
        return (
            <DeviceSelector
                desktopLayout={this.renderDropList()}
                mobileLayout={
                    <StyledModal disableScroll contentCss={mobileContentCss} isOpen>
                        {this.renderDropList(styles['static-position'])}
                    </StyledModal>
                }
            />
        );
    }
}

export default FilterSelectPanel;
