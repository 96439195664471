import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

export const combinedMapStateToProps = (...list) => (...args) =>
    Object.assign(
        {},
        ...list.map(item => {
            if (isFunction(item)) {
                return item(...args);
            }

            if (isObject(item)) {
                return mapValues(item, selector => selector(...args));
            }

            throw new Error('Unknown type of combinedMapStateToProps argument ');
        })
    );
