import requestBuilder from '../../redux/utils/request-builder';
import { makeBundle, makePrefixAdder } from '../../redux/utils/type-utils';
import * as endpoints from '../../utils/api/endpoints';
import { gamePlayerSchema, leagueSchema, playerProfileSchema } from '../../utils/schemas';

const addPrefixTo = makePrefixAdder('statsFilterDynamic');

const leaguesBundle = makeBundle(addPrefixTo('LEAGUES_INIT'));
const gamePlayersBundle = makeBundle(addPrefixTo('GAME_PLAYERS_INIT'));
const playerProfilesBundle = makeBundle(addPrefixTo('PLAYER_PROFILES_INIT'));

export const nameToRequest = {
    leagues: {
        bundle: leaguesBundle,
        request: () =>
            requestBuilder(leaguesBundle)
                .addSchema([leagueSchema])
                .get(endpoints.leagues)
    },
    gamePlayers: {
        bundle: gamePlayersBundle,
        request: gameIds =>
            requestBuilder(gamePlayersBundle)
                .addSchema([gamePlayerSchema])
                .post(endpoints.extractPlayersInGames, gameIds)
    },
    playerProfiles: {
        bundle: playerProfilesBundle,
        request: profileIds =>
            requestBuilder(playerProfilesBundle)
                .addSchema([playerProfileSchema])
                .post(endpoints.playerProfiles, profileIds)
    }
};

function handleBundle(state, action, bundleName, bundle) {
    const { type } = action;
    switch (type) {
        case bundle.REQUEST:
            return {
                ...state,
                [bundleName]: { ...state[bundleName], processing: true }
            };
        case bundle.SUCCESS:
            const data = action.response.result;
            const additionalData = {};

            if (bundleName === 'gamePlayers') {
                const playerIds = Object.values(action.response.entities.gamePlayers).map(gp => gp.playerId);
                additionalData.playerIds = [...new Set(playerIds)];
            }

            return {
                ...state,
                [bundleName]: { data, ...additionalData, processing: false }
            };
        case bundle.CANCELLED:
            return {
                ...state,
                [bundleName]: { processing: false }
            };
        case bundle.FAILURE:
            return {
                ...state,
                [bundleName]: { processing: false, errors: [action.response.data.message] }
            };
        default:
            return state;
    }
}

export const handleDynamicOptions = (state, action) => {
    const { dynamicOptions } = state;

    const nextDynamicOptions = Object.keys(nameToRequest).reduce(
        (state, name) => handleBundle(state, action, name, nameToRequest[name].bundle),
        dynamicOptions
    );

    if (nextDynamicOptions === dynamicOptions) {
        return state;
    }

    return { ...state, dynamicOptions: nextDynamicOptions };
};
