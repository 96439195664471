const escapeValue = value => encodeURI(JSON.stringify(value));

const unescapeValue = escapedValue => JSON.parse(decodeURI(escapedValue));
/**
 *
 * Encodes data to URISearchParams
 * @param paramsObject - paramName->paramValue {number|boolean|object} !Note that strings as param-value are not supported,
 * but objects with string valued fields or string valued arrays can be encoded and decoded.
 * @returns {string}
 */

export const toSearchParam = paramsObject => {
    const params = new URLSearchParams();
    Object.entries(paramsObject).forEach(([param, value]) => {
        params.append(param, escapeValue(value));
    });
    return `?${params}`;
};
/**
 * Decodes back the result of toSearchParam
 * @param search {string} - search params from location
 * @returns paramsObject {object}
 */

export const fromSearchParam = search => {
    const params = new URLSearchParams(search);
    const paramsObject = {};
    Array.from(params.entries()).forEach(([param, escapedValue]) => {
        paramsObject[param] = unescapeValue(escapedValue);
    });
    return paramsObject;
};

export const utoa = str => {
    try {
        return window.btoa(encodeURIComponent(str));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return [];
    }
};

export const atou = str => {
    try {
        return decodeURIComponent(window.atob(str));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return [];
    }
};

const identityMapping = e => e;

export const encodeSearchUrl = (paramsObject, transform = identityMapping) => {
    const params = new URLSearchParams();
    Object.entries(paramsObject).forEach(([param, value]) => {
        params.append(param, transform(value));
    });
    return params;
};

export const decodeSearchUrl = (transform = identityMapping) => {
    const params = new URLSearchParams(window.location.search);
    const paramsObject = {};
    Array.from(params.entries()).forEach(([param, value]) => {
        paramsObject[param] = transform(value);
    });
    return paramsObject;
};
