import { useEffect } from 'react';

const usePrint = processing => {
    useEffect(() => {
        !processing &&
            setTimeout(() => {
                requestAnimationFrame(window.print);
            }, 200);
    }, [processing]);
};

export default usePrint;
