/**
 * Created by Lytov on 23.04.2020.
 */
import { makePrefixAdder } from '../utils/type-utils';

const addPrefixTo = makePrefixAdder('SECONDARY-NAV');
const SET_LINKS = addPrefixTo('SET_LINKS');

export const setSecondaryLinks = links => {
    return { type: SET_LINKS, payload: links };
};

const defaultState = {
    links: []
};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case SET_LINKS:
            return {
                links: action.payload
            };
        default:
            return state;
    }
}

export const selector = state => state.secondaryNav.links;
