import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notify } from 'redux/modules/notifications';
import * as endpoints from 'utils/api/endpoints';
import { httpMethods, useFetchData } from 'utils/hooks/use-fetch-data';
import { selector as productSelector } from 'containers/product-initializer/product-module';
import { PREMIUM, productToPath } from 'containers/product-initializer/products';
import { useHistory } from 'react-router-dom';
import { SCOUT } from '../../../../../../constants/game-types';

export const useFetchLastTeamGame = ({ isSummaryMode, onRedirect }) => {
    const [activeTeamId, setActiveTeamId] = useState(null);
    const [lastTeamData, setLastTeamData] = useState(null); // away_team_acronym_en, away_team_elite_prospects_team_id, away_team_id, away_team_logo_href, away_team_name_en, away_team_short_name_en,  date,  home_team_acronym_en,  home_team_elite_prospects_team_id,  home_team_id,  home_team_logo_href, home_team_name_en, home_team_short_name_en

    const product = useSelector(productSelector);
    const dispatch = useDispatch();
    const history = useHistory();

    const activeSeasonId = lastTeamData?.[0]?.season_id;
    const activeLeagueId = lastTeamData?.[0]?.league_id;
    const activeGameId = lastTeamData?.[0]?.game_id;

    const redirectParams = {
        teamIds: activeTeamId ? [activeTeamId] : [],
        seasonIds: activeSeasonId ? [activeSeasonId] : [],
        leagueIds: activeLeagueId ? [activeLeagueId] : [],
        gameIds: activeGameId ? [activeGameId] : []
    };

    const handleTeamSelect = useCallback(
        id => {
            setActiveTeamId(id);

            if (!isSummaryMode) {
                const redirectUrl = `${
                    [SCOUT].some(el => el === product) ? productToPath[PREMIUM].path : productToPath[product].path
                }/team/${id}`;

                history.push(redirectUrl);
                onRedirect?.();
            }
        },
        [product, history, isSummaryMode, onRedirect]
    );

    const handleReset = useCallback(() => {
        setActiveTeamId(null);
        setLastTeamData(null);
    }, []);

    const handleErrorNotify = useCallback(() => {
        dispatch(notify("There is no information about the team's last game."));
    }, [dispatch]);

    const { processing, error } = useFetchData({
        method: httpMethods.get,
        url: endpoints.teamLastGameClientSearch(activeTeamId),
        initialCondition: !!activeTeamId,
        responseHandler: data => setLastTeamData(data)
    });

    useEffect(() => {
        if (error) {
            //eslint-disable-next-line
            console.error(String(error?.message || error));

            handleReset();

            handleErrorNotify?.();
        }
    }, [error]);

    return {
        processing,
        redirectParams,
        handleTeamSelect,
        handleReset
    };
};
