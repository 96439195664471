import React from 'react';
import styled from 'styled-components/macro';

import ThemeContainer from 'components/theme-container/theme-container';
import AppLayout from 'containers/app/private/layout';
import NavBar from 'containers/app/private/components/nav-bar/nav-bar';
import PageContainer from 'containers/page-container';
import Widget from 'components/widget/widget';
import logoImg from 'containers/app/TPE.svg';
import ErrorBoundary from 'containers/app/private/error-boundary';

const PageWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
`;

const ImgWrap = styled.div`
    display: flex;
    justify-content: center;
    padding: 12px;

    background: #000;
`;

const TextWrap = styled.div`
    padding: 32px;

    text-align: center;
`;

const Disabled = () => (
    <ErrorBoundary noReport>
        <ThemeContainer>
            <AppLayout>
                <NavBar />
                <PageContainer>
                    <PageWrap>
                        <Widget>
                            <ImgWrap>
                                <img alt="" src={logoImg} height={40} />
                            </ImgWrap>
                            <TextWrap>
                                The Prospect Exchange will be upgrading its environments on Tuesday, February 6, 2024
                                between 6am ET and 9am ET.
                                <br />
                                The platform will be unavailable during this time.
                                <br /> We apologize for the inconvenience.
                            </TextWrap>
                        </Widget>
                    </PageWrap>
                </PageContainer>
            </AppLayout>
        </ThemeContainer>
    </ErrorBoundary>
);

export default Disabled;
