import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '../button/button';
import FilterSelectPanel from './filter-select-panel';
import styles from './filter-select.module.css';
import EllipsisText from '../ellipsis-text/ellipsis-text';

const propTypes = {
    /*Filter name*/
    filterName: PropTypes.string.isRequired,
    /*Value*/
    values: PropTypes.array.isRequired,
    /*Options*/
    options: PropTypes.any.isRequired,
    /*Meta options*/
    metaOptions: PropTypes.any,

    /*Value presentation*/
    valuesPresentation: PropTypes.func.isRequired,
    /*Option presentation*/
    optionPresentation: PropTypes.func.isRequired,

    /*Value change callback*/
    onChange: PropTypes.func.isRequired,

    /*Option to unique key mapper*/
    optionKey: PropTypes.func,

    /*Predicate which handle autocomplete filtration*/
    optionFilterPredicate: PropTypes.func,

    /*Predicate for options sorting*/
    optionsSortingPredicate: PropTypes.func,

    /*Values which ignored in selection (special case for lite games)*/
    ignoredValues: PropTypes.array,

    /*Styles*/
    color: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    panelClassName: PropTypes.string,

    /*Flags*/
    autocomplete: PropTypes.bool,
    single: PropTypes.bool,
    singleAutoClosable: PropTypes.bool,
    singleUnselectable: PropTypes.bool,
    hasControls: PropTypes.bool,
    optionsGrouped: PropTypes.bool,
    disabled: PropTypes.bool,
    optionsSorted: PropTypes.bool
};

const defaultProps = {
    color: 'primary',
    autocomplete: false,
    single: false,
    singleAutoClosable: false,
    hasControls: false,
    optionsGrouped: false
};

class FilterSelect extends PureComponent {
    static propTypes = propTypes;
    static defaultProps = defaultProps;

    state = { edit: false };

    toggleBtn = React.createRef();

    toggle = () => {
        const { options, optionsGrouped, fixedGroups } = this.props;
        this.setState(prevState => {
            if (prevState.edit) {
                return { edit: false, storedShape: fixedGroups ? null : { options: [], optionsGrouped: false } };
            } else {
                // save options because they shouldn't be updated until panel with options open
                return { edit: true, storedShape: fixedGroups ? null : { options, optionsGrouped } };
            }
        });
    };

    handleOptionClick = (option, selected) => {
        const { single, onChange, values, optionKey, filterName, singleUnselectable } = this.props;
        const key = optionKey ? optionKey(option) : option;

        if (singleUnselectable && selected) {
            onChange(filterName, [key]);
        } else if (singleUnselectable && !selected) {
            onChange(filterName, []);
        } else if (single) {
            onChange(filterName, [key]);
            this.props.singleAutoClosable && this.toggle();
        } else if (selected) {
            onChange(filterName, [...values, key]);
        } else {
            onChange(filterName, values.filter(v => v !== key));
        }
    };

    handleOptionsSelectAll = options => {
        const { onChange, values, optionKey, filterName } = this.props;
        const keys = optionKey ? options.map(o => optionKey(o)) : options;
        onChange(filterName, [...new Set([...values, ...keys])]);
    };

    handleOptionsDeselectAll = options => {
        const { onChange, values, optionKey, filterName } = this.props;
        const keys = optionKey ? options.map(o => optionKey(o)) : options;
        const deselected = new Set(keys);
        onChange(filterName, values.filter(v => !deselected.has(v)));
    };

    render() {
        const {
            values,
            className,
            labelClassName,
            panelClassName,
            outline,
            valuesPresentation,
            color,
            width,
            display,
            disabled,
            style,
            height,
            ...rest
        } = this.props;

        const { edit, storedShape } = this.state;

        const panelProps = {
            ...rest,
            width,
            display,
            values,
            ...storedShape,
            className: cx(panelClassName, 'fs-panel'),
            onOptionClick: this.handleOptionClick,
            onSelectAll: this.handleOptionsSelectAll,
            onDeselectAll: this.handleOptionsDeselectAll,
            onClickOutside: this.toggle
        };

        //send to valuesPresentation second parameters with total length - for show 'All ... ' label at presentation component
        const totalOptions = Array.isArray(rest.options) ? rest.options.flat().length : null;

        const { filterName } = this.props;

        const isWideFilter = ['LEAGUES', 'SEASONS', 'TEAMS'].some(el => el === filterName);

        return (
            <div
                style={style}
                className={cx(
                    styles.relativeWrapper,
                    {
                        [styles.relativeWrapperWide]: isWideFilter
                    },
                    className
                )}
            >
                <Button
                    color={color}
                    disabled={disabled}
                    className={cx(styles.button, { [styles.hidden]: this.state.edit }, 'fs-label', labelClassName)}
                    innerRef={this.toggleBtn}
                    onClick={this.toggle}
                    outline={outline}
                >
                    <EllipsisText>{valuesPresentation?.(values, totalOptions) ?? '-'}</EllipsisText>
                    <span className={styles.panelOpen}>&#9660;</span>
                </Button>
                {edit && <FilterSelectPanel {...panelProps} />}
            </div>
        );
    }
}

export default FilterSelect;
