import { schema } from 'normalizr';

export const teamSchema = new schema.Entity('teams');

export const gameSchema = new schema.Entity('games', { awayTeam: teamSchema, homeTeam: teamSchema });

export const gamePlayerSchema = new schema.Entity('gamePlayers');

export const playerProfileSchema = new schema.Entity('playerProfiles');

export const seasonSchema = new schema.Entity('seasons');

export const countrySchema = new schema.Entity('countries');

export const leagueSchema = new schema.Entity('leagues');
