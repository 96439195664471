/* eslint-disable eqeqeq */
import { createSelector } from 'reselect';
import { playerIdToPositionPlayerSelector } from '../../../../../containers/widgets-stats/selectors/game-players';
import groupBy from 'lodash/groupBy';
import gameSelector, { activeGameSelector } from '../../../../../containers/widgets-stats/selectors/active-game';
import { getUserProfileTeamId } from '../../../../../selectors/user/user-profiles-selector';
import { playerProspectIntegrationSelector } from '../../../../../redux/modules/prospect-integration';
import { extractedShiftsWithUniteDuplicates } from './shifts-utils';

const shiftsStat = state => {
    return state.widgetsStats.shiftsStatsSingle;
};

export const shiftsSelector = createSelector(
    playerIdToPositionPlayerSelector,
    shiftsStat,
    (playerIdMap, shifts) => {
        if (!playerIdMap || !shifts || playerIdMap.processing || shifts.processing) {
            return { processing: true };
        }

        const outPutShiftsData = shifts.data
            .filter(shift => playerIdMap.data[shift.playerId])
            .map(shift => {
                return {
                    ...shift,
                    pace: (shift.activeDistance / shift.distance) * 100,
                    backcheckPace: (shift.activeBackcheckDistance / shift.backcheckDistance) * 100,
                    ...playerIdMap.data[shift.playerId]
                };
            });

        const outPutUnitedShiftsData = extractedShiftsWithUniteDuplicates(outPutShiftsData);

        return {
            data: [...outPutUnitedShiftsData]
        };
    }
);

export const playerIdToShiftsSelector = createSelector(
    shiftsStat,
    shifts => {
        if (!shifts || shifts.processing) {
            return { processing: true };
        }
        return {
            data: groupBy(shifts.data, shift => shift.playerId)
        };
    }
);

export const getStrengthStatus = (teamPlayersNumber, opponentPlayerNumbers) => {
    if (teamPlayersNumber > opponentPlayerNumbers) {
        return 'PP';
    } else if (teamPlayersNumber < opponentPlayerNumbers) {
        return 'PK';
    } else {
        return 'EV';
    }
};

export const strengthChangeTimesSelector = createSelector(
    state => state.widgetsStats.gameStrengthChangeTimes,
    activeGameSelector,
    getUserProfileTeamId,
    (strengthChangeTimes, game, teamId) => {
        if (!strengthChangeTimes || strengthChangeTimes.processing) {
            return { processing: true };
        }
        return {
            data: strengthChangeTimes.data.map(strengthChangeTime => {
                const teamPlayerNumber =
                    game.homeTeam.id === teamId
                        ? strengthChangeTime.homePlayersNumber
                        : strengthChangeTime.awayPlayersNumber;
                const opponentPlayerNumber =
                    game.homeTeam.id === teamId
                        ? strengthChangeTime.awayPlayersNumber
                        : strengthChangeTime.homePlayersNumber;
                const status = getStrengthStatus(teamPlayerNumber, opponentPlayerNumber);

                return { ...strengthChangeTime, teamPlayerNumber, opponentPlayerNumber, status };
            })
        };
    }
);

export const shiftsPropsSelector = createSelector(
    shiftsSelector,
    (_, props) => props.selectedPlayerIds,
    (_, props) => props.selectedShiftId,
    (shifts, playerIds, shiftId) => {
        if (shifts.processing || !shifts.data || !shifts.data.length) {
            return { processing: true };
        }
        if (!playerIds || !playerIds.length) {
            playerIds = [shifts.data[0].playerId];
        }
        const intervals = [];
        const shiftsData = shifts.data.filter(d => playerIds.indexOf(d.playerId) !== -1);
        if (shiftId) {
            const s = shifts.data.find(d => d.id === shiftId);
            const { startTime, endTime, period } = s;
            intervals.push({ startTime, endTime, period, shiftId });
        } else {
            for (const shiftProps of shiftsData) {
                const { startTime, endTime, period, id } = shiftProps;
                intervals.push({ startTime, endTime, period, shiftId: id });
            }
        }
        return {
            processing: false,
            selectedPlayerIds: playerIds,
            intervals,
            shifts: [...(shiftsData ?? [])].sort((a, b) =>
                a.period !== b.period ? a.period - b.period : a.startTime - b.startTime
            )
        };
    }
);

export const teamIdForShiftsSelector = createSelector(
    (state, props) => gameSelector(state, props),
    state => playerProspectIntegrationSelector(state),
    (gamesInfo, forClientPlayerId) => {
        const { gamePlayers, game, processing } = gamesInfo;

        if (processing || !gamePlayers) {
            return null;
        }

        const gamePlayersAsArray = Object.values(gamePlayers);

        if (!gamePlayersAsArray.find(el => el.playerId === forClientPlayerId)) {
            return null;
        }

        const teamStatus = gamePlayersAsArray.find(el => el.playerId === forClientPlayerId).teamStatus;

        return teamStatus === 'HOME' ? game.homeTeam.id : teamStatus === 'AWAY' ? game.awayTeam.id : null;
    }
);
