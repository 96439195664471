/* eslint-disable eqeqeq */
import { createSelector } from 'reselect';
import { getFormatMessageFunction } from '../../../../../redux/modules/locale';
import { playerIdToFieldPlayerSelector } from '../../../../../containers/widgets-stats/selectors/game-players';
import pageTypes from '../types';
import { teamsPredicateSelector } from '../../../../../selectors/predicates/team-predicates';

const getPageType = (_, props) => props.type;

const getTabIndex = (_, props) => props.chartTab;

function getGamesDuration(periods) {
    let duration = 0;
    for (const gameId in periods) {
        for (const period in periods[gameId]) {
            duration += periods[gameId][period];
        }
    }
    return duration;
}

const durationByPpType = ppType => (duration, interval) =>
    duration + (interval.type == ppType ? interval.endTime - interval.startTime : 0);

const preparePpLines = stats => ({
    ...stats,
    data: {
        ...stats.data,
        lines: stats.data.lines.map((line, i) => {
            const rushTime = line.powerPlayTypes.reduce(durationByPpType('PP_RUSH'), 0);
            const regroupTime = line.powerPlayTypes.reduce(durationByPpType('PP_REGROUP_DZ'), 0);
            const ozNoFormationTime = line.powerPlayTypes.reduce(durationByPpType('PP_OZ_OUT_FORM'), 0);
            const ozFormationTime = line.powerPlayTypes.reduce(durationByPpType('PP_OZ_IN_FORM'), 0);

            return {
                ...line,
                id: i + 1,
                playerIds: line.playerIds,
                rushTime,
                regroupTime,
                ozFormationTime,
                ozNoFormationTime
            };
        })
    }
});

const preparePkLines = stats => ({
    ...stats,
    data: {
        ...stats.data,
        lines: stats.data.lines.map((line, i) => {
            return { ...line, id: i + 1, playerIds: line.playerIds };
        })
    }
});

const stStatsSelectorCreator = (stStatsSelector, prepareLines) =>
    createSelector(
        stStatsSelector,
        playerIdToFieldPlayerSelector,
        (ppStats, playerIdToPlayer) => {
            if (!ppStats || ppStats.processing || !ppStats.data || playerIdToPlayer.processing)
                return { processing: true };
            return {
                ...prepareLines(ppStats, playerIdToPlayer.data)
            };
        }
    );

const ppStatsSelector = stStatsSelectorCreator(state => state.widgetsStats.ppStats, preparePpLines);

const pkStatsSelector = stStatsSelectorCreator(state => state.widgetsStats.pkStats, preparePkLines);

export const getGamesPps = state =>
    state.widgetsStats.gamesPps && !state.widgetsStats.gamesPps.processing
        ? state.widgetsStats.gamesPps
        : { processing: true };

export const getGamesPks = state =>
    state.widgetsStats.gamesPks && !state.widgetsStats.gamesPks.processing
        ? state.widgetsStats.gamesPks
        : { processing: true };

const getPPStats = createSelector(
    ppStatsSelector,
    (state, props) => props && !!props.per2,
    (ppStats, per2) => ({ ...ppStats, per2 })
);

const getPKStats = createSelector(
    pkStatsSelector,
    (state, props) => props && props.type === pageTypes.POWER_PLAY && !!props.per2,
    (ppStats, per2) => ({ ...ppStats, per2 })
);

const getPeriods = state =>
    !state.widgetsStats.periods || state.widgetsStats.periods.processing
        ? { processing: true }
        : state.widgetsStats.periods;

function getInfo(ppStats, periods) {
    if (ppStats.processing || periods.processing) return ppStats;
    return {
        data: /*per2Info(ppStats.data.infoBar, periods, ppStats.per2)*/ {
            ...ppStats.data.infoBar,
            toi: getGamesDuration(periods.data)
        }
    };
}

const getLineId = (state, props) => (props && props.selectedLineId) || null;

function addTotalTime(line) {
    return {
        ...line,
        totalTime: line.rushTime + line.ozNoFormationTime + line.ozFormationTime + line.regroupTime
    };
}

function getChartData(ppStats, pkStats, type, tab, lineId) {
    return type === pageTypes.POWER_PLAY
        ? getPowerPlayChartData(ppStats, lineId)
        : getPenaltyKillChartData(pkStats, lineId, tab);
}

function getPowerPlayChartData(ppStats, lineId) {
    if (ppStats.processing) return { processing: true };
    return {
        data: lineId
            ? addTotalTime(ppStats.data.lines.find(l => l.id === lineId))
            : ppStats.data.lines.reduce((a, d, i, list) => {
                  a.xg = (a.xg || 0) + (d.xg || 0);
                  a.rushTime = (a.rushTime || 0) + (d.rushTime || 0);
                  a.ozNoFormationTime = (a.ozNoFormationTime || 0) + (d.ozNoFormationTime || 0);
                  a.ozFormationTime = (a.ozFormationTime || 0) + (d.ozFormationTime || 0);
                  a.regroupTime = (a.regroupTime || 0) + (d.regroupTime || 0);
                  if (list.length - 1 == i) {
                      a.totalTime = a.rushTime + a.ozNoFormationTime + a.ozFormationTime + a.regroupTime;
                  }
                  return a;
              }, {})
    };
}

function getRushChartData(pkStats, lineId) {
    if (lineId) {
        const line = pkStats.data.lines.find(l => l.id === lineId);
        let rushToOZConversion = 0;
        let rushToRegroupConversion = 0;
        let rushToPKConversion = 0;
        line.powerPlayTypes.forEach((d, i, list) => {
            if (i === list.length - 1 || d.type !== 'PP_RUSH') return;
            const next = list[i + 1];
            if (next.type === 'PP_OZ_OUT_FORM' || next.type === 'PP_OZ_IN_FORM') {
                rushToOZConversion++;
            } else if (next.type.indexOf('REGROUP') !== -1) {
                rushToRegroupConversion++;
            } else if (next.type.indexOf('PK') !== -1) {
                rushToPKConversion++;
            }
        });
        return {
            data: {
                rushToOZConversion,
                rushToRegroupConversion,
                rushToPKConversion,
                total: rushToOZConversion + rushToRegroupConversion + rushToPKConversion
            }
        };
    }
    let rushToOZConversion = 0;
    let rushToRegroupConversion = 0;
    let rushToPKConversion = 0;
    pkStats.data.lines.forEach(line => {
        line.powerPlayTypes.forEach((d, i, list) => {
            if (i === list.length - 1 || d.type !== 'PP_RUSH') return;
            const next = list[i + 1];
            if (next.type === 'PP_OZ_OUT_FORM' || next.type === 'PP_OZ_IN_FORM') {
                rushToOZConversion++;
            } else if (next.type.indexOf('REGROUP') !== -1) {
                rushToRegroupConversion++;
            } else if (next.type.indexOf('PK') !== -1) {
                rushToPKConversion++;
            }
        });
    });
    return {
        data: {
            rushToOZConversion,
            rushToRegroupConversion,
            rushToPKConversion,
            total: rushToOZConversion + rushToRegroupConversion + rushToPKConversion
        }
    };
}

function getEntriesChartData(pkStats, lineId) {
    if (lineId) {
        const line = pkStats.data.lines.find(l => l.id === lineId);
        return {
            data: {
                carries: line.carryInAgainst,
                dumps: line.dumpInAgainst,
                passes: line.passInAgainst,
                total: line.carryInAgainst + line.dumpInAgainst + line.passInAgainst
            }
        };
    }
    const carries = pkStats.data.lines.reduce((a, d) => a + d.carryInAgainst, 0);
    const dumps = pkStats.data.lines.reduce((a, d) => a + d.dumpInAgainst, 0);
    const passes = pkStats.data.lines.reduce((a, d) => a + d.passInAgainst, 0);
    return {
        data: {
            carries,
            dumps,
            passes,
            total: carries + dumps + passes
        }
    };
}

function getPenaltyKillChartData(pkStats, lineId, tab) {
    if (pkStats.processing) return { processing: true };
    return tab === 0 ? getRushChartData(pkStats, lineId) : getEntriesChartData(pkStats, lineId);
}

function getLineEvents(ppStats) {
    if (ppStats.processing) return { processing: true };
    return {
        data: ppStats.data.lines
            .reduce((a, line) => {
                line.powerPlayTypes.forEach(d => {
                    a.push({
                        ...d,
                        lineId: line.id
                    });
                });
                return a;
            }, [])
            .sort((a, b) => (a.period != b.period ? a.period - b.period : a.startTime - b.startTime))
    };
}

export const lineEventsSelector = createSelector(
    getPPStats,
    getLineEvents
);

export const linesPKSelector = createSelector(
    getPKStats,
    getLineEvents
);

export const chartDataSelector = createSelector(
    getPPStats,
    getPKStats,
    getPageType,
    getTabIndex,
    getLineId,
    getChartData
);

const ppInfoSelector = createSelector(
    getPPStats,
    getPeriods,
    getInfo
);
const pkInfoSelector = createSelector(
    getPKStats,
    getPeriods,
    getInfo
);

export const infoSelector = (state, props) =>
    props.type === pageTypes.PENALTY_KILL ? pkInfoSelector(state, props) : ppInfoSelector(state, props);

const normalizeAttrs = (stat, attrs, normalizeFunc) => {
    const normalized = {};
    attrs.forEach(attr => (normalized[attr] = normalizeFunc(stat[attr])));
    return normalized;
};

const stLineStatsSelectorCreator = (stStatsSelector, normalizedAttrs) =>
    createSelector(
        stStatsSelector,
        playerIdToFieldPlayerSelector,
        getFormatMessageFunction,
        (state, props) => props && !!props.per2,
        (stStats, playerIdToPlayer, formatMessage, per2) => {
            if (stStats.processing || playerIdToPlayer.processing) return { processing: true, data: [] };

            const lineStats = stStats.data.lines;

            const data = lineStats.map(stat => {
                const players = stat.playerIds.map(playerId => playerIdToPlayer.data[playerId]);
                const linePresentation = players
                    .map(player => formatMessage({ id: 'entities.player.lastName', values: player.profile }))
                    .join(' / ');
                return {
                    ...stat,
                    ...normalizeAttrs(stat, normalizedAttrs, val => /*formatStat(per2, stat.toi, */ val /*)*/),
                    linePresentation
                };
            });

            return { processing: false, data };
        }
    );

export const ppLineStatsSelector = stLineStatsSelectorCreator(ppStatsSelector, [
    'shotsOnGoal',
    'shotsMissed',
    'shotsBlocked',
    'scoringChancesFor'
]);

export const pkLineStatsSelector = stLineStatsSelectorCreator(pkStatsSelector, [
    'crossIcePasses',
    'shotsAgainst',
    'scoringChancesAgainst',
    'reboundRecoveriesFor'
]);

const unfilteredPlayerStatsSelectorCreator = (stStatsSelector, normalizedAttrs) =>
    createSelector(
        stStatsSelector,
        playerIdToFieldPlayerSelector,
        (state, props) => props && !!props.per2,
        (stStats, playerIdToPlayer, per2) => {
            if (stStats.processing || playerIdToPlayer.processing) return { processing: true, data: [] };

            const playerStats = stStats.data.players;

            const data = playerStats
                .filter(stat => !!playerIdToPlayer.data[stat.playerId])
                .map(stat => ({
                    ...stat,
                    ...playerIdToPlayer.data[stat.playerId],
                    ...normalizeAttrs(
                        stat,
                        normalizedAttrs,
                        val => /*formatStat(per2, stat.toi || stat.onIceTime,*/ val /*)*/
                    )
                }));

            return { processing: false, data };
        }
    );

const unfilteredPpPlayerStatsSelector = unfilteredPlayerStatsSelectorCreator(ppStatsSelector, [
    'shotsOnGoal',
    'shotsMissed',
    'shotsBlocked',
    'scoringChances',
    'scoringChancesFor',
    'shotAttemptsFor',
    'successZoneEntries',
    'reboundRecoveries',
    'giveaways',
    'possessionCount'
]);

const unfilteredPkPlayerStatsSelector = unfilteredPlayerStatsSelectorCreator(pkStatsSelector, [
    'loosePuckRecoveries',
    'reboundRecoveriesPersonal',
    'clearances',
    'dzPossessions',
    'blockedShots',
    'potentiallyBlockedShots',
    'corsiAgainst',
    'scoringChancesAgainst',
    'crossIcePassesAgainst'
]);

const selectedStLineCreator = stLineStatsSelector =>
    createSelector(
        stLineStatsSelector,
        (state, props) => props.selectedLineId,
        (lines, lineId) => {
            return lines.processing ? null : lines.data.find(l => l.id == lineId);
        }
    );

export const selectedPpLine = selectedStLineCreator(ppLineStatsSelector);
export const selectedPkLine = selectedStLineCreator(pkLineStatsSelector);

const stPlayerStatsSelectorCreator = (unfilteredPlayerStatsSelector, selectedLine) =>
    createSelector(
        unfilteredPlayerStatsSelector,
        selectedLine,
        (playerStats, line) => {
            if (!line || playerStats.processing) {
                return playerStats;
            }

            const playerIds = new Set(line.playerIds);
            return { processing: false, data: playerStats.data.filter(ps => playerIds.has(ps.playerId)) };
        }
    );

export const ppPlayerStatsSelector = stPlayerStatsSelectorCreator(unfilteredPpPlayerStatsSelector, selectedPpLine);

export const pkPlayerStatsSelector = stPlayerStatsSelectorCreator(unfilteredPkPlayerStatsSelector, selectedPkLine);

/**
 * check that shot was in pp interval
 */
const shotInStInterval = shot => st => {
    const gameIdsEquals = shot.gameId == st.gameId;
    const periodsEquals = shot.period == st.period;
    const timeInInterval = st.startTime < shot.time && st.endTime >= shot.time;
    return gameIdsEquals && periodsEquals && timeInInterval;
};

const stShotsSelectorCreator = (statsStsSelector, filterStsSelector) =>
    createSelector(
        statsStsSelector,
        filterStsSelector,
        teamsPredicateSelector,
        (gamesSts, filterSts, teamPredicate) => {
            if (!gamesSts || gamesSts.processing) return { processing: true };

            const allStShots = gamesSts.data.filter(st => teamPredicate(st.teamId));
            let finalStShotsList = [];
            if (filterSts && filterSts?.length !== allStShots?.length) {
                filterSts.forEach(el => {
                    const spitGameIdAndIndex = el.split(',');
                    const gameId = spitGameIdAndIndex[0];
                    const stGameIndex = spitGameIdAndIndex[1];
                    const st = allStShots.find(st => st.gameId === Number(gameId) && st.index === Number(stGameIndex));
                    if (st) {
                        finalStShotsList.push(st);
                    }
                });
            } else {
                finalStShotsList = [...allStShots];
            }
            return { stShotFilter: shot => finalStShotsList.some(shotInStInterval(shot)) };
        }
    );

export const ppShotsSelector = stShotsSelectorCreator(
    state => state.widgetsStats.gamesPps,
    state => state.statsFilter.gamesSts
);

export const pkShotsSelector = stShotsSelectorCreator(
    state => state.widgetsStats.gamesPks,
    state => state.statsFilter.gamesSts
);
