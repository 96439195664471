import axios from 'axios';
import { createSelector } from 'reselect';
import { AUTH_TOKEN_NAME, getAuthKey } from '../auth-utils';

function handleArrayParam([paramName, paramValue]) {
    return Array.isArray(paramValue)
        ? paramValue.map(value => `${paramName}=${encodeURIComponent(value)}`).join('&')
        : `${paramName}=${encodeURIComponent(paramValue)}`;
}

function paramsSerializer(params) {
    return Object.entries(params)
        .map(handleArrayParam)
        .join('&');
}

export const apiBuilder = createSelector(
    getAuthKey,
    key =>
        axios.create({
            headers: { 'X-Requested-With': 'XMLHttpRequest', [AUTH_TOKEN_NAME]: key },
            paramsSerializer
        })
);

export default axios.create({
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    paramsSerializer
});
