export const fieldTypes = {
    select: 'select',
    reactSelect: 'react-select',
    text: 'text',
    date: 'date',
    dateRange: 'dateRange',
    time: 'time',
    dateTime: 'dateTime',
    checkBox: 'checkBox',
    textarea: 'textarea',
    splitDateTime: 'splitDateTime',
    searchableList: 'searchableList'
};
