export const statPercentage = (stat, statSum) => {
    return !statSum ? '-' : ((stat / statSum) * 100).toFixed(0);
};

export const transformTime = t => {
    const minutes = Math.floor(t / 600);
    const secondDecimals = Math.floor((t - minutes * 600) / 100);
    const secondUnits = Math.floor((t - minutes * 600 - secondDecimals * 100) / 10);
    return `${minutes}:${secondDecimals}${secondUnits}`;
};

export const statPerTwo = (stat, toi) => (toi ? (stat * 2 * 60 * 10) / toi : '-');
