/**
 * Created by Lytov on 11.14.2018.
 *
 * Стилизованная форма ввода времени
 */
import React, { Component } from 'react';
import Input from './input.js';
import { convertFromDs, convertToDs } from '../../utils/helpers/date-utils';
import styles from './input-time.module.css';
import Icon from '../icon/index';

const timeUnits = {
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds'
};

const isPositiveNumber = value => Number.isFinite(value) && value > 0;
const maxSeconds = 60 - 0.1;
const maxMinutes = 60 - 1;
const maxHours = 24 - 1;

const formatTime = (value, timeUnit, restrict) => {
    if (!isPositiveNumber(value)) return 0;
    switch (timeUnit) {
        case timeUnits.seconds:
            return restrict ? Math.min(value, maxSeconds) : value;
        case timeUnits.minutes:
            return restrict ? Math.min(Math.floor(value), maxMinutes) : Math.floor(value);
        case timeUnits.hours:
            return restrict ? Math.min(Math.floor(value), maxHours) : Math.floor(value);
        default:
    }
};

export default class InputTime extends Component {
    onChange = e => {
        const { onChange, value = 0, restrictHours } = this.props;
        const timeUnit = e.target.name;
        const fieldValue = formatTime(+e.target.value, timeUnit, timeUnit !== timeUnits.hours || restrictHours);

        const time = convertFromDs(value);
        const deciseconds = convertToDs({ ...time, [timeUnit]: fieldValue });

        onChange(deciseconds);
    };

    onClear = () => {
        this.props.onChange(null);
    };

    render() {
        const { disabled, hideClear, hideSeconds, hideHours, value, className, style } = this.props;
        const time = convertFromDs(value);

        return (
            <div className={styles.inputTime} style={style}>
                {!hideHours && (
                    <>
                        <Input
                            name={timeUnits.hours}
                            value={`${time.hours}`}
                            onChange={this.onChange}
                            type="number"
                            placeholder="hh"
                            className={className}
                            style={{ height: '100%' }}
                            disabled={disabled}
                        />
                        <div className={styles.separator}>:</div>
                    </>
                )}
                <Input
                    name={timeUnits.minutes}
                    value={`${time.minutes}`}
                    onChange={this.onChange}
                    type="number"
                    placeholder="mm"
                    className={className}
                    style={{ height: '100%' }}
                    disabled={disabled}
                />
                {!hideSeconds && (
                    <>
                        <div className={styles.separator}>:</div>
                        <Input
                            name={timeUnits.seconds}
                            value={`${time.seconds}`}
                            onChange={this.onChange}
                            type="number"
                            placeholder="ss"
                            className={className}
                            style={{ height: '100%' }}
                            disable={disabled}
                        />
                    </>
                )}

                {!hideClear && (
                    <div className={styles.clear} onClick={this.onClear}>
                        <Icon type="trash" />
                    </div>
                )}
            </div>
        );
    }
}
