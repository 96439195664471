/**
 * Important: each array should contains different elements
 *
 * @return {Boolean} true if arrays contains same values
 */
export const hasSameValues = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    if (arr1 === arr2) return true;
    const arr1Values = new Set(arr1);
    return arr1.length === arr2.length && arr2.every(value => arr1Values.has(value));
};

/**
 * @param values array of single element
 * @return first element of array
 * @throws Error if `values` is not an array or `values` length is not 1
 */
export const unboxSingleValueArray = values => {
    if (!Array.isArray(values) || values.length !== 1) {
        throw new Error('Array should contains single value');
    }

    return values[0];
};

/**
 * ATTENTION! Do not use this function in new code. It uses now only in expressions like:
 * `field ? safeFind(items, item => item[fieldName] === field, item => item[fieldName], emptyField) : emptyField`
 *
 * @deprecated use Array#find instead or more specific function
 *
 * @param {Array} values
 * @param {Function} predicate
 * @param {Function} mapper
 * @param defaultValue
 * @returns defaultValue, if find return `undefined` or `values` is not array, and `selector(foundValue)` otherwise
 */
export const safeFind = (values, predicate, mapper, defaultValue) => {
    if (!Array.isArray(values)) {
        return defaultValue;
    }

    const foundValue = values.find(predicate);

    return foundValue === undefined ? defaultValue : mapper(foundValue);
};

export const putFirst = (arr, predicate) => {
    const passed = arr.filter(predicate);
    const rest = arr.filter(el => !predicate(el));
    return [...passed, ...rest];
};

export const assignObjects = objList => Object.assign(...objList);
