import { makePrefixAdder } from '../utils/type-utils';
import { getRolledBackPages, setRolledBackPages } from '../../utils/helpers/storages-utils';

const addPrefixTo = makePrefixAdder('VERSION_SWITCHER');
const ROLLBACK_VERSION = addPrefixTo('ROLLBACK_VERSION');
const RETURN_NEW_VERSION = addPrefixTo('RETURN_NEW_VERSION');

export const makeRollbackVersion = (featureName = window.location.pathname) => ({
    type: ROLLBACK_VERSION,
    payload: featureName
});
export const makeReturnNewVersion = (featureName = window.location.pathname) => ({
    type: RETURN_NEW_VERSION,
    payload: featureName
});

const defaultState = {
    rolledBackPages: getRolledBackPages()
};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case ROLLBACK_VERSION: {
            const newState = {
                rolledBackPages: [...state.rolledBackPages.filter(p => p !== action.payload), action.payload]
            };
            setRolledBackPages(newState.rolledBackPages);
            return newState;
        }
        case RETURN_NEW_VERSION: {
            const newState = {
                rolledBackPages: state.rolledBackPages.filter(p => p !== action.payload)
            };
            setRolledBackPages(newState.rolledBackPages);
            return newState;
        }
        default:
            return state;
    }
}

const selector = state => state.versionSwitcher.rolledBackPages;
export const isRolledBack = (state, featureName = window.location.pathname) => selector(state).includes(featureName);
