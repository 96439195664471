import React, { useEffect, useState } from 'react';
import { BadLink } from '../../components/error/error';
import { decodeSearchUrl, encodeSearchUrl } from '../../utils/api/path-utils';
import request from '../../utils/api/common-requests/common-requests';
import Loading from '../../components/loading/loading';
import { setupAuthKey } from '../../utils/api/auth-utils';
import styled from 'styled-components/macro';
import FormattedMessage from '../../components/formatted-message';
import Button from '../../components/button/button';
import Action from '../../containers/rest-query/action';
import * as endpoints from '../../utils/api/endpoints';

const resendQuery = email => ({
    method: 'POST',
    url: endpoints.resendConfirmationEmail,
    params: { email }
});

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 48px - 48px);
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 500px;
    height: 300px;
    padding: 20px 70px;

    text-align: center;

    background-color: var(--backgroundColorSecondary);
`;

const EmailConfirmation = () => {
    const [processing, setProcessingStatus] = useState(true);
    const [error, setError] = useState(null);

    const { login, token } = decodeSearchUrl();

    useEffect(() => {
        if (!login || !token) return;
        request
            .post(endpoints.confirmEmailAndLogin, encodeSearchUrl({ login, token }))
            .then(response => {
                setupAuthKey(response.data.xauthToken || response.data.xAuthToken, true);
                window.location.assign('/');
                setProcessingStatus(false);
            })
            .catch(error => {
                setProcessingStatus(false);
                setError(error);
            });
    }, [login, token]);

    if (login && !token) {
        return (
            <Container>
                <Wrapper>
                    <b>
                        <FormattedMessage id="confirmation.thankYou" />
                    </b>
                    <FormattedMessage id="confirmation.checkEmail" />
                    <Action query={resendQuery} variables={login}>
                        {({ performAction, processing }) => (
                            <Button outline disabled={processing} onClick={performAction}>
                                <FormattedMessage id="confirmation.resend" />
                            </Button>
                        )}
                    </Action>
                    <FormattedMessage id="confirmation.checkSpam" />
                    <a href="mailto:tpeteamsupport@theprospectexchange.com">tpeteamsupport@theprospectexchange.com</a>
                </Wrapper>
            </Container>
        );
    }

    if (!login || !token || error) {
        return <BadLink />;
    }

    if (processing) {
        return <Loading />;
    }

    return null;
};

export default EmailConfirmation;
