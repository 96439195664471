import styled from 'styled-components/macro';

export const TemporaryUnavailablePageWrapper = styled('section')`
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    min-height: calc(100vh - 2 * 48px);
    margin: 0;
    padding: 0;

    user-select: none;
`;

const unavailable = styled('div')`
    color: #2967b0;
    font-weight: bold;
    letter-spacing: 0.15rem;
`;

export const TemporaryUnavailableTitle = styled(unavailable)`
    font-size: 2rem;
`;

export const TemporaryUnavailableText = styled(unavailable)`
    font-size: 0.9rem;
`;
