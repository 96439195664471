import { useEffect } from 'react';
/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideClick({ ref, callback }) {
    useEffect(
        () => {
            /**
             * Callback if clicked on outside of element
             */
            const handleClickOutside = event => {
                if (ref.current && !ref.current.contains(event.target) && callback) {
                    document.removeEventListener('mousedown', handleClickOutside);
                    callback();
                }
            };

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        },
        /*eslint-disable*/ [callback, ref] /*eslint-enable*/
    );
}
