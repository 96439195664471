import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import Button from '../../components/button/button';
import { LicenseDeclined } from '../../components/error/error';
import Action from '../../containers/rest-query/action';
import * as endpoints from '../../utils/api/endpoints';
import { LicenseContent } from './license-content';
import { hasAuthKey } from '../../utils/api/auth-utils';
import logoImg from './TPE.svg';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 96px);
`;

const Layout = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-rows: 1fr 50px;
    width: 700px;
    height: 90%;
    padding: 40px;

    border-radius: 4px;

    background-color: var(--backgroundColorSecondary);

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
    grid-row: ${props => (props.buttons ? 'initial' : '1/3')};
    overflow-y: scroll;
`;

const ButtonsContainer = styled.div`
    display: flex;
    margin-left: auto;
`;

const Clickable = styled.div`
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
`;

const ClickableText = styled.span`
    padding-left: 20px;
`;

const acceptQuery = {
    method: 'PUT',
    url: endpoints.acceptLicense
};

const LicensePage = ({ location }) => {
    const { from } = location.state || { from: '/' };
    const [declined, setDeclined] = useState(false);
    const [isAuth, setAuth] = useState(false);
    const onDecline = useCallback(() => setDeclined(true), [setDeclined]);

    const history = useHistory();

    const onSuccessfulAccepting = () => {
        window.location.assign(from);
    };

    useEffect(() => {
        setAuth(hasAuthKey());
    }, []);

    if (declined) {
        return <LicenseDeclined />;
    }

    return (
        <Container>
            <Layout>
                <Content buttons={!isAuth}>
                    <LicenseContent />
                </Content>
                {isAuth ? (
                    <Action query={acceptQuery} onSuccess={onSuccessfulAccepting}>
                        {({ performAction, processing }) => (
                            <ButtonsContainer>
                                <Button
                                    style={{
                                        width: '120px',
                                        height: '50px',
                                        background: '#009ece'
                                    }}
                                    isLoading={processing}
                                    onClick={performAction}
                                >
                                    Accept
                                </Button>
                                <div style={{ width: '20px' }} />
                                <Button
                                    style={{
                                        width: '120px',
                                        height: '50px',
                                        background: '#868686'
                                    }}
                                    isLoading={processing}
                                    onClick={onDecline}
                                >
                                    Decline
                                </Button>
                            </ButtonsContainer>
                        )}
                    </Action>
                ) : (
                    <Button>
                        <Clickable
                            onClick={() => {
                                history.push('/login');
                            }}
                        >
                            <img alt="" src={logoImg} height="20" />
                            <ClickableText>BACK TO LOGIN PAGE</ClickableText>
                        </Clickable>
                    </Button>
                )}
            </Layout>
        </Container>
    );
};

export default LicensePage;
