import React, { useState, useRef, useEffect, useCallback } from 'react';

import { httpMethods, useFetchData } from 'utils/hooks/use-fetch-data.js';
import * as endpoints from 'utils/api/endpoints';
import { ReleaseNoteWrapper, IconContainer } from './release-note-styled-components.js';
import BellIcon from 'components/svg-icons/bell-icon.js';
import ReleaseNoteModel from './release-note-model.js';

const ReleaseNote = () => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const [notificationProcessing, setNotificationProcessing] = useState(false);
    const [releaseData, setReleaseData] = useState(null);
    const dropdownRef = useRef(null);
    const profilePanelRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpened(!isDropdownOpened);
    };

    const handleClickOutside = useCallback(
        e => {
            if (
                e.target !== dropdownRef.current &&
                !dropdownRef.current?.contains(e.target) &&
                e.target !== profilePanelRef.current &&
                !profilePanelRef.current?.contains(e.target)
            ) {
                toggleDropdown();
            }
        },
        [dropdownRef, profilePanelRef, toggleDropdown]
    );

    useEffect(() => {
        if (isDropdownOpened) {
            if ('ontouchstart' in window) {
                document.addEventListener('touchstart', handleClickOutside, false);
            } else {
                document.addEventListener('click', handleClickOutside, false);
            }
        } else {
            if ('ontouchstart' in window) {
                document.removeEventListener('touchstart', handleClickOutside, false);
            } else {
                document.removeEventListener('click', handleClickOutside, false);
            }
        }

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isDropdownOpened, handleClickOutside]);

    const handleReleaseNoteResponse = useCallback(
        data => {
            if (!data) {
                return;
            }
            setReleaseData(data);
        },
        [setReleaseData]
    );

    //Get Latest Release Note
    useFetchData({
        method: httpMethods.get,
        url: endpoints.getLastReleases,
        initialCondition: true,
        processingSetter: setNotificationProcessing,
        responseHandler: handleReleaseNoteResponse
    });

    if (notificationProcessing) {
        return null;
    }

    return (
        <ReleaseNoteWrapper>
            <div ref={profilePanelRef}>
                <IconContainer onClick={toggleDropdown}>
                    <BellIcon />
                </IconContainer>
            </div>
            {isDropdownOpened && <ReleaseNoteModel ref={dropdownRef} releaseData={releaseData} />}
        </ReleaseNoteWrapper>
    );
};

export default ReleaseNote;
