import React, { Component } from 'react';
import { appendScript, isAnyScriptPresentOnDocument } from '../../utils/append-script-utils';
import PropTypes from 'prop-types';

const RECAPTCHA_SCRIPT_URL = 'https://www.google.com/recaptcha/api.js';
const RECAPTCHA_SCRIPT_REGEX = /(http|https):\/\/(www)?.+\/recaptcha/;
export const recaptchaKey = '6LdUqw0pAAAAADXted4dwYz5LwBjFZu5QfVcPbxS';
export const CHECK_RECAPTCHA = true;

//If user input this values to username - login button will be disconnected from captcha result
export const CHINA_USERNAMES = ['CIHANHC', 'MoCapI', 'Ryan_Demo', 'EBELdemo'];

class Recaptcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            instanceId: null,
            ready: false,
            rendered: false,
            invisible: this.props.size === 'invisible',
            timer: null
        };
    }

    _isAvailable = () => Boolean(window && window.grecaptcha && window.grecaptcha.ready);

    _inject = () => {
        if (this.props.inject && !isAnyScriptPresentOnDocument(RECAPTCHA_SCRIPT_REGEX)) {
            const hlParam = this.props.hl ? `&hl=${this.props.hl}` : '';
            const src = `${RECAPTCHA_SCRIPT_URL}?render=explicit${hlParam}`;
            appendScript(src);
        }
    };

    _prepare = () => {
        const { explicit, onLoad } = this.props;

        window.grecaptcha.ready(() => {
            this.setState({ ready: true }, () => {
                if (!explicit) {
                    this.renderExplicitly();
                }
                if (onLoad) {
                    onLoad();
                }
            });
        });
    };

    _renderRecaptcha = (container, config) => window.grecaptcha.render(container, config);

    _resetRecaptcha = () => {
        if (!window.grecaptcha) {
            return;
        }
        window.grecaptcha.reset(this.state.instanceId);
    };

    _executeRecaptcha = () => window.grecaptcha.execute(this.state.instanceId);

    _getResponseRecaptcha = () => window.grecaptcha.getResponse(this.state.instanceId);

    _stopTimer = () => {
        if (this.state.timer) {
            clearInterval(this.state.timer);
        }
    };

    componentDidMount = () => {
        this._inject();

        if (this._isAvailable()) {
            this._prepare();
        } else {
            const timer = setInterval(() => {
                if (this._isAvailable()) {
                    this._prepare();
                    this._stopTimer();
                }
            }, 500);
            this.setState({ timer });
        }
    };

    shouldComponentUpdate = nextProps => this.props.className !== nextProps.className || !this.state.rendered;

    componentWillUnmount = () => {
        this._stopTimer();

        if (this.state.rendered) {
            this._resetRecaptcha();
        }
    };

    renderExplicitly = () => {
        return new Promise((resolve, reject) => {
            if (this.state.rendered) {
                return reject(new Error('This recaptcha instance has been already rendered.'));
            }
            if (this.state.ready && this.container) {
                const instanceId = this._renderRecaptcha(this.container, {
                    sitekey: this.props.recaptchaKey,
                    theme: this.props.theme,
                    size: this.props.size,
                    badge: this.state.invisible ? this.props.badge : null,
                    tabindex: this.props.tabindex,
                    callback: this.props.onVerify,
                    'expired-callback': this.props.onExpire,
                    'error-callback': this.props.onError,
                    isolated: this.state.invisible ? this.props.isolated : null,
                    hl: this.state.invisible ? null : this.props.hl
                });

                this.setState(
                    {
                        instanceId,
                        rendered: true
                    },
                    () => {
                        if (this.props.onRender) {
                            this.props.onRender();
                        }
                        resolve();
                    }
                );
            } else {
                return reject(new Error('Recaptcha is not ready for rendering yet.'));
            }
        });
    };

    reset = () => {
        return new Promise((resolve, reject) => {
            if (this.state.rendered) {
                this._resetRecaptcha();
                return resolve();
            }
            reject(new Error('This recaptcha instance did not render yet.'));
        });
    };

    execute = () => {
        return new Promise((resolve, reject) => {
            if (!this.state.invisible) {
                return reject(new Error('Manual execution is only available for invisible size.'));
            }
            if (this.state.rendered) {
                this._executeRecaptcha();
                resolve();
            }
            return reject(new Error('This recaptcha instance did not render yet.'));
        });
    };

    getResponse = () => {
        return new Promise((resolve, reject) => {
            if (this.state.rendered) {
                const response = this._getResponseRecaptcha();
                return resolve(response);
            }
            reject(new Error('This recaptcha instance did not render yet.'));
        });
    };

    render = () => {
        const container = <div id={this.props.id} className={this.props.className} ref={e => (this.container = e)} />;

        return this.props.children
            ? this.props.children({
                  renderExplicitly: this.renderExplicitly,
                  reset: this.reset,
                  execute: this.execute,
                  getResponse: this.getResponse,
                  recaptchaComponent: container
              })
            : container;
    };
}

export default Recaptcher;

Recaptcher.defaultProps = {
    id: '',
    className: 'g-recaptcha',
    theme: 'light',
    size: 'normal',
    badge: 'bottomright',
    tabindex: 0,
    explicit: false,
    inject: true,
    isolated: false,
    hl: ''
};

Recaptcher.propTypes = {
    recaptchaKey: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onLoad: PropTypes.func,
    onVerify: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onExpire: PropTypes.func,
    isolated: PropTypes.bool.isRequired
};
