import React from 'react';

import { SearchButton, StyledInput, StyledSearchIcon, IconSearchWrap, InputWrap } from '../top-search-panel.styled';

export const SearchField = props => (
    <>
        {props.isMobile ? (
            <SearchButton onClick={props.onClick}>
                <StyledSearchIcon />
            </SearchButton>
        ) : (
            <InputWrap>
                <StyledInput limited readOnly placeholder="Search" value={props.value} onClick={props.onClick} />
                <IconSearchWrap>
                    <StyledSearchIcon />
                </IconSearchWrap>
            </InputWrap>
        )}
    </>
);
