/* eslint-disable */

import React from 'react';

const russianDictionary = {
    /***********************************************Entities***************************************************************/
    'entities.player.fullName':
        '${!values ? "-" : values.firstNameRu&&values.firstNameRu.length>0 ? values.firstNameRu[0] : values.firstNameEn&&values.firstNameEn.length>0 ? values.firstNameEn[0] : ""}. ${!values ? :values.lastNameRu ? values.lastNameRu : values.lastNameEn ? values.lastNameEn : ""}',
    'entities.player.fullNameLong':
        '${values.firstNameRu&&values.firstNameRu.length>0 ? values.firstNameRu : values.firstNameEn ? values.firstNameEn : ""} ${values.lastNameRu ? values.lastNameRu : values.lastNameEn ? values.lastNameEn : ""}',
    'entities.player.fullNameInverted':
        '${values&&values.lastNameRu&&values.lastNameRu.length>0 ? values.lastNameRu : values&&values.lastNameEn ? values.lastNameEn : "" } ${values && values.firstNameRu&&values.firstNameRu.length>0 ? values.firstNameRu[0] : values && values.firstNameEn ? values.firstNameEn[0] : ""}.',
    'entities.player.longName':
        '{lastNameRu && lastNameRu.length>0 ? lastNameRu : lastNameEn ? lastNameEn : ""} {firstNameRu&&firstNameRu.length>0 ? firstNameRu : firstNameEn ? firstNameEn : ""}',
    'entities.player.lastName': '{lastNameRu&&lastNameRu.length>0?lastNameRu:lastNameEn?lastNameEn:""   }',
    'entities.player.lastNameWithInitial':
        '{lastNameRu&&lastNameRu.length>0?lastNameRu:lastNameEn?lastNameEn:""} ${values.firstNameRu&&values.firstNameRu.length>0 ? values.firstNameRu[0] : values.firstNameEn&&values.firstNameEn.length>0?values.firstNameEn[0]: ""}.',
    'entities.team.name': '{nameRu}',
    'entities.team.acronym': '{acronymRu}',
    'entities.season': '{nameRu}',
    'entities.league': '{nameRu}',
    'entities.team': '{nameRu}',
    'entities.country': '{nameRu}',
    'entities.scout': '{name}',
    'game.winType': '{ winType, select, OT {OT} other {Б} }',
    brand: 'TPE',

    SCOUT: 'Скаут',

    /***********************************************Components*************************************************************/
    //sign-up
    'signUp.title': 'Регистрация игрока',
    'signUp.button': 'Создать аккаунт',
    'signUp.firstName': 'Имя',
    'signUp.lastName': 'Фамилия',
    'signUp.email': 'Email',
    'signUp.password': 'Пароль',
    'signUp.haveAnAccount': 'Уже есть аккаунт?',
    'signUp.loginHere': 'Войдите здесь',
    'signUp.BySingingUpYouAgreeToOur': 'Проходя регистрацию, вы принимаете',
    'signUp.termsAndConditions': 'Условия соглашения',
    'signUp.error': 'Ошибка на сервере',
    'signUp.emailExists': 'Email занят',
    //login
    'login.title': 'Войдите',
    'login.button': 'Войти',
    'login.error': 'Неверный логин или пароль',
    'login.username': 'Логин',
    'login.email': 'Электронная почта',
    'login.password': 'Пароль',
    'login.remember': 'Запомнить меня',

    'login.attempts': 'До наступления временной блокировки осталось попыток: {attemptCount}.',
    'login.waitSec': 'До окончания временной блокировки попыток авторизации пожалуйста подождите {waitSec} с. .',

    'resetPassword.title': 'Сбросить пароль',
    'resetPassword.login': 'Логин (email)',
    'resetPassword.button': 'Сбросить',
    'resetPassword.emailNotification': 'Вам на почту отправленно письмо для сброса пароля',
    'resetPassword.successNotification': 'Пароль успешно изменен',
    'resetPassword.newPassword': 'Новый пароль',
    //confirmation
    'confirmation.thankYou': 'Спасибо!',
    'confirmation.checkEmail':
        'Пожалуйста, перейдите по ссылке, которую мы отправили вам на почту, для подтверждения аккаунта',
    'confirmation.resend': 'Выслать повторно',
    'confirmation.checkSpam':
        'Если письмо не пришло, пожалуйста, проверьте папку со спамом и все прочие папки. Если письмо не дошло, свяжитесь с поддержкой:',

    //dashboard
    home: 'Главная',
    dashboard: 'Аналитика',
    'dashboard.summary': 'Резюме игры',
    'dashboard.skaters': 'Игроки',
    'dashboard.powerPlay': 'Большинство',
    'dashboard.penaltyKill': 'Меньшинство',
    'dashboard.statistics': 'Статистика',
    'dashboard.deprecated': 'Старая версия',
    'dashboard.gameScore': 'Оценка игры',
    'dashboard.configurator': 'Конфигуратор',
    'dashboard.playersPage': 'Страница игрока',
    'dashboard.collapse': 'Свернуть',
    'dashboard.schedule': 'Расписание',
    'dashboard.conservator': 'Плейлисты',
    'dashboard.prescout': 'Пре-скаут',
    'dashboard.superLite': 'Супер-лайт',
    'dashboard.playersComparison': 'Сравнение игроков',
    'dashboard.scout': 'Scout',
    'dashboard.scout.teamSelect': 'Команды',
    'dashboard.players': 'Игроки',
    'dashboard.favorites': 'Фавориты',
    'dashboard.reports': 'Отчеты',
    'dashboard.playlists': 'Плейлисты',
    playerSelect: 'Select Player',
    //widget
    'widget.viewDetails': 'Подробнее',
    //event-list
    'eventList.goals': 'Голы',
    'eventList.scoreAttempts': 'Броски',
    'eventList.carriesIn': 'Входы с контролем',
    'eventList.dumpsIn': 'Входы без контроля',
    'eventList.carriesOut': 'Выходы с контролем',
    'eventList.dumpsOut': 'Выходы без контроля',
    'eventList.scoringChances': 'Голевые моменты',
    'eventList.blockedShots': 'Заблокированные броски',
    'eventList.passes': 'Передачи',
    'eventList.recovery': 'Подборы',
    'eventList.takeaway': 'Перехваты',
    'eventList.giveaway': 'Потери',
    'eventList.faceoff': 'Вбрасывания',
    'eventList.shotOnGoal': 'Броски в створ',
    'eventList.shifts': 'Смены',
    'eventList.rush': 'Развитие атаки',
    'eventList.regroup': 'Перестроение',
    'eventList.oziformation': 'Позиционная атака',
    'eventList.ozoformation': 'Атака без построения',
    'eventList.pp': 'Большинство',
    //breadCrumbs
    'breadCrumbs.versus': ' - ',
    'components.notFound.oops': 'Ой!',
    'components.notFound.errorMessage': 'Мы не можем найти нужную вам страницу.',
    'components.notImplemented.errorMessage': 'Страница находится в разработке.',
    'components.licenseDeclined.title': 'Вы отклонили пользовательское соглашение',
    'components.licenseDeclined.errorMessage':
        'Пожалуйста свяжитесь с вашим менеджером для обсуждения деталей договора.',
    'components.badLink.title': 'Неправильная ссылка',
    'components.badLink.errorMessage': 'Пожалуйста проверьте правильность ссылки или сообщите о проблеме в поддержку.',
    'components.noGames.errorMessage': 'Нет доступных игр.',
    'components.noGames.info': 'Заказать игры',
    'components.notFound.errorCode': 'Код ошибки: 404',
    'components.error.info': 'Попробуйте обновить страницу.',
    'components.error.reloadPage': 'Обновить',
    'components.updateFromExcel': 'Обновить с помощью excel-файла',

    'components.notFilledInfo.playlists': 'Вы не добавили ещё ни одного события в свои пользовательские плейлисты.',
    'components.notFilledInfo.favoritePlayers': 'Вы не добавили ещё ни одного игрока в избранное.',

    eliteProspectsId: 'Elite Prospect ID',
    'eliteProspectsId.additionalParameters': 'Параметры',

    'measures.m': 'м',
    'measures.km': 'км',
    'measures.mPerSec': 'м/с',
    'measures.mPerSec2': 'м/с²',
    'measures.ft': 'фут',
    'measures.mile': 'миля',
    'measures.mph': 'миль/ч',
    'measures.kmPerH': ' км/ч',
    'promptButton.apply': 'Применить',

    /******************************************************Containers******************************************************/
    //filter
    'filter.lastGame': 'Последняя игра',
    'filter.add': 'Добавить',
    'filter.clear': 'Очистить',
    'filter.apply': 'Применить',
    'filter.selectAll': 'ВЫБРАТЬ ВСЕ',
    'filter.deselectAll': 'СНЯТЬ',
    'filter.search.placeholder': 'Поиск',
    'filter.periods.one': '{period} период',
    'filter.periods.overtime': '{period} от',
    'filter.periods.many': '{periods, plural, few {# периода} many {# периодов} other {# периода}}',
    'filter.strength.ev': 'Равные составы',
    'filter.strength.pk': 'Меньшинство',
    'filter.strength.pp': 'Большинство',
    'filter.strength.st': 'Неравные составы',
    'filter.strength.en': 'Пустые ворота',
    'filter.strength.enTeam': 'Пустые ворота (команда)',
    'filter.strength.enOpponent': 'Пустые ворота (противник)',
    'filter.strength.mix': '{strengths, plural, =1 {# состав} few {# состава} many {# составов} other {# состава}}',
    'filter.seasons.many': '{seasons, plural, few {# сезона} many {# сезонов} other {# сезона}}',
    'filter.seasons.all': 'Все сезоны',
    'filter.teams.many': '{teams, plural, few {# команды} many {# команд} other {# команды}}',
    'filter.teams.all': 'Все команды',
    'filter.teams.presentation': '{nameRu} ({acronymRu})',
    'filter.games.one': '{acronymRu}',
    'filter.games.empty': 'Нет доступных игр',
    'filter.games.mixed': '{available} Игр (из {total})',
    'filter.games.date': '{date, date, shortUTC}',
    'filter.games.scoutGame': '{homeTeamNameRu}-{awayTeamNameRu}',
    'filter.games.scoutGame.teamsName': '{homeFullNameRu}-{awayFullNameRu}',
    'filter.games.teamStatus': ' { isHome, select, true {vs} other {@} } ',
    'filter.games.opponentName': '{acronymRu} {teamScore}-{opponentScore}',
    'filter.games.many': '{games, plural, few {# игры} many {# игр} other {# игры}}',
    'filter.games.status': '{ isHome, select, true {Д} other {В} }{year}',
    'filter.teamStatus': '{ teamStatus, select, TEAM {Команда} OPPONENT {Соперник} other {Все команды} }',
    'filter.games.selectAll': 'ВЫБРАТЬ ВСЕ',
    'filter.games.deselect': 'СНЯТЬ',
    'filter.position.D': 'Защитники',
    'filter.position.F': 'Нападающие',
    'filter.position.G': 'Вратари',
    'filter.position.not.D': 'Не Защитники',
    'filter.position.not.F': 'Не Нападающие',
    'filter.position.not.G': 'Не Вратари',
    'filter.position.all': 'Все позиции',
    'filter.success.success': 'Успешные',
    'filter.success.unsuccess': 'Неуспешные',
    'filter.success.all': 'Все исходы',
    'filter.shoots.all': 'Все Хваты',
    'filter.entryType.pass': 'Пас',
    'filter.entryType.dump': 'Вброс',
    'filter.entryType.carry': 'Ведение',
    'filter.entryType.notPass': 'Не пас',
    'filter.entryType.notDump': 'Не вброс',
    'filter.entryType.notCarry': 'Не ведение',
    'filter.entryType.all': 'Все типы',
    'filter.possession.offensive': 'Нападение',
    'filter.possession.neutral': 'Нейтральная',
    'filter.possession.defensive': 'Защита',
    'filter.possession.notOffensive': 'Не нападение',
    'filter.possession.notNeutral': 'Не нейтральная',
    'filter.possession.notDefensive': 'Не защита',
    'filter.possession.all': 'Все зоны',
    'filter.pp.many': '{pps} эпизодов',
    'filter.pk.many': '{pks} эпизодов',
    'filter.leagues.many': '{leagues, plural, few {# лиги} many {# лиг} other {# лиги}}',
    'filter.leagues.all': 'Все лиги',
    'filter.players.many': '{players, plural, few {# игрока} many {# игроков} other {# игрока}}',
    'filter.country.all': 'Все нации',

    'filter.position.lf': 'ЛФ',
    'filter.position.cf': 'ЦФ',
    'filter.position.rf': 'ПФ',

    'filter.position.lrf': 'ЛПФ',
    'filter.position.clf': 'ЦЛФ',
    'filter.position.crf': 'ЦПФ',
    'filter.position.clrf': 'ЦЛПФ',

    'filter.position.f': 'Нападение',

    'filter.position.cr': 'ЦПФ',
    'filter.position.cl': 'ЦЛФ',
    'filter.position.l': 'ЛФ',
    'filter.position.r': 'ПФ',
    'filter.position.lr': 'ЛПФ',
    'filter.position.clr': 'ЦЛПФ',
    'filter.position.c': 'ЦФ',

    'filter.position.d': 'З',
    'filter.position.g': 'Г',

    'filter.position.defensemen': 'Защита',
    'filter.position.forwards': 'Нападение',
    'filter.position.goalies': 'Вратари',
    //app-container
    'appContainer.supportRequest': 'Запрос технической поддержки',
    'appContainer.supportRequest.reason': 'Тема запроса',
    'appContainer.supportRequest.reason.placeholder': 'Выберите тему запроса',
    'appContainer.supportRequest.email': 'Email для связи',
    'appContainer.supportRequest.submit': 'ОТПРАВИТЬ',
    'appContainer.supportRequest.success.p1': 'Спасибо за обращение.',
    'appContainer.supportRequest.success.p2': 'Вскоре мы свяжемся с вами через email.',
    'appContainer.supportRequest.dataVideoIssue': 'Проблема с данными / видео игры',
    'appContainer.supportRequest.dataVideoIssue.description':
        'Пожалуйста, предоставьте информацию об игре / подробное описание момента',
    'appContainer.supportRequest.missingGamesIssue': 'Некоторые игры отсутствуют',
    'appContainer.supportRequest.missingGamesIssue.description':
        'Пожалуйста, предоставьте информацию об отсутствующих играх',
    'appContainer.supportRequest.logInIssue': 'Не получается войти на портал',
    'appContainer.supportRequest.logInIssue.description':
        'Пжалуйста, предоставьте ваш логин и информацию о браузере и устройстве',
    'appContainer.supportRequest.verificationRequest': 'Запрос на проверку данных',
    'appContainer.supportRequest.verificationRequest.description':
        'Пожалуйста, предоставьте информацию об игре и детали проблемы',
    'appContainer.supportRequest.financialIssue': 'Финансовые вопросы',
    'appContainer.supportRequest.financialIssue.description': 'Пожалуйста, укажите номер счета',
    'appContainer.supportRequest.hardwareSystemIssue': 'Проблема с аппаратной системой ICEBERG',
    'appContainer.supportRequest.hardwareSystemIssue.description':
        'Пожалуйста, предоставьте описание проблемы и ссылку на фото',
    'appContainer.supportRequest.other': 'Другое',
    'appContainer.supportRequest.other.description': 'Пожалуйста, предоставьте подробное описание проблемы',

    'appContainer.logout': 'Выйти',
    'appContainer.dashboard': 'Аналитика',
    'appContainer.scouting': 'Скаутинг',
    'appContainer.scoutAdmin': 'Скаут',
    'appContainer.clientsAdmin': 'Клиенты',
    'appContainer.scheduleAdmin': 'График',
    'appContainer.players': 'Игроки',
    'appContainer.service': 'Справка',
    'appContainer.whatsNew': 'Новости портала',
    'appContainer.teams': 'Команды',
    'appContainer.glossary': 'Словарь',
    'appContainer.profile.addGame': 'Загрузить видео',
    'appContainer.profile.mode': 'Тема',
    'appContainer.profile.evaluation': 'Оценки',
    'appContainer.profile.customization': 'Настройка',
    'appContainer.profile.ratingSystem': 'Рейтинговая система',
    'appContainer.profile.ratingSystem.FIVE_POINT': 'Пятибалльная',
    'appContainer.profile.ratingSystem.TEN_POINT': 'Десятибалльная',
    'appContainer.profile.ratingSystem.HUNDRED_POINT': 'Стобалльная',
    'appContainer.profile.ratingSystem.LETTER': 'Буквенная',
    'appContainer.profile.signOut': 'Выйти',
    'appContainer.profile.units': 'Система мер',
    'appContainer.toggleRadio.METRIC': 'Метрич.',
    'appContainer.toggleRadio.IMPERIAL': 'Англ.',
    'appContainer.toggleRadio.classic': 'Светлая',
    'appContainer.toggleRadio.darcula': 'Ночная',
    'appContainer.footer.service': 'Глоссарий',

    //match-switcher
    'matchSwitcher.january': 'Январь ',
    'matchSwitcher.february': 'Февраль ',
    'matchSwitcher.march': 'Март ',
    'matchSwitcher.april': 'Апрель ',
    'matchSwitcher.may': 'Май ',
    'matchSwitcher.june': 'Июнь ',
    'matchSwitcher.july': 'Июль ',
    'matchSwitcher.august': 'Август ',
    'matchSwitcher.september': 'Сентябрь ',
    'matchSwitcher.october': 'Октябрь ',
    'matchSwitcher.november': 'Ноябрь ',
    'matchSwitcher.december': 'Декабрь ',
    //tooltip
    'tooltip.video': 'Видео',
    //upload-client-game-popup
    'uploadClientGamePopup.title': 'Загрузка игры',
    'uploadClientGamePopup.gameDate': 'Дата',
    'uploadClientGamePopup.homeTeam': 'Домашняя команда',
    'uploadClientGamePopup.awayTeam': 'Выездная команда',
    'uploadClientGamePopup.dragHere':
        'Введите дату, домашнюю команду и команду гостей. Далее загрузите файлы с ВИДЕО и ПРОТОКОЛОМ МАТЧА, перетащив их в окно загрузки. Все файлы могут загружены одновременно.',
    'uploadClientGamePopup.filesToUpload': 'ФАЙЛЫ ДЛЯ ЗАГРУЗКИ',
    'uploadClientGamePopup.filesToUploadPlaceholder': 'Видеофайлы могут быть в любом формате.',
    'uploadClientGamePopup.uploading': 'ЗАГРУЗКА',
    'uploadClientGamePopup.success': 'ФАЙЛЫ УСПЕШНО ЗАГРУЖЕНЫ',
    'uploadClientGamePopup.error': 'ОШИБКА ПРИ ЗАЗГРУЗКЕ ФАЙЛОВ, ПОЖАЛУЙСТА ПОПРОБУЙТЕ ПОЗДНЕЕ',
    'uploadClientGamePopup.upload': 'Загрузить',
    'uploadClientGamePopup.cancelUpload': 'Отменить',
    'uploadClientGamePopup.uploadMore': 'Загрузить ещё',

    /***************************************************************Widgets************************************************/
    'shotMap.shotAttempts': 'Кол-во бросков: ',
    'shotMap.scoringChances': 'Голевые моменты: ',
    'shotMap.firstPeriod': '1 ПЕРИОД',
    'shotMap.secondPeriod': '2 ПЕРИОД',
    'shotMap.thirdPeriod': '3 ПЕРИОД',
    'shotMap.somePeriod': '1 ОТ',
    'shotMap.lowerRight': 'Нижний правый',
    'shotMap.upperLeft': 'Верхний левый',
    'shotMap.upperMiddle': 'Вверх по центру',
    'shotMap.upperRight': 'Верхний правый',
    'shotMap.lowerLeft': 'Нижний левый',
    'shotMap.lowerMiddle': 'Вниз по центру',
    'shotMap.blocked': 'Заблокирован',
    'shotMap.deflected': 'Подправлен',
    'shotMap.overTheNet': 'Выше ворот',
    'shotMap.wideRight': 'Мимо правее',
    'shotMap.wideMiddle': 'Выше ворот',
    'shotMap.wideLeft': 'Мимо левее',
    'shotMap.goal': 'Гол',
    'shotMap.shotOnGoal': 'Бросок в створ',
    'shotMap.scoreAttempt': 'Бросок',
    'shotMap.teamXG': 'xG команды',
    'shotMap.moment': 'Момент',
    'shotMap.time': 'Время',
    'shotMap.xG': 'xG',
    'shotMap.strength': 'Состав',
    'shotMap.goalkeeper': 'Вратарь',
    'shotMap.pulled': 'Снят',
    'shotMap.shotSpeed': 'Скорость',
    'shotMap.MPH': 'м/с',
    'shotMap.game': 'Игра',
    'shotMap.title': 'Карта бросков',
    'shotMap.location': ' Броски',
    'shotMap.heatMap': 'Тепловая карта',
    'iceRinkChart.zones.DZ': 'DZ',
    'iceRinkChart.zones.OZ': 'OZ',
    'iceRinkChart.zones.summaryTitle': 'TOTAL',
    'score.OT': 'ОТ',
    'score.SO': 'Б',
    'score.opponent': 'Соперники',

    /***************************************************************Pages**************************************************/
    'noGamesPage.title': 'НЕТ ДОСТУПНЫХ ИГР',
    'home.gameScore.preseason': 'Предсезонные матчи',
    'home.pastGames': 'Предыдущие игры',
    'home.restGames': 'Другие игры',
    'home.favoriteGames': 'Избранные игры',
    'home.evenStrengthXg': 'РАВ xG:',
    'home.specialTeamsXg': 'НЕР xG:',
    'home.gameInfo.postGameReport': 'Краткий отчет',
    'summary.summary': 'Резюме',
    'summary.versus': ' - ',
    'summary.gameStatistics': 'Статистика игры',
    'summary.score': 'Счет',
    'summary.xGMap': 'xG карта',
    'summary.xGMap.dz': 'Зона обороны',
    'summary.xGMap.oz': 'Зона атаки',
    'summary.xGTimeline': 'Хронология xG',
    'summary.skaters': 'Три звезды',
    'summary.animation': 'Анимация',
    'summary.video': 'Видео',
    'summary.playList': 'Плейлист',
    'summary.infobar.shotAttempts': 'Броски',
    'summary.infobar.faceoffs': 'Вбрасывания',
    'summary.infobar.scoringChances': 'Голевые моменты',
    'summary.infobar.shotsOnGoal': 'Броски в створ',
    'summary.infobar.possession': 'Владение шайбой',
    'summary.infobar.less': '%\u00A0меньше',
    'summary.infobar.more': '%\u00A0больше',
    'summary.infobar.then': ' прошлого матча',
    'summary.infobar.for': 'команды',
    'summary.infobar.against': 'соперника',
    'summary.infobar.puckTimeByZone': 'Время в зоне',
    'summary.infobar.interceptions': 'Перехваты',
    'summary.score.goals': 'счет',
    'summary.score.shotAttempts': 'бросков',
    'summary.score.shotsOnGoal': 'бросков в створ',
    'summary.score.bodyChecks': 'отборы корпусом',
    'summary.score.passing': '%\u00A0успешных передач',
    'summary.score.timeOfPossessions': 'время владения',
    'summary.score.distance': 'Дистанция,',
    'summary.score.hits': 'Силовые приемы',
    'summary.score.recoveries': 'Нейтральные шайбы',
    'summary.score.evXg': 'РАВ xG:',
    'summary.score.stXg': 'НЕР xG:',
    'summary.score.reportPostgame': 'Послематчевый отчет',
    'summary.score.downloadRawData': 'Скачать исходные данные',
    'summary.score.scoringChancesPecentage': 'Голевые моменты %',
    'summary.score.shotsAccuracy': 'точность бросков\u00A0%',
    'summary.xGTimeline.shotAttempts': 'Кол-во бросков: ',
    'summary.xGTimeline.scoringChances': 'Голевые моменты: ',
    'summary.xGTimeline.firstPeriod': '1 ПЕРИОД',
    'summary.xGTimeline.secondPeriod': '2 ПЕРИОД',
    'summary.xGTimeline.thirdPeriod': '3 ПЕРИОД',
    'summary.xGTimeline.somePeriod': '1 ОТ',
    'summary.xGTimeline.lowerRight': 'Нижний правый',
    'summary.xGTimeline.upperLeft': 'Верхний левый',
    'summary.xGTimeline.upperMiddle': 'Вверх по центру',
    'summary.xGTimeline.upperRight': 'Верхний правый',
    'summary.xGTimeline.lowerLeft': 'Нижний левый',
    'summary.xGTimeline.lowerMiddle': 'Вниз по центру',
    'summary.xGTimeline.blocked': 'Заблокирован',
    'summary.xGTimeline.deflected': 'Подправлен',
    'summary.xGTimeline.overTheNet': 'Выше ворот',
    'summary.xGTimeline.wideRight': 'Мимо правее',
    'summary.xGTimeline.wideMiddle': 'Выше ворот',
    'summary.xGTimeline.wideLeft': 'Мимо левее',
    'summary.xGTimeline.goal': 'Гол',
    'summary.xGTimeline.shotOnGoal': 'Бросок в створ',
    'summary.xGTimeline.scoreAttempt': 'Голевой момент',
    'summary.xGTimeline.teamXG': 'xG команды',
    'summary.xGTimeline.moment': 'Момент',
    'summary.xGTimeline.time': 'Время',
    'summary.xGTimeline.overtime': 'ОТ',
    'summary.shotMap.xG': 'xG',
    'summary.skaters.onIceXG': 'xG на льду',
    'summary.skaters.totalXG': 'Общ. xG',
    'summary.skaters.gameScore': 'Оценка игры',
    'summary.skaters.OZoneTimeOnIce': 'Время в зоне атаки',
    'summary.skaters.shotAttempts': 'Броски',
    'summary.skaters.potentialAssists': 'Потенциальные ГП',
    'summary.skaters.takeaways': 'Перехваты',
    'summary.skaters.save': 'Сейвы',
    'summary.skaters.rebound': 'Отскоки',
    'summary.skaters.recoveries': 'Подборы',
    'summary.skaters.shotAngle': 'Угол отскока',
    'summary.skaters.goalsAGST': 'Голы соп.',
    'summary.skaters.xgAGST': 'xG соп.',
    'skaters.efficiency': 'Эффективность',
    'skaters.compareLines': 'Сравнение звеньев',
    'skaters.xgTopSkaters': 'Лучшие / худшие игроки',
    'skaters.efficiency.timeOnIce': 'Время на льду',
    'skaters.efficiency.shotsFor': 'Броски команды',
    'skaters.efficiency.shotsAgainst': 'Броски соперника',
    'skaters.efficiency.dull': 'Мало Событий',
    'skaters.efficiency.good': 'ХОРОШО',
    'skaters.efficiency.fun': 'Много Событий',
    'skaters.efficiency.bad': 'ПЛОХО',
    'skaters.compareLines.line': 'ТРОЙКИ',
    'skaters.compareLines.xG': 'xG\u00A0%',
    'skaters.compareLines.forwards': 'НАПАДАЮЩИЕ',
    'skaters.compareLines.defenders': 'ЗАЩИТНИКИ',
    'skaters.compareLines.pair': 'ПАРЫ',
    'skaters.compareLines.L': 'Т',
    'skaters.compareLines.P': 'П',
    'skaters.compareLines.combination': 'Комбинация',
    'skaters.compareLines.toi': {
        message: 'Время на льду',
        title: 'Общее время на льду (звено)'
    },
    'skaters.compareLines.possessionTime': {
        message: 'Владение %',
        title: 'Процент владения шайбой (команда)'
    },
    'skaters.compareLines.possessionTimeFor': 'Владение за',
    'skaters.compareLines.possessionTimeAgainst': 'Владение против',
    'skaters.compareLines.OZpuckTime': {
        message: 'Время в зоне атаки %',
        title: 'Процентное соотношение к общему времени на льду, когда шайба находилась в зоне атаки'
    },
    'skaters.compareLines.puckTimeOZFor': 'Время в зоне атаки',
    'skaters.compareLines.puckTimeOZAgainst': 'Время в зоне защиты',
    'skaters.compareLines.onIceXgRelativeFor': 'xG за',
    'skaters.compareLines.onIceXgRelativeAgainst': 'xG против',
    'skaters.compareLines.xgFor': 'xG за',
    'skaters.compareLines.xgAgainst': 'xG против',
    'skaters.compareLines.onIceXg': {
        message: 'xG\u00A0%',
        title: 'xG команды, разделенное на сумму xG команды и xG оппонента'
    },
    'skaters.compareLines.corsi': {
        message: 'Броски %',
        title: 'Процентное соотношение бросков команды к общему количеству бросков'
    },
    'skaters.compareLines.gameScore': {
        message: 'Игровые очки',
        title: 'Набранные очки игрока за игру'
    },
    'skaters.compareLines.shotsFor': 'Броски за',
    'skaters.compareLines.shotsAgainst': 'Броски против',
    'skaters.compareLines.infobar': 'Статистика',
    'skaters.compareLines.table': 'Таблица',
    'skaters.compareLines.F': 'Т',
    'skaters.compareLines.D': 'П',
    'skaters.compareLines.toiTotal': 'Общее время на льду',
    'skaters.compareLines.possessionFor': 'Владение за',
    'skaters.compareLines.possessionAgainst': 'Владение против',
    'skaters.compareLines.gameTime': 'Время игры',
    'skaters.xGTopSkaters.timeOnIce': {
        message: 'Время на льду',
        title: 'Общее время на льду (игрок)'
    },
    'skaters.xGTopSkaters.possession': {
        message: 'Владение\u00A0%',
        title: 'Процент владения шайбой пока игрок был на льду'
    },
    'skaters.xGTopSkaters.puckTimeInOZ': {
        message: 'Время в зоне атаки\u00A0%',
        title: 'Средний процент времени нахождения шайбы в зоне атаки пока игрок был на льду'
    },
    'skaters.xGTopSkaters.corsi': {
        message: 'Броски\u00A0%',
        title: 'Соотношение бросков команды к общему кол-ву бросков, пока игрок был на льду'
    },
    'skaters.xGTopSkaters.scoringChances': {
        message: 'Голевые моменты\u00A0%',
        title: 'Соотношение голевых моментов команды к общему кол-ву голевых моментов, пока игрок был на льду'
    },
    'skaters.xGTopSkaters.onIcexG': {
        message: 'xG\u00A0%',
        title: 'Соотношение xG, созданных командой к общему кол-ву xG, пока игрок был на льду'
    },
    'skaters.xGTopSkaters.gameScore': {
        message: 'Оценка игры',
        title:
            'Вероятность, что команда игрока победит при условии его позиции, его времени на льду и его защитных и атакующих действий'
    },
    'skaters.xGTopSkaters.totalxG': 'Сумма xG',
    'skaters.xGTopSkaters.shots': 'Броски',
    'skaters.xGTopSkaters.recoveries': 'Подборы',
    'skaters.xGTopSkaters.skaters': 'ИГРОКИ',
    'skaters.xGTopSkaters.skatersAll': 'ВСЕ ИГРОКИ',
    'skaters.xGTopSkaters.infobar': 'Среднее по таблице',
    'skaters.xGTopSkaters.table': 'Таблица игроков',
    'skaters.xGTopSkaters.possessionTimeFor': 'Владение за',
    'skaters.xGTopSkaters.puckTimeOZFor': 'Время в зоне атаки',
    'skaters.xGTopSkaters.shotsFor': 'Броски за',
    'skaters.xGTopSkaters.scoringChanceFor': 'Голевые моменты за',
    'skaters.xGTopSkaters.onIceXgRelativeFor': 'xG на льду за',
    'skaters.xGTopSkaters.possessionTimeAgainst': 'Владение против',
    'skaters.xGTopSkaters.puckTimeOZAgainst': 'Время в зоне защиты',
    'skaters.xGTopSkaters.shotsAgainst': 'Броски против',
    'skaters.xGTopSkaters.scoringChanceAgainst': 'Голевые моменты против',
    'skaters.xGTopSkaters.onIceXgRelativeAgainst': 'xG на льду против',
    'skaters.xGTopSkaters.toi': 'Общее время на льду',
    'skaters.xGTopSkaters.possessionFor': 'Владение за',
    'skaters.xGTopSkaters.possessionAgainst': 'Владение против',
    'skaters.xGTopSkaters.faceoffs': {
        message: 'Смены в зоне атаки, %',
        title: 'Процент случаев, когда игрок выходил на лёд на вбрасывание в зоне атаки'
    },
    'skaters.xgTopSkaters.dzFaceOffs': 'Зона защиты',
    'skaters.xgTopSkaters.nzFaceOffs': 'Нейтральная зона',
    'skaters.xgTopSkaters.ozFaceOffs': 'Зона атаки',
    'skaters.xGTopSkaters.gameTime': 'Время игры',
    'powerPlay.infobar': 'Среднее / сумма по таблице',
    'powerPlay.linesTable': 'Звенья большинства',
    'powerPlay.donutChart': 'Типы большинства',
    'powerPlay.skatersTable': 'Игроки',
    'powerPlay.shotMap': 'Карта бросков',
    'powerPlay.info.totalPPTime': 'Общее время',
    'powerPlay.info.ozFormationTime': 'Позиционная атака',
    'powerPlay.info.shotsOnGoal': 'Броски в створ',
    'powerPlay.info.ozEntries': 'Входы в зону',
    'powerPlay.info.reboundRecoveries': 'Подборы',
    'powerPlay.info.faceOff%': 'Выигранные вбрасывания,\u00A0%',
    'powerPlay.info.faceOffWon': 'Выигранные вбрасывания',
    'powerPlay.info.faceOffLost': 'Проигранные вбрасывания',
    'powerPlay.players.toi': {
        message: 'Время на льду',
        title: 'Общее время на льду в большинстве (игроки)'
    },
    'powerPlay.players.ozTime': {
        message: 'Время в зоне атаки',
        title: 'Время, которое шайба находилась в зоне атаки, пока игрок был на льду'
    },
    'powerPlay.players.ozfPerOz': {
        message: 'Время в позиционной атаке,\u00A0%',
        title: 'Процентное соотношение времени атаки с построением к общему времени в атаке, пока игрок был на льду'
    },
    'powerPlay.players.shots': {
        message: 'Броски',
        title: 'Общее количество бросков, нанесенных в большинстве, пока игрок был на льду'
    },
    'powerPlay.players.scoringChances': {
        message: 'Голевые моменты',
        title: 'Общее количество голевых моментов, созданных в большинстве, пока игрок был на льду'
    },
    'powerPlay.players.scoringChancesPerShots': 'Голевые моменты / броски',
    'powerPlay.players.reboundRecoveries': 'Подборы',
    'powerPlay.players.successZoneEntries': 'Успешные входы в зону',
    'powerPlay.players.potentialAssists': 'Потенциальные ГП',
    'powerPlay.players.giveawaysPercent': 'Потери,\u00A0%',
    'powerPlay.players.avgRushSpeed': 'Средняя скорость развития атаки,\u00A0',
    'powerPlay.players.giveaways': 'Потери,\u00A0%',
    'powerPlay.players.possessionCount': 'Владения',
    'powerPlay.players.ozFormationTime': 'Позиционная атака',
    'powerPlay.players.ozOnIceTime': 'Время в зоне атаки',
    'powerPlay.lines.lines': 'Большинство',
    'powerPlay.lines.ozfTime': 'Позиционная атака',
    'powerPlay.lines.toi': {
        message: 'Время на льду',
        title: 'Общее время на льду в большинстве (по звеньям)'
    },
    'powerPlay.lines.lineIndex': 'Б{index}',
    'powerPlay.lines.shots': {
        message: 'Броски',
        title: 'Общее количество бросков, нанесенных в большинстве, пока звено было на льду'
    },
    'powerPlay.lines.shotsOnGoal': 'Броски в створ',
    'powerPlay.lines.shotsBlocked': 'Заблокированные',
    'powerPlay.lines.shotsMissed': 'Мимо',
    'powerPlay.lines.scoringChances': {
        message: 'Голевые моменты',
        title: 'Общее количество голевых моментов, созданных в большинстве, пока звено было на льду'
    },
    'powerPlay.lines.ozTime': {
        message: 'Время в зоне атаки',
        title: 'Время нахождения шайбы в зоне атаки при игре в большинстве'
    },
    'powerPlay.lines.ozfTimeOfOzTime': {
        message: 'Позиционная атака,\u00A0%',
        title: 'Процентное соотношение времени атаки с построением к общему времени в атаке, пока звено было на льду'
    },
    'powerPlay.chart.ppXG': 'БОЛ XG',
    'powerPlay.chart.rush': 'РАЗВИТИЕ АТАКИ',
    'powerPlay.chart.oziFormation': 'ПОЗИЦИОННАЯ АТАКА',
    'powerPlay.chart.ozoFormation': 'АТАКА БЕЗ ПОСТРОЕНИЯ',
    'powerPlay.chart.regrouping': 'ПЕРЕСТРОЕНИЕ',
    'penaltyKill.linesTable': 'Звенья меньшинства',
    'penaltyKill.donutChart': 'Атаки',
    'penaltyKill.chart.rushToOZConversion': 'К АТАКЕ СОПЕРНИКА',
    'penaltyKill.chart.rushToRegroupConversion': 'К ПЕРЕСТРОЕНИЮ',
    'penaltyKill.chart.rushToPKConversion': 'К ВЛАДЕНИЮ КОМАНДЫ',
    'penaltyKill.chart.zone': 'ZONE',
    'penaltyKill.chart.OZ': 'OZ',
    'penaltyKill.chart.NZ': 'NZ',
    'penaltyKill.chart.DZ': 'DZ',
    'penaltyKill.chart.puckTime': 'PUCK TIME',
    'penaltyKill.chart.opponentPossessions': '# владений соперника',
    'penaltyKill.chart.clearances': 'Выбросы /# владений',
    'penaltyKill.chart.rushes': 'Развитие атаки',
    'penaltyKill.chart.entries': 'Входы',
    'penaltyKill.chart.carries': 'Ведение',
    'penaltyKill.chart.dumps': 'Вброс',
    'penaltyKill.chart.passes': 'Пас',
    'penaltyKill.info.totalPkTime': 'Время меньшинства',
    'penaltyKill.info.dzTimePercent': 'Время в зоне защиты, %',
    'penaltyKill.info.regroupTimePerRush': 'Перестроение за развитие атаки',
    'penaltyKill.info.clearancesPerDzPossessions': 'Выбросы, %',
    'penaltyKill.info.shotsPerPk': 'Броски',
    'penaltyKill.info.blockPercentage': 'Блокированные броски, %',
    'penaltyKill.info.scoringChancesPerShots': 'Голевые моменты / броски, %',
    'penaltyKill.info.faceOffPercentage': 'Вбрасывания, %',
    'penaltyKill.info.faceOffWon': 'Выигранные вбрасывания',
    'penaltyKill.info.faceOffLost': 'Проигранные вбрасывания',
    'penaltyKill.info.dzTime': 'Время в зоне защиты',
    'penaltyKill.info.regroupTime': 'Время перестроения',
    'penaltyKill.info.rushes': 'Развитий атаки',
    'penaltyKill.info.clearances': 'Выносы',
    'penaltyKill.info.possessionsInDz': 'Владений в зоне защиты',
    'penaltyKill.info.blockedShots': 'Блокировано',
    'penaltyKill.info.shots': 'Бросков',
    'penaltyKill.info.scoringChances': 'Голевых моментов',
    'penaltyKill.lines.lineIndex': 'М{index}',
    'penaltyKill.lines.lines': 'Меньшинство',
    'penaltyKill.lines.toi': {
        message: 'Время на льду',
        title: 'Общее время звена на льду'
    },
    'penaltyKill.lines.dzTime': {
        message: 'Время в защите',
        title: 'Общее время владения шайбой в зоне обороны (по звеньям)'
    },
    'penaltyKill.lines.dzFormationTimeOfDzTime': {
        message: 'Позиционная защита, %',
        title:
            'Процентное соотношение когда шайба находилась в зоне обороны от всего времени, проведенного звеном на льду'
    },
    'penaltyKill.lines.dzfTime': 'Время позиционной защиты',
    'penaltyKill.lines.crossIcePasses': {
        message: 'Поперечные передачи',
        title: 'Общее кол-во поперечных передач команды-соперника в зоне обороны'
    },
    'penaltyKill.lines.shotsOnGoal': {
        message: 'Броски в створ',
        title: 'Общее кол-во бросков в створ команды соперника'
    },
    'penaltyKill.lines.scoringChances': {
        message: 'Опасные моменты',
        title: 'Общее кол-во голевых моментов у соперника'
    },
    'penaltyKill.lines.recoveryPercentage': {
        message: 'Подборы, %',
        title: 'Количество подборов после отбитой вратарем шайбы от общего числа отбитых шайб (своя команда)'
    },
    'penaltyKill.lines.recoveries': 'Подборы',
    'penaltyKill.players.dzFormationOfDzTime': 'Позиционная защита, %',
    'penaltyKill.players.dzFormationTime': 'Время позиционной защиты',
    'penaltyKill.players.loosePuckRecoveries': 'Нейтральные шайбы',
    'penaltyKill.players.reboundRecoveriesPersonal': 'Подборы',
    'penaltyKill.players.clearancesPerPossessions': 'Выбросы, %',
    'penaltyKill.players.clearances': 'Выбросы',
    'penaltyKill.players.dzPossessions': 'Владений в защите',
    'penaltyKill.players.blockedShotsPerPotentiallyBlocked': 'Блокированные броски, %',
    'penaltyKill.players.blockedShots': 'Блокированные',
    'penaltyKill.players.potentiallyBlockedShots': 'Потенциально блокированные броски',
    'penaltyKill.players.avgDistanceToTargetInFormation': 'Плотность опеки, ',
    'penaltyKill.players.toi': {
        message: 'Время на льду',
        title: 'Общее время на льду в меньшинстве'
    },
    'penaltyKill.players.dzTimePercentage': {
        message: 'Время в зоне защиты, %',
        title:
            'Процентное соотношение когда шайба находилась в зоне обороны от всего времени, проведенного игроком на льду'
    },
    'penaltyKill.players.dzTime': 'Время в зоне защиты',
    'penaltyKill.players.dzfTimePercentage': {
        message: 'Позиционная защита, %',
        title: 'Время проведенное в зоне обороны с построением, во время позиционной атаки соперника'
    },
    'penaltyKill.players.dzfTime': 'Позиционная защита',
    'penaltyKill.players.shots': {
        message: 'Броски',
        title: 'Общее кол-во бросков соперника, пока игрок был на льду'
    },
    'penaltyKill.players.scoringChances': {
        message: 'Голевые моменты',
        title: 'Общее кол-во голевых моментов соперника, пока игрок находился на льду'
    },
    'penaltyKill.players.crossIcePasses': {
        message: 'Поперечные передачи',
        title: 'Общее кол-во кроссов, сделанных командой  противника в зоне защиты, пока игрок был на льду'
    },
    'statistics.passing': 'Передачи',
    'statistics.shooting': 'Броски',
    'statistics.entries': 'Входы',
    'statistics.exits': 'Выходы',
    'statistics.entries.passes': 'Пас',
    'statistics.entries.controlled': 'С контролем',
    'statistics.entries.uncontrolled': 'Без контроля',
    'statistics.possession': 'Владение',
    'statistics.goalkeepers': 'Вратари',
    'statistics.skating': 'Катание',
    'statistics.title': 'Статистика',
    'statistics.shifts': 'Смены',
    'statistics.playerComparison': 'Сравнение игроков',
    'statistics.shooting.shotAttempts': {
        message: 'Броски',
        title:
            'Общее количество бросков (броски в створ, блокированные броски, броски с подправлением и броски мимо ворот)'
    },
    'statistics.shooting.shotsOnGoal': {
        message: 'Броски в створ',
        title: 'Общее количество бросков в створ ворот'
    },
    'statistics.shooting.shooting': {
        message: 'Реализация,\u00A0%',
        title: 'Количество голов, разделенное на количество бросков в створ'
    },
    'statistics.shooting.goals': {
        message: 'Голы',
        title: 'Фиксируется при пересечении шайбой линии ворот'
    },
    'statistics.shooting.games': {
        message: 'Игры',
        title: 'Количество игр'
    },
    'statistics.shooting.toi': {
        message: 'Ср. время на льду',
        title: 'Среднее время игрока на льду'
    },
    'statistics.shooting.scoringChances': {
        message: 'Голевые моменты',
        title:
            'Моменты, которые удовлетворяют хотя бы одному из условий: бросок из опасной зоны; бросок после потенциальной голевой передачи, которая пересекла центральную линию; повторный незаблокированный бросок в створ'
    },
    'statistics.shooting.rebound': {
        message: 'Отскоки,\u00A0%',
        title: 'Соотношение отбитых вратарем бросков, к общему количеству бросков в створ'
    },
    'statistics.shooting.avgShotSpeed': {
        message: 'Сред. скор. броска, ',
        title: 'Средняя скорость броска'
    },
    'statistics.shooting.overall': 'Бросков всего',
    'statistics.shooting.shotMaxSpeed': {
        message: 'Макс. скор. броска, ',
        title: 'Максимальная скорость броска'
    },
    'statistics.shooting.infobarTitle': 'Сумма / среднее по таблице',
    'statistics.shooting.shotMap': 'Карта бросков',
    'statistics.shooting.table': 'Таблица бросков',
    'statistics.shooting.avgShotAttempts': 'Ср. брсоков',
    'statistics.shooting.avgShotsOnGoal': 'Ср. бросков в створ',
    'statistics.shooting.avgScoringChances': 'Ср. голевых моментов',
    'statistics.shooting.avgGoals': 'Ср. голов.',
    'statistics.shooting.avgXg': 'Ср. xG',
    'statistics.shooting.reboundTooltip': 'С отскоком',
    'statistics.shooting.timeOnIce': 'Time on Ice',
    'statistics.shooting.assists': 'Assists',
    'statistics.shooting.shotAccuracy': 'Shot accuracy %',
    'statistics.shooting.shotsFromDangerZone': 'Shots from danger zone',
    'statistics.shooting.shotsAfterRebounds': 'Shots after rebounds',
    'statistics.shooting.faceoffs': 'faceoffs %',
    'statistics.shooting.penaltyMinutes': 'Penalty minutes',
    'statistics.shooting.xg': {
        message: 'xG',
        title:
            'Вероятность броска закончиться голом. Учитываются такие факторы, как дистанция до ворот, позиция бросающего, а также расположение партнеров по команде и соперников'
    },
    'statistics.goalkeepers.save': {
        message: 'Сейв,\u00A0%',
        title: 'Количество сейвов разделенное на количество бросков в створ'
    },
    'statistics.goalkeepers.rebound': {
        message: 'Отскок,\u00A0%',
        title: 'Соотношение отбитых вратарем бросков, к общему количеству бросков в створ'
    },
    'statistics.goalkeepers.toi': {
        message: 'Время на льду',
        title: 'Общее кол-во игрового времени, когда вратарь был на льду'
    },
    'statistics.goalkeepers.overall': 'Всего',
    'statistics.goalkeepers.gamesPlayed': {
        message: 'Сыграно игр',
        title: 'Количество игр, в которых игрок принимал участие'
    },
    'statistics.goalkeepers.shotsOnGoal': {
        message: 'Броски в створ',
        title: 'Общее количество бросков в створ ворот'
    },
    'statistics.goalkeepers.goals': {
        message: 'Голы',
        title: 'Фиксируется при пересечении шайбой линии ворот'
    },
    'statistics.goalkeepers.scoringChances': {
        message: 'Голевые моменты',
        title:
            'Моменты, которые удовлетворяют хотя бы одному из условий: бросок из опасной зоны; бросок после потенциальной голевой передачи, которая пересекла центральную линию; повторный незаблокированный бросок в створ'
    },
    'statistics.goalkeepers.scoringChancesSave': {
        message: '% Сэйвов голевых бросков',
        title: '% Сэйвов голевых бросков'
    },
    'statistics.goalkeepers.shotsOnGoalDangerZone': {
        message: 'Danger zone SOG',
        title: ''
    },
    'statistics.goalkeepers.avgShotDistance': {
        message: 'Средняя дистанция,',
        title: 'Средняя дистанция броска среди всех игроков соперника'
    },
    'statistics.goalkeepers.avgAngleOfDeflection': {
        message: 'Угол отскока,\u00A0°',
        title: 'Угол между направлением отскока шайбы и перпендикуляром относительно линии ворот'
    },
    'statistics.goalkeepers.maxShotSpeed': {
        message: 'Макс. скор. броска',
        title: 'Максимальная скорость броска (оппонент)'
    },
    'statistics.goalkeepers.shotsWithRebound': {
        message: 'Rebound shots',
        title: ''
    },
    'statistics.goalkeepers.leagueAverage': 'Среднее по лиге',
    'statistics.goalkeepers.infobar': 'Сумма / среднее по таблице',
    'statistics.goalkeepers.table': 'Таблица вратарей',
    'statistics.goalkeepers.gateChart': 'Зоны ворот',
    'statistics.goalkeepers.shotMap': 'Карта бросков',
    'statistics.goalkeepers.zonesMap': 'Броски по зонам',
    'statistics.goalkeepers.rebounds': 'Отскоки',
    'statistics.goalkeepers.reboundsAgainst': 'Rebound shots against',
    'statistics.goalkeepers.totalShotsOnGoal': 'Всего бросков в створ',
    'statistics.goalkeepers.totalGoals': 'Всего голов',
    'statistics.goalkeepers.shotOnGoalPerTime': 'Бросков по воротам в минуту',
    'statistics.goalkeepers.shotOnGoalPerTimeDeflected': 'Отраженных бросков в минуту',
    'statistics.passing.passes': 'Передачи',
    'statistics.passing.completedPasses': 'Успешные передачи',
    'statistics.passing.team': 'Команда',
    'statistics.passing.overallAverage': {
        message: 'Среднее, %',
        title: 'Средний процент точных передач по всем зонам'
    },
    'statistics.passing.players': 'Игроки',
    'statistics.passing.defensive': {
        message: 'Зона обороны, %',
        title: 'Процент точных передач в зоне обороны'
    },
    'statistics.passing.neutral': {
        message: 'Средняя зона, %',
        title: 'Процент точных передач в нейтральной зоне'
    },
    'statistics.passing.offensive': {
        message: 'Зона атаки, %',
        title: 'Процент точных передач в зоне атаки'
    },
    'statistics.passing.overall': 'Общее по зонам',
    'statistics.passing.ppa': {
        message: 'ПГП (основная)',
        title:
            'Последняя передача перед броском - потенциально голевым моментом (при условии, что в промежуток между передачей и броском владение команды не меняется)'
    },
    'statistics.passing.spa': {
        message: 'ПГП (вторичная)',
        title:
            'Предпоследняя передача перед броском - потенциально голевым моментом (при условии, что в промежуток между предпоследней и последней передачей владение команды не меняется)'
    },
    'statistics.passing.potentialAssist': 'Потенциальные ГП',
    'statistics.passing.successful': 'Точные передачи',
    'statistics.passing.failed': 'Неточные передачи',
    'statistics.passing.bad': 'ПЛОХО',
    'statistics.passing.good': 'ХОРОШО',
    'statistics.passing.infobar': 'Сумма / среднее по таблице',
    'statistics.passing.table': 'Таблица передач',
    'statistics.passing.chart': 'График ПГП',
    'statistics.passing.avgPAP': 'Ср. ПГП (основная)',
    'statistics.passing.avgPAS': 'Ср. ПГП (вторичная)',
    'statistics.passing.defenceZone.accurate': 'Зона обороны, точные',
    'statistics.passing.defenceZone.inaccurate': 'Зона обороны, неточные',
    'statistics.passing.neutralZone.accurate': 'Средняя зона, точные',
    'statistics.passing.neutralZone.inaccurate': 'Средняя зона, неточные',
    'statistics.passing.offensiveZone.accurate': 'Зона атаки, точные',
    'statistics.passing.offensiveZone.inaccurate': 'Зона атаки, точные',
    'statistics.entries.entries': 'ВХОДЫ',
    'statistics.entries.exits': 'ВЫХОДЫ',
    'statistics.entries.ppStatistics': 'ВХОДЫ / ВЫХОДЫ',
    'statistics.entries.entriesExitsAnimation': 'АНИМАЦИЯ ВХОДОВ / ВЫХОДОВ',
    'statistics.entries.playList': 'Плейлист',
    'statistics.entries.shots': 'Броски',
    'statistics.entries.avgDuration': 'Ср. время атаки',
    'statistics.entries.carries': 'Ведение',
    'statistics.entries.carriesIn': 'Ведение',
    'statistics.entries.carriesOut': 'Ведение',
    'statistics.entries.dumps': 'Вброс',
    'statistics.entries.dumpsIn': 'Вброс',
    'statistics.entries.dumpsOut': 'Выброс',
    'statistics.entries.carriesRate': 'Успешные ведения',
    'statistics.entries.passesRate': 'Успешные пасы',
    'statistics.entries.dumpsRate': 'Успешные вбросы',
    'statistics.entries.avgTimeCarries': 'Cр. время ведения',
    'statistics.entries.avgTimePasses': 'Cр. время паса',
    'statistics.entries.avgTimeDumps': 'Cр. время вброса',
    'statistics.entries.successRate': 'Процент успешных',
    'statistics.entries.positiveRate': 'Процент позитивных',
    'statistics.entries.Xg': 'xG',
    'statistics.entries.info.totalEntries': 'Всего',
    'statistics.entries.info.avgDurationTime': 'Cр. время',
    'statistics.entries.info.avgXg': 'Cр. xG',
    'statistics.entries.infobar': 'Сумма / среднее',
    'statistics.entries.info.successRate': 'Успешность',
    'statistics.entries.info.positiveRate': 'Позитивность',
    'statistics.entries.info.distribution': 'Распределение',
    'statistics.possession.checks': {
        message: 'Отборы',
        title:
            'Сколько раз у игрока, владеющего шайбой, выбивают из под клюшки шайбу или выносят шайбу из поля действия или когда игрок перекрывает телом шайбу от соперника'
    },
    'statistics.possession.bodyChecks': 'Отборы корпусом',
    'statistics.possession.faceOffWon': 'Выигранные вбрасывания',
    'statistics.possession.faceOffWon%': {
        message: 'Выигранные вбрасывания,\u00A0%',
        title: 'Процент выигранных вбрасываний'
    },
    'statistics.possession.faceOffLost': 'Проигранные вбрасывания',
    'statistics.possession.giveaways': 'Потери',
    'statistics.possession.giveawaysPercent': {
        message: 'Потери,\u00A0%',
        title: 'Количество владений шайбой или передач, закончившихся потерей, разделенное на количество владений'
    },
    'statistics.possession.possessionCount': {
        message: 'Число владений',
        title: 'Владение начинается с первого касания шайбы игроком. Общее количество владений шайбы игроком'
    },
    'statistics.possession.possessionPlayerValue': 'Владения игрока',
    'statistics.possession.possessionTeamValue': 'Владения команды',
    'statistics.possession.giveawaysPlayerValue': 'Потери игрока',
    'statistics.possession.giveawaysTeamValue': 'Потери команды',
    'statistics.possession.possessionTime': {
        message: 'Время владения',
        title:
            'Игрок контролирует шайбу или совершает передачу по борту, после которой шайба возвращается обратно к нему'
    },
    'statistics.possession.recoveries': {
        message: 'Нейтральные шайбы',
        title: 'Подбор шайбы, которой не владеет ни одна из команд'
    },
    'statistics.possession.reboundRecoveries': {
        message: 'Подборы',
        title: 'Подбор шайбы, которой не владеет ни одна из команд'
    },
    'statistics.possession.takeaways': {
        message: 'Перехваты',
        title: 'Число моментов, когда игрок подбирал шайбу, отскочившую после сейва'
    },
    'statistics.possession.timePerPossession': {
        message: 'Среднее время владения',
        title: 'Время владения шайбой, разделенное на общее количество владений'
    },
    'statistics.possession.percentTeamPossessions': '%\u00A0от владения команды',
    'statistics.possession.stickChecks': 'Отборы клюшкой',
    'statistics.possession.infobar': 'Среднее по таблице',
    'statistics.possession.table': 'Таблица владения',
    'statistics.possession.possessionTimeAVG': 'Ср. владение',
    'statistics.possession.possessionCountAVG': 'Ср. владений',
    'statistics.possession.takeawaysAVG': 'Ср. перехватов',
    'statistics.possession.recoveriesAVG': 'Ср. ничейных шайб',
    'statistics.possession.reboundRecoveriesAVG': 'Ср. подборов',
    'statistics.possession.stickChecksAVG': 'Ср. отборов клюшкой',
    'statistics.possession.bodyChecksAVG': 'Ср. отборов корпусом',
    'statistics.possession.faceOffWonAVG': 'Ср. выигранных вбрасываний',
    'statistics.possession.faceOffLostAVG': 'Ср. проигранных вбрасываний',
    'statistics.skating.heatmap': 'Тепловая карта',
    'statistics.skating.skating': 'График темпа',
    'statistics.skating.byPlayers': 'По игрокам',
    'statistics.skating.players': 'Игрок',
    'statistics.skating.playtime': {
        message: 'Время на льду',
        title: 'Чистое время, проведенное игроком на льду'
    },
    'statistics.skating.distance': {
        message: 'Дистанция, ',
        title: 'Дистанция, которую игрок преодолел'
    },
    'statistics.skating.speed': {
        message: 'Скорость, ',
        title: 'Скорость катания игрока на льду'
    },
    'statistics.skating.possesionSpeed': {
        message: 'Средняя скорость с шайбой, ',
        title: 'Скорость катания с шайбой на крюке'
    },
    'statistics.skating.acceleration': {
        message: 'Ускорение, м/с²',
        title: 'Значение изменения скорости в сторону увеличения по отношению к единице времени'
    },
    'statistics.skating.pace': {
        message: 'Темп,\u00A0%',
        title: 'Дистанция, которую игрок преодолел на скорости выше 20км/ч, разделенная на общую дистанцию игрока'
    },
    'statistics.skating.backchecking': {
        message: 'Темп в оборону,\u00A0%',
        title:
            'Дистанция, которую игрок преодолел на скорости выше 20км/ч при возврате в оборону, разделенная на общую дистанцию возврата в оборону'
    },
    'statistics.skating.shift': {
        message: 'Средняя длина смены',
        title: 'Средняя длительность смены (началом смены считается первое касание коньком льда)'
    },
    'statistics.skating.throughout': 'По ходу смены',
    'statistics.skating.secondsIntoTheShift': 'СЕКУНДА СМЕНЫ',
    'statistics.skating.chart': 'Усталость в смене',
    'statistics.skating.chartPace': 'ТЕМП',
    'statistics.skating.timeOnIce': 'Время на льду',
    'statistics.skating.speedWithThePuck': 'Скорость с шайбой, ',
    'statistics.skating.shiftTime': 'Средняя смена',
    'statistics.skating.avgRestTime': 'Ср. время между сменами',
    'statistics.skating.infobar': 'Среднее по таблице',
    'statistics.skating.table': 'Таблица катания',
    'statistics.skating.totalDistance': 'Общая дистанция',
    'statistics.skating.totalShiftsLength': 'Общая длительность смен',
    'statistics.skating.shifts': 'Смен',
    'statistics.shifts.chart': 'График смен',
    'statistics.shifts.table': 'Таблица смен',
    'statistics.shifts.statistics': 'Статистика игрока',
    'statistics.shifts.startShiftTime': 'Начало',
    'statistics.shifts.endShiftTime': 'Конец',
    'statistics.shifts.eventType': 'Тип',
    'statistics.shifts.strengths': 'Составы',
    'statistics.shifts.OZtimeFor': 'Время в зоне атаки',
    'statistics.shifts.OZtimeAgainst': 'Время в зоне защиты',
    'statistics.shifts.shotAttemptsFor': 'Броски команды',
    'statistics.shifts.shotAttemptsAgainst': 'Броски соперника',
    'statistics.shifts.possessionTime': 'Владение',
    'statistics.shifts.reboundRecoveries': 'Подборы',
    'statistics.shifts.PPA': 'ПГП (основная)',
    'statistics.shifts.SPA': 'ПГП (вторичная)',
    'statistics.shifts.onGoal': 'В створ',
    'statistics.shifts.blocked': 'Заблокированные',
    'statistics.shifts.missed': 'Не в створ',
    'statistics.shifts.shiftLength': 'Длительность смены',
    'statistics.shifts.distance': {
        message: 'Дистанция, ',
        title: 'Дистанция, преодоленная во время смены'
    },
    'statistics.shifts.pace': {
        message: 'Темп, %',
        title:
            'Средний темп (дистанция, преодоленная на скорости выше 20 км/ч - 12,43 м/ч, разделенная на общую дистанцию)'
    },
    'statistics.shifts.backcheckPace': {
        message: 'Темп в обороне, %',
        title:
            'Дистанция, которую игрок преодолел на скорости выше 20км/ч при возврате в оборону, разделенная на общую дистанцию возврата в оборону'
    },
    'statistics.shifts.OZtime': {
        message: 'Время в зоне атаки, %',
        title: 'Процент от общего времени владения, когда шайба была в зоне атаки'
    },
    'statistics.shifts.shotAttemptsOnIce': {
        message: 'Броски, %',
        title: 'Процент бросков в створ команды от общего числа бросков в створ'
    },
    'statistics.shifts.possessionCount': {
        message: 'Число владений',
        title: 'Количество уникальных владений шайбы командой во время смены'
    },
    'statistics.shifts.giveaways': {
        message: 'Потери',
        title: 'Количество потерь команды во время смены'
    },
    'statistics.shifts.takeaways': {
        message: 'Перехваты',
        title: 'Кол-во перехватов команды во время смены'
    },
    'statistics.shifts.recoveries': {
        message: 'Нейтральные шайбы',
        title: 'Кол-во подборов команды во время смены'
    },
    'statistics.shifts.passes': {
        message: 'Передачи',
        title: 'Количество пассов, сделанных командой в течение смены'
    },
    'statistics.shifts.shotAttempts': {
        message: 'Броски',
        title: 'Количество бросков, сделанных командой в течение смены'
    },
    'statistics.shifts.goals': {
        message: 'Голы',
        title: ''
    },
    'statistics.shifts.assists': {
        message: 'Ассисты',
        title: ''
    },
    'statistics.shifts.faceOffWon': {
        message: 'Face off won',
        title: ''
    },
    'statistics.shifts.faceOffLost': {
        message: 'Face off lost',
        title: ''
    },
    'statistics.shifts.xG': {
        message: 'xG',
        title: 'Общая сумма XG, сделанных командой в течение смены'
    },
    'statistics.shifts.shotsOnGoal': {
        message: 'Броски в створ'
    },
    'statistics.shifts.numberOfShifts': {
        message: 'Количество смен'
    },
    'statistics.shifts.averageShift': {
        message: 'Среднее время смены'
    },
    'statistics.shifts.toi': {
        message: 'Время на льду'
    },
    'statistics.shifts.period': 'Период',
    'statistics.shifts.startTime': 'Начало',
    'statistics.shifts.endTime': 'Конец',
    'statistics.shifts.duration': 'Длительность',

    'configurator.customTable': 'Таблица',
    'configurator.metrics': 'Метрики',

    'tableBuilder.selectPreset': 'Выбрать пресет',
    'tableBuilder.save': 'Сохранить',
    'tableBuilder.enterPresetName': 'Введите название пресета: ',
    'tableBuilder.editColumns': 'Редактировать',

    /**************************************************************Game score***********************************************/
    'gameScore.playersTable.title': 'Игроки',
    'gameScore.playersTable.gamesCount': 'Игры',
    'gameScore.playersTable.totalAvgGameScore': 'Ср. оценка игры',
    'gameScore.playersTable.offensiveAvgGameScore': 'Ср. оценка игры в атаке',
    'gameScore.playersTable.defensiveAvgGameScore': 'Ср. оценка игры в обороне',
    'gameScore.playersTable.toi': 'Общ. время на льду',
    'gameScore.playersTable.ppToi': 'Время на льду в бол.',
    'gameScore.playersTable.pkToi': 'Время на льду в мен.',
    'gameScore.gameScoreWidget.title': 'Оценка игры',
    'gameScore.gameScoreWidget.goaltenders': 'Вратари',
    'gameScore.gameScoreWidget.overall': 'Общее',
    'gameScore.gameScoreWidget.offensive': 'Атака',
    'gameScore.gameScoreWidget.defensive': 'Защита',
    'gameScore.gameScoreWidget.header.metric': 'Показатель',
    'gameScore.gameScoreWidget.header.value': 'Значение',
    'gameScore.gameScoreWidget.header.value.player': 'Игрока',
    'gameScore.gameScoreWidget.header.value.avg': 'Среднее',
    'gameScore.gameScoreWidget.header.delta': 'Разница',
    'gameScore.gameScoreWidget.header.insight': 'Описание (относительно ср. по команде)',
    'gameScore.metric.relations.more': "${values.secondary ? 'более' : 'больше'}",
    'gameScore.metric.relations.less': "${values.secondary ? 'менее' : 'меньше'}",
    'gameScore.metric.xg.title': 'xG',
    'gameScore.metric.xg.def': 'Броски игрока были совокупно на {percentage} {relation} опасны.',
    'gameScore.metric.goals.title': 'Голы',
    'gameScore.metric.goals.def': 'Игрок забил на {percentage} {relation} голов.',
    'gameScore.metric.xgPP.title': 'xG  (большинство)',
    'gameScore.metric.xgPP.def': 'Броски игрока в большинстве были совокупно на {percentage} {relation} опасны.',
    'gameScore.metric.xgPerShot.title': 'xG / бросок',
    'gameScore.metric.xgPerShot.def': 'Броски игрока в среднем были на {percentage} {relation} опасны.',
    'gameScore.metric.xgPerShotOnGoal.title': 'xG / бросок в створ (большинство)',
    'gameScore.metric.xgPerShotOnGoal.def':
        'Броски игрока в большинстве в среднем были на {percentage} {relation} опасны.',
    'gameScore.metric.xa.title': 'xA',
    'gameScore.metric.xa.def': 'Передачи игрока под удар были на {percentage} {relation} опасны.',
    'gameScore.metric.xaPP.title': 'xA  (большинство)',
    'gameScore.metric.xaPP.def': 'Передачи игрока под удар в большинстве были на {percentage} {relation} опасны.',
    'gameScore.metric.xa2.title': 'xA2',
    'gameScore.metric.xa2.def': 'Предпоследние передачи игрока перед броском были на {percentage} {relation} опасны.',
    'gameScore.metric.xa2PP.title': 'xA2  (большинство)',
    'gameScore.metric.xa2PP.def':
        'Предпоследние передачи игрока перед броском в большинстве были на {percentage} {relation} опасны.',
    'gameScore.metric.keyPasses.title': 'Ключевые пасы',
    'gameScore.metric.keyPasses.def': 'Игрок сделал на {percentage} {relation} передач, ведущих к опасному броску.',
    'gameScore.metric.entries.title': 'Входы',
    'gameScore.metric.entries.def': 'Игрок совершил на {percentage} {relation} входов в зону.',
    'gameScore.metric.controlledEntries.title': 'Входы с контролем',
    'gameScore.metric.controlledEntries.def':
        'Игрок совершил на {percentage} {relation} входов в зону с контролем (через ведение или передачу).',
    'gameScore.metric.successfulEntries.title': 'Успешные входы, %',
    'gameScore.metric.successfulEntries.def': 'Успешность входов игрока на {percentage} {relation}.',
    'gameScore.metric.controlledSuccessfulEntries.title': 'Успешные входы с контролем, %',
    'gameScore.metric.controlledSuccessfulEntries.def':
        'Успешность входов с контролем игрока на {percentage} {relation}.',
    'gameScore.metric.xgRebound.title': 'xG  (Отскоки)',
    'gameScore.metric.xgRebound.def': 'Броски игрока после отскоков были совокупно на {percentage} {relation} опасны.',
    'gameScore.metric.recoveries.title': 'Нейтральные шайбы',
    'gameScore.metric.recoveries.def': 'Игрок совершил на {percentage} {relation} подборов ничейных шайб.',
    'gameScore.metric.rebounds.title': 'Отскоки',
    'gameScore.metric.rebounds.def': 'Игрок совершил на {percentage} {relation} подборов после отскоков.',
    'gameScore.metric.recoveriesOz.title': 'Нейтральные шайбы в зоне атаки',
    'gameScore.metric.recoveriesOz.def':
        'Игрок совершил на  {percentage} {relation} подборов ничейных шайб в зоне атаки.',
    'gameScore.metric.reboundsOz.title': 'Отскоки в зоне атаки',
    'gameScore.metric.reboundsOz.def':
        'Игрок совершил на {percentage} {relation} подборов после отскоков в зоне атаки.',
    'gameScore.metric.checks.title': 'Отборы',
    'gameScore.metric.checks.def': 'Игрок совершил на {percentage} {relation} отборов.',
    'gameScore.metric.recoveriesDz.title': 'Нейтральные шайбы в зоне защиты',
    'gameScore.metric.recoveriesDz.def':
        'Игрок совершил на {percentage} {relation} подборов ничейных шайб в зоне защиты.',
    'gameScore.metric.reboundsDz.title': 'Отскоки в зоне защиты',
    'gameScore.metric.reboundsDz.def':
        'Игрок совершил на {percentage} {relation} подборов после отскоков в зоне защиты.',
    'gameScore.metric.interceptions.title': 'Перехваты',
    'gameScore.metric.interceptions.def': 'Игрок совершил на {percentage} {relation} перехватов.',
    'gameScore.metric.interceptionsOz.title': 'Перехваты в зоне атаки',
    'gameScore.metric.interceptionsOz.def': 'Игрок совершил на {percentage} {relation} перехватов в зоне атаки.',
    'gameScore.metric.interceptionsDz.title': 'Перехваты в зоне защиты',
    'gameScore.metric.interceptionsDz.def': 'Игрок совершил на {percentage} {relation} перехватов в зоне защиты.',
    'gameScore.metric.xgAgainst.title': 'xG против',
    'gameScore.metric.xgAgainst.def': 'Игрок допустил на {percentage} {relation} опасные броски, пока был на льду.',
    'gameScore.metric.dispossessed.title': 'Потери в борьбе',
    'gameScore.metric.dispossessed.def': 'Игрок потерял в борьбе на {percentage} {relation} владений шайбы.',
    'gameScore.metric.dispossessedDz.title': 'Потери в борьбе  в зоне защиты',
    'gameScore.metric.dispossessedDz.def':
        'Игрок потерял в борьбе в зоне защиты на {percentage} {relation} владений шайбы.',
    'gameScore.metric.xgAgainstNearNet.title': 'xG против  (возле ворот)',
    'gameScore.metric.xgAgainstNearNet.def':
        'Игрок допустил на {percentage} {relation} опасные броски из зоны перед воротами, пока был на льду.',
    'gameScore.metric.keyPassesAgainst.title': 'Ключевые пасы против',
    'gameScore.metric.keyPassesAgainst.def':
        'Игрок допустил на {percentage} {relation} передач, ведущих к опасному броску.',
    'gameScore.metric.xgAgainstEntries.title': 'xG против (после входов)',
    'gameScore.metric.xgAgainstEntries.def':
        'Игрок допустил на {percentage} {relation} опасные броски за три секунды после входов противника, пока был на льду.',
    'gameScore.metric.blockedShots.title': 'Заблокированные броски',
    'gameScore.metric.blockedShots.def': 'Игрок заблокировал на {percentage} {relation} бросков.',
    'gameScore.metric.xgShotAttemptsAgainst.title': 'xG / бросок против',
    'gameScore.metric.xgShotAttemptsAgainst.def':
        'Игрок допустил в среднем за бросок {percentage} {relation} опасные броски.',
    'gameScore.metric.avgSpeed.title': 'Средняя скорость',
    'gameScore.metric.avgSpeed.def': 'Средняя скорость игрока на {percentage} {relation}.',
    'gameScore.metric.pace.title': 'Темп, %',
    'gameScore.metric.pace.def': 'Игрок проходит на высокой скорости на {percentage} {relation} пройденной дистанции.',
    'gameScore.metric.backCheckPace.title': 'Темп в оборону, %',
    'gameScore.metric.backCheckPace.def':
        'Игрок проходит на высокой скорости на {percentage} {relation} пройденной дистанции при возврате в оборону.',
    'gameScore.metric.distanceCovered.title': 'Дистанция',
    'gameScore.metric.distanceCovered.def': 'Игрок проходит на {percentage} {relation} дистанции.',
    'gameScore.metric.acceleration.title': 'Ускорение',
    'gameScore.metric.acceleration.def': 'Ускорения игрока на {percentage} {relation}.',
    'gameScore.metric.xgAgainstShotsOnGoal.title': 'xG против (броски в створ)',
    'gameScore.metric.xgAgainstShotsOnGoal.def': 'Совокупная опасность бросков в створ соперника на {value}.',
    'gameScore.metric.goalsAgainstShotsOnGoal.title': 'Голы против  (броски в створ)',
    'gameScore.metric.goalsAgainstShotsOnGoal.def': 'Количество пропущенных шайб  {value}.',
    'gameScore.metric.xgAgainstNearNetGoaltenders.title': 'xG против (возле ворот)',
    'gameScore.metric.xgAgainstNearNetGoaltenders.def':
        'Совокупная опасность бросков соперника из зоны перед воротами на {value}.',
    'gameScore.metric.goalsAgainstNearNet.title': 'Голы против  (возле ворот)',
    'gameScore.metric.goalsAgainstNearNet.def': 'Количество пропущенных шайб из зоны перед воротами на {value}.',
    'gameScore.metric.saves.title': 'Сейвы, %',
    'gameScore.metric.saves.def': 'Процент отраженных бросков на {value}.',
    'gameScore.metric.savesNearNet.title': 'Сейвы, % (возле ворот)',
    'gameScore.metric.savesNearNet.def': 'Процент отраженных бросков из зоны возле ворот  {value}.',
    'playerPage.modal.title': 'Выбрано менее 5 игр.',
    'shiftsPage.modal.title': 'Игрок не участвовал в игре.',
    'shiftsPageMiss.modal.title': 'Нет данных для этого игрока.',
    'playerPage.modal.description': ' Данные на странице могут быть неточными.',
    'playerPage.modal.advice': ' Пожалуйста, выберите более 5 игр для более точной оценки показателей.',
    'playerPage.playerStats.title': 'Статистика игрока',
    'playerPage.playerStats.games': 'Игры',
    'playerPage.playerStats.avgToi': 'Время (ср.)',
    'playerPage.playerStats.goals': 'Голы',
    'playerPage.playerStats.assists': 'Передачи',
    'playerPage.playerStats.avgGs': 'Оценка',
    'playerPage.playerStats.gameScoreTrend': 'Динамика оценки игры',
    'playerPage.playerStats.hotTitle': 'В трех последних играх играл значительно лучше, чем в предыдущих.',
    'playerPage.playerStats.coldTitle': 'В трех последних играх играл значительно хуже, чем в предыдущих.',
    'playerPage.playerStats.PK': 'В меньшинстве',
    'playerPage.playerStats.EV': 'В равных составах',
    'playerPage.playerStats.PP': 'В большинстве',
    'playerPage.playerStats.skillsChart': 'Диаграмма показателей',
    'playerPage.lastSeason': 'Последний сезон',
    'playerPage.radar.skills': 'Статистика игрока',
    'playerPage.radar.stat.successPasses': 'Точность передач',
    'playerPage.radar.stat.pace': 'Темп',
    'playerPage.radar.stat.xgPer20': 'xG / 20',
    'playerPage.radar.stat.giveaways': 'Владение шайбой',
    'playerPage.radar.stat.xgForPer20': 'xG за / 20',
    'playerPage.radar.stat.xgAgainstPer20': 'xG против /\u00A020',
    'playerPage.radar.stat.xgPerShot': 'xG / бросок',
    'playerPage.radar.stat.reboundRecoveriesPer20': 'Подборы / 20',
    'playerPage.radar.stat.checksPer20': 'Отборы / 20',
    'playerPage.radar.stat.speedWithPuck': 'Скор.\u00A0с шайбой',
    'playerPage.radar.stat.xgOnIce': 'xG на льду',
    'playerPage.radar.stat.xaPer20': 'xA / 20',
    'playerPage.radar.stat.controlledEntries': 'Контролир. входы',
    'playerPage.radar.stat.interceptionsPer20': ' Перехваты\u00A0/ 20',
    'playerPage.radar.stat.successExits': 'Успешные выходы',
    'playerPage.radar.stat.successThroughtPassesPer20': ' Усп. разрез. передачи / 20',
    'playerPage.radar.stat.reboundRecoveriesDzPer20': 'Подборы\u00A0 отск.\u00A0/\u00A020',
    'playerPage.radar.stat.shotBlocking': 'Блокировка бросков',
    'playerPage.radar.stat.shotsPer20': 'Броски / 20',
    'playerPage.eventMap.title': 'Карта событий',
    'playerPage.eventMap.tabs.FACE_OFFS': 'Вбрасывания',
    'playerPage.eventMap.tabs.RECOVERIES': 'Подборы',
    'playerPage.eventMap.tabs.SHOTS': 'Броски',
    'playerPage.skillsTable.header': 'Показатели игрока',
    'playerPage.skillsTable.shooting': 'Броски',
    'playerPage.skillsTable.shots_on_goal_20': 'Броски в створ',
    'playerPage.skillsTable.shot_accuracy': 'Точность бросков, %',
    'playerPage.skillsTable.xg_20': 'xG',
    'playerPage.skillsTable.shots_blocked_20': 'Блок. броски',
    'playerPage.skillsTable.shots_lth_20': 'Броски после пер. на СЛ',
    'playerPage.skillsTable.xg_lth': 'xG после пер. на СЛ',
    'playerPage.skillsTable.xg_shot_attempt': 'xG за бросок',
    'playerPage.skillsTable.shooting_from_danger_zone_percent': 'Броски из опасной зоны, %',
    'playerPage.skillsTable.transition': 'Переход в атаку',
    'playerPage.skillsTable.controlled_entries_percent': 'Входы с контролем, %',
    'playerPage.skillsTable.successful_entries_percent': 'Успешные входы, %',
    'playerPage.skillsTable.avg_speed_with_puck_nz': 'Скор. с шайбой (НЗ)',
    'playerPage.skillsTable.successful_passes_into_nz_20': 'Успешные пасы в НЗ',
    'playerPage.skillsTable.controlled_exits_percent': 'Выходы с контролем, %',
    'playerPage.skillsTable.successful_exits_percent': 'Успешные выходы',
    'playerPage.skillsTable.skating': 'Катание',
    'playerPage.skillsTable.avg_pace': 'Темп, %',
    'playerPage.skillsTable.backchecking_pace_percent': 'Темп в оборону, %',
    'playerPage.skillsTable.avg_speed': 'Ср. скорость',
    'playerPage.skillsTable.avg_shifts_length': 'Ср. длина смены',
    'playerPage.skillsTable.acceleration': 'Ускорение',
    'playerPage.skillsTable.avg_speed_with_puck': 'Скорость с шайбой',
    'playerPage.skillsTable.distance': 'Дистанция',
    'playerPage.skillsTable.onIce': 'На льду',
    'playerPage.skillsTable.xg_danger_zone_against': 'xG против из ОЗ',
    'playerPage.skillsTable.puck_time_into_oz_percent': 'Время в зоне атаки %',
    'playerPage.skillsTable.shots_percent': 'Броски за, %',
    'playerPage.skillsTable.xg_percent': 'xG %',
    'playerPage.skillsTable.on_ice_xg_for_20': 'xG за',
    'playerPage.skillsTable.on_ice_xg_against_20': 'xG против',
    'playerPage.skillsTable.passingAndPossession': 'Владение и передачи',
    'playerPage.skillsTable.individual': 'individual',
    'playerPage.skillsTable.giveaways_percent': 'Потери, %',
    'playerPage.skillsTable.interceptions_20': 'Перехваты',
    'playerPage.skillsTable.rebound_recoveries_20': 'Подборы',
    'playerPage.skillsTable.checks_20': 'Отборы',
    'playerPage.skillsTable.possession_time_20': 'Время владения',
    'playerPage.skillsTable.blocked_shots_percent': 'Заблок. броски %',
    'playerPage.skillsTable.dispossessed_dz': 'Потери в борьбе (ЗЗ)',
    'playerPage.skillsTable.rebound_recoveries_oz_20': 'Подборы с отскоков (ЗА)',
    'playerPage.skillsTable.successful_passes_percent': 'Успешные передачи, %',
    'playerPage.skillsTable.xa_20': 'xA',
    'playerPage.skillsTable.xa2_20': 'xA 2',
    'playerPage.skillsTable.successful_through_passes': 'Усп. обостр. передачи',
    'playerPage.skillsTable.key_passes_20': 'Ключевые передачи',

    'playerPage.skillsTable.potentialPrimaryAssistsPer20': 'Потенциальная первая передача',
    'playerPage.skillsTable.potentialSecondaryAssistsPer20': 'Потенциальная вторая передача',
    'playerPage.skillsTable.realPrimaryAssistsPer20': 'Первая передача',
    'playerPage.skillsTable.realSecondaryAssistsPer20': 'Вторая передача',
    'playerPage.skillsTable.successfulPassesPercent': 'Успешных передач, %',
    'playerPage.skillsTable.reboundRecoveriesOzPer20': 'Подборы в атаке',
    'playerPage.skillsTable.reboundRecoveriesDzPer20': 'Подборы в защите',
    'playerPage.skillsTable.possessionTimePer20': 'Время владения',
    'playerPage.skillsTable.possessionTimeForPercent': 'Время владения команды, %',
    'playerPage.skillsTable.possessionsPer20': 'Владений',
    'playerPage.skillsTable.controlledEntriesPercent': 'Входы с контролем, %',
    'playerPage.skillsTable.controlledExitsPercent': 'Выходы с контролем, %',
    'playerPage.skillsTable.successfulEntriesPercent': 'Успешные входы, %',
    'playerPage.skillsTable.successfulExitsPercent': 'Успешные выходы, %',
    'playerPage.skillsTable.timePerPossession': 'Ср. время владения',
    'playerPage.skillsTable.giveawaysPercent': 'Потери,\u00A0',
    'playerPage.skillsTable.takeawaysPer20': 'Перехваты',
    'playerPage.skillsTable.recoveriesPer20': 'Подборы',
    'playerPage.skillsTable.shotsPer20': 'Броски',
    'playerPage.skillsTable.shotsOnGoalPer20': 'Броски в створ',
    'playerPage.skillsTable.xgPer20': 'xG',
    'playerPage.skillsTable.xgDangerZonePer20': 'xG из опасной зоны',
    'playerPage.skillsTable.xgPerShot': 'xG за бросок',
    'playerPage.skillsTable.puckTimeOzPercent': 'Время шайбы в атаке,\u00A0%',
    'playerPage.skillsTable.puckTimeNzPercent': 'Время шайбы в нейтральной зоне,\u00A0%',
    'playerPage.skillsTable.puckTimeDzPercent': 'Время шайбы в защите,\u00A0%',
    'playerPage.skillsTable.xgForPercent': 'xG за,\u00A0%',
    'playerPage.skillsTable.xgForPer20': 'xG за',
    'playerPage.skillsTable.xgAgainstPer20': 'xG против',
    'playerPage.skillsTable.shotsOnGoalPercent': 'Бросков в створ,\u00A0%',
    'playerPage.skillsTable.shotsAgainstPer20': 'Броски против',
    'playerPage.skillsTable.shotsOnGoalAgainstPer20': 'Броски в створ против',
    'playerPage.skillsTable.scoringChancesForPer20': 'Голевые моменты за',
    'playerPage.skillsTable.scoringChancesAgainstPer20': 'Голевые моменты против',

    'playerPage.skillsChart.games': 'Игры',
    'playerPage.skillsChart.skillsDynamicsChart': 'Динамика показателей',
    'playerPage.skillsChart.descriptions.shots_on_goal_20': 'Броски в створ за 20 минут игрового времени.',
    'playerPage.skillsChart.descriptions.shot_accuracy':
        'Процент бросков, достигших створа ворот (от общего количества бросков).',
    'playerPage.skillsChart.descriptions.xg_20':
        'Вероятные голы (xG), созданные за 20 минут игрового времени. Метрика состоит из комбинации более 30 параметров, таких как угол и дистанция броска, трафик перед воротами и перемещение вратаря.',
    'playerPage.skillsChart.descriptions.shots_blocked_20':
        'Количество бросков, заблокированных противником за 20 минут от общего игрового времени.',
    'playerPage.skillsChart.descriptions.shots_lth_20':
        'Броски в зоне атаки после передачи из глубины на синюю линию. Передача из глубины на синюю линию - когда шайба двигается из глубины зоны атаки к синей линии и может быть переправлена как пас или бросок в ворота.',
    'playerPage.skillsChart.descriptions.xg_lth': 'xG при получении игроком паса из глубины на синюю линию. ',
    'playerPage.skillsChart.descriptions.xg_shot_attempt':
        'Вероятные голы (xG), разделенные на количество бросков. Данный метрик показывает усредненную ценность броска.',
    'playerPage.skillsChart.descriptions.shooting_from_danger_zone_percent':
        'Процент бросков из опасной зоны, достигших створа ворот. Вероятность забить гол из опасной зоны очень высока, и точность таких бросков увеличивает шансы забить гол или добить с отскока.',
    'playerPage.skillsChart.descriptions.controlled_entries_percent':
        'Контролируемые входы в зону атаки: таковыми являются входы через пас или ведение.',
    'playerPage.skillsChart.descriptions.successful_entries_percent':
        'Процент попыток контролируемого входа, завершившихся владением шайбы в зоне атаки.',
    'playerPage.skillsChart.descriptions.avg_speed_with_puck_nz':
        'Средняя скорость игрока с шайбой в средней зоне. Высокая скорость прохождения средней зоны вынуждает соперника терять позицию и увеличивает вероятность успешного входа в зону атаки.',
    'playerPage.skillsChart.descriptions.successful_passes_into_nz_20': 'Процент точных передач в средней зоне.',
    'playerPage.skillsChart.descriptions.controlled_exits_percent':
        'Контролируемые выходы из зоны обороны: выходы через пас или ведение.',
    'playerPage.skillsChart.descriptions.successful_exits_percent':
        'Процент попыток контролируемого выхода, завершившихся владением шайбы в средней зоне.',
    'playerPage.skillsChart.descriptions.avg_pace':
        'Процент дистанции, которую игрок проехал на скорости выше среднего (20 км/ч), от общей пройденной дистанции. Как правило, нападающие играют в более быстромскоростном темпе, чем защитники.',
    'playerPage.skillsChart.descriptions.backchecking_pace_percent':
        'Процент дистанции при движении в оборону, которую игрок проехал на скорости выше среднего (20 км/ч), от общей пройденной дистанции. Высокий темп в оборону означает, что игрок быстро возвращается в зону обороны.',
    'playerPage.skillsChart.descriptions.avg_speed': 'Данная метрика показывает среднюю скорость игрока на льду.',
    'playerPage.skillsChart.descriptions.avg_shifts_length': 'Средняя продолжительность смены игрока за игру.',
    'playerPage.skillsChart.descriptions.acceleration': 'Среднее ускорение игрока.',
    'playerPage.skillsChart.descriptions.avg_speed_with_puck':
        'Средняя скорость игрока с шайбой показывает как быстро игрок двигается при контроле шайбы.',
    'playerPage.skillsChart.descriptions.distance': 'Дистанция, которую игрок проезжает за 20 минут игрового времени.',
    'playerPage.skillsChart.descriptions.xg_danger_zone_against':
        'Общий xG, созданный соперниками в опасной зоне команды пока игрок был на льду. (Усредненный показатель за 20 минут от общего времени).',
    'playerPage.skillsChart.descriptions.puck_time_into_oz_percent':
        'Время, которое шайба провела в зоне атаки, в момент нахождения игрока на льду.',
    'playerPage.skillsChart.descriptions.shots_percent':
        'Процент бросков команды в створ ворот соперника во время нахождения игрока на льду. Любое значение свыше 50% означает, что команда чаще бросает по воротам соперника, когда игрок находится на льду.',
    'playerPage.skillsChart.descriptions.xg_percent':
        'Процент xG созданный командой, когда игрок был на льду. Любое значение свыше 50% означает, что команда создала больше вероятных голов(xG), чем соперник, когда игрок был на льду.',
    'playerPage.skillsChart.descriptions.on_ice_xg_for_20':
        'Общая вероятность гола (xG), созданная командой, когда игрок был на льду.',
    'playerPage.skillsChart.descriptions.on_ice_xg_against_20':
        'Общая вероятность гола (xG), созданная командой соперника, когда игрок был на льду.',
    'playerPage.skillsChart.descriptions.giveaways_percent':
        'Процент потерь. Данная метрика показывает, какой процент всех владений игрока заканчивается потерей шайбы (кроме попыток броска, умышленного выброса или вброса).',
    'playerPage.skillsChart.descriptions.interceptions_20': 'Количество перехватов передач соперника.',
    'playerPage.skillsChart.descriptions.rebound_recoveries_20':
        'Количество подборов нейтральной шайбы за 20 минут игрового времени. Нейтральная шайба означает, что ни одна из команд ею не владеет.',
    'playerPage.skillsChart.descriptions.checks_20':
        'Отбор шайбы клюшкой или корпусом. Рассчитывается за 20 минут от общего игрового времени.',
    'playerPage.skillsChart.descriptions.possession_time_20':
        'Количество времени, в течение которого игрок контролировал шайбу. Считается общее количество владения во всех зонах (обороны, средней, атаки).',
    'playerPage.skillsChart.descriptions.blocked_shots_percent':
        'Отношение заблокированных игроком бросков к броскам, которые он мог потенциально заблокировать. Высокий процент показывает, что игрок хорош в блокировке бросков соперника.',
    'playerPage.skillsChart.descriptions.dispossessed_dz':
        'Метрика показывает, сколько раз у игрока отнимали шайбу в зоне защиты. Для защитников небольшое количество таких потерь говорит о том, что игрок хорошо контролирует шайбу в зоне защиты.',
    'playerPage.skillsChart.descriptions.rebound_recoveries_oz_20':
        'Количество подборов, совершенных игроком после отскока шайбы от вратаря. Данная метрика рассчитывается для выявления количества подборов совершенных за 20 минут игрового времени.',
    'playerPage.skillsChart.descriptions.successful_passes_percent':
        'Процент точности передач. Данная метрика рассчитывается делением количества точных передач на общее количество передач.',
    'playerPage.skillsChart.descriptions.xa_20':
        'Вероятность гола (xG) броска после первой потенциальной голевой передачи игрока.',
    'playerPage.skillsChart.descriptions.xa2_20':
        'Вероятность гола (xG) броска после второй потенциальной голевой передачи игрока.',
    'playerPage.skillsChart.descriptions.successful_through_passes':
        'Количество передач за 20 минут от общего времени проведенного игроком на льду в сторону ворот соперника, которые могли быть перехвачены противником. Данная метрика показывает способность игрока точно отдавать сложные передачи.',
    'playerPage.skillsChart.descriptions.key_passes_20':
        'Данная метрика измеряет количество ключевых передач игрока, после которых совершаются броски с наибольшей вероятностью гола (xG).',
    'playerPage.barChart.allGames': 'все игры',
    'playerPage.barChart.lastTenGames': 'последние 10',
    'playerPage.barChart.tooltip.game': 'Игра',
    'playerPage.barChart.tooltip.gameScore': 'Оценка игры',
    'playerComparison.tool.title': 'Сравнение игроков',
    'playerComparison.chart.controls.xAxis': 'Ось x',
    'playerComparison.chart.controls.yAxis': 'Ось y',
    'playerComparison.chart.controls.calculation': 'Метод расчета',
    'playerComparison.chart.controls.apply': 'Применить ',
    'playerComparison.chart.controls.resetZoom': 'Скрыть зум',
    'playerComparison.chart.controls.returnZoom': 'Показать зум',
    'playerComparison.chart.controls.jerseyVisibility': 'Показывать номера',
    'playerComparison.chart.controls.lastNameVisibility': 'Показывать фамилии',

    'configurator.group.onIce': 'On ice',
    'configurator.group.shifts': 'Shifts',
    'configurator.group.faceoffs': 'Faceoffs',
    'configurator.group.possession': 'Possession',
    'configurator.group.giveaways': 'Giveaways',
    'configurator.group.xG': 'xG',
    'configurator.group.interceptions': 'Interceptions',
    'configurator.group.recoveries': 'Recoveries',
    'configurator.group.checks': 'Checks',
    'configurator.group.entries': 'Entries',
    'configurator.group.exits': 'Exits',
    'configurator.group.boxscore': 'Boxscore',
    'configurator.group.passing': 'Passing',
    'configurator.group.shooting': 'Shooting',
    'configurator.group.pace': 'Pace',

    'playerComparison.chart.controls.compareWith': 'Сравнить с',
    'playerComparison.chart.controls.showOpponent': 'Показать соперников',
    'playerComparison.calculationTypes.RAW': 'АБСОЛЮТНЫЙ',
    'playerComparison.calculationTypes.PER_20': 'ЗА 20 МИНУТ',
    'playerComparison.calculationTypes.AVG': 'СРЕДНЕЕ',
    'playerComparison.metrics.team': 'Команда',
    'playerComparison.metrics.games': {
        message: 'Игр',
        title: 'Количество сыгранных игр'
    },
    'playerComparison.metrics.toi': {
        message: 'Время на льду',
        title: 'Время на льду'
    },
    'playerComparison.metrics.giveaways': {
        message: 'Потери',
        title: 'Потери'
    },
    'playerComparison.metrics.giveaways_dz': {
        message: 'Потери в зоне защиты',
        title: 'Потери в зоне защиты'
    },
    'playerComparison.metrics.giveaways_oz': {
        message: 'Потери в зоне атаки',
        title: 'Потери в зоне атаки'
    },
    'playerComparison.metrics.interceptions': {
        message: 'Перехваты',
        title: 'Перехваты'
    },
    'playerComparison.metrics.interceptions_oz': {
        message: 'Перехваты в зоне атаки',
        title: 'Перехваты в зоне атаки'
    },
    'playerComparison.metrics.interceptions_nz': {
        message: 'Перехваты в средней зоне',
        title: 'Перехваты в средней зоне'
    },
    'playerComparison.metrics.interceptions_dz': {
        message: 'Перехваты в зоне защиты',
        title: 'Перехваты в зоне защиты'
    },
    'playerComparison.metrics.possessions_count': {
        message: 'Количество владений',
        title: 'Количество владений'
    },
    'playerComparison.metrics.possessions_count_oz': {
        message: 'Количество владений в зоне атаки',
        title: 'Количество владений в зоне атаки'
    },
    'playerComparison.metrics.possessions_count_dz': {
        message: 'Количество владений в зоне защиты',
        title: 'Количество владений в зоне защиты'
    },
    'playerComparison.metrics.possessions_count_nz': {
        message: 'Количество владений в средней зоне',
        title: 'Количество владений в средней зоне'
    },
    'playerComparison.metrics.possession_time': {
        message: 'Время владения',
        title: 'Время владения'
    },
    'playerComparison.metrics.possession_time_oz': {
        message: 'Время владения в зоне атаки',
        title: 'Время владения в зоне атаки'
    },
    'playerComparison.metrics.possession_time_dz': {
        message: 'Время владения в зоне защиты',
        title: 'Время владения в зоне защиты'
    },
    'playerComparison.metrics.possession_time_nz': {
        message: 'Время владения в средней зоне',
        title: 'Время владения в средней зоне'
    },
    'playerComparison.metrics.recoveries': {
        message: 'Подборы нейтральных шайб',
        title: 'Подборы нейтральных шайб'
    },
    'playerComparison.metrics.recoveries_oz': {
        message: 'Подборы нейтральных шайб в зоне атаки',
        title: 'Подборы нейтральных шайб в зоне атаки'
    },
    'playerComparison.metrics.recoveries_dz': {
        message: 'Подборы нейтральных шайб в зоне защиты',
        title: 'Подборы нейтральных шайб в зоне защиты'
    },
    'playerComparison.metrics.recoveries_nz': {
        message: 'Подборы нейтральных шайб в средней зоне',
        title: 'Подборы нейтральных шайб в средней зоне'
    },
    'playerComparison.metrics.rebound_recoveries': {
        message: 'Подборы после отскоков',
        title: 'Подборы после отскоков'
    },
    'playerComparison.metrics.rebound_recoveries_oz': {
        message: 'Подборы после отскоков в зоне атаки',
        title: 'Подборы после отскоков в зоне атаки'
    },
    'playerComparison.metrics.rebound_recoveries_dz': {
        message: 'Подборы после отскоков в зоне защиты',
        title: 'Подборы после отскоков в зоне защиты'
    },
    'playerComparison.metrics.rebound_recoveries_nz': {
        message: 'Подборы после отскоков в средней зоне',
        title: 'Подборы после отскоков в средней зоне'
    },
    'playerComparison.metrics.dispossessed': {
        message: 'Потери в борьбе',
        title: 'Потери в борьбе'
    },
    'playerComparison.metrics.dispossessed_oz': {
        message: 'Потери в борьбе в зоне атаки',
        title: 'Потери в борьбе в зоне атаки'
    },
    'playerComparison.metrics.dispossessed_dz': {
        message: 'Потери в борьбе в зоне защиты',
        title: 'Потери в борьбе в зоне защиты'
    },
    'playerComparison.metrics.dispossessed_nz': {
        message: 'Потери в борьбе в средней зоне',
        title: 'Потери в борьбе в средней зоне'
    },
    'playerComparison.metrics.check': {
        message: 'Отборы клюшкой и корпусом',
        title: 'Отборы клюшкой и корпусом'
    },
    'playerComparison.metrics.check_oz': {
        message: 'Отборы клюшкой и корпусом в зоне атаке',
        title: 'Отборы клюшкой и корпусом в зоне атаке'
    },
    'playerComparison.metrics.check_dz': {
        message: 'Отборы клюшкой и корпусом в зоне защиты',
        title: 'Отборы клюшкой и корпусом в зоне защиты'
    },
    'playerComparison.metrics.check_nz': {
        message: 'Отборы клюшкой и корпусом в средней зоне',
        title: 'Отборы клюшкой и корпусом в средней зоне'
    },
    'playerComparison.metrics.body_check': {
        message: 'Отборы корпусом',
        title: 'Отборы корпусом'
    },
    'playerComparison.metrics.body_check_oz': {
        message: 'Отборы корпусом в зоне атаки',
        title: 'Отборы корпусом в зоне атаки'
    },
    'playerComparison.metrics.body_check_dz': {
        message: 'Отборы корпусом в зоне защиты',
        title: 'Отборы корпусом в зоне защиты'
    },
    'playerComparison.metrics.body_check_nz': {
        message: 'Отборы корпусом в средней зоне',
        title: 'Отборы корпусом в средней зоне'
    },
    'playerComparison.metrics.stick_check': {
        message: 'Отборы клюшкой',
        title: 'Отборы клюшкой'
    },
    'playerComparison.metrics.stick_check_dz': {
        message: 'Отборы клюшкой в зоне защиты',
        title: 'Отборы клюшкой в зоне защиты'
    },
    'playerComparison.metrics.stick_check_nz': {
        message: 'Отборы клюшкой в средней зоне',
        title: 'Отборы клюшкой в средней зоне'
    },
    'playerComparison.metrics.stick_check_oz': {
        message: 'Отборы клюшкой в зоне атаки',
        title: 'Отборы клюшкой в зоне атаки'
    },
    'playerComparison.metrics.controlled_successful_entries': {
        message: 'Успешные входы с контролем',
        title: 'Успешные входы с контролем'
    },
    'playerComparison.metrics.uncontrolled_successful_entries': {
        message: 'Успешные входы без контроля',
        title: 'Успешные входы без контроля'
    },
    'playerComparison.metrics.controlled_successful_exits': {
        message: 'Успешные выходы с контролем',
        title: 'Успешные выходы с контролем'
    },
    'playerComparison.metrics.uncontrolled_successful_exits': {
        message: 'Успешные выходы без контроля',
        title: 'Успешные выходы без контроля'
    },
    'playerComparison.metrics.controlled_failed_exits': {
        message: 'Неуспешные выходы с контролем',
        title: 'Неуспешные выходы с контролем'
    },
    'playerComparison.metrics.uncontrolled_failed_exits': {
        message: 'Неуспешные выходы без контроля',
        title: 'Неуспешные выходы без контроля'
    },
    'playerComparison.metrics.controlled_failed_entries': {
        message: 'Неуспешные входы с контролем',
        title: 'Неуспешные входы с контролем'
    },
    'playerComparison.metrics.uncontrolled_failed_entries': {
        message: 'Неуспешные входы без контроля',
        title: 'Неуспешные входы без контроля'
    },
    'playerComparison.metrics.carry_out_count': {
        message: 'Выходы с контролем',
        title: 'Выходы с контролем'
    },
    'playerComparison.metrics.dump_out_count': {
        message: 'Выходы без контроля',
        title: 'Выходы без контроля'
    },
    'playerComparison.metrics.oz_on_ice_time': {
        message: 'Время игрока в зоне атаки',
        title: 'Время игрока в зоне атаки'
    },
    'playerComparison.metrics.dz_on_ice_time': {
        message: 'Время игрока в зоне защиты',
        title: 'Время игрока в зоне защиты'
    },
    'playerComparison.metrics.on_ice_possession_for': {
        message: 'Владение за (пока игрок был на льду)',
        title: 'Владение за (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_possession_against': {
        message: 'Владение против (пока игрок был на льду)',
        title: 'Владение против (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_shots_for': {
        message: 'Броски за (пока игрок был на льду)',
        title: 'Броски за (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_shots_against': {
        message: 'Броски против (пока игрок был на льду)',
        title: 'Броски против (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_shots_on_goal_for': {
        message: 'Голы за (пока игрок был на льду)',
        title: 'Голы за (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_shots_on_goal_against': {
        message: 'Голы против (пока игрок был на льду)',
        title: 'Голы против (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_goals_against_from_danger_zone': {
        message: 'Голы из опасной зоны против (пока игрок был на льду)',
        title: 'Голы из опасной зоны против (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_goals_for': {
        message: 'Голы за (пока игрок был на льду)',
        title: 'Голы за (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_goals_against': {
        message: 'Голы против (пока игрок был на льду)',
        title: 'Голы против (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_xg_for': {
        message: 'xG за (пока игрок был на льду)',
        title: 'xG за (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_xg_against': {
        message: 'xG против (пока игрок был на льду)',
        title: 'xG против (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_scoring_chances_for': {
        message: 'Голевые моменты за (пока игрок был на льду)',
        title: 'Голевые моменты за (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_scoring_chances_against': {
        message: 'Голевые моменты против (пока игрок был на льду)',
        title: 'Голевые моменты против (пока игрок был на льду)'
    },
    'playerComparison.metrics.successful_passes_in_oz': {
        message: 'Точные передачи в зоне атаки',
        title: 'Точные передачи в зоне атаки'
    },
    'playerComparison.metrics.successful_passes_in_dz': {
        message: 'Точные передачи в зоне защиты',
        title: 'Точные передачи в зоне защиты'
    },
    'playerComparison.metrics.successful_passes_in_nz': {
        message: 'Точные передачи в средней зоне',
        title: 'Точные передачи в средней зоне'
    },
    'playerComparison.metrics.unsuccessful_passes': {
        message: 'Неточные передачи',
        title: 'Неточные передачи'
    },
    'playerComparison.metrics.unsuccessful_passes_in_oz': {
        message: 'Неточные передачи в зоне атаки',
        title: 'Неточные передачи в зоне атаки'
    },
    'playerComparison.metrics.unsuccessful_passes_in_dz': {
        message: 'Неточные передачи в зоне защиты',
        title: 'Неточные передачи в зоне защиты'
    },
    'playerComparison.metrics.unsuccessful_passes_in_nz': {
        message: 'Неточные передачи в средней зоне',
        title: 'Неточные передачи в средней зоне'
    },
    'playerComparison.metrics.primary_potential_assists': {
        message: 'Потенциальные голевые передачи (первые)',
        title: 'Потенциальные голевые передачи (первые)'
    },
    'playerComparison.metrics.secondary_potential_assists': {
        message: 'Потенциальные голевые передачи (вторые)',
        title: 'Потенциальные голевые передачи (вторые)'
    },
    'playerComparison.metrics.potential_assists': {
        message: 'Потенциальные голевые передачи',
        title: 'Потенциальные голевые передачи'
    },
    'playerComparison.metrics.primary_assists': {
        message: 'Голевые передачи (первые)',
        title: 'Голевые передачи (первые)'
    },
    'playerComparison.metrics.secondary_assists': {
        message: 'Голевые передачи (вторые)',
        title: 'Голевые передачи (вторые)'
    },
    'playerComparison.metrics.key_passes': {
        message: 'Ключевые пасы',
        title: 'Kлючевые пасы'
    },
    'playerComparison.metrics.xg_danger_zone': {
        message: 'xG из опасной зоны',
        title: 'xG из опасной зоны'
    },
    'playerComparison.metrics.xa': {
        message: 'xA',
        title: 'xA'
    },
    'playerComparison.metrics.xa2': {
        message: 'xA (вторичная)',
        title: 'xA (вторичная)'
    },
    'playerComparison.metrics.xa_against': {
        message: 'xA против (пока игрок был на льду)',
        title: 'xA против (пока игрок был на льду)'
    },
    'playerComparison.metrics.giveaways_%': {
        message: 'Потери, %',
        title: 'Потери, %'
    },
    'playerComparison.metrics.giveaways_dz%': {
        message: 'Потери в зоне защиты, %',
        title: 'Потери в зоне защиты, %'
    },
    'playerComparison.metrics.giveaways_oz%': {
        message: 'Потери в зоне атаки, %',
        title: 'Потери в зоне атаки, %'
    },
    'playerComparison.metrics.face_off_won': {
        message: 'Выигранные вбрасывания',
        title: 'Выигранные вбрасывания'
    },
    'playerComparison.metrics.face_off_lost': {
        message: 'Проигранные вбрасывания',
        title: 'Проигранные вбрасывания'
    },
    'playerComparison.metrics.oz_face_offs': {
        message: 'Вбрасывания в зоне атаки (пока игрок был на льду)',
        title: 'Вбрасывания в зоне атаки (пока игрок был на льду)'
    },
    'playerComparison.metrics.dz_face_offs': {
        message: 'Вбрасывания в зоне защиты (пока игрок был на льду)',
        title: 'Вбрасывания в зоне защиты (пока игрок был на льду)'
    },
    'playerComparison.metrics.nz_face_offs': {
        message: 'Вбрасывания в средней зоне (пока игрок был на льду)',
        title: 'Вбрасывания в средней зоне (пока игрок был на льду)'
    },
    'playerComparison.metrics.blocked_shots_by': {
        message: 'Заблокированные броски соперника',
        title: 'Заблокированные броски соперника'
    },
    'playerComparison.metrics.shot_attempts': {
        message: 'Броски',
        title: 'Броски'
    },
    'playerComparison.metrics.goals': {
        message: 'Голы',
        title: 'Голы'
    },
    'playerComparison.metrics.shifts': {
        message: 'Количество смен',
        title: 'Количество смен'
    },
    'playerComparison.metrics.clearances': {
        message: 'Выбросы',
        title: 'Выбросы'
    },
    'playerComparison.metrics.total_game_score': {
        message: 'Оценка игры (общая)',
        title: 'Оценка игры (общая)'
    },
    'playerComparison.metrics.offensive_game_score': {
        message: 'Оценка игры (игра в атаке)',
        title: 'Оценка игры (игра в атаке)'
    },
    'playerComparison.metrics.defensive_game_score': {
        message: 'Оценка игры (игра в защите)',
        title: 'Оценка игры (игра в защите)'
    },
    'playerComparison.metrics.shots_on_goal': {
        message: 'Броски в створ',
        title: 'Броски в створ'
    },
    'playerComparison.metrics.shots_on_goal_danger_zone': {
        message: 'Броски в створ из опасной зоны',
        title: 'Броски в створ из опасной зоны'
    },
    'playerComparison.metrics.shots_blocked_for': {
        message: 'Заблокированные броски за (пока игрок был на льду)',
        title: 'Заблокированные броски за (пока игрок был на льду)'
    },
    'playerComparison.metrics.shots_blocked_against': {
        message: 'Заблокированные броски против (пока игрок был на льду)',
        title: 'Заблокированные броски против (пока игрок был на льду)'
    },

    'playerComparison.metrics.successful_passes': {
        message: 'Успешные передачи',
        title: 'Успешные передачи'
    },

    'playerComparison.metrics.pass_in_entries': {
        message: 'Входы через пас',
        title: 'Входы через пас'
    },

    'playerComparison.metrics.carry_in_entries': {
        message: 'Входы с ведением',
        title: 'Входы с ведением'
    },

    'playerComparison.metrics.dump_in_entries': {
        message: 'Входы через вброс',
        title: 'Входы через вброс'
    },

    'playerComparison.metrics.pass_out_exits': {
        message: 'Выходы через пас',
        title: 'ВЫходы через пас'
    },
    'playerComparison.metrics.on_ice_xg_for_from_danger_zone': {
        message: 'xG за, из опасной зоны (пока игрок был на льду)',
        title: 'xG за, из опасной зоны (пока игрок был на льду)'
    },
    'playerComparison.metrics.on_ice_xg_against_from_danger_zone': {
        message: 'xG против, из опасной зоны (пока игрок был на льду)',
        title: 'xG против, из опасной зоны (пока игрок был на льду)'
    },

    'playerComparison.metrics.shot_attempts_from_danger_zone': {
        message: 'Броски из опасной зоны',
        title: 'Броски из опасной зоны'
    },
    'playerComparison.metrics.xg': {
        message: 'xG',
        title: 'xG'
    },
    'playerComparison.metrics.pace': {
        message: 'Темп %',
        title: 'Темп %'
    },
    'playerComparison.metrics.backchecking_pace': {
        message: 'Темп в оборону %',
        title: 'Темп в оборону %'
    },
    'playerComparison.metrics.shots_with_rebound': {
        message: 'Броски с отскоком',
        title: 'Броски с отскоком'
    },
    'playerComparison.metrics.shots_missed': {
        message: 'Броски мимо створа ворот',
        title: 'Броски мимо створа ворот'
    },
    'playerComparison.metrics.average_toi': {
        message: 'Время на льду (среднее)',
        title: 'Время на льду (среднее)'
    },
    'playerComparison.metrics.shots_blocked': {
        message: 'Броски, заблокированные соперником',
        title: 'Броски, заблокированные соперником'
    },
    'playerComparison.metrics.shots_on_goal_for': {
        message: 'Броски в створ за (пока игрок был на льду)',
        title: 'Броски в створ за (пока игрок был на льду)'
    },
    'playerComparison.metrics.shots_on_goal_against': {
        message: 'Броски в створ против (пока игрок был на льду)',
        title: 'Броски в створ против (пока игрок был на льду)'
    },
    'playerComparison.metrics.xg_btn_against': {
        message: 'xG против, после пасов из-за ворот',
        title: 'xG против, после пасов из-за ворот'
    },
    'playerComparison.metrics.xg_cl_against': {
        message: 'xG против, после пасов через центральную линию',
        title: 'xG против, после пасов через центральную линию'
    },
    'playerComparison.metrics.goals_one_timer': {
        message: 'Голы в касание',
        title: 'Голы в касание'
    },
    'playerComparison.metrics.avg_rest_time': {
        message: 'Время между сменами (среднее)',
        title: 'Время между сменами (среднее)'
    },
    'playerComparison.metrics.max_shoot_speed': {
        message: 'Скорость броска (максимальная)',
        title: 'Скорость броска (максимальная)'
    },
    'playerComparison.metrics.avg_shoot_speed': {
        message: 'Скорость броска (средняя)',
        title: 'Скорость броска (средняя)'
    },
    'playerComparison.metrics.scoring_chances': {
        message: 'Голевые моменты',
        title: 'Голевые моменты'
    },
    'playerComparison.metrics.avgShiftLength': {
        message: 'Длина смены (средняя)',
        title: 'Длина смены (средняя)'
    },
    'playerComparison.metrics.faceOffWonRelative': {
        message: 'Выигранные вбрасывания, %',
        title: 'Выигранные вбрасывания, %'
    },
    'playerComparison.metrics.zoneStarts': {
        message: 'Смены в зоне атаки, %',
        title: 'Смены в зоне атаки, %'
    },
    'playerComparison.metrics.avgPossessionTimeOz': {
        message: 'Среднее время одного владения в зоне атаки',
        title: 'Среднее время одного владения в зоне атаки'
    },
    'playerComparison.metrics.avgPossessionTimeNz': {
        message: 'Среднее время одного владения в средней зоне',
        title: 'Среднее время одного владения в средней зоне'
    },
    'playerComparison.metrics.avgPossessionTimeDz': {
        message: 'Среднее время одного владения в зоне защиты',
        title: 'Среднее время одного владения в зоне защиты'
    },
    'playerComparison.metrics.avgPossessionTime': {
        message: 'Среднее время одного владения',
        title: 'Среднее время одного владения'
    },
    'playerComparison.metrics.offensiveZoneStarts': {
        message: 'Смены в зоне атаки',
        title: 'Смены в зоне атаки'
    },
    'playerComparison.metrics.defensiveZoneStarts': {
        message: 'Смены в зоне защиты',
        title: 'Смены в зоне защиты'
    },

    'playerComparison.metrics.neutralZoneStarts': {
        message: 'Смены в нейтральной зоне',
        title: 'Смены в нейтральной зоне'
    },

    'playerComparison.metrics.giveawaysNz': {
        message: 'Потери в средней зоне',
        title: 'Потери в средней зоне'
    },
    'playerComparison.metrics.giveawaysNzRelative': {
        message: 'Потери в средней зоне, %',
        title: 'Потери в средней зоне, %'
    },
    'playerComparison.metrics.onIceXgRelative': {
        message: 'xG %',
        title: 'xG %'
    },
    'playerComparison.metrics.onIceScoringChances': {
        message: 'Голевые моменты, % (пока игрок был на льду)',
        title: 'Голевые моменты, % (пока игрок был на льду)'
    },
    'playerComparison.metrics.scoringChancesPerShotAttempts': {
        message: 'Голевые моменты / броски',
        title: 'Голевые моменты / броски'
    },
    'playerComparison.metrics.key_passes_against': {
        message: 'Ключевые передачи против (пока игрок был на льду)',
        title: 'Ключевые передачи против (пока игрок был на льду)'
    },
    'playerComparison.metrics.passing_accuracy': {
        message: 'Точность передач, %',
        title: 'Точность передач, %'
    },
    'playerComparison.metrics.passing_in_dz': {
        message: 'Точность передач в зоне защиты, %',
        title: 'Точность передач в зоне защиты, %'
    },
    'playerComparison.metrics.passing_in_nz': {
        message: 'Точность передач в средней зоне, %',
        title: 'Точность передач в средней зоне, %'
    },
    'playerComparison.metrics.passing_in_oz': {
        message: 'Точность передач в зоне атаки, %',
        title: 'Точность передач в зоне атаки, %'
    },
    'playerComparison.metrics.rebound_recoveries_relative': {
        message: 'Подборы после отскоков, %',
        title: 'Подборы после отскоков, %'
    },
    'playerComparison.metrics.controlledEntriesRelative': {
        message: 'Входы с контролем, %',
        title: 'Входы с контролем, %'
    },
    'playerComparison.metrics.controlledExitsRelative': {
        message: 'Выходы с контролем, %',
        title: 'Выходы с контролем, %'
    },
    'playerComparison.metrics.successExitsRelative': {
        message: 'Успешные выходы, %',
        title: 'Успешные выходы, %'
    },
    'playerComparison.metrics.successEntriesRelative': {
        message: 'Успешные входы, %',
        title: 'Успешные входы, %'
    },
    'playerComparison.metrics.goals_danger_zone': {
        message: 'Голы из опасной зоны',
        title: 'Голы из опасной зоны'
    },
    'playerComparison.metrics.shotsOnGoalRelative': {
        message: 'Броски в створ, % (пока игрок был на льду)',
        title: 'Броски в створ, % (пока игрок был на льду)'
    },
    'playerComparison.metrics.goals_after_rebounds': {
        message: 'Голы после отскоков',
        title: 'Голы после отскоков'
    },
    'playerComparison.metrics.clearancesRelative': {
        message: 'Выбросы, %',
        title: 'Выбросы, %'
    },
    'playerComparison.metrics.possessionRelative': {
        message: 'Владение, % (пока игрок был на льду)',
        title: 'Владение, % (пока игрок был на льду)'
    },
    'playerComparison.metrics.puckTimeInOZRelative': {
        message: 'Время в зоне атаки, % (пока игрок был на льду)',
        title: 'Время в зоне атаки, % (пока игрок был на льду)'
    },
    'playerComparison.metrics.passes': {
        message: 'Передачи',
        title: 'Передачи'
    },
    'playerComparison.metrics.hitDelivered': {
        message: 'Силовой прием',
        title: 'Силовой прием'
    },
    'playerComparison.metrics.hitTaken': {
        message: 'Силовой прием против',
        title: 'Силовой прием против'
    },

    /***************************************************************Pre-scout product**************************************************/
    'summary.stats.table': 'stats',

    /***************************************************************Scout product**************************************************/
    'scout.player.selected': 'Выбранный игрок',
    'scout.player.games': 'Игры',
    'scout.player.toi': 'Время на льду',
    'scout.player.avgToi': 'Ср. время на льду',

    'scout.playerPage.playerProfile': 'Профиль игрока',
    'scout.playerPage.playerDetails': 'Информация об игроке',
    'scout.playerPage.team': 'Команда',
    'scout.playerPage.contract': 'Контракт',
    'scout.playerPage.skills': 'Навыки',
    'scout.playerPage.evaluation': 'Оценки',
    'scout.playerPage.notes': 'Заметки',
    'scout.playerPage.combine': 'Соединять',
    'scout.playerPage.schedule': 'Расписание',
    'scout.playerPage.videos': 'Видео',

    /***************************************************************Элементы виджета**************************************************/
    'widget.header.per20View': 'ЗА 20',
    'widget.header.perGameView': 'ЗА ИГРУ',
    'widget.header.defaultView': 'Общее',
    'widget.header.per2Pp': 'ЗА 2 МИН',
    'widget.header.per2Pk': 'ЗА 2 МИН',
    'widget.header.onIce': 'НА ЛЬДУ',
    'widget.header.individual': 'ЛИЧНЫЕ',
    'widget.header.favorites': 'FAVORITES',
    'widget.header.metrics': 'Метрики',
    'widget.header.video': 'Видео',
    'widget.header.axisChart': 'Осевой график',

    /***************************************************************Post Game Reports***********************************/
    'postGameReportPage.tables.columns.game': 'ИГРА',
    'postGameReportPage.tables.columns.season': 'СЕЗОН',
    'postGameReportPage.tables.columns.goal': 'ЦЕЛЬ',
    'postGameReportPage.tables.columns.lineXg': 'xG% ЗВЕНА',
    'postGameReportPage.tables.columns.lineToi': 'ВРЕМЯ',
    'postGameReportPage.tables.rows.possessionTime': 'Время владения, %',
    'postGameReportPage.tables.rows.puckToiOz': 'Время в зоне атаки, %',
    'postGameReportPage.tables.rows.shotAttempts': 'Броски по воротам, %',
    'postGameReportPage.tables.rows.shotAttemptsDangerZone': 'Опасная зона: броски, %',
    'postGameReportPage.tables.rows.shotsOnGoal': 'Броски в створ, %',
    'postGameReportPage.tables.rows.xg': 'xG, %',
    'postGameReportPage.tables.rows.giveaways': 'Потери, %',
    'postGameReportPage.tables.rows.faceOffWon': 'Вбрасывания, %',
    'postGameReportPage.tables.rows.successfulEntries': 'Успешные входы, %',
    'postGameReportPage.tables.rows.successfulExits': 'Успешные выходы, %',
    'postGameReportPage.tables.rows.savesDangerZone': 'Опасная зона: cейвы, %',
    'postGameReportPage.tables.rows.controlledEntries': 'Входы с контролем, %',
    'postGameReportPage.tables.rows.controlledExits': 'Выходы с контролем, %',
    'postGameReportPage.tables.rows.ppTotal': 'Количество / Время',
    'postGameReportPage.tables.rows.powerPlayGoals': 'Реализация, %',
    'postGameReportPage.tables.rows.xgForPer2': 'xG за 2 мин',
    'postGameReportPage.tables.rows.reboundRecoveriesOz': 'Подборы отскоков (нап.), %',
    'postGameReportPage.tables.rows.pkTotal': 'Количество / Время',
    'postGameReportPage.tables.rows.penaltyKillGoalsAgainst': 'Нейтрализация, %',
    'postGameReportPage.tables.rows.xgAgainstPer2': 'xG соперника за 2 мин',
    'postGameReportPage.tables.rows.shotAttemptsDangerZoneAgainst': 'Опасная зона: броски, %',
    'postGameReportPage.tables.rows.reboundRecoveriesDz': 'Подборы отскоков (защ.), %',
    'postGameReportPage.titles.evenStrength': 'РАВНЫЕ СОСТАВЫ',
    'postGameReportPage.titles.offenseGameScores': 'OFFENSE GAME SCORES',
    'postGameReportPage.titles.defenseGameScores': 'DEFENSE GAME SCORES',
    'postGameReportPage.titles.GameScores': 'ИГРОКИ',
    'postGameReportPage.titles.defensemen': 'ЗАЩИТНИКИ 5v5',
    'postGameReportPage.titles.forwards': 'ФОРВАРДЫ 5v5',
    'postGameReportPage.titles.powerPlay': 'БОЛЬШИНСТВО',
    'postGameReportPage.titles.penaltyKill': 'МЕНЬШИНСТВО',
    'postGameReportPage.titles.shotMap': 'КАРТА БРОСКОВ',
    'postGameReportPage.labels.shotsFor': 'Броски команды ',
    'postGameReportPage.info.xg': 'xG все составы',
    'postGameReportPage.info.evXg': 'xG равные составы',
    'postGameReportPage.info.shotAttempts': 'Броски по воротам',
    'postGameReportPage.info.shotAttemptsFromDangerZone': 'Опасная зона: броски',
    'postGameReportPage.titles.goalie': 'ВРАТАРИ',
    'postGameReportPage.titles.noDefencemen': 'НЕТ ЗАЩИТИКОВ',
    'postGameReportPage.titles.noForwards': 'НЕТ НАПАДАЮЩИХ',

    /***************************************************************Help**************************************************/
    'summary.xGMap.helpTitle': 'xG карта',
    'summary.xGMap.helpBody':
        'xG, или ожидаемые голы, учитывают вероятность каждого броска закончится голом.\n' +
        'Данный показатель можно сравнить с голевым моментом, но xG намного точнее и дает более объективную картину в связи с тем, что каждый бросок учтен. Используется свыше 30 параметров (такие как трафик перед вратарем, скорость броска и т.д.) для подсчета вероятности. 0.05 xG означает, что бросок имел вероятность 5% закончится голом. 0.00 xG означает, что бросок имел вероятность менее 1% закончится голом. Чем больше круг - тем выше вероятность.',
    'summary.skaters.helpTitle': 'Три звезды матча',
    'summary.skaters.helpBody':
        'Три звезды матча по версии TPE автоматически выбираются на основе 12 ключевых показателях игры. Данные показатели покрывают все ключевые компоненты игры. Для нападающих игра в атаке имеет больший вес. Для защитников - их защитные действия.',
    'summary.xGTimeline.helpTitle': 'Хронология xG',
    'summary.xGTimeline.helpBody':
        'В связи с тем, что в хоккее происходит достаточно большое количество бросков в сторону ворот - шанс, что один конкретный бросок окажется голом, очень низок. Данный график показывает, как развивалась игра в динамике, какая из команд доминировала на каждом из временных участков.',
    'skaters.efficiency.helpTitle': 'Эффективность (Корси)',
    'skaters.efficiency.helpBody':
        'График показывает показатели Корси (броски в сторону ворот команды и соперника с каждым игроком на льду) и визуализирует их. “Веселый” игрок - тот, с которым на льду происходит много бросков в обе стороны. “Скучный” - наоборот. Чем ближе игрок к зоне “Хорошо”, тем больше они помогали команде созидать в атаке и при этом быть эффективными в обороне, будучи на льду.',
    'skaters.xGTopSkaters.helpTitle': 'Лучшие / худшие игроки',
    'skaters.xGTopSkaters.helpBody':
        'xG % = xG команды / (xG команды + xG соперника) показывает, насколько больше команда создала моментов с игроком на льду, чем соперник. Хороший результат - это выше 50%, плохой - ниже. Во вкладке “Все игроки” все показатели с % подсчитаны по аналогичной формуле.',
    'passing.chart.helpTitle': 'График плеймейкинга',
    'passing.chart.helpBody':
        'График демонстрирует уровень плеймейкинга каждого игрока, визуализируя отношение потенциально голевых передач (ПГП) ко всем пасам. ПГП - это передача, после которой создается голевой момент. Чем больше ПГП при наименьшем кол-ве пасов - тем выше умение игрока создавать моменты через пас.',
    'skating.chart.helpTitle': 'График темпа',
    'skating.chart.helpBody':
        'Темп играет большую роль в каждой игре. Темп % - это процент дистанции, которую игрок проезжает на скорости выше 20 км/ч. График показывает, как игрок катается по ходу смены. Он также показывает оптимальную длину смены каждого игрока. У некоторых игроков темп резко повышается в самом конце смен - это показывает усилие игрока, когда он возвращается на смену.',

    /******************************************Scout**********************************************************/
    'scout.home.searchTable': 'Поиск',
    'scout.home.searchTable.filterPredicate': 'Все игроки',
    'scout.home.searchTable.textQuery': 'Поиск по имени',
    'scout.home.searchTable.recentList': 'История поиска',
    'scout.home.searchTable.favoritesList': 'Избранное',
    'scout.home.searchTable.columns.teamTitle': '{teamNameRu}',
    'scout.home.searchTable.columns.photo': 'Фото',
    'scout.home.searchTable.columns.name': 'Имя и фамилия',
    'scout.home.searchTable.columns.games': 'Игры',
    'scout.home.searchTable.columns.players': 'Игроки',
    'scout.home.searchTable.columns.age': 'Возраст',
    'scout.home.searchTable.columns.height': 'Рост',
    'scout.home.searchTable.columns.position': 'Позиция',
    'scout.home.searchTable.columns.performance': 'Эффективность',
    'scout.home.searchTable.columns.weight': 'Вес',
    'scout.home.searchTable.columns.toi': 'Время (ср.)',
    'scout.home.searchTable.columns.DgZShoot': 'Броски из опасной зоны,\u00A0%',
    'scout.home.searchTable.columns.shotsOnGoalPer20': 'Броски в створ\u00A0',
    'scout.home.searchTable.columns.xGRelative': 'xG %',
    'scout.home.searchTable.columns.controlledExits': 'Выходы с контролем,\u00A0%',
    'scout.home.searchTable.columns.controlledEntries': 'Входы с контролем,\u00A0%',
    'scout.home.searchTable.columns.giveawaysRelative': 'Потери,\u00A0%',
    'scout.home.searchTable.columns.interceptions': 'Перехваты',
    'scout.home.searchTable.columns.reboundRecoveries': 'Подборы отскоков',
    'scout.home.searchTable.columns.xA': 'xA',
    'scout.home.searchTable.columns.keyPasses': 'Ключевые передачи',
    'scout.home.searchTable.columns.shotsOnGoal': 'Броски в створ',
    'scout.home.searchTable.columns.shotsBlocked': 'Блок.броски',
    'scout.home.searchTable.columns.successfulPassesNz': 'Успешные пасы в\u00A0НЗ',
    'scout.home.searchTable.columns.shotsBlockingRelative': 'Заблок.броски,\u00A0%',
    'scout.home.searchTable.columns.successfulPasses': 'Успешные передачи,\u00A0%',
    'scout.home.searchTable.columns.xA2': 'xA2',
    'scout.home.searchTable.columns.xGAfterLTHPass': 'xG после пер. на СЛ',
    'scout.home.searchTable.columns.team': 'Команда',
    'scout.home.searchTable.tooltip.age': 'Возраст',
    'scout.home.searchTable.tooltip.height': 'Рост',
    'scout.home.searchTable.tooltip.weight': 'Вес',
    'scout.home.searchTable.tooltip.handedness': 'Хват',
    'scout.home.searchTable.tooltip.shoot': '${values.shoots === "LEFT" ? "Левый" : "Правый"}',
    'scout.home.gamesPlayed.columns.opponent': 'Соперник',
    'scout.home.searchTable.tooltip.citizenship': 'Гражданство',
    'scout.home.filtersSearch.noResults':
        'Мы не нашли игроков с такими характеристиками. Попробуйте расширить критерии для поиска.',
    'scout.home.textQuerySearch.noResults': 'Мы не нашли игроков с таким именем.',
    'scout.home.gameShifts.selectSkater': 'Выберите игрока для просмотра его игр.',
    'scout.home.searchTable.noAccessToPlayer':
        'У\u00A0вас\u00A0нет\u00A0доступа\u00A0для\u00A0просмотра\u00A0этого\u00A0игрока.',
    'scout.home.searchTable.noFavorites': 'Нет избранных игроков',
    'scout.home.searchTable.noRecents': 'История поиска пуста',
    'scout.playerSelectWidget.playerComparison': 'Players comparison',
    'scout.playerSelectWidget.playersPage': 'Players page',
    'scout.playerSelectWidget.playerSelect': 'Select player page',
    'scout.playerSelectWidget.addToFavorites': 'Add to favorite',
    'scout.suggestion.player.name':
        '{firstNameRu&&firstNameRu.length>0?firstNameRu:firstNameEn?firstNameEn:""} {lastNameRu&&lastNameRu.length>0?lastNameRu:lastNameEn?lastNameEn:""}',
    'scout.home.searchTable.columns.date': 'Дата',
    'scout.home.searchTable.columns.scout': 'Скаут',

    /*******************************Scout metrics*******************************/

    'scout.metrics.groupNames.boxscore': 'Набор очков',
    'scout.metrics.groupNames.faceoffs': 'Вбрасывания',
    'scout.metrics.groupNames.goalie': 'Вратари',
    'scout.metrics.groupNames.other': 'Другие',
    'scout.metrics.groupNames.recoveries': 'Владение',
    'scout.metrics.groupNames.shooting': 'Удары',
    'scout.metrics.groupNames.shifts': 'Смены',
    'scout.metrics.groupNames.onice': 'На льду',

    'scout.metrics.onIceScoringChances': 'Голевые моменты звена, %',
    'scout.metrics.onIceScoringChances.description':
        'Эта метрика показывает соотношение между голевыми моментами команды и голевыми моментами обеих команд.',

    'scout.metrics.onIceShotsFor': 'Броски звена',
    'scout.metrics.onIceShotsFor.description':
        'Эта метрика является счетчиком бросков команды с конкретным игроком на льду.',

    'scout.metrics.onIceShotsAgainst': 'Броски против звена',
    'scout.metrics.onIceShotsAgainst.description':
        'Этот показатель является счетчиком бросков против команды с конкретным игроком на льду.',

    'scout.metrics.onIceGoalsAgainstFromDangerZone': 'Голы соперника против звена из опасной зоны',
    'scout.metrics.onIceGoalsAgainstFromDangerZone.description':
        'Этот показатель является счетчиком пропущенных голов команды из опасной зоны с конкретным игроком на льду',

    'scout.metrics.onIceGoalsFor': 'Голы соперника против звена из опасной зоны',
    'scout.metrics.onIceGoalsFor.description':
        'Этот показатель является счетчиком голов своей команды с конкретным игроком на льду',

    'scout.metrics.onIceGoalsAgainst': 'Голы звена',
    'scout.metrics.onIceGoalsAgainst.description':
        'Этот показатель является счетчиком целей для команды с конкретным игроком на льду',

    'scout.metrics.onIceScoringChancesFor': 'Голы против звена',
    'scout.metrics.onIceScoringChancesFor.description':
        'Этот показатель является счетчиком голевых моментов команды с конкретным игроком на льду',

    'scout.metrics.onIceScoringChancesAgainst': 'Голевые моменты звена ',
    'scout.metrics.onIceScoringChancesAgainst.description':
        'Этот показатель является счетчиком голевых моментов команды соперника с конкретным игроком звена на льду',

    'scout.metrics.onIceShotsOnGoalFor': 'Голевые моменты против звена',
    'scout.metrics.onIceShotsOnGoalFor.description':
        'Этот показатель является счетчиком бросков по воротам команды соперника с конкретным игроком на льду',

    'scout.metrics.onIceShotsOnGoalAgainst': 'Броски звена по воротам соперника',
    'scout.metrics.onIceShotsOnGoalAgainst.description':
        'Этот показатель является счетчиком бросков соперника по воротам команды с конкретным игроком на льду',

    'scout.metrics.onIceShotsOnGoalRelative': 'Соотношение броском по воротам',
    'scout.metrics.onIceShotsOnGoalRelative.description':
        'Этот показатель показывает соотношение бросков по воротам команды и суммарных бросков по воротам обеих команд с конкретным игроком на льду.',

    'scout.metrics.avgToi': 'Среднее время на льду',
    'scout.metrics.avgToi.description': 'Этот показатель показывает среднее время игрока на льду',

    'scout.metrics.avgShiftLength': 'Среднее время смены',
    'scout.metrics.avgShiftLength.description': 'Этот показатель показывает среднюю длину смены игрока',

    'scout.metrics.avgShifts': 'Среднее количество смен за игру',
    'scout.metrics.avgShifts.description': 'Этот показатель показывает среднее количество смен игрока за одну игру',

    'scout.metrics.avgRestTime': 'Среднее время отдыха',
    'scout.metrics.avgRestTime.description': 'Этот показатель показывает среднее время отдыха игрока',

    'scout.metrics.faceOffWonRelative': 'Выигранные вбрасывания, %',
    'scout.metrics.faceOffWonRelative.description':
        'Этот показатель показывает соотношение между выигранными вбрасываниями и общими вбрасываниями в игре',

    'scout.metrics.zoneStarts': 'Смены в зоне атаки, %',
    'scout.metrics.zoneStarts.description':
        'Этот показатель показывает соотношение между количеством начала смен в зоне атаки и общим количеством начала смен во время игры.',

    'scout.metrics.offensiveZoneStarts': 'Смены в зоне атаки',
    'scout.metrics.offensiveZoneStarts.description':
        'Этот показатель является счетчиком вбрасываний, которые произошли в зоне атаки. ',

    'scout.metrics.defensiveZoneStarts': 'Смены в зоне защиты',
    'scout.metrics.defensiveZoneStarts.description':
        'Этот показатель является счетчиком вбрасываний, которые произошли в оборонительной зоне. ',

    'scout.metrics.neutralZoneStarts': 'Смены в нейтральной зоне',
    'scout.metrics.neutralZoneStarts.description':
        'Этот показатель является счетчиком вбрасываний, которые произошли в нейтральной зоне. ',

    'scout.metrics.faceOffWon': 'Выигранные вбрасывания',
    'scout.metrics.faceOffWon.description': 'Этот показатель является счетчиком выигранных вбрасываний',

    'scout.metrics.faceOffLost': 'Проигранные вбрасывания',
    'scout.metrics.faceOffLost.description': 'Этот показатель является счетчиком проигранных вбрасываний',

    'scout.metrics.recoveries': 'Подборы шайбы',
    'scout.metrics.recoveries.description':
        'Этот показатель является счетчиком общего восстановления владений шайбой во время игры.',

    'scout.metrics.recoveriesOz': 'Подборы шайбы в зоне атаки',
    'scout.metrics.recoveriesOz.description':
        'Этот показатель является счетчиком общего восстановления владения шайбой во время игры в зоне атаки.',

    'scout.metrics.recoveriesDz': 'Подбор шайбы в зоне обороны',
    'scout.metrics.recoveriesDz.description':
        'Этот показатель является счетчиком общего восстановления владения шайбой во время игры в зоне обороны.',

    'scout.metrics.recoveriesNz': 'Подбор шайбы в нейтральной зоне',
    'scout.metrics.recoveriesNz.description':
        'Этот показатель является счетчиком общего восстановления владения шайбой во время игры в нейтральной зоне.',

    'scout.metrics.reboundRecoveries': 'Подбор шайбы после отскока ',
    'scout.metrics.reboundRecoveries.description':
        'Этот показатель является счетчиком общего восстановления владения шайбой с отскока во время игры.',

    'scout.metrics.reboundRecoveriesOz': 'Подбор шайбы с отскока, в зоне атаки',
    'scout.metrics.reboundRecoveriesOz.description':
        'Этот показатель является счетчиком общего количества подборов с отскока во время игры в зоне атаки',

    'scout.metrics.reboundRecoveriesDz': 'Подбор шайбы с отскока, в зоне обороны',
    'scout.metrics.reboundRecoveriesDz.description':
        'Этот показатель является счетчиком общего количества подборов с отскока во время игры в зоне обороны.',

    'scout.metrics.goalsDangerZone': 'Голы из опасной зоны',
    'scout.metrics.goalsDangerZone.description': 'Этот показатель является счетчиком голов, забитых из опасной зоны.',

    'scout.metrics.realPrimaryAssists': 'Первые голевые пасы',
    'scout.metrics.realPrimaryAssists.description': 'Этот показатель является счетчиком первых голевых передач',

    'scout.metrics.realSecondaryAssists': 'Вторые голевые пасы',
    'scout.metrics.realSecondaryAssists.description': 'Этот показатель является счетчиком вторых голевых передач',

    'scout.metrics.goals': 'Голы',
    'scout.metrics.goals.description': 'Этот показатель является счетчиком заброшенных шайб',

    'scout.metrics.goalsAfterRebounds': 'Голы после отскока',
    'scout.metrics.goalsAfterRebounds.description': 'Этот показатель является счетчиком голов, забитых после отскока',

    'scout.metrics.shotAttemptsFromDangerZone': 'Броски из опасной зоны',
    'scout.metrics.shotAttemptsFromDangerZone.description':
        'Этот показатель является счетчиком попыток бросков из опасной зоны.',

    'scout.metrics.shotsWithRebound': 'Броски с отскоком',
    'scout.metrics.shotsWithRebound.description': 'Этот показатель является счетчиком бросков с отскоком',

    'scout.metrics.shotsMissed': 'Броски мимо',
    'scout.metrics.shotsMissed.description': 'Этот показатель является счетчиком бросков мимо',

    'scout.metrics.blockedShots': 'Заблокированные броски ',
    'scout.metrics.blockedShots.description': 'Этот показатель является счетчиком заблокированных бросков',

    'scout.metrics.shotAttempts': 'Броски',
    'scout.metrics.shotAttempts.description': 'Этот показатель является счетчиком попыток бросков',

    'scout.metrics.shotsOnGoal': 'Броски по воротам',
    'scout.metrics.shotsOnGoal.description': 'Этот показатель является счетчиком бросков по воротам.',

    'scout.metrics.shotsBlocked': 'Блокирование бросков',
    'scout.metrics.shotsBlocked.description': 'Этот показатель является счетчиком заблокированных бросков оппонента',

    'scout.metrics.shotsOnGoalDangerZone': 'Броски по воротам из опасной зоны',
    'scout.metrics.shotsOnGoalDangerZone.description':
        'Этот показатель является счетчиком бросков по воротам опасной зоны',

    'scout.metrics.shotsBlockedDangerZone': 'Заблокированные броски из опасной зоны',
    'scout.metrics.shotsBlockedDangerZone.description':
        'Этот показатель является счетчиком заблокированных бросков из опасной зоны',

    'scout.metrics.shotsMissedDangerZone': 'Броски мимо из опасной зоны',
    'scout.metrics.shotsMissedDangerZone.description':
        'Этот показатель является счетчиком бросков мимо из опасной зоны',

    'scout.metrics.scoringChances': 'Голевые моменты',
    'scout.metrics.scoringChances.description': 'Этот показатель является счетчиком голевых моментов',

    'scout.metrics.scoringChancesPerShotAttempts': 'Голевые моменты / броски',
    'scout.metrics.scoringChancesPerShotAttempts.description':
        'Этот показатель показывает соотношение между голевыми моментами и попытками бросков',

    'scout.metrics.savesPerc': 'Сэйвы %',
    'scout.metrics.savesPerc.description':
        'Этот показатель представляет собой соотношение между сэйвами и бросками по воротами.',

    'scout.metrics.goalsAgainstAverage': 'Голы соперника, среднее',
    'scout.metrics.goalsAgainstAverage.description':
        'Этот показатель является счетчиком среднего количества голов пропущенных вратарем за игру',

    'scout.metrics.savesAverage': 'Среднее количество сейвов',
    'scout.metrics.savesAverage.description':
        'Этот показатель является счетчиком среднего количества сэйвов вратаря за игру.',

    'scout.metrics.shotsOnGoalAgainst': 'Броски против',
    'scout.metrics.shotsOnGoalAgainst.description':
        'Этот показатель является счетчиком общих бросков по воротам вратаря.',

    'scout.metrics.shotsOnGoalDangerZoneAgainst': 'Броски из опасной зоны, против',
    'scout.metrics.shotsOnGoalDangerZoneAgainst.description':
        'Этот показатель является счетчиком общего количества бросков по воротам вратаря из опасной зоны.',

    'scout.metrics.dangerZoneSave': 'Сэйвов из опасной зоны,%',
    'scout.metrics.dangerZoneSave.description':
        'Этот показатель представляет собой соотношение между сэйвами и бросками по воротам вратаря из опасной зоны.',

    'scout.metrics.pp': 'Игра в большинстве',
    'scout.metrics.pP.description':
        'Этот показатель показывает среднее время на льду, пока команда играет в большинстве',

    'scout.metrics.pk': 'Игра в меньшинстве',
    'scout.metrics.pk.description':
        ' Этот показатель показывает среднее время на льду, в то время как команда находится в меньшинстве',

    'scout.metrics.pts': 'Очки',
    'scout.metrics.pts.description': 'Общее количество голов плюс первые голевые передачи плюс вторые голевые передачи',

    'scout.metrics.plusMinus': '+/-',
    'scout.metrics.plusMinus.description':
        'Игроку присуждается знак «плюс» каждый раз, когда он находится на льду и его команда забрасывает шайбу в равных составах или в меньшинстве. Он получает «минус», если находясь в равных составах или меньшинстве, его звено пропускает шайбу от команды соперника. Разница в этих числах считается статистикой игрока «плюс-минус».',

    'scout.metrics.gamesSkaters': 'Число игр',
    'scout.metrics.shifts': 'Число смен',
    'scout.metrics.gamesGoalie': 'Число игр',

    /******************************************Report**********************************************************/
    'report.section.summary': 'Резюме',
    'report.section.summary-lite': 'Резюме',
    'report.section.skaters': 'Игроки',
    'report.section.shooting': 'Броски',
    'report.section.passing': 'Передачи',
    'report.section.goaltenders': 'Вратари',
    'report.section.entries': 'Входы',
    'report.section.entries-lite': 'Входы',
    'report.section.entriesTitle': 'Входы',
    'report.section.exits': 'Выходы',
    'report.section.exits-lite': 'Выходы',
    'report.section.possession': 'Владение',
    'report.section.skating': 'Катание',
    'report.widget.report': 'Полный Отчет',
    'report.panel.printReport': 'Сформировать',
    'report.panel.strength': '{ strength, select, ev {РАВ} pp {БОЛ} other {МЕН} }',

    /******************************************Admin**********************************************************/

    'scoutAdmin.leagues': 'Лиги',
    'scoutAdmin.seasons': 'Сезоны',
    'scoutAdmin.teams': 'Команды',
    'scoutAdmin.scoutGames': 'Игры (Скаут)',
    'scoutAdmin.players': 'Игроки',
    'scoutAdmin.gamesUpload': 'Загрузка игр',
    'scoutAdmin.playersTPE': 'Игроки TPE',

    'scoutAdmin.leagues.id': 'Id',
    'scoutAdmin.leagues.nameEn': 'Name (En)',
    'scoutAdmin.leagues.nameRu': 'Name (Ru)',
    'scoutAdmin.leagues.quantileType': 'Quantile type',
    'scoutAdmin.seasons.id': 'Id',
    'scoutAdmin.seasons.league': 'League',
    'scoutAdmin.seasons.nameEn': 'Name (En)',
    'scoutAdmin.seasons.nameRu': 'Name (Ru)',
    'scoutAdmin.seasons.status': 'Status',
    'scoutAdmin.seasons.dateBegin': 'Date Begin',
    'scoutAdmin.seasons.dateEnd': 'Date End',
    'scoutAdmin.teams.nameEn': 'Name (En)',
    'scoutAdmin.teams.nameRu': 'Name (Ru)',
    'scoutAdmin.teams.shortNameEn': 'Short Name (En)',
    'scoutAdmin.teams.shortNameRu': 'Short Name (Ru)',
    'scoutAdmin.teams.acronymEn': 'Acronym (En)',
    'scoutAdmin.teams.acronymRu': 'Acronym (Ru)',
    'scoutAdmin.teams.logo': 'Logo',
    'scoutAdmin.teams.addTeam': 'Добавить команду',
    'scoutAdmin.teams.team': 'Команда',
    'scoutAdmin.scoutGames.id': 'Id',
    'scoutAdmin.scoutGames.date': 'Дата (местная)',
    'scoutAdmin.scoutGames.time': 'Время',
    'scoutAdmin.scoutGames.timeZone': 'Часовой пояс',
    'scoutAdmin.scoutGames.dateTime': 'Дата и время (MSK)',
    'scoutAdmin.scoutGames.homeTeam': 'Хозяева',
    'scoutAdmin.scoutGames.awayTeam': 'Гости',
    'scoutAdmin.scoutGames.homeAwayTeam': 'Хозяева/Гости Team',
    'scoutAdmin.scoutGames.homeScore': 'Очки хозяев',
    'scoutAdmin.scoutGames.awayScore': 'Очки гостей',
    'scoutAdmin.scoutGames.season': 'Сезон',
    'scoutAdmin.scoutGames.updateVideoLinks': 'обновить ссылки на видео',
    'scoutAdmin.scoutGames.dialog.copyPlaceHolder': 'Файл на Azure',
    'scoutAdmin.scoutGames.dialog.copy': 'Копировать',
    'scoutAdmin.scoutGames.dialog.stitchPlaceHolder': 'Папка на Azure',
    'scoutAdmin.scoutGames.dialog.stitch': 'Склеить',
    'scoutAdmin.scoutGames.dialog.upload': 'Загрузить видео',
    'scoutAdmin.scoutGames.dialog.clear': 'Очистить',

    'scoutAdmin.playerProfiles.firstNameEn': 'Имя англ.',
    'scoutAdmin.playerProfiles.lastNameEn': 'Фамилия англ.',
    'scoutAdmin.playerProfiles.firstNameRu': 'Имя рус.',
    'scoutAdmin.playerProfiles.lastNameRu': 'Фамилия рус.',
    'scoutAdmin.playerProfiles.firstNameZh': 'Имя кит.',
    'scoutAdmin.playerProfiles.lastNameZh': 'Фамилия кит.',

    'scoutAdmin.playerProfiles.born': 'Дата рождения',
    'scoutAdmin.playerProfiles.gender': 'Пол',
    'scoutAdmin.playerProfiles.height': 'Рост',
    'scoutAdmin.playerProfiles.weight': 'Вес',
    'scoutAdmin.playerProfiles.photoHeader': 'Фото',
    'scoutAdmin.playerProfiles.photoHref': 'Ссылка на фото',
    'scoutAdmin.playerProfiles.shoots': 'Хват',
    'scoutAdmin.playerProfiles.country': 'Страна',
    'scoutAdmin.playerProfiles.games': 'Игры',
    'scoutAdmin.playerProfiles.pts': 'Очки',
    'scoutAdmin.playerProfiles.avgTOI': 'Средн. время на льду',
    'scoutAdmin.playerProfiles.avgPP': 'Средн. в больш.',
    'scoutAdmin.playerProfiles.avgPK': 'Средн. в меньш.',
    'scoutAdmin.playerProfiles.plusMinus': '+/-',
    'scoutAdmin.playerProfiles.savesPrc': 'Сейвы, %',
    'scoutAdmin.playerProfiles.goalsAgAvg': 'Голы против соперника в среднем',
    'scoutAdmin.playerProfiles.savesAvg': 'Сейвы в среднем',
    'scoutAdmin.playerProfiles.teamCurrent': 'Текущая команда',
    'scoutAdmin.playerProfiles.teamPast': 'Прошлая команда',
    'scoutAdmin.playerProfiles.email': 'Электронный адрес',

    'scoutAdmin.playerProfiles.lastGameId': 'ID посл. игры',
    'scoutAdmin.playerProfiles.lastSeason': 'Последний сезон',
    'scoutAdmin.playerProfiles.lastTeam': 'Последняя команда',
    'scoutAdmin.playerProfiles.lastPlayerPosition': 'Последняя позиция',
    'scoutAdmin.playerProfiles.lastJersey': 'Последний номер',

    'scoutAdmin.playerProfiles.gameId': 'ID игры',

    'scoutHome.teams.logo': 'Логотип',
    'scoutHome.teams.nameEn': 'Название команды',
    'scoutHome.teams.leagueName': 'Лига',
    'scoutHome.teams.gamesPlayed': 'Сыграно игр',

    'scout.interface.back': 'Назад',
    'scout.interface.reports': 'Отчеты',
    'scout.interface.hideCategories': 'Скрыть категории',
    'scout.interface.showCategories': 'Показать категории',
    'scout.interface.videoUnavailable': 'Извините, ваш браузер не поддерживает работу с видео',
    'scout.interface.comment': 'Комментировать',

    'scout.evaluation.categoryTypes.PERFORMANCE': 'ЭФФЕКТИВНОСТЬ',
    'scout.evaluation.categoryTypes.PERFOMANCE': 'ЭФФЕКТИВНОСТЬ',
    'scout.evaluation.categoryTypes.STARTING': 'Начинающий',
    'scout.evaluation.categoryTypes.PUCK_STRIKE': 'Удерживание шайбы',
    'scout.evaluation.categoryTypes.SHOT': 'Удар',
    'scout.evaluation.categoryTypes.COMPETITIVENESS': 'Конкурентность',
    'scout.evaluation.categoryTypes.CHARACTER': 'Характер',
    'scout.evaluation.categoryTypes.PLAYER_TYPE': 'Типичность игрока',
    'scout.evaluation.categoryTypes.TALENT': 'Талант',

    'scout.playerPage.evaluation.playerName': 'Имя игрока',
    'scout.playerPage.evaluation.position': 'Позиция',
    'scout.playerPage.evaluation.jersey': 'Номер',
    'scout.playerPage.evaluation.gameDateTime': 'Дата/время игры',
    'scout.playerPage.evaluation.homeTeam': 'Домашняя команда',
    'scout.playerPage.evaluation.score': 'Оценка',
    'scout.playerPage.evaluation.awayTeam': 'Выездная команда',
    'scout.playerPage.evaluation.league': 'Лига',
    'scout.playerPage.evaluation.notes': 'Заметки',
    'scout.playerPage.evaluation.gameScore': 'Счет',
    'scout.playerPage.evaluation.teamStatus': 'Статус команды',
    'scout.playerPage.evaluation.season': 'Сезон',

    'scout.schedule.group.GAME': 'Игры',
    'scout.schedule.group.EVENT': 'События',
    'scout.schedule.my': 'Мое расписание',
    'scout.schedule.all': 'Общее расписание',

    'admin.schedule.gameInfo.clients': 'Клиенты',
    'admin.schedule.gameInfo.productType': 'Тип продукта',
    'admin.schedule.gameInfo.created': 'Дата',
    'admin.schedule.gameInfo.createdBy': 'Создано',

    'clientsAdmin.clients': 'Клиенты',
    'clientsAdmin.users': 'Пользователи',
    'clientsAdmin.clientUsers': 'Пользователи клиентов',
    'clientsAdmin.clientReports': 'Отчеты пользователей',
    'clientsAdmin.clientGames': 'Клиентские игры',
    'clientsAdmin.contracts': 'Контракты',
    'clientsAdmin.deals': 'Сделки',
    'clientsAdmin.scoutContracts': 'Скаут контракты',
    'clientsAdmin.favoriteTeams': 'Любимые команды',
    'clientsAdmin.clientUsers.sendPlaylists': 'Отправлять плейлисты',
    'clientsAdmin.clientUsers.playlistsMask': 'Маска плейлистов',
    'clientsAdmin.supportTemple': null,

    'clientsAdmin.clients.name': 'Имя клиента',
    'clientsAdmin.clients.email': 'Email клиента',
    'clientsAdmin.clients.salesEmail': 'Sales email',
    'clientsAdmin.clients.purchasedAt': 'Дата покупки',
    'clientsAdmin.clients.userName': 'Имя пользователя',
    'clientReports.attachFile.reportUploadingSuccess': 'Отчет успешно загружен',
    'clientReports.attachFile.uploadReport': 'Загрузить отчет',
    'clientReports.clients.email': 'Email',
    'clientsAdmin.users.username': 'Имя пользователя',
    'clientsAdmin.users.accountType': 'Тип',
    'clientsAdmin.users.expireDate': 'До',
    'clientsAdmin.users.password': 'Пароль',
    'clientsAdmin.users.generatePassword': 'Сгенерировать',
    'clientsAdmin.users.passwordToClipboard': 'Пароль скопирован в буфер обмена',
    'clientsAdmin.users.demo': 'Демо аккаунт',
    'clientsAdmin.supportTemple.belongsToValuableCustomer': 'Важный клиент',
    'clientsAdmin.supportTemple.shouldVideoBeUploadedBySupport': 'Загружаем сами',
    'clientsAdmin.clientUsers.client': 'Клиент',
    'clientsAdmin.clientUsers.individualStatus': 'Статус',
    'clientsAdmin.clientUsers.user': 'Пользователь',
    'clientsAdmin.clientUsers.team': 'Команда',
    'clientsAdmin.clientUsers.player': 'Игрок',
    'clientsAdmin.clientUsers.analytic': 'Аналитик',
    'clientsAdmin.clientUsers.featuresMask': null,
    'clientsAdmin.clientUsers.emails': 'Эл. адреса (через зап., исп. %)',
    'clientsAdmin.clientUsers.locales': 'Языки (через зап.)',
    'clientsAdmin.clientUsers.picture': 'Изображение',
    'clientsAdmin.clientUsers.rooms': 'Комнаты',
    'clientsAdmin.clientUsers.clearCaches': 'Очистить кеш',
    'clientsAdmin.clientGames.liteModuleTypes': null,
    'clientsAdmin.clientGames.addingType': 'Тип',
    'clientsAdmin.clientGames.productType': 'Тип продукта',
    'clientsAdmin.clientGames.date': 'Дата',
    'clientsAdmin.clientGames.homeTeam': 'Домашняя команда',
    'clientsAdmin.clientGames.awayTeam': 'Выездная команда',
    'clientsAdmin.clientGames.homeScore': 'Home Score',
    'clientsAdmin.clientGames.awayScore': 'Away Score',
    'clientsAdmin.clientGames.client': 'Клиент',
    'clientsAdmin.clientGames.gameIds': 'Id игр (через зап.)',
    'clientsAdmin.clientGames.status': 'Статус',
    'clientsAdmin.clientGames.season': 'Сезон',
    'clientsAdmin.clientGames.seasonId': 'Id сезона',
    'clientsAdmin.clientGames.modulesMask': 'Modules Mask',
    'clientsAdmin.contracts.liteModulesMask': null,
    'clientsAdmin.contracts.team': 'Команда',
    'clientsAdmin.contracts.season': 'Сезон',
    'clientsAdmin.contracts.client': 'Клиент',
    'clientsAdmin.contracts.dateBegin': 'Дата начала',
    'clientsAdmin.contracts.dateEnd': 'Дата окончания',
    'clientsAdmin.contracts.product': 'Продукт',
    'clientsAdmin.contracts.fulfill': null,
    'clientsAdmin.contracts.dealId': 'ID Сделки',
    'clientsAdmin.scoutContracts.client': 'Клиент',
    'clientsAdmin.scoutContracts.season': 'Сезон',
    'clientsAdmin.scoutContracts.league': 'Лига',
    'clientsAdmin.scoutContracts.team': 'Команда',
    'clientsAdmin.scoutContracts.dateStart': 'Дата начала',
    'clientsAdmin.scoutContracts.dateEnd': 'Дата окончания',
    'clientsAdmin.favoriteTeams.user': 'Пользователь',
    'clientsAdmin.favoriteItems.team': 'Любимые команды',
    'clientsAdmin.favoriteItems.player': 'Любимые игроки',
    'clientsAdmin.favoriteTeams.teamId': 'Id команды',
    'clientsAdmin.favoriteTeams.clientId': 'Id клиента',
    'clientsAdmin.favoriteTeams.userAccountId': 'Id пользовательского акк.',

    'playlistMasks.playerShifts': 'Смены игрока',
    'playlistMasks.teamShifts': 'Смены команды',
    'playlistMasks.gameGoals': 'Голы',
    'playlistMasks.powerPlays': 'Игра в большинстве',
    'playlistMasks.penaltyKills': 'Игра в меньшинстве',

    'scheduleAdmin.games': 'Игры',
    'scheduleAdmin.noClients': 'Нет клиентов',
    'scheduleAdmin.database': 'База данных',
    'scheduleAdmin.gamesUpload': 'Загрузить игры',
    'scheduleAdmin.shifts': 'Смены',

    'scheduleAdmin.apply': 'Применить',
    'scheduleAdmin.clear': 'Очистить',

    'scheduleGames.tableContent.id': 'ID',
    'scheduleGames.tableContent.requestedGameId': 'Рекв. ID',
    'scheduleGames.tableContent.gameType': 'Тип игры',
    'scheduleGames.tableContent.date': 'Дата',
    'scheduleGames.tableContent.teams': 'Команды',
    'scheduleGames.tableContent.seasons': 'Сезон',
    'scheduleGames.tableContent.status': 'Статус',
    'scheduleGames.tableContent.staff': 'Персонал',
    'scheduleGames.tableContent.priority': 'Приоритет',
    'scheduleGames.tableContent.resetAllSort': 'Сбросить сортировку всех столбцов',

    'scheduleGamesTable.priorityBadge.minor': 'Минорный',
    'scheduleGamesTable.priorityBadge.normal': 'Обычный',
    'scheduleGamesTable.priorityBadge.major': 'Важный',
    'scheduleGamesTable.priorityBadge.critical': 'Критический',
    'scheduleGamesTable.priorityBadge.stopper': 'Блокирующий',

    'scheduleAdmin.allGames': 'Все игры',
    'scheduleAdmin.lite': 'Lite',
    'scheduleAdmin.superLite': 'Super lite',
    'scheduleAdmin.pro': 'Pro',
    'scheduleAdmin.scout': 'Scout',
    'scheduleAdmin.preScout': 'Pre scout',
    'scheduleAdmin.slowPremium': 'Slow premium',
    'scheduleAdmin.createNewGame': 'Создать игру',
    'scheduleAdmin.TBA': 'TBA',
    'scheduleAdmin.NA': 'N/A',
    'scheduleAdmin.addComment': 'Отправить',
    'scheduleAdmin.addComment.reply': 'Ответить',

    'scheduleAdmin.gameTeam': '{teamNameRu}',
    'scheduleAdmin.gameSeason': '{teamNameRu}',

    'scheduleAdmin.createGame.apply': 'Подтвердить',
    'scheduleAdmin.createGame.request': 'Заказать',
    'scheduleAdmin.createGame.cancel': 'Отменить заказ',
    'scheduleAdmin.createGame.clear': 'Очистить',
    'scheduleAdmin.createGame.fromDB': 'Из БД',
    'scheduleAdmin.createGame.newGame': 'Новая игра',
    'scheduleAdmin.createGame.game': 'Игра',
    'scheduleAdmin.createGame.gameType': 'Тип игры',
    'scheduleAdmin.createGame.overtimeType': 'Доп время',
    'scheduleAdmin.createGame.support': 'Суппорт',
    'scheduleAdmin.createGame.moderator': 'Модератор',
    'scheduleAdmin.createGame.verifier': 'Верификатор',
    'scheduleAdmin.createGame.client': 'Клиент',
    'scheduleAdmin.createGame.bugged': 'Повреждено',
    'scheduleAdmin.createGame.comment': 'Комментарий',
    'scheduleAdmin.createGame.date': 'Дата',
    'scheduleAdmin.createGame.dateTime': 'Дата и время',
    'scheduleAdmin.createGame.dateTime.zeroTimeZone': 'Дата и время (GMT UTC +00:00)',
    'scheduleAdmin.createGame.season': 'Лига',
    'scheduleAdmin.createGame.homeTeam': 'Домашняя команда',
    'scheduleAdmin.createGame.awayTeam': 'Выездная команда',
    'scheduleAdmin.createGame.expectedUploadDateTime': 'Ожидаемое время загрузки',
    'scheduleAdmin.createGame.timeZone': 'Часовой пояс',
    'scheduleAdmin.createGame.time': 'Время',
    'scheduleAdmin.createGame.status': 'Статус',

    'scheduleAdmin.attachFile.video': 'Видео',
    'scheduleAdmin.attachFile.cropVideo': 'Обрезать Видео',
    'scheduleAdmin.attachFile.protocol': 'Протокол',
    'scheduleAdmin.attachFile.problems': 'Проблемы с видео',
    'scheduleAdmin.attachFile.upload': 'Загрузить файл',
    'scheduleAdmin.attachFile.uploadVideo': 'Загрузить видео',
    'scheduleAdmin.attachFile.uploadGameSheet': 'Загрузить протокол',
    'scheduleAdmin.attachFile.delete': 'Удалить файл',
    'scheduleAdmin.attachFile.copy': 'Скопировать',
    'scheduleAdmin.attachFile.send': 'Отправить',
    'scheduleAdmin.attachFile.videoUploaded': 'Видео загружено',
    'scheduleAdmin.attachFile': 'Прикрепить файл',
    'scheduleAdmin.attachFile.videoUploadingSuccess': 'Видео загружено',
    'scheduleAdmin.attachFile.protocolUploadingSuccess': 'Протокол загружен',

    'scheduleAdmin.shifts.dateTimeFrom': 'Начало смены',
    'scheduleAdmin.shifts.dateTimeTo': 'Конец смены',
    'scheduleAdmin.shifts.capacity': 'Максимум игр',
    'scheduleAdmin.shifts.type': 'Тип',
    'scheduleAdmin.shifts.create': 'Создать',
    'scheduleAdmin.shifts.delete': 'Удалить',
    'scheduleAdmin.shifts.apply': 'Применить',
    'scheduleAdmin.shifts.gameType': 'Тип игры',
    'scheduleAdmin.shifts.saveShifts': 'Сохранить смену',
    'scheduleAdmin.shifts.addShift': 'Добавить смену',

    /***************************************Collector*********************************************************/

    'admin.collector.publishGame': 'Publish Game',
    'admin.collector.reportsWereSent': 'Отчеты отправлены',
    'admin.collector.reportsWereNotSent': 'Отчеты не отправлены',

    'admin.collector.findDuplicates': 'Find duplicates',

    'admin.collector.player.born': 'Дата рождения',
    'admin.collector.player.born.format': 'ДД.ММ.ГГГГ',
    'admin.collector.player.gender': 'Пол',
    'admin.collector.player.gender.male': 'Мужской',
    'admin.collector.player.gender.female': 'Женский',
    'admin.collector.player.firstNameEn': 'Имя (англ.)',
    'admin.collector.player.lastNameEn': 'Фамилия (англ.)',
    'admin.collector.player.firstNameRu': 'Имя (рус.)',
    'admin.collector.player.lastNameRu': 'Фамилия (рус.)',
    'admin.collector.player.firstNameZn': 'Имя (кит.)',
    'admin.collector.player.lastNameZn': 'Фамилия (кит.)',
    'admin.collector.player.jersey': 'Номер',
    'admin.collector.player.playerPosition': 'Позиция',

    /***************************************CrudTable*********************************************************/
    'components.field.create': 'Создать',
    'components.field.clear': 'Очистить',
    'components.field.created': 'Добавлено',
    'components.field.error': 'Ошибка',
    'components.edit.close': 'Закрыть',
    'components.edit.cancel': 'Отменить',
    'components.edit.save': 'Сохранить',
    'components.edit.title': 'Редактирование',

    /***************************************Glossary*********************************************************/
    'support.glossary.title': 'Словарь',
    'support.glossary.info': 'Термины и определения',
    'support.glossary.entries': '{entries, plural, =1 { запись} few { записи} many { записей} other { записи}}',
    'support.videoManuals.title': 'Обучение',
    'support.videoManuals.info': 'Видеоуроки по порталу',
    'glossary.components.quickJump': 'Быстрый переход',
    'glossary.term.acc': 'Ускорение, м/с²',
    'glossary.def.acc':
        'Среднее изменение скорости или направления движения игрока. Чем выше показатель - тем лучше (высокие показатели говорят о взрывных качествах катания).',
    'glossary.term.aade': 'Среднее время атаки [Входы]',
    'glossary.def.aade':
        'Атака заканчивается, когда шайба выходит из зоны соперника (время между моментом входа в зону и выходом из зоны) или звучит свисток.',
    'glossary.term.aadx': 'Среднее время атаки [Выходы]',
    'glossary.def.aadx':
        'Суммарное время атаки (начинающееся с момента входа в зону соперника после выхода из своей зоны), разделенное на количество выходов из своей зоны.',
    'glossary.term.aar': 'Угол отскока',
    'glossary.def.aar':
        'Угол между центральной осью проходящей между центром обоих ворот и вектором от сейва по направлению, куда был направлен отскок.',
    'glossary.term.ape': 'Среднее владение [Входы]',
    'glossary.def.ape':
        '[Команда] Время владения шайбой в зоне, разделенное на кол-во входов (владение шайбой начинается с момента входа)',
    'glossary.term.apx': 'Среднее владение [Выходы]',
    'glossary.def.apx':
        'Время владения команды в зоне атаки, разделенное на общее количество выходов из своей зоны. Время владения заканчивается, когда шайба выходит из зоны атаки или звучит свисток.',
    'glossary.term.asl': 'Средняя длина смены',
    'glossary.def.asl': 'Общее время на льду, разделенное на количество смен в течение матча.',
    'glossary.term.ass': 'Средняя скорость броска',
    'glossary.def.ass':
        'Среднее значение скорости броска, которая высчитывается как дистанция между моментом броска и расположение вратаря, разделенная на время броска, которое шайба была в движении до момента остановки.',
    'glossary.term.bac': 'Темп в оборону, %',
    'glossary.def.bac':
        'Суммарная дистанция, которую игрок проехал в сторону зоны обороны на скорости выше среднего (20 км/ч), разделенное на общую дистанцию.',
    'glossary.term.boc': 'Отбор корпусов',
    'glossary.def.boc':
        'Количество раз, когда игрок использует свое тело, чтобы оттенсить соперника от шайбы. В результате меняется команда, владеющая шайбой. Шайба может быть подобрана как игроком, который сделал отбор, так и его командой, отбор корпусом получает изначальный игрок.',
    'glossary.term.clr': 'Выброс шайбы',
    'glossary.def.clr': 'Выброс шайбы из зоны защиты с целью остановить атаку соперника.',
    'glossary.term.cin': 'Ведение [Входы]',
    'glossary.def.cin': 'Вход в зону через ведение, когда шайба на клюшке.',
    'glossary.term.cout': 'Ведение [Выходы]',
    'glossary.def.cout': 'Выходы из своей зоны (пересечение синей линии) с шайбой на клюшке.',
    'glossary.term.deff': 'Эффективность в обороне',
    'glossary.def.deff':
        'Комбинированный показатель эффективности игры в обороне, основанный на комплексе оборонительных показателей.',
    'glossary.term.dzp': 'Передача в зоне обороны',
    'glossary.def.dzp': 'Целенаправленный пас или пас через борт в зоне обороны.',
    'glossary.term.dis': 'Дистанция',
    'glossary.def.dis': 'Дистанция, которую игрок (или команда) проехал за определенный промежуток времени.',
    'glossary.term.din': 'Вброс [Входы]',
    'glossary.def.din':
        'Вход в зону через вброс шайбы в зону атаки, без целенаправленного развития атаки с помощью паса или ведения.',
    'glossary.term.dout': 'Выброс [Выходы]',
    'glossary.def.dout':
        'Моменты выхода из зоны, когда игрок выбрасывает шайбу из зоны защиты без целенаправленного паса.',
    'glossary.term.OZentry': 'Вход в зону соперника',
    'glossary.def.OZentry':
        'Действие атакующей команды, при котором шайба пересекает синюю линию соперника. Может быть входом через ведение, пас или вброс, а также может быть успешным и неуспешным.',
    'glossary.term.DZexit': 'Выходы из зоны обороны',
    'glossary.def.DZexit': 'Моменты, когда шайба пересекают синию линию между зоной обороны и средней зоной.',
    'glossary.term.fow': 'Выигранное вбрасывание',
    'glossary.def.fow':
        'Команда и игрок (стоящий на вбрасывании), которые получают первые 2 полные секунды владения шайбой или наносят бросок в сторону ворот, считаются выигрывшими вбрасывание.',
    'glossary.term.fpass': 'Неточная передача',
    'glossary.def.fpass':
        'Неточная целенаправленная передача от игрока к партнеру по команде или неточная передача через борт, которая закончилась потерей. Передача могла быть перехвачена соперником, либо закончилась ничейной шайбой.',
    'glossary.term.fcin': 'Неуспешное ведение [Входы]',
    'glossary.def.fcin':
        'После входа в зону шайба покинула зону атаки в течение 4 секунд после входа и не был нанесен хотя бы 1 бросок в сторону ворот.',
    'glossary.term.fdin': 'Неуспешный вброс [Входы]',
    'glossary.def.fdin':
        'Вброс, при котором теряется владение шайбой (пока шайба находится в зоне) или шайба покинула зону атаки в течение 4 секунд.',
    'glossary.term.fpin': 'Неуспешный пас [Входы]',
    'glossary.def.fpin':
        'Входы через передачу, которые закончились выходом шайбы из зоны в течение первых 4 секунд после входа или если не было броска в сторону ворот в течение этих 4 секунд.',
    'glossary.term.gs': 'Оценка игры',
    'glossary.def.gs':
        'Рейтинг игрока, основанный на статистической эффективности игрока во время определенной игры или серии игр.',
    'glossary.term.gva': 'Потеря',
    'glossary.def.gva':
        'Любой эпизод, при котором игрок теряет шайбу из-за неточного паса или потери контроля над шайбой.',
    'glossary.term.gvap': 'Потеря, %',
    'glossary.def.gvap': 'Количество потерь, разделенное на количество владений.',
    'glossary.term.zonegva': 'Потери по зонам, число',
    'glossary.def.zonegva': 'Количество потерь в конкретной зоне.',
    'glossary.term.zonegvap': 'Потеря, % по зонам',
    'glossary.def.zonegvap': 'Количество потерь в определенной зоне, разделенное на количество владений в данной зоне.',
    'glossary.term.goal': 'Голевой момент',
    'glossary.def.goal': 'Ситуация, когда шайба полностью пересекает линию ворот.',
    'glossary.term.int': 'Перехват',
    'glossary.def.int': 'Количество прерванных прямых пасов, которые влекут смену команды, владеющей шайбой.',
    'glossary.term.mss': 'Максимальная скорость броска',
    'glossary.def.mss':
        'Максимальное значение скорости броска, которая высчитывается как дистанция между моментом броска и расположение вратаря, разделенная на время броска, которое шайба была в движении до момента остановки.',
    'glossary.term.nzp': 'Передача в средней зоне',
    'glossary.def.nzp': 'Целенаправленный пас или пас через борт в средней зоне.',
    'glossary.term.nop': 'Число владений',
    'glossary.def.nop': 'Количество уникальных владений шайбой игроком или командой.',
    'glossary.term.znop': 'Число владений по зонам',
    'glossary.def.znop': 'Количество уникальных владений шайбой в конкретной зоне.',
    'glossary.term.oze': 'Эффективность в атаке',
    'glossary.def.oze':
        'Комбинированный показатель эффективности игры в атаке, основанный на комплексе атакующих показателей.',
    'glossary.term.ozp': 'Передача в зоне атаки',
    'glossary.def.ozp': 'Целенаправленный пас или пас через борт в зоне атаки.',
    'glossary.term.ozinf': 'Позиционная атака',
    'glossary.def.ozinf':
        'Время, которое команда проводит в зоне атаки и контроллирует шайбу в структурированном расположении на льду.',
    'glossary.term.ozoof': 'Атака без построения',
    'glossary.def.ozoof':
        'Время, которое команда проводит в зоне атаки и не контроллирует шайбу (борьба в углу, игра на отскоках, неточные пасы).',
    'glossary.term.ozt': 'Время в зоне атаки [Большинство - Звенья]',
    'glossary.def.ozt': 'Общее время, которое шайба провела в зоне атаки, пока игроки были на льду.',
    'glossary.term.ozft': 'Время в позиционной атаке [Большинство - Звенья]',
    'glossary.def.ozft': 'Процент времени в позиционной атаки от общего времени в большинстве.',
    'glossary.term.pacep': 'Темп, %',
    'glossary.def.pacep':
        'Суммарная дистанция, которую игрок проехал на скорости выше среднего (20 км/ч), разделенное на общую дистанцию.',
    'glossary.term.pass': 'Передача',
    'glossary.def.pass': 'Направленное действие попытки передачи шайбы в направлении партнера (прямое или через борт).',
    'glossary.term.pine': 'Пас [Входы]',
    'glossary.def.pine':
        'Вход в зону через пас партнеру, во время которого шайба пересекает синюю линю (вход засчитывается игроку, который делал передачу).',
    'glossary.term.pout': 'Пас [Выходы]',
    'glossary.def.pout':
        'Выходы из зоны защиты через целенаправленный пас партнеру по команде, который пересекает синюю линию (выход засчитывается игроку, который делал передачу).',
    'glossary.term.poss': 'Владение',
    'glossary.def.poss': 'Ситуация, когда игрок касается шайбы и контроллирует ее.',
    'glossary.term.possp': 'Владение, % [Сравнение звеньев]',
    'glossary.def.possp': 'Командный процент владения шайбой, когда звено / пара находились на льду..',
    'glossary.term.pot': 'Время владения (игрок)',
    'glossary.def.pot': 'Общее время владения шайбой игроком, когда шайба на клюшке и контроллируется игроком.',
    'glossary.term.pap': 'Потенциально голевая передача (основная)',
    'glossary.def.pap': 'Последний пас перед броском (без смены владеющей командой между пасом и броском).',
    'glossary.term.pas': 'Потенциально голевая передача (вторичная)',
    'glossary.def.pas':
        'Последний пас перед основной потенциально голевой передачей (без смены владеющей командой между вторым пасом, первым пасом и броском).',
    'glossary.term.pr': 'Ничейная шайба',
    'glossary.def.pr':
        'Ситуация, при которой игрок подбирает шайбу, которая не принадлежит ни одной из команд и не была направлена ни одному из игроков.',
    'glossary.term.ptioz': 'Время в зоне атаки',
    'glossary.def.ptioz': 'Время, которое шайба провела в зоне атаки, в момент нахождения игрока на льду.',
    'glossary.term.ptiozp': 'Время в зоне атаки, %',
    'glossary.def.ptiozp': 'Процент времени, которое шайбой находилась в зоне атаки от всего времени.',
    'glossary.term.rr': 'Подбор',
    'glossary.def.rr':
        'Количество подборов шайбы после сейва вратаря с отскоком; является первым касанием после отскока, может быть как отскок в зоне защиты, так и в зоне нападения.',
    'glossary.term.rbp': 'Отскоки, %',
    'glossary.def.rbp':
        'Количество бросков, после которых был отскок, от общего количества бросков в створ, которые были отбиты вратарем.',
    'glossary.term.rgp': 'Перестроение',
    'glossary.def.rgp':
        'Время, когда атакующая команда откатывается назад в среднюю или свою зоны с целью начать новую атаку.',
    'glossary.term.rush': 'Развитие атаки',
    'glossary.def.rush':
        'Время, которое атакующая команда проводит от перестроения до зоны атаки (проход средней зоны).',
    'glossary.term.savep': 'Сейв, %',
    'glossary.def.savep': 'Количество сейвов, разделенное на количество всех бросков в створ.',
    'glossary.term.scsa': 'Голевые моменты / броски. %',
    'glossary.def.scsa':
        'Показывает, насколько опасными были броски команды (процент голевых моментов от общего количества бросков).',
    'glossary.term.sc': 'Голевой момент',
    'glossary.def.sc':
        '1. Бросок из зоны трапеции перед воротами; 2. Бросок после потенциально голевой передачи, которая пересекате центральную ось площадки (заставляя вратаря перемещаться); 3. Повторный незаблокированный бросок после отскока от вратаря.',
    'glossary.term.shotp': 'Реализация, %',
    'glossary.def.shotp': 'Количество голов, разделенное на количество бросков в створ.',
    'glossary.term.sa': 'Бросок',
    'glossary.def.sa':
        'Бросок в сторону ворот или подправление, которое дошло до вратаря, оказалось голом, промахом или было заблокировано.',
    'glossary.term.sap': 'Броски, %',
    'glossary.def.sap':
        'Процент бросков в сторону ворот, нанесенных командой звена / пары от всех бросков в сторону ворот, нанесенных обеими командами, пока звено / пара были на льду.',
    'glossary.term.sape': 'Броски [Входы]',
    'glossary.def.sape': 'Количество бросков в сторону ворот, сделанных командой, после входа(ов) в зону',
    'glossary.term.sapx': 'Броски [Выходы]',
    'glossary.def.sapx':
        'Количество бросков в сторону ворот, сделанных командой, после выхода(ов) из своей зоны и последующего входа в зону соперника.',
    'glossary.term.sog': 'Бросок в створ',
    'glossary.def.sog': 'Бросок в сторону ворот или подправление, которое дошло до вратаря или оказалось голом.',
    'glossary.term.swtp': 'Скорость с шайбой, м/с',
    'glossary.def.swtp': 'Средняя скорость игрока во время ведения шайбы.',
    'glossary.term.speed': 'Скорость, м/с',
    'glossary.def.speed': 'Средняя скорость игрока, когда он находится на льду.',
    'glossary.term.sch': 'Отбор клюшкой',
    'glossary.def.sch':
        'Количество раз, когда игрок использует клюшку, чтобы выбить шайбу у соперника с шайбой. Заканчивается сменой команды, владеющей шайбой. Шайба может быть подобрана как игроком, который сделал отбор, так и его командой, отбор клюшкой получает изначальный игрок.',
    'glossary.term.sentry': 'Успешный вход',
    'glossary.def.sentry':
        'После входа в зону шайба не покинула зону атаки в течение 4 секунд после входа, или если был нанесен хотя бы 1 бросок в сторону ворот.',
    'glossary.term.sucr': 'Процент успешных [Входы / Выходы]',
    'glossary.def.sucr':
        'Количество успешных входов / выходов из зоны, разделенное на общее количество входов / выходов.',
    'glossary.term.scine': 'Успешное ведение [Входы]',
    'glossary.def.scine':
        'После входа в зону шайба не покинула зону атаки в течение 4 секунд после входа, или если был нанесен хотя бы 1 бросок в сторону ворот.',
    'glossary.term.sdine': 'Успешный вброс [Входы]',
    'glossary.def.sdine':
        'Вброс, при котором сохраняется или возвращается владение шайбой (пока шайба находится в зоне) или шайба не покинула зону атаки в течение 4 секунд.',
    'glossary.term.spine': 'Успешный пас [Входы]',
    'glossary.def.spine':
        'После входа в зону через пас (игрок чисто принял шайбу) шайба не покинула зону атаки в течение 4 секунд после входа, или если был нанесен хотя бы 1 бросок в сторону ворот.',
    'glossary.term.scoutx': 'Успешное ведение [Выходы]',
    'glossary.def.scoutx':
        'Выходы из своей зоны (пересечение синей линии) с шайбой на клюшке, после которого шайба не вернулась в зону защиты в течение 3 секунд после выхода.',
    'glossary.term.sdoutx': 'Успешный выброс [Выходы]',
    'glossary.def.sdoutx':
        'Моменты выхода из зоны, когда игрок выбрасывает шайбу из зоны защиты без целенаправленного паса, после которого команда завладела шайбой (в любой момент после выхода) или она не вернулась в зону защиты в течение 10 секунд после выхода.',
    'glossary.term.spoutx': 'Успешный пас [Выходы]',
    'glossary.def.spoutx':
        'Выходы из своей зоны (пересечение синей линии) через пас, который был успешно принят партнером, после которого шайба не вернулась в зону защиты в течение 3 секунд после выхода.',
    'glossary.term.spass': 'Точная передача',
    'glossary.def.spass':
        'Успешная целенаправленная передача от игрока к партнеру по команде или успешный пас через борт, который закончился владением игрока той же команды.',
    'glossary.term.tpt': 'Время владения команды',
    'glossary.def.tpt':
        'Общее время владения шайбой командой, включая владения игроками, время успешных передач, подбор шайб и входы в зону / выходы из зоны.',
    'glossary.term.toi': 'Время на льду',
    'glossary.def.toi': 'Общее время, проведенное игроком / командой на льду.',
    'glossary.term.tpp': 'Среднее время владения',
    'glossary.def.tpp': 'Время владение, разделенное на число владений.',
    'glossary.term.xge': 'хG [Входы]',
    'glossary.def.xge':
        'Среднее xG (вероятность броска оказаться голом), созданное командой после входа(ов) в зону. Подсчет заканчивается с моментом выхода шайбы из зоны.',
    'glossary.term.xgex': 'хG [Выходы]',
    'glossary.def.xgex':
        'Среднее xG (вероятность броска оказаться голом), созданное командой после выхода(ов) из своей зоны и последующего входа в зону соперника. Подсчет заканчивается с моментом выхода шайбы из зоны.',
    'glossary.term.xg': 'хG',
    'glossary.def.xg':
        'Вероятность того, что бросок игрока окажется голом; учитывая характеристики броска (дистанция до ворот, угол броска, скорость броска), а также расположение игрока, его партнеров по команде и игроков соперника.',
    'glossary.term.zs': 'Смены в зоне атаки, %',
    'glossary.def.zs':
        'Смены, которые игрок начинает в зоне атаки (игра начинается вбрасыванием в зоне атаки), разделенные на все смены в зоне атаки или обороны, которые игрок начинал во время вбрасывания (не по ходу игры). Показывает "сложность" игровых ситуация игрока - чем выше показатель, тем проще было играть (больше времени начиналось в зоне атаки).',
    'glossary.term.epr': 'Вход. Positive rate',
    'glossary.def.epr': 'Вход в зону нападения с последующим броском или длительным непрерывным владением.',

    /****************************************Video manuals*****************************************************/
    'videoManuals.title.intro': 'Начало',
    'videoManuals.link.intro': 'https://www.youtube.com/embed/dYYRvvyzj5g',
    'videoManuals.title.filters': 'Фильтры',
    'videoManuals.link.filters': 'https://www.youtube.com/embed/ZMJaClfp0SU',
    'videoManuals.title.video': 'Видео плеер',
    'videoManuals.link.video': 'https://www.youtube.com/embed/IRNNj9GdvRc',
    'videoManuals.title.skaters': 'Игроки',
    'videoManuals.link.skaters': 'https://www.youtube.com/embed/Zng0NTEjNsk',
    'videoManuals.title.pppk': 'Неравные составы',
    'videoManuals.link.pppk': 'https://www.youtube.com/embed/hWmeYtlyjec',
    'videoManuals.title.reports': 'Отчеты',
    'videoManuals.link.reports': 'https://www.youtube.com/embed/6HdNd3prpZQ',
    'videoManuals.title.stats': 'Статистика',
    'videoManuals.link.stats': 'https://www.youtube.com/embed/EMxvEGqkA38',
    'videoManuals.title.shifts': 'Смены',
    'videoManuals.link.shifts': 'https://www.youtube.com/embed/qCIDp7GWjsU',

    /****************************************Date values*****************************************************/

    'calendar.view.week': 'Неделя',
    'calendar.view.month': 'Месяц',

    'calendar.weekday.monday': 'Понедельник',
    'calendar.weekday.tuesday': 'Вторник',
    'calendar.weekday.wednesday': 'Среда',
    'calendar.weekday.thursday': 'Четверг',
    'calendar.weekday.friday': 'Пятница',
    'calendar.weekday.saturday': 'Суббота',
    'calendar.weekday.sunday': 'Воскресенье',

    'calendar.weekday.short.monday': 'ПН',
    'calendar.weekday.short.tuesday': 'ВТ',
    'calendar.weekday.short.wednesday': 'СР',
    'calendar.weekday.short.thursday': 'ЧТ',
    'calendar.weekday.short.friday': 'ПТ',
    'calendar.weekday.short.saturday': 'СБ',
    'calendar.weekday.short.sunday': 'ВС',

    'calendar.month.january': 'Январь',
    'calendar.month.february': 'Февраль',
    'calendar.month.march': 'Март',
    'calendar.month.april': 'Апрель',
    'calendar.month.may': 'Май',
    'calendar.month.june': 'Июнь',
    'calendar.month.july': 'Июль',
    'calendar.month.august': 'Август',
    'calendar.month.september': 'Сентябрь',
    'calendar.month.october': 'Октябрь',
    'calendar.month.november': 'Ноябрь',
    'calendar.month.december': 'Декабрь',

    'calendar.more': '+ {count, plural, few {# события} many {# событий} other {# события}}',

    /****************************************Clients cabinet values*****************************************************/

    'clientsCabinet.payment.successPayment':
        'Your purchase has been successfully payed. You will be redirected back soon.',
    'clientsCabinet.payment.failedPayment': 'Error occurred while processing your payment',
    'clientsCabinet.payment.goBack': 'НАЗАД',
    'clientsCabinet.payment.gamesToBePurchased': 'Количество игр',
    'clientsCabinet.payment.pricePerGame': 'Стоимость игры',
    'clientsCabinet.payment.totalAmount': 'Всего',
    'clientsCabinet.payment.gamesLimitToBePurchased': 'Max. amount of games to be purchased: {games}',
    'clientsCabinet.payment.includingHst': 'Включая HST/GST {taxPercent}%',
    'clientsCabinet.payment.payNow': 'ОПЛАТИТЬ',
    'clientsCabinet.payment.close': 'ЗАКРЫТЬ',

    'clientsCabinet.calendar.purchaseCredits': 'Купить кредиты',
    'clientsCabinet.calendar.creditsLeft': 'Кредитов',
    'clientsCabinet.calendar.gamesPurchased': 'Кредитов потрачено',

    'clientsCabinet.calendar.videoUploader.placeholder': 'Перетащите',
    'clientsCabinet.calendar.videoUploader.placeholderHint': 'ваши файлы сюда, или выберите',
    'clientsCabinet.calendar.videoUploader.cancelAll': 'Отменить загрузку',
    'clientsCabinet.calendar.videoUploader.upload': 'Загрузить',
    'clientsCabinet.calendar.videoUploader.uploaded': 'Загружен',
    'clientsCabinet.calendar.videoUploader.uploading': 'В процессе',
    'clientsCabinet.calendar.videoUploader.rejected': 'Отменен',
    'clientsCabinet.calendar.videoUploader.pending': 'Готово к загрузке',
    'clientsCabinet.calendar.videoUploader.waiting': 'В обработке',

    'clientsCabinet.calendar.videoUploader.description.h1': 'Если вы загружаете несколько файлов, ',
    'clientsCabinet.calendar.videoUploader.description.h2':
        'пожалуйста, четко укажите, какой период содержится в каждом файле (пример: ',
    'clientsCabinet.calendar.videoUploader.description.h3':
        'Period_1, Period_2, Period_1.1, Period_1.2, Period_2.1, Period_2.2 ',
    'clientsCabinet.calendar.videoUploader.description.h4': 'и т.д.). Это поможет нам быстрее обработать видео. ',
    'clientsCabinet.calendar.videoUploader.description.h5':
        'Обратите внимание, что вы можете загружать до 10 видеофайлов (максимальный размер файла 2 ГБ) одновременно.',
    'clientsCabinet.calendar.videoUploader.description.h6':
        'Допустимые форматы: AVI, MOV, MP4, MKV, MTS, M2TS, MPG, M4V, WMV, ASF',
    'clientsCabinet.calendar.videoUploader.description.p1': 'Если вы загружаете несколько файлов, ',
    'clientsCabinet.calendar.videoUploader.description.p2':
        'пожалуйста, четко укажите, какой период содержится в каждом файле (пример: ',
    'clientsCabinet.calendar.videoUploader.description.p3':
        'Period_1, Period_2, Period_1.1, Period_1.2, Period_2.1, Period_2.2 ',
    'clientsCabinet.calendar.videoUploader.description.p4': 'и т.д.). Это поможет нам быстрее обработать видео. ',
    'clientsCabinet.calendar.videoUploader.description.p5':
        'Обратите внимание, что вы можете загружать до 10 видеофайлов (максимальный размер файла 2 ГБ) одновременно.',
    'clientsCabinet.calendar.videoUploader.description.p6':
        'Допустимые форматы: AVI, MOV, MP4, MKV, MTS, M2TS, MPG, M4V, WMV, ASF',
    'clientsCabinet.calendar.sheetUploader.description.p1':
        'Пожалуйста, убедитесь, что игровой протокол полон, разборчив и включает в себя:',

    'clientsCabinet.calendar.videoUploader.uploadIsBlocked':
        'Для текущего статуса реквеста игры дополнение видео файлов не предусмотрено.',
    'clientsCabinet.calendar.videoUploader.inProgress.warning':
        'Специалисты Айсберга уже производят Вашу игру. Вы действительно хотите загрузить свое видео?',
    'clientsCabinet.calendar.videoUploader.inProgress.yes': 'Да',
    'clientsCabinet.calendar.videoUploader.inProgress.no': 'Нет',
    'clientsCabinet.calendar.videoUploader.beforeApply.warning':
        'Вы собираетесь заказать игру, с вас будет списан 1 кредит, вы уверены?',
    'clientsCabinet.calendar.sheetUploader.description.li1': 'Имена и номера игроков',
    'clientsCabinet.calendar.sheetUploader.description.li2': 'Все голы и передачи',
    'clientsCabinet.calendar.sheetUploader.description.li3': 'Все штрафы',
    'clientsCabinet.calendar.sheetUploader.description.li4':
        'Исходный вратарь (при смене вратаря укажите время и период смены)',
    'clientsCabinet.calendar.sheetUploader.description.p2': 'Допустимые форматы: PDF, DOC, TXT, PAGES',

    /****************************************Clients cabinet PRE-SCOUT products descriptions*****************************************************/

    'clientsCabinet.productsDescription.preScout.description.h1': 'TPE',
    'clientsCabinet.productsDescription.preScout.description.h2': 'PRE-SCOUT',
    'clientsCabinet.productsDescription.preScout.description.h3': 'Plan.',
    'clientsCabinet.productsDescription.preScout.description.h4': 'Prepare.',
    'clientsCabinet.productsDescription.preScout.description.h5': 'Execute.',

    'clientsCabinet.productsDescription.preScout.description.m1':
        'TPE Sports Analytics Pre-Scout Product provides teams with detailed insights and advanced scouting reports on their upcoming opponents.',

    'clientsCabinet.productsDescription.preScout.description.m2':
        'Our Pre-Scout Technology System will help coaches increase efficiency when preparing for upcoming games by automatically collecting highly relevant statistics and analytics including: ',

    'clientsCabinet.productsDescription.preScout.description.ul1.li1': 'Goals, assists, shots and shot attempts',

    'clientsCabinet.productsDescription.preScout.description.ul1.li2': 'Shifts breakdown by player',

    'clientsCabinet.productsDescription.preScout.description.ul1.li3': 'Face-offs',

    'clientsCabinet.productsDescription.preScout.description.ul1.li4':
        'Special teams play - power play and penalty kill',

    'clientsCabinet.productsDescription.preScout.description.ul1.li5': 'Corresponding video',

    'clientsCabinet.productsDescription.preScout.description.m3':
        'This list of statistics and metrics comes with corresponding video breakdown centered around coaches key points of preparation including: ',

    'clientsCabinet.productsDescription.preScout.description.ul2.li1': 'Special teams formations',

    'clientsCabinet.productsDescription.preScout.description.ul2.li2': 'Face-offs formations and strategy',

    'clientsCabinet.productsDescription.preScout.description.ul2.li3': 'Breakouts / forechecks',

    'clientsCabinet.productsDescription.preScout.description.ul2.li4': 'How / where opponent generates shots from',

    'clientsCabinet.productsDescription.preScout.description.ul2.li5':
        "Advanced scouting reports on the opposition's goaltender",

    'clientsCabinet.productsDescription.preScout.description.m4':
        'The time-saving this product generates will allow teams to realign and focus additional resources on other areas of preparation in advance of their games.',

    'clientsCabinet.productsDescription.preScout.description.m5':
        'Our Pre-Scout Technology System is set to revolutionize how teams and coaches prepare for games. Our  user-friendly portal is easy to navigate and filter by different metrics and events. It comes equipped with a Summary Page, Shifts Page and Goaltenders Page.',

    'clientsCabinet.productsDescription.preScout.description.m6': 'The Shifts Page ',

    'clientsCabinet.productsDescription.preScout.description.m7':
        'shows each individual player’s shifts and is accompanied with corresponding video of those shifts. It is optimal for pre-scouting specific players on the opposition and understanding their tendencies.',

    'clientsCabinet.productsDescription.preScout.description.m8': 'The Summary Page ',

    'clientsCabinet.productsDescription.preScout.description.m9':
        'allows you to filter by team, number of games (or quick button - last 5 games, last 10 games), specific periods and strength. It also comes equipped with: ',

    'clientsCabinet.productsDescription.preScout.description.ul3.li1': 'Shot location maps',

    'clientsCabinet.productsDescription.preScout.description.ul3.li2': 'Heat maps',

    'clientsCabinet.productsDescription.preScout.description.ul3.li3': 'Face-offs maps',

    'clientsCabinet.productsDescription.preScout.description.m10':
        'It includes a table with individual player statistics and metrics including: ',

    'clientsCabinet.productsDescription.preScout.description.m11':
        'goals, assists, shots, shot attempts, shots in the danger zone, hits, time on ice and more',

    'clientsCabinet.productsDescription.preScout.description.m12': 'The Goalies Page ',

    'clientsCabinet.productsDescription.preScout.description.m13': 'includes:',

    'clientsCabinet.productsDescription.preScout.description.ul4.li1':
        'Goalie Chart which highlights the opposing goaltender’s save percentage by shot location on net, allowing teams to easily identify areas of strength and weakness',

    'clientsCabinet.productsDescription.preScout.description.ul4.li2':
        'Shot Location Map which depicts the areas where the goaltender faces shots from',

    'clientsCabinet.productsDescription.preScout.description.ul4.li3':
        'Zones Map that indicates the goaltender’s save percentage on shots from different areas in the offensive zone',

    'clientsCabinet.productsDescription.preScout.description.m14':
        'Lastly, it comes equipped with corresponding video of all goals, shot attempts, scoring chances, shots with rebounds, shots without rebounds, blocked shots and missed shots',

    'clientsCabinet.productsDescription.preScout.description.m15':
        'In hockey’s increasingly competitive environment, access to relevant and accurate information on the opposition is crucial in order to strategize effectively and afford your team the best opportunity to win. TPE’s Pre-Scout Technology System will allow teams to do just that.',

    /****************************************Clients cabinet SCOUT products descriptions*****************************************************/

    'clientsCabinet.productsDescription.scout.description.h1': 'ICEBERG',
    'clientsCabinet.productsDescription.scout.description.h2': 'SCOUT',
    'clientsCabinet.productsDescription.scout.description.h3': 'Identify.',
    'clientsCabinet.productsDescription.scout.description.h4': 'Recruit.',
    'clientsCabinet.productsDescription.scout.description.h5': 'Excel.',

    'clientsCabinet.productsDescription.scout.description.m1':
        'TPE Sports Analytics Scout Technology System is revolutionizing player management and how teams scout, identify, recruit and draft talent.',

    'clientsCabinet.productsDescription.scout.description.m2':
        'Our Scout Technology System provides teams and management with detailed insights and statistics as well as broken down game film on individual player’s throughout the entirety of their career. It will increase team’s scouting departments efficiency and coverage by allowing them to quickly and easily navigate through advanced analytics, statistics and game film on hundreds of players from their personal computers.',

    'clientsCabinet.productsDescription.scout.description.m3': 'Relevant statistics and analytics includes:',

    'clientsCabinet.productsDescription.scout.description.ul1.li1': 'Goals, assists, shots and shot attempts',

    'clientsCabinet.productsDescription.scout.description.ul1.li2': 'Shifts breakdown by player',

    'clientsCabinet.productsDescription.scout.description.ul1.li3': 'Face-offs',

    'clientsCabinet.productsDescription.scout.description.ul1.li4': 'Special teams play - power play and penalty kill',

    'clientsCabinet.productsDescription.scout.description.ul1.li5': 'Corresponding video',

    'clientsCabinet.productsDescription.scout.description.m4':
        'The time-saving this product generates will allow teams to realign and focus additional resources on other areas of hockey operations.',

    'clientsCabinet.productsDescription.scout.description.m5':
        'Our fast and reliable, user-friendly portal makes it easy to filter by player and navigate to an individual player’s profile page. It allows users to filter by team, league, and/or position. From there they are able to ',

    'clientsCabinet.productsDescription.scout.description.m6':
        'identify and sort leaders by specific metrics and statistics including; goals, assists, shots, shot attempts, shots in the danger zone, hits, time on ice and more.',

    'clientsCabinet.productsDescription.scout.description.m7': 'The Players Page ',

    'clientsCabinet.productsDescription.scout.description.m8': 'allows users to filter a player’s stats by:',

    'clientsCabinet.productsDescription.scout.description.ul2.li1': 'Last 5 games',

    'clientsCabinet.productsDescription.scout.description.ul2.li2': 'Last 10 games',

    'clientsCabinet.productsDescription.scout.description.ul2.li3': 'Current season / Career',

    'clientsCabinet.productsDescription.scout.description.ul2.li4': 'Home games / away games',

    'clientsCabinet.productsDescription.scout.description.ul2.li5': 'Game’s team won / lost ',

    'clientsCabinet.productsDescription.scout.description.ul2.li6':
        'Games against specific opponent / group of opponents',

    'clientsCabinet.productsDescription.scout.description.ul2.li7': 'Specific individual games',

    'clientsCabinet.productsDescription.scout.description.m9':
        'Player metrics and statistics in the selected games will be shown ',

    'clientsCabinet.productsDescription.scout.description.m10': 'compared to the league average for that position.',

    'clientsCabinet.productsDescription.scout.description.m11':
        'By selecting an individual metric, users will be able to see said player’s ',

    'clientsCabinet.productsDescription.scout.description.m12':
        'game-by-game tendencies and each corresponding video clip for the selected metric.',

    'clientsCabinet.productsDescription.scout.description.m13':
        'Lastly, the Players Page is equipped with TPE’s Player Comparison Tool which can be used for scouting (to compare different players) or when considering potential trades or call-ups.',

    'clientsCabinet.productsDescription.scout.description.m14':
        'In hockey’s increasingly competitive environment, access to relevant and accurate information on current and potential talent is crucial in order to build dynamic roster. TPE’s Scout Technology System will allow teams to do just that',

    // Scout product

    'scout.playerSearch.advantageSearchSwitcherTitle': 'Расширенный поиск',
    'scout.playerSearch.personalDataTitle': 'Личные данные',
    'scout.playerSearch.gameDataTitle': 'Данные по играм',
    'scout.playerSearch.clearAllTitle': 'Очистить всё',
    'scout.playerSearch.typeLabel': 'Позиция',

    'scout.playerSearch.typeLabel.d': 'З',
    'scout.playerSearch.typeLabel.g': 'В',
    'scout.playerSearch.typeLabel.f': 'Ф',

    'scout.playerSearch.typeLabel.l': 'Ф(Л)',
    'scout.playerSearch.typeLabel.r': 'Ф(П)',

    'scout.playerSearch.typeLabel.lf': 'Ф(Л)',
    'scout.playerSearch.typeLabel.rf': 'Ф(П)',
    'scout.playerSearch.typeLabel.cf': 'Ф(Ц)',

    'scout.playerSearch.typeLabel.c': 'Ф(Ц)',
    'scout.playerSearch.typeLabel.cl': 'Ф(ЦЛ)',
    'scout.playerSearch.typeLabel.cr': 'Ф(ЦП)',

    'scout.playerSearch.typeLabel.clf': 'Ф(ЦЛ)',
    'scout.playerSearch.typeLabel.crf': 'Ф(ЦП)',

    'scout.playerSearch.typeLabel.lrf': 'Ф(ЛП)',
    'scout.playerSearch.typeLabel.clrf': 'Ф(ЦЛП)',

    'scout.playerSearch.sideLabel': 'Основная бросковая рука',

    'scout.playerSearch.sideLabel.left': 'ЛЕВАЯ',
    'scout.playerSearch.sideLabel.right': 'ПРАВАЯ',

    'scout.playerSearch.heightLabel': 'Рост',
    'scout.playerSearch.weightLabel': 'Вес',

    'scout.playerSearch.from': 'от',
    'scout.playerSearch.to': 'до',

    'scout.playerSearch.min': 'мин',
    'scout.playerSearch.kg': 'кг',
    'scout.playerSearch.sm': 'см',

    'scout.playerSearch.inch': 'дюйм',
    'scout.playerSearch.lbs': 'фунт',

    'scout.playerSearch.leaguesLabel': 'Лиги',
    'scout.playerSearch.leagueLabel': '${values.nameRu ? values.nameRu : "" }',

    'scout.playerSearch.seasonsLabel': 'Сезоны',
    'scout.playerSearch.seasonLabel': '${values.nameRu ? values.nameRu : "" }',

    'scout.playerSearch.teamsLabel': 'Команды',
    'scout.playerSearch.teamLabel': '${values.acronymRu ? values.acronymRu : "" }',

    'scout.playerSearch.datesLabel': 'Даты',

    'scout.playerSearch.timeLabel': 'Среднее время на льду',
    'scout.playerSearch.forwards': 'Нападение',
    'scout.playerSearch.forwardsLW': 'ЛК',
    'scout.playerSearch.forwardsC': 'Ц',
    'scout.playerSearch.forwardsRW': 'ПК',
    'scout.playerSearch.forwardsLWC': 'ЛК + Ц',
    'scout.playerSearch.forwardsRWC': 'ПК + Ц',
    'scout.playerSearch.forwardsRWLW': 'ПК + ЛК',
    'scout.playerSearch.forwardsLeft': 'Левое крыло',
    'scout.playerSearch.forwardsCenter': 'Центровой',
    'scout.playerSearch.forwardsRight': 'Правое крыло',
    'scout.playerSearch.forwardsLeftRight': 'Левое & правое крыло',
    'scout.playerSearch.forwardsCenterRight': 'Центральное & правое крыло',
    'scout.playerSearch.forwardsCenterLeft': 'Центральное & левое крыло',
    'scout.playerSearch.forwardsCenterLeftRight': 'Универсал',
    'scout.playerSearch.forwardsUnset': 'Нет информации',
    'scout.playerSearch.defenseman': 'Защита',
    'scout.playerSearch.goalie': 'Вратари',
    'scout.playerSearch.leftSide': 'Левая',
    'scout.playerSearch.rightSide': 'Правая',
    'scout.playerSearch.smLabel': 'см',
    'scout.playerSearch.lbsLabel': 'фунт',
    'scout.playerSearch.kgLabel': 'кг',
    'scout.playerSearch.inchLabel': 'дюйм',
    'scout.playerSearch.minLabel': 'мин',
    'scout.playerSearch.maxHeightError': 'Максимальный рост не может быть меньше минимального роста.',
    'scout.playerSearch.maxWeightError': 'Максимальный вес не может быть меньше минимального веса.',
    'scout.playerSearch.maxAvgTimeError':
        'Максимальное среднее время на льду не может быть меньше минимального среднего времени на льду.',
    'scout.playerSearch.noActiveRoleError': 'Пожалуйста, выберете хотя бы один тип игроков.',
    'scout.playerSearch.noActiveSideError': 'Пожалуйста, выберете хотя бы одно активное значение.',

    'scout.notFoundVideoForYour': 'Для вашего аккаунта нет доступных игр.',

    'scout.reportFilters.team': 'Все команды',
    'scout.reportFilters.league': 'Все лиги',
    'scout.reportFilters.season': 'Все сезоны',
    'scout.reportFilters.scout': 'Все скауты',

    'scout.reportFilters.team.amount': 'команд',
    'scout.reportFilters.league.amount': 'лиг',
    'scout.reportFilters.season.amount': 'сезонов',
    'scout.reportFilters.scout.amount': 'скаутов',

    'interface.calendar.begin': 'С',
    'interface.calendar.end': 'По',
    'interface.filters.allPositions': 'Все позиции',

    'interface.playerSide.left': 'Левая',
    'interface.playerSide.right': 'Правая',
    'interface.playerSide.left+right': 'Левая + Правая',
    'interface.playerSide.right+left': 'Левая + Правая',
    'interface.playerSide.none': 'Ни одна из',

    'unavailablePage.title': `Мы скоро вернемся.`,
    'unavailablePage.text': `Страница, к которой вы пытаетесь получить доступ, в настоящее время недоступна. Пожалуйста, попробуйте еще раз в ближайшее время.`,

    'translation.useRussian': 'Пожалуйста, используйте кирилические символы в этом поле ввода.',

    playerProfile:
        '${values && values.firstNameRu && values.firstNameRu.length > 0 && values.firstNameRu[0] ? values.firstNameRu[0] : values && values.firstNameEn && values.firstNameEn.length > 0 && values.firstNameEn[0] ? values.firstNameEn[0] : ""}. ${values && values.lastNameRu && values.lastNameRu.length>0 ? values.lastNameRu : values && values.lastNameEn ? values.lastNameEn : ""}',
    nameOfTeam:
        '${values && values.nameRu && values.nameRu.length > 0 ? values.nameRu : values && values.nameEn ? values.nameEn : ""}',

    createNewEvent: 'Создать новое событие',

    /*Admin pages*/

    'adminPages.games.forClients': 'for clients',
    'clipService.failure': 'Ошибка'
};

export default russianDictionary;
