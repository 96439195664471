/* eslint-disable eqeqeq */
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { gameSchema, seasonSchema } from '../../utils/schemas';
import { createSkipMarkedArgsSelector, markSelector } from '../../utils/selectors';
import { fromStrengthsMask, swapStrengthsMask } from '../../utils/strengths';
import union from 'lodash/union';
import { transformTime } from '../../utils/helpers/date-utils';
import { teamsPredicateSelector } from '../../selectors/predicates/team-predicates';
import { isSingleTeamView } from '../../selectors/user/user-features-selector';
import { getUserProfileTeamId } from '../../selectors/user/user-profiles-selector';
import { teamsFromGamesSelector } from '../../selectors/teams/teams-selector';

const EMPTY_SEASON = { id: null, nameRu: 'Без сезона', nameEn: 'No season' };

export const seasonItemsSelector = createSkipMarkedArgsSelector(
    state => state.statsFilterSeason.availableSeasonIds,
    markSelector(state => state.entities),
    state => state.statsFilter.fetchedGameIds,
    state => state.statsFilter.ignoredFetchedGameIds,
    (seasonIds, entities, gameIds, ignoredGameIds = []) => {
        const seasons = denormalize(seasonIds, [seasonSchema], entities);
        const games = denormalize([...gameIds, ...ignoredGameIds], [gameSchema], entities);
        if (games.some(game => !game.seasonId)) seasons.unshift(EMPTY_SEASON);
        return seasons;
    }
);

export const seasonsSelector = createSkipMarkedArgsSelector(
    seasonItemsSelector,
    state => state.statsFilterSeason.seasonIds,
    markSelector(state => state.entities),
    state => state.statsFilter.gameIds,
    (seasons, seasonIds, entities, gameIds) => {
        const games = denormalize(gameIds, [gameSchema], entities);
        const gamesSeasonIds = games.map(game => game.seasonId);
        if (seasonIds) {
            return seasons.filter(season => seasonIds.includes(season.id));
        } else {
            return seasons.filter(season => gamesSeasonIds.includes(season.id));
        }
    }
);

const dateComparator = (a, b) => 2 * (b.date > a.date) - 1;

const gameItemsFilteredBySeason = createSkipMarkedArgsSelector(
    state => state.statsFilter.fetchedGameIds,
    seasonsSelector,
    markSelector(state => state.entities),
    (gameIds, seasons, entities) =>
        denormalize(gameIds, [gameSchema], entities)
            .filter(game => seasons.some(season => game.seasonId === season.id))
            .sort(dateComparator)
);

export const teamItems = createSkipMarkedArgsSelector(gameItemsFilteredBySeason, games => {
    const teams = [].concat(...games.map(game => [game.homeTeam, game.awayTeam]));
    return [...new Set(teams)];
});

const teamsWithoutDefault = createSkipMarkedArgsSelector(
    teamItems,
    state => state.statsFilterSeason.teamIds,
    (teamItems, teamIds) => {
        return teamIds ? teamItems.filter(team => teamIds.includes(team.id)) : null;
    }
);

export const teams = state => {
    const teams = teamsWithoutDefault(state);
    if (teams !== null) {
        return teams;
    } else {
        return teamsFromGamesSelector(state);
    }
};

const gameItemsFilteredBySeasonAndTeam = createSkipMarkedArgsSelector(
    state => state.statsFilter.fetchedGameIds,
    state => state.statsFilter.ignoredFetchedGameIds,
    seasonsSelector,
    teams,
    markSelector(state => state.entities),
    (gameIds, ignoredGameIds, seasons, teams, entities) =>
        [
            ...denormalize(gameIds, [gameSchema], entities),
            ...(denormalize(ignoredGameIds, [gameSchema], entities) || []).map(d => ({ ...d, isIgnored: true }))
        ]
            .filter(
                game =>
                    seasons.some(season => game.seasonId === season.id) &&
                    teams.some(team => team.id === game.homeTeam.id || team.id === game.awayTeam.id)
            )
            .sort(dateComparator)
);

export const gameItemsSelector = state =>
    isSingleTeamView(state) ? gameItemsFilteredBySeason(state) : gameItemsFilteredBySeasonAndTeam(state);

export const gamesSelector = createSkipMarkedArgsSelector(
    gameItemsSelector,
    state => state.statsFilter.gameIds,
    state => state.statsFilter.ignoredGameIds,
    (games, gameIds, ignoredGameIds) =>
        (ignoredGameIds && games?.filter(game => ignoredGameIds?.includes(game.id) || gameIds.includes(game.id))) || []
);

export const periodItemsSelector = createSelector(
    gamesSelector,
    games => {
        const periods = games.reduce((periods, game) => Math.max(periods, game.periods), 1);
        return new Array(periods).fill().map((_, index) => index + 1);
    }
);

export const periodsSelector = state => {
    const periods = state.statsFilter.periods;
    const periodItems = periodItemsSelector(state);
    return periods && periods.length > 0 ? periods : periodItems;
};

export const strengthItemsSelector = createSelector(
    gamesSelector,
    getUserProfileTeamId,
    (games, userTeamId) => {
        const strengthsMask = games.reduce((strengthsMask, game) => {
            const gameStrengthsMask =
                game.homeTeam.id === userTeamId ? game.strengthsMask : swapStrengthsMask(game.strengthsMask);

            return strengthsMask | gameStrengthsMask;
        }, 0);
        return fromStrengthsMask(strengthsMask);
    }
);

function strengthFilter(s, { even, powerPlay, penaltyKill }) {
    return (
        (even && s.team === s.opponent) || (powerPlay && s.team > s.opponent) || (penaltyKill && s.team < s.opponent)
    );
}

export const strengthsSelector = state => {
    const strengthTypes = state.statsFilter.strengthTypes;
    const strengthItems = strengthItemsSelector(state);
    const strengths = strengthTypes
        ? union(strengthItems.filter(s => strengthFilter(s, strengthTypes)), state.statsFilter.strengths)
        : state.statsFilter.strengths;
    return strengths && strengths.length > 0 ? strengths : strengthItems;
};

export const teamStatusTypes = {
    team: 'TEAM',
    opponent: 'OPPONENT',
    both: 'BOTH'
};

export const teamStatusItems = Object.values(teamStatusTypes);

export const teamStatusSelector = createSelector(
    state => state.statsFilter.teamStatus,
    teamStatus => {
        return [teamStatus || teamStatusTypes.team];
    }
);

const defaultPlayerPositions = ['D', 'F'];

export const positionStatusSelector = createSelector(
    state => state.statsFilter.positionStatus,
    positionStatus => positionStatus || defaultPlayerPositions
);

const defaultExtendedPlayerPositions = ['D', 'F', 'G'];

export const extendedPositionStatusSelector = createSelector(
    state => state.statsFilter.positionStatus,
    positionStatus => positionStatus || defaultExtendedPlayerPositions
);

export const successStatusSelector = createSelector(
    state => state.statsFilter.successStatus,
    successStatus => successStatus || [true, false]
);

export const entryTypeStatusSelector = createSelector(
    state => state.statsFilter.entryTypeStatus,
    entryType => entryType || ['pass', 'dump', 'carry']
);

export const possessionZonesSelector = createSelector(
    state => state.statsFilter.possessionZones,
    zones => {
        return !!zones ? zones : ['offensive', 'defensive', 'neutral'];
    }
);

const currentGamesSelector = createSkipMarkedArgsSelector(
    state => state.statsFilter.gameIds,
    markSelector(state => state.entities),
    (gameIds, entities) => denormalize(gameIds, [gameSchema], entities)
);

function getTimePresentation(time, period, periods) {
    const fullTime = Object.entries(periods)
        .filter(([p]) => p < period)
        .reduce((acc, [p, d]) => acc + d, time);
    return transformTime(fullTime);
}

const stItems = (gamesSts, games, periods, teamPredicate) => {
    const gameStProcessing = !gamesSts || gamesSts.processing || !gamesSts.data;
    const periodTimelineProcessing = !periods || periods.processing || !periods.data;

    if (gameStProcessing || periodTimelineProcessing) return { processing: true, data: [] };

    return {
        processing: false,
        data: gamesSts.data
            .filter(game => teamPredicate(game.teamId))
            .map(st => {
                const game = games.find(game => game.id === st.gameId);
                const startTimePresentation = getTimePresentation(st.startTime, st.period, periods.data[st.gameId]);

                return { ...st, game, startTimePresentation };
            })
    };
};

export const ppItemsSelector = createSelector(
    state => state.widgetsStats.gamesPps,
    currentGamesSelector,
    state => state.widgetsStats.periods,
    teamsPredicateSelector,
    stItems
);

export const pkItemsSelector = createSelector(
    state => state.widgetsStats.gamesPks,
    currentGamesSelector,
    state => state.widgetsStats.periods,
    teamsPredicateSelector,
    stItems
);
/**
 * selector return null, if no items for widget stat executor
 */

const getSTRaw = (allSts, gamesSts) => {
    if (gamesSts === null) return null;
    const filteredGamesSts = allSts.data.filter(
        st => !!gamesSts.find(selectedSt => selectedSt.gameId === st.gameId && selectedSt.index === st.index)
    );
    return filteredGamesSts.length === 0 ? null : filteredGamesSts;
};

export const ppRawSelector = createSelector(
    ppItemsSelector,
    state => state.statsFilter.gamesSts,
    getSTRaw
);

export const pkRawSelector = createSelector(
    pkItemsSelector,
    state => state.statsFilter.gamesSts,
    getSTRaw
);

export const ppSelector = state => ppRawSelector(state) || ppItemsSelector(state).data;

export const pkSelector = state => pkRawSelector(state) || pkItemsSelector(state).data;

export const ppStrengthItemsSelector = createSelector(
    strengthItemsSelector,
    teamStatusSelector,
    (strengthItems, [teamStatus]) => {
        switch (teamStatus) {
            case teamStatusTypes.team:
                return strengthItems.filter(strength => strength.team > strength.opponent);
            case teamStatusTypes.opponent:
                return strengthItems.filter(strength => strength.team < strength.opponent);
            default:
                return strengthItems.filter(strength => strength.team !== strength.opponent);
        }
    }
);

export const pkStrengthItemsSelector = createSelector(
    strengthItemsSelector,
    teamStatusSelector,
    (strengthItems, [teamStatus]) => {
        switch (teamStatus) {
            case teamStatusTypes.team:
                return strengthItems.filter(strength => strength.team < strength.opponent);
            case teamStatusTypes.opponent:
                return strengthItems.filter(strength => strength.team > strength.opponent);
            default:
                return strengthItems.filter(strength => strength.team !== strength.opponent);
        }
    }
);

export const ppStrengthsSelector = state => {
    const strengths = state.statsFilter.strengths;
    const strengthItems = ppStrengthItemsSelector(state);
    return strengths && strengths.length > 0 ? strengths : strengthItems;
};

export const pkStrengthsSelector = state => {
    const strengths = state.statsFilter.strengths;
    const strengthItems = pkStrengthItemsSelector(state);
    return strengths && strengths.length > 0 ? strengths : strengthItems;
};
