import { normalize } from 'normalizr';
import { HTTP_REQUEST_API } from '../sagas/request-api';
import request from '../../utils/api/common-requests/common-requests';
import { makePrefixAdder } from './type-utils';
import { AUTH_TOKEN_NAME, getAuthKey } from '../../utils/api/auth-utils';
const requestPrefix = makePrefixAdder('@@request');

function formatConfig(config = {}, cancelToken) {
    const { headers = {} } = config;
    headers[AUTH_TOKEN_NAME] = getAuthKey();
    return { ...config, headers, cancelToken };
}

function formatEndpoint(endpoint, state) {
    if (typeof endpoint === 'string') return endpoint;
    if (typeof endpoint !== 'function') throw new Error('Unexpected type for endpoint');
    endpoint = endpoint(state);
    if (typeof endpoint !== 'string') throw new Error('Wrong return type of endpoint function');
    return endpoint;
}

function makeRequestApiBody(props, requester) {
    const { requestBundle, cancelToken, responseTransformer, errorHandler, options } = props;
    return {
        [HTTP_REQUEST_API]: {
            types: [requestBundle.REQUEST, requestBundle.SUCCESS, requestBundle.FAILURE, requestBundle.CANCELLED],
            cancelToken,
            responseTransformer,
            errorHandler,
            requester
        },
        type: requestPrefix(requestBundle.REQUEST),
        ...options
    };
}

const requestBuilderProps = new WeakMap();

class RequestBuilder {
    constructor(requestBundle) {
        requestBuilderProps.set(this, {
            requestBundle
        });
    }

    withOptions(options) {
        const oldProps = requestBuilderProps.get(this);
        requestBuilderProps.set(this, { ...oldProps, options });
        return this;
    }

    setCancelToken(cancelToken) {
        const oldProps = requestBuilderProps.get(this);
        requestBuilderProps.set(this, { ...oldProps, cancelToken });
        return this;
    }

    handleError(errorHandler) {
        const oldProps = requestBuilderProps.get(this);
        requestBuilderProps.set(this, { ...oldProps, errorHandler });
        return this;
    }

    addSchema(schema, isEntity = true) {
        const oldProps = requestBuilderProps.get(this);
        const { options } = oldProps;
        requestBuilderProps.set(this, {
            ...oldProps,
            options: { ...options, isEntity },
            responseTransformer: response => normalize(response.data, schema)
        });
        return this;
    }

    get(endpoint, config) {
        const requester = (state, cancelToken) =>
            request.get(formatEndpoint(endpoint, state), formatConfig(config, cancelToken));

        return makeRequestApiBody(requestBuilderProps.get(this), requester);
    }

    post(endpoint, data, config) {
        const requester = (state, cancelToken) =>
            request.post(formatEndpoint(endpoint, state), data, formatConfig(config, cancelToken));

        return makeRequestApiBody(requestBuilderProps.get(this), requester);
    }

    put(endpoint, data, config) {
        const requester = (state, cancelToken) =>
            request.put(formatEndpoint(endpoint, state), data, formatConfig(config, cancelToken));

        return makeRequestApiBody(requestBuilderProps.get(this), requester);
    }

    patch(endpoint, data, config) {
        const requester = (state, cancelToken) =>
            request.patch(formatEndpoint(endpoint, state), data, formatConfig(config, cancelToken));

        return makeRequestApiBody(requestBuilderProps.get(this), requester);
    }

    del(endpoint, config) {
        const requester = (state, cancelToken) =>
            request.delete(formatEndpoint(endpoint, state), formatConfig(config, cancelToken));

        return makeRequestApiBody(requestBuilderProps.get(this), requester);
    }

    fake(fakeData) {
        const requester = () =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve({
                        data: fakeData
                    });
                }, 500);
            });

        return makeRequestApiBody(requestBuilderProps.get(this), requester);
    }
}

const requestBundler = requestBundle => {
    return new RequestBuilder(requestBundle);
};
export default requestBundler;
