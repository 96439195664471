import { FAVORITE_ZONE_CHANGE } from '../../favorite-types';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { createSelector } from 'reselect';
import entriesModule from './entries';

const selector = state => state.widgetsStats.entries || processingState;

const { CARRIES_IN, DUMPS_IN, CARRIES_OUT, DUMPS_OUT, PASS_IN, PASS_OUT } = entriesModule.types;

const UNFILTERED_CONTROLLED_IN = 'UNFILTERED_CONTROLLED_IN';
const UNFILTERED_UNCONTROLLED_IN = 'UNFILTERED_UNCONTROLLED_IN';
const UNFILTERED_CONTROLLED_OUT = 'UNFILTERED_CONTROLLED_OUT';
const UNFILTERED_UNCONTROLLED_OUT = 'UNFILTERED_UNCONTROLLED_OUT';

const types = {
    UNFILTERED_CONTROLLED_IN,
    UNFILTERED_UNCONTROLLED_IN,
    UNFILTERED_CONTROLLED_OUT,
    UNFILTERED_UNCONTROLLED_OUT
};

const favoriteType = FAVORITE_ZONE_CHANGE;

export const createControlledInSelector = () =>
    createSelector(
        entries => entries.data,
        data => ({ data: data.filter(e => e.type === CARRIES_IN || e.type === PASS_IN) })
    );

export const createUncontrolledInSelector = () =>
    createSelector(
        entries => entries.data,
        data => ({ data: data.filter(e => e.type === DUMPS_IN) })
    );

export const createControlledOutSelector = () =>
    createSelector(
        entries => entries.data,
        data => ({ data: data.filter(e => e.type === CARRIES_OUT || e.type === PASS_OUT) })
    );

export const createUncontrolledOutSelector = () =>
    createSelector(
        entries => entries.data,
        data => ({ data: data.filter(e => e.type === DUMPS_OUT) })
    );

const typeToSelector = {
    [UNFILTERED_CONTROLLED_IN]: createEpisodeIdWrapper(UNFILTERED_CONTROLLED_IN, createControlledInSelector()),
    [UNFILTERED_UNCONTROLLED_IN]: createEpisodeIdWrapper(UNFILTERED_UNCONTROLLED_IN, createUncontrolledInSelector()),
    [UNFILTERED_CONTROLLED_OUT]: createEpisodeIdWrapper(UNFILTERED_CONTROLLED_OUT, createControlledOutSelector()),
    [UNFILTERED_UNCONTROLLED_OUT]: createEpisodeIdWrapper(UNFILTERED_UNCONTROLLED_OUT, createUncontrolledOutSelector())
};

function mapper(entries, type) {
    if (entries.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Entries');
    return typeToSelector[type](entries);
}

const getTime = ({ endTime, time }) => ({
    startTime: time,
    endTime,
    time,
    hasPadding: true,
    leftPadding: 3,
    rightPadding: 3
});

const typeToMediaTimeWrapper = Object.keys(typeToSelector)
    .map(key => ({ key, wrapper: createMediaTimeWrapper(getTime) }))
    .reduce((a, d) => {
        a[d.key] = d.wrapper;
        return a;
    }, {});

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const unfilteredEntries = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};

export default unfilteredEntries;
