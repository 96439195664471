import styled from 'styled-components/macro';

const EllipsisText = styled.div`
    display: inline-block;

    width: ${props => props.width || '100%'};
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;

    vertical-align: bottom;
`;

export default EllipsisText;
