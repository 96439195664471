/* ============ translation-utils ============ */
const TRANSLATABLE_KEY = Symbol('Formatted Message');

export function markAsTranslatable(id, props) {
    return { [TRANSLATABLE_KEY]: { id, ...props } };
}

export function readMarkedProps(marked) {
    return marked[TRANSLATABLE_KEY];
}

export function isTranslatable(marked) {
    return marked.hasOwnProperty(TRANSLATABLE_KEY);
}
