import React, { Component } from 'react';
import Error from '../../../components/error/error';

class ErrorBoundary extends Component {
    state = {
        failed: false
    };

    componentDidCatch(error, errorInfo) {
        this.setState({ failed: true, error: error ?? errorInfo });
    }

    render() {
        const { children, noReport } = this.props;
        const { error } = this.state;

        return this.state.failed ? <Error showRefreshButton noReport={noReport} error={error} /> : children;
    }
}

export default ErrorBoundary;
