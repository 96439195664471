/**
 * @return {Number} user profile's teamId or `null` if not defined
 */
export const getUserProfileTeamId = state => {
    return (state.user && state.user.profile && state.user.profile.teamId) || null;
};
/**
 * @return {Number} user profile's teamId or `null` if not defined
 */

export const getUserProfilePictureHref = state => {
    return (state.user && state.user.profile && state.user.profile.pictureHref) || null;
};
