import React from 'react';
import styled from 'styled-components/macro';
import { IconWrapper } from '../../../components/svg-icons/playlist-icons';

export const InfoWarningWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
`;

export const FullPageMessageWrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    background: rgba(170, 170, 170, 0.5);
`;

export const MessageWrapper = styled('div')`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 50vw;
    height: 20rem;

    border-radius: 5px;

    background: white;
`;

export const MessageText = styled('span')`
    max-width: 90%;
    max-height: 4rem;

    color: #000;
    white-space: normal;
`;

export const MessageTextH1 = styled(MessageText)`
    color: var(--uiPrimaryColor);
    font-weight: bold;
    font-size: 18px;
`;

export const MessageTextH2 = styled(MessageText)`
    color: var(--uiPrimaryColor);
`;

export const ImgWrap = styled.div`
    position: absolute;
    top: 36px;
    left: 36px;

    color: var(--uiPrimaryColor);
`;

export const MessageApproveButton = styled('button')`
    padding: 0.25rem;

    border: none;
    border-radius: 5px;
    padding-inline: 32px;

    color: #ffffff;
    font-weight: 300;

    background: var(--uiPrimaryColor);
`;

export const WarningIcon = ({ onClick, showed }) => {
    return (
        <IconWrapper style={{ height: '30px', width: '30px', marginRight: '20px' }} onClick={onClick}>
            {showed ? (
                <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        id="symbol"
                        d="M11.1154 12.7068H8.89495L8.58039 3.37183H11.4299L11.1154 12.7068ZM10.0051 14.1685C10.4554 14.1685 10.8162 14.3011 11.0876 14.5663C11.3652 14.8315 11.5039 15.1708 11.5039 15.584C11.5039 15.9911 11.3652 16.3272 11.0876 16.5924C10.8162 16.8576 10.4554 16.9902 10.0051 16.9902C9.56107 16.9902 9.20025 16.8576 8.9227 16.5924C8.65132 16.3272 8.51562 15.9911 8.51562 15.584C8.51562 15.177 8.65132 14.8408 8.9227 14.5756C9.20025 14.3042 9.56107 14.1685 10.0051 14.1685Z"
                        fill="#fff"
                    />
                    <path
                        id="circle"
                        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                        stroke="#fff"
                        strokeWidth="2"
                    />
                </svg>
            ) : (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        id="symbol"
                        d="M11.1154 12.7068H8.89495L8.58039 3.37183H11.4299L11.1154 12.7068ZM10.0051 14.1685C10.4554 14.1685 10.8162 14.3011 11.0876 14.5663C11.3652 14.8315 11.5039 15.1708 11.5039 15.584C11.5039 15.9911 11.3652 16.3272 11.0876 16.5924C10.8162 16.8576 10.4554 16.9902 10.0051 16.9902C9.56107 16.9902 9.20025 16.8576 8.9227 16.5924C8.65132 16.3272 8.51562 15.9911 8.51562 15.584C8.51562 15.177 8.65132 14.8408 8.9227 14.5756C9.20025 14.3042 9.56107 14.1685 10.0051 14.1685Z"
                        fill="#fff"
                    />
                    <path
                        id="circle"
                        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                        stroke="#fff"
                        strokeWidth="2"
                    />
                    <animate
                        xlinkHref="#circle"
                        attributeType="auto"
                        attributeName="stroke"
                        dur="0.5s"
                        to="#4A90E2"
                        repeatCount="indefinite"
                    />
                    <animate
                        xlinkHref="#symbol"
                        attributeType="auto"
                        attributeName="fill"
                        dur="0.5s"
                        to="#4A90E2"
                        repeatCount="indefinite"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export const ClipServiceInformerWrapper = styled(InfoWarningWrapper)`
    display: ${props => (props.isAvailable ? 'block' : 'none')};
`;

const Svg = styled('svg')``;

export const DownloadClipServiceIcon = ({ onClick, inProgress }) => {
    const iconColor = '#fff';
    const flashColor = '#4A90E2';

    return (
        <IconWrapper style={{ height: '24px', width: '24px' }} onClick={onClick}>
            <Svg viewBox="0 0 508 508" width="16" height="16">
                <path
                    id="arrow"
                    fill={iconColor}
                    d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
			c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
			c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"
                />

                <path
                    id="base"
                    fill={iconColor}
                    d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z"
                />
                {inProgress && (
                    <animate
                        xlinkHref="#arrow"
                        attributeType="auto"
                        attributeName="fill"
                        dur="1s"
                        to={flashColor}
                        repeatCount="indefinite"
                    />
                )}
                {inProgress && (
                    <animate
                        xlinkHref="#base"
                        attributeType="auto"
                        attributeName="fill"
                        dur="1s"
                        to={flashColor}
                        repeatCount="indefinite"
                    />
                )}
            </Svg>
        </IconWrapper>
    );
};
