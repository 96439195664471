import { createSelector } from 'reselect';
import { isSingleTeamView } from '../user/user-features-selector';
import teamStatusTypes from '../teams/team-status-types';
import { teamIdsSelector } from '../teams/teams-selector';
import { statFilterGames } from '../games/games-selector';
import { getUserProfileTeamId } from '../user/user-profiles-selector';

const teamStatusPredicateSelector = createSelector(
    getUserProfileTeamId,
    state => state.statsFilter.teamStatus || teamStatusTypes.team,
    (activeTeamId, teamStatusFilter) => {
        return teamId => {
            const bothTeamsPredicate = teamStatusFilter === teamStatusTypes.both;
            const teamPredicate = teamStatusFilter === teamStatusTypes.team && teamId === activeTeamId;
            const opponentPredicate = teamStatusFilter === teamStatusTypes.opponent && teamId !== activeTeamId;
            return bothTeamsPredicate || teamPredicate || opponentPredicate;
        };
    }
);

const teamFilterPredicateSelector = createSelector(
    teamIdsSelector,
    teamIds => (teamIds.length ? teamId => teamIds.includes(teamId) : () => true)
);

/**
 * selector for predicate which returns true when teamId from selected teams
 * todo: unnecessary argument 'props'
 */
export const teamsPredicateSelector = (state, props) =>
    isSingleTeamView(state, props)
        ? teamStatusPredicateSelector(state, props)
        : teamFilterPredicateSelector(state, props);

const teamStatusOpponentPredicateSelector = createSelector(
    getUserProfileTeamId,
    state => state.statsFilter.teamStatus || teamStatusTypes.team,
    (activeTeamId, teamStatusFilter) => {
        return teamId => {
            const bothTeamsPredicate = teamStatusFilter === teamStatusTypes.both;
            const teamPredicate = teamStatusFilter === teamStatusTypes.team && teamId !== activeTeamId;
            const opponentPredicate = teamStatusFilter === teamStatusTypes.opponent && teamId === activeTeamId;
            return bothTeamsPredicate || teamPredicate || opponentPredicate;
        };
    }
);

const byGameSelector = (_, props) => props && props.byGame;

const filterSelector = (_, props) => props && props.filter;

const teamFilterOpponentPredicateSelector = createSelector(
    byGameSelector,
    filterSelector,
    teamIdsSelector,
    statFilterGames,
    (byGame, filter, teamIds, games) => {
        //todo change filtering mechanism, remove byGame argument
        if (byGame) {
            return (teamId, gameId) => {
                const game = games.find(game => game.id === gameId);
                const { homeTeam, awayTeam } = game;
                if (filter && filter.teamIds) {
                    return filter.teamIds.includes(homeTeam.id) ? homeTeam.id !== teamId : awayTeam.id !== teamId;
                } else return true;
            };
        } else {
            if (!teamIds.length) return () => true;

            const gameIdToOpponentId = {};
            games.forEach(game => {
                const opponentIds = [];
                if (teamIds.includes(game.homeTeam.id)) {
                    opponentIds.push(game.awayTeam.id);
                }
                if (teamIds.includes(game.awayTeam.id)) {
                    opponentIds.push(game.homeTeam.id);
                }
                gameIdToOpponentId[game.id] = opponentIds;
            });

            return (teamId, gameId) => {
                const opponentIds = gameIdToOpponentId[gameId];
                return opponentIds && opponentIds.includes(teamId);
            };
        }
    }
);

/**
 * selector for predicate which returns true when pair (teamId, gameId) from selected teams opponents
 */
export const teamsOpponentPredicateSelector = (state, props) =>
    isSingleTeamView(state)
        ? teamStatusOpponentPredicateSelector(state, props)
        : teamFilterOpponentPredicateSelector(state, props);
