import { createSelector, createStructuredSelector } from 'reselect';
import { denormalize } from 'normalizr';
import groupBy from 'lodash/groupBy';
import requestBuilder from '../../redux/utils/request-builder';
import * as endpoints from '../../utils/api/endpoints';
import * as bundles from './bundles';
import { gamePlayerSchema, gameSchema, leagueSchema, playerProfileSchema, seasonSchema } from '../../utils/schemas';
import * as expressions from '../../utils/helpers/expression-utils';
import { fieldNames } from '../../utils/api/predicate';
import { isSingleTeamView } from '../../selectors/user/user-features-selector';
import { getUserProfileTeamId } from '../../selectors/user/user-profiles-selector';
import teamStatusTypes from '../../selectors/teams/team-status-types';
import {
    getEmptyNetValue,
    getGameIds,
    getSeasonIds,
    getLeagueId,
    getPlayerId,
    getPlayerPosition,
    getStrengthsMask,
    getTeamIds,
    isSingleTeamMode,
    periodPredicateSelector,
    getGameIdsAndLeaguesSelector,
    getLeagueIds
} from '../../selectors/filters/common-filter-selectors';
import { teamIdsSelector } from '../../selectors/teams/teams-selector';
import sortBy from 'lodash/sortBy';

const MAX_PLAYER_COUNT = 5000;

const getPlayerPredicate = playerId => expressions.eq(fieldNames.PLAYER_ID, expressions.number(playerId));

const getPlayerRecoveriesPredicate = playerId =>
    expressions.and(expressions.eq(fieldNames.RECOVERY, expressions.boolean(true)), getPlayerPredicate(playerId));

// todo deprecated, should be deleted soon! Instead just use `unboxSingleValueArray` on gameIds
export const getCurrentGameId = createSelector(
    state => state.statsFilter.gameIds,
    state => state.entities,
    (gameIds, entities) => {
        const games = denormalize(gameIds, [gameSchema], entities);
        const game = [...games].sort((a, b) => (b.date > a.date ? 1 : b.date === a.date ? 0 : -1))[0];
        return game ? game.id : null;
    }
);

const teamStatusSelector = createSelector(
    state => state.statsFilter.teamStatus,
    getUserProfileTeamId,
    (teamStatus, teamId) => {
        switch (teamStatus || teamStatusTypes.team) {
            case teamStatusTypes.team:
                return expressions.eq(fieldNames.TEAM_ID, expressions.number(teamId));
            case teamStatusTypes.opponent:
                return expressions.ne(fieldNames.TEAM_ID, expressions.number(teamId));
            default:
                return undefined;
        }
    }
);

const teamPredicateSelector = createSelector(
    getTeamIds,
    teamIds => expressions.containedIn(fieldNames.TEAM_ID, ...teamIds.map(teamId => expressions.number(teamId)))
);

const teamSelector = state => (isSingleTeamView(state) ? teamStatusSelector(state) : teamPredicateSelector(state));

const composePredicates = (...args) => {
    const predicateValues = args.filter(p => !!p);
    if (predicateValues.length === 0) {
        return undefined;
    } else if (predicateValues.length === 1) {
        const [predicate] = predicateValues;
        return predicate;
    } else {
        return expressions.and(...predicateValues);
    }
};

const composeByOrPredicates = (...args) => {
    const predicateValues = args.filter(p => !!p);
    if (predicateValues.length === 0) {
        return undefined;
    } else if (predicateValues.length === 1) {
        const [predicate] = predicateValues;
        return predicate;
    } else {
        return expressions.or(...predicateValues);
    }
};

const getPredicate = periodPredicateSelector;

function getStatFilterTeamId(state) {
    if (isSingleTeamMode(state) && !isSingleTeamView(state)) {
        return getTeamIds(state)[0];
    }
    return isSingleTeamView(state) ? getUserProfileTeamId(state) : null;
}

const getStandardStatFilter = createStructuredSelector({
    gameIds: getGameIds,
    predicate: getPredicate,
    strengths: getStrengthsMask,
    teamId: getStatFilterTeamId,
    emptyNet: getEmptyNetValue,
    teamIds: teamIdsSelector
});

export const info = {
    requestSelector: state => {
        let endpoint;
        let params;

        if (isSingleTeamView(state)) {
            endpoint = endpoints.dashboardSummaryInfo;
        } else {
            endpoint = endpoints.teamsInfo;
            params = { teamIds: getTeamIds(state).join() };
        }

        return requestBuilder(bundles.info).post(endpoint, getStandardStatFilter(state), { params });
    }
};

export const shots = {
    requestSelector: state =>
        requestBuilder(bundles.shots).post(endpoints.dashboardSummaryShots, getStandardStatFilter(state))
};

export const gamePlayers = {
    requestSelector: state =>
        requestBuilder(bundles.gamePlayers)
            .addSchema([gamePlayerSchema])
            .post(endpoints.extractPlayersInGames, getGameIds(state))
};

export const periods = {
    requestSelector: state =>
        requestBuilder(bundles.periods).get(endpoints.periods, { params: { gameIds: getGameIds(state).join() } })
};

export const seasons = {
    requestSelector: state =>
        requestBuilder(bundles.seasons)
            .addSchema([seasonSchema])
            .get(endpoints.seasons)
};

export const keeperToi = {
    requestSelector: state => requestBuilder(bundles.keeperToi).post(endpoints.keeperToi, getStandardStatFilter(state))
};

export const filteredShots = {
    requestSelector: state =>
        requestBuilder(bundles.filteredShots).post(endpoints.filteredShots, getStandardStatFilter(state))
};

const getProfilesToExtract = state => {
    const gamePlayerEntities = state.entities.gamePlayers;
    const profileIds = state.widgetsStats.gamePlayers.data.map(id => gamePlayerEntities[id].playerId);

    //decrease the same playerId for optimization
    const playerIds = sortBy(profileIds).reduce((acc, cur) => {
        if (acc.length === 0) {
            return [cur];
        }

        const lastElement = acc[acc.length - 1];

        if (lastElement === cur) {
            return acc;
        }

        return [...acc, cur];
    }, []);

    if (playerIds.length > MAX_PLAYER_COUNT) {
        /*eslint-disable*/
        console.error(`To much players at search: more than ${MAX_PLAYER_COUNT}.`);
        /*eslint-enable*/
        return playerIds.slice(0, MAX_PLAYER_COUNT);
    }
    return playerIds;
};

export const playerProfiles = {
    requestSelector: state =>
        requestBuilder(bundles.playerProfiles)
            .addSchema([playerProfileSchema])
            .post(endpoints.playerProfiles, getProfilesToExtract(state)),
    dependencies: ['gamePlayers']
};

export const skatersCompare = {
    requestSelector: state =>
        requestBuilder(bundles.skatersCompare).get(endpoints.gameSkatersCompare(getCurrentGameId(state)))
};

export const possessionTeams = {
    requestSelector: state =>
        requestBuilder(bundles.possessionTeams).post(endpoints.possessionTeams, getStandardStatFilter(state))
};

export const linesXG = {
    requestSelector: state => requestBuilder(bundles.linesXG).post(endpoints.linesXG, getStandardStatFilter(state))
};

export const fullLinesXG = {
    requestSelector: state =>
        requestBuilder(bundles.fullLinesXG).post(endpoints.fullLinesXG, getStandardStatFilter(state))
};

export const skatingPlayersList = {
    requestSelector: state =>
        requestBuilder(bundles.skatingPlayersList).post(endpoints.skatingPlayersList, getStandardStatFilter(state))
};

export const homeTeamLeft = {
    requestSelector: state =>
        requestBuilder(bundles.homeTeamLeft).get(endpoints.homeTeamLeft, { params: { gameIds: getGameIds(state) } })
};

export const entries = {
    requestSelector: state => requestBuilder(bundles.entries).post(endpoints.entries, getStandardStatFilter(state))
};

export const skatingPlayers = {
    requestSelector: state =>
        requestBuilder(bundles.skatingPlayers).post(endpoints.skatingPlayers, getStandardStatFilter(state))
};

//todo deprecated! Should be deleted soon. Use just `shiftsStats` instead!
export const shiftsStatsSingle = {
    requestSelector: state =>
        requestBuilder(bundles.shiftsStatsSingle).post(
            endpoints.shiftsStatsSingle(getCurrentGameId(state)),
            getStandardStatFilter(state)
        )
};

const getIndividualShiftsPredicate = state => {
    const gameIds = getGameIds(state);
    const playerId = getPlayerId(state);
    return {
        gameIds,
        predicate: expressions.eq(fieldNames.PLAYER_ID, expressions.number(playerId))
    };
};

export const individualShiftStats = {
    requestSelector: state =>
        requestBuilder(bundles.individualShiftStats).post(endpoints.shiftStats, getIndividualShiftsPredicate(state))
};

export const storage = {
    requestSelector: state => requestBuilder(bundles.storage).get(endpoints.storage(getGameIds(state)))
};

//todo deprecated! Should be deleted soon. Use just `events` instead!
export const eventsSingle = {
    requestSelector: state => requestBuilder(bundles.eventsSingle).get(endpoints.eventsSingle(getCurrentGameId(state)))
};

export const events = {
    requestSelector: state => requestBuilder(bundles.events).post(endpoints.events, getStandardStatFilter(state))
};

//todo deprecated! Should be deleted soon. Use just `skatingPlayers` instead!
export const skatingPlayersSingle = {
    requestSelector: state =>
        requestBuilder(bundles.skatingPlayersSingle).post(endpoints.skatingPlayers, getStandardStatFilter(state))
};

const getZonesParams = state => {
    const zones = state.statsFilter.possessionZones;
    return zones ? { zones: zones.map(zone => zone.toUpperCase()).join() } : {};
};

export const possessionPlayers = {
    requestSelector: state =>
        requestBuilder(bundles.possessionPlayers).post(endpoints.possessionPlayers, getStandardStatFilter(state), {
            params: getZonesParams(state)
        })
};

//todo deprecated! Should be deleted soon. Use just `possessionPlayers` instead!
export const possessionPlayersSingle = {
    requestSelector: state =>
        requestBuilder(bundles.possessionPlayersSingle).post(endpoints.possessionPlayers, getStandardStatFilter(state))
};

export const passes = {
    requestSelector: state =>
        requestBuilder(bundles.passes).post(endpoints.statisticPassing, getStandardStatFilter(state))
};

//todo deprecated! Should be deleted soon. Use just `passes` instead!
export const passesSingle = {
    requestSelector: state =>
        requestBuilder(bundles.passesSingle).post(endpoints.statisticPassing, getStandardStatFilter(state))
};

export const skatersShots = {
    requestSelector: state =>
        requestBuilder(bundles.skatersShots).post(endpoints.dashboardSkatersShots, getStandardStatFilter(state))
};

//todo deprecated! Should be deleted soon. Use just `skatersShots` instead!
export const skatersShotsSingle = {
    requestSelector: state =>
        requestBuilder(bundles.skatersShotsSingle).post(endpoints.dashboardSkatersShots, getStandardStatFilter(state))
};

export const gameStrengthChangeTimes = {
    requestSelector: state =>
        requestBuilder(bundles.gameStrengthChangeTimes).get(endpoints.gameStrengthChangeTimes(getCurrentGameId(state)))
};

export const gamesPps = {
    requestSelector: state =>
        requestBuilder(bundles.gamesPps).get(endpoints.gamesPps, { params: { gameIds: getGameIds(state).join() } })
};

export const gamesPks = {
    requestSelector: state =>
        requestBuilder(bundles.gamesPks).get(endpoints.gamesPks, { params: { gameIds: getGameIds(state).join() } })
};

const wrapStIndexes = (gameId, sts) =>
    expressions.and(
        expressions.eq(fieldNames.GAME_ID, expressions.number(gameId)),
        expressions.containedIn(fieldNames.STATS_INDEX, ...sts.map(([gameId, index]) => expressions.number(index)))
    );

const stPredicateSelector = createSelector(
    state => state.statsFilter.gamesSts,
    gamesSts => {
        if (!gamesSts) return undefined;
        const transformedGameSts = gamesSts.map(st => st.split(','));
        const gameIdToGameSt = groupBy(transformedGameSts, ([gameId]) => gameId);

        return composeByOrPredicates(...Object.entries(gameIdToGameSt).map(e => wrapStIndexes(...e)));
    }
);

export const ppStats = {
    requestSelector: state =>
        requestBuilder(bundles.ppStats).post(endpoints.ppStats, {
            teamId: getStatFilterTeamId(state),
            gameIds: getGameIds(state),
            predicate: composePredicates(teamSelector(state), getPredicate(state), stPredicateSelector(state)),
            strengths: getStrengthsMask(state)
        })
};

export const pkStats = {
    requestSelector: state =>
        requestBuilder(bundles.pkStats).post(endpoints.pkStats, {
            teamId: getStatFilterTeamId(state),
            gameIds: getGameIds(state),
            predicate: composePredicates(teamSelector(state), getPredicate(state), stPredicateSelector(state)),
            strengths: getStrengthsMask(state)
        })
};

export const gameScoreStats = {
    requestSelector: state =>
        requestBuilder(bundles.gameScoreStats).post(endpoints.gameScore, getStandardStatFilter(state))
};

export const avgRestTime = {
    requestSelector: state =>
        requestBuilder(bundles.avgRestTime).post(endpoints.avgRestTime, getStandardStatFilter(state))
};

export const playersStats = {
    requestSelector: state =>
        requestBuilder(bundles.playerStats).get(endpoints.playerGamesStats(getPlayerId(state)), {
            params: { gameIds: getGameIds(state).join() }
        })
};

export const playerFaceoffs = {
    requestSelector: state =>
        requestBuilder(bundles.playerFaceoffs).get(endpoints.faceoffsZones(getPlayerId(state)), {
            params: { gameIds: getGameIds(state).join() }
        })
};

export const playerRecoveries = {
    requestSelector: state =>
        requestBuilder(bundles.playerRecoveries).post(endpoints.events, {
            predicate: getPlayerRecoveriesPredicate(getPlayerId(state)),
            gameIds: getGameIds(state)
        })
};

export const playerShots = {
    requestSelector: state =>
        requestBuilder(bundles.playerShots).post(endpoints.filteredShots, {
            gameIds: getGameIds(state),
            predicate: getPlayerPredicate(getPlayerId(state))
        })
};

export const playerStatsQuantiles = {
    requestSelector: state =>
        requestBuilder(bundles.playerStatsQuantiles).get(endpoints.playerQuantiles(getPlayerId(state)), {
            params: {
                gameIds: getGameIds(state).join(),
                leagueId: getLeagueId(state),
                playerPosition: getPlayerPosition(state)
            }
        })
};

export const playerStatsQuantilesExtended = {
    requestSelector: (state, props) =>
        requestBuilder(bundles.playerStatsQuantilesExtended).post(endpoints.playerQuantilesMultipleLeagues, {
            playerPosition: getPlayerPosition(state),
            playerId: getPlayerId(state),
            games: getGameIdsAndLeaguesSelector(state)
        })
};

export const leagueAvgQuantiles = {
    requestSelector: state =>
        requestBuilder(bundles.leagueAvgQuantiles).get(endpoints.leagueAvgQuantiles, {
            params: {
                leagueId: getLeagueId(state),
                playerPosition: getPlayerPosition(state)
            }
        })
};

export const leagueAvgQuantilesExtended = {
    requestSelector: state =>
        requestBuilder(bundles.leagueAvgQuantilesExtended).post(endpoints.leagueAvgQuantiles, {
            leagueIds: getLeagueIds(state),
            playerPosition: getPlayerPosition(state)
        })
};

export const leagues = {
    requestSelector: state =>
        requestBuilder(bundles.leagues)
            .addSchema([leagueSchema])
            .get(endpoints.leagues)
};

export const scoutPlayerStats = {
    requestSelector: state =>
        requestBuilder(bundles.scoutPlayerStats).post(endpoints.scoutPlayerStats, {
            seasonIds: getSeasonIds(state),
            playerIds: [getPlayerId(state)]
        })
};

export const scoutLeagueStats = {
    requestSelector: state =>
        requestBuilder(bundles.scoutLeagueStats).post(endpoints.scoutLeagueStats, {
            leagueIds: [getLeagueId(state)],
            playerPositions: [getPlayerPosition(state)]
        })
};

export const videoTimecodes = {
    requestSelector: state =>
        requestBuilder(bundles.videoTimecodes).post(
            endpoints.videoTimecodes,
            {},
            {
                params: { gameIds: getGameIds(state).join() }
            }
        )
};
