import React, { Component } from 'react';
import ReactTestUtils from 'react-dom/test-utils';
import ReactDOM from 'react-dom';

const devRootHOC = RootComponent => {
    return class DevModeRootComponent extends Component {
        render() {
            return <RootComponent {...this.props} />;
        }
    };
};

/**
 * store  - redux store
 */
export default class DevTestPlugin {
    constructor(store) {
        this.tools = {
            store,
            ReactDOM,
            React,
            ReactTestUtils,
            findComponentByDOMNode: this.findComponentByDOMNode
        };
        Object.defineProperty(this.tools, 'root', {
            get: () => this.root.current
        });
        this.root = React.createRef();
    }

    /**
     *finds corresponding React Node In Virtual DOM for NodeDOM element
     */
    findComponentByDOMNode = element => {
        return ReactTestUtils.findAllInRenderedTree(this.root.current, c => {
            const e = c instanceof React.Component && ReactDOM.findDOMNode(c);
            return e && e.contains(element);
        }).pop();
    };

    /**
     * creates wrapper for root element for Virtual DOM access
     */
    createReactDOMDevRoot = RootComponent => props => {
        const DevRootComponent = devRootHOC(RootComponent);
        return <DevRootComponent {...props} ref={this.root} />;
    };
}
