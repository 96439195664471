import React, { useState, useEffect, useCallback } from 'react';
import PageContainer from 'containers/page-container';
import styled from 'styled-components/macro';
import img1 from './img1.png';
import img2 from './img2.png';
import { contentList } from './content';
import Button from '../../components/button/button';
import { apiBuilder } from '../../utils/api/common-requests/common-requests';
import * as endpoints from '../../utils/api/endpoints';
import { atou, decodeSearchUrl } from '../../utils/api/path-utils';
import { connect } from 'react-redux';
import { notificationTypes, notify } from '../../redux/modules/notifications';
import { isProduction } from '../../utils/helpers/envirioment-utils';

const STRIPE_URL = 'https://js.stripe.com/v3/';
const STRIPE_KEY = isProduction() ? 'pk_live_I1zyMmqyvvOVGOrqYV5VQGdU' : 'pk_test_zTvqZP87uRZ2o0zNqBrlJBVc';

const Layout = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.7fr 1fr;
    grid-template-rows: 1fr 1fr 40px 50px 40px;
    width: 80%;
    max-width: 1050px;
    min-height: calc(100vh - 2 * 48px - 2 * 20px);
    margin: 20px auto;
    padding: 20px 20px 5px;

    border-radius: 10px;

    background: white;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
`;

const Text = styled.div`
    padding: 15px;

    font-weight: bold;
    font-size: 0.9em;
`;

const StyledImg = styled.img`
    width: 100%;
    padding: 15px;
`;

const ButtonContainer = styled.div`
    display: flex;
    grid-column: 2;
    justify-content: space-evenly;
    align-items: center;
    & div {
        font-weight: bold;
        font-size: 1.5em;
    }
`;

const PurchasePage = ({ notify }) => {
    const [stripe, setStripe] = useState(null);
    const [processing, setProcessingStatus] = useState(false);

    const onError = useCallback(() => {
        notify('Something went wrong...', notificationTypes.ERROR);
        setProcessingStatus(false);
    }, [notify, setProcessingStatus]);

    const onBadLinkError = useCallback(() => {
        notify('Bad link', notificationTypes.ERROR);
    }, [notify]);

    const stripeOnloadFunction = useCallback(() => {
        setStripe(window.Stripe(STRIPE_KEY));
    }, [setStripe]);

    useEffect(() => {
        const newScript = document.createElement('script');
        newScript.onerror = onError;
        newScript.onload = stripeOnloadFunction;
        document.head.appendChild(newScript);
        newScript.src = STRIPE_URL;
    }, [onError, stripeOnloadFunction]);

    const paymentSessionSuccess = useCallback(
        ({ data }) => {
            setProcessingStatus(false);
            stripe
                .redirectToCheckout({
                    sessionId: data.stripeSessionId
                })
                .then(onError);
        },
        [stripe, onError]
    );

    const getAccountId = useCallback(() => {
        try {
            return decodeSearchUrl(atou).id;
        } catch (error) {
            onBadLinkError();
        }
    }, [onBadLinkError]);

    const handlePurchase = useCallback(() => {
        const root = window.location.origin + window.location.pathname;
        const search = window.location.search;
        const accountId = getAccountId();
        if (!accountId) return;

        setProcessingStatus(true);
        apiBuilder()
            .post(endpoints.stripeUserSession(accountId), {
                successUrl: `${root}/success${search}`,
                cancelUrl: `${root}/fail${search}`,
                gamesToBuy: 1, // todo: cut legacy param,
                purchaseType: 'REPORT'
            })
            .then(paymentSessionSuccess)
            .catch(onError);
    }, [getAccountId, paymentSessionSuccess, onError]);

    return (
        <PageContainer>
            <Layout>
                <Text style={{ gridColumn: '1/3' }}>{contentList[0]}</Text>
                <StyledImg src={img1} alt="Example" />
                <StyledImg src={img2} alt="Example" />
                <Text style={{ gridColumn: '2/4' }}>{contentList[1]}</Text>
                <div
                    style={{
                        gridColumn: '1/4',
                        fontSize: '0.85em',
                        fontWeight: 'bold',
                        alignSelf: 'center',
                        justifySelf: 'center'
                    }}
                >
                    {contentList[2]}
                </div>
                <ButtonContainer>
                    <div>{contentList[3]}</div>
                    <Button isLoading={processing} onClick={handlePurchase}>
                        PURCHASE
                    </Button>
                </ButtonContainer>
                <div style={{ gridColumn: '1/4', textAlign: 'center', fontWeight: 'bold', fontSize: '0.8em' }}>
                    {contentList[4]}
                </div>
            </Layout>
            <div />
        </PageContainer>
    );
};

export default connect(
    null,
    { notify }
)(PurchasePage);
