import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Content as ContentLayout } from '../../../components/layouts/content';
import { trackedEvents, trackEvent } from '../../../telemetry/app-analytics';
import {
    isBrandedByMocap,
    isBrandedByHockeyTech,
    isProspectMode
} from '../../../selectors/user/user-features-selector';
import logoImg from '../TPE.svg';
import styles from './footer.module.css';
import { productToPath } from '../../product-initializer/products';
import { serviceIndex } from '../../../constants/page-names';
import FormattedMessage from '../../../components/formatted-message';

const GLOSSARY_LINKS_IS_HIDDEN = true;

const getPath = product => {
    const path = productToPath[product.name];
    return path ? path.path : null;
};

const getSupportLink = state => {
    const { product } = state;
    const path = getPath(product);
    return path ? `${path}/${serviceIndex}` : null;
};

const Footer = props => {
    const handleSupportClick = () => {
        const { history, supportUrl } = props;
        if (!supportUrl) return;
        trackEvent(trackedEvents.GO_TO_SUPPORT, { properties: { from: 'footer' } });
        history.push(supportUrl);
    };

    const { prospectMode } = props;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <div className={styles.footer}>
            <ContentLayout inline>
                <div
                    className={styles.clickable}
                    onClick={() => {
                        props.history.push('/');
                    }}
                >
                    <img alt="" src={logoImg} height="20" />
                </div>
                {!prospectMode && (
                    <div className={styles.rightContent}>
                        <div>
                            {`© Copyright ${currentYear} TPE`} <a href="/dmca">DMCA</a>
                        </div>
                        {!GLOSSARY_LINKS_IS_HIDDEN && (
                            <div className={styles.documents}>
                                <div className={styles.item} onClick={handleSupportClick}>
                                    <FormattedMessage id="appContainer.footer.service" />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </ContentLayout>
        </div>
    );
};

const mapStateToProps = state => ({
    brandedByMocap: isBrandedByMocap(state),
    brandedByHockeyTech: isBrandedByHockeyTech(state),
    supportUrl: getSupportLink(state),
    prospectMode: isProspectMode(state)
});

export default withRouter(connect(mapStateToProps)(Footer));
