import { useEffect, useMemo, useState } from 'react';
import { apiBuilder } from '../api/common-requests/common-requests';
import isFunction from 'lodash/isFunction';

const useQuery = (query, variables, allowFetch) => {
    if (!query) {
        throw new Error('useQuery hook require "query" argument!');
    }
    const computedQuery = useMemo(() => (isFunction(query) ? query(variables) : query), [query, variables]);

    if (!computedQuery.method) {
        throw new Error(
            'useQuery hook "query" argument must be object or function returning object with "method" field!'
        );
    }
    if (!computedQuery.url) {
        throw new Error('useQuery hook "query" argument must be object or function returning object with "url" field!');
    }

    const [data, setData] = useState(null);
    const [processing, setProcessing] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (allowFetch !== undefined && allowFetch === false) return;
        setProcessing(true);
        apiBuilder()
            [computedQuery.method.toLowerCase()](
                computedQuery.url,
                computedQuery.params
                    ? {
                          params: computedQuery.params,
                          ...computedQuery.data
                      }
                    : computedQuery.data
            )
            .then(({ data }) => {
                setData(data);
                setProcessing(false);
            })
            .catch(e => {
                setError(e);
                setProcessing(false);
            });
    }, [
        /*eslint-disable*/
        computedQuery, allowFetch
        /*eslint-enable*/
    ]);

    return { data, processing, error };
};

export default useQuery;
