import React from 'react';

export const TrashIcon = ({ isFullscreen, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 1000 1000"
            fill="currentColor"
            {...props}
        >
            <path d="M926.6,169.3H757.3v-98c0-27.1-21.9-49-49-49H291.8c-27.1,0-49,21.9-49,49v98H73.4c-35,0-63.4,27.4-63.4,61.3s28.4,61.3,63.4,61.3h71.3v588c0,54.1,43.9,98,98,98h514.5c54.1,0,98-43.9,98-98v-588h71.3c35,0,63.4-27.4,63.4-61.3S961.6,169.3,926.6,169.3z M340.7,95.8h318.5v73.5H340.7V95.8z M340.7,818.4c0,20.3-16.5,36.8-36.7,36.8s-36.8-16.5-36.8-36.8V291.8h73.5V818.4z M536.8,818.4c0,20.3-16.5,36.8-36.8,36.8s-36.8-16.5-36.8-36.8V291.8h73.5V818.4z M732.8,818.4c0,20.3-16.5,36.8-36.8,36.8s-36.8-16.5-36.8-36.8V291.8h73.5V818.4z" />
        </svg>
    );
};
