import React, { Component } from 'react';
import styleBinder from 'classnames/bind';
import styles from './icon.module.css';

export default class Icon extends Component {
    render() {
        const { className, wrapped = true, type, size, pulse, ...rest } = this.props;
        const cx = styleBinder.bind(styles);
        const iconStyle = cx(className, { icon: true, [type]: true, [`size-${size}x`]: !!size, pulse });
        //todo wrapped flag is hotfix, need more clean solution
        return wrapped ? (
            <div className={styles.wrapper} {...rest}>
                <i className={iconStyle} />
            </div>
        ) : (
            <i className={iconStyle} {...rest} />
        );
    }
}
