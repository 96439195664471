import { entriesTeamsSelector } from '../../../../../containers/widgets-stats/selectors/entries';
import { gameShotsSelector } from '../../../../../containers/widgets-stats/selectors/shots';
import { createSelector } from 'reselect';
import { createEpisodeIdWrapper, mapperErrorMessage, processingState } from '../../episode-utils';
import { FAVORITE_EVENT } from '../../favorite-types';
import {
    extractedDrawsShots,
    extractedAfterControlInShots,
    extractedAllShotsAtOffensiveZoneAfterRecoveries,
    extractedAllShotsAtOzAfterTakeAway,
    extractedFaceOffShots,
    extractedShotsFromOZ,
    getTypeToMediaTimeWrapper
} from '../helpers/episodes-selectors-helpers';
import {
    faceOffFromEventsSelector,
    recoveriesFromEventsSelector,
    takeAwayFromEventsSelector,
    possessionsFromEventsSelector
} from '../helpers/common-episodes-selectors';
import { ppItemsSelector } from '../../../../../containers/stats-filter/selectors';

//Selectors for draws from widget stats
const selector = createSelector(
    entriesTeamsSelector,
    gameShotsSelector,
    recoveriesFromEventsSelector,
    takeAwayFromEventsSelector,
    ppItemsSelector,
    faceOffFromEventsSelector,
    possessionsFromEventsSelector,
    (entriesTeamsStats, fieldShotsStats, recoveriesStats, takeAwayStats, ppStats, faceOffStats, possessionsStats) => ({
        entries: entriesTeamsStats,
        shots: fieldShotsStats,
        recoveries: recoveriesStats,
        takeAway: takeAwayStats,
        ppEvents: ppStats,
        faceOffs: faceOffStats,
        possessions: possessionsStats
    })
);

//Types of events for video player episodes
const FROM_ATTACK_SHOTS = 'FROM_ATTACK_SHOTS';
const FROM_ATTACK_SHOTS_CONTROLLED_IN_AT_DELTA_TIME = 'FROM_ATTACK_SHOTS_CONTROLLED_IN_AT_DELTA_TIME';
const FROM_ATTACK_SHOTS_RECOVERY_AT_DELTA_TIME = 'FROM_ATTACK_SHOTS_RECOVERY_AT_DELTA_TIME';
const FROM_ATTACK_SHOTS_AFTER_TAKEAWAY_NOT_ON_PP = 'FROM_ATTACK_SHOTS_AFTER_TAKEAWAY_NOT_ON_PP';
const FROM_ATTACK_SHOTS_AFTER_FACE_OFF = 'FROM_ATTACK_SHOTS_AFTER_FACE_OFF';
const FROM_ATTACK_DRAWS_SHOTS = 'FROM_ATTACK_DRAWS_SHOTS';

const types = {
    FROM_ATTACK_SHOTS,
    FROM_ATTACK_SHOTS_CONTROLLED_IN_AT_DELTA_TIME,
    FROM_ATTACK_SHOTS_RECOVERY_AT_DELTA_TIME,
    FROM_ATTACK_SHOTS_AFTER_TAKEAWAY_NOT_ON_PP,
    FROM_ATTACK_SHOTS_AFTER_FACE_OFF,
    FROM_ATTACK_DRAWS_SHOTS
};

//All shots from OZ selector
const createFromAttackShotsSelector = () =>
    createSelector(
        state => !!state.shots && state.shots,
        dataShots => {
            return extractedShotsFromOZ(dataShots);
        }
    );

//Only shots from OZ, what was being after delta time since controlled in OZ selector
const createAfterControlledInAttackShotsSelector = () =>
    createSelector(
        state => !!state.shots && state.shots,
        state => !!state.entries && state.entries,
        (allShots, controlledEntries) => {
            return extractedAfterControlInShots(allShots, controlledEntries);
        }
    );

const createFromAfterRecoveryShotsSelector = () =>
    createSelector(
        state => !!state.recoveries && state.recoveries,
        state => !!state.shots && state.shots,
        state => !!state.entries && state.entries,
        (recoveriesStats, allShots, controlledEntries) => {
            return extractedAllShotsAtOffensiveZoneAfterRecoveries(allShots, controlledEntries, recoveriesStats);
        }
    );

const createFromAfterTakeawayNotOnPPSelector = () =>
    createSelector(
        state => !!state.recoveries && state.recoveries,
        state => !!state.shots && state.shots,
        state => !!state.entries && state.entries,
        state => !!state.takeAway && state.takeAway,
        state => !!state.ppEvents && state.ppEvents,
        (recoveriesStats, allShots, controlledEntries, takeAwayFromEvents, powerPlays) => {
            return extractedAllShotsAtOzAfterTakeAway(
                allShots,
                controlledEntries,
                recoveriesStats,
                takeAwayFromEvents,
                powerPlays
            );
        }
    );

const createFromAfterFaceOff = () =>
    createSelector(
        state => !!state.recoveries && state.recoveries,
        state => !!state.shots && state.shots,
        state => !!state.entries && state.entries,
        state => !!state.takeAway && state.takeAway,
        state => !!state.ppEvents && state.ppEvents,
        state => !!state.faceOffs && state.faceOffs,
        state => !!state.possessions && state.possessions,
        (recoveriesStats, allShots, controlledEntries, takeAwayFromEvents, powerPlays, faceOffs, possessions) => {
            return extractedFaceOffShots(
                allShots,
                controlledEntries,
                recoveriesStats,
                takeAwayFromEvents,
                powerPlays,
                faceOffs,
                possessions
            );
        }
    );

const createFromAfterDraws = () =>
    createSelector(
        state => !!state.recoveries && state.recoveries,
        state => !!state.shots && state.shots,
        state => !!state.entries && state.entries,
        state => !!state.takeAway && state.takeAway,
        state => !!state.ppEvents && state.ppEvents,
        state => !!state.faceOffs && state.faceOffs,
        state => !!state.possessions && state.possessions,
        (recoveriesStats, allShots, controlledEntries, takeAwayFromEvents, powerPlays, faceOffs, possessions) => {
            return extractedDrawsShots(
                allShots,
                controlledEntries,
                recoveriesStats,
                takeAwayFromEvents,
                powerPlays,
                faceOffs,
                possessions
            );
        }
    );

//Selectors mapping by type object
const typeToSelector = {
    FROM_ATTACK_SHOTS: createEpisodeIdWrapper(FROM_ATTACK_SHOTS, createFromAttackShotsSelector()),
    FROM_ATTACK_SHOTS_CONTROLLED_IN_AT_DELTA_TIME: createEpisodeIdWrapper(
        FROM_ATTACK_SHOTS_CONTROLLED_IN_AT_DELTA_TIME,
        createAfterControlledInAttackShotsSelector()
    ),
    FROM_ATTACK_SHOTS_RECOVERY_AT_DELTA_TIME: createEpisodeIdWrapper(
        FROM_ATTACK_SHOTS_RECOVERY_AT_DELTA_TIME,
        createFromAfterRecoveryShotsSelector()
    ),
    FROM_ATTACK_SHOTS_AFTER_TAKEAWAY_NOT_ON_PP: createEpisodeIdWrapper(
        FROM_ATTACK_SHOTS_AFTER_TAKEAWAY_NOT_ON_PP,
        createFromAfterTakeawayNotOnPPSelector()
    ),
    FROM_ATTACK_SHOTS_AFTER_FACE_OFF: createEpisodeIdWrapper(
        FROM_ATTACK_SHOTS_AFTER_FACE_OFF,
        createFromAfterFaceOff()
    ),
    FROM_ATTACK_DRAWS_SHOTS: createEpisodeIdWrapper(FROM_ATTACK_DRAWS_SHOTS, createFromAfterDraws())
};

//Mapper of selectors by type
function mapper(stateForDraws, type) {
    if (stateForDraws.entries.processing || stateForDraws.shots.processing) {
        return processingState;
    }
    if (!(type in typeToSelector)) {
        throw mapperErrorMessage(type, 'Draws');
    }
    return typeToSelector[type](stateForDraws);
}

const favoriteType = FAVORITE_EVENT;

const typeToMediaTimeWrapper = getTypeToMediaTimeWrapper(typeToSelector);

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const draws = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default draws;
