import styled from 'styled-components/macro';

export const LicenseHeaderContainer = styled.div`
    margin-bottom: 12pt;

    text-align: center;
`;

export const ParagraphTitle = styled.b`
    font-size: 18px;
`;

export const ParagraphContent = styled.p`
    margin-bottom: 12pt;
`;

export const ParagraphContentWithSpace = styled.p`
    margin-top: 12pt;
    margin-bottom: 12pt;
`;
