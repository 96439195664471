import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tether from 'tether';
import { placements, configAttachments } from './attachments';
import styles from './tooltip.module.css';

const propTypes = {
    children: PropTypes.node.isRequired,
    placement: PropTypes.oneOf(placements),
    tether: PropTypes.object,
    opened: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,

    onMouseOverTooltipContent: PropTypes.func,
    onMouseLeaveTooltipContent: PropTypes.func,

    detached: PropTypes.bool,
    hideDelay: PropTypes.number
};

const defaultProps = {
    opened: false,
    detached: false,
    hideDelay: 250
};

const defaultTetherConfig = {
    classPrefix: 'tether',
    classes: {
        element: false,
        enabled: styles.show
    },
    constraints: [{ to: 'scrollParent', attachment: 'together none' }, { to: 'window', attachment: 'together none' }]
};

class TooltipContent extends Component {
    constructor(props) {
        super(props);

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.mouseOverTooltipContent = this.mouseOverTooltipContent.bind(this);
        this.mouseLeaveTooltipContent = this.mouseLeaveTooltipContent.bind(this);
    }

    componentDidMount() {
        this.handleProps();
    }

    componentDidUpdate(prevProps) {
        if (this.props.opened !== prevProps.opened) {
            this.handleProps();
        } else if (this.props.tether !== prevProps.tether && this._tether) {
            this._tether.setOptions(this.createTetherConfig());
            this._tether.position();
            this._element.childNodes[0].focus();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.detached !== nextProps.detached && nextProps.detached) {
            this.clearHideTimeout();
            this._hideTimeout = setTimeout(this.props.onToggle, this.props.hideDelay);
        } else if (!nextProps.detached) {
            this.clearHideTimeout();
        }
    }

    componentWillUnmount() {
        this.hide();
    }

    handleDocumentClick(e) {
        const container = this._element;
        if (e.target === container || !container.contains(e.target)) {
            this.props.onToggle();
        }
    }

    handleProps() {
        if (this.props.opened) {
            this.show();
        } else {
            this.hide();
        }
    }

    createTetherConfig() {
        return {
            ...defaultTetherConfig,
            constraints: [
                {
                    to: 'window',
                    attachment: 'together'
                }
            ],
            ...configAttachments(this.props.placement),
            element: this._element,
            target: this.props.target,
            ...this.props.tether
        };
    }

    hide() {
        document.removeEventListener('touchstart', this.handleDocumentClick, false);
        if (this._tether) {
            this._tether.destroy();
            this._tether = null;
        }
    }

    show() {
        document.addEventListener('touchstart', this.handleDocumentClick, false);
        this._tether = new Tether(this.createTetherConfig());
        this._tether.position();
        this._element.childNodes[0].focus();
    }

    mouseOverTooltipContent(e) {
        if (this.props.onMouseOverTooltipContent) return this.props.onMouseOverTooltipContent(e);
        if (this._hideTimeout) {
            this.clearHideTimeout();
        }
    }

    mouseLeaveTooltipContent(e) {
        if (this.props.onMouseLeaveTooltipContent) return this.props.onMouseLeaveTooltipContent(e);
        if (this.props.detached && !this._hideTimeout) {
            this.clearHideTimeout();
            this._hideTimeout = setTimeout(this.props.onToggle, this.props.hideDelay);
        }
    }

    clearHideTimeout() {
        if (!this._hideTimeout) return;
        clearTimeout(this._hideTimeout);
        this._hideTimeout = null;
    }

    render() {
        return (
            <div
                className={styles.wrapper}
                ref={div => {
                    this._element = div;
                }}
            >
                <div
                    className={styles.content}
                    onMouseOver={this.mouseOverTooltipContent}
                    onMouseLeave={this.mouseLeaveTooltipContent}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

TooltipContent.propTypes = propTypes;
TooltipContent.defaultProps = defaultProps;

export default TooltipContent;
