import React, { Component } from 'react';
import { connect } from 'react-redux';
import { readCurrentUser } from 'redux/modules/user';
import { withRouter } from 'react-router-dom';
import { isWebAnalyticsEnabled, setupWebAnalytics, trackPageView } from 'telemetry/app-analytics';
import FallbackPage from 'components/fallback-page/fallback-page';
import createObservableSubject from 'utils/helpers/create-observable-subject';
import TemporaryUnavailablePage from '../../components/temporary-unavailable-page/temporary-unavailable-page';

export const observableUserInit = createObservableSubject();

class UserContainer extends Component {
    componentDidMount() {
        if (!this.props.user) {
            this.props.readCurrentUser(this.errorHandler);
        }
    }

    componentDidUpdate(prevProps) {
        const { user: nextUser, location: nextLocation } = this.props;
        const { user, location } = prevProps;

        if (nextUser !== user && nextUser && nextUser.appInsightsKey) {
            setupWebAnalytics(nextUser.appInsightsKey, nextUser.account);
            trackPageView(nextLocation.pathname);
        }

        if (isWebAnalyticsEnabled() && nextLocation.pathname !== location.pathname) {
            trackPageView(nextLocation.pathname);
        }

        if (nextUser !== user && nextUser && !nextUser.isProcessing) {
            observableUserInit.notify();
        }
    }

    errorHandler = error => {
        const isUnauthorized = error && error.response && error.response.status === 401;
        if (isUnauthorized) {
            localStorage.clear();
            window.location.reload();
        }
    };

    render() {
        const { user, children } = this.props;
        const isProcessing = !user || user.isProcessing;
        const isFailed = user && user.isFailed;

        if (isFailed) {
            return <TemporaryUnavailablePage unavailableMessage={user.errorMessage} />;
        }

        return isProcessing ? <FallbackPage /> : React.Children.only(children);
    }
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default withRouter(
    connect(
        mapStateToProps,
        { readCurrentUser }
    )(UserContainer)
);
