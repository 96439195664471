import { makePrefixAdder } from '../utils/type-utils';
import { getLocalConfiguration, setLocalConfiguration } from '../../utils/helpers/storages-utils';
import isFunction from 'lodash/isFunction';

const addPrefixTo = makePrefixAdder('LOCAL_CONFIGURATION');
const SET_ATTRIBUTE = addPrefixTo('SET_ATTRIBUTE');

export const setAttribute = attributeName => value => {
    return { type: SET_ATTRIBUTE, payload: { attributeName, value } };
};

export const getAttribute = attributeName => state => state.localConfiguration[attributeName];

const defaultState = getLocalConfiguration();

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case SET_ATTRIBUTE:
            const { attributeName, value } = action.payload;
            const computedValue = isFunction(value) ? value(state[attributeName]) : value;
            const newState = { ...state, [attributeName]: computedValue };
            setLocalConfiguration(newState);
            return newState;
        default:
            return state;
    }
}
