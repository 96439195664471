import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions } from './module';
import MountedTooltip from './mounted-tooltip';
import TooltipContent from './tooltip-content';

function mapStateToProps(state) {
    return {
        mountedTooltips: state.tooltip.mountedTooltips,
        plainTooltips: state.tooltip.plainTooltips
    };
}

class TooltipAnchor extends Component {
    constructor(props) {
        super(props);
        this._tooltipIdToToogleFunc = new Map();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.plainTooltips === this.props.plainTooltips) return;
        const entries = Object.values(nextProps.plainTooltips)
            .map(tooltip => tooltip.tooltipId)
            .map(tooltipId => [tooltipId, this._tooltipIdToToogleFunc.get(tooltipId)]);

        this._tooltipIdToToogleFunc.clear();

        this._tooltipIdToToogleFunc = new Map(entries);
    }

    getToggleFunc(tooltipId) {
        let toggleFunc = this._tooltipIdToToogleFunc.get(tooltipId);

        if (toggleFunc) {
            return toggleFunc;
        } else {
            toggleFunc = () => this.props.hideTooltip(tooltipId);

            this._tooltipIdToToogleFunc.set(tooltipId, toggleFunc);

            return toggleFunc;
        }
    } //noinspection JSMethodCanBeStatic

    renderMounted(tooltip) {
        const { target, options = {}, render } = tooltip;
        return (
            <MountedTooltip key={target} target={target} tether={options.tether} placement={options.placement}>
                {render}
            </MountedTooltip>
        );
    }

    renderPlain(tooltip) {
        const { tooltipId, target, options = {}, detached = false, render } = tooltip;
        if (!target) return null;
        return (
            <TooltipContent
                key={tooltipId}
                target={target}
                detached={detached}
                placement={options.placement}
                tether={options.tether}
                onToggle={this.getToggleFunc(tooltipId)}
                opened
            >
                {render}
            </TooltipContent>
        );
    }

    render() {
        const { mountedTooltips = {}, plainTooltips = {} } = this.props;
        return (
            <div>
                {Object.values(mountedTooltips).map(tooltip => this.renderMounted(tooltip))}
                {Object.values(plainTooltips).map(tooltip => this.renderPlain(tooltip))}
            </div>
        );
    }
}

TooltipAnchor = connect(
    mapStateToProps,
    {
        hideTooltip: actions.hideTooltip
    }
)(TooltipAnchor);
export default TooltipAnchor;
