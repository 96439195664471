import React, { useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Input from '../../components/input/input';
import FormattedMessage from '../../components/formatted-message';
import PropsFormatter, { tr } from '../../containers/props-formatter';
import {
    StyledButton,
    StyledContainer,
    StyledError,
    StyledForm,
    StyledHeader,
    StyledP
} from './auth-styled-components';
import { isEmailValid } from '../../utils/regex-utils';
import Action from '../../containers/rest-query/action';
import { decodeSearchUrl } from '../../utils/api/path-utils';
import { Redirect } from 'react-router-dom';
import { isPasswordValid } from '../../utils/password-utils';
import { notEmptyPredicate } from '../../utils/helpers/check-condition-utils';
import { AuthOptionLinks, LOGIN_AUTH_LINK, SIGN_UP_AUTH_LINK } from './auth-option-links';
import Recaptcher, { CHECK_RECAPTCHA, recaptchaKey } from '../../components/recaptcher/recaptcher';
import { RecaptcherWrapper } from '../../components/recaptcher/recaptcher-styled-components';
import { languageSelectorForRecaptcha } from '../../redux/modules/locale';

const resetPasswordQuery = ({ password, token, recaptchaToken }) => ({
    method: 'POST',
    url: '/api/users/func/update-password',
    params: { password, token, recaptchaToken }
});

const requestResetPasswordQuery = ({ email, recaptchaToken }) => ({
    method: 'POST',
    url: '/api/users/func/reset-password',
    params: { email, recaptchaToken }
});

const ResetPassword = props => {
    const { token } = decodeSearchUrl();

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationMode, setValidationMode] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [recaptchaToken, setRecaptchaToken] = useState('');

    const captchaRef = useRef();

    const { hl } = props;

    const handleCaptchaReady = resp => {
        setRecaptchaToken(resp);
    };
    const handleCaptchaError = respError => {
        /*eslint-disable*/
        console.error(respError);
        /*eslint-enable*/
        setRecaptchaToken('');
    };

    const handleCaptchaExpire = () => {
        /*eslint-disable*/
        console.error('Captcha expire!');
        /*eslint-enable*/
        setRecaptchaToken('');
    };

    const handleRecaptchaUpdate = () => {
        setRecaptchaToken('');
        if (!!captchaRef && !!captchaRef.current) {
            captchaRef.current.reset();
        }
    };

    const submitEnabled = useMemo(() => (token ? isPasswordValid(password) : isEmailValid(email)), [
        email,
        password,
        token
    ]);

    const resetPasswordButtonIsDisable = useMemo(
        () => {
            return (
                (validationMode && !submitEnabled) || (CHECK_RECAPTCHA && (!recaptchaToken || recaptchaToken === ''))
            );
        },
        /*eslint-disable*/ [validationMode, submitEnabled, recaptchaToken] /*eslint-enable*/
    );

    if (notEmptyPredicate(token) && notEmptyPredicate(success)) {
        return <Redirect to="/login" />;
    }

    return (
        <StyledContainer>
            <StyledForm>
                <StyledHeader>
                    <FormattedMessage id="resetPassword.title" />
                </StyledHeader>
                {success ? (
                    token ? (
                        <StyledP>
                            <FormattedMessage id="resetPassword.successNotification" />
                        </StyledP>
                    ) : (
                        <StyledP>
                            <FormattedMessage id="resetPassword.emailNotification" />
                        </StyledP>
                    )
                ) : (
                    <>
                        {token ? (
                            <>
                                <PropsFormatter
                                    validationPredicate={validationMode && isPasswordValid}
                                    placeholder={tr('resetPassword.newPassword')}
                                >
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={input => setPassword(input.target.value)}
                                        unbounded
                                    />
                                </PropsFormatter>
                                <Action
                                    query={resetPasswordQuery}
                                    variables={{ token, password, recaptchaToken }}
                                    notifyOnFailure={false}
                                    onSuccess={() => setSuccess(true)}
                                    onFailure={() => setError(true)}
                                >
                                    {({ performAction, processing }) => (
                                        <StyledButton
                                            disabled={resetPasswordButtonIsDisable || processing}
                                            onClick={e => {
                                                e.preventDefault();
                                                handleRecaptchaUpdate();
                                                if (submitEnabled) {
                                                    performAction();
                                                } else {
                                                    setValidationMode(true);
                                                }
                                            }}
                                            isLoading={processing}
                                        >
                                            <FormattedMessage id="resetPassword.button" />
                                        </StyledButton>
                                    )}
                                </Action>
                            </>
                        ) : (
                            <>
                                <PropsFormatter
                                    validationPredicate={validationMode && isEmailValid}
                                    placeholder={tr('resetPassword.login')}
                                >
                                    <Input
                                        type="text"
                                        value={email}
                                        onChange={input => setEmail(input.target.value)}
                                        unbounded
                                    />
                                </PropsFormatter>
                                <Action
                                    query={requestResetPasswordQuery}
                                    variables={{ email, recaptchaToken }}
                                    notifyOnFailure={false}
                                    onSuccess={() => setSuccess(true)}
                                    onFailure={() => setError(true)}
                                >
                                    {({ performAction, processing }) => (
                                        <StyledButton
                                            disabled={resetPasswordButtonIsDisable || processing}
                                            onClick={e => {
                                                e.preventDefault();
                                                handleRecaptchaUpdate();
                                                if (submitEnabled) {
                                                    performAction();
                                                } else {
                                                    setValidationMode(true);
                                                }
                                            }}
                                            isLoading={processing}
                                        >
                                            <FormattedMessage id="resetPassword.button" />
                                        </StyledButton>
                                    )}
                                </Action>
                            </>
                        )}
                        {CHECK_RECAPTCHA && (
                            <RecaptcherWrapper>
                                <Recaptcher
                                    ref={captchaRef}
                                    recaptchaKey={recaptchaKey}
                                    onVerify={handleCaptchaReady}
                                    onError={handleCaptchaError}
                                    onExpire={handleCaptchaExpire}
                                    hl={hl}
                                    id={`Recaptcher-Reset-Password`}
                                />
                            </RecaptcherWrapper>
                        )}
                    </>
                )}
                {error && (
                    <StyledError>
                        <FormattedMessage id="signUp.error" />
                    </StyledError>
                )}
            </StyledForm>
            <AuthOptionLinks linksToShow={[SIGN_UP_AUTH_LINK, LOGIN_AUTH_LINK]} />
        </StyledContainer>
    );
};

const mapStateToProps = state => {
    const hl = languageSelectorForRecaptcha(state);

    return {
        hl
    };
};

export default connect(mapStateToProps)(ResetPassword);
