import axios from 'axios';
import { CANCEL } from 'redux-saga';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';
import { INTERNAL_ERROR } from '../utils/type-utils';
import { notify, notificationTypes } from '../modules/notifications';
import { DEFAULT_FAILURE_NOTIFICATION } from '../../containers/rest-query/constants';
/**
 * Идентификатор асинхронного http-запроса
 */

export const HTTP_REQUEST_API = Symbol('HTTP Request API');

const actionWith = (action, data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[HTTP_REQUEST_API];
    return finalAction;
};
/**
 * @param action redux-action с http-запросом
 * @param state текущее состоянеи redux store
 * @param commonCancelSource общий интерфейс отмены запроса
 * @return промис http-запроса
 */

function makeRequest(action, state, commonCancelSource) {
    const { requester, types, cancelSource, responseTransformer, errorHandler } = action[HTTP_REQUEST_API];
    const [requestType, successType, failureType, cancelledType] = types;
    const source = commonCancelSource || cancelSource || axios.CancelToken.source();
    const promise = requester(state, source.token)
        .then(response => {
            if (responseTransformer && typeof responseTransformer === 'function')
                response = responseTransformer(response);
            return {
                type: successType,
                response
            };
        })
        .catch(thrown => {
            if (axios.isCancel(thrown))
                return {
                    type: cancelledType,
                    response: thrown.message
                };
            if (!thrown.response)
                return {
                    type: INTERNAL_ERROR,
                    error: thrown
                };
            if (errorHandler && typeof errorHandler === 'function') errorHandler(thrown);
            return {
                type: failureType,
                response: thrown.response
            };
        });

    promise[CANCEL] = () => source.cancel(`Request with type ${requestType} cancelled`);

    return promise;
}

const identity = state => state;
/**
 * saga выполняющая асинхронный http-запрос
 */

export function* handleRequest(action, cancelSource) {
    const { requester, types } = action[HTTP_REQUEST_API];
    if (!requester) throw new Error('Specify request action');
    const [requestType, successType] = types;
    yield put(
        actionWith(action, {
            type: requestType
        })
    );
    const state = yield select(identity);
    const apiResponse = yield call(makeRequest, action, state, cancelSource);
    yield put(actionWith(action, apiResponse));
    if (apiResponse.type !== successType) {
        //Error message to user: max 200 symbols (include default request error message).
        const errorMessageToUser = `${
            apiResponse && apiResponse.response && apiResponse.response.data && apiResponse.response.data.message
                ? `${DEFAULT_FAILURE_NOTIFICATION}:\n${apiResponse.response.data.message}`.slice(0, 200)
                : DEFAULT_FAILURE_NOTIFICATION
        }`;

        yield put(notify(errorMessageToUser, notificationTypes.ERROR));
        yield cancel();
    }
    return apiResponse;
}
/**
 * @return saga наблюдающая за асинхронными http-запросами
 */

export default function* requestApiWatcher() {
    yield takeEvery(action => action.hasOwnProperty(HTTP_REQUEST_API), handleRequest);
}
