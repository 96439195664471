import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import Button from '../../components/button/button';
import { DmcaContent } from './dmca-content';
import { hasAuthKey } from '../../utils/api/auth-utils';
import logoImg from './../license/TPE.svg';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 96px);
`;

const Layout = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-rows: 1fr 50px;
    width: 700px;
    height: 90%;
    padding: 40px;

    border-radius: 4px;

    background-color: var(--backgroundColorSecondary);

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
    grid-row: ${props => (props.buttons ? 'initial' : '1/3')};
    overflow-y: scroll;
`;

const Clickable = styled.div`
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
`;

const ClickableText = styled.span`
    padding-left: 20px;
`;

const DmcaPage = ({ location }) => {
    const { from } = location.state || { from: '/' };
    const [isAuth, setAuth] = useState(false);

    const history = useHistory();

    const onSuccessfulAccepting = () => {
        window.location.assign(from);
    };

    useEffect(() => {
        setAuth(hasAuthKey());
    }, []);

    return (
        <Container>
            <Layout>
                <Content buttons={!isAuth}>
                    <DmcaContent />
                </Content>
                {isAuth ? (
                    <Button>
                        <Clickable onClick={onSuccessfulAccepting}>
                            <img alt="" src={logoImg} height="20" />
                            <ClickableText>BACK TO HOME PAGE</ClickableText>
                        </Clickable>
                    </Button>
                ) : (
                    <Button>
                        <Clickable
                            onClick={() => {
                                history.push('/login');
                            }}
                        >
                            <img alt="" src={logoImg} height="20" />
                            <ClickableText>BACK TO LOGIN PAGE</ClickableText>
                        </Clickable>
                    </Button>
                )}
            </Layout>
        </Container>
    );
};

export default DmcaPage;
