import { pkItemsSelector } from '../../../../../containers/stats-filter/selectors';
import { FAVORITE_PK_TEAM } from '../../favorite-types';
import { createSpecialTeamsSelector } from './power-play';
import { processingState, createEpisodeIdWrapper, createMediaTimeWrapper } from '../../episode-utils';

const PENALTY_KILL = 'PENALTY_KILL';

const types = { PENALTY_KILL };

const selector = pkItemsSelector;

const favoriteType = FAVORITE_PK_TEAM;

const pkSelector = createEpisodeIdWrapper(PENALTY_KILL, createSpecialTeamsSelector());

function mapper(specialTeams) {
    if (specialTeams.processing) return processingState;
    return pkSelector(specialTeams);
}

const getTime = ({ startTime, endTime }) => ({ startTime, endTime, time: startTime, hasPadding: false });

const mediaTimeWrapper = createMediaTimeWrapper(getTime);

function getTimedGroup(group, periods) {
    return mediaTimeWrapper(group, periods);
}

const penaltyKill = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default penaltyKill;
