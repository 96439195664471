import { Component } from 'react';
import { setupAuthKey, setupSharedSession } from 'utils/api/auth-utils';

class SsoLogin extends Component {
    componentDidMount() {
        const { history, location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('sessionId');
        if (!token) {
            history.replace('/login');
        } else {
            setupAuthKey(token, true);
            setupSharedSession();
            history.replace('/');
        }
    }

    render() {
        return null;
    }
}

export default SsoLogin;
