/* eslint-disable */

import React from 'react';

const engDictionary = {
    /***********************************************Entities***************************************************************/
    'entities.player.fullName':
        '${!values ? "" : values && values.firstNameEn ? values.firstNameEn[0]+"." : ""} ${!!values && !!values.lastNameEn ? values.lastNameEn : "-"}',
    'entities.player.fullNameLong':
        '${values && values.firstNameEn ? values.firstNameEn : ""} ${!!values && !!values.lastNameEn ? values.lastNameEn : "-"}',
    'entities.player.fullNameInverted':
        '${values&&values.lastNameEn ? values.lastNameEn : ""} ${values && values.firstNameEn ? values.firstNameEn[0] : ""}.',
    'entities.player.longName': '{firstNameEn} {lastNameEn}',
    'entities.player.lastName': '{lastNameEn}',
    'entities.player.lastNameWithInitial': '{lastNameEn} ${values && values.firstNameEn ? values.firstNameEn[0] : ""}.',
    'entities.team.name': '{nameEn}',
    'entities.team.acronym': '{acronymEn}',
    'entities.season': '{nameEn}',
    'entities.league': '{nameEn}',
    'entities.team': '{nameEn}',
    'entities.country': '{nameEn}',
    'entities.scout': '{name}',
    'game.period.fullPresentation': '{period, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} period',
    'game.ot.fullPresentation': '{ot, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} ot',
    'game.winType': '{ winType, select, OT {OT} other {SO} }',
    brand: 'TPE team',

    SCOUT: 'Scout',

    'entities.player.position': '{position}',
    'entities.player.period': '{period}',
    'entities.player.id': '{id}',

    /***********************************************Ice Player************************************************************/
    'icePlayer.widget': 'Ice Player',
    'icePlayer.playlistMode': 'Playlist',
    'icePlayer.fullVideoMode': 'Full video',
    'icePlayer.ANIMATION': 'Animation',
    'icePlayer.VIDEO': 'Video',
    'icePlayer.SYNC': 'Sync',
    'icePlayer.jumpForward': '5 sec forward',
    'icePlayer.jumpBackward': '5 sec backward',
    'icePlayer.showPlayList': 'Show Playlist',
    'icePlayer.hidePlayList': 'Hide Playlist',
    'icePlayer.episodes.gameDate': '{date, date, shortUTC}',
    'icePlayer.episodes.RECOVERY': 'Recoveries',
    'icePlayer.episodes.GIVEAWAY': 'Giveaways',
    'icePlayer.episodes.TAKEAWAY': 'Interceptions',
    'icePlayer.episodes.FACE_OFF': 'Faceoffs',
    'icePlayer.episodes.SINGLE_SCORE_ATTEMPTS': 'Shot Attempts',
    'icePlayer.episodes.SINGLE_SCORE_ATTEMPTS_ON_ICE': 'Shot Attempts on ice',
    'icePlayer.episodes.PERSONAL_SCORE_ATTEMPTS': 'Shot Attempts individual',
    'icePlayer.episodes.SINGLE_SCORING_CHANCES': 'Scoring Chances',
    'icePlayer.episodes.SINGLE_SCORING_CHANCES_ON_ICE': 'Scoring Chances on ice',
    'icePlayer.episodes.PERSONAL_SCORING_CHANCES': 'Scoring Chances individual',
    'icePlayer.episodes.SINGLE_GIVEAWAY': 'Giveaways',
    'icePlayer.episodes.SINGLE_GIVEAWAY_ON_ICE': 'Giveaways on ice',
    'icePlayer.episodes.PERSONAL_SINGLE_GIVEAWAY': 'Giveaways individual',
    'icePlayer.episodes.SINGLE_TAKEAWAY': 'Interceptions',
    'icePlayer.episodes.SINGLE_TAKEAWAY_ON_ICE': 'Interceptions on ice',
    'icePlayer.episodes.PERSONAL_SINGLE_TAKEAWAY': 'Interceptions individual',
    'icePlayer.episodes.GAME_RECOVERY': 'Recoveries',
    'icePlayer.episodes.GAME_GIVEAWAY': 'Giveaways',
    'icePlayer.episodes.GAME_TAKEAWAY': 'Interceptions',
    'icePlayer.episodes.GAME_FACE_OFF': 'Faceoff',
    'icePlayer.episodes.GAME_CARRY_IN': 'Carry in',
    'icePlayer.episodes.GAME_CARRY_OUT': 'Carry out',
    'icePlayer.episodes.GAME_SHOTS_ON_GOAL': 'Shots on goal',
    'icePlayer.episodes.GAME_GOALS': 'Goals',
    'icePlayer.episodes.GAME_SCORE_ATTEMPTS': 'Shot Attempts',
    'icePlayer.episodes.GAME_SCORING_CHANCES': 'Scoring chances',
    'icePlayer.episodes.GAME_BLOCKED_SHOTS': 'Shots blocked',
    'icePlayer.episodes.GAME_SHOTS_WITH_REBOUND': 'Shots with rebound',
    'icePlayer.episodes.GAME_SHOTS_WITHOUT_REBOUND': 'Shots without rebound',
    'icePlayer.episodes.GAME_MISSED_SHOTS': 'Missed shots',
    'icePlayer.episodes.CARRY_IN': 'Carry In',
    'icePlayer.episodes.DUMP_IN': 'Dump In',
    'icePlayer.episodes.CARRY_OUT': 'Carry Out',
    'icePlayer.episodes.DUMP_OUT': 'Dump Out',
    'icePlayer.episodes.PASS_IN': 'Pass In',
    'icePlayer.episodes.PASS_OUT': 'Pass Out',
    'icePlayer.episodes.CONTROLLED_IN': 'CONTROLLED ENTRIES',
    'icePlayer.episodes.UNCONTROLLED_IN': 'UNCONTROLLED ENTRIES',
    'icePlayer.episodes.CONTROLLED_OUT': 'CONTROLLED EXITS',
    'icePlayer.episodes.UNCONTROLLED_OUT': 'UNCONTROLLED EXITS',
    'icePlayer.episodes.UNFILTERED_CONTROLLED_IN': 'CONTROLLED ENTRIES',
    'icePlayer.episodes.UNFILTERED_UNCONTROLLED_IN': 'UNCONTROLLED ENTRIES',
    'icePlayer.episodes.UNFILTERED_CONTROLLED_OUT': 'CONTROLLED EXITS',
    'icePlayer.episodes.UNFILTERED_UNCONTROLLED_OUT': 'UNCONTROLLED EXITS',
    'icePlayer.episodes.BLOCKED_SHOTS': 'Shots blocked',
    'icePlayer.episodes.SCORING_CHANCES': 'Scoring chances',
    'icePlayer.episodes.SCORE_ATTEMPTS': 'Shot attempts',
    'icePlayer.episodes.SHOTS_ON_GOAL': 'Shots on goal',
    'icePlayer.episodes.GOALS': 'Goals',
    'icePlayer.episodes.MISSED_SHOTS': 'Missed shots',
    'icePlayer.episodes.SHOTS_WITH_REBOUND': 'Shots with rebound',
    'icePlayer.episodes.SHOTS_WITHOUT_REBOUND': 'Shots without rebound',
    'icePlayer.episodes.GOALTENDERS_BLOCKED_SHOTS': 'Shots blocked',
    'icePlayer.episodes.GOALTENDERS_SCORING_CHANCES': 'Scoring chances',
    'icePlayer.episodes.GOALTENDERS_SCORE_ATTEMPTS': 'Shot attempts',
    'icePlayer.episodes.GOALTENDERS_SHOTS_ON_GOAL': 'Shots on goal',
    'icePlayer.episodes.GOALTENDERS_GOALS': 'Goals',
    'icePlayer.episodes.GOALTENDERS_SHOTS_WITH_REBOUND': 'Shots with rebound',
    'icePlayer.episodes.GOALTENDERS_SHOTS_WITHOUT_REBOUND': 'Shots without rebound',
    'icePlayer.episodes.GOALTENDERS_MISSED_SHOTS': 'Missed shots',
    'icePlayer.episodes.PP_RUSH': 'Rush',
    'icePlayer.episodes.PP_REGROUP_DZ': 'Regroup',
    'icePlayer.episodes.PP_OZ_IN_FORM': 'OZ In Formation',
    'icePlayer.episodes.PP_OZ_OUT_FORM': 'OZ Out Formation',
    'icePlayer.episodes.OPPONENT_PP_RUSH': 'Rush',
    'icePlayer.episodes.OPPONENT_PP_REGROUP_DZ': 'Regroup',
    'icePlayer.episodes.OPPONENT_PP_OZ_IN_FORM': 'DZ In Formation',
    'icePlayer.episodes.OPPONENT_PP_OZ_OUT_FORM': 'DZ Out Formation',
    'icePlayer.episodes.POWER_PLAY': 'Power Play',
    'icePlayer.episodes.PENALTY_KILL': 'Penalty Kill',
    'icePlayer.episodes.PENALTY_KILL_BY_TEAM_STATUS': 'Power Play',
    'icePlayer.episodes.POWER_PLAY_BY_TEAM_STATUS': 'Penalty Kill',
    'icePlayer.episodes.GAME_SHIFTS': 'Whole Shifts',
    'icePlayer.episodes.INDIVIDUAL_SHIFTS': 'Whole Shifts',
    'icePlayer.episodes.SEASONS': '{nameEn}',
    'icePlayer.episodes.game': '{homeTeamEn}-{awayTeamEn}',
    'icePlayer.episodes.GIVE_AWAYS': 'Giveaways',
    'icePlayer.episodes.NEUTRAL_PUCKS': 'Puck recoveries',
    'icePlayer.episodes.REBOUND_RECOVERIES': 'Rebound recoveries',
    'icePlayer.episodes.STICK_CHECKS': 'Stick checks',
    'icePlayer.episodes.BODY_CHECKS': 'Body checks',
    'icePlayer.episodes.FACE_OFFS': 'Faceoffs',
    'icePlayer.episodes.POSSESSIONS': 'Possessions',
    'icePlayer.episodes.TAKE_AWAYS': 'Interceptions',
    'icePlayer.episodes.PPA': 'PPA',
    'icePlayer.episodes.PPS': 'PSA',
    'icePlayer.episodes.SINGLE_PPA': 'PPA',
    'icePlayer.episodes.SINGLE_PPS': 'PSA',
    'icePlayer.episodes.SUCCESS_PASSES': 'Successful passes',
    'icePlayer.episodes.UNSUCCESS_PASSES': 'Unsuccessful passes',
    'icePlayer.episodes.PENALTY': 'Penalty',
    'icePlayer.episodes.PLAYER_SHOTS_ON_GOAL': 'Shots on goal',
    'icePlayer.episodes.PLAYER_GOALS': 'Goals',
    'icePlayer.episodes.PLAYER_SCORE_ATTEMPTS': 'Shot Attempts',
    'icePlayer.episodes.PLAYER_SCORING_CHANCES': 'Scoring chances',
    'icePlayer.episodes.PLAYER_BLOCKED_SHOTS': 'Shots blocked',
    'icePlayer.episodes.PLAYER_SHOTS_WITH_REBOUND': 'Shots with rebound',
    'icePlayer.episodes.PLAYER_SHOTS_WITHOUT_REBOUND': 'Shots without rebound',
    'icePlayer.episodes.all': 'ALL',
    'icePlayer.episodes.SINGLE_GOALS': 'GOALS',
    'icePlayer.episodes.SINGLE_SHOTS_ON_GOAL': 'Shots on goal',
    'icePlayer.episodes.SINGLE_FACE_OFF': 'Faceoffs',

    'icePlayer.episodes.SINGLE_FACE_OFF_OFFENSIVE_ZONE': 'Faceoffs offensive zone',
    'icePlayer.episodes.SINGLE_FACE_OFF_DEFENSIVE_ZONE': 'Faceoffs defensive zone',
    'icePlayer.episodes.SINGLE_FACE_OFF_WON': 'Faceoff won',
    'icePlayer.episodes.SINGLE_FACE_OFF_LOST': 'Faceoff lost',

    'icePlayer.episodes.RECOVERIES': 'Recoveries',
    'icePlayer.episodes.RECOVERIES_OZ': 'Recoveries offensive zone',
    'icePlayer.episodes.RECOVERIES_DZ': 'Recoveries defensive zone',
    'icePlayer.episodes.RECOVERIES_NZ': 'Recoveries neutral zone',

    'icePlayer.episodes.REBOUND_RECOVERIES_OZ': 'Rebound recoveries offensive zone',
    'icePlayer.episodes.REBOUND_RECOVERIES_DZ': 'Rebound recoveries defensive zone',

    'icePlayer.episodes.PRIMARY_ASSIST': 'Primary assist',
    'icePlayer.episodes.SECONDARY_ASSIST': 'Secondary assist',

    'icePlayer.episodes.GOALS_AFTER_REBOUNDS': 'Goals after rebounds',

    'icePlayer.episodes.SHOTS_MISSED': 'Shots missed',
    'icePlayer.episodes.SHOTS_ATTEMPTS': 'Shots attempts',

    'icePlayer.episodes.SHOTS_BLOCKED': 'Shots blocked',

    'icePlayer.episodes.SAVES': 'Saves',
    'icePlayer.episodes.PTS': 'PTS',

    'icePlayer.episodes.PASS': 'Pass',
    'icePlayer.episodes.PLAYER_ASSISTS': 'Assists',

    'icePlayer.episodes.HITS': 'Hits',

    'icePlayer.episodes.SKATING_WITH_THE_PUCK': 'Skating with the puck',
    'icePlayer.episodes.SHOT_ATTEMPT_LOWER_LEFT': 'Shot attempt lower left',
    'icePlayer.episodes.RECEPTION': 'Reception',
    'icePlayer.episodes.SHOT_ATTEMPT_BLOCKED': 'Shot attempt blocked',
    'icePlayer.episodes.SHOT_ATTEMPT_WIDE_RIGHT': 'Shot attempt wide right',
    'icePlayer.episodes.SHOT_ATTEMPT_LOWER_RIGHT': 'Shot attempt lower right',

    'icePlayer.episodes.FROM_ATTACK_SHOTS': 'From offensive zone shots',
    'icePlayer.episodes.FROM_ATTACK_SHOTS_CONTROLLED_IN_AT_DELTA_TIME': 'Shots after controlled in',
    'icePlayer.episodes.FROM_ATTACK_SHOTS_RECOVERY_AT_DELTA_TIME': 'Shots after recovery',
    'icePlayer.episodes.FROM_ATTACK_SHOTS_AFTER_TAKEAWAY_NOT_ON_PP': 'Shots after takeaway',
    'icePlayer.episodes.FROM_ATTACK_SHOTS_AFTER_FACE_OFF': 'Shots after face off',
    'icePlayer.episodes.FROM_ATTACK_DRAWS_SHOTS': 'Shots after draws',

    'icePlayer.episodes.GAME_GOAL_EVENT': 'Goals',

    'icePlayer.episodes.PUCK_RECOVERIES': 'Puck Recoveries',

    'icePlayer.mediaDisabled': 'No file',

    'icePlayer.episodes.playlists': 'PLAYLISTS',
    'icePlayer.customPlaylists.selectAndAdd': 'Add to my playlist',
    'icePlayer.customPlaylists.addToMyPlaylist': 'Add to my playlist',
    'icePlayer.customPlaylists.addBatch': 'ADD BATCH TO',
    'icePlayer.customPlaylists.addTo': 'ADD TO',
    'icePlayer.customPlaylists.copyTo': 'COPY TO',
    'icePlayer.customPlaylists.moveTo': 'MOVE TO',
    'icePlayer.customPlaylists.createPlaylist': 'Create playlist',
    'icePlayer.customPlaylists.name': 'Playlist name',
    'icePlayer.customPlaylists.create': 'Create',
    'icePlayer.customPlaylists.share': 'Share',
    'icePlayer.customPlaylists.rename': 'Rename',
    'icePlayer.customPlaylists.delete': 'Delete',
    'icePlayer.customPlaylists.copy': 'Copy',
    'icePlayer.customPlaylists.move': 'Move',
    'icePlayer.customPlaylists.add': 'Add',
    'icePlayer.customPlaylists.downloading': 'Downloading',
    'icePlayer.customPlaylists.download': 'Download',
    'icePlayer.customPlaylists.save': 'SAVE',
    'icePlayer.customPlaylists.areYouSure': 'Are you sure?',
    'icePlayer.customPlaylists.selected': 'Selected',

    'icePlayer.videoGrid.loadMore': 'Load more',

    'icePlayer.video.archived': 'The video is being processed and will be available soon. Please wait.',
    'icePlayer.video.notDone': 'Sorry. The video is being processed. Please wait.',

    'player.downloadPopup.title': 'Download video file',

    'player.downloadPopup.emailSwitch': 'Send a video link on your email?',

    'player.downloadPopup.inputEmailButton': 'Input email',
    'player.downloadPopup.inputEmailButtonHide': 'Without email',

    'player.downloadPopup.emailIsCorrect': 'Correct',
    'player.downloadPopup.emailIsInCorrect': 'Incorrect',

    'player.downloadPopup.leaveEmail':
        'Please leave you email address and we will email you the link to download or start downloading without email.',
    'player.downloadPopup.loadingWasStart':
        'Download has just started. If you chose sending the link to your email, please check your mailbox in a couple of minutes where you will find the message with the link. Make sure to check both inbox and spam folder.',

    /***********************************************Components*************************************************************/
    //sign-up
    'signUp.title': 'Player sign up',
    'signUp.button': 'Create an account',
    'signUp.firstName': 'First name',
    'signUp.lastName': 'Last name',
    'signUp.email': 'Email',
    'signUp.password': 'Password',
    'signUp.haveAnAccount': 'Have an account?',
    'signUp.loginHere': 'Login here',
    'signUp.BySingingUpYouAgreeToOur': 'By singing up, you agree to our',
    'signUp.termsAndConditions': 'Terms and conditions',
    'signUp.error': 'Server error',
    'signUp.emailExists': 'Email exists',
    // login
    'login.title': 'Sign in',
    'login.button': 'Login',
    'login.error': 'Incorrect login or password',
    'login.username': 'Username',
    'login.email': 'Email',
    'login.password': 'Password',
    'login.password-confirmation': 'Confirm password',
    'login.remember': 'Remember me',

    'login.attempts': 'Attempts left before the temporary blocking occurs: {attemptCount}.',
    'login.waitSec': 'Until the end of the temporary blocking of authorization attempts, please wait {waitSec} s. .',

    'resetPassword.title': 'Reset password',
    'resetPassword.login': 'Login (email)',
    'resetPassword.button': 'Reset',
    'resetPassword.emailNotification': 'A password reset email has been sent to your mail',
    'resetPassword.successNotification': 'Password has been successfully changed ',
    'resetPassword.newPassword': 'New password',
    //confirmation
    'confirmation.thankYou': 'Thank you!',
    'confirmation.checkEmail': 'Please check your email for a link to confirm your account.',
    'confirmation.resend': 'Resend confirmation email',
    'confirmation.checkSpam':
        'Please check your spam folder, your "All mail" or the Promotions tab. If you can\'t find the email, please contact us at',

    //password-update
    'password-update.title': 'Create your password!',
    'password-update.error': 'The link has expired. Please request a reset from your Account Executive.',
    'password-update.create': 'Create',

    //dashboard
    home: 'Home',
    cabinet: 'Cabinet',
    'cabinet.myPage': 'My page',
    'cabinet.myOrders': 'My orders',
    stats: 'Stats',
    shifts: 'Shifts',
    goalies: 'Goalies',
    dashboard: 'Analytics',
    'dashboard.summary': 'Summary',
    'dashboard.skaters': 'Skaters',
    'dashboard.powerPlay': 'Power play',
    'dashboard.penaltyKill': 'Penalty kill',
    'dashboard.statistics': 'Statistics',
    'dashboard.deprecated': 'Old version',
    'dashboard.gameScore': 'Game score',
    'dashboard.configurator': 'Configurator',
    'dashboard.playersPage': 'Players page',
    'dashboard.collapse': 'Collapse sidebar',
    'dashboard.schedule': 'Schedule',
    'dashboard.conservator': 'Playlists',
    'dashboard.raw': 'Raw video',
    'dashboard.team': 'Team',
    'dashboard.prescout': 'Pre-scout',
    'dashboard.superLite': 'Super-lite',
    'dashboard.playersComparison': 'Players comparison',
    'dashboard.scout': 'Scout',
    'dashboard.scout.teamSelect': 'Teams',
    'dashboard.customPlaylists': 'Custom playlists',
    playerSelect: 'Select Player',
    'dashboard.players': 'Players',
    'dashboard.favorites': 'Favorites',
    'dashboard.reports': 'Reports',
    'dashboard.playlists': 'Playlists',
    //widgets
    'widget.viewDetails': 'View details',
    //event-list
    'eventList.goals': 'Goals',
    'eventList.scoreAttempts': 'Shot attempts',
    'eventList.carriesIn': 'Carry in',
    'eventList.dumpsIn': 'Dump in',
    'eventList.carriesOut': 'Carry out',
    'eventList.dumpsOut': 'Dump out',
    'eventList.scoringChances': 'Scoring chances',
    'eventList.blockedShots': 'Shots blocked',
    'eventList.passes': 'Passes',
    'eventList.recovery': 'Recoveries',
    'eventList.takeaway': 'Interceptions',
    'eventList.giveaway': 'Giveaways',
    'eventList.faceoff': 'Faceoffs',
    'eventList.shotOnGoal': 'Shots on goal',
    'eventList.shifts': 'Shifts',
    'eventList.rush': 'Rush',
    'eventList.regroup': 'Regroup',
    'eventList.oziformation': 'Ozone In Formation',
    'eventList.ozoformation': 'Ozone Out of Formation',
    'eventList.pp': 'Power play',
    //breadCrumbs
    'breadCrumbs.versus': ' VS ',
    'components.notFound.oops': 'Oops!',
    'components.notFound.errorMessage': "The page you are looking for can't be found.",
    'components.notImplemented.errorMessage': 'The page in development.',
    'components.licenseDeclined.title': 'You have declined the "terms of agreement"',
    'components.licenseDeclined.errorMessage': 'Please contact your manager to discuss details of the agreement.',
    'components.badLink.title': 'Bad link',
    'components.badLink.errorMessage': 'Check the link is correct please or contact to support.',
    'components.noGames.errorMessage': 'Looks like you do not have any games yet.',
    'components.noGames.info': 'Please, schedule your games on the scheduling page.',
    'components.notFound.errorCode': 'Error code: 404',
    'components.error.errorMessage': 'Something went wrong.',
    'components.error.info': 'Try to refresh the page.',
    'components.error.reloadPage': 'Refresh',
    'components.updateFromExcel': 'Refresh from excel-file',

    'components.notFilledInfo.playlists': 'You have not added any events to your custom playlists yet.',
    'components.notFilledInfo.favoritePlayers': 'You have not added any player to your favorites yet.',

    eliteProspectsId: 'Elite Prospect ID',
    'eliteProspectsId.additionalParameters': 'Parameters',

    'measures.m': 'm',
    'measures.km': 'km',
    'measures.mPerSec': 'm/s',
    'measures.mPerSec2': 'm/s²',
    'measures.ft': 'ft',
    'measures.mile': 'mi',
    'measures.mph': 'mph',
    'measures.kmPerH': ' km/h',
    'promptButton.apply': 'Apply',

    /******************************************************Containers******************************************************/
    //filter
    'filter.lastGame': 'Last game',
    'filter.add': 'Add',
    'filter.clear': 'Clear',
    'filter.apply': 'Apply',
    'filter.selectAll': 'SELECT ALL',
    'filter.deselectAll': 'DESELECT',
    'filter.search.placeholder': 'Type here',
    'filter.periods.one': '{period} period',
    'filter.periods.many': '{periods} periods',
    'filter.periods.overtime': '{period} OT',
    'filter.strength.ev': 'Even strength',
    'filter.strength.pk': 'Penalty kill',
    'filter.strength.pp': 'Power play',
    'filter.strength.st': 'Special teams',
    'filter.strength.en': 'Empty net',
    'filter.strength.enTeam': 'Empty net (team)',
    'filter.strength.enOpponent': 'Empty net (opponent)',
    'filter.strength.mix': '{strengths, plural, =1 {# strength} other {# strengths}}',
    'filter.seasons.many': '{seasons} seasons',
    'filter.seasons.all': 'all seasons',
    'filter.teams.many': '{teams} teams',
    'filter.teams.all': 'All teams',
    'filter.games.all': 'All games',
    'filter.teams.presentation': '{nameEn} ({acronymEn})',
    'filter.games.date': '{date, date, shortUTC}',
    'filter.games.empty': 'No games available',
    'filter.games.mixed': '{available} Games (of {total})',
    'filter.games.scoutGame': '{homeTeamNameEn}-{awayTeamNameEn}',
    'filter.games.scoutGame.teamsName': '{homeFullNameEn}-{awayFullNameEn}',
    'filter.games.teamStatus': ' { isHome, select, true {vs} other {@} } ',
    'filter.games.opponentName': '{acronymEn} {teamScore}-{opponentScore}',
    'filter.teamStatus': '{ teamStatus, select, TEAM {My team} OPPONENT {Opposite team} other {All teams} }',
    'filter.games.one': '{acronymEn}',
    'filter.games.status': '{ isHome, select, true {H} other {A} }{year}',
    'filter.games.many': '{games} games',
    'filter.scoreAggregationType': 'Score type',
    'filter.games.selectAll': 'SELECT ALL',
    'filter.games.deselect': 'DESELECT',
    'filter.position.D': 'Defense',
    'filter.position.F': 'Forward',
    'filter.position.G': 'Goaltender',
    'filter.position.not.D': 'Not Defense',
    'filter.position.not.F': 'Not Forward',
    'filter.position.not.G': 'Not Goaltenders',
    'filter.position.all': 'All positions',
    'filter.success.success': 'Successful',
    'filter.success.unsuccess': 'Failed',
    'filter.success.all': 'All results',
    'filter.entryType.pass': 'Pass',
    'filter.entryType.dump': 'Dump',
    'filter.entryType.carry': 'Carry',
    'filter.entryType.notPass': 'Not Pass',
    'filter.entryType.notDump': 'Not Dump',
    'filter.entryType.notCarry': 'Not Carry',
    'filter.entryType.all': 'All Types',
    'filter.shoots.all': 'All Shoots',
    'filter.possession.offensive': 'Offensive',
    'filter.possession.neutral': 'Neutral',
    'filter.possession.defensive': 'Defensive',
    'filter.possession.notOffensive': 'Not offensive',
    'filter.possession.notNeutral': 'Not neutral',
    'filter.possession.notDefensive': 'Not defensive',
    'filter.possession.all': 'All zones',
    'filter.pp.many': '{pps} pps',
    'filter.pk.many': '{pks} pks',
    'filter.leagues.many': '{leagues} leagues',
    'filter.leagues.all': 'all leagues',
    'filter.players.many': '{players} players',
    'filter.country.all': 'All Nations',

    'filter.position.lf': 'LF',
    'filter.position.cf': 'CF',
    'filter.position.rf': 'RF',

    'filter.position.lrf': 'LRF',
    'filter.position.clf': 'CLF',
    'filter.position.crf': 'CRF',
    'filter.position.clrf': 'CLRF',

    'filter.position.d': 'D',
    'filter.position.g': 'G',

    'filter.position.f': 'Forwards',

    'filter.position.cr': 'CRF',
    'filter.position.cl': 'CLF',
    'filter.position.l': 'LF',
    'filter.position.r': 'RF',
    'filter.position.lr': 'LRF',
    'filter.position.clr': 'CLRF',
    'filter.position.c': 'CF',

    'filter.position.defensemen': 'Defensemens',
    'filter.position.forwards': 'Forwards',
    'filter.position.goalies': 'Goalies',

    'filter.faceOffMapPlayers.all': 'All players',

    // app-container
    'appContainer.supportRequest': 'Support request',
    'appContainer.supportRequest.reason': 'Subject',
    'appContainer.supportRequest.reason.placeholder': 'Please select the subject',
    'appContainer.supportRequest.email': 'Contact email',
    'appContainer.supportRequest.submit': 'SUBMIT',
    'appContainer.supportRequest.success.p1': 'Thanks for your request.',
    'appContainer.supportRequest.success.p2': 'We will contact you soon via email.',
    'appContainer.supportRequest.dataVideoIssue': 'An issue with game data / video',
    'appContainer.supportRequest.dataVideoIssue.description':
        'Please provide game information and detailed issue description below',
    'appContainer.supportRequest.missingGamesIssue': 'Some games are missing',
    'appContainer.supportRequest.missingGamesIssue.description': 'Please provide missing games information below',
    'appContainer.supportRequest.logInIssue': 'Can not log in into the portal',
    'appContainer.supportRequest.logInIssue.description': 'Please provide your login information below',
    'appContainer.supportRequest.verificationRequest': 'Data verification request',
    'appContainer.supportRequest.verificationRequest.description':
        'Please provide game information and detailed issue description below',
    'appContainer.supportRequest.financialIssue': 'Financial issue',
    'appContainer.supportRequest.financialIssue.description': 'Please provide your invoice number below',
    'appContainer.supportRequest.hardwareSystemIssue': 'An issue with TPE team hardware system',
    'appContainer.supportRequest.hardwareSystemIssue.description':
        'Please provide the issue description and link to a photo (if possible) below',
    'appContainer.supportRequest.other': 'Other',
    'appContainer.supportRequest.other.description': 'Please provide detailed issue description below',

    'appContainer.logout': 'Log out',
    'appContainer.dashboard': 'Analytics',
    'appContainer.scouting': 'Scouting',
    'appContainer.scoutAdmin': 'Scout admin',
    'appContainer.clientsAdmin': 'Clients admin',
    'appContainer.scheduleAdmin': 'Schedule admin',
    'appContainer.players': 'Players',
    'appContainer.service': 'Service',
    'appContainer.whatsNew': "What's new",
    'appContainer.teams': 'Teams',
    'appContainer.glossary': 'Glossary',
    'appContainer.profile.signOut': 'Sign out',
    'appContainer.profile.email': 'Email',
    'appContainer.profile.units': 'Units',
    'appContainer.profile.mode': 'Theme',
    'appContainer.profile.ratingSystem': 'Rating system',
    'appContainer.profile.manageFavorites.Favorites': 'Favorites',
    'appContainer.profile.manageFavorites.manage': 'Manage',

    'appContainer.profile.evaluation': 'Evaluation',
    'appContainer.profile.customization': 'Customization',
    'appContainer.profile.ratingSystem.FIVE_POINT': 'five-points',
    'appContainer.profile.ratingSystem.TEN_POINT': 'ten-points',
    'appContainer.profile.ratingSystem.HUNDRED_POINT': '100-points',
    'appContainer.profile.ratingSystem.LETTER': 'letter',
    'appContainer.profile.addGame': 'Upload video',
    'appContainer.toggleRadio.METRIC': 'Metric',
    'appContainer.toggleRadio.IMPERIAL': 'Imperial',
    'appContainer.toggleRadio.classic': 'Classic',
    'appContainer.toggleRadio.darcula': 'Night',
    'appContainer.footer.service': 'Glossary & videos',
    'appContainer.profile.password': 'Password',
    'appContainer.profile.password.change': 'Change',
    //match-switcher
    'matchSwitcher.january': 'January ',
    'matchSwitcher.february': 'February ',
    'matchSwitcher.march': 'March ',
    'matchSwitcher.april': 'April ',
    'matchSwitcher.may': 'May ',
    'matchSwitcher.june': 'June ',
    'matchSwitcher.july': 'July ',
    'matchSwitcher.august': 'August ',
    'matchSwitcher.september': 'September ',
    'matchSwitcher.october': 'October ',
    'matchSwitcher.november': 'November ',
    'matchSwitcher.december': 'December ',
    //tooltip
    'tooltip.video': 'Video',
    //upload-client-game-popup
    'uploadClientGamePopup.title': 'Upload video',
    'uploadClientGamePopup.gameDate': 'Date',
    'uploadClientGamePopup.homeTeam': 'Home Team',
    'uploadClientGamePopup.awayTeam': 'Away Team',
    'uploadClientGamePopup.dragHere':
        'Enter the date, home team, and away team in the top section. Then upload both VIDEO and GAMESHEET files by dragging and dropping in the box. Both files can be uploaded together, and should only be uploaded once.',
    'uploadClientGamePopup.filesToUpload': 'FILES TO UPLOAD',
    'uploadClientGamePopup.filesToUploadPlaceholder': 'Video files can be in any format.',
    'uploadClientGamePopup.uploading': 'UPLOADING...',
    'uploadClientGamePopup.success': 'FILES UPLOADED SUCCESSFULLY',
    'uploadClientGamePopup.error': 'COULD NOT UPLOAD FILES, PLEASE TRY AGAIN LATER',
    'uploadClientGamePopup.upload': 'Upload',
    'uploadClientGamePopup.cancelUpload': 'Cancel upload',
    'uploadClientGamePopup.uploadMore': 'Upload more',

    /***************************************************************Widgets************************************************/
    'shotMap.xG': 'xG',
    'shotMap.moment': 'Moment',
    'shotMap.time': 'Time',
    'shotMap.shotAttempts': 'Shots: ',
    'shotMap.scoringChances': 'Scoring chances: ',
    'shotMap.firstPeriod': '1ST PERIOD',
    'shotMap.secondPeriod': '2ND PERIOD',
    'shotMap.thirdPeriod': '3RD PERIOD',
    'shotMap.somePeriod': '1ST OT',
    'shotMap.lowerRight': 'Lower right',
    'shotMap.upperLeft': 'Upper left',
    'shotMap.upperMiddle': 'Upper middle',
    'shotMap.upperRight': 'Upper right',
    'shotMap.lowerLeft': 'Lower left',
    'shotMap.lowerMiddle': 'Lower middle',
    'shotMap.blocked': 'Blocked',
    'shotMap.deflected': 'Deflected',
    'shotMap.overTheNet': 'Over the net',
    'shotMap.wideRight': 'Wide right',
    'shotMap.wideMiddle': 'Wide middle',
    'shotMap.wideLeft': 'Wide left',
    'shotMap.goal': 'Goal',
    'shotMap.shotOnGoal': 'Shot on goal',
    'shotMap.scoreAttempt': 'Shot attempt',
    'shotMap.strength': 'Strength',
    'shotMap.goalkeeper': 'Goalkeeper',
    'shotMap.pulled': 'Pulled',
    'shotMap.shotSpeed': 'Speed',
    'shotMap.MPH': 'm/s',
    'shotMap.game': 'Game',
    'shotMap.title': 'Shot map',
    'shotMap.location': 'Shot location',
    'shotMap.heatMap': 'Heat map',
    'iceRinkChart.zones.DZ': 'DZ',
    'iceRinkChart.zones.OZ': 'OZ',
    'iceRinkChart.zones.summaryTitle': 'TOTAL',
    'score.OT': 'OT',
    'score.SO': 'SO',
    'score.opponent': 'Opponents',

    /***************************************************************Pages**************************************************/
    'noGamesPage.title': 'NO GAMES TO DISPLAY',
    'home.gameScore.preseason': 'Preseason',
    'home.gameInfo.postGameReport': 'Short Report',
    'home.pastGames': 'Past games',
    'home.restGames': 'Other games',
    'home.favoriteGames': 'Favorite games',
    'home.evenStrengthXg': 'EV xG:',
    'home.specialTeamsXg': 'ST xG:',
    'summary.summary': 'Summary',
    'summary.versus': ' VS ',
    'summary.gameStatistics': 'Game Statistics',
    'summary.score': 'Score',
    'summary.gameOverview': 'Game overview',
    'summary.xGMap': 'xG Map',
    'summary.xGTimeline': 'xG Timeline',
    'summary.skaters': 'Three stars',
    'summary.animation': 'Animation',
    'summary.video': 'Video',
    'summary.playList': 'Playlist',
    'summary.infobar.goals': 'Goals',
    'summary.infobar.xg': 'xG',
    'summary.infobar.shotAttempts': 'Shot attempts',
    'summary.infobar.faceoffs': 'Faceoffs',
    'summary.infobar.scoringChances': 'Scoring chances',
    'summary.infobar.shotsOnGoal': 'Shots  on goal',
    'summary.infobar.possession': 'Puck possession',
    'summary.infobar.interceptions': 'Interceptions',
    'summary.infobar.savePerc': 'Saves',
    'summary.infobar.controlledExits': 'Controlled exits',
    'summary.infobar.controlledEntries': 'Controlled entries',
    'summary.infobar.less': '%\u00A0less',
    'summary.infobar.more': '%\u00A0more',
    'summary.infobar.then': ' then last match',
    'summary.infobar.for': 'for',
    'summary.infobar.against': 'against',
    'summary.infobar.puckTimeByZone': 'Puck time in OZ',
    'summary.infobar.goalsFor': 'Goals for',
    'summary.infobar.goalsAgainst': 'Goals against',
    'summary.infobar.xgFor': 'XG for',
    'summary.infobar.xgAgainst': 'XG against',
    'summary.infobar.shotsOnGoalFor': 'Shots On Goal for',
    'summary.infobar.shotsOnGoalAgainst': 'Shots On Goal against',
    'summary.infobar.puckTimeByZoneOffensive': 'Puck time OZ',
    'summary.infobar.puckTimeByZoneDefensive': 'Puck time DZ',
    'summary.infobar.puckTimeByZoneNeutral': 'Puck time NZ',
    'summary.infobar.shotAttemptsFor': 'Shot Attempts for',
    'summary.infobar.shotAttemptsAgainst': 'Shot Attempts against',
    'summary.infobar.faceoffsFor': 'Faceoffs for',
    'summary.infobar.faceoffsAgainst': 'Faceoffs against',
    'summary.infobar.scoringChancesFor': 'Scoring Chances for',
    'summary.infobar.scoringChancesAgainst': 'Scoring Chances against',
    'summary.infobar.savePercFor': 'SavePerc for',
    'summary.infobar.savePercAgainst': 'SavePerc against',
    'summary.infobar.controlledEntriesFor': 'Controlled Entries for',
    'summary.infobar.controlledEntriesAgainst': 'Controlled Entries against',
    'summary.infobar.controlledExitsFor': 'Controlled Exits for',
    'summary.infobar.controlledExitsAgainst': 'Controlled Exits against',
    'summary.infobar.puckPossessionsFor': 'Puck Possessions for',
    'summary.infobar.puckPossessionsAgainst': 'Puck Possessions against',
    'summary.score.goals': 'goals',
    'summary.score.shotAttempts': 'shot attempts',
    'summary.score.shotsOnGoal': 'shots on goal',
    'summary.score.bodyChecks': 'body checks',
    'summary.score.passing': '%\u00A0passing',
    'summary.score.timeOfPossessions': 'time of possession',
    'summary.score.distance': 'Distance,',
    'summary.score.hits': 'Hits',
    'summary.score.recoveries': 'Puck recoveries',
    'summary.score.evXg': 'EV xG:',
    'summary.score.stXg': 'ST xG:',
    'summary.score.reportPostgame': 'Postgame report',
    'summary.score.downloadRawData': 'Download raw data',
    'summary.score.scoringChancesPecentage': 'Scoring chances %',
    'summary.score.shotsAccuracy': 'Shots accuracy %',
    'summary.xGTimeline.shotAttempts': 'Shots: ',
    'summary.xGTimeline.scoringChances': 'Scoring chances: ',
    'summary.xGTimeline.firstPeriod': '1ST PERIOD',
    'summary.xGTimeline.secondPeriod': '2ND PERIOD',
    'summary.xGTimeline.thirdPeriod': '3RD PERIOD',
    'summary.xGTimeline.lowerRight': 'Lower right',
    'summary.xGTimeline.upperLeft': 'Upper left',
    'summary.xGTimeline.upperMiddle': 'Upper middle',
    'summary.xGTimeline.upperRight': 'Upper right',
    'summary.xGTimeline.lowerLeft': 'Lower left',
    'summary.xGTimeline.lowerMiddle': 'Lower middle',
    'summary.xGTimeline.blocked': 'Blocked',
    'summary.xGTimeline.deflected': 'Deflected',
    'summary.xGTimeline.overTheNet': 'Over the net',
    'summary.xGTimeline.wideRight': 'Wide right',
    'summary.xGTimeline.wideMiddle': 'Wide middle',
    'summary.xGTimeline.wideLeft': 'Wide left',
    'summary.xGTimeline.goal': 'Goal',
    'summary.xGTimeline.shotOnGoal': 'Shot on goal',
    'summary.xGTimeline.scoreAttempt': 'Score attempt',
    'summary.xGTimeline.teamXG': 'Team xG',
    'summary.xGTimeline.moment': 'Moment',
    'summary.xGTimeline.time': 'Time',
    'summary.xGTimeline.overtime': 'OT',
    'summary.skaters.onIceXG': 'On Ice xG',
    'summary.skaters.totalXG': 'Total xG',
    'summary.skaters.gameScore': 'Game score',
    'summary.skaters.OZoneTimeOnIce': 'Puck time in OZ',
    'summary.skaters.shotAttempts': 'Shot attempts',
    'summary.skaters.potentialAssists': 'Potential assists',
    'summary.skaters.takeaways': 'Interceptions',
    'summary.skaters.save': 'Save',
    'summary.skaters.rebound': 'Rebound',
    'summary.skaters.recoveries': 'Recoveries',
    'summary.skaters.shotAngle': 'Shot angle',
    'summary.skaters.goalsAGST': 'Goals agst',
    'summary.skaters.xgAGST': 'xG agst',
    'skaters.efficiency': 'Efficiency',
    'skaters.compareLines': 'Lines comparison',
    'skaters.xgTopSkaters': 'Top skaters by xG',
    'skaters.efficiency.timeOnIce': 'Time on Ice',
    'skaters.efficiency.shotsFor': 'Shots for',
    'skaters.efficiency.shotsAgainst': 'Shots against',
    'skaters.efficiency.dull': 'EVENTLESS',
    'skaters.efficiency.good': 'GOOD',
    'skaters.efficiency.fun': 'EVENTFUL',
    'skaters.efficiency.bad': 'BAD',
    'skaters.compareLines.line': 'LINE',
    'skaters.compareLines.xG': 'xG\u00A0%',
    'skaters.compareLines.forwards': 'FORWARDS',
    'skaters.compareLines.defenders': 'DEFENSEMEN',
    'skaters.compareLines.pair': 'PAIR',
    'skaters.compareLines.combinationC': 'COMBINATION',
    'skaters.compareLines.L': 'L',
    'skaters.compareLines.P': 'P',
    'skaters.compareLines.combination': 'Combination',
    'skaters.compareLines.toi': {
        message: 'Time on Ice',
        title: 'Total time on ice played by line'
    },
    'skaters.compareLines.possessionTime': {
        message: 'Possession %',
        title: 'Percentage of team possession when line was on the ice'
    },
    'skaters.compareLines.possessionTimeFor': 'Possession for',
    'skaters.compareLines.possessionTimeAgainst': 'Possession against',
    'skaters.compareLines.OZpuckTime': {
        message: 'Puck time in OZ %',
        title: 'Percentage of time when the puck was in the offensive zone while the line was on the ice'
    },
    'skaters.compareLines.puckTimeOZFor': 'Puck time in OZ',
    'skaters.compareLines.puckTimeOZAgainst': 'Puck time in DZ',
    'skaters.compareLines.onIceXgRelativeFor': 'xG for',
    'skaters.compareLines.onIceXgRelativeAgainst': 'xG against',
    'skaters.compareLines.xgFor': 'xG for',
    'skaters.compareLines.xgAgainst': 'xG against',
    'skaters.compareLines.infobar': 'Statistics',
    'skaters.compareLines.table': 'Table',
    'skaters.compareLines.F': 'L',
    'skaters.compareLines.D': 'P',
    'skaters.compareLines.onIceXg': {
        message: 'xG\u00A0%',
        title: 'Team xG divided by sum of the team xG and opponent xG'
    },
    'skaters.compareLines.corsi': {
        message: 'Shot attempts\u00A0%',
        title: 'Ratio of shot attempts made by team to the total shots, while the line was on the ice'
    },
    'skaters.compareLines.gameScore': {
        message: 'Game score',
        title: 'Game score of player'
    },
    'skaters.compareLines.shotsFor': 'Shots for',
    'skaters.compareLines.shotsAgainst': 'Shots against',
    'skaters.compareLines.toiTotal': 'Total TOI',
    'skaters.compareLines.possessionFor': 'Possession For',
    'skaters.compareLines.possessionAgainst': 'Possession Against',
    'skaters.compareLines.gameTime': 'Game time',
    'skaters.xGTopSkaters.timeOnIce': {
        message: 'Time on ice',
        title: 'Total time on ice played by player'
    },
    'skaters.xGTopSkaters.possession': {
        message: 'Possession\u00A0%',
        title: 'Possession percentage while player was on the ice'
    },
    'skaters.xGTopSkaters.puckTimeInOZ': {
        message: 'Puck time in OZ\u00A0%',
        title: 'Average puck time percentage in OZ while the player was on the ice'
    },
    'skaters.xGTopSkaters.corsi': {
        message: 'Shot attempts\u00A0%',
        title: 'Ratio of shot attempts made by team to the total shots, while the player was on the ice'
    },
    'skaters.xGTopSkaters.scoringChances': {
        message: 'Scoring Chances\u00A0%',
        title: 'Ratio of scoring chances made by team to the total scoring chances, while the player was on the ice'
    },
    'skaters.xGTopSkaters.onIcexG': {
        message: 'xG\u00A0%',
        title: 'Ratio of expected goals made by team to the total expected goals while the player was on the ice'
    },
    'skaters.xGTopSkaters.gameScore': {
        message: 'Game Score',
        title:
            "The probability that the player's team will win on condition of his position, his time on the ice and his offensive and defensive actions during this time"
    },
    'skaters.xGTopSkaters.totalxG': 'Total xG',
    'skaters.xGTopSkaters.shots': 'Shots',
    'skaters.xGTopSkaters.recoveries': 'Recoveries',
    'skaters.xGTopSkaters.skaters': 'SKATERS',
    'skaters.xGTopSkaters.skatersAll': 'ALL SKATERS',
    'skaters.xGTopSkaters.infobar': 'Table average',
    'skaters.xGTopSkaters.table': 'Players table',
    'skaters.xGTopSkaters.possessionTimeFor': 'Possession for',
    'skaters.xGTopSkaters.puckTimeOZFor': 'Puck time in OZ',
    'skaters.xGTopSkaters.shotsFor': 'Shots for',
    'skaters.xGTopSkaters.scoringChanceFor': 'Scoring chances for',
    'skaters.xGTopSkaters.onIceXgRelativeFor': 'On ice xG for',
    'skaters.xGTopSkaters.possessionTimeAgainst': 'Possession against',
    'skaters.xGTopSkaters.puckTimeOZAgainst': 'Puck time in DZ',
    'skaters.xGTopSkaters.shotsAgainst': 'Shots against',
    'skaters.xGTopSkaters.scoringChanceAgainst': 'Scoring chances against',
    'skaters.xGTopSkaters.onIceXgRelativeAgainst': 'On ice xG against',
    'skaters.xGTopSkaters.toi': 'Total TOI',
    'skaters.xGTopSkaters.possessionFor': 'Possession for',
    'skaters.xGTopSkaters.possessionAgainst': 'Possession against',
    'skaters.xGTopSkaters.faceoffs': {
        message: 'Zone starts',
        title: 'Offensive zone starts divided by the total starts in offensive and defensive zones'
    },
    'skaters.xgTopSkaters.dzFaceOffs': 'Defensive zone',
    'skaters.xgTopSkaters.nzFaceOffs': 'Neutral zone',
    'skaters.xgTopSkaters.ozFaceOffs': 'Offensive zone',
    'skaters.xGTopSkaters.gameTime': 'Game time',
    'powerPlay.infobar': 'Table sum / average',
    'powerPlay.linesTable': 'Power play lines',
    'powerPlay.donutChart': 'Power play states',
    'powerPlay.skatersTable': 'Players',
    'powerPlay.shotMap': 'Shot Map',
    'powerPlay.info.totalPPTime': 'Total PP time',
    'powerPlay.info.ozFormationTime': 'OZ Formation time',
    'powerPlay.info.shotsOnGoal': 'Shots on Goal',
    'powerPlay.info.ozEntries': 'OZ Entries',
    'powerPlay.info.reboundRecoveries': 'Rebound Recoveries',
    'powerPlay.info.faceOff%': 'Faceoff won,\u00A0%',
    'powerPlay.info.faceOffWon': 'Faceoff won',
    'powerPlay.info.faceOffLost': 'Faceoff lost',
    'powerPlay.info.ppPercentage': 'PP%',
    'powerPlay.players.toi': {
        message: 'Time on ice',
        title: 'Total time played in powerplay by players'
    },
    'powerPlay.players.ozTime': {
        message: 'OZ time',
        title: 'Puck time in offensive zone while the player was on the ice'
    },
    'powerPlay.players.ozfPerOz': {
        message: 'OZF time of OZ time,\u00A0%',
        title: 'Percentage of time in formation attack from the total time in attack while the player was on the ice'
    },
    'powerPlay.players.shots': {
        message: 'Shots',
        title: 'Sum of shot attempts made by team during power play time while the player was on the ice'
    },
    'powerPlay.players.scoringChances': {
        message: 'Scoring chances',
        title: 'Sum of scoring chances made by team during power play time while player was on the ice'
    },
    'powerPlay.players.scoringChancesPerShots': 'Scoring chances / shots,\u00A0%',
    'powerPlay.players.reboundRecoveries': 'Rebound recoveries',
    'powerPlay.players.successZoneEntries': 'Succ. zone entries',
    'powerPlay.players.GF': 'GF',
    'powerPlay.players.potentialAssists': 'Potential assists',
    'powerPlay.players.giveawaysPercent': 'Giveaways,\u00A0%',
    'powerPlay.players.avgRushSpeed': 'Avg. rush speed,\u00A0',
    'powerPlay.players.giveaways': 'Giveaways',
    'powerPlay.players.possessionCount': 'Possessions',
    'powerPlay.players.ozFormationTime': 'OZ formation time',
    'powerPlay.players.ozOnIceTime': 'OZ time',
    'powerPlay.lines.lines': 'Line',
    'powerPlay.lines.shots': {
        message: 'Shots',
        title: 'Sum of shot attempts made by team during power play time while the line was on the ice'
    },
    'powerPlay.lines.shotsOnGoal': 'Shots on goal',
    'powerPlay.lines.GF': 'GF',
    'powerPlay.lines.shotsBlocked': 'Shots blocked',
    'powerPlay.lines.shotsMissed': 'Shots missed',
    'powerPlay.lines.scoringChances': {
        message: 'Scoring chances',
        title: 'Sum of scoring chances made by team during power play time while line was on the ice'
    },
    'powerPlay.lines.ozfTime': 'OZ formation time',
    'powerPlay.lines.ozTime': {
        message: 'OZ time',
        title: 'Puck time in offensive zone while the line was on the ice'
    },
    'powerPlay.lines.ozfTimeOfOzTime': {
        message: 'OZF time of OZ time,\u00A0%',
        title: 'Percentage of time in formation attack from the total time in attack while the line was on the ice'
    },
    'powerPlay.lines.toi': {
        message: 'Time on ice',
        title: 'Total time played in powerplay by lines'
    },
    'powerPlay.lines.lineIndex': 'PP{index}',
    'powerPlay.chart.ppXG': 'PP XG',
    'powerPlay.chart.rush': 'RUSH',
    'powerPlay.chart.oziFormation': 'OZONE IN FORMATION',
    'powerPlay.chart.ozoFormation': 'OZONE OUT OF FORMATION',
    'powerPlay.chart.regrouping': 'REGROUP',
    'penaltyKill.chart.rushToOZConversion': 'RUSH TO OZ CONVERSION',
    'penaltyKill.chart.rushToRegroupConversion': 'RUSH TO RG CONVERSION',
    'penaltyKill.chart.rushToPKConversion': 'RUSH TO PK CONVERSION',
    'penaltyKill.chart.rushes': 'RUSHES',
    'penaltyKill.chart.entries': 'ENTRIES',
    'penaltyKill.chart.carries': 'CARRIES',
    'penaltyKill.chart.dumps': 'DUMPS',
    'statistics.entries.dumpsIn': 'Dump in',
    'statistics.entries.dumpsOut': 'Dump out',
    'penaltyKill.chart.passes': 'PASSES',
    'penaltyKill.info.totalPkTime': 'PK time',
    'penaltyKill.info.dzTimePercent': 'Time in DZ, %',
    'penaltyKill.info.regroupTimePerRush': 'Time in regroups per rushes',
    'penaltyKill.info.clearancesPerDzPossessions': 'Clearances, %',
    'penaltyKill.info.shotsPerPk': 'Shot attempts',
    'penaltyKill.info.pkPercentage': 'PK%',
    'penaltyKill.info.blockPercentage': 'Shots blocked, %',
    'penaltyKill.info.scoringChancesPerShots': 'SС / SA, %',
    'penaltyKill.info.faceOffPercentage': 'Faceoffs, %',
    'penaltyKill.info.faceOffWon': 'Faceoff won',
    'penaltyKill.info.faceOffLost': 'Faceoff lost',
    'penaltyKill.info.dzTime': 'Time in DZ',
    'penaltyKill.info.regroupTime': 'Regroup time',
    'penaltyKill.info.rushes': 'Rushes',
    'penaltyKill.info.clearances': 'Clearances',
    'penaltyKill.info.possessionsInDz': '# of poss in DZ',
    'penaltyKill.info.blockedShots': 'Blocked shots',
    'penaltyKill.info.shots': 'Shot attempts',
    'penaltyKill.info.scoringChances': 'Scoring chances',
    'penaltyKill.lines.lineIndex': 'PK{index}',
    'penaltyKill.lines.lines': 'Line',
    'penaltyKill.lines.toi': {
        message: 'TOI',
        title: 'Total amount of time the line was on the ice together'
    },
    'penaltyKill.lines.dzTime': {
        message: 'Time in DZ',
        title: 'Total amount of puck time in defensive zone by lines'
    },
    'penaltyKill.lines.dzFormationTimeOfDzTime': {
        message: 'Time in DZ, %',
        title: 'Percentage of time when the puck was in the defensive zone while the line was on the ice'
    },
    'penaltyKill.lines.dzfTime': 'Formation time in DZ',
    'penaltyKill.lines.crossIcePasses': {
        message: 'Cross passes',
        title: 'Total number of cross passes made by opposite team in defensive zone by lines'
    },
    'penaltyKill.lines.shotsOnGoal': {
        message: 'Shots on goal',
        title: 'Total number of shots on goal made by opposite team'
    },
    'penaltyKill.lines.shotsAgainst': {
        message: 'Shots against',
        title: 'Total number of shots against made by opposite team'
    },
    'penaltyKill.lines.GF': {
        message: 'GF',
        title: 'Goals scored while on the power play'
    },
    'penaltyKill.lines.scoringChances': {
        message: 'Scoring chances',
        title: 'Total number of scoring chances made by opposite team'
    },
    'penaltyKill.lines.GA': {
        message: 'GA',
        title: 'Goal scored Against the line combination and individual players'
    },
    'penaltyKill.lines.recoveryPercentage': {
        message: 'Rebound recovery, %',
        title: 'Percentage of recovered rebounds made by teammates to the total number of rebounds'
    },
    'penaltyKill.lines.recoveries': 'Rebound recovery',
    'penaltyKill.players.dzFormationOfDzTime': 'Formation time in DZ, %',
    'penaltyKill.players.dzFormationTime': 'Formation time in DZ',
    'penaltyKill.players.loosePuckRecoveries': 'Puck recoveries',
    'penaltyKill.players.reboundRecoveriesPersonal': 'Rebound recoveries',
    'penaltyKill.players.clearancesPerPossessions': 'Clearances, %',
    'penaltyKill.players.clearances': 'Clearances',
    'penaltyKill.players.dzPossessions': '# of poss in DZ',
    'penaltyKill.players.blockedShotsPerPotentiallyBlocked': 'Blocked shots, %',
    'penaltyKill.players.blockedShots': 'Blocked shots',
    'penaltyKill.players.potentiallyBlockedShots': 'Potentially blocked shots',
    'penaltyKill.players.avgDistanceToTargetInFormation': 'DZ gap, ',
    'penaltyKill.players.toi': {
        message: 'TOI',
        title: 'Total amount of time the player was on the ice in penalty kill'
    },
    'penaltyKill.players.dzTimePercentage': {
        message: 'Time in DZ, %',
        title: 'Percentage of time when the puck was in the defensive zone while the player was on the ice'
    },
    'penaltyKill.players.dzTime': 'Time in DZ ',
    'penaltyKill.players.dzfTimePercentage': {
        message: 'Formation time in DZ, %',
        title:
            'Percentage of time when the opposite team is in a positional attack in the offensive zone, and tactical construction is clearly distinguishable'
    },
    'penaltyKill.players.dzfTime': 'Formation time in DZ',
    'penaltyKill.players.shots': {
        message: 'Shots',
        title: 'Total number of shots made by opposite team while the player was on the ice'
    },
    'penaltyKill.players.scoringChances': {
        message: 'Scoring chances',
        title: 'Total scoring chances made by opposite team while the player was on the ice'
    },
    'penaltyKill.players.crossIcePasses': {
        message: 'Cross passes',
        title: 'Total cross passes made by opposite team in the defensive zone while the player was on the ice'
    },
    'penaltyKill.linesTable': 'Penalty kill table',
    'penaltyKill.donutChart': 'Attack types',
    'statistics.passing': 'Passing',
    'statistics.shooting': 'Shooting',
    'statistics.entries': 'Entries',
    'statistics.exits': 'Exits',
    'statistics.possession': 'Possession',
    'statistics.goalkeepers': 'Goaltenders',
    'statistics.skating': 'Skating',
    'statistics.title': 'Statistics',
    'statistics.shifts': 'Shifts',
    'statistics.playerComparison': 'Player comparison',
    'statistics.shooting.shotAttempts': {
        message: 'Shot attempts',
        title: 'Number of shot attempts which were missed, blocked, deflected, saved or resulted by goal'
    },
    'statistics.shooting.shotsOnGoal': {
        message: 'Shots on goal',
        title: 'Number of direct shots or deflections of the puck, which were saved or resulted by goal'
    },
    'statistics.shooting.shotsMissed': {
        message: 'Shots missed',
        title: 'Number of missed shots'
    },
    'statistics.shooting.blockedShots': {
        message: 'Shots blocked',
        title: 'Number of blocked shots'
    },
    'statistics.shooting.shooting': {
        message: 'Shooting,\u00A0%',
        title: 'Number of goals divided by the number of shots on goal'
    },
    'statistics.shooting.goals': {
        message: 'Goals',
        title: 'When the puck fully crosses the goal line'
    },
    'statistics.shooting.games': {
        message: 'Games',
        title: 'Number of games'
    },
    'statistics.shooting.toi': {
        message: 'Avg time on ice',
        title: "Average player's time on ice"
    },
    'statistics.shooting.scoringChances': {
        message: 'Scoring chances',
        title:
            'Moments that satisfy at least one of the conditions: 1. Shot attempt from the high danger zone; 2. Shot attempt after the potential assist, which crossed the middle line; 3. Repeated not blocked shot attempt'
    },
    'statistics.shooting.rebound': {
        message: 'Rebound,\u00A0%',
        title: 'Number of shots with a rebound divided by the number of shots on goal'
    },
    'statistics.shooting.avgShotSpeed': {
        message: 'Avg. shot speed, ',
        title: 'Average value for the metric "shot speed"'
    },
    'statistics.shooting.overall': 'Shots overall',
    'statistics.shooting.shotMaxSpeed': {
        message: 'Max. shot speed, ',
        title: 'Maximum value for the metric "shot speed"'
    },
    'statistics.shooting.infobarTitle': 'Table sum / average',
    'statistics.shooting.shotMap': 'Shot Map',
    'statistics.shooting.table': 'Shooting table',
    'statistics.shooting.avgShotAttempts': 'Avg. shot attempts',
    'statistics.shooting.avgShotsOnGoal': 'Avg. shots on goal',
    'statistics.shooting.avgScoringChances': 'Avg. scoring chances',
    'statistics.shooting.avgGoals': 'Avg. goals',
    'statistics.shooting.avgXg': 'Avg. xG',
    'statistics.shooting.reboundTooltip': 'Rebound',
    'statistics.shooting.timeOnIce': 'Time on Ice',
    'statistics.shooting.assists': 'Assists',
    'statistics.shooting.shotAccuracy': 'Shot accuracy %',
    'statistics.shooting.shotsFromDangerZone': 'Shots from danger zone',
    'statistics.shooting.shotsAfterRebounds': 'Shots after rebounds',
    'statistics.shooting.faceoffs': 'Faceoffs %',
    'statistics.shooting.penaltyMinutes': 'Penalty minutes',
    'statistics.shooting.xg': {
        message: 'xG',
        title:
            "The probability that the player's shot will be a goal, provided the characteristics of the shot (the distance to the goal, the angle of impact etc.), as well as the positions of the player shooting, his team and the opponent"
    },
    'statistics.goalkeepers.save': {
        message: 'Save,\u00A0%',
        title: 'Number of saves divided by total number of shots on goal'
    },
    'statistics.goalkeepers.rebound': {
        message: 'Rebound,\u00A0%',
        title: 'Percentage of shot attempts that were saved with rebound from the total number of shots on goal'
    },
    'statistics.goalkeepers.toi': {
        message: 'Time on Ice',
        title: "Total amount of time the goaltender's skates were on the ice during live action"
    },
    'statistics.goalkeepers.overall': 'Overall',
    'statistics.goalkeepers.gamesPlayed': {
        message: 'Games played',
        title: 'Number of games in which the player participated'
    },
    'statistics.goalkeepers.shotsOnGoal': {
        message: 'Shots on goal',
        title: 'Direct shot or deflection of the puck, that was saved or resulted by goal'
    },
    'statistics.goalkeepers.goals': {
        message: 'Goals',
        title: 'When the puck fully crosses the goal line'
    },
    'statistics.goalkeepers.scoringChances': {
        message: 'Scoring chances',
        title:
            'Moments that satisfy at least one of the conditions: 1. Shot attempt from the high danger zone; 2. Shot attempt after the potential assist, which crossed the middle line; 3. Repeated not blocked shot attempt'
    },
    'statistics.goalkeepers.scoringChancesSave': {
        message: 'Scoring Chance Save %',
        title: 'Scoring Chance Save %'
    },
    'statistics.goalkeepers.shotsOnGoalDangerZone': {
        message: 'Danger zone SOG',
        title: ''
    },
    'statistics.goalkeepers.avgShotDistance': {
        message: 'Avg. shot distance,',
        title: 'Average shot distance among the opposite team players'
    },
    'statistics.goalkeepers.avgAngleOfDeflection': {
        message: 'Avg. angle of deflection,\u00A0°',
        title: 'Angle between the center lane and where the puck went after the shot'
    },
    'statistics.goalkeepers.maxShotSpeed': {
        message: 'Max. shot speed,',
        title: 'Maximum metric for the value "shot speed" among the players of the opponent team'
    },
    'statistics.goalkeepers.shotsWithRebound': {
        message: 'Rebound shots',
        title: ''
    },
    'statistics.goalkeepers.leagueAverage': 'League average',
    'statistics.goalkeepers.infobar': 'Table sum / average',
    'statistics.goalkeepers.table': 'Goaltenders',
    'statistics.goalkeepers.gateChart': 'Goalie Chart',
    'statistics.goalkeepers.shotMap': 'Shot Map',
    'statistics.goalkeepers.zonesMap': 'Zones Map',
    'statistics.goalkeepers.rebounds': 'Rebounds',
    'statistics.goalkeepers.reboundsAgainst': 'Rebound shots against',
    'statistics.goalkeepers.shotOnGoalPerTime': 'Shot on goal per minute',
    'statistics.goalkeepers.shotOnGoalPerTimeDeflected': 'Deflected shot on goal per minute',
    'statistics.goalkeepers.goalsAllowed': 'Goals allowed',
    'statistics.goalkeepers.totalShotsOnGoal': 'Total shots on goal',
    'statistics.goalkeepers.totalGoals': 'Total goals',
    'statistics.passing.passes': 'Passes',
    'statistics.passing.successfulPasses': 'Successful passes',
    'statistics.passing.failedPasses': 'Failed passes',
    'statistics.passing.completedPasses': 'Completed passes',
    'statistics.passing.team': 'Team',
    'statistics.passing.overallAverage': {
        message: 'Average, %',
        title: 'Average percentage of successful passes by all zones'
    },
    'statistics.passing.players': 'Players',
    'statistics.passing.defensive': {
        message: 'Defensive zone, %',
        title: 'Percentage of successful passes in defensive zone'
    },
    'statistics.passing.neutral': {
        message: 'Neutral zone, %',
        title: 'Percentage of successful passes in neutral zone'
    },
    'statistics.passing.offensive': {
        message: 'Offensive zone, %',
        title: 'Percentage of successful passes in offensive zone'
    },
    'statistics.passing.overall': 'Overall by players',
    'statistics.passing.ppa': {
        message: 'PA (primary)',
        title: 'The last pass before a shot attempt (no team possession change between the pass and shot attempt)'
    },
    'statistics.passing.spa': {
        message: 'PA (secondary)',
        title:
            'The last pass before a primary potential assist (no team possession change between the primary potential assist and secondary potential assist)'
    },
    'statistics.passing.potentialAssist': 'Potential assists',
    'statistics.passing.successful': 'Successful passes',
    'statistics.passing.failed': 'Failed passes',
    'statistics.passing.bad': 'BAD',
    'statistics.passing.good': 'GOOD',
    'statistics.passing.infobar': 'Table sum / average',
    'statistics.passing.table': 'Passing table',
    'statistics.passing.chart': 'Potential assists',
    'statistics.passing.avgPAP': 'Avg. PA (Primary)',
    'statistics.passing.avgPAS': 'Avg. PA (Secondary)',
    'statistics.passing.defenceZone.accurate': 'Passing at def. zone - accurate',
    'statistics.passing.defenceZone.inaccurate': 'Passing at def. zone - inaccurate',
    'statistics.passing.neutralZone.accurate': 'Passing at neutral zone - accurate',
    'statistics.passing.neutralZone.inaccurate': 'Passing at neutral zone - inaccurate',
    'statistics.passing.offensiveZone.accurate': 'Passing at offensive zone - accurate',
    'statistics.passing.offensiveZone.inaccurate': 'Passing at offensive zone - inaccurate',
    'statistics.entries.entries': 'ENTRIES',
    'statistics.entries.exits': 'EXITS',
    'statistics.entries.passes': 'Pass',
    'statistics.entries.controlled': 'Controlled',
    'statistics.entries.uncontrolled': 'Uncontrolled',
    'statistics.entries.ppStatistics': 'ENTRIES / EXITS',
    'statistics.entries.entriesExitsAnimation': 'ENTRIES / EXITS ANIMATION',
    'statistics.entries.playList': 'Playlist',
    'statistics.entries.shots': 'Shot attempts',
    'statistics.entries.avgDuration': 'Avg. attack duration',
    'statistics.entries.carries': 'Carry',
    'statistics.entries.carriesIn': 'Carry in',
    'statistics.entries.carriesOut': 'Carry out',
    'statistics.entries.dumps': 'Dump',
    'statistics.entries.carriesRate': 'Successful carries',
    'statistics.entries.passesRate': 'Successful passes',
    'statistics.entries.dumpsRate': 'Successful dumps',
    'statistics.entries.avgTimeCarries': 'Avg. carry time',
    'statistics.entries.avgTimePasses': 'Avg. pass time',
    'statistics.entries.avgTimeDumps': 'Avg. dump time',
    'statistics.entries.successRate': 'Success rate',
    'statistics.entries.positiveRate': 'Positive rate',
    'statistics.entries.Xg': 'xG',
    'statistics.entries.info.totalEntries': 'Total',
    'statistics.entries.info.avgDurationTime': 'Avg. duration',
    'statistics.entries.info.avgXg': 'Avg. xG',
    'statistics.entries.infobar': 'Sum / average',
    'statistics.entries.info.successRate': 'Success Rate',
    'statistics.entries.info.positiveRate': 'Positive Rate',
    'statistics.entries.info.distribution': 'Distribution',
    'statistics.possession.bodyChecks': 'Body checks',
    'statistics.possession.checks': {
        message: 'Checks',
        title:
            "The number of times when the stick / body is used to knock the puck off of the puck carrier's stick, changing possession"
    },
    'statistics.possession.faceOffWon': {
        message: 'Faceoff won',
        title:
            'Team and player (the faceoff taker) that have the first two full seconds of possession after the whistle win the faceoff'
    },
    'statistics.possession.faceOffWon%': {
        message: 'Faceoff won,\u00A0%',
        title: 'Faceoff won percentage'
    },
    'statistics.possession.faceOffLost': 'Faceoff lost',
    'statistics.possession.giveaways': 'Giveaways',
    'statistics.possession.giveawaysPercent': {
        message: 'Giveaways,\u00A0%',
        title:
            'Number of cases when a player takes the puck, makes a pass or is skating with the puck and loses it to the opponent divided by number of possessions'
    },
    'statistics.possession.possessionCount': {
        message: 'Number of possessions',
        title:
            'Number of cases when team touches the puck for the first time. Count of unique possessions by the team(s)'
    },
    'statistics.possession.possessionPlayerValue': "Player's possessions",
    'statistics.possession.possessionTeamValue': "Teams's possessions",
    'statistics.possession.giveawaysPlayerValue': "Player's giveaways",
    'statistics.possession.giveawaysTeamValue': "Teams's giveaways",
    'statistics.possession.possessionTime': {
        message: 'Possession time',
        title:
            'When the player is skating with the puck on the stick or makes a pass into the board and gets the puck back after'
    },
    'statistics.possession.recoveries': {
        message: 'Puck recoveries',
        title: 'When players retrieves a puck that is not possessed by any team'
    },
    'statistics.possession.reboundRecoveries': {
        message: 'Rebound recoveries',
        title: 'Number of cases when a player carries the puck after a save with rebound'
    },
    'statistics.possession.takeaways': {
        message: 'Interceptions',
        title: 'Number of direct passes intercepted, resulting in a change of possession'
    },
    'statistics.possession.timePerPossession': {
        message: 'Time per possessions',
        title: 'Possession time divided by number of possessions'
    },
    'statistics.possession.percentTeamPossessions': '%\u00A0of team possessions',
    'statistics.possession.stickChecks': 'Stick checks',
    'statistics.possession.infobar': 'Table average',
    'statistics.possession.table': 'Possession table',
    'statistics.possession.possessionTimeAVG': 'Avg. possession',
    'statistics.possession.possessionCountAVG': 'Avg. possessions',
    'statistics.possession.takeawaysAVG': 'Avg. interceptions',
    'statistics.possession.recoveriesAVG': 'Avg. recoveries',
    'statistics.possession.reboundRecoveriesAVG': 'Avg. rebounds',
    'statistics.possession.stickChecksAVG': 'Avg. stick checks',
    'statistics.possession.bodyChecksAVG': 'Avg. body checks',
    'statistics.possession.faceOffWonAVG': 'Avg. faceoff won',
    'statistics.possession.faceOffLostAVG': 'Avg. faceoff lost',
    'statistics.skating.heatmap': 'Heatmap',
    'statistics.skating.skating': 'Skating',
    'statistics.skating.byPlayers': 'By players',
    'statistics.skating.players': 'Player',
    'statistics.skating.playtime': 'Play time',
    'statistics.skating.distance': {
        message: 'Distance, ',
        title: 'Covered distance'
    },
    'statistics.skating.speed': {
        message: 'Speed, ',
        title: 'The average speed of skating while the player was on the ice'
    },
    'statistics.skating.possesionSpeed': 'Average possession speed, ',
    'statistics.skating.acceleration': {
        message: 'Acceleration, m/s²',
        title: 'The rate of change of velocity of a player with respect to time'
    },
    'statistics.skating.pace': {
        message: 'Pace,\u00A0%',
        title: 'Percentage of distance skated above 20 km/h (12.43 mph) from the total distance skated'
    },
    'statistics.skating.backchecking': {
        message: 'Backchecking Pace,\u00A0%',
        title:
            'Percentage of distance skated above 20 km/h (12.43 mph) in the direction of the defensive zone from the total distance skated towards the defensive zone while the opposite team is attacking'
    },
    'statistics.skating.shift': 'Shift',
    'statistics.skating.throughout': 'Throughout the shift',
    'statistics.skating.secondsIntoTheShift': 'SECONDS INTO THE SHIFT',
    'statistics.skating.chart': 'Shift stamina',
    'statistics.skating.chartPace': 'PACE',
    'statistics.skating.timeOnIce': {
        message: 'Time on ice',
        title: "Total time when the player's skates were on the ice during live action"
    },
    'statistics.skating.speedWithThePuck': {
        message: 'Speed with the puck, ',
        title: 'The average speed of skating while the player was skating with possession of the puck'
    },
    'statistics.skating.shiftTime': {
        message: 'Avg. shift length',
        title:
            'Average duration of all shifts. Each shift starts from the first touch of skate to the ice during live action'
    },
    'statistics.skating.avgRestTime': 'Avg. rest time',
    'statistics.skating.infobar': 'Table average',
    'statistics.skating.table': 'Skating table',
    'statistics.skating.totalDistance': 'Total distance',
    'statistics.skating.totalShiftsLength': 'Total shifts length',
    'statistics.skating.shifts': 'Shifts',
    'statistics.shifts.chart': 'Shifts chart',
    'statistics.shifts.table': 'Shifts table',
    'statistics.shifts.statistics': "Player's Statistics",
    'statistics.shifts.startShiftTime': 'Start time',
    'statistics.shifts.endShiftTime': 'End time',
    'statistics.shifts.eventType': 'Event type',
    'statistics.shifts.strengths': 'Strengths',
    'statistics.shifts.OZtimeFor': 'Puck time in OZ',
    'statistics.shifts.OZtimeAgainst': 'Puck time in DZ',
    'statistics.shifts.shotAttemptsFor': 'Shot attempts for',
    'statistics.shifts.shotAttemptsAgainst': 'Shot attempts against',
    'statistics.shifts.possessionTime': 'Possession time',
    'statistics.shifts.reboundRecoveries': 'Rebound recoveries',
    'statistics.shifts.PPA': 'PA (primary)',
    'statistics.shifts.SPA': 'PA (secondary)',
    'statistics.shifts.onGoal': 'On goal',
    'statistics.shifts.blocked': 'Blocked',
    'statistics.shifts.missed': 'Missed',
    'statistics.shifts.shiftLength': {
        message: 'Shift length',
        title: "Duration of the shift which continuing while the player's skates are on the ice"
    },
    'statistics.shifts.distance': {
        message: 'Distance, ',
        title: 'Distance skated during the shift'
    },
    'statistics.shifts.pace': {
        message: 'Pace, %',
        title:
            'Average pace (the amount of the distance skated above 20 km/h (12.43 mph) divided by total distance skated) during the shift'
    },
    'statistics.shifts.backcheckPace': {
        message: 'Backchecking Pace, %',
        title:
            'Average backchecking pace (the amount of the distance skated above 20 km/h (12.43 mph) in the direction of the defensive zone divided by total distance skated towards the defensive zone) during the shift'
    },
    'statistics.shifts.OZtime': {
        message: 'OZ time, %',
        title: 'Percentage of time when puck was in the offensive zone during the shift'
    },
    'statistics.shifts.shotAttemptsOnIce': {
        message: 'Shot attempts, %',
        title: 'Percentage of shot attempts made by team from the total number of shot attempts during the shift'
    },
    'statistics.shifts.possessionCount': {
        message: 'Possession count',
        title: 'Number of unique players possessions made by team during the shift'
    },
    'statistics.shifts.giveaways': {
        message: 'Giveaways',
        title: 'Number of giveaways made by team during the shift'
    },
    'statistics.shifts.takeaways': {
        message: 'Interceptions',
        title: 'Number of interceptions made by team during the shift'
    },
    'statistics.shifts.recoveries': {
        message: 'Recoveries',
        title: 'Number of puck recoveries made by team during the shift'
    },
    'statistics.shifts.passes': {
        message: 'Passes',
        title: 'Number of passes made by team during the shift'
    },
    'statistics.shifts.shotAttempts': {
        message: 'Shot attempts',
        title: 'Number of shot attempts made by team during the shift'
    },
    'statistics.shifts.goals': {
        message: 'Goals',
        title: ''
    },
    'statistics.shifts.assists': {
        message: 'Assists',
        title: ''
    },
    'statistics.shifts.faceOffWon': {
        message: 'Face off won',
        title: ''
    },
    'statistics.shifts.faceOffLost': {
        message: 'Face off lost',
        title: ''
    },
    'statistics.shifts.xG': {
        message: 'xG',
        title: 'Total amount of XG made by team during the shift'
    },
    'statistics.shifts.shotsOnGoal': {
        message: 'Shots on goal'
    },
    'statistics.shifts.numberOfShifts': {
        message: 'Number of shifts'
    },
    'statistics.shifts.averageShift': {
        message: 'Average shift'
    },
    'statistics.shifts.toi': {
        message: 'TOI'
    },
    'statistics.shifts.period': 'Period',
    'statistics.shifts.startTime': 'Start time',
    'statistics.shifts.endTime': 'End time',
    'statistics.shifts.duration': 'Duration',

    'configurator.customTable': 'Custom table',
    'configurator.metrics': 'Metrics',

    'tableBuilder.selectPreset': 'Select preset',
    'tableBuilder.save': 'Save',
    'tableBuilder.enterPresetName': 'Enter preset name: ',
    'tableBuilder.editColumns': 'Edit columns',

    'scout.pct.games': 'Games',
    'scout.pct.toi': 'Time on ice',
    'scout.pct.successfulPasses': 'Successful passes',
    'scout.pct.failedPasses': 'Failed passes',
    'scout.pct.possessions': 'Possessions',
    'scout.pct.giveaways': 'Giveaways',
    'scout.pct.takeaways': 'Takeaways',
    'scout.pct.recoveries': 'Recoveries',
    'scout.pct.shots': 'Shots',
    'scout.pct.shotsOnGoal': 'Shots on goal',
    'scout.pct.shotsMissed': 'Shots missed',
    'scout.pct.blockedShots': 'Blocked shots',
    'scout.pct.shotsOnGoalAgainst': 'Shots on goal against',
    'scout.pct.xg': 'Xg',

    'scout.playersSelect.newPreset': 'New preset',
    'scout.playersPreset.onIceShotsAgainst': 'On ice shots against',
    'scout.playersPreset.toi': 'Time on ice',
    'scout.playersPreset.possessionTime': 'Possession time',
    'scout.playersPreset.possessionCount': 'Possessions count',
    'scout.playersPreset.onIcePossessionFor': 'On ice possession for',
    'scout.playersPreset.onIcePossessionAgainst': 'On ice possession against',
    'scout.playersPreset.ozOnIceTime': 'Toi in OZ',
    'scout.playersPreset.nzOnIceTime': 'Toi in NZ',
    'scout.playersPreset.dzOnIceTime': 'Toi in DZ',
    'scout.playersPreset.xg': 'xG',
    'scout.playersPreset.xgDangerZone': 'xG in DZ',
    'scout.playersPreset.onIceXgFor': 'on ice xg for',
    'scout.playersPreset.onIceXgAgainst': 'on ice xg against',
    'scout.playersPreset.successfulPasses': 'Successful passes',
    'scout.playersPreset.failedPasses': 'Failed passes',
    'scout.playersPreset.primaryPotentialAssists': 'Primary potential assists',
    'scout.playersPreset.secondaryPotentialAssists': 'Secondary potential assists',
    'scout.playersPreset.primaryAssists': 'Primary assists',
    'scout.playersPreset.secondaryAssists': 'Secondary assists',
    'scout.playersPreset.giveaways': 'Giveaways',
    'scout.playersPreset.interceptions': 'Interceptions',
    'scout.playersPreset.recoveries': 'Recoveries',
    'scout.playersPreset.shifts': 'Shifts',
    'scout.playersPreset.onIceShotsFor': 'on ice shots for',
    'scout.playersPreset.saves': 'Saves',
    'scout.playersPreset.onIceGoalsAgainst': 'on ice goals against',
    'scout.playersPreset.shotsWithRebound': 'Shots with rebound',
    'scout.playersPreset.zoneStartsOz': 'Zone starts OZ',
    'scout.playersPreset.zoneStartsDz': 'Zone starts DZ',
    'scout.playersPreset.reboundAngle': 'Rebound angle',
    'scout.playersPreset.controlledSuccessfulEntries': 'Controlled successful entries',
    'scout.playersPreset.uncontrolledSuccessfulEntries': 'Uncontrolled successful entries',
    'scout.playersPreset.controlledSuccessfulExits': 'Controlled successful exits',
    'scout.playersPreset.uncontrolledSuccessfulExits': 'Uncontrolled successful exits',
    'scout.playersPreset.controlledFailedEntries': 'Controlled failed entries',
    'scout.playersPreset.uncontrolledFailedEntries': 'Uncontrolled failed entries',
    'scout.playersPreset.controlledFailedExits': 'Controlled failed exits',
    'scout.playersPreset.uncontrolledFailedExits': 'Uncontrolled failed exits',
    'scout.playersPreset.shots': 'Shots',
    'scout.playersPreset.shotsOnGoal': 'Shots on goal',
    'scout.playersPreset.shotsMissed': 'Shots missed',
    'scout.playersPreset.shotsBlocked': 'Shots blocked',
    'scout.playersPreset.shotsOnGoalAgainst': 'Shots on goal against',
    'scout.playersPreset.reboundRecoveriesOz': 'Rebound recoveries in OZ',
    'scout.playersPreset.reboundRecoveriesDz': 'Rebound recoveries in DZ',
    'scout.playersPreset.onIceScoringChancesFor': 'On ice scoring chances for',
    'scout.playersPreset.onIceScoringChancesAgainst': 'On ice scoring chances against',

    'scout.notFoundVideoForYour': 'There are no games available for your account.',

    'scout.reportFilters.team': 'All teams',
    'scout.reportFilters.league': 'All leagues',
    'scout.reportFilters.season': 'All seasons',
    'scout.reportFilters.scout': 'All scouts',

    'scout.reportFilters.team.amount': 'team',
    'scout.reportFilters.league.amount': 'league',
    'scout.reportFilters.season.amount': 'season',
    'scout.reportFilters.scout.amount': 'scout',

    /**************************************************************Game score***********************************************/
    'gameScore.playersTable.title': 'Players',
    'gameScore.playersTable.gamesCount': 'Games',
    'gameScore.playersTable.totalAvgGameScore': ' Avg. total game score',
    'gameScore.playersTable.offensiveAvgGameScore': 'Avg. offensive game score',
    'gameScore.playersTable.defensiveAvgGameScore': 'Avg. defensive game score',
    'gameScore.playersTable.toi': 'Total TOI',
    'gameScore.playersTable.ppToi': 'PP TOI',
    'gameScore.playersTable.pkToi': 'PK TOI',
    'gameScore.gameScoreWidget.title': 'Game score',
    'gameScore.gameScoreWidget.goaltenders': 'Goaltenders',
    'gameScore.gameScoreWidget.overall': 'Overall',
    'gameScore.gameScoreWidget.offensive': 'Offensive',
    'gameScore.gameScoreWidget.defensive': 'Defensive',
    'gameScore.gameScoreWidget.header.metric': 'Metric',
    'gameScore.gameScoreWidget.header.value': 'Value',
    'gameScore.gameScoreWidget.header.value.player': 'Player',
    'gameScore.gameScoreWidget.header.value.avg': 'Average',
    'gameScore.gameScoreWidget.header.delta': 'Delta',
    'gameScore.gameScoreWidget.header.insight': 'Description (against team avg.)',
    'gameScore.metric.relations.more': 'more',
    'gameScore.metric.relations.less': 'less',
    'gameScore.metric.xg.title': 'xG',
    'gameScore.metric.xg.def': 'All player’s shots were {percentage} {relation} dangerous.',
    'gameScore.metric.goals.title': 'Goals',
    'gameScore.metric.goals.def': 'Player scored {percentage} {relation} goals.',
    'gameScore.metric.xgPP.title': 'xG (PP)',
    'gameScore.metric.xgPP.def': 'Player’s shots in Power Play were {percentage} {relation} dangerous.',
    'gameScore.metric.xgPerShot.title': 'xG / Shot Attempt',
    'gameScore.metric.xgPerShot.def': 'Player’s shots were {percentage} {relation} dangerous.',
    'gameScore.metric.xgPerShotOnGoal.title': 'xG / Shot Attempt (PP)',
    'gameScore.metric.xgPerShotOnGoal.def': 'Player’s shots in Power Play were {percentage} {relation} dangerous.',
    'gameScore.metric.xa.title': 'xA',
    'gameScore.metric.xa.def': 'Player’s assists were {percentage} {relation} dangerous.',
    'gameScore.metric.xaPP.title': 'xA (PP)',
    'gameScore.metric.xaPP.def': 'Player’s assists in Power Play were {percentage} {relation} dangerous.',
    'gameScore.metric.xa2.title': 'xA2',
    'gameScore.metric.xa2.def': 'Player’s secondary assists were {percentage} {relation} dangerous.',
    'gameScore.metric.xa2PP.title': 'xA2 (PP)',
    'gameScore.metric.xa2PP.def': 'Player’s secondary assists in Power Play were {percentage} {relation} dangerous.',
    'gameScore.metric.keyPasses.title': 'Key Passes',
    'gameScore.metric.keyPasses.def':
        'Player made {percentage} {relation} key passes (passes leads to high danger shot).',
    'gameScore.metric.entries.title': 'Entries',
    'gameScore.metric.entries.def': 'Player made {percentage} {relation} entries in opponent’s offensive zone.',
    'gameScore.metric.controlledEntries.title': 'Controlled Entries',
    'gameScore.metric.controlledEntries.def':
        'Player made {percentage} {relation} controlled entries (through skating or passing) in opponent’s offensive zone.',
    'gameScore.metric.successfulEntries.title': 'Entries SR%',
    'gameScore.metric.successfulEntries.def':
        'Player’s entries were {percentage} {relation} successful and led to offensive zone holding.',
    'gameScore.metric.controlledSuccessfulEntries.title': 'Controlled Entries SR%',
    'gameScore.metric.controlledSuccessfulEntries.def':
        'Player’s controlled entries were {percentage} {relation} successful and led to offensive zone holding.',
    'gameScore.metric.xgRebound.title': 'xG  (Rebounds)',
    'gameScore.metric.xgRebound.def': 'Player’s total shots after rebounds were {percentage} {relation} dangerous.',
    'gameScore.metric.recoveries.title': 'Recoveries',
    'gameScore.metric.recoveries.def':
        'Player made {percentage} {relation} receptions of puck not possessed by any team.',
    'gameScore.metric.rebounds.title': 'Rebounds',
    'gameScore.metric.rebounds.def': 'Player made {percentage} {relation} receptions of puck after save with rebound.',
    'gameScore.metric.recoveriesOz.title': 'Recoveries (OZ)',
    'gameScore.metric.recoveriesOz.def':
        'Player made {percentage} {relation} receptions of puck not possessed by any team in offensive zone.',
    'gameScore.metric.reboundsOz.title': 'Rebounds (OZ)',
    'gameScore.metric.reboundsOz.def':
        'Player made {percentage} {relation} receptions of the puck after a save with rebound in offensive zone.',
    'gameScore.metric.checks.title': 'Checks',
    'gameScore.metric.checks.def': 'Player made {percentage} {relation} checks',
    'gameScore.metric.recoveriesDz.title': 'Recoveries (DZ)',
    'gameScore.metric.recoveriesDz.def':
        'Player made {percentage} {relation} receptions of puck not possessed by any team in defensive zone.',
    'gameScore.metric.reboundsDz.title': 'Rebounds (DZ)',
    'gameScore.metric.reboundsDz.def':
        'Player made {percentage} {relation} receptions of puck after save with rebound in defensive zone.',
    'gameScore.metric.interceptions.title': 'Interceptions',
    'gameScore.metric.interceptions.def':
        'Player made {percentage} {relation} interceptions resulted in a change of puck possession.',
    'gameScore.metric.interceptionsOz.title': 'Interceptions (OZ)',
    'gameScore.metric.interceptionsOz.def':
        'Player made {percentage} {relation} interceptions resulted in a change of puck possession in offensive zone.',
    'gameScore.metric.interceptionsDz.title': 'Interceptions (DZ)',
    'gameScore.metric.interceptionsDz.def':
        'Player made {percentage} {relation} interceptions resulted in a change of puck possession in defensive zone.',
    'gameScore.metric.xgAgainst.title': 'xG (Against)',
    'gameScore.metric.xgAgainst.def':
        'Player allowed {percentage} {relation} danger shots of the opponent while being on ice.',
    'gameScore.metric.dispossessed.title': 'Dispossessed',
    'gameScore.metric.dispossessed.def': 'Player lost {percentage} {relation} own possessions of the puck.',
    'gameScore.metric.dispossessedDz.title': 'Dispossessed (DZ)',
    'gameScore.metric.dispossessedDz.def':
        'Player lost {percentage} {relation} own possessions of the puck  in defensive zone.',
    'gameScore.metric.xgAgainstNearNet.title': 'xG (Aganist, near the net)',
    'gameScore.metric.xgAgainstNearNet.def':
        'Player allowed {percentage} {relation} danger shots of the opponent from the zone right in front of the net.',
    'gameScore.metric.keyPassesAgainst.title': 'Key Passes (Against)',
    'gameScore.metric.keyPassesAgainst.def':
        'Player allowed {percentage} {relation} key passes of the opponent (passes leads to high danger shot).',
    'gameScore.metric.xgAgainstEntries.title': 'xG Against (Entries)',
    'gameScore.metric.xgAgainstEntries.def':
        'Player allowed {percentage} {relation} danger shots of the opponent in 3 seconds after zone entries.',
    'gameScore.metric.blockedShots.title': 'Blocked Shots',
    'gameScore.metric.blockedShots.def': 'Player blocked {percentage} {relation} shot attempts.',
    'gameScore.metric.xgShotAttemptsAgainst.title': 'xG / Shot Attempt (Against)',
    'gameScore.metric.xgShotAttemptsAgainst.def':
        'Player allowed {percentage} {relation} danger shots of the opponent in average.',
    'gameScore.metric.avgSpeed.title': 'Average Speed',
    'gameScore.metric.avgSpeed.def': 'Player’s average speed is {percentage} {relation}.',
    'gameScore.metric.pace.title': 'Pace, %',
    'gameScore.metric.pace.def': 'Player covered {percentage} {relation} distance on speed more than 20 km/h.',
    'gameScore.metric.backCheckPace.title': 'Backcheck Pace, %',
    'gameScore.metric.backCheckPace.def':
        'Player covered {percentage} {relation} distance  on speed more than 20 km/h while opposite team is attacking.',
    'gameScore.metric.distanceCovered.title': 'Distance Covered',
    'gameScore.metric.distanceCovered.def': 'Player covered {percentage} {relation} distance.',
    'gameScore.metric.acceleration.title': 'Acceleration',
    'gameScore.metric.acceleration.def': 'Player accelerated {percentage} {relation}.',
    'gameScore.metric.xgAgainstShotsOnGoal.title': 'xG (Against, Shots on Goal)',
    'gameScore.metric.xgAgainstShotsOnGoal.def': 'Goalkeeper faced {value} dangerous shots on goal.',
    'gameScore.metric.goalsAgainstShotsOnGoal.title': 'Goals (Against, Shots on Goal)',
    'gameScore.metric.goalsAgainstShotsOnGoal.def': 'Goalkeeper allowed {value} goals.',
    'gameScore.metric.xgAgainstNearNetGoaltenders.title': 'xG (Against, near the net)',
    'gameScore.metric.xgAgainstNearNetGoaltenders.def':
        'Goalkeeper faced {value} dangerous shots from the zone right in front of the net.',
    'gameScore.metric.goalsAgainstNearNet.title': 'Goals (Against, near the net)',
    'gameScore.metric.goalsAgainstNearNet.def':
        'Goalkeeper allowed {value} goals from the zone right in front of the net.',
    'gameScore.metric.saves.title': 'Saves, %',
    'gameScore.metric.saves.def': 'Goalkeeper saved {value} shots.',
    'gameScore.metric.savesNearNet.title': 'Saves, % (near the net)',
    'gameScore.metric.savesNearNet.def': 'Goalkeeper saved {value} shots from the zone right in front of the net.',
    'playerPage.modal.title': 'Less than 5 games selected.\u00A0',
    'shiftsPage.modal.title': 'The player did not play this game.',
    'shiftsPageMiss.modal.title': 'No data for this player.',
    'playerPage.modal.description': ' Page data may be inaccurate and biased.\u00A0',
    'playerPage.modal.advice': ' If possible, please select more than 5 games for more precise estimations.',
    'playerPage.playerStats.title': 'Player stats',
    'playerPage.playerStats.games': 'Games',
    'playerPage.playerStats.avgToi': 'AVG TOI',
    'playerPage.playerStats.goals': 'Goals',
    'playerPage.playerStats.assists': 'Assists',
    'playerPage.playerStats.avgGs': 'AVG GS',
    'playerPage.playerStats.gameScoreTrend': 'Game score trend',
    'playerPage.playerStats.hotTitle':
        'The player’s overall performance in the last three games has increased significantly compared to the player’s average performance.',
    'playerPage.playerStats.coldTitle':
        'The player’s overall performance in the last three games has decreased significantly compared to the player’s average performance.',
    'playerPage.playerStats.PK': 'Penalty kill',
    'playerPage.playerStats.EV': 'Even strengths',
    'playerPage.playerStats.PP': 'Power play',
    'playerPage.playerStats.skillsChart': 'Skills Chart',
    'playerPage.radar.skills': 'Skills',
    'playerPage.lastSeason': 'Last season',
    'playerPage.radar.stat.successPasses': 'Pass Accuracy',
    'playerPage.radar.stat.pace': 'Pace',
    'playerPage.radar.stat.xgPer20': 'xG / 20',
    'playerPage.radar.stat.giveaways': 'Puck control',
    'playerPage.radar.stat.xgForPer20': 'xG For / 20',
    'playerPage.radar.stat.xgAgainstPer20': 'xG Against / 20',
    'playerPage.radar.stat.xgPerShot': 'xG / Shot',
    'playerPage.radar.stat.reboundRecoveriesPer20': 'Reb. Rec. / 20',
    'playerPage.radar.stat.checksPer20': 'Checks / 20',
    'playerPage.radar.stat.speedWithPuck': 'Speed\u00A0With Puck',
    'playerPage.radar.stat.xgOnIce': 'xG On Ice',
    'playerPage.radar.stat.xaPer20': 'xA / 20',
    'playerPage.radar.stat.controlledEntries': 'Controlled Entries',
    'playerPage.radar.stat.interceptionsPer20': ' Interceptions / 20',
    'playerPage.radar.stat.successExits': 'Successful Exits',
    'playerPage.radar.stat.successThroughtPassesPer20': ' Successful through passes / 20',
    'playerPage.radar.stat.reboundRecoveriesDzPer20': 'Reb.\u00A0Rec. DZ\u00A0/\u00A020',
    'playerPage.radar.stat.shotBlocking': 'Shot Blocking',
    'playerPage.radar.stat.shotsPer20': 'Shots / 20',
    'playerPage.eventMap.title': 'Event Map',
    'playerPage.eventMap.tabs.FACE_OFFS': 'FACE-OFFS',
    'playerPage.eventMap.tabs.RECOVERIES': 'RECOVERIES',
    'playerPage.eventMap.tabs.SHOTS': 'SHOTS',
    'playerPage.eventMap.tabs.HEAT_MAP': 'HEAT MAP',
    'playerPage.eventMap.tabs.SHOT_LOCATION': 'SHOT LOCATION',
    'playerPage.skillsTable.Ind': 'Ind.',
    'playerPage.skillsTable.Percentile': 'Perc.',
    'playerPage.skillsTable.Avg': 'Avg.',
    'playerPage.skillsTable.SD': 'SD',
    'playerPage.skillsTable.header': 'Skills',
    'playerPage.skillsTable.shooting': 'Shooting',
    'playerPage.skillsTable.shots_on_goal_20': 'Shots on goal',
    'playerPage.skillsTable.shot_accuracy': 'Shot accuracy, %',
    'playerPage.skillsTable.xg_20': 'xG',
    'playerPage.skillsTable.shots_blocked_20': 'Shots blocked',
    'playerPage.skillsTable.shots_lth_20': 'Shots after LTH pass',
    'playerPage.skillsTable.xg_lth': 'xG after LTH pass',
    'playerPage.skillsTable.xg_shot_attempt': 'xG per shot',
    'playerPage.skillsTable.shooting_from_danger_zone_percent': 'Shooting from danger zone, %',
    'playerPage.skillsTable.transition': 'Transition',
    'playerPage.skillsTable.controlled_entries_percent': 'Controlled entries, %',
    'playerPage.skillsTable.successful_entries_percent': 'Successful entries, %',
    'playerPage.skillsTable.avg_speed_with_puck_nz': 'Speed with the puck (NZ)',
    'playerPage.skillsTable.successful_passes_into_nz_20': 'Successful passes into NZ',
    'playerPage.skillsTable.controlled_exits_percent': 'Controlled exits, %',
    'playerPage.skillsTable.successful_exits_percent': 'Successful exits, %',
    'playerPage.skillsTable.skating': 'Skating',
    'playerPage.skillsTable.avg_pace': 'Pace, %',
    'playerPage.skillsTable.backchecking_pace_percent': 'Backchecking pace, %',
    'playerPage.skillsTable.avg_speed': 'Avg. speed',
    'playerPage.skillsTable.avg_shifts_length': 'Avg. shifts length',
    'playerPage.skillsTable.acceleration': 'Acceleration',
    'playerPage.skillsTable.avg_speed_with_puck': 'Avg. speed with the puck',
    'playerPage.skillsTable.distance': 'Distance',
    'playerPage.skillsTable.onIce': 'On-Ice',
    'playerPage.skillsTable.xg_danger_zone_against': 'xG from DgZ against',
    'playerPage.skillsTable.puck_time_into_oz_percent': 'Puck time in OZ %',
    'playerPage.skillsTable.shots_percent': 'Shots, %',
    'playerPage.skillsTable.xg_percent': 'xG %',
    'playerPage.skillsTable.on_ice_xg_for_20': 'xG for',
    'playerPage.skillsTable.on_ice_xg_against_20': 'xG against',
    'playerPage.skillsTable.passingAndPossession': 'Passing & Possession',
    'playerPage.skillsTable.individual': 'individual',
    'playerPage.skillsTable.giveaways_percent': 'Giveaways, %',
    'playerPage.skillsTable.interceptions_20': 'Interceptions',
    'playerPage.skillsTable.rebound_recoveries_20': 'Recoveries',
    'playerPage.skillsTable.checks_20': 'Checks',
    'playerPage.skillsTable.possession_time_20': 'Possession time',
    'playerPage.skillsTable.blocked_shots_percent': 'Shot blocking %',
    'playerPage.skillsTable.dispossessed_dz': 'Dispossessed (DZ)',
    'playerPage.skillsTable.rebound_recoveries_oz_20': 'Rebound recoveries (OZ)',
    'playerPage.skillsTable.successful_passes_percent': 'Successful passes, %',
    'playerPage.skillsTable.xa_20': 'xA',
    'playerPage.skillsTable.xa2_20': 'xA 2',
    'playerPage.skillsTable.successful_through_passes': 'Successful through passes',
    'playerPage.skillsTable.key_passes_20': 'Key passes',

    'playerPage.skillsTable.potentialPrimaryAssistsPer20': 'Potential Primary Assists',
    'playerPage.skillsTable.potentialSecondaryAssistsPer20': 'Potential Secondary Assists',
    'playerPage.skillsTable.realPrimaryAssistsPer20': 'Primary Assists',
    'playerPage.skillsTable.realSecondaryAssistsPer20': 'Secondary Assists',
    'playerPage.skillsTable.successfulPassesPercent': 'Successful Passes, %',
    'playerPage.skillsTable.reboundRecoveriesOzPer20': 'Rebound Recoveries OZ',
    'playerPage.skillsTable.reboundRecoveriesDzPer20': 'Rebound Recoveries DZ',
    'playerPage.skillsTable.possessionTimePer20': 'Possession Time',
    'playerPage.skillsTable.possessionTimeForPercent': 'Possession Time For, %',
    'playerPage.skillsTable.possessionsPer20': 'Possessions',
    'playerPage.skillsTable.controlledEntriesPercent': 'Controlled Entries',
    'playerPage.skillsTable.controlledExitsPercent': 'Controlled Exits',
    'playerPage.skillsTable.successfulEntriesPercent': 'Successful Entries',
    'playerPage.skillsTable.successfulExitsPercent': 'Successful Exits',
    'playerPage.skillsTable.timePerPossession': 'Time Per Possession',
    'playerPage.skillsTable.giveawaysPercent': 'Giveaways,\u00A0%',
    'playerPage.skillsTable.takeawaysPer20': 'Interceptions',
    'playerPage.skillsTable.recoveriesPer20': 'Recoveries',
    'playerPage.skillsTable.shotsPer20': 'Shots',
    'playerPage.skillsTable.shotsOnGoalPer20': 'Shots On Goal',
    'playerPage.skillsTable.xgPer20': 'xG',
    'playerPage.skillsTable.xgDangerZonePer20': 'xG Danger Zone',
    'playerPage.skillsTable.xgPerShot': 'xG Per Shot',
    'playerPage.skillsTable.puckTimeOzPercent': 'Puck Time OZ,\u00A0%',
    'playerPage.skillsTable.puckTimeNzPercent': 'Puck Time NZ,\u00A0%',
    'playerPage.skillsTable.puckTimeDzPercent': 'Puck Time DZ,\u00A0%',
    'playerPage.skillsTable.xgForPercent': 'xG For,\u00A0%',
    'playerPage.skillsTable.xgForPer20': 'xG For',
    'playerPage.skillsTable.xgAgainstPer20': 'xG Against',
    'playerPage.skillsTable.shotsOnGoalPercent': 'Shots On Goal,\u00A0%',
    'playerPage.skillsTable.shotsAgainstPer20': 'Shots Against',
    'playerPage.skillsTable.shotsOnGoalAgainstPer20': 'Shots On Goal Against',
    'playerPage.skillsTable.scoringChancesForPer20': 'Scoring Chances For',
    'playerPage.skillsTable.scoringChancesAgainstPer20': 'Scoring Chances Against',

    'playerPage.skillsChart.games': 'Games',
    'playerPage.skillsChart.skillsDynamicsChart': 'Skills dynamics chart',
    'playerPage.skillsChart.descriptions.shots_on_goal_20': 'Shots on goal per 20 minutes of TOI',
    'playerPage.skillsChart.descriptions.shot_accuracy': 'The percentage of shot attempts that hit the net',
    'playerPage.skillsChart.descriptions.xg_20':
        'Expected goals generated per 20 minutes of TOI. This metric is built from a combination of over 30 parameters, including shot angle, net front traffic, pass distance, and goalie movement.',
    'playerPage.skillsChart.descriptions.shots_blocked_20':
        'Number of shots taken that got blocked by opposition per 20 minutes of TOI',
    'playerPage.skillsChart.descriptions.shots_lth_20':
        'This metric shows how many shots the player attempted after receiving a low-to-high pass from a teammate. Low-to-high passes are when the puck moves from down low in the OZ toward the blue line, and can be direct or a pass off the boards.',
    'playerPage.skillsChart.descriptions.xg_lth':
        'The amount of xG generated by the player after receiving a low-to-high pass.',
    'playerPage.skillsChart.descriptions.xg_shot_attempt':
        "Expected goals divided by the number of shots attempted. This metric shows the average value of the player's shot attempts.",
    'playerPage.skillsChart.descriptions.shooting_from_danger_zone_percent':
        'The percentage of shots inside the danger zone that hit the net. Danger zone shots have higher xG, and higher shot accuracy means there is a greater chance to score or generate a rebound.',
    'playerPage.skillsChart.descriptions.controlled_entries_percent':
        'Entries where puck possession is maintained through the NZ and into the OZ. Controlled entries are pass and carry entries.',
    'playerPage.skillsChart.descriptions.successful_entries_percent':
        'The percentage of controlled entry attempts that resulted in OZ puck possession.',
    'playerPage.skillsChart.descriptions.avg_speed_with_puck_nz':
        'This calculates the average speed at which the player is moving while possessing the puck in the NZ. NZ speed can create in zone possession and force the opposition out of position.',
    'playerPage.skillsChart.descriptions.successful_passes_into_nz_20':
        'The percentage of passes that were completed in the NZ.',
    'playerPage.skillsChart.descriptions.controlled_exits_percent':
        'Controlled exits are when the team is able to maintain puck possession while exiting their defensive zone.  Controlled exits are pass and carry exits.',
    'playerPage.skillsChart.descriptions.successful_exits_percent':
        'The percentage of controlled exit attempts that resulted in maintaining puck possession into the NZ.',
    'playerPage.skillsChart.descriptions.avg_pace':
        "The player's average pace while on the ice, measured by the amount of the distance skated above 20 km/h (12.43 mph) divided by total distance skated.",
    'playerPage.skillsChart.descriptions.backchecking_pace_percent':
        'Backchecking pace measures the  amount of the distance a player backchecks above a certain speed. A high backchecking pace means the player hustles to get back back into the defensive zone.',
    'playerPage.skillsChart.descriptions.avg_speed':
        'This metric shows the average speed of the player while they are on the ice.',
    'playerPage.skillsChart.descriptions.avg_shifts_length': "The average length of the player's total shifts.",
    'playerPage.skillsChart.descriptions.acceleration': 'This metric shows the player’s average acceleration.',
    'playerPage.skillsChart.descriptions.avg_speed_with_puck':
        'Average speed with the puck specifically measures how fast the player is skated while in possession of the puck.',
    'playerPage.skillsChart.descriptions.distance': 'The distance the player skated per 20 minutes of TOI',
    'playerPage.skillsChart.descriptions.xg_danger_zone_against':
        'Amount of xG (per 20 min of TOI) from inside the danger zone generated by opponents when player is on the ice.',
    'playerPage.skillsChart.descriptions.puck_time_into_oz_percent':
        'The amount of time when the puck was in the offensive zone while the player was on the ice.',
    'playerPage.skillsChart.descriptions.shots_percent':
        "The percentage of shots on goal by the player's team while the player was on the ice. Anything over 50% means that the player's team had the majority of shots on goal while they were on the ice.",
    'playerPage.skillsChart.descriptions.xg_percent':
        "The percentage of xG generate by the player's team while the player was on the ice. Anything over 50% means that the player's team generate more xG than the opposition while that player was on the ice.",
    'playerPage.skillsChart.descriptions.on_ice_xg_for_20':
        "The total xG generated by the player's team while the player was on the ice.",
    'playerPage.skillsChart.descriptions.on_ice_xg_against_20':
        'The total xG generated by the opposition while the player was on the ice.',
    'playerPage.skillsChart.descriptions.giveaways_percent':
        'The percentage of time that a player loses the puck to the opponent. This metric is calculated by looking at the total number of possessions a player has, and the result of those possessions (excluding shot attempts and intentional entry/exits).',
    'playerPage.skillsChart.descriptions.interceptions_20':
        'The number of times the player intercepts a pass by the opposition. (not in glossary)',
    'playerPage.skillsChart.descriptions.rebound_recoveries_20':
        'The number of times per 20 minutes of TOI that a player is able to gain possession of a loose puck.  The puck must be in a "neutral" state, meaning neither team has control.',
    'playerPage.skillsChart.descriptions.checks_20':
        'Any stick or body check made by the player that results in a change of possession, calculated per 20 minutes of TOI.',
    'playerPage.skillsChart.descriptions.possession_time_20':
        'The amount of time that the player had the puck on their stick. This is the total possession time in all three zones of the ice (DZ, NZ, OZ).',
    'playerPage.skillsChart.descriptions.blocked_shots_percent':
        'This shows the number of shots the player blocked relative to the number of shots they could have potentially blocked. A high % indicates the player is consistent at blocking opponents’ shots.',
    'playerPage.skillsChart.descriptions.dispossessed_dz':
        ' This metric shows how many times a player had the puck taken from them in the DZ. For defensemen, having a low count signals that the player is strong on the puck and is able to maintain possession in the DZ.',
    'playerPage.skillsChart.descriptions.rebound_recoveries_oz_20':
        'How many times the player was able to recover a loose puck after the opposing goalie gave up a rebound. This metric is calculated to show how many rebound recoveries were made per 20 minutes of TOI.',
    'playerPage.skillsChart.descriptions.successful_passes_percent':
        'The rate at which the player is able to complete passes. This metric is generated by taking the number of completed passes divided by the total number of passing attempts.',
    'playerPage.skillsChart.descriptions.xa_20': "xG created by player's primary potential assists.",
    'playerPage.skillsChart.descriptions.xa2_20': "xG created by player's secondary potential assists.",
    'playerPage.skillsChart.descriptions.successful_through_passes':
        "Number of passes per 20 in the direction of the opposing net, which can be potentially intercepted by an opposing player. This metrics shows the player's ability to create plays through opponents.",
    'playerPage.skillsChart.descriptions.key_passes_20':
        'This metric measures the number of passes a player makes that lead to significant xG. Passes leading to a high xG are considered key passes.',
    'playerPage.barChart.allGames': 'all games',
    'playerPage.barChart.lastTenGames': 'last 10',
    'playerPage.barChart.tooltip.game': 'Game',
    'playerPage.barChart.tooltip.gameScore': 'Game score',
    'playerComparison.tool.title': 'Player comparison tool',
    'playerComparison.chart.controls.xAxis': 'X Axis',
    'playerComparison.chart.controls.yAxis': 'Y Axis',
    'playerComparison.chart.controls.calculation': 'Calculation',
    'playerComparison.chart.controls.compareWith': 'Compare with',
    'playerComparison.chart.controls.showOpponent': 'Show opponent players',
    'playerComparison.chart.controls.apply': 'Apply',
    'playerComparison.chart.controls.resetZoom': 'Reset Zoom',
    'playerComparison.chart.controls.returnZoom': 'Return Zoom',
    'playerComparison.calculationTypes.RAW': 'RAW',
    'playerComparison.calculationTypes.PER_20': 'PER 20',
    'playerComparison.calculationTypes.AVG': 'AVERAGE',
    'playerComparison.chart.controls.jerseyVisibility': 'Show jersey',
    'playerComparison.chart.controls.lastNameVisibility': 'Show last name',

    'configurator.group.onIce': 'On ice',
    'configurator.group.shifts': 'Shifts',
    'configurator.group.faceoffs': 'Faceoffs',
    'configurator.group.possession': 'Possession',
    'configurator.group.giveaways': 'Giveaways',
    'configurator.group.xG': 'xG',
    'configurator.group.interceptions': 'Interceptions',
    'configurator.group.recoveries': 'Recoveries',
    'configurator.group.checks': 'Checks',
    'configurator.group.entries': 'Entries',
    'configurator.group.exits': 'Exits',
    'configurator.group.boxscore': 'Boxscore',
    'configurator.group.passing': 'Passing',
    'configurator.group.shooting': 'Shooting',
    'configurator.group.pace': 'Pace',
    'configurator.group.goaltending': 'Goaltending',

    'playerComparison.metrics.team': 'Team',

    'playerComparison.metrics.games': {
        message: 'Games',
        title: 'Games'
    },
    'playerComparison.metrics.toi': {
        message: 'Time on ice',
        title: 'Time on ice'
    },
    'playerComparison.metrics.giveaways': {
        message: 'Giveaways',
        title: 'Giveaways'
    },
    'playerComparison.metrics.giveaways_dz': {
        message: 'Giveaways, DZ',
        title: 'Giveaways, DZ'
    },
    'playerComparison.metrics.giveaways_oz': {
        message: 'Giveaways, OZ',
        title: 'Giveaways, OZ'
    },
    'playerComparison.metrics.interceptions': {
        message: 'Interceptions',
        title: 'Interceptions'
    },
    'playerComparison.metrics.interceptions_oz': {
        message: 'Interceptions, OZ',
        title: 'Interceptions, OZ'
    },
    'playerComparison.metrics.interceptions_nz': {
        message: 'Interceptions, NZ',
        title: 'Interceptions, NZ'
    },
    'playerComparison.metrics.interceptions_dz': {
        message: 'Interceptions, DZ',
        title: 'Interceptions, DZ'
    },
    'playerComparison.metrics.possessions_count': {
        message: 'Number of possessions',
        title: 'Number of possessions'
    },
    'playerComparison.metrics.possessions_count_oz': {
        message: 'Number of possessions, OZ',
        title: 'Number of possessions, OZ'
    },
    'playerComparison.metrics.possessions_count_dz': {
        message: 'Number of possessions, DZ',
        title: 'Number of possessions, DZ'
    },
    'playerComparison.metrics.possessions_count_nz': {
        message: 'Number of possessions, NZ',
        title: 'Number of possessions, NZ'
    },
    'playerComparison.metrics.possession_time': {
        message: 'Possession time',
        title: 'Possession time'
    },
    'playerComparison.metrics.possession_time_oz': {
        message: 'Possession time, OZ',
        title: 'Possession time, OZ'
    },
    'playerComparison.metrics.possession_time_dz': {
        message: 'Possession time, DZ',
        title: 'Possession time, DZ'
    },
    'playerComparison.metrics.possession_time_nz': {
        message: 'Possession time, NZ',
        title: 'Possession time, NZ'
    },
    'playerComparison.metrics.recoveries': {
        message: 'Puck recoveries',
        title: 'Puck recoveries'
    },
    'playerComparison.metrics.recoveries_oz': {
        message: 'Puck recoveries, OZ',
        title: 'Puck recoveries, OZ'
    },
    'playerComparison.metrics.recoveries_dz': {
        message: 'Puck recoveries, DZ',
        title: 'Puck recoveries, DZ'
    },
    'playerComparison.metrics.recoveries_nz': {
        message: 'Puck recoveries, NZ',
        title: 'Puck recoveries, NZ'
    },
    'playerComparison.metrics.rebound_recoveries': {
        message: 'Rebound recoveries',
        title: 'Rebound recoveries'
    },
    'playerComparison.metrics.rebound_recoveries_oz': {
        message: 'Rebound recoveries, OZ',
        title: 'Rebound recoveries, OZ'
    },
    'playerComparison.metrics.rebound_recoveries_dz': {
        message: 'Rebound recoveries, DZ',
        title: 'Rebound recoveries, DZ'
    },
    'playerComparison.metrics.rebound_recoveries_nz': {
        message: 'Rebound recoveries, NZ',
        title: 'Rebound recoveries, NZ'
    },
    'playerComparison.metrics.dispossessed': {
        message: 'Dispossessed',
        title: 'Dispossessed'
    },
    'playerComparison.metrics.dispossessed_oz': {
        message: 'Dispossessed, OZ',
        title: 'Dispossessed, OZ'
    },
    'playerComparison.metrics.dispossessed_dz': {
        message: 'Dispossessed, DZ',
        title: 'Dispossessed, DZ'
    },
    'playerComparison.metrics.dispossessed_nz': {
        message: 'Dispossessed, NZ',
        title: 'Dispossessed, NZ'
    },
    'playerComparison.metrics.check': {
        message: 'Stick & body checks',
        title: 'Stick & body checks'
    },
    'playerComparison.metrics.check_oz': {
        message: 'Stick & body checks, OZ',
        title: 'Stick & body checks, OZ'
    },
    'playerComparison.metrics.check_dz': {
        message: 'Stick & body checks, DZ',
        title: 'Stick & body checks, DZ'
    },
    'playerComparison.metrics.check_nz': {
        message: 'Stick & body checks, NZ',
        title: 'Stick & body checks, NZ'
    },
    'playerComparison.metrics.body_check': {
        message: 'Body checks',
        title: 'Body checks'
    },
    'playerComparison.metrics.body_check_oz': {
        message: 'Body checks, OZ',
        title: 'Body checks, OZ'
    },
    'playerComparison.metrics.body_check_dz': {
        message: 'Body checks, DZ',
        title: 'Body checks, DZ'
    },
    'playerComparison.metrics.body_check_nz': {
        message: 'Body checks, NZ',
        title: 'Body checks, NZ'
    },
    'playerComparison.metrics.stick_check': {
        message: 'Stick checks',
        title: 'Stick checks'
    },
    'playerComparison.metrics.stick_check_oz': {
        message: 'Stick checks, OZ',
        title: 'Stick checks, OZ'
    },
    'playerComparison.metrics.stick_check_dz': {
        message: 'Stick checks, DZ',
        title: 'Stick checks, DZ'
    },
    'playerComparison.metrics.stick_check_nz': {
        message: 'Stick checks, NZ',
        title: 'Stick checks, NZ'
    },
    'playerComparison.metrics.controlled_successful_entries': {
        message: 'Successful controlled entries',
        title: 'Successful controlled entries'
    },
    'playerComparison.metrics.uncontrolled_successful_entries': {
        message: 'Successful uncontrolled entries',
        title: 'Successful uncontrolled entries'
    },
    'playerComparison.metrics.controlled_successful_exits': {
        message: 'Successful controlled exits',
        title: 'Successful controlled exit'
    },
    'playerComparison.metrics.uncontrolled_successful_exits': {
        message: 'Successful uncontrolled exits',
        title: 'Successful uncontrolled exits'
    },
    'playerComparison.metrics.controlled_failed_exits': {
        message: 'Failed controlled exits',
        title: 'Failed controlled exits'
    },
    'playerComparison.metrics.uncontrolled_failed_exits': {
        message: 'Failed uncontrolled exits',
        title: 'Failed uncontrolled exits'
    },
    'playerComparison.metrics.controlled_failed_entries': {
        message: 'Failed controlled entries',
        title: 'Failed controlled entries'
    },
    'playerComparison.metrics.uncontrolled_failed_entries': {
        message: 'Failed uncontrolled entries',
        title: 'Failed uncontrolled entries'
    },
    'playerComparison.metrics.carry_out_count': {
        message: 'Carry out exits',
        title: 'Carry out exits'
    },
    'playerComparison.metrics.dump_out_count': {
        message: 'Dump out exits',
        title: 'Dump out exits'
    },
    'playerComparison.metrics.oz_on_ice_time': {
        message: 'Player on ice time in OZ',
        title: 'Player on ice time in OZ'
    },
    'playerComparison.metrics.dz_on_ice_time': {
        message: 'Player on ice time in DZ',
        title: 'Player on ice time in DZ'
    },
    'playerComparison.metrics.on_ice_possession_for': {
        message: 'On ice possession for',
        title: 'On ice possession for'
    },
    'playerComparison.metrics.on_ice_possession_against': {
        message: 'On ice possession against',
        title: 'On ice possession against'
    },
    'playerComparison.metrics.on_ice_shots_for': {
        message: 'On ice shots for',
        title: 'On ice shots for'
    },
    'playerComparison.metrics.on_ice_shots_against': {
        message: 'On ice shots against',
        title: 'On ice shots against'
    },
    'playerComparison.metrics.on_ice_shots_on_goal_for': {
        message: 'On ice shots on goal for',
        title: 'On ice shots on goal for'
    },
    'playerComparison.metrics.on_ice_shots_on_goal_against': {
        message: 'On ice shots on goal against',
        title: 'On ice shots on goal against'
    },
    'playerComparison.metrics.on_ice_goals_against_from_danger_zone': {
        message: 'On ice goals against from danger zone',
        title: 'On ice goals against from danger zone'
    },
    'playerComparison.metrics.on_ice_goals_for': {
        message: 'On ice goals for',
        title: 'On ice goals for'
    },
    'playerComparison.metrics.on_ice_goals_against': {
        message: 'On ice goals against',
        title: 'On ice goals against'
    },
    'playerComparison.metrics.on_ice_xg_for': {
        message: 'On ice xG for',
        title: 'On ice xG for'
    },
    'playerComparison.metrics.on_ice_xg_against': {
        message: 'On ice xG against',
        title: 'On ice xG against'
    },
    'playerComparison.metrics.on_ice_scoring_chances_for': {
        message: 'On ice scoring chances for',
        title: 'On ice scoring chances for'
    },
    'playerComparison.metrics.on_ice_scoring_chances_against': {
        message: 'On ice scoring chances against',
        title: 'On ice scoring chances against'
    },
    'playerComparison.metrics.successful_passes_in_oz': {
        message: 'Successful passes, OZ',
        title: 'Successful passes, OZ'
    },
    'playerComparison.metrics.successful_passes_in_dz': {
        message: 'Successful passes, DZ',
        title: 'Successful passes, DZ'
    },
    'playerComparison.metrics.successful_passes_in_nz': {
        message: 'Successful passes, NZ',
        title: 'Successful passes, NZ'
    },
    'playerComparison.metrics.unsuccessful_passes': {
        message: 'Unsuccessful passes',
        title: 'Unsuccessful passes'
    },
    'playerComparison.metrics.unsuccessful_passes_in_oz': {
        message: 'Unsuccessful passes, OZ',
        title: 'Unsuccessful passes, OZ'
    },
    'playerComparison.metrics.unsuccessful_passes_in_dz': {
        message: 'Unsuccessful passes, DZ',
        title: 'Unsuccessful passes, DZ'
    },
    'playerComparison.metrics.unsuccessful_passes_in_nz': {
        message: 'Unsuccessful passes, NZ',
        title: 'Unsuccessful passes, NZ'
    },
    'playerComparison.metrics.primary_potential_assists': {
        message: 'Potential primary assists',
        title: 'Potential primary assists'
    },
    'playerComparison.metrics.secondary_potential_assists': {
        message: 'Potential secondary assists',
        title: 'Potential secondary assists'
    },
    'playerComparison.metrics.primary_assists': {
        message: 'Primary assists',
        title: 'Primary assists'
    },
    'playerComparison.metrics.secondary_assists': {
        message: 'Secondary assists',
        title: 'Secondary assists'
    },
    'playerComparison.metrics.key_passes': {
        message: 'Key passes',
        title: 'Key passes'
    },
    'playerComparison.metrics.xg_danger_zone': {
        message: 'xG from danger zone',
        title: 'xG from danger zone'
    },
    'playerComparison.metrics.xa': {
        message: 'xA',
        title: 'xA'
    },
    'playerComparison.metrics.xa2': {
        message: 'xA secondary',
        title: 'xA secondary'
    },
    'playerComparison.metrics.xa_against': {
        message: 'On ice xA against',
        title: 'On ice xA against'
    },
    'playerComparison.metrics.giveaways_%': {
        message: 'Giveaways, %',
        title: 'Giveaways, %'
    },
    'playerComparison.metrics.giveaways_dz%': {
        message: 'Giveaways DZ, %',
        title: 'Giveaways DZ, %'
    },
    'playerComparison.metrics.giveaways_oz%': {
        message: 'Giveaways OZ, %',
        title: 'Giveaways OZ, %'
    },
    'playerComparison.metrics.face_off_won': {
        message: 'Faceoffs won',
        title: 'Faceoffs won'
    },
    'playerComparison.metrics.face_off_lost': {
        message: 'Faceoffs lost',
        title: 'Faceoffs lost'
    },
    'playerComparison.metrics.oz_face_offs': {
        message: 'On ice faceoffs, OZ',
        title: 'On ice faceoffs, OZ'
    },
    'playerComparison.metrics.dz_face_offs': {
        message: 'On ice faceoffs, DZ',
        title: 'On ice faceoffs, DZ'
    },
    'playerComparison.metrics.nz_face_offs': {
        message: 'On ice faceoffs, NZ',
        title: 'On ice faceoffs, NZ'
    },
    'playerComparison.metrics.blocked_shots_by': {
        message: 'Blocked shots',
        title: 'Blocked shots'
    },
    'playerComparison.metrics.shot_attempts': {
        message: 'Shot attempts',
        title: 'Shot attempts'
    },
    'playerComparison.metrics.goals': {
        message: 'Goals',
        title: 'Goals'
    },
    'playerComparison.metrics.shifts': {
        message: 'Number of shifts',
        title: 'Number of shifts'
    },
    'playerComparison.metrics.clearances': {
        message: 'Clearances',
        title: 'Clearances'
    },
    'playerComparison.metrics.total_game_score': {
        message: 'Total game score',
        title: 'Total game score'
    },
    'playerComparison.metrics.offensive_game_score': {
        message: 'Offensive game score',
        title: 'Offensive game score'
    },
    'playerComparison.metrics.defensive_game_score': {
        message: 'Defensive game score',
        title: 'Defensive game score'
    },
    'playerComparison.metrics.shots_on_goal': {
        message: 'Shots on goal',
        title: 'Shots on goal'
    },
    'playerComparison.metrics.shots_on_goal_danger_zone': {
        message: 'Shots on goal from danger zone',
        title: 'Shots on goal from danger zone'
    },
    'playerComparison.metrics.shots_blocked_for': {
        message: 'On ice shots blocked for',
        title: 'On ice shots blocked for'
    },
    'playerComparison.metrics.shots_blocked_against': {
        message: 'On ice shots blocked against',
        title: 'On ice shots blocked against'
    },
    'playerComparison.metrics.shots_blocked': {
        message: 'Shots blocked',
        title: 'Shots blocked'
    },
    'playerComparison.metrics.successful_passes': {
        message: 'Successful passes',
        title: 'Successful passes'
    },
    'playerComparison.metrics.pass_in_entries': {
        message: 'Pass in entries',
        title: 'Pass in entries'
    },

    'playerComparison.metrics.carry_in_entries': {
        message: 'Carry in entries',
        title: 'Carry in entries'
    },
    'playerComparison.metrics.dump_in_entries': {
        message: 'Dump in entries',
        title: 'Dump in entries'
    },
    'playerComparison.metrics.pass_out_exits': {
        message: 'Pass out exits',
        title: 'Pass out exits'
    },
    'playerComparison.metrics.shots_on_goal_for': {
        message: 'On ice shots on goal for',
        title: 'On ice shots on goal for'
    },

    'playerComparison.metrics.on_ice_xg_for_from_danger_zone': {
        message: 'On ice xG for from danger zone',
        title: 'On ice xG for from danger zone'
    },
    'playerComparison.metrics.on_ice_xg_against_from_danger_zone': {
        message: 'On ice xG against from danger zone',
        title: 'On ice xG against from danger zone'
    },
    'playerComparison.metrics.shot_attempts_from_danger_zone': {
        message: 'Shot attempts from danger zone',
        title: 'Shot attempts from danger zone'
    },
    'playerComparison.metrics.shots_on_goal_from_danger_zone': {
        message: 'Shots on goal from danger zone',
        title: 'Shots on goal from danger zone'
    },
    'playerComparison.metrics.xg': {
        message: 'xG',
        title: 'xG'
    },
    'playerComparison.metrics.on_ice_xg_20': {
        message: 'xG per 20',
        title: 'xG per 20'
    },
    'playerComparison.metrics.on_ice_xg_for_20': {
        message: 'on ice xG for per 20',
        title: 'on ice xG for per 20'
    },
    'playerComparison.metrics.on_ice_xg_against_20': {
        message: 'on ice xG against per 20',
        title: 'on ice xG against per 20'
    },
    'playerComparison.metrics.pace': {
        message: 'Pace %',
        title: 'Pace %'
    },
    'playerComparison.metrics.backchecking_pace': {
        message: 'Backchecking pace %',
        title: 'Backchecking pace %'
    },
    'playerComparison.metrics.shots_with_rebound': {
        message: 'Shots with rebound',
        title: 'Shots with rebound'
    },
    'playerComparison.metrics.shots_missed': {
        message: 'Shots missed net',
        title: 'Shots missed net'
    },
    'playerComparison.metrics.average_toi': {
        message: 'Time on ice, avg',
        title: 'Time on ice, avg'
    },
    'playerComparison.metrics.shots_on_goal_against': {
        message: 'On ice shots on goal against',
        title: 'On ice shots on goal against'
    },
    'playerComparison.metrics.xg_btn_against': {
        message: 'xG behind the net against',
        title: 'xG behind the net against'
    },
    'playerComparison.metrics.xg_cl_against': {
        message: 'xG CL against',
        title: 'xG after center lane assist against'
    },
    'playerComparison.metrics.goals_one_timer': {
        message: 'One timer goals',
        title: 'One timer goals'
    },
    'playerComparison.metrics.avg_rest_time': {
        message: 'Avg. rest time',
        title: 'Avg. rest time'
    },
    'playerComparison.metrics.max_shoot_speed': {
        message: 'Max. shot speed',
        title: 'Max. shot speed'
    },
    'playerComparison.metrics.avg_shoot_speed': {
        message: 'Avg. shot speed',
        title: 'Avg. shot speed'
    },
    'playerComparison.metrics.scoring_chances': {
        message: 'Scoring chances',
        title: 'Scoring chances'
    },
    'playerComparison.metrics.avgShiftLength': {
        message: 'Avg. shift length',
        title: 'Avg. shift length'
    },
    'playerComparison.metrics.faceOffWonRelative': {
        message: 'Faceoffs won, %',
        title: 'Faceoffs won, %'
    },
    'playerComparison.metrics.zoneStarts': {
        message: 'Zone starts, %',
        title: 'Zone starts, %'
    },
    'playerComparison.metrics.avgPossessionTimeOz': {
        message: 'Time per possession, OZ',
        title: 'Time per possession, OZ'
    },
    'playerComparison.metrics.avgPossessionTimeNz': {
        message: 'Time per possession, NZ',
        title: 'Time per possession, NZ'
    },
    'playerComparison.metrics.avgPossessionTimeDz': {
        message: 'Time per possession, DZ',
        title: 'Time per possession, DZ'
    },
    'playerComparison.metrics.avgPossessionTime': {
        message: 'Time per possession',
        title: 'Time per possession'
    },
    'playerComparison.metrics.offensiveZoneStarts': {
        message: 'Offensive zone starts',
        title: 'Offensive zone starts'
    },
    'playerComparison.metrics.defensiveZoneStarts': {
        message: 'Defensive zone starts',
        title: 'Defensive zone starts'
    },

    'playerComparison.metrics.neutralZoneStarts': {
        message: 'Neutral zone starts',
        title: 'Neutral zone starts'
    },
    'playerComparison.metrics.giveawaysNz': {
        message: 'Giveaways, NZ',
        title: 'Giveaways, NZ'
    },
    'playerComparison.metrics.giveawaysNzRelative': {
        message: 'Giveaways NZ, %',
        title: 'Giveaways NZ, %'
    },
    'playerComparison.metrics.onIceXgRelative': {
        message: 'xG %',
        title: 'xG %'
    },
    'playerComparison.metrics.onIceScoringChances': {
        message: 'On ice scoring chances, %',
        title: 'On ice scoring chances, %'
    },
    'playerComparison.metrics.scoringChancesPerShotAttempts': {
        message: 'Scoring chances / shots',
        title: 'Scoring chances / shots'
    },
    'playerComparison.metrics.key_passes_against': {
        message: 'On ice key passes against',
        title: 'On ice key passes against'
    },
    'playerComparison.metrics.passing_accuracy': {
        message: 'Passing accuracy, %',
        title: 'Passing accuracy, %'
    },
    'playerComparison.metrics.passing_in_dz': {
        message: 'Passing accuracy DZ, %',
        title: 'Passing accuracy DZ, %'
    },
    'playerComparison.metrics.passing_in_nz': {
        message: 'Passing accuracy NZ, %',
        title: 'Passing accuracy NZ, %'
    },
    'playerComparison.metrics.passing_in_oz': {
        message: 'Passing accuracy OZ, %',
        title: 'Passing accuracy OZ, %'
    },
    'playerComparison.metrics.rebound_recoveries_relative': {
        message: 'Rebound recoveries, %',
        title: 'Rebound recoveries, %'
    },
    'playerComparison.metrics.controlledEntriesRelative': {
        message: 'Controlled entries, %',
        title: 'Controlled entries, %'
    },
    'playerComparison.metrics.controlledExitsRelative': {
        message: 'Controlled exits, %',
        title: 'Controlled exits, %'
    },
    'playerComparison.metrics.successExitsRelative': {
        message: 'Successful exits, %',
        title: 'Successful exits, %'
    },
    'playerComparison.metrics.successEntriesRelative': {
        message: 'Successful entries, %',
        title: 'Successful entries, %'
    },
    'playerComparison.metrics.goals_danger_zone': {
        message: 'Goals from danger zone',
        title: 'Goal from danger zone'
    },

    'playerComparison.metrics.plus_minus': {
        message: `+/-`,
        title: ''
    },
    'playerComparison.metrics.shotsOnGoalRelative': {
        message: 'On ice shots on goal',
        title: 'On ice shots on goal'
    },
    'playerComparison.metrics.goals_after_rebounds': {
        message: 'Goals after rebounds',
        title: 'Goals after rebounds'
    },
    'playerComparison.metrics.clearancesRelative': {
        message: 'Clearances, %',
        title: 'Clearances, %'
    },
    'playerComparison.metrics.possessionRelative': {
        message: 'On ice possession, %',
        title: 'On ice possession, %'
    },
    'playerComparison.metrics.puckTimeInOZRelative': {
        message: 'On ice puck time in OZ, %',
        title: 'On ice puck time in OZ, %'
    },
    'playerComparison.metrics.passes': {
        message: 'Passes',
        title: 'Passes'
    },
    'playerComparison.metrics.potential_assists': {
        message: 'Potential assists',
        title: 'Potential assists'
    },
    'playerComparison.metrics.hitDelivered': {
        message: 'Hit delivered',
        title: 'Hit delivered'
    },
    'playerComparison.metrics.hitTaken': {
        message: 'Hit taken',
        title: 'Hit taken'
    },
    'playerComparison.metrics.jersey': {
        message: 'Jersey',
        title: 'Jersey'
    },
    'playerComparison.metrics.players': {
        message: 'Players',
        title: 'Players'
    },
    'playerComparison.metrics.gamesPlayed': {
        message: 'Games played',
        title: 'Games played'
    },
    'playerComparison.metrics.shotsOnGoal': {
        message: 'Shots on goal',
        title: 'Shots on goal'
    },
    'playerComparison.metrics.shotOnGoalPerTime': {
        message: 'Shots on goal per minute',
        title: 'Shots on goal per minute'
    },
    'playerComparison.metrics.shotOnGoalPerTimeDeflected': {
        message: 'Deflected shot on goal per minute',
        title: 'Deflected shot on goal per minute'
    },
    'playerComparison.metrics.scoringChances': {
        message: 'Scoring chances',
        title: 'Scoring chances'
    },
    'playerComparison.metrics.Goals': {
        message: 'Goals',
        title: 'Goals'
    },
    'playerComparison.metrics.goalsAllowed': {
        message: 'Goals Allowed',
        title: 'Goals Allowed'
    },
    'playerComparison.metrics.scoringChancesSave': {
        message: 'Scoring Chance Save %',
        title: 'Scoring Chance Save %'
    },
    'playerComparison.metrics.save': {
        message: 'Save %',
        title: 'Save %'
    },
    'playerComparison.metrics.avgShotDistance': {
        message: 'Avg. shot distance,m',
        title: 'Avg. shot distance,m'
    },
    'playerComparison.metrics.rebound': {
        message: 'Rebound %',
        title: 'Rebound %'
    },
    'playerComparison.metrics.avgAngleOfDeflection': {
        message: 'Avg. Angle of deflection',
        title: 'Avg. Angle of deflection'
    },

    /***************************************************************Pre-scout product**************************************************/
    'summary.stats.table': 'stats',

    /***************************************************************Scout product**************************************************/
    'scout.player.selected': 'Selected player',
    'scout.player.games': 'Games',
    'scout.player.toi': 'Time on ice',
    'scout.player.avgToi': 'Avg. time on ice',

    /***************************************************************Widget**************************************************/
    'widget.header.per20View': 'PER 20',
    'widget.header.perGameView': 'PER GAME',
    'widget.header.defaultView': 'Total',
    'widget.header.per2Pp': 'PER PP',
    'widget.header.per2Pk': 'PER PK',
    'widget.header.onIce': 'ON ICE',
    'widget.header.individual': 'INDIVIDUAL',
    'widget.header.favorites': 'FAVORITES',
    'widget.header.metrics': 'Metrics',
    'widget.header.video': 'Video',
    'widget.header.axisChart': 'Axis chart',

    /**************************************************************Post game reports************************************/
    'postGameReportPage.tables.columns.game': 'GAME',
    'postGameReportPage.tables.columns.season': 'SEASON',
    'postGameReportPage.tables.columns.goal': 'GOAL',
    'postGameReportPage.tables.columns.lineXg': 'LINE xG %',
    'postGameReportPage.tables.columns.lineToi': 'LINE TOI',
    'postGameReportPage.tables.rows.possessionTime': 'Possession time, %',
    'postGameReportPage.tables.rows.puckToiOz': 'Time in OZ, %',
    'postGameReportPage.tables.rows.shotAttempts': 'Shot attempts, %',
    'postGameReportPage.tables.rows.shotAttemptsDangerZone': 'SA from danger zone, %',
    'postGameReportPage.tables.rows.shotsOnGoal': 'Shots on goal, %',
    'postGameReportPage.tables.rows.xg': 'xG, %',
    'postGameReportPage.tables.rows.giveaways': 'Giveaways, %',
    'postGameReportPage.tables.rows.faceOffWon': 'Faceoffs won, %',
    'postGameReportPage.tables.rows.successfulEntries': 'Entries success, %',
    'postGameReportPage.tables.rows.successfulExits': 'Exits success, %',
    'postGameReportPage.tables.rows.savesDangerZone': 'High danger saves, %',
    'postGameReportPage.tables.rows.controlledEntries': 'Controlled entries, %',
    'postGameReportPage.tables.rows.controlledExits': 'Controlled exits, %',
    'postGameReportPage.tables.rows.ppTotal': 'PP number / PP time',
    'postGameReportPage.tables.rows.powerPlayGoals': 'Power play, %',
    'postGameReportPage.tables.rows.xgForPer2': 'xG for per 2 min',
    'postGameReportPage.tables.rows.reboundRecoveriesOz': 'Rebound rec. in OZ, %',
    'postGameReportPage.tables.rows.pkTotal': 'PK number / PK time',
    'postGameReportPage.tables.rows.penaltyKillGoalsAgainst': 'Penalty kill, %',
    'postGameReportPage.tables.rows.xgAgainstPer2': 'xG against per 2 min',
    'postGameReportPage.tables.rows.shotAttemptsDangerZoneAgainst': 'High danger shots, %',
    'postGameReportPage.tables.rows.reboundRecoveriesDz': 'Rebound rec. in DZ, %',
    'postGameReportPage.titles.evenStrength': 'EVEN STRENGTH',
    'postGameReportPage.titles.offenseGameScores': 'OFFENSE GAME SCORES',
    'postGameReportPage.titles.defenseGameScores': 'DEFENSE GAME SCORES',
    'postGameReportPage.titles.GameScores': 'PLAYERS',
    'postGameReportPage.titles.defensemen': 'DEFENSEMEN 5v5',
    'postGameReportPage.titles.forwards': 'FORWARDS 5v5',
    'postGameReportPage.titles.powerPlay': 'POWER PLAY',
    'postGameReportPage.titles.penaltyKill': 'PENALTY KILL',
    'postGameReportPage.titles.shotMap': 'SHOT MAP',
    'postGameReportPage.labels.shotsFor': 'Shots for',
    'postGameReportPage.info.xg': 'Total xG',
    'postGameReportPage.info.evXg': 'EV xG',
    'postGameReportPage.info.shotAttempts': 'Shot attempts',
    'postGameReportPage.info.shotAttemptsFromDangerZone': 'SA from danger zone',
    'postGameReportPage.titles.goalie': 'GOALIES',
    'postGameReportPage.titles.noDefencemen': 'NO DEFENSEMEN',
    'postGameReportPage.titles.noForwards': 'NO FORWARDS',

    /***************************************************************Help**************************************************/
    'summary.xGMap.helpTitle': 'What are “expected goals” (xG)?',
    'summary.xGMap.helpBody':
        'xG, or expected goals, considers the chance that every shot has to be a goal. \n' +
        'You can compare it to a scoring chance but the result is much more accurate and it gives a better overall picture as every shot is factored in. We use over 30 paramaters (such as traffic in front of the net and shot location) to determine this chance. \n' +
        '\n' +
        '0.05 xG means the shot had a 5% of becoming a goal. 0.00 xG means the shot had less than a 1% chance of becoming a goal. The bigger the dot the greater the chance. ',
    'summary.xGMap.dz': 'Defensive zone',
    'summary.xGMap.oz': 'Offensive zone',
    'summary.skaters.helpTitle': 'What are the Three Stars?',
    'summary.skaters.helpBody':
        'The TPE team "3 stars" of the game are selected from 12 different parameters automatically. These parameters look at the players overall play to determine who actually played best. For a forward, offense holds more weight. For a defenseman, defensive play holds more weight.',
    'summary.xGTimeline.helpTitle': 'What is the xG Timeline?',
    'summary.xGTimeline.helpBody':
        'In hockey there are a very large amount of shot attempts. Because of this the reality is that the chance a shot has to be a goal is actually much lower than in other sports. This timeline shows the "flow" of the game and which team led the game offensively the most.',
    'skaters.efficiency.helpTitle': 'What is the efficiency chart?',
    'skaters.efficiency.helpBody':
        'This map takes Corsi numbers (Shot attempts for and against) and visualizes them. A "fun" player is on the ice for many shots in general (both for and against). A "dull" player - the opposite. The closer the player is to the "good" portion of the chart the more they helped their team produce offense and maintain possession while they were on the ice.',
    'skaters.xGTopSkaters.helpTitle': 'What is the xG Top skaters?',
    'skaters.xGTopSkaters.helpBody':
        'xG % = xG for/ (xG for + xG against) this shows that a player was on the ice for more quality chances for than quality chances against. In "All Skaters" all percentages are calculated this way with xG in the formula being switched with each of those different metrics.',
    'passing.chart.helpTitle': 'What is the passing chart?',
    'passing.chart.helpBody':
        'This chart demonstrates playmaking ability by visualizing the ratio of potential assists to passes. The more passes a  player makes that become potential assists the better. A potential assist is a pass that results in a quality shot attempt.',
    'skating.chart.helpTitle': 'What is the skating chart?',
    'skating.chart.helpBody':
        "Pace plays an important role in each game. Pace % is the amount of the distance skated above 20 km/h. This chart shows how a player skates throughout the course of their shift and also shows what a player's optimal shift length is. If you notice a players pace % spike at the end of the chart it is from the effort put into coming back to the bench for a change.",

    /******************************************Scout**********************************************************/
    'scout.playerPage.playerProfile': 'Player profile',
    'scout.playerPage.playerDetails': 'Player details',
    'scout.playerPage.team': 'Team',
    'scout.playerPage.contract': 'Contract',
    'scout.playerPage.skills': 'Skills',
    'scout.playerPage.videos': 'Videos',
    'scout.playerPage.evaluation': 'Evaluation',
    'scout.playerPage.notes': 'Notes',
    'scout.playerPage.combine': 'Combine',
    'scout.playerPage.schedule': 'Schedule',

    'scout.home.searchTable': 'Search',
    'scout.home.searchTable.filterPredicate': 'All Players',
    'scout.home.searchTable.textQuery': 'Name Search',
    'scout.home.searchTable.recentList': 'Recent',
    'scout.home.searchTable.favoritesList': 'Favorites',
    'scout.home.searchTable.columns.photo': 'Photo',
    'scout.home.searchTable.columns.name': 'Name and surname',
    'scout.home.searchTable.columns.teamTitle': '{teamNameEn}',
    'scout.home.searchTable.columns.games': 'Games',
    'scout.home.searchTable.columns.players': 'Players',
    'scout.home.searchTable.columns.age': 'Age',
    'scout.home.searchTable.columns.position': 'Position',
    'scout.home.searchTable.columns.performance': 'Performance',
    'scout.home.searchTable.columns.height': 'Height',
    'scout.home.searchTable.columns.weight': 'Weight',
    'scout.home.searchTable.columns.toi': 'Avg TOI',
    'scout.home.searchTable.columns.DgZShoot': 'Shooting from DgZ,\u00A0%',
    'scout.home.searchTable.columns.shotsOnGoalPer20': 'Shots on goal',
    'scout.home.searchTable.columns.xGRelative': 'xG %',
    'scout.home.searchTable.columns.controlledExits': 'Controlled exits,\u00A0%',
    'scout.home.searchTable.columns.controlledEntries': 'Controlled entries,\u00A0%',
    'scout.home.searchTable.columns.giveawaysRelative': 'Giveaways,\u00A0%',
    'scout.home.searchTable.columns.interceptions': 'Interceptions',
    'scout.home.searchTable.columns.reboundRecoveries': 'Rebound recoveries',
    'scout.home.searchTable.columns.xA': 'xA',
    'scout.home.searchTable.columns.keyPasses': 'Key passes',
    'scout.home.searchTable.columns.shotsOnGoal': 'Shots on goal',
    'scout.home.searchTable.columns.shotsBlocked': 'Shots blocked',
    'scout.home.searchTable.columns.successfulPassesNz': 'Successful passes\u00A0NZ',
    'scout.home.searchTable.columns.shotsBlockingRelative': 'Shots blocking, %',
    'scout.home.searchTable.columns.successfulPasses': 'Successful passes,\u00A0%',
    'scout.home.searchTable.columns.xA2': 'xA2',
    'scout.home.searchTable.columns.xGAfterLTHPass': 'xG after LTH pass',
    'scout.home.searchTable.columns.team': 'Team',
    'scout.home.searchTable.columns.date': 'Date',
    'scout.home.searchTable.columns.scout': 'Scout',

    'scout.home.searchTable.tooltip.age': 'Age',
    'scout.home.searchTable.tooltip.height': 'Height, cm',
    'scout.home.searchTable.tooltip.weight': 'Weight, kg',
    'scout.home.searchTable.tooltip.handedness': 'Shoots',
    'scout.home.searchTable.tooltip.shoot': '${values.shoots}',
    'scout.home.gamesPlayed.columns.opponent': 'Opponent',
    'scout.home.searchTable.tooltip.citizenship': 'Nationality',
    'scout.home.filtersSearch.noResults': 'No players matching filters.',
    'scout.home.textQuerySearch.noResults': 'No players found.',
    'scout.home.gameShifts.selectSkater': 'Select skater to view video.',
    'scout.home.searchTable.noAccessToPlayer': 'You\u00A0have\u00A0no\u00A0access\u00A0to\u00A0this\u00A0player.',
    'scout.home.searchTable.noFavorites': 'Favorites list is empty',
    'scout.home.searchTable.noRecents': 'Players watch history is empty',
    'scout.playerSelectWidget.playerComparison': 'Players comparison',
    'scout.playerSelectWidget.playersPage': 'Players page',
    'scout.playerSelectWidget.playerSelect': 'Select player page',
    'scout.playerSelectWidget.addToFavorites': 'Add to favorite',
    'scout.playerSelectWidget.removeFromFavorites': 'Remove from favorite',
    'scoutHome.teams.logo': 'Logo',
    'scoutHome.teams.nameEn': 'Team name',
    'scoutHome.teams.leagueName': 'League',
    'scoutHome.teams.gamesPlayed': 'Games played',
    'scoutHome.teams.goalsFor': 'Goals for',
    'scoutHome.teams.goalsAgainst': 'Goals against',
    'scoutHome.teams.shotAttemptsFor': 'Shot attempts for',
    'scoutHome.teams.shotAttemptsAgainst': 'Shot attempts agains',
    'scoutHome.teams.shotAttemptsRelative': 'Shot attempts %',
    'scoutHome.teams.shotsOnGoalFor': 'Shots on goal for',
    'scoutHome.teams.shotsOnGoalAgainst': 'Shots on goal against',
    'scoutHome.teams.shotsOnGoalRelative': 'Shots on goal %',
    'scoutHome.teams.scoringChancesFor': 'Scoring chances for',
    'scoutHome.teams.scoringChancesAgainst': 'Scoring chances against',
    'scoutHome.teams.scoringChancesRelative': 'Scoring chances percentage',
    'scoutHome.teams.xGFor': 'xG for',
    'scoutHome.teams.xGAgainst': 'xG against',
    'scoutHome.teams.xGRelative': 'xG %',
    'scoutHome.teams.faceoffsWon': 'faceoffs won',
    'scoutHome.teams.faceoffsLost': 'faceoffs lost',
    'scoutHome.teams.faceoffsRelative': 'faceoffs %',
    'scout.suggestion.player.name': '{firstNameEn} {lastNameEn}',

    /*******************************Scout metrics*******************************/

    'scout.metrics.groupNames.boxscore': 'Boxscore',
    'scout.metrics.groupNames.faceoffs': 'Faceoffs',
    'scout.metrics.groupNames.goalie': 'Goalie',
    'scout.metrics.groupNames.other': 'Other',
    'scout.metrics.groupNames.recoveries': 'Recoveries',
    'scout.metrics.groupNames.shooting': 'Shooting',
    'scout.metrics.groupNames.shifts': 'Shifts',
    'scout.metrics.groupNames.onice': 'On ice',

    'scout.metrics.onIceScoringChances': 'On ice scoring chances, %',
    'scout.metrics.onIceScoringChances.description':
        'This metric shows the ratio between chosen team scoring chances and scoring chances of both teams',

    'scout.metrics.onIceShotsFor': 'On ice shots for',
    'scout.metrics.onIceShotsFor.description':
        'This metric is a counter of shots of the team with the specific player on ice.',

    'scout.metrics.onIceShotsAgainst': 'On ice shots against',
    'scout.metrics.onIceShotsAgainst.description':
        'This metric is a counter of shots to the team with the specific player on ice.',

    'scout.metrics.onIceGoalsAgainstFromDangerZone': 'On ice goals against from danger zone',
    'scout.metrics.onIceGoalsAgainstFromDangerZone.description':
        'This metric is a counter of goals to the team from danger zone with the specific player on ice',

    'scout.metrics.onIceGoalsFor': 'On ice goals for',
    'scout.metrics.onIceGoalsFor.description':
        'This metric is a counter of goals of the team with the specific player on ice',

    'scout.metrics.onIceGoalsAgainst': 'On ice goals against',
    'scout.metrics.onIceGoalsAgainst.description':
        'This metric is a counter of goals to the team with the specific player on ice',

    'scout.metrics.onIceScoringChancesFor': 'On ice scoring chances for',
    'scout.metrics.onIceScoringChancesFor.description':
        'This metric is a counter of scoring chances of the team with a specific player on ice',

    'scout.metrics.onIceScoringChancesAgainst': 'On ice scoring chances against',
    'scout.metrics.onIceScoringChancesAgainst.description':
        'This metric is a counter of scoring chances of the opposite team with a specific player on ice',

    'scout.metrics.onIceShotsOnGoalFor': 'On ice shots on goal for',
    'scout.metrics.onIceShotsOnGoalFor.description':
        'This metric is a counter of shots on goal of the team with the specific player on ice',

    'scout.metrics.onIceShotsOnGoalAgainst': 'On ice shots on goal against',
    'scout.metrics.onIceShotsOnGoalAgainst.description':
        'This metric is a counter of shots on goal to the team with the specific player on ice',

    'scout.metrics.onIceShotsOnGoalRelative': 'On ice shots on goal',
    'scout.metrics.onIceShotsOnGoalRelative.description':
        'This metric shows the ratio between shots on goal of the team and summary shots on goal of both teams with a specific player on ice',

    'scout.metrics.avgToi': 'Avg. TOI',
    'scout.metrics.avgToi.description': 'This metric shows tha average time on ice of the player',

    'scout.metrics.avgShiftLength': 'Avg. shift length',
    'scout.metrics.avgShiftLength.description': 'This metric shows tha average shift length of the player',

    'scout.metrics.avgShifts': 'Shifts per game average',
    'scout.metrics.avgShifts.description': 'This metric shows an average shifts quantity of the player per one game',

    'scout.metrics.avgRestTime': 'Avg. rest time',
    'scout.metrics.avgRestTime.description': 'This metric shows tha average rest time of the player',

    'scout.metrics.faceOffWonRelative': 'Faceoffs won, %',
    'scout.metrics.faceOffWonRelative.description':
        'This metric shows the ratio between won faceoffs and overall faceoffs for the game',

    'scout.metrics.zoneStarts': 'Zone starts, %',
    'scout.metrics.zoneStarts.description':
        'This metric shows the ratio between offensive zone starts and overall starts during the game',

    'scout.metrics.offensiveZoneStarts': 'Offensive zone starts',
    'scout.metrics.offensiveZoneStarts.description':
        'This metric is a counter of face offs which have been occured in offensive zone. ',

    'scout.metrics.defensiveZoneStarts': 'Defensive zone starts',
    'scout.metrics.neutralZoneStarts': 'Neutral zone starts',

    'scout.metrics.defensiveZoneStarts.description':
        'This metric is a counter of face offs which have been occured in defensive zone. ',

    'scout.metrics.neutralZoneStarts.description':
        'This metric is a counter of face offs which have been occured in neutral zone. ',

    'scout.metrics.faceOffWon': 'Faceoffs won',
    'scout.metrics.faceOffWon.description': 'This metric is a counter of won faceoffs',

    'scout.metrics.faceOffLost': 'Faceoffs lost',
    'scout.metrics.faceOffLost.description': 'This metric is a counter of lost faceoffs',

    'scout.metrics.recoveries': 'Puck recoveries',
    'scout.metrics.recoveries.description': 'This metric is a counter of overall puck recoveries during the game',

    'scout.metrics.recoveriesOz': 'Puck recoveries, OZ',
    'scout.metrics.recoveriesOz.description':
        'This metric is a counter of overall puck recoveries during the game in the offensive zone',

    'scout.metrics.recoveriesDz': 'Puck recoveries, DZ',
    'scout.metrics.recoveriesDz.description':
        'This metric is a counter of overall puck recoveries during the game in the defensive zone',

    'scout.metrics.recoveriesNz': 'Puck recoveries, NZ',
    'scout.metrics.recoveriesNz.description':
        'This metric is a counter of overall puck recoveries during the game in the neutral zone',

    'scout.metrics.reboundRecoveries': 'Rebound recoveries',
    'scout.metrics.reboundRecoveries.description':
        'This metric is a counter of overall rebound recoveries during the game',

    'scout.metrics.reboundRecoveriesOz': 'Rebound recoveries, OZ',
    'scout.metrics.reboundRecoveriesOz.description':
        'This metric is a counter of overall rebound recoveries during the game in the offensive zone',

    'scout.metrics.reboundRecoveriesDz': 'Rebound recoveries, DZ',
    'scout.metrics.reboundRecoveriesDz.description':
        'This metric is a counter of overall rebound recoveries during the game in the defensive zone',

    'scout.metrics.goalsDangerZone': 'Goals from danger zone',
    'scout.metrics.goalsDangerZone.description': 'This metric is a counter of the goals scored from the danger zone',

    'scout.metrics.realPrimaryAssists': 'Primary assists',
    'scout.metrics.realPrimaryAssists.description': 'This metric is a counter of primary assists',

    'scout.metrics.realSecondaryAssists': 'Secondary assists',
    'scout.metrics.realSecondaryAssists.description': 'This metric is a counter of secondary assists',

    'scout.metrics.goals': 'Goals',
    'scout.metrics.goals.description': 'This metric is a counter of scored goals',

    'scout.metrics.goalsAfterRebounds': 'Goals after rebounds',
    'scout.metrics.goalsAfterRebounds.description': 'This metric is a counter of goals scored after rebounds',

    'scout.metrics.shotAttemptsFromDangerZone': 'Shot attempts from danger zone',
    'scout.metrics.shotAttemptsFromDangerZone.description': 'This metric is a counter of shot attemps from danger zone',

    'scout.metrics.shotsWithRebound': 'Shots with rebound',
    'scout.metrics.shotsWithRebound.description': 'This metric is a counter of shots with rebound',

    'scout.metrics.shotsMissed': 'Shots missed',
    'scout.metrics.shotsMissed.description': 'This metric is a counter of missed shots',

    'scout.metrics.blockedShots': 'Blocked shots',
    'scout.metrics.blockedShots.description': 'This metric is a counter of blocked shots',

    'scout.metrics.shotAttempts': 'Shot attempts',
    'scout.metrics.shotAttempts.description': 'This metric is a counter of shot attemps ',

    'scout.metrics.shotsOnGoal': 'Shots on goal',
    'scout.metrics.shotsOnGoal.description': 'This metric is a counter of shots on goal',

    'scout.metrics.shotsBlocked': 'Shots blocked',
    'scout.metrics.shotsBlocked.description': 'This metric is a counter of shots blocked',

    'scout.metrics.shotsOnGoalDangerZone': 'Shots on goal from danger zone',
    'scout.metrics.shotsOnGoalDangerZone.description': 'This metric is a counter of shots on goal danger zone',

    'scout.metrics.shotsBlockedDangerZone': 'Blocked shots from danger zone',
    'scout.metrics.shotsBlockedDangerZone.description': 'This metric is a counter of blocked shots from danger zone',

    'scout.metrics.shotsMissedDangerZone': 'Missed shots from danger zone',
    'scout.metrics.shotsMissedDangerZone.description': 'This metric is a counter of missed shots from danger zone',

    'scout.metrics.scoringChances': 'Scoring chances',
    'scout.metrics.scoringChances.description': 'This metric is a counter of scoring chances',

    'scout.metrics.scoringChancesPerShotAttempts': 'Scoring chances / shots',
    'scout.metrics.scoringChancesPerShotAttempts.description':
        'This metric shows the ratio between scoring chances and shot attempts',

    'scout.metrics.savesPerc': 'Saves %',
    'scout.metrics.savesPerc.description': 'This metric is a ratio between saves and shots on goal to the goalie',

    'scout.metrics.goalsAgainstAverage': 'Goals against average',
    'scout.metrics.goalsAgainstAverage.description':
        'This metric is a counter of average quantity goals to the goalie per game',

    'scout.metrics.savesAverage': 'Saves average',
    'scout.metrics.savesAverage.description':
        'This metric is a counter of average quantity of saves of the goalie per game',

    'scout.metrics.shotsOnGoalAgainst': 'Shots against',
    'scout.metrics.shotsOnGoalAgainst.description': 'This metric is a counter of overall shots on goal to the goalie',

    'scout.metrics.shotsOnGoalDangerZoneAgainst': 'Danger zone shots',
    'scout.metrics.shotsOnGoalDangerZoneAgainst.description':
        'This metric is a counter of overall shots on goal to the goalie from the danger zone',

    'scout.metrics.dangerZoneSave': 'Danger zone save, %',
    'scout.metrics.dangerZoneSave.description': 'This metric is a ratio between saves and shots on goal to the goalie',

    'scout.metrics.pp': 'Power play',
    'scout.metrics.pP.description': ' This metric shows the average time on ice while the team has power play',

    'scout.metrics.pk': 'Penalty kill',
    'scout.metrics.pk.description': ' This metric shows the average time on ice while the team has penalty kill',

    'scout.metrics.pts': 'Points',
    'scout.metrics.pts.description': ' Total number of goals plus primary assists plus secondary assists',

    'scout.metrics.plusMinus': '+/-',
    'scout.metrics.plusMinus.description': `A player is awarded a 'plus' each time he is on the ice when his Club scores an even-strength or shorthanded goal. He receives a 'minus' if he is on the ice for an even-strength or shorthanded goal scored by the opposing Club. The difference in these numbers is considered the player's 'plus-minus' statistic.`,

    'scout.metrics.gamesSkaters': 'Number of games',
    'scout.metrics.shifts': 'Number of shifts',
    'scout.metrics.gamesGoalie': 'Number of games',

    /******************************************Report**********************************************************/
    'report.section.summary': 'Summary',
    'report.section.summary-lite': 'Summary',
    'report.section.skaters': 'Skaters',
    'report.section.shooting': 'Shooting',
    'report.section.passing': 'Passing',
    'report.section.goaltenders': 'Goaltenders',
    'report.section.entries': 'Entries',
    'report.section.entries-lite': 'Entries',
    'report.section.entriesTitle': 'Entries',
    'report.section.exits': 'Exits',
    'report.section.exits-lite': 'Exits',
    'report.section.possession': 'Possession',
    'report.section.skating': 'Skating',
    'report.widget.report': 'Full Report',
    'report.panel.printReport': 'Print Report',
    'report.panel.strength': '{strength}',

    /******************************************Admin**********************************************************/

    'scoutAdmin.leagues': 'Leagues',
    'scoutAdmin.seasons': 'Seasons',
    'scoutAdmin.teams': 'Teams',
    'scoutAdmin.scoutGames': 'Games (Scout)',
    'scoutAdmin.players': 'Players',
    'scoutAdmin.gamesUpload': 'Games Upload',
    'scoutAdmin.playersTPE': 'Players TPE',

    'scoutAdmin.leagues.id': 'Id',
    'scoutAdmin.leagues.nameEn': 'Name (En)',
    'scoutAdmin.leagues.nameRu': 'Name (Ru)',
    'scoutAdmin.leagues.quantileType': 'Quantile type',
    'scoutAdmin.seasons.id': 'Id',
    'scoutAdmin.seasons.league': 'League',
    'scoutAdmin.seasons.nameEn': 'Name (En)',
    'scoutAdmin.seasons.nameRu': 'Name (Ru)',
    'scoutAdmin.seasons.status': 'Status',
    'scoutAdmin.seasons.dateBegin': 'Date Begin',
    'scoutAdmin.seasons.dateEnd': 'Date End',
    'scoutAdmin.teams.nameEn': 'Name (En)',
    'scoutAdmin.teams.nameRu': 'Name (Ru)',
    'scoutAdmin.teams.shortNameEn': 'Short Name (En)',
    'scoutAdmin.teams.shortNameRu': 'Short Name (Ru)',
    'scoutAdmin.teams.acronymEn': 'Acronym (En)',
    'scoutAdmin.teams.acronymRu': 'Acronym (Ru)',
    'scoutAdmin.teams.logo': 'Logo',
    'scoutAdmin.teams.addTeam': 'Add team',
    'scoutAdmin.teams.team': 'Team',
    'scoutAdmin.scoutGames.id': 'Id',
    'scoutAdmin.scoutGames.date': 'Date (locale)',
    'scoutAdmin.scoutGames.time': 'Time',
    'scoutAdmin.scoutGames.timeZone': 'Time zone',
    'scoutAdmin.scoutGames.dateTime': 'Date time (MSK)',
    'scoutAdmin.scoutGames.homeTeam': 'Home Team',
    'scoutAdmin.scoutGames.awayTeam': 'Away Team',
    'scoutAdmin.scoutGames.homeAwayTeam': 'Home/Away Team',
    'scoutAdmin.scoutGames.homeScore': 'Home Score',
    'scoutAdmin.scoutGames.awayScore': 'Away Score',
    'scoutAdmin.scoutGames.season': 'Season',
    'scoutAdmin.scoutGames.updateVideoLinks': 'update video links',
    'scoutAdmin.scoutGames.dialog.copyPlaceHolder': 'Azure storage file url',
    'scoutAdmin.scoutGames.dialog.copy': 'Copy',
    'scoutAdmin.scoutGames.dialog.stitchPlaceHolder': 'Azure storage directory url',
    'scoutAdmin.scoutGames.dialog.stitch': 'Stitch',
    'scoutAdmin.scoutGames.dialog.upload': 'Upload video',
    'scoutAdmin.scoutGames.dialog.clear': 'Clear',

    'scoutAdmin.playerProfiles.firstNameEn': 'First Name En',
    'scoutAdmin.playerProfiles.lastNameEn': 'Last Name En',
    'scoutAdmin.playerProfiles.firstNameRu': 'First Name Ru',
    'scoutAdmin.playerProfiles.lastNameRu': 'Last Name Ru',
    'scoutAdmin.playerProfiles.firstNameZh': 'First Name Zh',
    'scoutAdmin.playerProfiles.lastNameZh': 'Last Name Zh',
    'scoutAdmin.playerProfiles.born': 'Born',
    'scoutAdmin.playerProfiles.gender': 'Gender',
    'scoutAdmin.playerProfiles.height': 'Height',
    'scoutAdmin.playerProfiles.weight': 'Weight',
    'scoutAdmin.playerProfiles.photoHeader': 'Photo',
    'scoutAdmin.playerProfiles.photoHref': 'Photo url',
    'scoutAdmin.playerProfiles.shoots': 'Shoots',
    'scoutAdmin.playerProfiles.country': 'Country',
    'scoutAdmin.playerProfiles.games': 'Games',
    'scoutAdmin.playerProfiles.pts': 'PTS',
    'scoutAdmin.playerProfiles.avgTOI': 'AvgTOI',
    'scoutAdmin.playerProfiles.avgPP': 'Avg. PP',
    'scoutAdmin.playerProfiles.avgPK': 'Avg. PK',
    'scoutAdmin.playerProfiles.plusMinus': '+/-',
    'scoutAdmin.playerProfiles.savesPrc': 'Saves, %',
    'scoutAdmin.playerProfiles.goalsAgAvg': 'Goals against average',
    'scoutAdmin.playerProfiles.savesAvg': 'Saves average',
    'scoutAdmin.playerProfiles.teamCurrent': 'Current team',
    'scoutAdmin.playerProfiles.teamPast': 'Past team',
    'scoutAdmin.playerProfiles.email': 'Email',
    'scoutAdmin.playerProfiles.lastGameId': 'Last game ID',
    'scoutAdmin.playerProfiles.lastSeason': 'Last season',
    'scoutAdmin.playerProfiles.lastTeam': 'Last team',
    'scoutAdmin.playerProfiles.lastPlayerPosition': 'Last position',
    'scoutAdmin.playerProfiles.lastJersey': 'Last jersey',
    'scoutAdmin.playerProfiles.gameId': 'Game ID',

    'scout.interface.back': 'Back',
    'scout.interface.reports': 'Reports',
    'scout.interface.hideCategories': 'Hide categories',
    'scout.interface.showCategories': 'Show categories',
    'scout.interface.videoUnavailable': 'Sorry, your browser does not support embedded videos.',
    'scout.interface.comment': 'Comment',

    'scout.evaluation.categoryTypes.PERFORMANCE': 'Performance',
    'scout.evaluation.categoryTypes.PERFOMANCE': 'PERFOMANCE',
    'scout.evaluation.categoryTypes.STARTING': 'Starting',
    'scout.evaluation.categoryTypes.PUCK_STRIKE': 'Puck Strike',
    'scout.evaluation.categoryTypes.SHOT': 'Shot',
    'scout.evaluation.categoryTypes.COMPETITIVENESS': 'Competitiveness',
    'scout.evaluation.categoryTypes.CHARACTER': 'Character',
    'scout.evaluation.categoryTypes.PLAYER_TYPE': 'Player type',
    'scout.evaluation.categoryTypes.TALENT': 'Talent',

    'scout.playerPage.evaluation.playerName': 'Player name',
    'scout.playerPage.evaluation.position': 'Position',
    'scout.playerPage.evaluation.jersey': 'Jersey',
    'scout.playerPage.evaluation.teamStatus': 'Team status',
    'scout.playerPage.evaluation.season': 'Season',
    'scout.playerPage.evaluation.gameDateTime': 'Game date/time',
    'scout.playerPage.evaluation.homeTeam': 'Home team',
    'scout.playerPage.evaluation.score': 'Score',
    'scout.playerPage.evaluation.awayTeam': 'Away team',
    'scout.playerPage.evaluation.league': 'League',
    'scout.playerPage.evaluation.notes': 'Notes',
    'scout.playerPage.evaluation.gameScore': 'Score',

    'scout.schedule.group.GAME': 'Games',
    'scout.schedule.group.EVENT': 'Events',
    'scout.schedule.my': 'My schedule',
    'scout.schedule.all': 'All schedule',
    'scout.schedule.type.GAME': 'Game',
    'scout.schedule.type.EVENT': 'Event',
    'scout.schedule.new.GAME': 'New Game',
    'scout.schedule.new.EVENT': 'New Event',

    'admin.schedule.gameInfo.clients': 'Clients',
    'admin.schedule.gameInfo.productType': 'Product type',
    'admin.schedule.gameInfo.created': 'Created',
    'admin.schedule.gameInfo.createdBy': 'Created by',

    'clientsAdmin.clients': 'Clients',
    'clientsAdmin.users': 'Users',
    'clientsAdmin.clientUsers': 'Client users',
    'clientsAdmin.clientReports': 'Client reports',
    'clientsAdmin.clientGames': 'Client games',
    'clientsAdmin.contracts': 'Contracts',
    'clientsAdmin.deals': 'Deals',
    'clientsAdmin.scoutContracts': 'Scout contracts',
    'clientsAdmin.favoriteTeams': 'Favorite teams',
    'clientsAdmin.clientRooms': 'Client rooms',
    'clientsAdmin.scoutParticipants': 'Scout participants',
    'clientsAdmin.supportTemple': 'Support temple',

    'clientsAdmin.automation.csvClientUser': 'CSV Client-User',
    'clientsAdmin.automation.csvClientUser.uploadFile': 'Drag & drop you .csv file',
    'clientsAdmin.automation.csvClientUser.uploadFile.ready':
        'File was read. Please click on Reset button, if you need read a new file.',
    'clientsAdmin.automation.csvClientUser.column.email': 'Email',
    'clientsAdmin.automation.csvClientUser.column.firstName': 'First name',
    'clientsAdmin.automation.csvClientUser.column.secondName': 'Last name',
    'clientsAdmin.automation.csvClientUser.column.dateOfBirth': 'Date of birth',
    'clientsAdmin.automation.csvClientUser.column.jersey': 'Jersey',
    'clientsAdmin.automation.csvClientUser.column.position': 'Position',
    'clientsAdmin.automation.csvClientUser.column.status': 'Status',
    'clientsAdmin.automation.csvClientUser.column.userAccountId': 'User account ID',
    'clientsAdmin.automation.csvClientUser.column.player': 'Player',

    'clientsAdmin.clients.name': 'Name',
    'clientsAdmin.clients.email': 'Client email',
    'clientsAdmin.clients.salesEmail': 'Sales email',
    'clientsAdmin.clients.purchasedAt': 'Purchase Date',
    'clientsAdmin.clients.userName': 'User name',
    'clientReports.attachFile.reportUploadingSuccess': 'Report uploaded successfuly',
    'clientReports.attachFile.uploadReport': 'Upload report',
    'clientReports.clients.email': 'Email',
    'clientsAdmin.users.username': 'Username',
    'clientsAdmin.users.accountType': 'Role',
    'clientsAdmin.users.expireDate': 'Until',
    'clientsAdmin.users.password': 'Password',
    'clientsAdmin.users.generatePassword': 'Generate',
    'clientsAdmin.users.passwordToClipboard': 'Password has been saved to buffer',
    'clientsAdmin.users.demo': 'Demo account',
    'clientsAdmin.supportTemple.belongsToValuableCustomer': 'Important client',
    'clientsAdmin.supportTemple.shouldVideoBeUploadedBySupport': 'Upload',
    'clientsAdmin.clientUsers.client': 'Client',
    'clientsAdmin.clientUsers.individualStatus': 'Individual Status',
    'clientsAdmin.clientUsers.user': 'User',
    'clientsAdmin.clientUsers.team': 'Team',
    'clientsAdmin.clientUsers.player': 'Player',
    'clientsAdmin.clientUsers.sendPlaylists': 'Send playlists',
    'clientsAdmin.clientUsers.playlistsMask': 'Playlists mask',
    'clientsAdmin.clientUsers.analytic': 'Analytic',
    'clientsAdmin.clientUsers.featuresMask': 'Features Mask',
    'clientsAdmin.clientUsers.emails': 'Email',
    'clientsAdmin.clientUsers.locales': "Locales (',')",
    'clientsAdmin.clientUsers.picture': 'Picture',
    'clientsAdmin.clientUsers.rooms': 'Rooms',
    'clientsAdmin.clientUsers.clearCaches': 'Clear caches',
    'clientsAdmin.clientUsers.welcomeEmail': 'Welcome',
    'clientsAdmin.clientGames.liteModuleTypes': 'Lite Module Types',
    'clientsAdmin.clientGames.productType': 'Product type',
    'clientsAdmin.clientGames.productType.PREMIUM_PLUS': 'Premium plus',
    'clientsAdmin.clientGames.productType.PREMIUM': 'Premium',
    'clientsAdmin.clientGames.productType.SCOUT': 'Scout',
    'clientsAdmin.clientGames.productType.SUPER_LITE': 'Super lite',
    'clientsAdmin.clientGames.addingType': 'Type',
    'clientsAdmin.clientGames.date': 'Date',
    'clientsAdmin.clientGames.homeTeam': 'Home Team',
    'clientsAdmin.clientGames.awayTeam': 'Away Team',
    'clientsAdmin.clientGames.homeScore': 'Home Score',
    'clientsAdmin.clientGames.awayScore': 'Away Score',
    'clientsAdmin.clientGames.client': 'Client',
    'clientsAdmin.clientGames.gameIds': "Game Ids (',')",
    'clientsAdmin.clientGames.status': 'Status',
    'clientsAdmin.clientGames.season': 'Season',
    'clientsAdmin.clientGames.seasonId': 'Season Id',
    'clientsAdmin.clientGames.modulesMask': 'Modules Mask',
    'clientsAdmin.contracts.liteModulesMask': 'Lite Modules Mask',
    'clientsAdmin.contracts.team': 'Team',
    'clientsAdmin.contracts.season': 'Season',
    'clientsAdmin.contracts.client': 'Client',
    'clientsAdmin.contracts.dateBegin': 'Date begin',
    'clientsAdmin.contracts.dateEnd': 'Date end',
    'clientsAdmin.contracts.product': 'Product',
    'clientsAdmin.contracts.fulfill': 'Fulfill',
    'clientsAdmin.contracts.dealId': 'Deal ID',
    'clientsAdmin.scoutContracts.client': 'Client',
    'clientsAdmin.scoutContracts.season': 'Season',
    'clientsAdmin.scoutContracts.league': 'League',
    'clientsAdmin.scoutContracts.team': 'Team',
    'clientsAdmin.scoutContracts.dateStart': 'Date Start',
    'clientsAdmin.scoutContracts.dateEnd': 'Date End',
    'clientsAdmin.favoriteTeams.user': 'User',
    'clientsAdmin.favoriteItems.team': 'Favorite teams',
    'clientsAdmin.favoriteItems.player': 'Favorite players',
    'clientsAdmin.favoriteTeams.teamId': 'Team Id',
    'clientsAdmin.favoriteTeams.clientId': 'Client Id',
    'clientsAdmin.favoriteTeams.userAccountId': 'User account Id',
    'clientsAdmin.clientRooms.roomName': 'Room name',

    'playlistMasks.playerShifts': 'Shifts of player',
    'playlistMasks.teamShifts': 'Team shifts',
    'playlistMasks.gameGoals': 'Game goals',
    'playlistMasks.powerPlays': 'Power plays',
    'playlistMasks.penaltyKills': 'Penalty kills',

    'scheduleAdmin.games': 'Games',
    'scheduleAdmin.noClients': 'No clients',
    'scheduleAdmin.database': 'Database',
    'scheduleAdmin.gamesUpload': 'Upload games',
    'scheduleAdmin.shifts': 'Shifts',

    'scheduleAdmin.apply': 'Apply',
    'scheduleAdmin.clear': 'Clear',

    'scheduleAdmin.allGames': 'All games',
    'scheduleAdmin.lite': 'Lite',
    'scheduleAdmin.superLite': 'Super lite',
    'scheduleAdmin.pro': 'Pro',
    'scheduleAdmin.scout': 'Scout',
    'scheduleAdmin.preScout': 'Pre scout',
    'scheduleAdmin.slowPremium': 'Slow premium',
    'scheduleAdmin.createNewGame': 'Create new game',
    'scheduleAdmin.TBA': 'TBA',
    'scheduleAdmin.NA': 'N/A',
    'scheduleAdmin.addComment': 'Add',
    'scheduleAdmin.addComment.reply': 'Write you answer',

    'scheduleAdmin.gameTeam': '{teamNameEn}',
    'scheduleAdmin.gameSeason': '{teamNameEn}',

    'scheduleAdmin.createGame.apply': 'Apply',
    'scheduleAdmin.createGame.request': 'Request',
    'scheduleAdmin.createGame.cancel': 'Cancel request',
    'scheduleAdmin.createGame.clear': 'Clear',
    'scheduleAdmin.createGame.fromDB': 'From DB',
    'scheduleAdmin.createGame.newGame': 'New game',
    'scheduleAdmin.createGame.game': 'Game',
    'scheduleAdmin.createGame.gameType': 'Game type',
    'scheduleAdmin.createGame.overtimeType': 'Overtime type',
    'scheduleAdmin.createGame.support': 'Support',
    'scheduleAdmin.createGame.moderator': 'Moderator',
    'scheduleAdmin.createGame.verifier': 'Verifier',
    'scheduleAdmin.createGame.client': 'Client',
    'scheduleAdmin.createGame.bugged': 'Has video issues',
    'scheduleAdmin.createGame.isRawVideo': 'Do not produce a game',
    'scheduleAdmin.createGame.purpose': 'Purpose',
    'scheduleAdmin.createGame.comment': 'Comment',
    'scheduleAdmin.createGame.date': 'Date',
    'scheduleAdmin.createGame.dateTime': 'Date & time',
    'scheduleAdmin.createGame.dateTime.zeroTimeZone': 'Date & time (GMT UTC +00:00)',
    'scheduleAdmin.createGame.season': 'League',
    'scheduleAdmin.createGame.homeTeam': 'Home team',
    'scheduleAdmin.createGame.awayTeam': 'Away team',
    'scheduleAdmin.createGame.expectedUploadDateTime': 'Expected video upload date & time',
    'scheduleAdmin.createGame.timeZone': 'Time zone',
    'scheduleAdmin.createGame.time': 'Time',
    'scheduleAdmin.createGame.status': 'Status',

    'scheduleAdmin.attachFile.video': 'Video',
    'scheduleAdmin.attachFile.cropVideo': 'Crop Video',
    'scheduleAdmin.attachFile.protocol': 'Game sheet',
    'scheduleAdmin.attachFile.problems': 'Issues with Video File',
    'scheduleAdmin.attachFile.upload': 'Upload file',
    'scheduleAdmin.attachFile.uploadVideo': 'Upload video',
    'scheduleAdmin.attachFile.uploadGameSheet': 'Upload game sheet',
    'scheduleAdmin.attachFile.delete': 'Delete file',
    'scheduleAdmin.attachFile.copy': 'Copy',
    'scheduleAdmin.attachFile.send': 'Send',
    'scheduleAdmin.attachFile.videoUploaded': 'Video uploaded',
    'scheduleAdmin.attachFile': 'Attach file',
    'scheduleAdmin.attachFile.videoUploadingSuccess': 'Video loaded successfully',
    'scheduleAdmin.attachFile.protocolUploadingSuccess': 'Game sheet loaded successfully',

    'scheduleAdmin.shifts.dateTimeFrom': 'Start time',
    'scheduleAdmin.shifts.dateTimeTo': 'End time',
    'scheduleAdmin.shifts.capacity': 'Capacity',
    'scheduleAdmin.shifts.type': 'Type',
    'scheduleAdmin.shifts.create': 'Create',
    'scheduleAdmin.shifts.delete': 'Delete',
    'scheduleAdmin.shifts.apply': 'Apply',
    'scheduleAdmin.shifts.gameType': 'Game type',
    'scheduleAdmin.shifts.saveShifts': 'Save shifts',
    'scheduleAdmin.shifts.addShift': 'Add shift',

    'scheduleAdmin.gameType.areYouSure': 'Are you sure? Change game type for game: ',

    'scheduleGames.tableContent.id': 'ID',
    'scheduleGames.tableContent.requestedGameId': 'Req ID',
    'scheduleGames.tableContent.gameType': 'Game type',
    'scheduleGames.tableContent.date': 'Date',
    'scheduleGames.tableContent.teams': 'Teams',
    'scheduleGames.tableContent.seasons': 'Seasons',
    'scheduleGames.tableContent.status': 'Status',
    'scheduleGames.tableContent.staff': 'Staff',
    'scheduleGames.tableContent.priority': 'Priority',
    'scheduleGames.tableContent.resetAllSort': 'Reset all sorting columns',

    'scheduleGamesTable.priorityBadge.all': 'All',
    'scheduleGamesTable.priorityBadge.minor': 'Minor',
    'scheduleGamesTable.priorityBadge.normal': 'Normal',
    'scheduleGamesTable.priorityBadge.major': 'Major',
    'scheduleGamesTable.priorityBadge.critical': 'Critical',
    'scheduleGamesTable.priorityBadge.stopper': 'Show-stopper',

    /***************************************Collector*********************************************************/

    'admin.collector.publishGame': 'Publish Game',
    'admin.collector.reportsWereSent': 'Reports have been sent',
    'admin.collector.reportsWereNotSent': "Reports haven't been sent",

    'admin.collector.player.born': 'Born',
    'admin.collector.player.born.format': 'DD.MM.YYYY',
    'admin.collector.player.gender': 'Gender',
    'admin.collector.player.gender.male': 'Male',
    'admin.collector.player.gender.female': 'Female',
    'admin.collector.player.firstNameEn': 'Firstname (eng.)',
    'admin.collector.player.lastNameEn': 'Lastname (eng.)',
    'admin.collector.player.firstNameRu': 'Firstname (rus.)',
    'admin.collector.player.lastNameRu': 'Lastname (rus.)',
    'admin.collector.player.firstNameZn': 'Firstname (zh.)',
    'admin.collector.player.lastNameZn': 'Lastname (zh.)',
    'admin.collector.player.jersey': 'Jersey',
    'admin.collector.player.playerPosition': 'Player position',

    'admin.collector.findDuplicates': 'Найдены совпадения',

    /***************************************CrudTable*********************************************************/
    'components.field.create': 'Create',
    'components.field.clear': 'Clear',
    'components.field.created': 'Created',
    'components.field.error': 'Error',
    'components.edit.close': 'Close',
    'components.edit.cancel': 'Cancel',
    'components.edit.save': 'Save',
    'components.edit.title': 'Editing',

    /***************************************Glossary*********************************************************/
    'support.glossary.title': 'Glossary',
    'support.glossary.info': 'List of terms and definitions',
    'support.glossary.entries': '{entries, plural, =1 { record} other { records}}',
    'support.videoManuals.title': 'Training',
    'support.videoManuals.info': 'Video tutorials',
    'glossary.components.quickJump': 'Quick jump',
    'glossary.term.acc': 'Acceleration, m/s²',
    'glossary.def.acc':
        'On average, a players change in speed or direction - the higher the number the better (high numbers show that a player has more explosiveness).',
    'glossary.term.aade': 'Average attack duration [Entries]',
    'glossary.def.aade':
        'The attack ends when the puck leaves offensive zone (duration between the moment of the puck crossing the blue line coming into the zone and crossing the blue line coming out of the zone).',
    'glossary.term.aadx': 'Average attack duration [Exits]',
    'glossary.def.aadx':
        'The attack starts with an entry into the offensive zone (when the puck crosses the blueline) and ends when the puck leaves offensive zone (entry is assigned to the exit from which the attack began).',
    'glossary.term.aar': 'Average angle of rebound',
    'glossary.def.aar':
        'Angle between the displacement vector of the rebound and the imaginary perpendicular line that runs from the middle of one goal to the middle of the opposite goal.',
    'glossary.term.ape': 'Average possession [Entries]',
    'glossary.def.ape':
        'Team possession time divided by the number of entries (team possession time is assigned to the entry from which the attack began) ending with the puck exiting the offensive zone.',
    'glossary.term.apx': 'Average possession [Exits]',
    'glossary.def.apx':
        'Team possession time divided by the total number of exits in offensive zone (possession time is assigned to exit from which the attack began). Ends when the puck exits the offensive zone blue line the first time after the original entry resulting from the exit.',
    'glossary.term.asl': 'Average shift length',
    'glossary.def.asl': 'Total time on ice divided by the number of shifts during the game',
    'glossary.term.ass': 'Average shot speed',
    'glossary.def.ass': 'Same as Maximum shot speed, but average.',
    'glossary.term.bac': 'Backchecking Pace, %',
    'glossary.def.bac':
        'The amount of the distance skated above 20 km/h (12.43 mph) in the direction of the defensive zone divided by total distance skated towards the defensive zone while the opposite team is attacking and the puck is in the NZ or "my team\'s" DZ.',
    'glossary.term.boc': 'Body check',
    'glossary.def.boc':
        'The number of times when a player uses their body to separate the opponent from the puck and it results in changing the team that possesses the puck. The puck can be recovered by the player making the check or by his team. Checker gets the credit.',
    'glossary.term.clr': 'Clearance',
    'glossary.def.clr': 'Shooting the puck out of the defensive zone to stop the opponents attack.',
    'glossary.term.cin': 'Carry in [Entries]',
    'glossary.def.cin': 'Entry into the offensive zone with the puck on the stick.',
    'glossary.term.cout': 'Carry out [Exits]',
    'glossary.def.cout': 'Exits from the defensive zone (crossing the blue line) with puck on the stick.',
    'glossary.term.deff': 'Defensive efficiency',
    'glossary.def.deff': 'A combined effeciency rating based on a number of defensive statistics.',
    'glossary.term.dzp': 'Defensive zone passes',
    'glossary.def.dzp': 'Direct pass or pass off the boards made from the defensive zone.',
    'glossary.term.dis': 'Distance',
    'glossary.def.dis': 'Distance skated by the player (or team).',
    'glossary.term.din': 'Dump in [Entries]',
    'glossary.def.din': 'Entry into the zone caused by the offensive team shooting the puck in.',
    'glossary.term.dout': 'Dump out [Exits]',
    'glossary.def.dout':
        'When a team / player shoots the puck from the defensive zone before they cross the defensive zone blue line.',
    'glossary.term.OZentry': 'Entry into the offensive zone',
    'glossary.def.OZentry':
        'Entry into the offensive zone resulting from a play by the offensive team that causes the puck to cross the blue line. This can occur by carrying, dumping or passing the puck in and can be labeled successful or unsuccessful.',
    'glossary.term.DZexit': 'Exits from the defensive zone',
    'glossary.def.DZexit': 'The moment of crossing the defensive blue line.',
    'glossary.term.fow': 'Faceoff won',
    'glossary.def.fow':
        'Team and player (the faceoff taker) that have the first two full seconds of possession after the whistle win the faceoff.',
    'glossary.term.fpass': 'Failed pass',
    'glossary.def.fpass':
        'Unsuccessful direct pass from one player to their teammate or an unsuccessful pass off the boards which led to a teammate possessing the puck. The pass may be intercepted or recovered by the opponent or might result in a "dead" puck where no team has possession.',
    'glossary.term.fcin': 'Failed carry in [Entries]',
    'glossary.def.fcin':
        'Following an entry, when the puck left the offensive zone in the first 4 seconds after the entry and there was no shot attempt in those 4 seconds.',
    'glossary.term.fdin': 'Failed dump in [Entries]',
    'glossary.def.fdin':
        'Entries which results in not saving the possession or the puck left the offensive zone in 4 seconds after an entry and there was no shot attempt in those 4 seconds.',
    'glossary.term.fpin': 'Failed pass [Entries]',
    'glossary.def.fpin':
        'An entry into the offensive zone by making a pass to the teammate, which crosses the blue line (the entry is assigned to the player who makes a pass).',
    'glossary.term.gs': 'Game Score',
    'glossary.def.gs':
        "A player rating based on the player's statistical effectiveness during a particular game, or set of games.",
    'glossary.term.gva': 'Giveaways',
    'glossary.def.gva':
        'Any time a player loses the puck to the opponent through missed passes or possession loss to the opponent.',
    'glossary.term.gvap': 'Giveaways, %',
    'glossary.def.gvap': 'Number of giveaways divided by number of possessions.',
    'glossary.term.zonegva': 'Giveaways by zone',
    'glossary.def.zonegva': 'Zone in which the giveaway was made.',
    'glossary.term.zonegvap': 'Giveaways, % in zone',
    'glossary.def.zonegvap':
        'Number of giveaways in a certain zone divided by the number of unique possessions in the same zone.',
    'glossary.term.goal': 'Goal',
    'glossary.def.goal': 'When the puck fully crosses the goal line.',
    'glossary.term.int': 'Interception',
    'glossary.def.int': 'Number of intercepted direct passes, resulting in a change of possession.',
    'glossary.term.mss': 'Max shot speed',
    'glossary.def.mss':
        'Maximum value for the metric "shot speed" which calculates the distance between the shot location and goaltender location divided by duration of shot movement to the goaltender or point of being stopped (on a missed shot).',
    'glossary.term.nzp': 'Neutral zone passes',
    'glossary.def.nzp': 'Direct pass or pass off the boards made from the neutral zone.',
    'glossary.term.nop': 'Number of possessions',
    'glossary.def.nop': 'Number of unique possessions by the player / team.',
    'glossary.term.znop': 'Number of possessions by zone',
    'glossary.def.znop':
        'Number of unique player possessions in a certain zone. If a player skates through three zones with the puck that will count as a possession in each zone.',
    'glossary.term.oze': 'Offensive efficiency',
    'glossary.def.oze': 'A combined efficiency rating based on a number of offensive statistics.',
    'glossary.term.ozp': 'Offensive zone passes',
    'glossary.def.ozp': 'Direct pass or pass off the boards made from the offensive zone.',
    'glossary.term.ozinf': 'OZ in formation',
    'glossary.def.ozinf':
        'Amount of time the team is in the offensive zone and possessing the puck in a controlled offensive formation.',
    'glossary.term.ozoof': 'OZ out of formation',
    'glossary.def.ozoof':
        'Amount of time the attacking team has the puck in the offensive zone, but is in an uncontrolled offensive formation (ex. puck battles on the wall, at the blue lines, etc).',
    'glossary.term.ozt': 'OZ time [Power play - Lines]',
    'glossary.def.ozt': 'Amount of time the puck was in offensive zone while the line was on the ice',
    'glossary.term.ozft': 'OZF time of OZ time [Power play - Lines]',
    'glossary.def.ozft': 'Percentage of OZ in-formation time out of total power play time.',
    'glossary.term.pacep': 'Pace, %',
    'glossary.def.pacep':
        'The amount of the distance skated above 20 km/h (12.43 mph) divided by total distance skated.',
    'glossary.term.pass': 'Pass',
    'glossary.def.pass': 'Direct pass (player to player) or pass off the boards.',
    'glossary.term.pine': 'Pass-in [Entries]',
    'glossary.def.pine':
        'An entry into the offensive zone by making a pass to the teammate, which crosses the blue line (the entry is assigned to the player who makes a pass).',
    'glossary.term.pout': 'Pass-out [Exits]',
    'glossary.def.pout':
        'Exits from the defensive zone by making a pass to a teammate across the blue line (The exit is assigned to player who made the pass).',
    'glossary.term.poss': 'Possession',
    'glossary.def.poss': 'When a player touches the puck and maintains control.',
    'glossary.term.possp': 'Possession, %',
    'glossary.def.possp': 'Team percentage of puck possession when line / player was on the ice.',
    'glossary.term.pot': 'Possession time (player)',
    'glossary.def.pot':
        'Amount of time that the player has the puck on his stick and / or maintains possession while in typical game-play scenarios.',
    'glossary.term.pap': 'Potential assist (primary)',
    'glossary.def.pap':
        'The last pass before a shot attempt (no change in team possession between the pass and the shot attempt) (We could limit this to only counting for shots with a certain xG - otherwise a shot on goal from the NZ will still be giving a PA to the person who made the last pass even though there is almost no chance for that shot to go in).',
    'glossary.term.pas': 'Potential assist (secondary)',
    'glossary.def.pas':
        'The last pass before a primary potential assist (no change in team possession between the secondary potential assist, primary potential assist, and the shot attempt).',
    'glossary.term.pr': 'Puck recovery',
    'glossary.def.pr':
        'When a player retrieves a puck that is not possessed by any team and was not directed by any player possessing the puck.',
    'glossary.term.ptioz': 'Puck time in OZ',
    'glossary.def.ptioz': 'The amount of time when the puck was in the offensive zone while the player was on the ice.',
    'glossary.term.ptiozp': 'Puck time in OZ, %',
    'glossary.def.ptiozp': 'Percentage of time when the puck was in the offensive zone while the line was on the ice.',
    'glossary.term.rr': 'Rebound recovery',
    'glossary.def.rr':
        'Number of cases when a player recovers the puck after a save with a rebound; the first touch after a rebound, can be for both defensive and offensive rebounds.',
    'glossary.term.rbp': 'Rebound, %',
    'glossary.def.rbp': 'Number of shots that created a rebound, divided by the number of shots on goal.',
    'glossary.term.rgp': 'Regroup',
    'glossary.def.rgp':
        'Amount of time the attacking team goes back into the neutral or defensive zone in order to restart their attack.',
    'glossary.term.rush': 'Rush',
    'glossary.def.rush':
        'Amount of time the attacking team spends in transition from a regroup leading into the offensive zone.',
    'glossary.term.savep': 'Save, %',
    'glossary.def.savep': 'Number of saves divided by total number of shots on goal.',
    'glossary.term.scsa': 'SC/SA. %',
    'glossary.def.scsa':
        'Defines how dangerous the opponents shot attempts were (scoring chances divided by shot attempts).',
    'glossary.term.sc': 'Scoring chance',
    'glossary.def.sc':
        '1. Shot attempt from the slot area; 2. Shot attempt after a potential assist (pass that crosses the middle plane of the offensive zone) 3. Repeated unblocked shot attempt.',
    'glossary.term.shotp': 'Shooting, %',
    'glossary.def.shotp': 'Number of goals divided by the number of shots on goal.',
    'glossary.term.sa': 'Shot attempt',
    'glossary.def.sa':
        'Shot attempt, or directed deflection of the puck, that either reached the goaltender, or was missed, blocked, or deflected.',
    'glossary.term.sap': 'Shot attempts, %',
    'glossary.def.sap':
        'Percentage of shot attempts made by the line / player out of the total shot attempts while the line /player was on the ice.',
    'glossary.term.sape': 'Shot attempts [Entries]',
    'glossary.def.sape':
        'Number of shot attempts made by the team after an entry into the offensive zone ending with the puck exiting the offensive zone.',
    'glossary.term.sapx': 'Shot attempts [Exits]',
    'glossary.def.sapx':
        'Number of shot attempts made by the team in offensive zone (Shot attempts are assigned to exit from which the attack began). Ends when the puck exits the offensive zone blue line the first time after the original entry resulting from the successful zone exit.',
    'glossary.term.sog': 'Shot on goal',
    'glossary.def.sog': 'Shot attempt, or directed deflection of the puck, that reached the goaltender.',
    'glossary.term.swtp': 'Speed with the puck, m/s',
    'glossary.def.swtp': 'The average speed of skating while the player was skating with possession of the puck.',
    'glossary.term.speed': 'Speed, m/s',
    'glossary.def.speed': 'The average speed of skating while the player was on the ice.',
    'glossary.term.sch': 'Stick check',
    'glossary.def.sch':
        "The number of times the stick is used to knock the puck off of the puck carrier's stick or to clear it away from their vicinity and results in changing the team that possesses the puck. The puck can be recovered by the player making the check or by his team. Checker gets the credit.",
    'glossary.term.sentry': 'Successful entry [Entries]',
    'glossary.def.sentry':
        "Following an entry, when the puck didn't leave the Offensive Zone within the first 4 seconds after the entry or when there was a shot attempt in those first 4 seconds.",
    'glossary.term.sucr': 'Success rate [Entries / Exits]',
    'glossary.def.sucr': 'Number of successful entries divided by number of total entries.',
    'glossary.term.scine': 'Successful carry in [Entries]',
    'glossary.def.scine':
        'Following an entry, when the puck stayed in the offensive zone for more than 4 seconds after the entry or when there was shot attempt in the first 4 seconds.',
    'glossary.term.sdine': 'Successful dump in [Entries]',
    'glossary.def.sdine':
        "When a dump in entry results in saving the possession and puck didn't leave offensive zone in 4 seconds after an entry or there was shot attempt in those 4 seconds.",
    'glossary.term.spine': 'Successful pass [Entries]',
    'glossary.def.spine':
        "Entries which were successfully recieved by a teammate and the puck didn't leave the offensive zone in 4 seconds after entry or less after the entry or there was shot attempt in those 4 seconds.",
    'glossary.term.scoutx': 'Successful carry out [Exits]',
    'glossary.def.scoutx':
        'Exits with the puck on the stick, which did not return into the DZ within 3 seconds after the EXIT.',
    'glossary.term.sdoutx': 'Successful dump out [Exits]',
    'glossary.def.sdoutx':
        "Exits which results in saving the possession or puck didn't return into the DZ in 10 seconds after DUMP OUT. Icings are excluded.",
    'glossary.term.spoutx': 'Successful pass [Exits]',
    'glossary.def.spoutx':
        "Exits, from the defensive zone by making a pass which were successfully received by a teammate, and didn't return to the DZ within 3 seconds after EXIT.",
    'glossary.term.spass': 'Successful pass',
    'glossary.def.spass':
        'Successful direct pass from one player to a teammate or a successful pass off the boards which led to a teammates possession of the puck.',
    'glossary.term.tpt': 'Team possession time',
    'glossary.def.tpt':
        'Total amount of time the team possessed the puck: Includes player possession, time taken for successful passes, and puck recoveries on zone entries / exits.',
    'glossary.term.toi': 'Time on ice',
    'glossary.def.toi': "Total amount of time the player's skates were on the ice during game time.",
    'glossary.term.tpp': 'Time per possessions',
    'glossary.def.tpp': 'Possession time divided by number of possessions.',
    'glossary.term.xge': 'xG [Entries]',
    'glossary.def.xge':
        'Average of the xG created by the team after an entry into the offensive zone; ends with the puck exiting the offensive zone.',
    'glossary.term.xgex': 'xG [Exits]',
    'glossary.def.xgex':
        'Average of the xG created by team after an exit from the defensive zone. Ends when the puck exits the offensive zone blue line the first time after the original entry resulting from the exit.',
    'glossary.term.xg': 'xG',
    'glossary.def.xg':
        "The probability that the player's shot will be a goal; provided the characteristics of the shot (the distance to the goal, the angle of impact etc.), as well as the position of the player shooting, his team, and the opponent.",
    'glossary.term.zs': 'Zone starts, %',
    'glossary.def.zs':
        'Offensive zone starts (when the player was on the ice during a faceoff in offensive zone) divided by the total starts in either the offensive or defensive zone (offensive zone starts / offensive zone starts + defensive zone starts). Zone starts tend to show the difficulty of player\'s ice time; the higher the number - the "easier" it was for player to get better results.',
    'glossary.term.epr': 'Entry. Positive rate',
    'glossary.def.epr': 'Entry into the offensive zone with a following shot or an extended continuous possession.',

    /****************************************Video manuals*****************************************************/
    'videoManuals.title.intro': 'Intro',
    'videoManuals.link.intro': 'https://www.youtube.com/embed/0AR13Mi44AE',
    'videoManuals.title.filters': 'Filters',
    'videoManuals.link.filters': 'https://www.youtube.com/embed/RI8eywnK2Bs',
    'videoManuals.title.video': 'Video Player',
    'videoManuals.link.video': 'https://www.youtube.com/embed/Zshe6YhU91M',
    'videoManuals.title.skaters': 'Skaters',
    'videoManuals.link.skaters': 'https://www.youtube.com/embed/xpfcolOPzCw',
    'videoManuals.title.pppk': 'PP and PK',
    'videoManuals.link.pppk': 'https://www.youtube.com/embed/BcElhPv1txg',
    'videoManuals.title.reports': 'Reports',
    'videoManuals.link.reports': 'https://www.youtube.com/embed/eTpSyE1HBT0',
    'videoManuals.title.stats': 'Statistics',
    'videoManuals.link.stats': 'https://www.youtube.com/embed/1JPY1S3ipg8',
    'videoManuals.title.shifts': 'Shifts',
    'videoManuals.link.shifts': 'https://www.youtube.com/embed/UOTkMfWPly0',

    /****************************************Video manuals*****************************************************/
    'tableList.error.requestData': 'Data request error',

    /****************************************Date values*****************************************************/

    'calendar.view.week': 'Week',
    'calendar.view.month': 'Month',

    'calendar.weekday.monday': 'Monday',
    'calendar.weekday.tuesday': 'Tuesday',
    'calendar.weekday.wednesday': 'Wednesday',
    'calendar.weekday.thursday': 'Thursday',
    'calendar.weekday.friday': 'Friday',
    'calendar.weekday.saturday': 'Saturday',
    'calendar.weekday.sunday': 'Sunday',

    'calendar.weekday.short.monday': 'MON',
    'calendar.weekday.short.tuesday': 'TUE',
    'calendar.weekday.short.wednesday': 'WED',
    'calendar.weekday.short.thursday': 'THU',
    'calendar.weekday.short.friday': 'FRI',
    'calendar.weekday.short.saturday': 'SAT',
    'calendar.weekday.short.sunday': 'SUN',

    'calendar.month.january': 'January',
    'calendar.month.february': 'February',
    'calendar.month.march': 'March',
    'calendar.month.april': 'April',
    'calendar.month.may': 'May',
    'calendar.month.june': 'June',
    'calendar.month.july': 'July',
    'calendar.month.august': 'August',
    'calendar.month.september': 'September',
    'calendar.month.october': 'October',
    'calendar.month.november': 'November',
    'calendar.month.december': 'December',

    'calendar.more': '+ {count} more',

    /****************************************Clients cabinet values*****************************************************/

    'clientsCabinet.payment.successPayment':
        'Your purchase has been successfully payed. You will be redirected back soon.',
    'clientsCabinet.payment.failedPayment': 'Error occurred while processing your payment',
    'clientsCabinet.payment.goBack': 'GO BACK',
    'clientsCabinet.payment.gamesToBePurchased': 'Games to be purchased',
    'clientsCabinet.payment.pricePerGame': 'Price per game',
    'clientsCabinet.payment.totalAmount': 'Total amount',
    'clientsCabinet.payment.gamesLimitToBePurchased': 'Max. amount of games to be purchased: {games}',
    'clientsCabinet.payment.includingHst': 'Including HST/GST {taxPercent}%',
    'clientsCabinet.payment.payNow': 'PAY NOW',
    'clientsCabinet.payment.close': 'CLOSE',

    'clientsCabinet.calendar.purchaseCredits': 'Purchase credits',
    'clientsCabinet.calendar.creditsLeft': 'Credits left',
    'clientsCabinet.calendar.gamesPurchased': 'Credits spent',

    'clientsCabinet.calendar.videoUploader.placeholder': 'Drag & Drop',
    'clientsCabinet.calendar.videoUploader.placeholderHint': 'your files here, or browse',
    'clientsCabinet.calendar.videoUploader.cancelAll': 'Cancel all',
    'clientsCabinet.calendar.videoUploader.upload': 'Upload',
    'clientsCabinet.calendar.videoUploader.uploaded': 'Uploaded',
    'clientsCabinet.calendar.videoUploader.uploading': 'Uploading',
    'clientsCabinet.calendar.videoUploader.rejected': 'Rejected',
    'clientsCabinet.calendar.videoUploader.pending': 'Ready to upload',
    'clientsCabinet.calendar.videoUploader.waiting': 'Waiting',
    'clientsCabinet.calendar.videoUploader.description.h1': 'If you are uploading multiple files, ',
    'clientsCabinet.calendar.videoUploader.description.h2':
        'please clearly indicate what period is contained in each file (example: ',
    'clientsCabinet.calendar.videoUploader.description.h3':
        'Period_1, Period_2, Period_1.1, Period_1.2, Period_2.1, Period_2.2 ',
    'clientsCabinet.calendar.videoUploader.description.h4': 'etc.) This will help us process the video faster. ',
    'clientsCabinet.calendar.videoUploader.description.h5':
        'Please note you can upload up to 10 video files (max file size 2GB) at a time. ',
    'clientsCabinet.calendar.videoUploader.description.h6':
        'Acceptable Formats:  AVI, MOV, MP4, MKV, MTS, M2TS, MPG, M4V, WMV, ASF',
    'clientsCabinet.calendar.videoUploader.description.p1': 'If you are uploading multiple files, ',
    'clientsCabinet.calendar.videoUploader.description.p2':
        'please clearly indicate what period is contained in each file (example: ',
    'clientsCabinet.calendar.videoUploader.description.p3':
        'Period_1, Period_2, Period_1.1, Period_1.2, Period_2.1, Period_2.2 ',
    'clientsCabinet.calendar.videoUploader.description.p4': 'etc.) This will help us process the video faster. ',
    'clientsCabinet.calendar.videoUploader.description.p5':
        'Please note you can upload up to 10 video files (max file size 2GB) at a time. ',
    'clientsCabinet.calendar.videoUploader.description.p6':
        'Acceptable Formats: AVI, MOV, MP4, MKV, MTS, M2TS, MPG, M4V, WMV, ASF',
    'clientsCabinet.calendar.videoUploader.uploadIsBlocked':
        'For the current status of the game request, the addition of video files is not provided.',
    'clientsCabinet.calendar.videoUploader.inProgress.warning':
        'TPE team specialists are already producing your game. Are you sure you want to upload your video?',
    'clientsCabinet.calendar.videoUploader.inProgress.yes': 'Yes',
    'clientsCabinet.calendar.videoUploader.inProgress.no': 'No',
    'clientsCabinet.calendar.videoUploader.beforeApply.warning':
        'Please confirm your game request. 1 credit will be debited',
    'clientsCabinet.calendar.videoUploader.beforeApply.warning.isRawVideo':
        'Please confirm your game request. This game will not be produced. Credit will not be debited',

    'clientsCabinet.calendar.sheetUploader.description.p1':
        'Please ensure that the game sheet is complete, legible and includes:',
    'clientsCabinet.calendar.sheetUploader.description.li1': 'Player names and jersey numbers',
    'clientsCabinet.calendar.sheetUploader.description.li2': 'All goals and assists',
    'clientsCabinet.calendar.sheetUploader.description.li3': 'All penalties',
    'clientsCabinet.calendar.sheetUploader.description.li4':
        'Starting Goaltender (if goalie change please indicate time & period of change)',
    'clientsCabinet.calendar.sheetUploader.description.p2': 'Acceptable formats: PDF, DOC, TXT, PAGES',

    /****************************************Clients cabinet PRE-SCOUT products descriptions*****************************************************/

    'clientsCabinet.productsDescription.preScout.description.h1': 'TPE',
    'clientsCabinet.productsDescription.preScout.description.h2': 'PRE-SCOUT',
    'clientsCabinet.productsDescription.preScout.description.h3': 'Plan.',
    'clientsCabinet.productsDescription.preScout.description.h4': 'Prepare.',
    'clientsCabinet.productsDescription.preScout.description.h5': 'Execute.',

    'clientsCabinet.productsDescription.preScout.description.m1':
        'TPE team Pre-Scout Product provides teams with detailed insights and advanced scouting reports on their upcoming opponents.',

    'clientsCabinet.productsDescription.preScout.description.m2':
        'Our Pre-Scout Technology System will help coaches increase efficiency when preparing for upcoming games by automatically collecting highly relevant statistics and analytics including: ',

    'clientsCabinet.productsDescription.preScout.description.ul1.li1': 'Goals, assists, shots and shot attempts',

    'clientsCabinet.productsDescription.preScout.description.ul1.li2': 'Shifts breakdown by player',

    'clientsCabinet.productsDescription.preScout.description.ul1.li3': 'Face-offs',

    'clientsCabinet.productsDescription.preScout.description.ul1.li4':
        'Special teams play - power play and penalty kill',

    'clientsCabinet.productsDescription.preScout.description.ul1.li5': 'Corresponding video',

    'clientsCabinet.productsDescription.preScout.description.m3':
        'This list of statistics and metrics comes with corresponding video breakdown centered around coaches key points of preparation including: ',

    'clientsCabinet.productsDescription.preScout.description.ul2.li1': 'Special teams formations',

    'clientsCabinet.productsDescription.preScout.description.ul2.li2': 'Face-offs formations and strategy',

    'clientsCabinet.productsDescription.preScout.description.ul2.li3': 'Breakouts / forechecks',

    'clientsCabinet.productsDescription.preScout.description.ul2.li4': 'How / where opponent generates shots from',

    'clientsCabinet.productsDescription.preScout.description.ul2.li5':
        "Advanced scouting reports on the opposition's goaltender",

    'clientsCabinet.productsDescription.preScout.description.m4':
        'The time-saving this product generates will allow teams to realign and focus additional resources on other areas of preparation in advance of their games.',

    'clientsCabinet.productsDescription.preScout.description.m5':
        'Our Pre-Scout Technology System is set to revolutionize how teams and coaches prepare for games. Our  user-friendly portal is easy to navigate and filter by different metrics and events. It comes equipped with a Summary Page, Shifts Page and Goaltenders Page.',

    'clientsCabinet.productsDescription.preScout.description.m6': 'The Shifts Page ',

    'clientsCabinet.productsDescription.preScout.description.m7':
        'shows each individual player’s shifts and is accompanied with corresponding video of those shifts. It is optimal for pre-scouting specific players on the opposition and understanding their tendencies.',

    'clientsCabinet.productsDescription.preScout.description.m8': 'The Summary Page ',

    'clientsCabinet.productsDescription.preScout.description.m9':
        'allows you to filter by team, number of games (or quick button - last 5 games, last 10 games), specific periods and strength. It also comes equipped with: ',

    'clientsCabinet.productsDescription.preScout.description.ul3.li1': 'Shot location maps',

    'clientsCabinet.productsDescription.preScout.description.ul3.li2': 'Heat maps',

    'clientsCabinet.productsDescription.preScout.description.ul3.li3': 'Face-offs maps',

    'clientsCabinet.productsDescription.preScout.description.m10':
        'It includes a table with individual player statistics and metrics including: ',

    'clientsCabinet.productsDescription.preScout.description.m11':
        'goals, assists, shots, shot attempts, shots in the danger zone, hits, time on ice and more',

    'clientsCabinet.productsDescription.preScout.description.m12': 'The Goalies Page ',

    'clientsCabinet.productsDescription.preScout.description.m13': 'includes:',

    'clientsCabinet.productsDescription.preScout.description.ul4.li1':
        'Goalie Chart which highlights the opposing goaltender’s save percentage by shot location on net, allowing teams to easily identify areas of strength and weakness',

    'clientsCabinet.productsDescription.preScout.description.ul4.li2':
        'Shot Location Map which depicts the areas where the goaltender faces shots from',

    'clientsCabinet.productsDescription.preScout.description.ul4.li3':
        'Zones Map that indicates the goaltender’s save percentage on shots from different areas in the offensive zone',

    'clientsCabinet.productsDescription.preScout.description.m14':
        'Lastly, it comes equipped with corresponding video of all goals, shot attempts, scoring chances, shots with rebounds, shots without rebounds, blocked shots and missed shots',

    'clientsCabinet.productsDescription.preScout.description.m15':
        'In hockey’s increasingly competitive environment, access to relevant and accurate information on the opposition is crucial in order to strategize effectively and afford your team the best opportunity to win. TPE’s Pre-Scout Technology System will allow teams to do just that.',

    /****************************************Clients cabinet SCOUT products descriptions*****************************************************/

    'clientsCabinet.productsDescription.scout.description.h1': 'TPE',
    'clientsCabinet.productsDescription.scout.description.h2': 'SCOUT',
    'clientsCabinet.productsDescription.scout.description.h3': 'Identify.',
    'clientsCabinet.productsDescription.scout.description.h4': 'Recruit.',
    'clientsCabinet.productsDescription.scout.description.h5': 'Excel.',

    'clientsCabinet.productsDescription.scout.description.m1':
        'TPE Sports Analytics Scout Technology System is revolutionizing player management and how teams scout, identify, recruit and draft talent.',

    'clientsCabinet.productsDescription.scout.description.m2':
        'Our Scout Technology System provides teams and management with detailed insights and statistics as well as broken down game film on individual player’s throughout the entirety of their career. It will increase team’s scouting departments efficiency and coverage by allowing them to quickly and easily navigate through advanced analytics, statistics and game film on hundreds of players from their personal computers.',

    'clientsCabinet.productsDescription.scout.description.m3': 'Relevant statistics and analytics includes:',

    'clientsCabinet.productsDescription.scout.description.ul1.li1': 'Goals, assists, shots and shot attempts',

    'clientsCabinet.productsDescription.scout.description.ul1.li2': 'Shifts breakdown by player',

    'clientsCabinet.productsDescription.scout.description.ul1.li3': 'Face-offs',

    'clientsCabinet.productsDescription.scout.description.ul1.li4': 'Special teams play - power play and penalty kill',

    'clientsCabinet.productsDescription.scout.description.ul1.li5': 'Corresponding video',

    'clientsCabinet.productsDescription.scout.description.m4':
        'The time-saving this product generates will allow teams to realign and focus additional resources on other areas of hockey operations.',

    'clientsCabinet.productsDescription.scout.description.m5':
        'Our fast and reliable, user-friendly portal makes it easy to filter by player and navigate to an individual player’s profile page. It allows users to filter by team, league, and/or position. From there they are able to ',

    'clientsCabinet.productsDescription.scout.description.m6':
        'identify and sort leaders by specific metrics and statistics including; goals, assists, shots, shot attempts, shots in the danger zone, hits, time on ice and more.',

    'clientsCabinet.productsDescription.scout.description.m7': 'The Players Page ',

    'clientsCabinet.productsDescription.scout.description.m8': 'allows users to filter a player’s stats by:',

    'clientsCabinet.productsDescription.scout.description.ul2.li1': 'Last 5 games',

    'clientsCabinet.productsDescription.scout.description.ul2.li2': 'Last 10 games',

    'clientsCabinet.productsDescription.scout.description.ul2.li3': 'Current season / Career',

    'clientsCabinet.productsDescription.scout.description.ul2.li4': 'Home games / away games',

    'clientsCabinet.productsDescription.scout.description.ul2.li5': 'Game’s team won / lost ',

    'clientsCabinet.productsDescription.scout.description.ul2.li6':
        'Games against specific opponent / group of opponents',

    'clientsCabinet.productsDescription.scout.description.ul2.li7': 'Specific individual games',

    'clientsCabinet.productsDescription.scout.description.m9':
        'Player metrics and statistics in the selected games will be shown ',

    'clientsCabinet.productsDescription.scout.description.m10': 'compared to the league average for that position.',

    'clientsCabinet.productsDescription.scout.description.m11':
        'By selecting an individual metric, users will be able to see said player’s ',

    'clientsCabinet.productsDescription.scout.description.m12':
        'game-by-game tendencies and each corresponding video clip for the selected metric.',

    'clientsCabinet.productsDescription.scout.description.m13':
        'Lastly, the Players Page is equipped with TPE’s Player Comparison Tool which can be used for scouting (to compare different players) or when considering potential trades or call-ups.',

    'clientsCabinet.productsDescription.scout.description.m14':
        'In hockey’s increasingly competitive environment, access to relevant and accurate information on current and potential talent is crucial in order to build dynamic roster. TPE’s Scout Technology System will allow teams to do just that',

    'clientsCabinet.error': 'Error',
    'clientsCabinet.error.refresh': 'Game was accepted by support. Please refresh the page',
    'clientsCabinet.error.return': 'Game does not exist. Please return to schedule page',

    // Players account

    'cabinet.myPage.profile': 'Profile',
    'cabinet.myPage.uploadPhoto': 'Upload your photo',
    'cabinet.myOrders.balance': 'Balance: {creditedGames}G / {creditedReports}R',
    'cabinet.myOrders.purchaseCredits': 'Purchase credits',
    'cabinet.myOrders.games': 'Games',
    'cabinet.myOrders.reports': 'Reports',
    'cabinet.myOrders.gamesToBePurchased': 'Games to be purchased',
    'cabinet.myOrders.reportsToBePurchased': 'Reports to be purchased',
    'cabinet.myOrders.pricePerGame': 'Price per game',
    'cabinet.myOrders.pricePerReport': 'Price per report',
    'cabinet.myOrders.ONE_GAME': '1 game',
    'cabinet.myOrders.FIVE_GAMES': '5 games',
    'cabinet.myOrders.TEN_GAMES': '10 games',
    'cabinet.myOrders.REPORT': 'Report',

    // Scout product

    'scout.playerSearch.advantageSearchSwitcherTitle': 'Advanced search',
    'scout.playerSearch.personalDataTitle': 'Personal data',
    'scout.playerSearch.gameDataTitle': 'Game data',
    'scout.playerSearch.clearAllTitle': 'Clear all',
    'scout.playerSearch.typeLabel': 'Type',

    'scout.playerSearch.typeLabel.d': 'D',
    'scout.playerSearch.typeLabel.g': 'G',
    'scout.playerSearch.typeLabel.f': 'F',

    'scout.playerSearch.typeLabel.l': 'F(L)',
    'scout.playerSearch.typeLabel.r': 'F(R)',

    'scout.playerSearch.typeLabel.lf': 'F(L)',
    'scout.playerSearch.typeLabel.rf': 'F(R)',
    'scout.playerSearch.typeLabel.cf': 'F(C)',

    'scout.playerSearch.typeLabel.c': 'F(C)',
    'scout.playerSearch.typeLabel.cl': 'F(CL)',
    'scout.playerSearch.typeLabel.cr': 'F(CR)',

    'scout.playerSearch.typeLabel.clf': 'F(CL)',
    'scout.playerSearch.typeLabel.crf': 'F(CR)',

    'scout.playerSearch.typeLabel.lrf': 'F(LR)',
    'scout.playerSearch.typeLabel.clrf': 'F(CLR)',

    'scout.playerSearch.sideLabel': 'Shooting type',

    'scout.playerSearch.sideLabel.left': 'LEFT',
    'scout.playerSearch.sideLabel.right': 'RIGHT',

    'scout.playerSearch.heightLabel': 'Height',
    'scout.playerSearch.weightLabel': 'Weight',
    'scout.playerSearch.timeLabel': 'Average time on ice',

    'scout.playerSearch.from': 'from',
    'scout.playerSearch.to': 'to',

    'scout.playerSearch.min': 'min',
    'scout.playerSearch.kg': 'kg',
    'scout.playerSearch.sm': 'sm',

    'scout.playerSearch.inch': 'inch',
    'scout.playerSearch.lbs': 'lbs',

    'scout.playerSearch.forwards': 'Forwards',
    'scout.playerSearch.forwardsLW': 'LW',
    'scout.playerSearch.forwardsC': 'C',
    'scout.playerSearch.forwardsRW': 'RW',
    'scout.playerSearch.forwardsLWC': 'LW + C',
    'scout.playerSearch.forwardsRWC': 'RW + C',
    'scout.playerSearch.forwardsRWLW': 'RW + LW',
    'scout.playerSearch.forwardsLeft': 'Left wing',
    'scout.playerSearch.forwardsCenter': 'Center',
    'scout.playerSearch.forwardsRight': 'Right wing',
    'scout.playerSearch.forwardsLeftRight': 'Left & right wing',
    'scout.playerSearch.forwardsCenterRight': 'Center & right wing',
    'scout.playerSearch.forwardsCenterLeft': 'Center & left wing',
    'scout.playerSearch.forwardsCenterLeftRight': 'Universal wing',
    'scout.playerSearch.forwardsUnset': 'Unset wing',
    'scout.playerSearch.defenseman': 'Defenseman',
    'scout.playerSearch.goalie': 'Goalie',
    'scout.playerSearch.leftSide': 'Left',
    'scout.playerSearch.rightSide': 'Right',
    'scout.playerSearch.smLabel': 'sm',
    'scout.playerSearch.lbsLabel': 'lbs',
    'scout.playerSearch.kgLabel': 'kg',
    'scout.playerSearch.inchLabel': 'inch',
    'scout.playerSearch.minLabel': 'min',
    'scout.playerSearch.maxHeightError': 'Maximum height can not be less than minimum height.',
    'scout.playerSearch.maxWeightError': 'Maximum weight can not be less than minimum weight.',
    'scout.playerSearch.maxAvgTimeError':
        'Maximum average on ice time can not be less than minimum average on ice time.',
    'scout.playerSearch.noActiveRoleError': 'Please choose one or more player types.',
    'scout.playerSearch.noActiveSideError': 'Please choose one or more sides.',

    'scout.playerSearch.leaguesLabel': 'Leagues',
    'scout.playerSearch.leagueLabel': '${values.nameEn ? values.nameEn : "" }',

    'scout.playerSearch.seasonsLabel': 'Seasons',
    'scout.playerSearch.seasonLabel': '${values.nameEn ? values.nameEn : "" }',

    'scout.playerSearch.teamsLabel': 'Teams',
    'scout.playerSearch.teamLabel': '${values.acronymEn ? values.acronymEn : "" }',

    'scout.playerSearch.datesLabel': 'Dates',

    'videoPlayer.videoControls.playlistLabel': 'Playlist',
    'videoPlayer.videoControls.fullVideoLabel': 'Full',
    'videoPlayer.videoControls.secondLabel': 's',

    'videoPlayer.playlists.allLabel': 'All',
    'videoPlayer.playlists.playlistsLabel': 'Playlists',
    'videoPlayer.playlists.sketches': 'Draw',

    'videoPlayer.playlists.addToBatch': 'Add to batch',
    'videoPlayer.playlists.addToBatchElements': 'Episodes',
    'videoPlayer.playlists.createNew': 'Create new',
    'videoPlayer.playlists.save': 'Save',
    'videoPlayer.playlists.cancel': 'Cancel',

    'videoPlayer.playlists.addToCustomControlLabel': 'Add to my playlist',
    'videoPlayer.playlists.addToCustomControlLabelCount': 'selected',
    'videoPlayer.playlists.saveCroppedEpisode': 'Save',
    'videoPlayer.playlists.addToCustomControl': 'Add to my playlist',
    'videoPlayer.playlists.addAnEvent': 'Create event',
    'videoPlayer.playlists.crop': 'Crop',
    'videoPlayer.playlists.appendSketch': 'Append sketch',

    'videoPlayer.playlists.subMenu.moveEpisode': 'Move',
    'videoPlayer.playlists.subMenu.renameEpisode': 'Rename',
    'videoPlayer.playlists.subMenu.deleteEpisode': 'Delete',
    'videoPlayer.playlists.subMenu.saveEpisode': 'Save',
    'videoPlayer.playlists.subMenu.saveAsEpisode': 'Save as',

    'videoPlayer.playlists.subMenu.downloadEpisodes': 'Download',
    'videoPlayer.playlists.subMenu.shareEpisodes': 'Share',
    'videoPlayer.playlists.subMenu.renameEpisodes': 'Rename',
    'videoPlayer.playlists.subMenu.deleteEpisodes': 'Delete',
    'videoPlayer.playlists.subMenu.saveEpisodes': 'Save',
    'videoPlayer.playlists.subMenu.cancelEpisodes': 'Cancel',

    'videoPlayer.videoControls.noCustomPlaylists': 'Please, add episodes(s) to new playlist.',

    'videoPlayer.loadingFiles.toMuchFiles': 'You have selected too many video episodes.',
    'videoPlayer.loadingFiles.noFiles': 'No episodes has been selected.',
    'videoPlayer.loadingFiles.clipServiceIsBusy': 'Please wait for the download to finish.',

    'videoPlayer.loadingFiles.urlUnavailable': 'One of video file for loading is unavailable.',

    'interface.calendar.begin': 'From',
    'interface.calendar.end': 'To',
    'interface.playerSide.left': 'Left',
    'interface.playerSide.right': 'Right',
    'interface.playerSide.left+right': 'Left + Right',
    'interface.playerSide.right+left': 'Left + Right',
    'interface.playerSide.none': 'None',

    'warningForUser.title': 'Maintenance note',
    'warningForUser.date': 'Tuesday, February 6, 2024',
    'warningForUser.messagePartFirst': ' Dear TPE users,',
    'warningForUser.messagePartSecond':
        ' The Prospect Exchange will be upgrading its environments on Tuesday, February 6, 2024 between 6am ET and 9am ET. The platform will be unavailable during this time. We apologize for the inconvenience.',

    'unavailablePage.title': `We'll be right back.`,
    'unavailablePage.text': `The page your are trying to access is currently unavailable. Please try again soon.`,

    'translation.useRussian': 'Please, use cyrillic symbols here.',

    playerProfile:
        '${values && values.firstNameEn && values.firstNameEn.length > 0 && values.firstNameEn[0] ? values.firstNameEn[0] : ""}. ${values && values.lastNameEn ? values.lastNameEn : ""}',
    nameOfTeam: '${values && values.nameEn ? values.nameEn : ""}',

    createNewEvent: 'Create new event',

    /*Admin pages*/

    'adminPages.games.forClients': 'for clients',

    'clipService.failure': 'Failure',

    /*Game Info NFT*/

    'gameInfoTable.id': 'id',
    'gameInfoTable.date': 'date',
    'gameInfoTable.games': 'games',
    'gameInfoTable.season': 'season',
    'gameInfoTable.league': 'league',

    'gameInfoTable.number': '#',
    'gameInfoTable.player': 'Player',
    'gameInfoTable.position': 'Position',
    'gameInfoTable.team': 'Team',
    'gameInfoTable.eventType': 'Event type',
    'gameInfoTable.period': 'Period',
    'gameInfoTable.videoTime': 'Video time',
    'gameInfoTable.score': 'Score',
    'gameInfoTable.playersNumber': 'Players number',

    'gameInfoTable.realization': 'REALIZATION, % (IN SEASON)',
    'gameInfoTable.saves': 'SAVES, % (IN SEASON)',
    'gameInfoTable.shots': 'SHOT xG, %',
    'gameInfoTable.shotsSpeed': '${ values && values.portalUnitIsImperial ? "SHOT SPEED, mp/h" : "SHOT SPEED, km/h" }',
    'gameInfoTable.passingAccuracy': 'PASSING ACCURACY, % (IN SEASON)',
    'gameInfoTable.assist': 'ASSIST xA, %',
    'gameInfoTable.hitDeliveredCount': 'COUNT (IN SEASON) only HIT DELIVERED',
    'gameInfoTable.blockedShots': 'BLOCKED SHOTS PER GAME, AVG',

    'gameInfoTable.title.gameInfo': 'Game info',
    'gameInfoTable.title.gameMoment': 'Game moment',
    'gameInfoTable.title.json': 'JSON',
    'gameInfoTable.title.events': 'Events',
    'gameInfoTable.title.statistics': 'Statistics',

    'gameInfoTable.affirm': 'Affirm',
    'gameInfoTable.cancel': 'Cancel',

    'nftGameStatus.pending': 'Pending',
    'nftGameStatus.inProgress': 'In progress',
    'nftGameStatus.ready': 'Ready',
    'nftGameStatus.approved': 'Approved',
    'nftGameStatus.published': 'Published',
    'nftGameStatus.canceled': 'Canceled',

    'nftGameTable.column.id': 'id',
    'nftGameTable.column.date': 'Date',
    'nftGameTable.column.gameType': 'Game type',
    'nftGameTable.column.gameInfo': 'Game info',
    'nftGameTable.column.seasons': 'Seasons',
    'nftGameTable.column.moderator': 'Moderator(s)',
    'nftGameTable.column.status': 'Status',
    'nftGameTable.column.creator': 'Creator',
    'nftGameTable.column.designer': 'Designer',
    'nftGameTable.column.verifier': 'Verifier',

    'nftDesignerBlock.title': 'Uploaded video-clips for game with id: {gameId}',
    'nftDesignerBlock.title.upload': 'Please, upload your clip here',
    cloudVideoCreator: 'Create clip',

    'cloudVideoCreator.controls.Intro': 'Intro',
    'cloudVideoCreator.controls.Ending': 'Ending',
    'cloudVideoCreator.controls.MAIN_VIDEO': 'Video',
    'cloudVideoCreator.controls.UPLOADING': 'Loader',
    'cloudVideoCreator.controls.FIELD_DATA': 'Field data',
    'cloudVideoCreator.controls.IMAGES': 'Images',

    'cloudVideoCreator.section.timeline': 'Timeline',

    'cloudVideoCreator.videoFragment.startPosition': 'Position on time line, sec.',
    'cloudVideoCreator.videoFragment.startVideoTime': 'Start video time, sec.',
    'cloudVideoCreator.videoFragment.endVideoTime': 'End video time, sec.',
    'cloudVideoCreator.videoFragment.duration': 'Duration of the fragment, sec.',

    'cloudVideoCreator.TemplateControls.player.lastNameEn': 'Last name',
    'cloudVideoCreator.TemplateControls.gameMoments.typeMoment': 'Type moment',
    'cloudVideoCreator.TemplateControls.season.nameEn': 'Season',
    'cloudVideoCreator.TemplateControls.game.date': 'Date',
    'cloudVideoCreator.TemplateControls.homeTeam.acronymEn': 'Home team',
    'cloudVideoCreator.TemplateControls.awayTeam.acronymEn': 'Away team',

    /*TPE exchange*/

    'tpeTable.column.player_id': 'Player ID',
    'tpeTable.column.select': 'Select',
    'tpeTable.column.first_name_en': 'First Name En',
    'tpeTable.column.last_name_en': 'Last Name En',
    'tpeTable.column.born': 'Born',
    'tpeTable.column.gender': 'Gender',
    'tpeTable.column.gender.default': 'Male',
    'tpeTable.column.email': 'Email',
    'tpeTable.column.shoots': 'Shoots',
    'tpeTable.column.cc3': 'CC3',
    'tpeTable.column.game_id': 'Last game ID',
    'tpeTable.column.team_name': 'Team name',
    'tpeTable.column.team_id': 'Team ID',
    'tpeTable.column.logo_url': 'Team logo',
    'tpeTable.column.game_player_position': 'Position',

    'tpeTable.column.extra_data_last_game_id': 'Last game ID',
    'tpeTable.column.extra_data_last_game_season': 'Last season',
    'tpeTable.column.extra_data_last_game_team': 'Last team',
    'tpeTable.column.extra_data_last_game_player_position': 'Last position',
    'tpeTable.column.extra_data_last_game_jersey': 'Last jersey',
    'tpeTable.column.tpe_date_send': 'TPE date send',

    'tpeTable.column.edit_player': 'Edit',
    'tpeTable.sent_to_tpe': 'Sent to the TPE',
    'tpeTable.generate_file_to_tpe': 'Generate JSON',
    'tpeTable.sent_to_tpe.selectAll': 'Select all on page',
    'tpeTable.sent_to_tpe.deSelectAll': 'Reset selection',
    'tpeTable.select_only_new': 'Select only new',
    'tpeTable.editTitle': 'Edit form',
    'tpeTable.editTitle.duplicates': 'Edit duplicates',

    'tpeTable.editForm.eliteProspectsId': 'Elite prospects ID',
    'tpeTable.editForm.firstNameEn': 'First Name En',
    'tpeTable.editForm.lastNameEn': 'Last Name En',
    'tpeTable.editForm.firstNameRu': 'First Name Ru',
    'tpeTable.editForm.lastNameRu': 'Last Name Ru',
    'tpeTable.editForm.firstNameZh': 'First Name Zh',
    'tpeTable.editForm.lastNameZh': 'Last Name Zh',
    'tpeTable.editForm.born': 'Born YYYY-MM-DD',
    'tpeTable.editForm.shoots': 'Shoots',
    'tpeTable.editForm.height': 'Height, sm',
    'tpeTable.editForm.weight': 'Weight, kg',
    'tpeTable.editForm.photoHref': 'Photo href',
    'tpeTable.editForm.playerPosition': 'Player position',
    'tpeTable.editForm.gender': 'Gender',
    'tpeTable.editForm.email': 'Email',

    /*Hockey map widget*/
    'shotLocation.subFilters.GOAL': 'Goal',
    'shotLocation.subFilters.SHOT_ON_GOAL': 'Shot on goal',
    'shotLocation.subFilters.BLOCKED': 'Blocked',
    'shotLocation.subFilters.DEFLECTED': 'Deflected',
    'shotLocation.subFilters.MISS': 'Missed',
    'shotLocation.subFilters.JERSEYS': 'With jerseys',

    /*Connect*/
    'connectPage.page': 'Connect'
};
export default engDictionary;
