import styled from 'styled-components/macro';
import { connect, useDispatch } from 'react-redux';
import { selector as linksSelector } from '../../../redux/modules/secondary-nav';
import { injectRoleManagement } from '../../../utils/permissions/role-utils';
import style from './nav-bar.module.css';
import styles from '../../../components/submenu/v.2.0/submenu.module.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, matchPath } from 'react-router-dom';
import cx from 'classnames';
import { setDeviceType } from '../../../redux/modules/device-type';
import { getDeviceType } from '../../../utils/helpers/device-utils';

const NavWrapper = styled.div`
    display: flex;
    justify-content: ${props => (props.prospectExchangeAccount ? 'center' : 'flex-end')};
    align-items: center;

    max-width: ${props => (props.prospectExchangeAccount ? '100vw' : '70vw')};

    height: 100%;
    padding: 0 5px;

    font-size: 0.875rem;
    white-space: nowrap;

    background-color: ${props =>
        props.prospectExchangeAccount ? 'var(--backgroundColorSecondary)' : 'var(--uiHeaderColor)'};

    user-select: none;
`;

export const Nav = ({ align = 'left', className, prospectExchangeAccount, ...props }) => (
    <NavWrapper
        prospectExchangeAccount={prospectExchangeAccount}
        {...props}
        className={`nav-${align} ${className || ''}`}
    />
);

const Item = ({ disabled, className, subLinkIsActive, prospectExchangeAccount, ...props }) => {
    return !!disabled ? (
        <div className={`${className} nl-disabled`}>{props.children}</div>
    ) : (
        <NavLink className={className} activeClassName="nl-active" {...props} />
    );
};

const StyledItem = styled(Item)`
    height: 24px;

    color: ${({ subLinkIsActive }) => (subLinkIsActive ? 'var(--uiPrimaryColor)' : 'white')};
    font-weight: bold;
    line-height: 24px;
    text-transform: none;
    text-decoration: none;

    cursor: pointer;
    padding-inline: 12px;

    &:hover,
    &:focus {
        color: ${({ subLinkIsActive }) =>
            subLinkIsActive ? 'var(--uiPrimaryColor)' : 'var(--uiPrimaryTextHighlighted)'};
        text-decoration: none;
    }

    &.nl-disabled {
        color: ${({ subLinkIsActive }) => (subLinkIsActive ? 'var(--uiPrimaryColor)' : 'var(--optionsDisabledColor)')};

        cursor: default;
    }
`;

const Container = styled.div`
    position: relative;

    min-width: 6rem;
    height: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    cursor: pointer;
`;

const PageSelector = ({ to, selectorIsActive, submenu, roleProps, prospectExchangeAccount, children }) => {
    const [menuOpened, setMenuStatus] = useState(false);
    const closeMenu = useCallback(() => {
        setMenuStatus(false);
    }, [setMenuStatus]);
    const toggleMenu = useCallback(() => {
        setMenuStatus(s => !s);
    }, [setMenuStatus]);

    const containerRef = useRef();

    const handleClickOutside = useCallback(
        e => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                closeMenu();
            }
        },
        [containerRef, closeMenu]
    );

    useEffect(() => {
        if (!menuOpened) return;
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('scroll', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('scroll', handleClickOutside);
        };
    }, [handleClickOutside, menuOpened]);

    const popupHeight =
        submenu && containerRef.current && containerRef.current.children[1].getBoundingClientRect().height;

    const currentSubLink = submenu
        ?.filter(link => matchPath(window.location.pathname, { path: link.to }))
        .sort((a, b) => b.to.length - a.to.length)[0];

    const selectedStyleCommon = {
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: '48px',
        color: prospectExchangeAccount
            ? 'var(--uiPrimaryColorDark)'
            : selectorIsActive
            ? 'var(--uiPrimaryColor)'
            : 'white'
    };

    const selectedStyleTab = {
        ...selectedStyleCommon,
        borderBottom: !prospectExchangeAccount
            ? 'none'
            : selectorIsActive
            ? '3px solid var(--uiPrimaryColorDark)'
            : '3px solid rgba(0,0,0,0)'
    };

    return (
        <Container style={selectedStyleCommon} ref={containerRef}>
            {submenu ? (
                <>
                    <div onClick={toggleMenu}>{children}</div>
                    <Popup popupHeight={popupHeight} opened={menuOpened}>
                        {submenu.map((link, linkKey) => (
                            <StyledItem
                                className={styles.submenuItem}
                                key={`linkKey_${linkKey}_${link.to}`}
                                to={link.to}
                                disabled={link.isEnabled && !link.isEnabled(roleProps)}
                                subLinkIsActive={!!currentSubLink && currentSubLink === link}
                                prospectExchangeAccount={prospectExchangeAccount}
                            >
                                {link.children}
                            </StyledItem>
                        ))}
                    </Popup>
                </>
            ) : (
                <StyledItem style={selectedStyleTab} key={to} to={to}>
                    {children}
                </StyledItem>
            )}
        </Container>
    );
};

const Popup = styled.div`
    position: absolute;
    bottom: ${props => (props.opened ? `-${props.popupHeight}px` : '0')};
    left: 0;
    z-index: -100;

    display: flex;
    flex-direction: column;
    min-width: 6rem;
    height: fit-content;
    padding: 0 0 5px;
    overflow: hidden;

    background-color: var(--uiHeaderColor);
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.25);

    transition: bottom 250ms;
`;

const NavComponent = ({ links, prospectExchangeAccount, className, ...roleProps }) => {
    const currentLink = useMemo(
        () =>
            links
                .filter(link => matchPath(window.location.pathname, { path: link.to }))
                .sort((a, b) => b.to.length - a.to.length)[0],
        [links, window.location.pathname]
    );

    const dispatch = useDispatch();

    useEffect(() => {
        const deviceType = getDeviceType();

        dispatch(setDeviceType(deviceType));
    }, [currentLink, prospectExchangeAccount]);

    return (
        <Nav
            className={prospectExchangeAccount ? '' : cx(className, style.topNav)}
            prospectExchangeAccount={prospectExchangeAccount}
        >
            {links
                .filter(link => !link.isVisible || link.isVisible(roleProps))
                .map(link => (
                    <PageSelector
                        roleProps={roleProps}
                        key={link.to}
                        to={link.to}
                        disabled={link.isEnabled && !link.isEnabled(roleProps)}
                        submenu={link.submenu}
                        selectorIsActive={currentLink === link}
                        prospectExchangeAccount={prospectExchangeAccount}
                    >
                        {link.children}
                    </PageSelector>
                ))}
        </Nav>
    );
};

export const LinksNav = connect(state => ({ links: linksSelector(state) }))(injectRoleManagement(NavComponent));
