/**
 * My goalie strictly in the net
 */
export const MY_TEAM = 'MY_TEAM';

/**
 * Opponent's goalie strictly in the net
 */
export const OPPONENT_TEAM = 'OPPONENT_TEAM';

/**
 * Both goalies in the net
 */
export const BOTH = 'BOTH';

/**
 * No filtering needed
 */
export const NONE = 'NONE';
