import { binary, multiArg, unary } from '../api/predicate';

export const field = fieldName => ({
    type: 'field',
    field: fieldName
});

const valueTypes = {
    NUMBER: 'number',
    STRING: 'string',
    BOOLEAN: 'boolean',
    DATE: 'date',
    DATE_TIME: 'datetime'
};

const valueWrapper = (type, value) => ({ type, value });

export const number = value => {
    if (!isFinite(value)) throw new Error(`${value} is not finite!`);
    return valueWrapper(valueTypes.NUMBER, value);
};
export const string = value => valueWrapper(valueTypes.STRING, value);
export const boolean = value => valueWrapper(valueTypes.BOOLEAN, value);
export const date = value => valueWrapper(valueTypes.DATE, value);
export const dateTime = value => valueWrapper(valueTypes.DATE_TIME, value);

const allFunctions = { ...unary, ...binary, ...multiArg };

export const func = (functionName, children) => {
    if (!Object.values(allFunctions).includes(functionName)) {
        throw new Error(`No such function ${functionName} !`);
    }
    return {
        type: 'function',
        function: functionName,
        children
    };
};

/**
 * deprecated
 */
export const autoTypedIn = (fieldName, children) => {
    const values = children.map(child => {
        switch (typeof child) {
            case 'string':
                return string(child);
            case 'boolean':
                return boolean(child);
            case 'number':
            default:
                return number(child);
        }
    });
    return func(multiArg.IN, [field(fieldName), ...values]);
};

export const containedIn = (fieldName, ...children) =>
    children.length ? func(multiArg.IN, [field(fieldName), ...children]) : null;

export const not = child => func(unary.NOT, [child]);
export const isNull = fieldName => func(unary.IS_NULL, [field(fieldName)]);

export const eq = (fieldName, child) => func(binary.EQ, [field(fieldName), child]);
export const ne = (fieldName, child) => func(binary.NE, [field(fieldName), child]);
export const ge = (fieldName, child) => func(binary.GE, [field(fieldName), child]);
export const le = (fieldName, child) => func(binary.LE, [field(fieldName), child]);
export const like = (fieldName, child) => func(binary.LIKE, [field(fieldName), child]);

/**
 * merge expressions by ___ function
 */
const joinByFunc = (funcName, ...expressions) => {
    expressions = expressions.filter(e => !!e);
    if (expressions.length === 0) return null;
    if (expressions.length === 1) return expressions[0];
    return func(funcName, expressions);
};

export const and = (...expressions) => joinByFunc(multiArg.AND, ...expressions);
export const or = (...expressions) => joinByFunc(multiArg.OR, ...expressions);

export const createSortFormForPosition = key => {
    const positionMap = {
        Defence: 'D',
        Forward: 'F',
        Goalie: 'G',
        Wing: 'W',
        Center: 'C',
        'Center/R.Wing': 'CRW',
        'Center/L.Wing': 'CLW',
        'Left Defence': 'LD',
        'Left Wing': 'LW',
        'Right Defence': 'RD',
        'Right Wing': 'RW'
    };
    return positionMap[key] || key;
};
