import { call, cancelled, fork, join, put, race, select, take, takeLatest } from 'redux-saga/effects';
import * as types from './fetch-stats-types';
import * as graph from './nodes';
import { handleRequest, HTTP_REQUEST_API } from '../../redux/sagas/request-api';

/**
 * wait until all required task fetched and call specified request
 */
function* waitRequiredTasksAndExecute(requestSelector, requiredTasks) {
    if (requiredTasks.length !== 0) yield join(...requiredTasks);
    const request = yield select(requestSelector);

    if (!request.hasOwnProperty(HTTP_REQUEST_API))
        throw new Error('Request selector must return request-builder based obj');

    yield call(handleRequest, request);
}

/**
 * start all graph executor requests and resolve theirs dependencies
 */
function* handleGraphExecutorRequest(action) {
    const nodeIdToTask = new Map();
    let nodeIds = [...action.payload.stats];

    try {
        while (nodeIds.length) {
            // collect nodeIds with unresolved dependencies
            const unresolvedNodeIds = [];
            for (const nodeId of nodeIds) {
                const { requestSelector, dependencies = [] } = graph[nodeId];
                // `true` if all task dependencies already forked
                const everyDependencyResolved = dependencies.every(d => nodeIdToTask.has(d));
                if (everyDependencyResolved) {
                    const requiredTasks = dependencies.map(d => nodeIdToTask.get(d));
                    const task = yield fork(waitRequiredTasksAndExecute, requestSelector, requiredTasks);
                    // put forked task to associated `nodeId`
                    nodeIdToTask.set(nodeId, task);
                } else {
                    unresolvedNodeIds.push(nodeId);
                }
            }
            nodeIds = unresolvedNodeIds;
        }

        const tasks = nodeIdToTask.values();

        // wait until all request fetched
        if (nodeIdToTask.size !== 0) yield join(...tasks);

        yield put({ type: types.SUCCESS });
    } finally {
        if (yield cancelled()) {
            yield put({ type: types.CANCELLED });
        }
    }
}

/**
 * graph executor cancellation watcher
 */
function* requestCancellationWatcher(action) {
    yield race({
        task: call(handleGraphExecutorRequest, action),
        cancel: take(types.CANCEL)
    });
}

/**
 * root watcher that handle graph executor requests
 */
export default function* graphExecutorWatcher() {
    yield takeLatest(types.REQUEST, requestCancellationWatcher);
}
