import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'entry/sagas';
import createRootReducer from 'entry/reducers';
import { isDevelopment } from '../utils/helpers/envirioment-utils';

const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(sagaMiddleware);

const store = createStore(
    createRootReducer(),
    !isDevelopment() ? middleware : require('redux-devtools-extension').composeWithDevTools(middleware)
);

store.injectedReducers = {};
store.injectedSagas = {};

store.runSaga = sagaMiddleware.run;

store.injectReducer = (key, reducer) => {
    if (store.injectedReducers[key] !== undefined) return;

    store.injectedReducers[key] = reducer;
    store.replaceReducer(createRootReducer(store.injectedReducers));
};

store.injectSaga = (key, saga) => {
    if (store.injectedSagas[key] !== undefined) return;

    store.injectedSagas[key] = saga;
    store.runSaga(saga);
};

sagaMiddleware.run(rootSaga);

export default store;
