import React, { lazy, Suspense } from 'react';
import Loading from 'components/loading/loading';

const AsyncMediaPlayerPage = lazy(() =>
    import(/* webpackChunkName: "media-player-page" */ '../../pages/media-player/media-player-page')
);

const fallback = <Loading />;

const MediaPlayerLazyRoute = props => {
    return (
        <Suspense fallback={fallback}>
            <AsyncMediaPlayerPage {...props} />
        </Suspense>
    );
};

export default MediaPlayerLazyRoute;
