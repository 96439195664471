/* eslint-disable eqeqeq */
import { createSelector } from 'reselect';
import { teamsPredicateSelector } from 'selectors/predicates/team-predicates';
import { processingState } from '../../../widgets/ice-player/episodes/episode-utils';

const defaultPositionStatus = ['F', 'D'];
const getPositionStatus = state => state.statsFilter.positionStatus || defaultPositionStatus;

const getGamePlayers = state => state.entities.gamePlayers;

const getGames = state => state.entities.games;

const getEvents = state => state.widgetsStats.events;

const getSingleEvents = state => (state.widgetsStats.eventsSingle ? state.widgetsStats.eventsSingle : processingState);

const forEvent = (event, gamePlayers, games, teamPredicate) => {
    const game = games[event.gameId];
    const player = gamePlayers[event.gamePlayerId];
    const teamId =
        !player || player.teamStatus === 'HOME' //hook for events without gamePlayerId
            ? game.homeTeam
            : game.awayTeam;
    return teamPredicate(teamId);
};

function getEventsFilteredByTeamStatus(events, teamPredicate, positionStatus, gamePlayers, games) {
    if (!events || events.processing || !games || !gamePlayers) return { processing: true };
    return {
        data: events.data
            .filter(d => forEvent(d, gamePlayers, games, teamPredicate))
            .filter(d => {
                const player = gamePlayers[d.gamePlayerId];
                return player && positionStatus.indexOf(player.playerPosition) !== -1;
            })
    };
}

function getEventsFilteredByPlayer(events) {
    if (!events || events.processing) return { processing: true };
    return { data: events.data.map(event => ({ ...event, individualEvent: true })) };
}

export const eventsTeamsSelector = createSelector(
    getEvents,
    teamsPredicateSelector,
    getPositionStatus,
    getGamePlayers,
    getGames,
    getEventsFilteredByTeamStatus
);

export const individualEventsSelector = createSelector(
    getSingleEvents,
    getEventsFilteredByPlayer
);
