import { makePrefixAdder } from '../../redux/utils/type-utils';
import omit from 'lodash/omit';
import { tetherOptionsFromChartJsTooltip } from '../../redux/utils/chartjs-tooltip';

const addPrefixTo = makePrefixAdder('tooltip');

const TOOLTIP_MOUNT = addPrefixTo('TOOLTIP_MOUNT');
const TOOLTIP_UNMOUNT = addPrefixTo('TOOLTIP_UNMOUNT');
const TOOLTIP_SHOW = addPrefixTo('TOOLTIP_SHOW');
const TOOLTIP_HIDE = addPrefixTo('TOOLTIP_HIDE');
const TOOLTIP_DETACH = addPrefixTo('TOOLTIP_DETACH');
const TOOLTIP_FIX = addPrefixTo('TOOLTIP_FIX');

function mountTooltip(target, render, options) {
    return { type: TOOLTIP_MOUNT, target, render, options };
}

function unmountTooltip(target) {
    return { type: TOOLTIP_UNMOUNT, target };
}

function showTooltip(tooltipId, target, render, options) {
    return { type: TOOLTIP_SHOW, tooltipId, target, render, options };
}

function showChartJsTooltip(tooltipId, target, chartJsTooltip, render) {
    return {
        type: TOOLTIP_SHOW,
        tooltipId,
        target,
        render,
        options: { tether: tetherOptionsFromChartJsTooltip(chartJsTooltip) }
    };
}

function hideTooltip(tooltipId) {
    return { type: TOOLTIP_HIDE, tooltipId };
}

function detachTooltip(tooltipId) {
    return { type: TOOLTIP_DETACH, tooltipId };
}

function fixTooltip(tooltipId) {
    return { type: TOOLTIP_FIX, tooltipId };
}

export const actions = {
    mountTooltip,
    unmountTooltip,
    showTooltip,
    showChartJsTooltip,
    hideTooltip,
    detachTooltip,
    fixTooltip
};

const initialState = {
    mountedTooltips: {},
    plainTooltips: {}
};

const tooltipModule = (state = initialState, action) => {
    const { target, tooltipId, render, options } = action;
    switch (action.type) {
        case TOOLTIP_MOUNT:
            return {
                ...state,
                mountedTooltips: { ...state.mountedTooltips, [target]: { target, render, options } },
                plainTooltips: state.plainTooltips
            };
        case TOOLTIP_UNMOUNT:
            return {
                ...state,
                mountedTooltips: omit(state.mountedTooltips, target),
                plainTooltips: state.plainTooltips
            };
        case TOOLTIP_SHOW:
            return {
                ...state,
                mountedTooltips: state.mountedTooltips,
                plainTooltips: { ...state.plainTooltips, [tooltipId]: { tooltipId, target, render, options } }
            };
        case TOOLTIP_HIDE:
            return {
                ...state,
                mountedTooltips: state.mountedTooltips,
                plainTooltips: omit(state.plainTooltips, tooltipId)
            };
        case TOOLTIP_FIX:
            if (!state.plainTooltips[tooltipId]) return state;

            return {
                ...state,
                mountedTooltips: state.mountedTooltips,
                plainTooltips: {
                    ...state.plainTooltips,
                    [tooltipId]: {
                        ...state.plainTooltips[tooltipId],
                        fixed: true
                    }
                }
            };
        case TOOLTIP_DETACH:
            const detachedTooltip = state.plainTooltips[tooltipId];
            return !detachedTooltip
                ? state
                : {
                      ...state,
                      mountedTooltips: state.mountedTooltips,
                      plainTooltips: { ...state.plainTooltips, [tooltipId]: { ...detachedTooltip, detached: true } }
                  };
        default:
            return state;
    }
};
export default tooltipModule;
