/**
 * PRO game type
 */
export const PRO = 'PRO';

/**
 * LITE game type
 */
export const LITE = 'LITE';

/**
 * SCOUT game type
 */
export const SCOUT = 'SCOUT';

/**
 * SLOW PREMIUM game type
 */

export const SLOW_PREMIUM = 'SLOW_PREMIUM';

/**
 * SUPER LITE game type
 */

export const SUPER_LITE = 'SUPER_LITE';
