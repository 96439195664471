import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { DateRangePickerConstant } from './date-range-picker-constants';
import {
    DateRangeFieldControls,
    DateRangeFieldWrapper,
    DaysPickerWrapper,
    DateRangeFieldControlReset,
    DayPickerWrapperBegin,
    DayPickerWrapperEnd,
    DateRangeFieldControlBegin,
    DateRangeFieldControlEnd,
    DateRangeFieldControlResetWrapper
} from './date-range-field-styled-components';
import FormattedMessage from '../formatted-message';
import { languageSelector } from '../../redux/modules/locale';
import DeviceSelector from '../device-selector/device-selector';
import StyledModal from '../modal/styled-modal';

function convertDayToDate(day) {
    const rawMM = day.getMonth() + 1;
    const rawDD = day.getDate();

    function formatDateTwoSymbol(partDate) {
        return partDate >= 10 ? partDate : `0${partDate}`;
    }

    const MM = '' + formatDateTwoSymbol(rawMM);
    const DD = '' + formatDateTwoSymbol(rawDD);
    const YYYY = day.getFullYear();

    return `${YYYY}-${MM}-${DD}`;
}

const DateRangeCustomStyles = {
    background: 'red'
};

/***
 * Will be render date picker and return on change date.
 * @param props
 * @returns {*}
 * {begin:  "YYYY-MM-DD", end: "YYYY-MM-DD" }
 */
const DateRangeField = props => {
    const { value, leftPadding, firstDateSet, DEFAULT_CUSTOM_DATE } = props;

    const [beginDate, setBeginDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [beginDatePickerIsVisible, setBeginDatePickerVisibility] = useState(false);
    const [endDatePickerIsVisible, setEndDatePickerVisibility] = useState(false);

    const [locale, setLocale] = useState('en');

    const [resetValue, setResetValue] = useState(false);

    useEffect(
        () => {
            const { onChange, path } = props;

            if (!onChange) {
                return;
            }

            if (!(beginDate === '' && endDate === '')) {
                onChange(path, { begin: beginDate, end: endDate });
                return;
            }

            if (!!firstDateSet && !resetValue) {
                onChange(path, { begin: DEFAULT_CUSTOM_DATE, end: '' });
                return;
            }

            onChange(path, { begin: '', end: '' });
        },
        /*eslint-disable*/ [beginDate, endDate] /*eslint-enable*/
    );

    useEffect(
        () => {
            setLocale(props.locale);
        },
        /*eslint-disable*/ [props.locale] /*eslint-enable*/
    );

    useEffect(() => {
        if (!resetValue) {
            return;
        }

        const resettableTimeout = setTimeout(() => {
            setResetValue(false);
        }, 250);

        return () => {
            clearTimeout(resettableTimeout);
            setResetValue(false);
        };
    }, [resetValue]);

    const handleChangeBeginDay = day => {
        const dateFromDay = convertDayToDate(day);

        setBeginDate(dateFromDay);

        setBeginDatePickerVisibility(false);
    };

    const handleChangeEndDay = day => {
        //todo check beginDate <= endDate and return this with notification

        const dateFromDay = convertDayToDate(day);

        setEndDate(dateFromDay);

        setEndDatePickerVisibility(false);
    };

    const handleDateBeginClick = () => {
        setBeginDatePickerVisibility(!beginDatePickerIsVisible);
        if (endDatePickerIsVisible && !beginDatePickerIsVisible) {
            setEndDatePickerVisibility(false);
        }
    };

    const handleDateEndClick = () => {
        if (endDatePickerIsVisible) {
            return;
        }
        setEndDatePickerVisibility(!endDatePickerIsVisible);
        if (!endDatePickerIsVisible && beginDatePickerIsVisible) {
            setBeginDatePickerVisibility(false);
        }
    };

    const handleResetTime = () => {
        setBeginDate('');
        setEndDate('');
        setBeginDatePickerVisibility(false);
        setEndDatePickerVisibility(false);
        setResetValue(true);
    };

    const transformDateToLocalView = dateRaw => {
        const year = dateRaw.slice(0, 4);
        const month = dateRaw.slice(5, 7);
        const day = dateRaw.slice(8);

        if (locale === 'ru') {
            return `${day}/${month}/${year}`;
        }

        return `${month}/${day}/${year}`;
    };

    const { path } = props;

    const { MONTHS, WEEKDAYS_SHORT, WEEKDAYS_LONG, FIRST_DAY_OF_WEEK, LABELS } = DateRangePickerConstant;

    const containerDayPickerBeginRef = useRef(null);
    const modalDayPickerBeginRef = useRef(null);
    const containerDayPickerEndRef = useRef(null);
    const modalDayPickerEndRef = useRef(null);

    const handleClickOutside = useCallback(
        e => {
            if (
                containerDayPickerBeginRef.current &&
                !containerDayPickerBeginRef.current.contains(e.target) &&
                (!modalDayPickerBeginRef.current ||
                    (modalDayPickerBeginRef.current && !modalDayPickerBeginRef.current.contains(e.target)))
            ) {
                setBeginDatePickerVisibility(false);
            }

            if (
                containerDayPickerEndRef.current &&
                !containerDayPickerEndRef.current.contains(e.target) &&
                (!modalDayPickerEndRef.current ||
                    (modalDayPickerEndRef.current && !modalDayPickerEndRef.current.contains(e.target)))
            ) {
                setEndDatePickerVisibility(false);
            }
        },
        /*eslint-disable*/ [
            containerDayPickerBeginRef,
            containerDayPickerEndRef,
            modalDayPickerBeginRef,
            modalDayPickerEndRef
        ] /*eslint-enable*/
    );

    useEffect(
        () => {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('scroll', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('scroll', handleClickOutside);
            };
        },
        /*eslint-disable*/ [beginDatePickerIsVisible, endDatePickerIsVisible] /*eslint-enable*/
    );

    useEffect(() => {
        setBeginDate(value.begin || '');
        setEndDate(value.end || '');
    }, [value]);

    return (
        <DateRangeFieldWrapper>
            <DateRangeFieldControls>
                <DateRangeFieldControlBegin isActive={beginDatePickerIsVisible} onClick={handleDateBeginClick}>
                    {beginDate === '' ? (
                        <FormattedMessage id={'interface.calendar.begin'} />
                    ) : (
                        transformDateToLocalView(beginDate)
                    )}
                </DateRangeFieldControlBegin>
                <DateRangeFieldControlEnd isActive={endDatePickerIsVisible} onClick={handleDateEndClick}>
                    {endDate === '' ? (
                        <FormattedMessage id={'interface.calendar.end'} />
                    ) : (
                        transformDateToLocalView(endDate)
                    )}
                </DateRangeFieldControlEnd>
                {(beginDate !== '' || endDate !== '') && (
                    <DateRangeFieldControlResetWrapper>
                        <DateRangeFieldControlReset onClick={handleResetTime}>
                            <span>X</span>
                        </DateRangeFieldControlReset>
                    </DateRangeFieldControlResetWrapper>
                )}
            </DateRangeFieldControls>
            {(beginDatePickerIsVisible || endDatePickerIsVisible) && (
                <DaysPickerWrapper leftPadding={leftPadding}>
                    {beginDatePickerIsVisible && (
                        <DayPickerWrapperBegin ref={containerDayPickerBeginRef}>
                            <DeviceSelector
                                desktopLayout={
                                    <DayPicker
                                        onDayClick={handleChangeBeginDay}
                                        locale={locale}
                                        months={MONTHS[locale]}
                                        weekdaysLong={WEEKDAYS_LONG[locale]}
                                        weekdaysShort={WEEKDAYS_SHORT[locale]}
                                        firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
                                        labels={LABELS[locale]}
                                        key={`${path}-begin`}
                                        style={{ DateRangeCustomStyles: DateRangeCustomStyles }}
                                    />
                                }
                                mobileLayout={
                                    <StyledModal innerRef={modalDayPickerBeginRef} isOpen disableScroll>
                                        <DayPicker
                                            onDayClick={handleChangeBeginDay}
                                            locale={locale}
                                            months={MONTHS[locale]}
                                            weekdaysLong={WEEKDAYS_LONG[locale]}
                                            weekdaysShort={WEEKDAYS_SHORT[locale]}
                                            firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
                                            labels={LABELS[locale]}
                                            key={`${path}-begin`}
                                            style={{ DateRangeCustomStyles: DateRangeCustomStyles }}
                                        />
                                    </StyledModal>
                                }
                            />
                        </DayPickerWrapperBegin>
                    )}

                    {endDatePickerIsVisible && (
                        <DayPickerWrapperEnd ref={containerDayPickerEndRef}>
                            <DeviceSelector
                                desktopLayout={
                                    <DayPicker
                                        onDayClick={handleChangeEndDay}
                                        locale={locale}
                                        months={MONTHS[locale]}
                                        weekdaysLong={WEEKDAYS_LONG[locale]}
                                        weekdaysShort={WEEKDAYS_SHORT[locale]}
                                        firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
                                        labels={LABELS[locale]}
                                        key={`${path}-end`}
                                        style={{ DateRangeCustomStyles: DateRangeCustomStyles }}
                                    />
                                }
                                mobileLayout={
                                    <StyledModal innerRef={modalDayPickerEndRef} isOpen disableScroll>
                                        <DayPicker
                                            onDayClick={handleChangeEndDay}
                                            locale={locale}
                                            months={MONTHS[locale]}
                                            weekdaysLong={WEEKDAYS_LONG[locale]}
                                            weekdaysShort={WEEKDAYS_SHORT[locale]}
                                            firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
                                            labels={LABELS[locale]}
                                            key={`${path}-end`}
                                            style={{ DateRangeCustomStyles: DateRangeCustomStyles }}
                                        />
                                    </StyledModal>
                                }
                            />
                        </DayPickerWrapperEnd>
                    )}
                </DaysPickerWrapper>
            )}
        </DateRangeFieldWrapper>
    );
};

const mapStateToProps = state => {
    return {
        locale: languageSelector(state)
    };
};

export default connect(mapStateToProps)(DateRangeField);
