import { hasTemporaryKey, STORAGE_DESIGN, STORAGE_THEME } from '../../../utils/api/auth-utils';

export function syncThemeWithStorage(theme) {
    if (hasTemporaryKey()) {
        theme && sessionStorage.setItem(STORAGE_THEME, theme);
    } else {
        theme && localStorage.setItem(STORAGE_THEME, theme);
    }
}

export function syncDesignWithStorage(design) {
    if (hasTemporaryKey()) {
        design && sessionStorage.setItem(STORAGE_DESIGN, design);
    } else {
        design && localStorage.setItem(STORAGE_DESIGN, design);
    }
}
