import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './language.module.css';
import { actions as localeActions } from '../../../redux/modules/locale';
import { trackEvent, trackedEvents } from '../../../telemetry/app-analytics';
import enLogo from '../../../translation/en.png';
import ruLogo from '../../../translation/ru.png';
import zhLogo from '../../../translation/zh.png';
import { isUserClient } from '../../../selectors/user/user-roles-selector';

const langs = ['en', 'ru', 'zh'];

const langTitles = {
    en: 'English',
    ru: 'Русский',
    zh: '简体中文'
};

const langLogos = {
    en: enLogo,
    ru: ruLogo,
    zh: zhLogo
};

class LangSelect extends PureComponent {
    constructor(props) {
        super(props);
        this.dropdown = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = e => {
        if (e.target !== this.dropdown.current && !this.dropdown.current.contains(e.target)) {
            this.props.select(this.props.value);
        }
    };

    render() {
        const { value, select } = this.props;
        return (
            <div className={styles.langSelect} ref={this.dropdown}>
                {langs.map(lang => (
                    <div
                        key={lang}
                        className={value === lang ? styles.currentLangItem : styles.langItem}
                        onClick={() => select(lang)}
                    >
                        <img alt="" src={langLogos[lang]} className={styles.langLogoItem} />
                        {langTitles[lang]}
                    </div>
                ))}
            </div>
        );
    }
}

class LanguageSwitcher extends Component {
    state = {
        opened: false
    };

    toggle = () => {
        this.setState({ opened: !this.state.opened });
    };

    renderClosed() {
        const { language } = this.props;
        return (
            <span className={styles.base} onClick={this.toggle}>
                <img alt="" src={langLogos[language]} className={styles.langLogo} />
            </span>
        );
    }

    select = lang => {
        trackEvent(trackedEvents.SWITCH_LANG, { properties: { language: lang } });
        this.props.changeLanguage(lang);
        this.setState({ opened: false });
    };

    renderOpened() {
        const { language } = this.props;

        return (
            <div className={styles.container}>
                <span className={styles.base} onClick={this.toggle}>
                    <img alt="" src={langLogos[language]} className={styles.langLogo} />
                </span>
                <div className={styles.dropdown}>
                    <LangSelect value={language} select={this.select} />
                </div>
            </div>
        );
    }

    render() {
        const { opened } = this.state;
        const { userIsClient } = this.props;
        return userIsClient ? null : opened ? this.renderOpened() : this.renderClosed();
    }
}

LanguageSwitcher.propTypes = {
    changeLanguage: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return { language: state.locale.language, userIsClient: isUserClient(state) };
}

export default connect(
    mapStateToProps,
    localeActions
)(LanguageSwitcher);
