import { fieldShotsSelector } from '../../../../../containers/widgets-stats/selectors/shots';
import { FAVORITE_EVENT } from '../../favorite-types';
import { createEpisodeIdWrapper, mapperErrorMessage, processingState } from '../../episode-utils';
import { createSelector } from 'reselect';
import { getTypeToMediaTimeWrapper } from '../helpers/episodes-selectors-helpers';

const BLOCKED_SHOTS = 'BLOCKED_SHOTS';
const SCORING_CHANCES = 'SCORING_CHANCES';
const SCORING_CHANCES_ON_ICE = 'SCORING_CHANCES_ON_ICE';
const SCORE_ATTEMPTS = 'SCORE_ATTEMPTS';
const SCORE_ATTEMPTS_ON_ICE = 'SCORE_ATTEMPTS_ON_ICE';
const GOALS = 'GOALS';
const SHOTS_ON_GOAL = 'SHOTS_ON_GOAL';
const SHOTS_WITH_REBOUND = 'SHOTS_WITH_REBOUND';
const SHOTS_WITHOUT_REBOUND = 'SHOTS_WITHOUT_REBOUND';
const MISSED_SHOTS = 'MISSED_SHOTS';

const selector = fieldShotsSelector;

const types = {
    BLOCKED_SHOTS,
    SCORING_CHANCES,
    SCORING_CHANCES_ON_ICE,
    SCORE_ATTEMPTS,
    SCORE_ATTEMPTS_ON_ICE,
    GOALS,
    SHOTS_ON_GOAL,
    SHOTS_WITH_REBOUND,
    SHOTS_WITHOUT_REBOUND,
    MISSED_SHOTS
};

export const createGoalsSelector = () =>
    createSelector(
        shots => shots.data,
        data => ({ data: data.filter(s => s.goal) })
    );

export const createScoringChancesSelector = () =>
    createSelector(
        shots => shots.data,
        data => ({ data: data.filter(s => s.danger) })
    );

export const createBlockedShotsSelector = () =>
    createSelector(
        shots => shots.data,
        data => ({ data: data?.filter(s => s.eventType === 'SHOT_ATTEMPT_BLOCKED') || [] })
    );

export const createShotsOnGoalSelector = () =>
    createSelector(
        shots => shots.data,
        data => ({ data: data.filter(s => s.shotOnGoal) })
    );

export const createShotsWithReboundSelector = () =>
    createSelector(
        shots => shots.data,
        data => ({ data: data.filter(s => s.shotWithRebound) })
    );

export const createShotsWithoutReboundSelector = () =>
    createSelector(
        shots => shots.data,
        data => ({ data: data.filter(s => !s.shotWithRebound) })
    );

export const createMissedShotsSelector = () =>
    createSelector(
        shots => shots.data,
        data => ({ data: data.filter(s => !s.shotOnGoal) })
    );

const typeToSelector = {
    GOALS: createEpisodeIdWrapper(GOALS, createGoalsSelector()),
    SCORING_CHANCES: createEpisodeIdWrapper(SCORING_CHANCES, createScoringChancesSelector()),
    BLOCKED_SHOTS: createEpisodeIdWrapper(BLOCKED_SHOTS, createBlockedShotsSelector()),
    SHOTS_ON_GOAL: createEpisodeIdWrapper(SHOTS_ON_GOAL, createShotsOnGoalSelector()),
    SHOTS_WITH_REBOUND: createEpisodeIdWrapper(SHOTS_WITH_REBOUND, createShotsWithReboundSelector()),
    SHOTS_WITHOUT_REBOUND: createEpisodeIdWrapper(SHOTS_WITHOUT_REBOUND, createShotsWithoutReboundSelector()),
    MISSED_SHOTS: createEpisodeIdWrapper(MISSED_SHOTS, createMissedShotsSelector()),
    SCORE_ATTEMPTS: createEpisodeIdWrapper(SCORE_ATTEMPTS, s => s)
};

function mapper(shots, type) {
    if (shots.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Shots');
    return typeToSelector[type](shots);
}

const favoriteType = FAVORITE_EVENT;

const typeToMediaTimeWrapper = getTypeToMediaTimeWrapper(typeToSelector);

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

export const shots = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
