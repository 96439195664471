import * as bundles from './bundles';
import { REQUEST, SUCCESS, CANCEL, CANCELLED } from './fetch-stats-types';
import { types as filterTypes } from '../stats-filter/module';

const STORAGE = 'STORAGE';

function fetchNodes(nodeIds) {
    return { type: REQUEST, payload: { stats: nodeIds, legacy: true } };
}

function fetchFilteredStats(stats, filterToValues, filterToStoredState, additionalFilterProps = {}) {
    return { type: REQUEST, payload: { stats, filterToValues, filterToStoredState, additionalFilterProps } };
}

function cancel() {
    return { type: CANCEL };
}

function setupVideoStorage(storage) {
    return { type: STORAGE, payload: { storage } };
}

// noinspection JSUnusedGlobalSymbols
export const actions = { fetchNodes, fetchFilteredStats, cancel, setupVideoStorage };

function handleBundle(state = {}, action, bundleName, bundle) {
    const { type } = action;
    switch (type) {
        case bundle.REQUEST:
            return {
                ...state,
                [bundleName]: {
                    ...state[bundleName],
                    processing: true
                }
            };
        case bundle.SUCCESS:
            const data = action.isEntity ? action.response.result : action.response.data;
            return {
                ...state,
                [bundleName]: {
                    data,
                    processing: false
                }
            };
        case bundle.CANCELLED:
            return {
                ...state,
                [bundleName]: {
                    processing: false
                }
            };
        case bundle.FAILURE:
            return {
                ...state,
                [bundleName]: {
                    processing: false,
                    errors: [action.response.data.message]
                }
            };
        default:
            return state;
    }
}

const widgetStatsModule = (state = {}, action) => {
    const { type } = action;
    switch (type) {
        case filterTypes.FILTER_UPDATE:
        case REQUEST:
            return { processing: true, cancelled: false };
        case SUCCESS:
            return { ...state, processing: false, cancelled: false };
        case CANCELLED:
            return { ...state, processing: false, cancelled: true };
        case STORAGE:
            return { ...state, sharingMode: true, ...action.payload };
        default:
    }

    return Object.entries(bundles).reduce((state, bundleEntry) => handleBundle(state, action, ...bundleEntry), state);
};

export default widgetStatsModule;
