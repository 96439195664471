import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { productToPath } from '../../containers/product-initializer/products';
import { scoutPages } from '../../constants/page-names';

const excludes = [new RegExp(productToPath?.SCOUT?.path + '/' + scoutPages.players + 'd*')];

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (
            excludes.some(regex => regex.test(this.props.location.pathname) && regex.test(prevProps.location.pathname))
        ) {
            return;
        }
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
