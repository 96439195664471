import { useCallback, useEffect, useRef } from 'react';

export const useOutsideClick = ({ isMobile, handeVisible, isVisible }) => {
    const containerRef = useRef();

    const handleClickOutside = useCallback(
        e => {
            if (!isMobile) {
                return;
            }

            if (containerRef.current && !containerRef.current.contains(e.target)) {
                handeVisible(false);
            }
        },
        [containerRef, handeVisible, isMobile]
    );

    useEffect(() => {
        if (!isVisible || !isMobile) {
            return;
        }

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('scroll', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('scroll', handleClickOutside);
        };
    }, [handleClickOutside, isVisible, isMobile]);

    return containerRef;
};

export const useOutsideClickContainer = ({ handelVisible }) => {
    const containerRef = useRef();
    const handleClickOutside = useCallback(
        event => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handelVisible();
            }
        },
        [containerRef]
    );

    useEffect(() => {
        /**
         * if clicked on outside of element
         */
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('scroll', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.addEventListener('scroll', handleClickOutside);
        };
    }, [containerRef]);
    return containerRef;
};

export const useOutSideClickDropdown = ({ handleReset, isVisible }) => {
    const dropdownRef = useRef(null);
    const containerPanelRef = useRef(null);

    const handleClickOutside = useCallback(
        e => {
            if (
                e.target !== dropdownRef.current &&
                !dropdownRef.current?.contains(e.target) &&
                e.target !== containerPanelRef.current &&
                !containerPanelRef.current?.contains(e.target)
            ) {
                handleReset();
            }
        },
        [handleReset]
    );

    useEffect(() => {
        const eventType = 'ontouchstart' in window ? 'touchstart' : 'click';

        if (isVisible) {
            document.addEventListener(eventType, handleClickOutside, false);
        }

        return () => {
            document.removeEventListener(eventType, handleClickOutside, false);
        };
    }, [isVisible, handleClickOutside]);

    return { dropdownRef, containerPanelRef };
};
