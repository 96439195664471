import { createSelector } from 'reselect';
import { processingState } from '../../episode-utils';
import { combinePossessions } from './episodes-selectors-helpers';

export const recoveriesFromEventsSelector = createSelector(
    state => (state.widgetsStats.events ? state.widgetsStats.events : processingState),
    events => {
        if (!events || !events.data || !Array.isArray(events.data)) {
            return {
                data: []
            };
        }
        return {
            data: events.data.filter(e => e.recovery || e.reboundRecovery)
        };
    }
);

export const takeAwayFromEventsSelector = createSelector(
    state => (state.widgetsStats.events ? state.widgetsStats.events : processingState),
    events => {
        if (!events || !events.data || !Array.isArray(events.data)) {
            return {
                data: []
            };
        }
        return {
            data: events.data.filter(e => !!e.takeaway && e.eventType !== 'FACE_OFF')
        };
    }
);

export const faceOffFromEventsSelector = createSelector(
    state => (state.widgetsStats.events ? state.widgetsStats.events : processingState),
    events => {
        if (!events || !events.data || !Array.isArray(events.data)) {
            return {
                data: []
            };
        }
        return {
            data: events.data.filter(e => e.eventType === 'FACE_OFF')
        };
    }
);

export const possessionsFromEventsSelector = createSelector(
    state => (state.widgetsStats.events ? state.widgetsStats.events : processingState),
    events => {
        if (!events || !events.data || !Array.isArray(events.data)) {
            return {
                data: []
            };
        }
        return {
            data: combinePossessions(events.data)
        };
    }
);
