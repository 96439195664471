import React, { Component } from 'react';
import { observableUserInit } from 'containers/user';
import AnimatedProgress from './animated-progress';
import { MOUNTED, UNMOUNTED, observableMount } from './mount-notifier';

const QUARTER_PROGRESS = 25;
const HALF_PROGRESS = 50;
const FULL_PROGRESS = 100;

class ProgressIndicator extends Component {
    state = {
        progress: QUARTER_PROGRESS
    };

    componentDidMount() {
        this.unsubscribeUserInit = observableUserInit.subscribe(this.handleUserInit);
        this.unsubscribeMount = observableMount.subscribe(this.handleMountNotify);
    }

    componentWillUnmount() {
        this.unsubscribeUserInit();
        this.unsubscribeMount();
    }

    handleMountNotify = status => {
        if (status === UNMOUNTED) {
            this.setState({ progress: FULL_PROGRESS });
        } else if (status === MOUNTED) {
            this.setState({ progress: HALF_PROGRESS });
        }
    };

    handleUserInit = () => {
        this.setState(({ progress: prevProgress }) =>
            prevProgress < HALF_PROGRESS ? { progress: HALF_PROGRESS } : null
        );
    };

    render() {
        const { progress } = this.state;

        if (window.location.pathname === '/report' || window.location.pathname === '/nft') {
            return null;
        }

        return <AnimatedProgress progress={progress} completed={progress === FULL_PROGRESS} />;
    }
}

export default ProgressIndicator;
