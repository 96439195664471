import React from 'react';
import {
    PopupMenu,
    PopupHeader,
    PopupTitle,
    PopupBody,
    ReleaseDescription,
    ReleaseContent,
    ReleaseTitle,
    ReleaseDate
} from './release-note-styled-components.js';
import { formatSeasonDate } from 'utils/helpers/date-utils.js';

const ReleaseNoteModel = ({ releaseData, ref }) => {
    return (
        <PopupMenu ref={ref}>
            <PopupHeader>
                <PopupTitle>What's New</PopupTitle>
            </PopupHeader>
            <PopupBody>
                {releaseData?.map((el, key) => (
                    <ReleaseContent key={key}>
                        <ReleaseTitle>{el.title}</ReleaseTitle>
                        <ReleaseDescription>{el.description}</ReleaseDescription>
                        <ReleaseDate>{formatSeasonDate(el.created)}</ReleaseDate>
                    </ReleaseContent>
                ))}
            </PopupBody>
        </PopupMenu>
    );
};

export default ReleaseNoteModel;
