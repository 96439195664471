/**
 * Stylized input form
 */
import React, { Component } from 'react';
import styleBinder from 'classnames/bind';
import style from './input.module.css';

export default class Input extends Component {
    render() {
        const { className, unbounded = false, inputRef, type, ...attrs } = this.props;
        const cx = styleBinder.bind(style);
        const inputStyle = cx('form-control', style.input, className, { unbounded });
        return type === 'textarea' ? (
            <textarea ref={inputRef} className={inputStyle} {...attrs} />
        ) : (
            <input ref={inputRef} className={inputStyle} type={type} {...attrs} />
        );
    }
}
