import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import PageContainer from 'containers/page-container';
import * as endpoints from 'utils/api/endpoints';
import { AUTH_TOKEN_NAME, getAuthKey } from 'utils/api/auth-utils';

import FormattedMessage from '../formatted-message/index';
import Button from '../button/button';

import styles from './error.module.css';

const baseHeaders = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
};

const reloadPage = () => window.location.reload();

const RefreshButton = () => (
    <Button onClick={reloadPage} className={styles.refreshButton}>
        <FormattedMessage id="components.error.reloadPage" />
    </Button>
);

const defaultTitle = <FormattedMessage id="components.error.errorMessage" />;
const defaultInfo = <FormattedMessage id="components.error.info" />;

const Error = ({
    showRefreshButton,
    error,
    title = defaultTitle,
    info = defaultInfo,
    withoutFooter,
    customButton,
    noReport = false
}) => {
    const { pathname } = useLocation() || {};

    useEffect(() => {
        if (noReport || !error) {
            return;
        }

        const errorText = error?.message ?? `No data for this error: ${error?.toString()}`;

        const authKey = getAuthKey();

        axios
            .post(
                endpoints.errorsReport,
                {
                    console: errorText,
                    referer: pathname
                },
                {
                    headers: authKey ? { ...baseHeaders, [AUTH_TOKEN_NAME]: authKey } : baseHeaders
                }
            )
            .catch(e => {
                //eslint-disable-next-line
                console.error(e);
            });
    }, [error, pathname, noReport]);

    const content = (
        <div className={styles.content}>
            <h1>
                <FormattedMessage id="components.notFound.oops" />
            </h1>
            <h2>{title}</h2>
            <h6>{info}</h6>
            {showRefreshButton && <RefreshButton />}
            {customButton && (
                <Button onClick={customButton.onClick} className={styles.refreshButton}>
                    {customButton.content}
                </Button>
            )}
        </div>
    );

    return <div className={styles.container}>{withoutFooter ? content : <PageContainer>{content}</PageContainer>}</div>;
};

export const NotFound = () => (
    <Error
        notReport
        title={<FormattedMessage id="components.notFound.errorMessage" />}
        info={<FormattedMessage id="components.notFound.errorCode" />}
    />
);

export const NotImplemented = ({ withoutFooter }) => (
    <Error
        notReport
        withoutFooter={withoutFooter}
        title={<FormattedMessage id="components.notImplemented.errorMessage" />}
        info={null}
    />
);

export const LicenseDeclined = ({ withoutFooter }) => (
    <Error
        notReport
        withoutFooter={withoutFooter}
        title={<FormattedMessage id="components.licenseDeclined.title" />}
        info={<FormattedMessage id="components.licenseDeclined.errorMessage" />}
    />
);

export const BadLink = ({ withoutFooter }) => (
    <Error
        notReport
        withoutFooter={withoutFooter}
        title={<FormattedMessage id="components.badLink.title" />}
        info={<FormattedMessage id="components.badLink.errorMessage" />}
    />
);

export default Error;
