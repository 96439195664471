import { notEmptyPredicate } from './check-condition-utils';
import { symbolIsNonASCII } from './symbols-checker';

export const capitalizeFirstLetter = (someString = '') => {
    return someString.charAt(0).toUpperCase() + someString.slice(1);
};

const MINIMUM_PASS_LENGTH = 8;

export const checkPasswordFieldCondition = fieldAsString => {
    if (!notEmptyPredicate(fieldAsString)) {
        return 'Required';
    }

    if (fieldAsString.length < MINIMUM_PASS_LENGTH) {
        return `Minimum ${MINIMUM_PASS_LENGTH} symbols.`;
    }

    const fieldAsArray = fieldAsString?.split('');

    if (fieldAsArray && fieldAsArray.length > 0) {
        if (fieldAsArray.filter(el => !isNaN(el)).length === 0) {
            return 'Minimum one of symbols must be a number from 0 to 9.';
        }

        if (fieldAsArray.filter(el => !symbolIsNonASCII(el) && isNaN(Number(el))).length === 0) {
            return `Minimum one of symbols must be a letter symbol like: 'a', 'i', 'b',  etc.`;
        }

        if (
            fieldAsArray.filter(el => !symbolIsNonASCII(el) && isNaN(Number(el)) && el === el.toUpperCase()).length ===
            0
        ) {
            return `Minimum one of symbols must be a capitalize like: 'A', 'I', 'B',  etc.`;
        }
    }

    return null;
};

export const checkMinFieldCondition = fieldAsString => {
    if (!notEmptyPredicate(fieldAsString)) {
        return 'Required';
    }

    return null;
};
