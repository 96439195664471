import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import sortBy from 'lodash/sortBy';

import { AUTH_TOKEN_NAME, getAuthKey } from 'utils/api/auth-utils';
import { httpMethods } from 'utils/hooks/use-fetch-data';
import * as endpoints from 'utils/api/endpoints';

import { INPUT_TIMEOUT, MIN_INPUT_LENGTH } from '../constants';

export const useFetchSearchPlayerTeams = ({ inputValue }) => {
    const [collectionPlayers, setCollectionPlayers] = useState(new Map());
    const [collectionTeams, setCollectionTeams] = useState(new Map());
    const [prevInputSearchValue, setPrevInputSearchValue] = useState('');

    const [processing, setProcessing] = useState(false);

    const abortController = new AbortController();

    const searchTimeoutRef = useRef();

    const handleSetPrev = useCallback(val => {
        searchTimeoutRef.current = setTimeout(() => {
            setPrevInputSearchValue(val);
        }, INPUT_TIMEOUT);
    }, []);

    useEffect(() => {
        clearTimeout(searchTimeoutRef.current);
        handleSetPrev(inputValue);
    }, [inputValue]);

    useEffect(() => {
        if (
            !inputValue ||
            inputValue === '' ||
            inputValue <= MIN_INPUT_LENGTH ||
            !prevInputSearchValue ||
            prevInputSearchValue === '' ||
            inputValue !== prevInputSearchValue
        ) {
            return;
        }

        setPrevInputSearchValue('');

        abortController.abort();

        setProcessing(true);

        Promise.all([
            axios({
                headers: { 'X-Requested-With': 'XMLHttpRequest', [AUTH_TOKEN_NAME]: getAuthKey() },
                method: httpMethods.get,
                url: endpoints.scoutSearchPlayersByName(inputValue),
                signal: abortController.signal
            }),
            axios({
                headers: { 'X-Requested-With': 'XMLHttpRequest', [AUTH_TOKEN_NAME]: getAuthKey() },
                method: httpMethods.get,
                url: endpoints.teamsClientSearch(inputValue),
                signal: abortController.signal
            })
        ])
            .then(resp => {
                setCollectionPlayers(el => el.set(inputValue, resp[0].data));
                setCollectionTeams(el => el.set(inputValue, resp[1].data));
            })
            .catch(e => {
                //eslint-disable-next-line
            console.error(e?.message);
            })
            .finally(() => {
                setProcessing(false);
            });
    }, [inputValue, prevInputSearchValue]);

    const playersData = collectionPlayers.get(inputValue);
    const teamsData = collectionTeams.get(inputValue);

    const playerSearchResults = sortBy([...(playersData || [])], 'lastNameEn');
    const teamSearchResults = sortBy([...(teamsData || [])], 'nameEn');

    return {
        players: playerSearchResults,
        teams: teamSearchResults,
        processing
    };
};
