import { AppInsights } from 'applicationinsights-js';
export { default as trackedEvents } from './tracked-events';
/**
 * Default web analytics state
 */

const webAnalytics = {
    enabled: false
};
/**
 * @return {Object} AppInsights config, with specified <code>instrumentationKey</code>
 */

const createAppInsightConfig = instrumentationKey => ({
    instrumentationKey,
    sessionRenewalMs: 90 * 60 * 1000,
    disableAjaxTracking: true,
    disableExceptionTracking: true
});
/**
 * initialize AppInsights and specify user context
 */

export function setupWebAnalytics(instrumentationKey, userAccount) {
    AppInsights.setAuthenticatedUserContext(userAccount.username, userAccount.id.toString(), false);
    AppInsights.downloadAndSetup(createAppInsightConfig(instrumentationKey));
    webAnalytics.enabled = true;
}
/**
 * @return {Boolean} <code>true</code> if AppInsights initialized otherwise <code>false</code>
 */

export function isWebAnalyticsEnabled() {
    return webAnalytics.enabled;
}
/**
 * track redirection on page with specified url
 */

export function trackPageView(pageUrl, pageName) {
    if (!webAnalytics.enabled) return;
    AppInsights.trackPageView(pageName || extractPageNameFromUrl(pageUrl), pageUrl);
}
/**
 * track custom events
 */

export function trackEvent(eventName, { properties, measurements } = {}) {
    if (!webAnalytics.enabled) return;
    AppInsights.trackEvent(eventName, properties, measurements);
}
/**
 * extract page name from url
 */

function extractPageNameFromUrl(url) {
    if (url === '/') {
        return 'root';
    } else {
        return url.slice(url.lastIndexOf('/') + 1);
    }
}
