import React from 'react';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components/macro';

const progressBarWrapperAnimation = props => {
    switch (props.state) {
        case 'entering':
            return css`
                opacity: 0.01;
            `;
        case 'entered':
            return css`
                opacity: 1;

                transition: opacity 400ms ease-out;
            `;
        case 'exiting':
            return css`
                opacity: 0.01;

                transition: opacity 800ms ease-out;
            `;
        default:
    }
};

const progressBarValueAnimation = props => {
    switch (props.state) {
        case 'entering':
            return css`
                width: 0;
            `;
        case 'entered':
            return css`
                width: ${props => props.progress}%;

                transition: width 1.5s ease-out;
            `;
        case 'exiting':
            return css`
                width: 100%;

                transition: width 200ms ease-out;
            `;
        default:
    }
};

const ProgressBarWrapper = styled.div`
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 1100;

    display: inline-block;

    width: 100%;
    height: 3px;

    ${progressBarWrapperAnimation}
`;

const ProgressValue = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    display: inline-block;

    height: 3px;

    background-color: var(--uiPrimaryColor);

    ${progressBarValueAnimation}
`;

const timeouts = { enter: 0, exit: 800 };

const AnimatedProgress = ({ progress, completed }) => {
    return (
        <Transition in={!completed} timeout={timeouts} appear unmountOnExit>
            {state => (
                <ProgressBarWrapper state={state}>
                    <ProgressValue state={state} progress={progress} />
                </ProgressBarWrapper>
            )}
        </Transition>
    );
};

export default AnimatedProgress;
