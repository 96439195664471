import { call, put, cancelled, race, take, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { apiBuilder } from 'utils/api/common-requests/common-requests';
import isNil from 'lodash/isNil';
import * as types from './redux-types';

const convertResponse = (response, schema) => {
    if (isNil(schema)) return { data: response.data };
    const { entities, result } = normalize(response.data, schema);
    return { entities, data: result };
};

/**
 * handle single request
 */
function* handleRequest(action) {
    const { queryId, payload } = action;
    try {
        const { url, method, data, schema, params, onUploadProgress } = payload;
        const request = getApiOrFakeRequest(payload);
        const apiResponse = yield call(request, { url, method, data, params, onUploadProgress });
        const { responseWith } = payload;
        const response = responseWith || apiResponse;
        yield put({ type: types.SUCCESS, ...convertResponse(response, schema), queryId });
    } catch (error) {
        yield put({ type: types.FAILURE, error, queryId });
    } finally {
        if (yield cancelled()) {
            yield put({ type: types.CANCELLED, queryId });
        }
    }
}

const getApiOrFakeRequest = ({ fakeResponse, thrownException = false }) => {
    if (isNil(fakeResponse)) return apiBuilder().request;

    const fakeRequestDuration = 500;

    return () =>
        new Promise((resolve, reject) => {
            const resolver = thrownException ? reject : resolve;
            setTimeout(() => resolver(fakeResponse), fakeRequestDuration);
        });
};

/**
 * graph executor cancellation watcher
 */
function* requestCancellationWatcher(action) {
    yield race({
        task: call(handleRequest, action),
        cancel: take(nextAction => nextAction.type === types.CANCEL && nextAction.queryId === action.queryId)
    });
}

/**
 * root watcher that handle query requests
 */
export default function* queryExecutorWatcher() {
    yield takeEvery(types.REQUEST, requestCancellationWatcher);
}
