import { LogoutIcon } from 'components/svg-icons/logout';
import React from 'react';
import styled from 'styled-components/macro';

const IconContainer = styled.div`
    height: 20px;
    margin-right: 10px;

    cursor: pointer;

    &:hover {
        color: rgb(149, 196, 251);
    }
`;

export const BackToAdminLink = props => (
    <IconContainer onClick={props.onClick}>
        <LogoutIcon />
    </IconContainer>
);
