import { useCallback, useEffect, useRef, useState } from 'react';

import { HIDE_DELAY, MODE } from '../constants';

export const useSearchInputField = () => {
    const [inputValue, setInputValue] = useState('');
    const [activeMode, setActiveMode] = useState(MODE.PLAYERS);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const isPlayers = activeMode === MODE.PLAYERS;
    const isTeams = activeMode === MODE.TEAMS;

    const hideTimeoutRef = useRef();

    const handleReset = useCallback(() => {
        setInputValue('');
        setTooltipVisible(false);
    }, []);

    const handleMouseEnter = useCallback(() => {
        if (hideTimeoutRef.current) {
            clearTimeout(hideTimeoutRef.current);
        }
    }, []);

    const handleMouseLeave = useCallback(() => {
        hideTimeoutRef.current = setTimeout(() => {
            setTooltipVisible(false);
        }, HIDE_DELAY);
    }, []);

    const handleInputChange = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();

        setInputValue(e.target.value);
    }, []);

    const handleInputClick = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();

        setTooltipVisible(true);
    }, []);

    const handeVisible = useCallback(val => {
        setTooltipVisible(val);
    }, []);

    const handlePlayers = useCallback(() => {
        setActiveMode(MODE.PLAYERS);
    }, []);

    const handleTeams = useCallback(() => {
        setActiveMode(MODE.TEAMS);
    }, []);

    //Unmount
    useEffect(
        () => () => {
            if (hideTimeoutRef.current) {
                clearTimeout(hideTimeoutRef.current);
            }
        },
        []
    );

    return {
        inputValue,
        isPlayers,
        isTeams,
        tooltipVisible,
        handleReset,
        handleMouseEnter,
        handleMouseLeave,
        handleInputChange,
        handleInputClick,
        handeVisible,
        handlePlayers,
        handleTeams
    };
};
