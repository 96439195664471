import { createMediaTimeWrapper } from '../../episode-utils';
import {
    CARRIES_IN,
    DELTA_TIME_AFTER_ENTRIES_DS,
    DELTA_TIME_AFTER_FACE_OFF_AND_POSSESSION_DS,
    DELTA_TIME_AFTER_FACE_OFF_DS,
    DELTA_TIME_AFTER_RECOVERIES_DS,
    DELTA_TIME_AFTER_TAKEAWAY_DS,
    OFFENSIVE,
    PASS_IN
} from '../constants/episodes-selectors-constants';
import groupBy from 'lodash/groupBy';

export const getTimeForMediaTimeWrapperType = eventProps => {
    const { time, relationalZoneType } = eventProps || {};

    if (time === undefined) {
        throw new Error('Please, check time entity of props.');
    }

    return {
        hasPadding: true,
        leftPadding: 5,
        rightPadding: 3,
        startTime: time,
        endTime: time,
        time,
        relationalZoneType
    };
};

export const getTypeToMediaTimeWrapper = typeToSelector =>
    Object.keys(typeToSelector)
        .map(key => ({ key, wrapper: createMediaTimeWrapper(getTimeForMediaTimeWrapperType) }))
        .reduce((a, d) => {
            a[d.key] = d.wrapper;
            return a;
        }, {});

// Filter for controlled in entries
export function extractedEntriesWithControlledIn(entries) {
    if (!entries.data) {
        return { data: [] };
    }
    return { data: entries.data.filter(e => e.type === CARRIES_IN || e.type === PASS_IN) };
}

export function extractedShotsFromOZ(dataShots) {
    if (!dataShots.data) {
        return { data: [] };
    }

    const shotsFromOffensiveZone = groupBy(dataShots.data, 'relationalZoneType')[OFFENSIVE];

    return {
        data: !!shotsFromOffensiveZone && Array.isArray(shotsFromOffensiveZone) ? [...shotsFromOffensiveZone] : []
    };
}

export function extractedAfterControlInShots(allShots, controlledEntries) {
    const shotsFromAttackZone = extractedShotsFromOZ(allShots);

    const controlledInEntries = extractedEntriesWithControlledIn(controlledEntries);

    const filteredShotsByDeltaTime = shotsFromAttackZone.data.filter(singleShot => {
        const { period, time } = singleShot;
        const shotAfterEntriesDeltaTimeExist = controlledInEntries.data.find(
            singleEntry =>
                singleEntry.period === period &&
                singleEntry.time <= time &&
                singleEntry.time + DELTA_TIME_AFTER_ENTRIES_DS > time
        );
        return !!shotAfterEntriesDeltaTimeExist;
    });

    return {
        data: !!filteredShotsByDeltaTime ? [...filteredShotsByDeltaTime] : []
    };
}

export function extractedAllShotsAtOffensiveZoneAfterRecoveries(allShots, controlledEntries, recoveriesStats) {
    //shots from OZ
    const shotsFromOz = extractedShotsFromOZ(allShots);
    const shotsAfterControlledShotsIds = extractedAfterControlInShots(allShots, controlledEntries).data.map(
        ({ id }) => id
    );

    const shotsFilteredWithoutControlledIn = shotsFromOz.data.filter(
        ({ id }) => !shotsAfterControlledShotsIds.find(el => el === id)
    );

    const recoveriesAsArray =
        !!recoveriesStats && recoveriesStats.data && Array.isArray(recoveriesStats.data) ? recoveriesStats.data : [];

    const shotsAfterRecoveryAtOffensiveZone = shotsFilteredWithoutControlledIn.filter(singleShot => {
        const { period, time } = singleShot;
        return !!recoveriesAsArray.find(singleRecovery => {
            return (
                singleRecovery.period === period &&
                singleRecovery.time <= time &&
                singleRecovery.time + DELTA_TIME_AFTER_RECOVERIES_DS > time
            );
        });
    });

    return {
        data: !!shotsAfterRecoveryAtOffensiveZone ? shotsAfterRecoveryAtOffensiveZone : []
    };
}

export function extractedAllShotsAtOzAfterTakeAway(
    allShots,
    controlledEntries,
    recoveriesStats,
    takeAwayFromEvents,
    powerPlays
) {
    const shotsFromAttackZone = extractedShotsFromOZ(allShots).data;
    const shotsAfterControlledInIds = extractedAfterControlInShots(allShots, controlledEntries).data.map(
        ({ id }) => id
    );
    const shotsAfterRecoveriesIds = extractedAllShotsAtOffensiveZoneAfterRecoveries(
        allShots,
        controlledEntries,
        recoveriesStats
    ).data.map(({ id }) => id);

    const shotsForTakeAwayFiltration = shotsFromAttackZone.filter(
        ({ id }) => !shotsAfterControlledInIds.includes(id) && !shotsAfterRecoveriesIds.includes(id)
    );

    const shotsFilteredByTakeaway = shotsForTakeAwayFiltration.filter(singleShot => {
        const { period, time } = singleShot;

        const shotAfterEntriesDeltaTimeExist = takeAwayFromEvents.data.find(
            singleTakeaway =>
                singleTakeaway.period === period &&
                singleTakeaway.time <= time &&
                singleTakeaway.time + DELTA_TIME_AFTER_TAKEAWAY_DS > time &&
                singleTakeaway.eventType !== 'FACE_OFF'
        );
        return !!shotAfterEntriesDeltaTimeExist;
    });

    //filtration by team id and startTime, endTime of penalties
    const shotsFilteredByTakeawayAndPp = shotsFilteredByTakeaway.filter(singleShot => {
        const { period, time, teamId } = singleShot;
        const shotAfterTakeAwayOnPp = powerPlays.data.find(
            singlePp =>
                singlePp.period === period &&
                singlePp.startTime <= time &&
                singlePp.endTime > time &&
                singlePp.teamId === teamId
        );
        return !!!shotAfterTakeAwayOnPp;
    });

    return {
        data: !!shotsFilteredByTakeawayAndPp ? shotsFilteredByTakeawayAndPp : []
    };
}

const keyCombinePerPossession = (...args) => args.join('-');
export const combinePossessions = events =>
    Array.from(
        events.reduce((m, e) => {
            if (e.playerPossessionIndex) {
                const id = keyCombinePerPossession(e.playerPossessionIndex, e.gameId);
                if (m.has(id)) {
                    m.get(id).add(e);
                } else {
                    m.set(id, new Set([e]));
                }
            }
            return m;
        }, new Map()),
        e => e[1]
    ).map((group, i) => {
        const iterator = group.values();
        let {
            period,
            playerId,
            gameId,
            time,
            duration,
            gamePlayerId,
            teamId,
            relationalZoneType
        } = iterator.next().value;

        for (const event of iterator) {
            time = Math.min(event.time, time);
            duration = Math.max(event.duration + event.time, time + duration) - time;
        }
        return {
            id: i + 1,
            time,
            duration,
            period,
            gamePlayerId,
            playerId,
            gameId,
            teamId,
            relationalZoneType
        };
    });

export function extractedFaceOffShots(
    allShots,
    controlledEntries,
    recoveriesStats,
    takeAwayFromEvents,
    powerPlays,
    faceOffs,
    possessions
) {
    const allShotsFromOz = extractedShotsFromOZ(allShots).data;
    const shotsAfterControlledInAsArray = extractedAfterControlInShots(allShots, controlledEntries).data.map(
        ({ id }) => id
    );
    const shotsAfterRecoveriesAsArray = extractedAllShotsAtOffensiveZoneAfterRecoveries(
        allShots,
        controlledEntries,
        recoveriesStats
    ).data.map(({ id }) => id);
    const shotsAfterTakeAwayAsArray = extractedAllShotsAtOzAfterTakeAway(
        allShots,
        controlledEntries,
        recoveriesStats,
        takeAwayFromEvents,
        powerPlays
    ).data.map(({ id }) => id);

    const shotsWithOtherTypesFiltration = allShotsFromOz.filter(
        ({ id }) =>
            !shotsAfterControlledInAsArray.includes(id) &&
            !shotsAfterRecoveriesAsArray.includes(id) &&
            !shotsAfterTakeAwayAsArray.includes(id)
    );

    //add face off check - shots after face off time + delta time
    const shotsAfterDeltaTimeFaceOff = shotsWithOtherTypesFiltration.filter(singleShot => {
        const { period, time } = singleShot;
        return !!faceOffs.data.find(singleFaceOff => {
            return (
                singleFaceOff.period === period &&
                singleFaceOff.time <= time &&
                singleFaceOff.time + DELTA_TIME_AFTER_FACE_OFF_DS > time
            );
        });
    });

    const isEndPossession = (el, ind, arr) => {
        if (!arr[ind + 1]) {
            return true;
        }
        return !!arr[ind + 1] && el.teamId !== arr[ind + 1].teamId;
    };

    const findEndOfPossessionTime = (singlePossession, wholeCollection) => {
        const finalPossession = wholeCollection.find(el => {
            return (
                el.id > singlePossession.id &&
                el.teamId === singlePossession.teamId &&
                el.period === singlePossession.period &&
                el.time > singlePossession.time &&
                el.endOfPossession
            );
        });
        if (!!finalPossession) {
            return finalPossession.time;
        }
        return singlePossession.time;
    };

    //add shots if team won face off and on time of possession this team after that
    //find gamePlayerId at face off and compare with time and possessions intervals

    const allPossessions = possessions.data.map((el, ind, arr) => ({
        ...el,
        endOfPossession: isEndPossession(el, ind, arr)
    }));

    const possessionsAfterFaceOffWon = allPossessions
        .filter(singlePossession => {
            const { time, gamePlayerId, period } = singlePossession;
            return !!faceOffs.data.find(singleFaceOff => {
                return (
                    singleFaceOff.period === period &&
                    singleFaceOff.time <= time &&
                    singleFaceOff.time + DELTA_TIME_AFTER_FACE_OFF_AND_POSSESSION_DS > time &&
                    singleFaceOff.gamePlayerId === gamePlayerId
                );
            });
        })
        .map(singlePossession => ({
            ...singlePossession,
            endOfPossessionTime: findEndOfPossessionTime(singlePossession, allPossessions)
        }));

    const shotsAfterDeltaTimeFaceOffWonAnPossession = shotsWithOtherTypesFiltration.filter(singleShot => {
        const { period, time } = singleShot;
        return !!possessionsAfterFaceOffWon.find(singlePossession => {
            return (
                singlePossession.period === period &&
                singlePossession.time <= time &&
                singlePossession.endOfPossessionTime > time
            );
        });
    });

    for (const singleShot of shotsAfterDeltaTimeFaceOffWonAnPossession) {
        const { id } = singleShot;
        if (!shotsAfterDeltaTimeFaceOff.find(el => el.id === id)) {
            shotsAfterDeltaTimeFaceOff.push(singleShot);
        }
    }

    return {
        data: !!shotsAfterDeltaTimeFaceOff ? shotsAfterDeltaTimeFaceOff : []
    };
}

export const extractedDrawsShots = (
    allShots,
    controlledEntries,
    recoveriesStats,
    takeAwayFromEvents,
    powerPlays,
    faceOffs,
    possessions
) => {
    const allShotsFromOz = extractedShotsFromOZ(allShots).data;
    const shotsAfterControlledInAsArray = extractedAfterControlInShots(allShots, controlledEntries).data.map(
        ({ id }) => id
    );
    const shotsAfterRecoveriesAsArray = extractedAllShotsAtOffensiveZoneAfterRecoveries(
        allShots,
        controlledEntries,
        recoveriesStats
    ).data.map(({ id }) => id);
    const shotsAfterTakeAwayAsArray = extractedAllShotsAtOzAfterTakeAway(
        allShots,
        controlledEntries,
        recoveriesStats,
        takeAwayFromEvents,
        powerPlays
    ).data.map(({ id }) => id);

    const shotsAfterFaceOff = extractedFaceOffShots(
        allShots,
        controlledEntries,
        recoveriesStats,
        takeAwayFromEvents,
        powerPlays,
        faceOffs,
        possessions
    ).data.map(({ id }) => id);

    const drawShots = allShotsFromOz.filter(
        ({ id }) =>
            !shotsAfterControlledInAsArray.includes(id) &&
            !shotsAfterRecoveriesAsArray.includes(id) &&
            !shotsAfterTakeAwayAsArray.includes(id) &&
            !shotsAfterFaceOff.includes(id)
    );

    return {
        data: !!drawShots ? drawShots : []
    };
};
