import { makePrefixAdder } from '../../redux/utils/type-utils';
import { REPORT } from './products';

const addPrefixTo = makePrefixAdder('PRODUCT_INITIALIZER');
const INITIALIZE_PRODUCT = addPrefixTo('INITIALIZE_PRODUCT');

const initializeProduct = product => ({ type: INITIALIZE_PRODUCT, product });

const buildFilterSearchParams = search => {
    const searchParams = new URLSearchParams(search);
    const state = {};
    if (searchParams.has('gameType')) {
        state.name = searchParams.get('gameType');
    } else {
        state.name = undefined;
    }
    return state;
};

const searchParamsState = buildFilterSearchParams(window.location.search);

const initialState = {
    ...searchParamsState,
    pages: {}
};

const productReducer = (state = initialState, action) => {
    if (action.type === INITIALIZE_PRODUCT) {
        return { name: action.product, pages: state.pages };
    } else {
        const product = state.name;
        return { name: product, pages: state.pages };
    }
};

export const selector = state => state.product.name;
export const isReport = state => selector(state) === REPORT;

export const reducer = productReducer;
export const actionTypes = { INITIALIZE_PRODUCT };
export const actions = { initializeProduct };
