import { makePrefixAdder } from '../utils/type-utils';

//Constants for reducers
const addPrefixTo = makePrefixAdder('PROSPECT_INTEGRATION');
const SET_AUTH_TOKEN = addPrefixTo('SET_AUTH_TOKEN');
const SET_PLAYER_ID = addPrefixTo('SET_PLAYER_ID');
const SET_ROLE = addPrefixTo('SET_ROLE');

//Constants for storage
const PROSPECT_INTEGRATION_TOKEN = 'PROSPECT_INTEGRATION_TOKEN';
const PROSPECT_INTEGRATION_PLAYER_ID = 'PROSPECT_INTEGRATION_PLAYER_ID';
const PROSPECT_INTEGRATION_POSITION = 'PROSPECT_INTEGRATION_POSITION';

//Selectors for reducer
export const tokenProspectIntegrationSelector = state => state.prospectIntegration.token;
export const playerProspectIntegrationSelector = state => +state.prospectIntegration.player;
export const playerIsGoalieProspectIntegrationSelector = state =>
    !!state.prospectIntegration.position && state.prospectIntegration.position.toLowerCase() === 'g';

//Actions for reducer
const setToken = token => {
    sessionStorage.setItem(PROSPECT_INTEGRATION_TOKEN, token);

    return { type: SET_AUTH_TOKEN, token };
};

const setPlayer = player => {
    sessionStorage.setItem(PROSPECT_INTEGRATION_PLAYER_ID, player);

    return { type: SET_PLAYER_ID, player };
};

const setPosition = position => {
    sessionStorage.setItem(PROSPECT_INTEGRATION_POSITION, position);

    return { type: SET_ROLE, position };
};

//Initial state for reducer
const initialState = {
    token: sessionStorage.getItem(PROSPECT_INTEGRATION_TOKEN) || null,
    player: sessionStorage.getItem(PROSPECT_INTEGRATION_PLAYER_ID) || null,
    position: sessionStorage.getItem(PROSPECT_INTEGRATION_POSITION) || null
};

//Reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_AUTH_TOKEN:
            return {
                ...state,
                token: action.token
            };
        case SET_PLAYER_ID:
            return {
                ...state,
                player: action.player
            };
        case SET_ROLE:
            return {
                ...state,
                position: action.position
            };
        default:
            return state;
    }
}

//Actions of reducer for imports
export const actions = {
    setToken,
    setPlayer,
    setPosition
};
