import { makePrefixAdder } from '../utils/type-utils';

const addPrefixTo = makePrefixAdder('REDIRECT_PROPS');
const ADD_REDIRECT_PROPS = addPrefixTo('ADD_REDIRECT_PROPS');

export const addRedirectProps = item => {
    return { type: ADD_REDIRECT_PROPS, payload: item };
};

const defaultState = {};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case ADD_REDIRECT_PROPS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export const selector = state => state.redirectProps[window.location.pathname];
