/**
 * Created by Lytov on 11.14.2018.
 *
 * Стилизованная форма ввода времени
 */
import React, { Component } from 'react';
import InputTime from './input-time.js';
import styled from 'styled-components/macro';
import { getLocaleDateString, transformTime } from '../../utils/helpers/date-utils';

const Wrapper = styled.div`
    display: flex;
    align-items: baseline;
`;

class InputDateTime extends Component {
    getDateValue = () => this.props.value.split('T')[0];
    getTimeValue = () => this.props.value.split('T')[1];

    onDateChange = e => {
        const { onChange } = this.props;
        const date = e.target.value;
        if (!date) {
            onChange(null);
            return;
        }
        const time = this.getTimeValue() || '00:00:00';

        onChange(`${date}T${time}`);
    };

    onTimeChange = newTime => {
        const { onChange } = this.props;
        const date = this.getDateValue() || getLocaleDateString();
        const time = transformTime(newTime, { pattern: 'hh:mm:ss.z' });

        onChange(`${date}T${time}`);
    };

    render() {
        const { hideClear, hideSeconds, className, value } = this.props;
        const dateTime = new Date(value);
        const date = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());

        const timeValue = (dateTime.getTime() - date.getTime()) / 100;

        return (
            <Wrapper>
                <input
                    style={{ flex: '1 1 80px' }}
                    type="date"
                    className={className}
                    value={this.getDateValue()}
                    onChange={this.onDateChange}
                />

                <div
                    style={{
                        flex: '1 1 5px'
                    }}
                />
                <InputTime
                    style={{ flex: '1 1 110px' }}
                    restrictHours
                    hideClear={hideClear}
                    hideSeconds={hideSeconds}
                    className={className}
                    value={timeValue}
                    onChange={this.onTimeChange}
                />
            </Wrapper>
        );
    }
}

export default InputDateTime;
