import React from 'react';
import FormattedMessage from 'components/formatted-message';

const STRENGTHS = [
    { team: 6, opponent: 5 },
    { team: 5, opponent: 6 },
    { team: 5, opponent: 5 },
    { team: 5, opponent: 4 },
    { team: 5, opponent: 3 },
    { team: 4, opponent: 5 },
    { team: 4, opponent: 4 },
    { team: 4, opponent: 3 },
    { team: 3, opponent: 5 },
    { team: 3, opponent: 4 },
    { team: 3, opponent: 3 },
    { teamEmptyNet: true },
    { opponentEmptyNet: true }
];

const isQuantitativeOption = option => option.team || option.opponent;
//const isEmptyNetOption = option => option.teamEmptyNet || option.opponentEmptyNet;

const MIN_NUMBER_OF_PLAYERS = 3;
const MAX_NUMBER_OF_PLAYERS = 6;

const quantitativeOptionToMaskIndex = option => {
    const teamPlayerNumberOffset = MAX_NUMBER_OF_PLAYERS - MIN_NUMBER_OF_PLAYERS + 1;
    const offset =
        (option.team - MIN_NUMBER_OF_PLAYERS) * teamPlayerNumberOffset + option.opponent - MIN_NUMBER_OF_PLAYERS;
    return 1 << offset;
};

export const TEAM_EMPTY_NET_MASK_INDEX = 1 << 16;
export const OPPONENT_EMPTY_NET_MASK_INDEX = 1 << 17;

const emptyNetOptionToMaskIndex = option =>
    option.teamEmptyNet ? TEAM_EMPTY_NET_MASK_INDEX : OPPONENT_EMPTY_NET_MASK_INDEX;

export const toStrengthsMaskIndex = option =>
    isQuantitativeOption(option) ? quantitativeOptionToMaskIndex(option) : emptyNetOptionToMaskIndex(option);

export const fromStrengthsMaskIndex = maskIndex =>
    STRENGTHS.find(strength => !!(maskIndex & toStrengthsMaskIndex(strength)));

export const fromStrengthsMask = strengthsMask =>
    STRENGTHS.filter(strength => !!(strengthsMask & toStrengthsMaskIndex(strength)));

export const toStrengthsMask = options =>
    options.reduce((strengthsMask, strength) => strengthsMask | toStrengthsMaskIndex(strength), 0);

const swapStrength = strength => ({ team: strength.opponent, opponent: strength.team });

export const swapStrengthsMask = strengthsMask => toStrengthsMask(fromStrengthsMask(strengthsMask).map(swapStrength));

export const optionPresentation = strength => {
    if (strength.team) {
        return `${strength.team} vs ${strength.opponent}`;
    }
    if (strength.teamEmptyNet) {
        return <FormattedMessage id="filter.strength.enTeam" />;
    }
    return <FormattedMessage id="filter.strength.enOpponent" />;
};

export const isEvenStrength = strength => strength.team && strength.team === strength.opponent;
export const isPenaltyKill = strength => strength.team < strength.opponent;
export const isPowerPlay = strength => strength.team > strength.opponent;
const isSpecialTeams = strength => strength.team !== strength.opponent;
export const isEmptyNet = strength => strength.teamEmptyNet || strength.opponentEmptyNet;

/*Will be return to filter presentation of options*/
export const optionsPresentation = options => {
    const filteredOptions = options.filter(option => option.team);

    if (options.length === 0) {
        return <FormattedMessage id="filter.strength.mix" values={{ strengths: options.length }} />;
    }

    if (options.length === 1) {
        const [strength] = options;
        return optionPresentation(strength);
    }
    if (options.every(isEvenStrength)) {
        return <FormattedMessage id="filter.strength.ev" />;
    }
    if (options.every(isPenaltyKill)) {
        return <FormattedMessage id="filter.strength.pk" />;
    }
    if (options.every(isPowerPlay)) {
        return <FormattedMessage id="filter.strength.pp" />;
    }
    if (options.every(isSpecialTeams)) {
        return <FormattedMessage id="filter.strength.st" />;
    }

    return <FormattedMessage id="filter.strength.mix" values={{ strengths: filteredOptions.length }} />;

    /*if (options.some(isEmptyNet)) {
        return <FormattedMessage id="filter.strength.mix" values={{ strengths: filteredOptions.length }} />;
    }*/
};
