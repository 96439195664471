import { makeBundle, makePrefixAdder } from '../utils/type-utils';
import requestBuilder from '../utils/request-builder';
import * as endpoints from '../../utils/api/endpoints';
import { roles } from '../../utils/permissions/role-utils';
import { setDefaultMeasureUnits, saveMetric } from '../../components/number-formatter/number-formatter';

const addPrefixTo = makePrefixAdder('user');
const userReadBundle = makeBundle(addPrefixTo('USER_READ'));

const userUserFromMeManual = addPrefixTo('UPDATE_MANUAL_FROM_ME');

const addPrefixToUnits = makePrefixAdder('PORTAL_UNITS');
export const SET_IMPERIAL = addPrefixToUnits('SET_IMPERIAL');
export const SET_METRIC = addPrefixToUnits('SET_METRIC');

export const setImperialUnits = () => {
    return {
        type: SET_IMPERIAL
    };
};
export const setMetricUnits = () => {
    return {
        type: SET_METRIC
    };
};

export function readCurrentUser(errorHandler) {
    return requestBuilder(userReadBundle)
        .handleError(errorHandler)
        .get(endpoints.userProfile);
}

const moduleUser = (state = null, action) => {
    switch (action.type) {
        case userReadBundle.REQUEST:
            return { isProcessing: true, isFailed: false };
        case userReadBundle.SUCCESS:
            return { ...state, ...action.response.data, isProcessing: false };

        case userUserFromMeManual:
            return { ...state, ...action.response.data, isProcessing: false };
        case userReadBundle.FAILURE:
            return { ...state, isFailed: true, errorMessage: action.response.data.message };

        default:
            return state;
    }
};

export const selector = state => state.user;
export const shiftsOnlyPlayerIdSelector = state => {
    return state.user && state.user.profile && state.user.profile.playerId;
};

export const userTeamIdSelector = state => state.user && state.user.profile && state.user.profile.teamId;

export const favoritesTeamIdsSelector = state => state.user && state.user.favoriteTeamIds;

export const portalUnitsSelector = state => state.portalUnits;

export const favoritePlayerIdSelector = state => state?.user?.profile?.favoritePlayerIds;

export const userHaveNFTPermissionsSelector = state => {
    if (!state.user || !state.user.account) {
        return false;
    }

    const { authorities } = state.user.account;

    return !!(
        authorities.includes(roles.NFT_DESIGNER) ||
        authorities.includes(roles.NFT_MODERATOR) ||
        authorities.includes(roles.NFT_VERIFIER) ||
        authorities.includes(roles.NFT_MANAGER) ||
        authorities.includes(roles.ADMIN)
    );
};

export const nftRoleSelector = state => {
    if (!state.user || !state.user.account) {
        return [];
    }

    const { authorities } = state.user.account;

    return authorities.filter(
        el =>
            el === roles.ADMIN ||
            el === roles.NFT_MANAGER ||
            el === roles.NFT_VERIFIER ||
            el === roles.NFT_MODERATOR ||
            el === roles.NFT_DESIGNER
    );
};

export default moduleUser;

export function unitsReducer(state, action) {
    state = state || {
        type: setDefaultMeasureUnits()
    };

    switch (action.type) {
        case SET_IMPERIAL:
            saveMetric('IMPERIAL');
            return {
                type: 'IMPERIAL'
            };

        case SET_METRIC:
            saveMetric('METRIC');
            return {
                type: 'METRIC'
            };

        default:
            return state;
    }
}
