import React from 'react';
import styled from 'styled-components/macro';

const Bars = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;

    border-radius: ${props => props.height}px;

    background-color: ${props => (props.hamburgerIsActive ? 'transparent' : props.color)};
    transform: translate(0, ${props => props.height + props.gap}px);

    transition: background-color ${props => props.animation}ms;

    &:before,
    &:after {
        display: block;

        border-bottom: ${props => props.height}px solid ${props => props.color};
        border-radius: ${props => props.height}px;

        transform: translate(0, ${props => props.height / 2}px);

        transition: transform ${props => props.animation}ms;
        content: '';
    }

    &:before {
        transform: ${props =>
            props.hamburgerIsActive ? `rotate(-45deg)` : `translate(0, -${props.gap + props.height}px)`};
    }

    &:after {
        transform: ${props =>
            props.hamburgerIsActive
                ? `translate(0, -${props.height}px) rotate(45deg)`
                : `translate(0, ${props.gap}px)`};
    }
`;

const Container = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;

    background-color: transparent;
    cursor: ${props => props.cursor || 'pointer'};
`;

const Hamburger = ({
    animation = 250,
    height = 3,
    width = 28,
    gap = 5,
    color = 'black',
    hamburgerIsActive,
    ...rest
}) => (
    <Container width={width} height={3 * height + 2 * gap} {...rest}>
        <Bars
            color={color}
            animation={animation}
            height={height}
            width={width}
            gap={gap}
            hamburgerIsActive={hamburgerIsActive}
        />
    </Container>
);

export default Hamburger;
