import { createSelector } from 'reselect';
import sortedIndexBy from 'lodash/sortedIndexBy';

const convertTimeFromEventTime = (eventTime, period, periods, gameTimecodes) => {
    const periodTimecodes = [...(gameTimecodes ?? [])].filter(t => t.period === period);

    if (periodTimecodes.length !== 0) {
        const sortedTimecodes = periodTimecodes.sort((eventA, eventB) => {
            if (eventA.cleanTime > eventB.cleanTime) {
                return 1;
            }
            if (eventA.cleanTime < eventB.cleanTime) {
                return -1;
            }
            return 0;
        });

        const foundIndex = sortedIndexBy(sortedTimecodes, { cleanTime: eventTime }, 'cleanTime');

        const index =
            sortedTimecodes[foundIndex] && sortedTimecodes[foundIndex].cleanTime === eventTime
                ? foundIndex
                : foundIndex - 1 > 0
                ? foundIndex - 1
                : 0;

        const { cleanTime, videoPlayerTime } = sortedTimecodes[index];
        return (videoPlayerTime + eventTime - cleanTime) / 10;
    }

    if (periods && !Object.values(periods).find(periodDuration => !periodDuration)) {
        const time = eventTime;
        const previousPeriodsDuration = Object.entries(periods)
            .filter(([index, duration]) => index < period)
            .reduce((totalDuration, [index, duration]) => totalDuration + duration, 0);
        return Math.floor((+time + previousPeriodsDuration) / 10);
    }

    /*eslint-disable*/
    console.error('Time from period duration calculation error.');
    /*eslint-enable*/

    return Math.floor(eventTime / 10);
};

export const mediaTime = (
    time,
    startTime,
    endTime,
    period,
    periods,
    gameTimecodes,
    hasPadding,
    leftPadding,
    rightPadding
) => ({
    startTime: convertTimeFromEventTime(startTime, period, periods, gameTimecodes),
    endTime: convertTimeFromEventTime(endTime, period, periods, gameTimecodes),
    time: convertTimeFromEventTime(time, period, periods, gameTimecodes),
    hasPadding,
    leftPadding,
    rightPadding
});

export const mapperErrorMessage = type => `typeId ${type} is not supported by entries mapper!`;

export const processingState = { processing: true };

export const getEpisodeId = (e, type) => `${type}-${e.id}`;

export const createMediaTimeWrapper = getTime =>
    createSelector(
        group => group,
        (group, { periods }) => periods,
        (group, periods, typeId) => typeId,
        (group, { videoTimecodes }) => videoTimecodes,
        (group, periods, typeId, videoTimecodes) => {
            if (group.processing || periods.processing || videoTimecodes.processing) return processingState;

            return {
                data: group.data.map(episode => {
                    const periodsLength = periods.data[episode.gameId];
                    const gameTimecodes = videoTimecodes.data.filter(({ gameId }) => gameId === episode.gameId);
                    const realTime = getTime(episode, typeId);
                    return {
                        ...episode,
                        realTime: mediaTime(
                            realTime.time,
                            realTime.startTime,
                            realTime.endTime,
                            episode.period,
                            periodsLength,
                            gameTimecodes,
                            realTime.hasPadding,
                            realTime.leftPadding,
                            realTime.rightPadding
                        )
                    };
                })
            };
        }
    );

export const createEpisodeIdWrapper = (type, selector) =>
    createSelector(
        selector,
        episodes => {
            if (episodes.processing || !episodes || !Array.isArray(episodes.data)) {
                return processingState;
            }
            return {
                data: episodes.data.map(e => ({ ...e, episodeId: getEpisodeId(e, type) }))
            };
        }
    );

export const typeIdToFormattedMessageId = typeId => `icePlayer.episodes.${typeId}`;

export const groupContainsEpisodeId = ({ typeId }, id) => id.includes(typeId);
