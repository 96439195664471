import requestBuilder from '../../redux/utils/request-builder';
import * as endpoints from '../../utils/api/endpoints';
import { seasonSchema } from '../../utils/schemas';
import { makeBundle, makePrefixAdder } from '../../redux/utils/type-utils';
import { types } from './module';

const addPrefixTo = makePrefixAdder('statsFilterSeason');
const initializeSeasonsBundle = makeBundle(addPrefixTo('SEASONS_INIT'));
const SEASON_UPDATE = addPrefixTo('SEASON_UPDATE');
const TEAM_UPDATE = addPrefixTo('TEAM_UPDATE');

function initializeSeasons() {
    return requestBuilder(initializeSeasonsBundle)
        .addSchema([seasonSchema])
        .get(endpoints.clientSeasons);
}

function initializeSeasonsIncludeScout() {
    return requestBuilder(initializeSeasonsBundle)
        .addSchema([seasonSchema])
        .get(`${endpoints.clientSeasons}/?includeScout=true`);
}

function initializeAllSeasons() {
    return requestBuilder(initializeSeasonsBundle)
        .addSchema([seasonSchema])
        .get(endpoints.seasons);
}

function updateSeasons(seasons) {
    return { type: SEASON_UPDATE, payload: seasons };
}

function updateTeams(teams) {
    return { type: TEAM_UPDATE, payload: teams };
}

export const actions = {
    initializeSeasons,
    initializeAllSeasons,
    initializeSeasonsIncludeScout,
    updateSeasons,
    updateTeams
};

const initialState = {
    initialized: false,
    availableSeasonIds: [],
    seasonIds: null
};

const seasonModule = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET:
            return initialState;
        case initializeSeasonsBundle.REQUEST:
            return { ...state, initialized: true, processing: true };
        case initializeSeasonsBundle.SUCCESS:
            const data = action.response.result;
            return {
                ...state,
                processing: false,
                availableSeasonIds: data
            };
        case types.FILTER_UPDATE:
            if (action.payload && action.payload.seasonIds) {
                return { ...state, seasonIds: action.payload.seasonIds, teamIds: action.payload.teamIds };
            } else {
                return state;
            }
        case initializeSeasonsBundle.FAILURE:
        case initializeSeasonsBundle.CANCELLED:
            return { ...state, initialized: false };
        case types.__FORCE_SETTING_GAME_IDS__:
            return { ...state, initialized: true };
        case SEASON_UPDATE:
            return { ...state, seasonIds: action.payload.map(season => season.id) };
        case TEAM_UPDATE:
            return { ...state, teamIds: action.payload.map(team => team.id) };
        default:
    }
    return state;
};
export default seasonModule;
