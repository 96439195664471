import React, { lazy, Suspense } from 'react';
import Icon from 'components/icon';

const fallback = <Icon type="spinner" pulse />;
const PostGameReportsPage = lazy(() => import('./post-game-report/post-game-report-page'));

export const AsyncPostGameReportsPage = () => {
    return (
        <Suspense fallback={fallback}>
            <PostGameReportsPage />
        </Suspense>
    );
};
