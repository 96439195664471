/* eslint-disable eqeqeq */
import { createSelector } from 'reselect';
import createStatsSelector, { markNonStat, processingStat } from 'selectors/stats/private/create-stats-selector';
import { teamIdsSelector } from 'selectors/teams/teams-selector';
import { selectedGamesSelector } from 'containers/widgets-stats/selectors/active-game';
import { shotsSelector } from 'containers/widgets-stats/selectors/shots.js';
import { getUserProfileTeamId } from 'selectors/user/user-profiles-selector';
import { isSingleTeamView } from 'selectors/user/user-features-selector';
import { getGameIds } from 'selectors/filters/common-filter-selectors';

function getTime({ period, time }, periods) {
    let realTime = time;
    for (const i in periods) {
        if (i < period) {
            realTime += periods[i];
        } else {
            return realTime;
        }
    }
    return realTime;
}

const identicalTruth = () => true;

const filterSelector = (state, props) => props && props.filter;

export const filterShotsSelector = createSelector(
    (props, state) => state.shotMaxTime,
    props => props.data,
    (shotMaxTime, data) => {
        if (shotMaxTime === null || !data.periods) return identicalTruth;
        return s => getTime(s, data.periods[s.gameId]) <= shotMaxTime;
    }
);

export const summaryActiveTeamSelector = createSelector(
    //change logic in neutral account mode
    isSingleTeamView,
    getUserProfileTeamId,
    teamIdsSelector,
    filterSelector,
    (singleTeamView, profileTeamId, teamIds, filter) => {
        return filter && filter.teamIds ? filter.teamIds[0] : singleTeamView ? profileTeamId : teamIds[0];
    }
);

const summarySelector = createStatsSelector(
    state => state.widgetsStats.periods || processingStat,
    markNonStat(getGameIds),
    (periods, gameIds) => {
        return {
            periods,
            multipleGamesIsSelected: gameIds && gameIds.length > 1,
            gameIds
        };
    }
);

const getTeamGoals = (shots, teamId, gameId) => shots.filter(s => s.gameId === gameId && s.teamId === teamId && s.goal);

const evPredicate = d => d.homePlayersNumber === d.awayPlayersNumber;

const stPredicate = d => d.homePlayersNumber !== d.awayPlayersNumber;

const countXg = (shots = [], predicate, teamId, gameId) =>
    shots
        .filter(s => s.gameId === gameId && predicate(s) && s.teamId === teamId)
        .reduce((a, s) => a + (s.xgValue || 0), 0);

const countFilteredGoals = (shots, { homeTeam, awayTeam, id, otScore }, predicate) => {
    const filteredShots = shots.filter(predicate);
    const homeScore = getTeamGoals(filteredShots, homeTeam.id, id).length;
    const awayScore = getTeamGoals(filteredShots, awayTeam.id, id).length;
    return {
        homeScore: homeScore,
        awayScore: awayScore
    };
};

const countFilteredScore = (shots, game, predicate) => {
    const { homeScore, awayScore } = countFilteredGoals(shots, game, predicate);
    return `${homeScore}-${awayScore}`;
};

export const gamesDataSelector = createSelector(
    selectedGamesSelector,
    shotsSelector,
    (games, shots) => {
        if (shots.processing) return processingStat;
        const multipleGames = games.length > 1;
        return {
            data: games.map(game => {
                let homeScore, awayScore;
                let firstPeriodScore, secondPeriodScore, thirdPeriodScore, otScore;

                if (multipleGames) {
                    ({ homeScore, awayScore } = countFilteredGoals(shots.data, game, () => true));
                    firstPeriodScore = countFilteredScore(shots.data, game, s => s.period === 1);
                    secondPeriodScore = countFilteredScore(shots.data, game, s => s.period === 2);
                    thirdPeriodScore = countFilteredScore(shots.data, game, s => s.period === 3);
                    otScore = game.otScore ? countFilteredScore(shots.data, game, s => s.period > 3) : null;
                } else {
                    ({ homeScore, awayScore, firstPeriodScore, secondPeriodScore, thirdPeriodScore, otScore } = game);
                }

                return {
                    ...game,
                    homeScore,
                    awayScore,
                    firstPeriodScore,
                    secondPeriodScore,
                    thirdPeriodScore,
                    otScore,
                    stXgHome: countXg(shots.data, stPredicate, game.homeTeam.id, game.id),
                    stXgAway: countXg(shots.data, stPredicate, game.awayTeam.id, game.id),
                    evXgHome: countXg(shots.data, evPredicate, game.homeTeam.id, game.id),
                    evXgAway: countXg(shots.data, evPredicate, game.awayTeam.id, game.id)
                };
            })
        };
    }
);

export default summarySelector;
