/**
 * system admin
 */
export const ADMIN = 'ADMIN';
/**
 * client's role to access portal
 */
export const CLIENT = 'CLIENT';
/**
 * Allows to work with collector
 * @deprecated Obsolete role
 */
export const MANAGER = 'MANAGER';
/**
 * Allows to work with collector
 */
export const MODERATOR = 'MODERATOR';
/**
 * Allows to work with collector (for manual worker)
 */
export const TRACKER = 'TRACKER';
/**
 * Admin panel role - allows to create scout games and related entities
 * @deprecated Obsolete role
 */
export const SCOUT_ADMIN = 'SCOUT_ADMIN';
/**
 * Admin role for PRO games, can manage PRO games
 */
export const PRO_ADMIN = 'PRO_ADMIN';
/**
 * Admin role fol LITE games, can manage LITE games
 */
export const LITE_ADMIN = 'LITE_ADMIN';
/**
 * Maintains game storage and appoints supports for the game
 */
export const SUPPORT_ADMIN = 'SUPPORT_ADMIN';
/**
 * Uploads video for the game and prepares it for production
 */
export const SUPPORT = 'SUPPORT';

export const VERIFIER_ADMIN = 'VERIFIER_ADMIN';
/**
 * Verifies and publishes game after processing
 */
export const VERIFIER = 'VERIFIER';
/**
 * Create requests for the games
 */
export const SALES = 'SALES';
/**
 * Allows to access any games without check
 */
export const ANALYST = 'ANALYST';

export const PRODUCTION_ADMIN = 'PRODUCTION_ADMIN';

export const CV_USER = 'CV_USER';

export const NFT_MODERATOR = 'NFT_MODERATOR';
export const NFT_DESIGNER = 'NFT_DESIGNER';
export const NFT_VERIFIER = 'NFT_VERIFIER';
export const NFT_MANAGER = 'NFT_MANAGER';
