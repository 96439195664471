import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
    playlistBackgroundColor,
    playlistBadgesColor,
    playlistFontColor,
    playlistIconColor
} from '../video-player/constants/video-player-palette';

const boxShadow =
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)';
const Svg = styled.svg``;
export const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${props => (props.withOutPadding ? 'none' : '5px')};

    border-radius: 5px;

    cursor: ${props => (props.noPointer ? 'cursor' : 'pointer')};

    &:hover {
        background: ${props => (props.withEffect ? 'rgba(0, 0, 0, 0.1)' : 'none')};
        box-shadow: ${props => (props.withEffect ? `${boxShadow}` : 'none')};
    }
`;

export const ListIcon = ({ isOpen, playlistIsActive, withEffect, specialColor }) => (
    <IconWrapper withEffect={withEffect}>
        {isOpen ? (
            <Svg
                style={{
                    width: '10px',
                    height: '10px',
                    fill: !!specialColor ? specialColor : !!playlistIsActive ? playlistIconColor : playlistFontColor
                }}
            >
                <polygon points="0,10 5,0 10,10" />
            </Svg>
        ) : (
            <Svg
                style={{
                    width: '10px',
                    height: '10px',
                    fill: !!specialColor ? specialColor : !!playlistIsActive ? playlistIconColor : playlistFontColor
                }}
            >
                <polygon points="0,0 10,0 5,10" />
            </Svg>
        )}
    </IconWrapper>
);

export const ListFolderIcon = ({ notEmpty, withEffect, ...rest }) => (
    <IconWrapper withEffect={withEffect}>
        {notEmpty ? (
            <Svg
                width="12px"
                height="12px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...rest}
            >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                <g id="SVGRepo_iconCarrier">
                    <path
                        d="M4 2C3.20435 2 2.44129 2.31607 1.87868 2.87868C1.31607 3.44129 1 4.20435 1 5V19C1 19.7957 1.31607 20.5587 1.87868 21.1213C2.44129 21.6839 3.20435 22 4 22H20C20.7957 22 21.5587 21.6839 22.1213 21.1213C22.6839 20.5587 23 19.7957 23 19V8C23 7.20435 22.6839 6.44129 22.1213 5.87868C21.5587 5.31607 20.7957 5 20 5H11.5352L10.1289 2.8906C9.75799 2.3342 9.13352 2 8.46482 2H4Z"
                        fill="currentColor"
                    />
                </g>
            </Svg>
        ) : (
            <Svg
                width="12px"
                height="12px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...rest}
            >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                <g id="SVGRepo_iconCarrier">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4 4C3.73478 4 3.48043 4.10536 3.29289 4.29289C3.10536 4.48043 3 4.73478 3 5V19C3 19.2652 3.10536 19.5196 3.29289 19.7071C3.48043 19.8946 3.73478 20 4 20H20C20.2652 20 20.5196 19.8946 20.7071 19.7071C20.8946 19.5196 21 19.2652 21 19V8C21 7.73478 20.8946 7.48043 20.7071 7.29289C20.5196 7.10536 20.2652 7 20 7H11.5352C10.8665 7 10.242 6.6658 9.87108 6.1094L8.46482 4H4ZM1.87868 2.87868C2.44129 2.31607 3.20435 2 4 2H8.46482C9.13352 2 9.75799 2.3342 10.1289 2.8906L11.5352 5H20C20.7957 5 21.5587 5.31607 22.1213 5.87868C22.6839 6.44129 23 7.20435 23 8V19C23 19.7957 22.6839 20.5587 22.1213 21.1213C21.5587 21.6839 20.7957 22 20 22H4C3.20435 22 2.44129 21.6839 1.87868 21.1213C1.31607 20.5587 1 19.7957 1 19V5C1 4.20435 1.31607 3.44129 1.87868 2.87868Z"
                        fill="currentColor"
                    />
                </g>
            </Svg>
        )}
    </IconWrapper>
);

export const ShareIcon = ({ onCLick, trackIsActive, isAvailable, fullscreen, withEffect }) => {
    const iconColor = fullscreen
        ? '#fff'
        : trackIsActive && isAvailable
        ? playlistBackgroundColor
        : !isAvailable
        ? '#ccc'
        : playlistIconColor;
    return (
        <IconWrapper onClick={isAvailable ? onCLick : null} title="Share" withEffect={withEffect}>
            <Svg viewBox="0 0 508 508" width="16" height="16">
                <path
                    fill={iconColor}
                    d="M408 178.5c-20.4 0-38.25 7.65-51 20.4L175.95 94.35c2.55-5.1 2.55-12.75 2.55-17.85C178.5 33.15 145.35 0 102 0S25.5 33.15 25.5 76.5 58.65 153 102 153c20.4 0 38.25-7.65 51-20.4l181.05 104.55c-2.55 5.1-2.55 12.75-2.55 17.85 0 5.1 0 12.75 2.55 17.85L153 379.95c-12.75-12.75-30.6-20.4-51-20.4-40.8 0-73.95 33.15-73.95 73.95S61.2 507.45 102 507.45s73.95-33.15 73.95-73.95c0-5.1 0-10.2-2.55-17.85l181.05-107.1c12.75 12.75 30.6 20.4 51 20.4 43.35 0 76.5-33.15 76.5-76.5S451.35 178.5 408 178.5z"
                />
            </Svg>
        </IconWrapper>
    );
};

export const DownloadIcon = ({ style, onClick, trackIsActive, isAvailable, fullscreen, color, withEffect }) => {
    const iconColor = color
        ? color
        : fullscreen
        ? '#fff'
        : trackIsActive && isAvailable
        ? playlistBackgroundColor
        : !isAvailable
        ? '#ccc'
        : playlistIconColor;

    return (
        <IconWrapper style={style} onClick={onClick} title="Download" withEffect={withEffect}>
            <Svg viewBox="0 0 508 508" width="16" height="16">
                <path
                    fill={iconColor}
                    d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
			c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
			c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"
                />

                <path
                    fill={iconColor}
                    d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z"
                />
            </Svg>
        </IconWrapper>
    );
};

export const CopyIcon = ({ onClick, trackIsActive, fullscreen, withEffect }) => {
    const iconColor = fullscreen ? '#fff' : trackIsActive ? playlistBackgroundColor : playlistIconColor;

    return (
        <IconWrapper title="Copy" onClick={onClick} withEffect={withEffect}>
            <Svg viewBox="0 0 12 16" width="16" height="16" fill="none">
                <path
                    d="M1.5 0.5C0.675 0.5 0.00750017 1.175 0.00750017 2L0 14C0 14.825 0.6675 15.5 1.4925 15.5H10.5C11.325 15.5 12 14.825 12 14V5L7.5 0.5H1.5ZM6.75 5.75V1.625L10.875 5.75H6.75Z"
                    fill={iconColor}
                />
            </Svg>
        </IconWrapper>
    );
};

export const EditIcon = ({ onClick, trackIsActive, fullscreen, withEffect }) => {
    const iconColor = fullscreen ? '#fff' : trackIsActive ? playlistBackgroundColor : playlistIconColor;

    return (
        <IconWrapper title="Edit" onClick={onClick} withEffect={withEffect}>
            <Svg width="22" height="20" viewBox="0 0 22 20" fill="none">
                <path
                    d="M3.25424 15H1V1H20V7.13261"
                    stroke={iconColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect x="3" y="3" width="1.93548" height="1.93548" fill={iconColor} />
                <rect x="16" y="3" width="1.93548" height="1.93548" fill={iconColor} />
                <rect x="3" y="7" width="2" height="2" fill={iconColor} />
                <rect x="16" y="7" width="1.93548" height="1.93548" fill={iconColor} />
                <rect x="3" y="11" width="1.93548" height="1.93548" fill={iconColor} />
                <circle cx="9.0646" cy="9.35489" r="1.90323" stroke={iconColor} strokeWidth="2" />
                <circle cx="9.0646" cy="17.0966" r="1.90323" stroke={iconColor} strokeWidth="2" />
                <path
                    d="M11 10.5L15.9209 13.2804M21 15.9883L15.9209 13.2804M15.9209 13.2804L11 15.9883M18.5 11.5L21 10"
                    stroke={iconColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </IconWrapper>
    );
};

export const AddToPlaylistIcon = ({
    handleAddToCustomPlaylistNewElement,
    playlistComponentEpisodeId,
    trackIsActive,
    fullscreen,
    withEffect
}) => {
    const iconColor = fullscreen ? '#fff' : trackIsActive ? playlistBackgroundColor : playlistIconColor;
    return (
        <IconWrapper
            onClick={() => handleAddToCustomPlaylistNewElement(playlistComponentEpisodeId)}
            title="Add"
            withEffect={withEffect}
        >
            <Svg viewBox="0 0 508 508" width="16" height="16">
                <path
                    fill={iconColor}
                    d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341
			c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z
			"
                />

                <path
                    fill={iconColor}
                    d="M355.148,234.386H275.83v-79.318c0-10.946-8.864-19.83-19.83-19.83s-19.83,8.884-19.83,19.83v79.318h-79.318
			c-10.966,0-19.83,8.884-19.83,19.83s8.864,19.83,19.83,19.83h79.318v79.318c0,10.946,8.864,19.83,19.83,19.83
			s19.83-8.884,19.83-19.83v-79.318h79.318c10.966,0,19.83-8.884,19.83-19.83S366.114,234.386,355.148,234.386z"
                />
            </Svg>
        </IconWrapper>
    );
};

export const RenameIcon = ({ fullscreen, trackIsActive, onClick, withEffect }) => {
    const iconColor = fullscreen ? '#fff' : trackIsActive ? playlistBackgroundColor : playlistIconColor;

    return (
        <IconWrapper title="Rename" onClick={onClick} withEffect={withEffect}>
            <Svg viewBox="0 0 600 600" width="16" height="16">
                <g stroke={iconColor} fill="none">
                    <path d="m70.064 422.35 374.27-374.26 107.58 107.58-374.26 374.27-129.56 21.97z" strokeWidth="30" />
                    <path d="m70.569 417.81 110.61 110.61" strokeWidth="25" />
                    <path d="m491.47 108.37-366.69 366.68" strokeWidth="25" />
                    <path d="m54.222 507.26 40.975 39.546" strokeWidth="25" />
                </g>
            </Svg>
        </IconWrapper>
    );
};

export const DeleteIcon = ({ onClick, fullscreen, flashOnFocus, trackIsActive, withEffect }) => {
    const iconColor = fullscreen ? '#fff' : trackIsActive ? playlistBackgroundColor : playlistIconColor;

    const [iconOnFlashColor, setIconOnFlashColor] = useState('#ccc');

    return (
        <IconWrapper title="Delete" onClick={onClick} withEffect={withEffect}>
            <Svg
                style={{
                    height: 16,
                    width: 16
                }}
                viewBox="0 0 512 512"
                onMouseEnter={() => setIconOnFlashColor(iconColor)}
                onMouseLeave={() => setIconOnFlashColor('#ccc')}
            >
                <path
                    d="M436,60h-90V45c0-24.813-20.187-45-45-45h-90c-24.813,0-45,20.187-45,45v15H76c-24.813,0-45,20.187-45,45v30
			c0,8.284,6.716,15,15,15h16.183L88.57,470.945c0.003,0.043,0.007,0.086,0.011,0.129C90.703,494.406,109.97,512,133.396,512
			h245.207c23.427,0,42.693-17.594,44.815-40.926c0.004-0.043,0.008-0.086,0.011-0.129L449.817,150H466c8.284,0,15-6.716,15-15v-30
			C481,80.187,460.813,60,436,60z M196,45c0-8.271,6.729-15,15-15h90c8.271,0,15,6.729,15,15v15H196V45z M393.537,468.408
			c-0.729,7.753-7.142,13.592-14.934,13.592H133.396c-7.792,0-14.204-5.839-14.934-13.592L92.284,150h327.432L393.537,468.408z
			 M451,120h-15H76H61v-15c0-8.271,6.729-15,15-15h105h150h105c8.271,0,15,6.729,15,15V120z"
                    fill={flashOnFocus ? iconOnFlashColor : iconColor}
                />

                <path
                    d="M256,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C271,186.716,264.284,180,256,180z"
                    fill={flashOnFocus ? iconOnFlashColor : iconColor}
                />

                <path
                    d="M346,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C361,186.716,354.284,180,346,180z"
                    fill={flashOnFocus ? iconOnFlashColor : iconColor}
                />

                <path
                    d="M166,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C181,186.716,174.284,180,166,180z"
                    fill={flashOnFocus ? iconOnFlashColor : iconColor}
                />
            </Svg>
        </IconWrapper>
    );
};

export const MoveIcon = ({ onClick, fullscreen, trackIsActive, withEffect }) => {
    const iconColor = fullscreen ? '#fff' : trackIsActive ? playlistBackgroundColor : playlistIconColor;

    return (
        <IconWrapper title={'Move'} onClick={onClick} withEffect={withEffect}>
            <Svg x="0px" y="0px" viewBox="0 0 477.175 477.175" width="16" height="16">
                <path
                    fill={iconColor}
                    d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
		c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                />
            </Svg>
        </IconWrapper>
    );
};

export const MoreIcon = ({ onClick, trackIsActive, fullscreen, withEffect }) => {
    const iconColor = fullscreen ? '#fff' : trackIsActive ? playlistBackgroundColor : playlistIconColor;

    return (
        <IconWrapper title={'More'} onClick={onClick} withEffect={withEffect}>
            <Svg fill={iconColor} x="0px" y="0px" viewBox="0 0 433.633 433.633" width="16" height="16" title="More">
                <path
                    fill={iconColor}
                    d="M369.894,153.078c-35.004,0-63.739,28.735-63.739,63.739s28.735,63.739,63.739,63.739
		s63.739-28.735,63.739-63.739S404.898,153.078,369.894,153.078z"
                />
                <path
                    fill={iconColor}
                    d="M216.816,153.078c-35.004,0-63.739,28.735-63.739,63.739s28.735,63.739,63.739,63.739
		s63.739-28.735,63.739-63.739S251.82,153.078,216.816,153.078z"
                />
                <path
                    fill={iconColor}
                    d="M63.739,153.078C28.735,153.078,0,181.812,0,216.816s28.735,63.739,63.739,63.739
		s63.739-28.735,63.739-63.739S98.743,153.078,63.739,153.078z"
                />
            </Svg>
        </IconWrapper>
    );
};

export const CropIcon = ({ trackIsActive, fullscreen, withEffect, title }) => {
    const iconColor = fullscreen ? '#fff' : trackIsActive ? playlistBackgroundColor : playlistBadgesColor;
    return (
        <IconWrapper title={!!title ? title : 'Episode from crop.'} withEffect={withEffect}>
            <Svg width="2rem" height="80%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 8H6V22H10" stroke={iconColor} />
                <path d="M20 8H24V22H20" stroke={iconColor} />
                <path d="M4 15L6 13V17L4 15Z" fill={iconColor} stroke={iconColor} />
                <path d="M26 15L24 13V17L26 15Z" fill={iconColor} stroke={iconColor} />
                <rect x="11" y="19" width="2" height="2" fill={iconColor} />
                <rect x="14" y="19" width="2" height="2" fill={iconColor} />
                <rect x="17" y="19" width="2" height="2" fill={iconColor} />
            </Svg>
        </IconWrapper>
    );
};

export const DrawIcon = ({ trackIsActive, fullscreen, withEffect, width, height, withOutPadding }) => {
    const iconColor = fullscreen ? '#fff' : trackIsActive ? playlistBackgroundColor : playlistBadgesColor;
    return (
        <IconWrapper title={'Episode with sketch'} withOutPadding={withOutPadding} withEffect={withEffect}>
            <Svg
                width={width || '2em'}
                height={height || '80%'}
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.7561 10.0013C15.4757 9.35552 13.7867 9 12 9C10.2133 9 8.52429 9.35552 7.24385 10.0013C5.7969 10.7309 5 11.7777 5 12.9489V17.0511C5 18.2223 5.7969 19.2691 7.24385 19.9987C8.52429 20.6445 10.2133 21 12 21C13.7867 21 15.4757 20.6445 16.7561 19.9987C18.2031 19.2691 19 18.2223 19 17.0511V12.9489C19 11.7777 18.2031 10.7309 16.7561 10.0013V10.0013ZM16.2812 18.6605C15.1476 19.2321 13.6271 19.5469 12 19.5469C10.3729 19.5469 8.85241 19.2321 7.71882 18.6605C6.79381 18.1941 6.21916 17.5773 6.21916 17.0511V15.2415C6.51172 15.4803 6.85398 15.7002 7.24385 15.8967C8.52429 16.5423 10.2133 16.898 12 16.898C13.7867 16.898 15.4757 16.5423 16.7561 15.8967C17.146 15.7002 17.4883 15.4803 17.7808 15.2415V17.0511C17.7808 17.5773 17.2062 18.1941 16.2812 18.6605V18.6605ZM16.2812 14.5585C15.1476 15.1301 13.6271 15.4449 12 15.4449C10.3729 15.4449 8.85241 15.1301 7.71882 14.5585C6.79381 14.0921 6.21916 13.4753 6.21916 12.9489C6.21916 12.4227 6.79381 11.8059 7.71882 11.3395C8.85241 10.7679 10.3729 10.4531 12 10.4531C13.6271 10.4531 15.1476 10.7679 16.2812 11.3395C17.2062 11.8059 17.7808 12.4227 17.7808 12.9489C17.7808 13.4753 17.2062 14.0921 16.2812 14.5585V14.5585Z"
                    fill={iconColor}
                />
                <path d="M20 13H23V14H20V13Z" fill={iconColor} />
                <path d="M24 13H25V14H24V13Z" fill={iconColor} />
                <path d="M21 15H24V16H21V15Z" fill={iconColor} />
                <path d="M25 15H26V16H25V15Z" fill={iconColor} />
                <path d="M20 17H23V18H20V17Z" fill={iconColor} />
                <path d="M24 17H25V18H24V17Z" fill={iconColor} />
            </Svg>
        </IconWrapper>
    );
};

export const ArrowsToDown = () => {
    const iconColor = '#fff';
    return (
        <IconWrapper>
            <Svg x="0px" y="0px" width="1rem" height="1rem" viewBox="0 0 490.688 490.688">
                <path
                    fill={iconColor}
                    d="M472.328,216.529L245.213,443.665L18.098,216.529c-4.237-4.093-10.99-3.975-15.083,0.262
		c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667
		c4.093-4.237,3.975-10.99-0.262-15.083c-4.134-3.993-10.687-3.993-14.821,0L472.328,216.529z"
                />
                <path
                    fill={iconColor}
                    d="M472.328,24.529L245.213,251.665L18.098,24.529c-4.237-4.093-10.99-3.975-15.083,0.262
		c-3.992,4.134-3.992,10.687,0,14.821l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0L487.432,39.612
		c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262
		L472.328,24.529z"
                />

                <path
                    fill={iconColor}
                    d="M245.213,469.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,231.633c-4.093-4.237-3.975-10.99,0.262-15.083
	c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.237-4.093,10.99-3.975,15.083,0.262
	c3.993,4.134,3.993,10.687,0,14.821L252.744,466.279C250.748,468.28,248.04,469.408,245.213,469.415z"
                />
                <path
                    fill={iconColor}
                    d="M245.213,277.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,39.633c-4.093-4.237-3.975-10.99,0.262-15.083
	c4.134-3.992,10.687-3.992,14.821,0l227.136,227.115L472.328,24.529c4.093-4.237,10.845-4.354,15.083-0.262
	s4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,274.279C250.748,276.28,248.04,277.408,245.213,277.415z"
                />
            </Svg>
        </IconWrapper>
    );
};

export const ArrowsToUp = () => {
    const iconColor = '#fff';
    return (
        <IconWrapper>
            <Svg x="0px" y="0px" width="1rem" height="1rem" viewBox="0 0 490.544 490.544">
                <path
                    d="M18.098,274.151L245.213,47.015l227.115,227.136c4.237,4.093,10.99,3.975,15.083-0.262
		c3.993-4.134,3.993-10.687,0-14.821L252.744,24.401c-4.165-4.164-10.917-4.164-15.083,0L2.994,259.068
		c-4.093,4.237-3.975,10.99,0.262,15.083c4.134,3.992,10.687,3.992,14.82,0H18.098z"
                    fill={iconColor}
                />
                <path
                    d="M252.765,216.38c-4.165-4.164-10.917-4.164-15.083,0L3.016,451.047
		c-4.093,4.237-3.976,10.99,0.262,15.083c4.134,3.993,10.687,3.993,14.821,0l227.115-227.115l227.115,227.136
		c4.237,4.093,10.99,3.976,15.083-0.261c3.993-4.134,3.993-10.688,0-14.821L252.765,216.38z"
                    fill={iconColor}
                />

                <path
                    d="M479.88,277.266c-2.831,0.005-5.548-1.115-7.552-3.115L245.213,47.015L18.098,274.151
	c-4.237,4.093-10.99,3.975-15.083-0.262c-3.992-4.134-3.992-10.687,0-14.82L237.682,24.401c4.165-4.164,10.917-4.164,15.083,0
	l234.667,234.667c4.159,4.172,4.148,10.926-0.024,15.085C485.409,276.146,482.702,277.265,479.88,277.266z"
                    fill={iconColor}
                />
                <path
                    d="M479.88,469.266c-2.831,0.005-5.548-1.115-7.552-3.115L245.213,239.015L18.098,466.151
	c-4.237,4.093-10.99,3.976-15.083-0.262c-3.993-4.134-3.993-10.687,0-14.821l234.667-234.667c4.165-4.164,10.917-4.164,15.083,0
	l234.667,234.667c4.159,4.172,4.148,10.926-0.024,15.085C485.409,468.146,482.702,469.265,479.88,469.266z"
                    fill={iconColor}
                />
            </Svg>
        </IconWrapper>
    );
};

export const MovieIcon = ({ onClick }) => {
    const iconColor = playlistIconColor;
    return (
        <IconWrapper noPointer={true} onClick={onClick}>
            <Svg
                style={{
                    height: 16,
                    width: 16
                }}
                viewBox="0 0 512 512"
            >
                <path
                    d="m0 70.334v371.333h512v-371.333zm482 227.555h-72.334v-83.778h72.334zm0-113.778h-72.334v-83.778h72.334zm-72.333 143.778h72.333v83.778h-72.334v-83.778zm-30 83.778h-247.334v-311.333h247.333v311.333zm-277.333-113.778h-72.334v-83.778h72.334zm0-113.778h-72.334v-83.778h72.334zm-72.334 143.778h72.334v83.778h-72.334z"
                    fill={iconColor}
                />
                <path
                    d="m327.5 268.991v-25.98l-113.137-65.32-22.5 12.99v130.639l22.5 12.99zm-105.637-52.329 68.137 39.338-68.137 39.339z"
                    fill={iconColor}
                />
            </Svg>
        </IconWrapper>
    );
};
