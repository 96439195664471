import { createSelector } from 'reselect';

export const emptyObject = {};
export const emptyList = [];
export const processingStat = {
    processing: true,
    data: emptyObject,
    errors: emptyList
};
export const createFetchedStat = data => ({
    processing: false,
    data,
    errors: emptyList
});
export const createFailedStat = errors => ({
    processing: false,
    data: emptyObject,
    errors
});
const NOT_STAT = Symbol();
export const markNonStat = selector => {
    return {
        _statsSelectorMark: NOT_STAT,
        selector
    };
};

const isStatSelector = selector => {
    return selector._statsSelectorMark !== NOT_STAT;
};

function uniq(list) {
    return [...new Set(list)];
}

const aggregatorWrapper = (aggregator, nonStatArgList) => {
    //todo wrong using flow-types should be any[] instead of Stat[]
    return function(...args) {
        const stats = args.filter((d, i) => !nonStatArgList.includes(i));
        const failedStats = stats.filter(stat => stat && stat.errors && stat.errors.length).map(stat => stat.errors);
        if (failedStats.length) return createFailedStat(uniq(failedStats.reduce((a, d) => [...a, ...d])));
        const processing = stats.some(stat => !stat || stat.processing);
        if (processing) return processingStat;
        return createFetchedStat(aggregator(...args.map((d, i) => (!nonStatArgList.includes(i) ? d.data : d))));
    };
};
/**
 * @deprecated
 */

export const createKeptStatDataSelector = (statsSelector, initialData) => {
    let keptData = initialData;
    return createSelector(
        statsSelector,
        stat => {
            if (stat.data === emptyObject) {
                return { ...stat, data: keptData };
            } else {
                keptData = stat.data;
                return stat;
            }
        }
    );
};
/**
 * @deprecated
 */

export default function createStatsSelector(...args) {
    const selectors = args.slice(0, args.length - 1).map(s => (isStatSelector(s) ? s : s.selector));
    const nonStatArgs = args
        .slice(0, args.length - 1)
        .map((s, i) => [s, i])
        .filter(([s, i]) => !isStatSelector(s))
        .map(([s, i]) => i);
    const aggregator = aggregatorWrapper(args[args.length - 1], nonStatArgs);
    return createSelector(
        ...selectors,
        aggregator
    );
}
