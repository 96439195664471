import { createSelector } from 'reselect';
import * as expressions from '../../utils/helpers/expression-utils';
import { fieldNames } from '../../utils/api/predicate';
import { unboxSingleValueArray } from '../../utils/arrays-utils';
import { OPPONENT_EMPTY_NET_MASK_INDEX, TEAM_EMPTY_NET_MASK_INDEX } from '../../utils/strengths';
import * as emptyNetTypes from 'constants/empty-net-type';
import { SCOUT } from 'containers/product-initializer/products';

export const getStatsFilter = state => state.statsFilter;

export const getGameIds = state => getStatsFilter(state).gameIds;

export const getTeamIds = state => getStatsFilter(state).teamIds;

export const getSeasonIds = state => getStatsFilter(state).seasonIds;

export const getLeagueIds = state => getStatsFilter(state).leagueIds;

export const getPlayerId = state =>
    state.product.name === SCOUT
        ? Number(window.location.pathname.split('/')[2])
        : unboxSingleValueArray(getStatsFilter(state).playerIds);

export const getPlayerPosition = state =>
    state.product.name === SCOUT
        ? window.location.pathname.split('/')[3]
        : unboxSingleValueArray(getStatsFilter(state).positionStatus);

export const getLeagueId = state => unboxSingleValueArray(getStatsFilter(state).leagueIds);

export const periodPredicateSelector = createSelector(
    state => getStatsFilter(state).periods,
    periods =>
        !periods || periods.length === 0
            ? undefined
            : expressions.containedIn(fieldNames.PERIOD, ...periods.map(period => expressions.number(period)))
);

export const getStrengthsMask = state => {
    const { strengths } = getStatsFilter(state);

    return !strengths || strengths.length === 0
        ? undefined
        : strengths.reduce((acc, strength) => {
              if ([TEAM_EMPTY_NET_MASK_INDEX, OPPONENT_EMPTY_NET_MASK_INDEX].includes(strength)) {
                  return acc;
              }

              return acc | strength;
          }, undefined);
};

export const getEmptyNetValue = state => {
    const strengths = getStatsFilter(state).strengths;
    if (!Array.isArray(strengths)) {
        return undefined;
    }
    const teamEmptyNet = strengths.includes(TEAM_EMPTY_NET_MASK_INDEX);
    const opponentEmptyNet = strengths.includes(OPPONENT_EMPTY_NET_MASK_INDEX);
    if (!!teamEmptyNet && !!opponentEmptyNet) {
        return emptyNetTypes.BOTH;
    }
    if (teamEmptyNet) {
        return emptyNetTypes.MY_TEAM;
    }
    if (opponentEmptyNet) {
        return emptyNetTypes.OPPONENT_TEAM;
    }
    return emptyNetTypes.NONE;
};

export const isSingleTeamMode = state => {
    const { additionalFilterProps } = getStatsFilter(state);
    return additionalFilterProps.singleGame || additionalFilterProps.singleTeam;
};

export const getGameIdsAndLeaguesSelector = state => {
    const storedIpp = state.statsFilter?.storedStates?.gameIds?.ipp?.gameIds;

    const gameIds = storedIpp && storedIpp.length > 0 ? storedIpp : getGameIds(state);
    const leagueIds = getLeagueIds(state);
    const { seasons, games } = state.entities;

    const gamesByLeagueIdMap = new Map();

    for (const { id, seasonId } of Object.values(games)) {
        const season = seasons[seasonId];

        if (!season) {
            continue;
        }

        const { leagueId } = season;
        const gameIdsByLeague = gamesByLeagueIdMap.get(leagueId);

        gamesByLeagueIdMap.set(leagueId, !!gameIdsByLeague ? [...gameIdsByLeague, id] : [id]);
    }

    return leagueIds.map(leagueId => {
        const gamesOfLeague = gamesByLeagueIdMap.get(leagueId);
        return {
            leagueId,
            gameIds: gamesByLeagueIdMap && gameIds ? gameIds.filter(gameId => gamesOfLeague.includes(gameId)) : []
        };
    });
};
