export const DmcaContent = () => (
    <>
        <p>
            <strong>
                At&nbsp;TPE, we&nbsp;respect the intellectual property rights of&nbsp;others and are committed
                to&nbsp;complying with U.S. copyright law.
            </strong>
        </p>
        <p>
            <strong>Copyright Infringement Notices: </strong>
            If&nbsp;you believe that a&nbsp;user has uploaded or&nbsp;posted content to&nbsp;TPE&rsquo;s platform that
            infringes your copyright, please let&nbsp;us know by&nbsp;sending a&nbsp;copyright notice that meets all
            requirements of&nbsp;the Digital Millenium Copyright Act (<strong>DMCA</strong>) to:
        </p>
        <p>The Prospect Exchange, LLC</p>
        <div>711 Nance Ford Road Southwest</div>
        <div>Suite C</div>
        <div>Hartselle, AL&nbsp;35640</div>
        <p>info@theprospectexchange.com</p>
        <p>
            For the DMCA notice to&nbsp;be&nbsp;complete and effective, it&nbsp;must be&nbsp;submitted by&nbsp;the
            copyright holder or&nbsp;their authorized agent, and must contain:
        </p>
        <p>A&nbsp;description of&nbsp;the copyrighted work.</p>
        <p>
            A&nbsp;description of&nbsp;where the material you claim is&nbsp;infringing is&nbsp;located on&nbsp;our
            platform.
        </p>
        <p>Your contact information, including: name, physical address, email address, and telephone number.</p>
        <p>
            A&nbsp;statement that you have a&nbsp;good faith belief that the use of&nbsp;the disputed material
            is&nbsp;not authorized by&nbsp;the copyright owner, its agent, or&nbsp;the law.
        </p>
        <p>
            A&nbsp;statement/certification, made under penalty of&nbsp;perjury, that the information contained
            in&nbsp;the notice is&nbsp;accurate. This statement/certification must be&nbsp;signed&nbsp;
            <ul>
                <li>either electronically or&nbsp;physically&nbsp;</li>
                <li>by&nbsp;the copyright owner or&nbsp;the owner&rsquo;s legal representative.</li>
            </ul>
        </p>
        <p>
            Please note that if&nbsp;you fail to&nbsp;provide the required information, your DMCA notice may not
            be&nbsp;effective and will not be&nbsp;processed.
        </p>
        <p>
            <strong>Counter Notices:</strong> If&nbsp;you believe that your material was disabled or&nbsp;removed
            as&nbsp;a&nbsp;result of&nbsp;an&nbsp;improper DMCA notice, a&nbsp;mistake,
            or&nbsp;a&nbsp;misidentification, you may file a&nbsp;DMCA counter notice. The counter notice must include:
        </p>

        <p>A&nbsp;description of&nbsp;the copyrighted work.</p>
        <p>
            A&nbsp;description of&nbsp;where the material that was removed/disabled was located before it&nbsp;was
            removed/disabled.
        </p>
        <p>Your contact information, including: name, physical address, email address, and telephone number.</p>
        <p>
            A&nbsp;statement, made under penalty of&nbsp;perjury, that the user has a&nbsp;good faith believe that the
            material was removed or&nbsp;disabled as&nbsp;a&nbsp;result of&nbsp;mistake or&nbsp;misidentification
            of&nbsp;the material to&nbsp;be&nbsp;removed or&nbsp;disabled.
        </p>
        <p>
            A&nbsp;statement/certification, made under penalty of&nbsp;perjury, that the user consents to&nbsp;the
            jurisdiction of&nbsp;Federal District Court for the judicial district in&nbsp;which the address
            is&nbsp;located, or&nbsp;if&nbsp;the user&rsquo;s address is&nbsp;outside of&nbsp;the United States, for any
            judicial district in&nbsp;which TPE may be&nbsp;found, and that the user will accept service of&nbsp;process
            from the person who provided the DMCA notice or&nbsp;an&nbsp;agent of&nbsp;such person.
        </p>
        <p>
            <strong>IMPORTANT NOTE:</strong> Following the receipt of&nbsp;a&nbsp;complaint counter-notice, TPE must
            restore access to&nbsp;the material after no&nbsp;less than ten and no&nbsp;more than fourteen business days
            of&nbsp;receiving the copy of&nbsp;the counter notice.
        </p>

        <p>
            For additional details on&nbsp;the process, please see:&nbsp;
            <a href="https://www.copyright.gov/512/" target="_blank" rel="noopener noreferrer">
                https://www.copyright.gov/512/
            </a>
            .
        </p>
    </>
);
