export const unary = {
    NOT: 'not', // negation
    IS_NULL: 'isNull',
    IS_NOT_NULL: 'isNotNull'
};

export const binary = {
    EQ: 'eq', // equality
    NE: 'ne', // inequality
    LE: 'le', // less or equal
    GE: 'ge', // greater or equal
    LT: 'lt', // less than
    GT: 'gt', // greater than
    LIKE: 'like' // include substring
};

export const multiArg = {
    AND: 'and', //conjunction
    OR: 'or', // disjunction
    IN: 'in' // in set
};

export const fieldNames = {
    CLIENT_ID: 'clientId',
    RECOVERY: 'recovery',
    REBOUND_RECOVERY: 'reboundRecovery',
    PLAYER_ID: 'playerId',
    PERIOD: 'period',
    TEAM_ID: 'teamId',
    SEASON_ID: 'seasonId',
    TEAM_SCORE: 'teamScore',
    TEAM_PLAYERS_NUMBER: 'teamPlayersNumber',
    OPPONENT_SCORE: 'opponentScore',
    OPPONENT_PLAYERS_NUMBER: 'opponentPlayersNumber',
    GAME_ID: 'gameId',
    STATS_INDEX: 'statsIndex',
    GAME_TYPE: 'gameType',
    STATUS: 'status',
    MODERATOR_ID: 'moderatorId',
    VERIFIER_ID: 'verifierId',
    SUPPORT_ID: 'supportId',
    HOME_TEAM_ID: 'homeTeamId',
    HOME_TEAM_EN: 'homeTeamEn',
    HOME_TEAM_RU: 'homeTeamRu',
    AWAY_TEAM_EN: 'awayTeamEn',
    AWAY_TEAM_RU: 'awayTeamRu',
    AWAY_TEAM_ID: 'awayTeamId',
    ROLE: 'role',
    DATE: 'date',
    CREATOR_ID: 'creatorId',
    DESIGNER_ID: 'designerId'
};
