import { lineEventsSelector } from '../../../../../pages/game-center/analytics/special-teams/private/special-teams-selector';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { createSelector } from 'reselect';

const RUSH = 'PP_RUSH';
const REGROUP = 'PP_REGROUP_DZ';
const OZIFORMATION = 'PP_OZ_IN_FORM';
const OZOFORMATION = 'PP_OZ_OUT_FORM';

const types = { RUSH, REGROUP, OZIFORMATION, OZOFORMATION };

const selector = lineEventsSelector;

export const createLinesTypeSelector = type =>
    createSelector(
        stl => stl.data,
        data => ({ data: data.filter(d => d.type === type).map((d, i) => ({ ...d, id: i + 1 })) })
    );

const typeToSelector = {
    [RUSH]: createEpisodeIdWrapper(RUSH, createLinesTypeSelector(RUSH)),
    [REGROUP]: createEpisodeIdWrapper(REGROUP, createLinesTypeSelector(REGROUP)),
    [OZIFORMATION]: createEpisodeIdWrapper(OZIFORMATION, createLinesTypeSelector(OZIFORMATION)),
    [OZOFORMATION]: createEpisodeIdWrapper(OZOFORMATION, createLinesTypeSelector(OZOFORMATION))
};

function mapper(lines, type) {
    if (lines.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Power Play Lines');
    return typeToSelector[type](lines);
}

const getTime = ({ startTime, endTime }) => ({
    time: startTime,
    startTime,
    endTime,
    hasPadding: true,
    leftPadding: 3,
    rightPadding: 2
});

const typeToMediaTimeWrapper = Object.keys(typeToSelector)
    .map(key => ({ key, wrapper: createMediaTimeWrapper(getTime) }))
    .reduce((a, d) => {
        a[d.key] = d.wrapper;
        return a;
    }, {});

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

export const ppLines = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType: null
};
