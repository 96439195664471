export const OPTION_HEIGHT = 24;
export const MAX_RENDERED_OPTIONS = 30;
export const PREVIOUS_RENDERED_OPTIONS = 10;
export const GROUP_DELIMITER_HEIGHT = 7;
/**
 * @return {array} part of options from optionsOffset, which size less than `MAX_RENDERED_OPTIONS`
 */

export const sliceOptions = (options, optionsAreGrouped, optionsOffset, maxRendered = MAX_RENDERED_OPTIONS) => {
    if (!optionsAreGrouped) {
        return !!options ? options.slice(optionsOffset, optionsOffset + maxRendered) : [];
    }

    let offset = optionsOffset;
    return options.map(group => {
        if (offset >= group.length || offset + maxRendered < 0) {
            offset -= group.length;
            return [];
        } else {
            const groupOffset = Math.max(0, offset);
            const slicedGroup = group.slice(groupOffset, offset + maxRendered);
            offset -= group.length;
            return slicedGroup;
        }
    });
};
/**
 * @return {number} number of group delimiters which was scrolled down
 */

export const countScrolledDelimiters = (groupedOptions, optionsOffset) => {
    let groups = 0;
    let offset = optionsOffset;

    for (const options of groupedOptions) {
        if (options.length > offset) return groups;
        offset -= options.length;
        groups++;
    }

    return groups;
};
/**
 * @return {number} number of options from which we start render options
 */

export const calculateOptionsOffset = pixelOffset => {
    return Math.max(0, Math.floor(pixelOffset / OPTION_HEIGHT) - PREVIOUS_RENDERED_OPTIONS);
};
