import styled from 'styled-components/macro';
import { containerRowHeight, playStates, rewindControls, zIndexesVideoPlayer } from '../constants/video-player-configs';

export const opacityColor = `rgba(0, 0, 0, 0.2)`;
const trackInfoBackground = `rgba(0, 0, 0, 0.15)`;
const fontColor = `rgba(255, 255, 255, 1)`;

const ordinaryColumnsGrid = `auto repeat(4, 5%) 10% 1fr 5% 3% 25% 3% 5% 1fr 3% 10% repeat(2, 5%) auto`;
const mobileColumnsGrid = `1px repeat(5, 1rem) 1fr 1.5rem 0.5rem 6rem 0.5rem 1.5rem 1fr 0.5rem 2rem repeat(2, 1.5rem) 1px`;

export const ControlsContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${({ fullscreen }) =>
        fullscreen ? zIndexesVideoPlayer.videoFullScreenControls : zIndexesVideoPlayer.videoControls};

    display: grid;

    grid-template-columns: ${props =>
        props.isMobile && props.windowConfig && props.windowConfig.isPortrait
            ? mobileColumnsGrid
            : ordinaryColumnsGrid};
    grid-template-rows: 14px 1fr;
    width: 100%;
    max-width: 100%;
    height: ${props => (props.isMobile ? `calc(1.5 * ${containerRowHeight})` : `calc(2 * ${containerRowHeight})`)};

    border-radius: 0 0 8px 8px;

    color: ${fontColor};

    font-size: ${props => (props.isMobile ? '12px' : '1rem')};

    background-color: ${opacityColor};

    transition: opacity 0.3s;

    user-select: none;
`;

export const DrawControlsContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    overflow: hidden;

    border-radius: 8px 8px 0 0;

    color: white;

    background-color: rgba(0, 0, 0, 0.4);

    opacity: 1;

    transition: opacity 0.3s;

    user-select: none;
`;

export const RewindContainer = styled.div`
    display: flex;
    grid-column: ${props => (props.type === rewindControls.FORWARD ? 11 : 9)};
    grid-row: 2;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    filter: ${props => (props.isDisable ? `drop-shadow(1px 1px 1px whitesmoke)` : 'none')};

    ${props => props.type === rewindControls.FORWARD && 'transform: rotate(180deg);'}
`;

export const RewindCapture = styled.div`
    display: flex;
    grid-column: ${props => (props.type === rewindControls.FORWARD ? 12 : 8)};
    grid-row: 2;
    justify-content: center;
    align-items: center;

    font-size: 0.8em;

    background: #cccccc;

    opacity: 0;

    transition: opacity 0.3s;

    user-select: none;
`;

export const PlayPauseContainer = styled.div`
    display: flex;
    grid-column-end: 3;
    grid-column-start: 3;
    grid-row: 2;
    justify-content: center;
    align-items: center;
`;

export const RecordContainer = styled.div`
    display: flex;
    grid-column-end: 6;
    grid-column-start: 5;
    grid-row: 2;
    gap: 2px;
    justify-content: start;
    align-items: center;
`;

export const HotkeysContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-left: 5px;

    transform: translateY(-50%);
`;

export const PlayerHotkeyOuterBox = styled.div`
    padding: 2px;

    border-radius: 3px;
`;
export const PlayerHotkeyInnerBox = styled.div`
    width: 20px;
    padding: 1px;

    border: 1px solid white;
    border-radius: 3px;

    font-size: 0.8rem;
    text-align: center;
`;

export const HotkeysButtonList = styled.div`
    position: absolute;
    bottom: 68px;
    left: 10px;

    display: flex;
    flex-direction: column;
    gap: 2px;
    min-width: 11em;
    overflow: auto;
`;

export const HotkeysListItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;

    font-size: 0.8rem;
`;

export const HotkeysButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    border: 1px solid white;
    border-radius: 3px;
`;

const PlayCss = `
    width: 0;
    height: 0;
    margin-left: 2px;

    border-width: 10px 0 10px 15px;
    border-style: solid;
    border-color: transparent transparent transparent white;
`;

const PauseCss = `
    width: 12px;
    height: 20px;

    border-width: 0 5px 0;
    border-style: solid;
    border-color: white;
`;

const drawPlayPause = props => {
    if (props.playState === playStates.PLAYING) {
        return PauseCss;
    } else if (props.playState === playStates.PAUSED || props.playState === playStates.STOPPED) {
        return PlayCss;
    } else {
        return '';
    }
};

export const PlayPause = styled.div`
    cursor: pointer;
    ${drawPlayPause}
`;

export const FullscreenContainer = styled.div`
    display: flex;
    flex-direction: row;
    grid-column: 16;
    grid-row: 2;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

export const SoundControlContainer = styled('div')`
    display: flex;
    flex-direction: column;
    grid-column: 17;
    grid-row: 2;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    font-size: 0.8rem;

    cursor: pointer;

    user-select: none;
`;

export const TimeContainer = styled.div`
    display: flex;
    grid-column: 10;
    grid-row: 2;
    justify-content: center;
    align-items: center;
`;

export const Time = styled.div`
    color: ${props => (props.isRecord ? '#ff0000' : '#fff')};
    font-size: ${props => (props.isRecord ? '0.8rem' : '1rem')};

    cursor: default;

    user-select: none;

    @media (max-width: 1440px) {
        font-size: 0.8rem;
    }
`;

export const RangeContainer = styled.div`
    display: flex;
    grid-column: 2 / 18;
    grid-row: 1;
    align-items: flex-end;

    cursor: pointer;
`;

export const Range = styled.div`
    position: relative;

    width: 100%;
    height: 2px;

    background-color: ${fontColor};
`;

export const Progress = styled.div`
    height: 100%;

    background-color: ${props => (props.disable ? '#ccc' : 'var(--uiPrimaryColor)')};
`;

export const Seeker = styled.div`
    position: absolute;

    width: 10px;
    height: 10px;

    border-radius: 50%;

    background-color: transparent;
    transform: translate(-5px, -5px);
    cursor: pointer;
`;

export const TOOLTIP_WIDTH = 80;

export const TimeTooltip = styled.div`
    --margin: 5px;
    --width: ${TOOLTIP_WIDTH}px;
    --triangle-width: 15px;

    position: absolute;
    bottom: calc(var(--margin) + var(--triangle-width) / 2 + 14px);

    width: var(--width);
    padding: 2px 7px;

    border-radius: 2px;

    color: ${fontColor};
    text-align: center;

    background-color: rgba(48, 55, 62, 0.6);

    visibility: hidden;
    cursor: default;

    user-select: none;

    &:before {
        position: absolute;
        bottom: calc(-1 * var(--triangle-width));
        left: calc((var(--width) - 2 * var(--triangle-width)) / 2);

        border-width: var(--triangle-width) var(--triangle-width) 0;
        border-style: solid;
        border-color: rgba(48, 55, 62, 0.6) transparent transparent;
        content: '';
    }
`;

export const VideoPlaylistModeWrapper = styled.div`
    display: flex;
    flex-direction: column;

    grid-column: 15;
    grid-row: 2;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const VideoPlayerPlaylistModeLabel = styled.span`
    color: ${fontColor};
    font-size: ${props => (props.isMobile ? '0.5rem' : '0.7rem')};
    text-transform: uppercase;
`;

export const VideoSpeedControlWrapper = styled.div`
    position: absolute;

    display: flex;
    flex-direction: column;
    grid-column: 6;
    grid-row: 2;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;
`;

export const VideoSpeedWrapper = styled.div`
    display: flex;

    justify-content: center;
    align-items: center;
    width: 50px;
    height: 100%;
`;

export const VideoPlayerSpeedLabel = styled.span`
    width: 100%;

    color: ${fontColor};
    font-size: 14px;
    text-align: center;
`;

export const VideoSpeedPicker = styled.div`
    position: absolute;

    top: calc(-50% - 1rem);

    display: flex;
    flex-direction: row;

    justify-content: center;
    width: 200px;
    height: 20px;
`;

export const VideoSpeedValueButton = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    padding: 5px;

    border: none;

    color: #000;
    font-size: 10px;

    background-color: rgba(125, 125, 125, 0.25);

    &:hover {
        color: ${fontColor};

        background-color: rgba(125, 125, 125, 0.75);
    }
`;

export const VideoFragmentDurationControlsWrapper = styled.div`
    position: absolute;

    display: flex;
    flex-direction: row;

    grid-column: 7;
    grid-row: 2;
    justify-content: space-between;
    width: 75px;
    height: 100%;
    margin-left: -20px;

    font-size: 12px;
`;

export const DurationMenuControls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const DurationMenuControl = styled.button`
    width: 50%;
    height: 50%;
    padding: 0;

    border: none;

    color: ${fontColor};

    background: transparent;
`;

export const DeltaTimeDialogWrapper = styled.div`
    position: absolute;
    top: calc(-50% - 1rem);
    left: -5em;

    display: flex;
    flex-direction: row;
    width: 100px;
`;

export const DeltaTimeElement = styled.button`
    width: 3em;

    border: none;

    text-align: center;

    background-color: rgba(125, 125, 125, 0.25);

    &:hover {
        color: ${fontColor};

        background-color: rgba(125, 125, 125, 0.75);
    }
`;

export const VideoControlsTrackInfoWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 5%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 1px;

    font-weight: ${props => (props.isMobile ? 100 : 400)};
    font-size: ${props => (props.isMobile ? '10px' : '12px')};
    letter-spacing: 2px;
    text-transform: uppercase;
`;

export const VideoControlsTrackInfoLabel = styled.span`
    background-color: ${trackInfoBackground};
`;

export const VideoControlsGameLabelWrapper = styled.div`
    position: absolute;

    bottom: ${props => (props.isMobile ? `calc(2.5 * ${containerRowHeight})` : `calc(3 * ${containerRowHeight})`)};

    left: 5%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 1px;
`;

export const VideoControlsTrackLabelWrapper = styled.div`
    position: absolute;

    bottom: ${props => (props.isMobile ? `calc(1.5 * ${containerRowHeight})` : `calc(2 * ${containerRowHeight})`)};

    left: ${props => (props.isMobile ? '0%' : '5%')};

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 1rem;
`;

export const NextTrackContainer = styled.div`
    display: flex;
    grid-column: 4;
    grid-row: 2;
    justify-content: flex-end;
    align-items: center;
    width: fit-content;
    margin-left: 4px;
`;

export const PrevTrackContainer = styled.div`
    display: flex;
    grid-column-end: 2;
    grid-column-start: 2;
    grid-row: 2;
    justify-content: flex-end;
    align-items: center;
    width: fit-content;
    margin-left: 35px;
`;

export const VolumeMenuWrapper = styled('div')`
    position: absolute;
    top: -1rem;
    right: 0;

    width: 4rem;
    height: 1rem;
`;

export const InputRange = styled.input`
    width: 3.5rem;
`;
