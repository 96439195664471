import { makePrefixAdder } from '../utils/type-utils';

const addPrefixTo = makePrefixAdder('PREFERENCES');
const SWITCH_ACCESS_ANALYST_TO_ADMIN = addPrefixTo('SWITCH_ACCESS_ANALYST_TO_ADMIN');

const switchAccessAnalystToAdmin = access => {
    return { type: SWITCH_ACCESS_ANALYST_TO_ADMIN, payload: access };
};

const defaultState = {
    accessAnalystToAdmin: localStorage.getItem('accessAnalystToAdmin')
};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case SWITCH_ACCESS_ANALYST_TO_ADMIN:
            return {
                ...state,
                accessAnalystToAdmin: action.payload
            };
        default:
            return state;
    }
}

export const accessAnalystToAdminSelector = state => state.preferences.accessAnalystToAdmin;

const allowAccessAnalystToAdmin = () => switchAccessAnalystToAdmin(true);
const denyAccessAnalystToAdmin = () => switchAccessAnalystToAdmin(false);

export const actions = {
    allowAccessAnalystToAdmin,
    denyAccessAnalystToAdmin
};
