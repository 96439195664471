export const deviceTypes = {
    MOBILE: 'MOBILE',
    DESKTOP: 'DESKTOP'
};
export const mobileMaxWidth = 1024;
export const DESKTOP_RES = {
    // Increased from 1200 to 1300 because of http://localhost:3000/schedule-admin/games
    minWidthPx: 1024,
    minHeightPx: 768
};
