import React from 'react';
import { connect } from 'react-redux';
import {
    waiterVisibilitySelector,
    actions as waiterOfProcessActions,
    waiterChildrenAreExistSelector,
    videoWaiterBusySelector
} from 'redux/modules/waiter-of-process';
import { ClipServiceInformerWrapper, DownloadClipServiceIcon } from '../app-private-styled-components';

//todo states: 1) no active loading 2) loading in progress 3) loading is finish

const ClipServiceInformer = ({
    waiterVisibility,
    activateWaiter,
    deactivateWaiter,
    waiterChildrenAreExist,
    videoWaiterIsBusy
}) => {
    const handleClickIcon = () => {
        if (waiterVisibility) {
            deactivateWaiter();
        }
        if (!waiterVisibility) {
            activateWaiter();
        }
    };

    return (
        <ClipServiceInformerWrapper isAvailable={waiterChildrenAreExist} style={{ marginRight: '20px' }}>
            <DownloadClipServiceIcon onClick={handleClickIcon} inProgress={videoWaiterIsBusy} />
        </ClipServiceInformerWrapper>
    );
};

export default connect(
    state => {
        return {
            waiterVisibility: waiterVisibilitySelector(state),
            waiterChildrenAreExist: waiterChildrenAreExistSelector(state),
            videoWaiterIsBusy: videoWaiterBusySelector(state)
        };
    },
    {
        activateWaiter: waiterOfProcessActions.activateWaiter,
        deactivateWaiter: waiterOfProcessActions.deActivateWaiter
    }
)(ClipServiceInformer);
