import StyledModal from '../../../components/modal/styled-modal';
import React, { useCallback, useEffect, useState } from 'react';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';

import styles from './edit-email-modal.module.css';
import { apiBuilder } from '../../../utils/api/common-requests/common-requests';
import * as endpoints from '../../../utils/api/endpoints';
import Loading from '../../../components/loading/loading';

const validRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const EditEmailModal = ({ editEmailIsOpen, onCancelEditEmail, currentEmail, readCurrentUser }) => {
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleUpdateEmail = useCallback(() => {
        setProcessing(true);
        setError(false);
        setSuccess(false);
    }, []);

    const handleChange = useCallback(e => {
        const updatedEmail = e.target.value || '';

        setEmail(updatedEmail);

        setIsValid(!!updatedEmail.match(validRegex));
    }, []);

    useEffect(() => {
        if (!processing || !email || email === '') {
            return;
        }

        apiBuilder()
            .post(endpoints.emailUpdate(email))
            .then(res => {
                const { status } = res || {};

                if (status === 200) {
                    setSuccess(true);
                } else {
                    setError(true);
                }

                setProcessing(false);
            })
            .catch(err => {
                // eslint-disable-next-line no-console
                console.error(err);
                setProcessing(false);
                setError(true);
            });
    }, [processing, email]);

    return (
        <StyledModal
            contentCss={`border-radius: 8px; box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12)`}
            isOpen={editEmailIsOpen}
            disableScroll
            onRequestClose={onCancelEditEmail}
        >
            {success && !error && (
                <div className={styles.success}>
                    <p>
                        Please check your mailbox, where you will find a&nbsp;message with a&nbsp;link to&nbsp;confirm
                        your email address. Be&nbsp;sure to&nbsp;check both the inbox and the spam folder.
                    </p>
                    <Button
                        className={styles.closeSuccess}
                        onClick={() => {
                            readCurrentUser();
                            onCancelEditEmail();
                        }}
                    >
                        <span className={styles.buttonText}>Close</span>
                    </Button>
                </div>
            )}

            {!error && !success && (
                <div className={styles.main}>
                    <Input className={styles.input} placeholder={currentEmail} onChange={handleChange} />

                    {processing && <Loading spinner />}

                    <Button className={styles.save} disabled={!isValid && !processing} onClick={handleUpdateEmail}>
                        <span className={styles.buttonText}>Save</span>
                    </Button>

                    <Button className={styles.cancel} onClick={onCancelEditEmail}>
                        <span className={styles.buttonText}>Cancel</span>
                    </Button>
                </div>
            )}

            {error && !success && <p>Error on update email. Please try again latter.</p>}
        </StyledModal>
    );
};

export default EditEmailModal;
