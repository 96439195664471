import styled from 'styled-components/macro';
import { boxShadow } from '../video-player/constants/video-player-configs';
import { mobileMediaCondition } from '../../utils/helpers/device-utils';

export const WaiterOfProcessWrapper = styled('section')`
    position: fixed;
    top: 55px;
    right: 100px;
    z-index: 12001;

    grid-template-columns: 5px auto 2rem 5px;

    grid-template-rows: 5px auto 5px;
    min-width: 370px;
    max-width: 50vw;

    height: 20vh;
    max-height: 40vh;
    overflow-x: auto;
    overflow-y: hidden;

    border-radius: 5px;

    background: var(--backgroundColorSecondary);

    box-shadow: ${boxShadow};

    @media ${mobileMediaCondition} {
        right: 1rem;

        max-width: 88vw;
        max-height: 88vh;
    }
`;

export const WaiterOfProcessContentWrapper = styled('div')`
    display: flex;

    flex-direction: column;
    grid-column-end: 4;
    grid-column-start: 2;
    grid-row-end: 3;

    grid-row-start: 2;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: auto;

    border-radius: 5px;

    background: var(--backgroundColorSecondary);
`;

export const WaiterProgressRowWrapper = styled('div')`
    display: grid;

    grid-gap: 5px;

    grid-template-columns: 10% 45% 35% 10%;
    grid-template-rows: 2rem;
    width: 100%;
    padding: 5px;

    border-bottom: 1px solid grey;

    user-select: none;
`;

export const WaiterProgressRowTitle = styled('span')`
    display: flex;
    flex-direction: row;
    grid-column: 2;
    grid-row: 1;

    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    overflow: hidden;

    color: ${props => (props.errorOnTask ? 'var(--cancelColor)' : 'var(--defaultColor)')};

    font-weight: bold;
    font-size: small;
    white-space: nowrap;

    text-transform: uppercase;
    text-overflow: ellipsis;
`;

export const WaiterProgressRowTitleError = styled('div')`
    display: flex;

    flex: 1;
    flex-direction: row;
    grid-column: 2;
    grid-row: 1;

    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    overflow: hidden;

    color: ${props => (props.errorOnTask ? 'var(--cancelColor)' : 'var(--defaultColor)')};

    font-weight: bold;
    font-size: small;
    white-space: nowrap;

    text-transform: uppercase;
    text-overflow: ellipsis;
`;

export const WaiterProgressRowLoadWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    grid-column: 3;

    grid-row: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    text-transform: uppercase;
`;

export const WaiterLinkToFile = styled('a')`
    display: flex;
    flex-direction: column;
    grid-column: 3;

    grid-row: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    text-decoration: none;
`;

export const WaiterProgressRowResetButton = styled('div')`
    display: flex;
    flex-direction: column;
    grid-column: 4;

    grid-row: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
