import { denormalize } from 'normalizr';
import { seasonSchema } from '../../utils/schemas';
import { createSkipMarkedArgsSelector, markSelector } from '../../utils/selectors';
import { fetchedGamesSelector } from '../games/games-selector';

export const EMPTY_SEASON = { id: null, leagueId: null, nameRu: 'Без сезона', nameEn: 'No season' };

export const fetchedSeasonsSelector = createSkipMarkedArgsSelector(
    state => state.statsFilterSeason.availableSeasonIds,
    fetchedGamesSelector,
    markSelector(state => state.entities),
    state => state.statsFilter.uploadedGames,
    state => state.statsFilter.publishedGames,
    state => state.statsFilter.inProgressGames,
    state => state.statsFilter.underReviewGames,
    (seasonIds, games, entities, uploadedGames, publishedGames, inProgressGames, underReviewGames) => {
        if (!seasonIds.length) {
            return [];
        }

        const uniqueSeasonIds = new Set(
            [
                ...(games || []),
                ...(uploadedGames ?? []),
                ...(publishedGames ?? []),
                ...(inProgressGames ?? []),
                ...(underReviewGames ?? [])
            ].map(game => game.seasonId)
        );
        const seasons = [];

        if (uniqueSeasonIds.has(null)) {
            uniqueSeasonIds.delete(null);
            seasons.push(EMPTY_SEASON);
        }
        // todo: fix undefined in denormalized data instead of filtering
        return [...seasons, ...denormalize([...uniqueSeasonIds], [seasonSchema], entities).filter(Boolean)];
    }
);
