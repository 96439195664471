import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Manager, Popper, Reference } from 'react-popper';

import { isMobile as isMobileSelector } from 'redux/modules/device-type';
import { useSummaryRedirect } from 'utils/hooks/summary-redirect';

import { Controls } from './components/controls';
import { InputField } from './components/input-field';
import { SearchField } from './components/search-field';
import { SearchResult } from './components/search-result';
import { useOutsideClick } from './hooks/outside-click';
import { useFetchLastTeamGame } from './hooks/fetch-last-team-game';
import { useFetchSearchPlayerTeams } from './hooks/fetch-search-player-teams';
import { useSearchInputField } from './hooks/search-input-field';

import { SearchPanelFullWrapper, Tooltip, DataWrap } from './top-search-panel.styled';
import { useFetchFavorites } from 'widgets/favorites/hook/use-fetch-favorites';
import { httpMethods, useFetchData } from 'utils/hooks/use-fetch-data';
import * as endpoints from 'utils/api/endpoints';
import { reFetchFavoritesTeamAndPlayerAction } from 'redux/modules/favourites';

const TopSearchPanel = ({ isSummaryMode = false, isFavoriteListNeedToFetch }) => {
    const isMobile = useSelector(isMobileSelector);
    const dispatch = useDispatch();
    const [deletedItem, setDeletedItem] = useState(null);
    const [addFavItem, setAddFavItem] = useState(null);

    const {
        inputValue,
        isPlayers,
        isTeams,
        tooltipVisible,
        handleReset,
        handleMouseEnter,
        handleMouseLeave,
        handleInputChange,
        handleInputClick,
        handeVisible,
        handlePlayers,
        handleTeams
    } = useSearchInputField();

    const {
        players: playerSearchResults,
        teams: teamSearchResults,
        processing: searchProcessing
    } = useFetchSearchPlayerTeams({ inputValue });

    const {
        redirectParams,
        processing: teamLastGameProcessing,
        handleTeamSelect: handleTeamClick,
        handleReset: handleTeamLastGameReset
    } = useFetchLastTeamGame({
        isSummaryMode,
        onRedirect: () => {
            handleTeamLastGameReset();
            handleReset?.();
        }
    });

    useSummaryRedirect({
        ...redirectParams,
        onRedirect: () => {
            handleTeamLastGameReset();
            handleReset?.();
        },
        isDisabled: !isSummaryMode
    });

    const containerRef = useOutsideClick({ isMobile, isVisible: tooltipVisible, handeVisible });

    const { data: playerData, processing: favPlayerProcessing } = useFetchFavorites({
        initialCondition: !addFavItem && !deletedItem && isFavoriteListNeedToFetch,
        favType: 'SCOUT_PLAYER'
    });

    const { data: teamData, processing: favTeamProcessing } = useFetchFavorites({
        initialCondition: !addFavItem && !deletedItem && isFavoriteListNeedToFetch,
        favType: 'TEAM',
        isAllTeams: true
    });

    const { processing: removeFavProcessing } = useFetchData({
        method: httpMethods.delete,
        url: endpoints.favoriteItem(deletedItem),
        initialCondition: !!deletedItem
    });

    useEffect(() => {
        if (removeFavProcessing) {
            return;
        }
        setDeletedItem(null);
        dispatch(reFetchFavoritesTeamAndPlayerAction(true));
    }, [removeFavProcessing]);

    useFetchData({
        method: httpMethods.post,
        url: endpoints.favoriteItems,
        initialCondition: !!addFavItem && !!addFavItem.id,
        payload: { itemEntityId: addFavItem?.id, itemType: addFavItem?.type },
        responseHandler: () => {
            setAddFavItem(null);
            dispatch(reFetchFavoritesTeamAndPlayerAction(true));
        }
    });

    const handleFavoriteToggle = useCallback(
        (deletedId, addFavItem) => {
            if (deletedId) {
                setDeletedItem(deletedId);
                dispatch(reFetchFavoritesTeamAndPlayerAction(false));
            } else {
                setAddFavItem(addFavItem);
                dispatch(reFetchFavoritesTeamAndPlayerAction(false));
            }
        },
        [dispatch]
    );

    const { favItems: favTeamList = [] } = teamData;
    const { favItems: favPlayerList = [] } = playerData;
    const processing = searchProcessing || teamLastGameProcessing || favTeamProcessing || favPlayerProcessing;

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div ref={ref}>
                        <SearchField isMobile={isMobile} onClick={handleInputClick} value={inputValue} />
                    </div>
                )}
            </Reference>

            {createPortal(
                <Popper key="player-search-panel">
                    {({ placement, ref, style }) => (
                        <div style={{ ...style, zIndex: 12001 }} ref={ref} data-placement={placement}>
                            {tooltipVisible && (
                                <Tooltip
                                    ref={containerRef}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <SearchPanelFullWrapper>
                                        <InputField
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            onCancel={handleReset}
                                            processing={processing}
                                        />

                                        {(!!playerSearchResults.length || !!teamSearchResults.length) && (
                                            <DataWrap>
                                                <Controls
                                                    isPlayers={isPlayers}
                                                    isTeams={isTeams}
                                                    onPlayers={handlePlayers}
                                                    onTeams={handleTeams}
                                                />
                                                <SearchResult
                                                    isPlayers={isPlayers}
                                                    isTeams={isTeams}
                                                    players={playerSearchResults}
                                                    teams={teamSearchResults}
                                                    onTeamSelect={handleTeamClick}
                                                    isFavorite
                                                    favTeamList={favTeamList}
                                                    favPlayerList={favPlayerList}
                                                    onFavoriteToggle={handleFavoriteToggle}
                                                />
                                            </DataWrap>
                                        )}
                                    </SearchPanelFullWrapper>
                                </Tooltip>
                            )}
                        </div>
                    )}
                </Popper>,
                document.querySelector('body')
            )}
        </Manager>
    );
};

export default TopSearchPanel;
