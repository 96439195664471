import styled from 'styled-components/macro';

export const ReleaseNoteWrapper = styled('div')`
    display: flex;
    align-items: center;
    padding-right: 4px;
`;

export const IconContainer = styled.div`
    height: 22px;
    padding-inline: 4px;

    cursor: pointer;
    &:hover {
        color: rgb(149, 196, 251);
    }
`;

export const PopupMenu = styled.div`
    position: absolute;
    top: 58px;
    right: 10px;
    z-index: 1000;

    min-width: 330px;
    max-width: 330px;
    min-height: 368px;
    max-height: 378px;
    overflow-y: scroll;

    border-radius: 8px;

    background-color: var(--backgroundColorSecondary);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;

        background: #0003;
        box-shadow: inset 0 0 6px ${'#ccc'};
    }
`;

export const PopupHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
`;

export const PopupTitle = styled.div`
    color: var(--uiPrimaryColor);
    font-weight: 600;
    font-size: 16px;
`;

export const PopupBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;

    color: var(--uiPrimaryColor);
    font-weight: 400;
    font-size: 16px;
`;

export const ReleaseContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 5px;
`;

export const ReleaseTitle = styled.div`
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
`;

export const ReleaseDescription = styled.div`
    color: var(--defaultColor);
    font-size: 14px;
    white-space: pre-wrap;
`;

export const ReleaseDate = styled.div`
    color: var(--optionsPrimaryColor);
    font-size: 12px;
    text-align: end;
`;
