const PTS = 'PTS';
const PRIMARY_ASSIST = 'PRIMARY_ASSIST';
const SECONDARY_ASSIST = 'SECONDARY_ASSIST';
const GOALS_AFTER_REBOUNDS = 'GOALS_AFTER_REBOUNDS';
const SHOTS_MISSED = 'SHOTS_MISSED';
const SHOTS_ATTEMPTS = 'SHOTS_ATTEMPTS';
const SHOTS_BLOCKED = 'SHOTS_BLOCKED';
const SAVES = 'SAVES';

const types = {
    PTS,
    PRIMARY_ASSIST,
    SECONDARY_ASSIST,
    GOALS_AFTER_REBOUNDS,
    SHOTS_MISSED,
    SHOTS_ATTEMPTS,
    SHOTS_BLOCKED,
    SAVES
};

// TODO: add functions inside metricGenerated
const metricGenerated = {
    types,
    selector: null,
    mapper: null,
    getTimedGroup: null,
    favoriteType: null
};

export default metricGenerated;
