/* eslint-disable eqeqeq */
import { createSelector } from 'reselect';
import { teamsPredicateSelector } from '../../../selectors/predicates/team-predicates';

const defaultPositionStatus = ['F', 'D'];
const getPositionStatus = state => state.statsFilter.positionStatus || defaultPositionStatus;

const defaultSuccessStatus = [true, false];
const getSuccessStatus = state => state.statsFilter.successStatus || defaultSuccessStatus;

export const getGamePlayers = state => state.entities.gamePlayers;

const getGames = state => state.entities.games;

const defaultEntryTypes = ['carry', 'dump', 'pass'];
const getEntryTypeStatus = state => state.statsFilter.entryTypeStatus || defaultEntryTypes;

const processingState = { processing: true };
const getEntries = state => state.widgetsStats.entries || processingState;

export const getEntriesWithPlayerId = createSelector(
    getEntries,
    state => state.entities && state.entities.gamePlayers,
    (entries, gamePlayers) => {
        if (entries.processing || !gamePlayers) return { processing: true };
        return { data: entries.data.map(e => ({ ...e, playerId: gamePlayers[e.gamePlayerId].playerId })) };
    }
);

const getEntryTeamId = entry => {
    return entry.teamStatus === 'HOME' ? entry.homeTeamId : entry.awayTeamId;
};

const getEntryType = type => type.split('_')[0].toLowerCase();

const predicateSelector = createSelector(
    (state, props) => props && props.filter,
    teamsPredicateSelector,
    (filter, teamsPredicate) => (filter && filter.teamIds ? teamId => filter.teamIds.includes(teamId) : teamsPredicate)
);

function getEntriesFilteredByTeamStatus(
    entries,
    teamsPredicate,
    positionStatus,
    successStatus,
    entryTypeStatus,
    gamePlayers,
    games
) {
    if (entries.processing || !games || !gamePlayers) return { processing: true };
    return {
        data: entries.data.filter(entry => {
            const player = gamePlayers[entry.gamePlayerId];
            const type = getEntryType(entry.type);
            const teamId = getEntryTeamId(entry);
            const { isSuccess } = entry;
            return (
                player &&
                positionStatus.includes(player.playerPosition) &&
                teamsPredicate(teamId) &&
                successStatus.includes(isSuccess) &&
                entryTypeStatus.includes(type)
            );
        })
    };
}

export const entriesTeamsSelector = createSelector(
    getEntriesWithPlayerId,
    predicateSelector,
    getPositionStatus,
    getSuccessStatus,
    getEntryTypeStatus,
    getGamePlayers,
    getGames,
    getEntriesFilteredByTeamStatus
);
