import { indexToMask } from '../helpers/bit-mask-utils';

export const modules = {
    GOALTENDERS: 0,
    PASSING: 1,
    ENTRIES: 2,
    EXITS: 3,
    POSSESSION: 4,
    SHIFTS: 5,
    GAME_SCORE: 6,
    PCT: 7,
    IPP: 8
};

const createMasksFromModuleIndexes = moduleIndexes => {
    const moduleMasks = {};
    for (const module of Object.keys(moduleIndexes)) {
        moduleMasks[module] = indexToMask(moduleIndexes[module]);
    }
    return moduleMasks;
};

export const moduleMasks = createMasksFromModuleIndexes(modules);
