export const getRolledBackPages = () => (localStorage.rolledBackPages ? JSON.parse(localStorage.rolledBackPages) : []);
export const setRolledBackPages = value => {
    localStorage.rolledBackPages = JSON.stringify(value);
};

export const getEpisodesPaddingLeft = () => window.localStorage.episodesPaddingLeft;
export const getEpisodesPaddingRight = () => window.localStorage.episodesPaddingRight;
export const setEpisodesPaddingLeft = value => {
    window.localStorage.episodesPaddingLeft = value;
};
export const setEpisodesPaddingRight = value => {
    window.localStorage.episodesPaddingRight = value;
};

export const getConfiguration = () => localStorage.configurator && JSON.parse(localStorage.configurator);
export const setConfiguration = value => {
    localStorage.configurator = JSON.stringify(value);
};

export const getRatingSystem = () => localStorage.ratingSystem;
export const setRatingSystem = value => {
    localStorage.ratingSystem = value;
};

export const getLocalConfiguration = () =>
    localStorage.localConfiguration ? JSON.parse(localStorage.localConfiguration) : {};
export const setLocalConfiguration = value => {
    localStorage.localConfiguration = JSON.stringify(value);
};
