import { makePrefixAdder } from '../utils/type-utils';
import { STORAGE_DESIGN } from '../../utils/api/auth-utils';

const designs = {
    STABLE: 'stable',
    MARKETING: 'marketing'
};

const addPrefixTo = makePrefixAdder('PORTAL_DESIGN');
const SWITCH_DESIGN = addPrefixTo('SWITCH_DESIGN');

const switchDesign = designType => {
    return { type: SWITCH_DESIGN, payload: designType };
};

const defaultState = {
    design: sessionStorage.getItem(STORAGE_DESIGN) || localStorage.getItem(STORAGE_DESIGN) || designs.STABLE
};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case SWITCH_DESIGN:
            return {
                design: action.payload
            };
        default:
            return state;
    }
}

export const selector = state => state.design.design;

export const isStable = state => selector(state) === designs.STABLE;
export const isMarketing = state => selector(state) === designs.MARKETING;

const switchToStable = () => {
    return switchDesign(designs.STABLE);
};

const switchToMarketing = () => {
    return switchDesign(designs.MARKETING);
};

export const actions = {
    switchToStable,
    switchToMarketing
};
