import { FAVORITE_EVENT } from '../../favorite-types';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { eventsTeamsSelector } from '../../../../../containers/widgets-stats/selectors/events';
import { createSelector } from 'reselect';
import { getTimeForMediaTimeWrapperType } from '../helpers/episodes-selectors-helpers';

const PPA = 'PPA';
const PPS = 'PPS';
const SUCCESS_PASSES = 'SUCCESS_PASSES';
const UNSUCCESS_PASSES = 'UNSUCCESS_PASSES';

const selector = eventsTeamsSelector;

const types = { PPA, PPS, SUCCESS_PASSES, UNSUCCESS_PASSES };

const favoriteType = FAVORITE_EVENT;

const ppaSelector = createEpisodeIdWrapper(
    PPA,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.assistType === 'POTENTIAL_ASSIST_1') })
    )
);

const ppsSelector = createEpisodeIdWrapper(
    PPS,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.assistType === 'POTENTIAL_ASSIST_2') })
    )
);

const successPassesSelector = createEpisodeIdWrapper(
    SUCCESS_PASSES,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.successPass) })
    )
);

const unsuccessPassesSelector = createEpisodeIdWrapper(
    UNSUCCESS_PASSES,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.eventType === 'PASS' && !e.successPass) })
    )
);

function mapper(events, type) {
    if (events.processing) return processingState;
    switch (type) {
        case PPA:
            return ppaSelector(events);
        case PPS:
            return ppsSelector(events);
        case SUCCESS_PASSES:
            return successPassesSelector(events);
        case UNSUCCESS_PASSES:
            return unsuccessPassesSelector(events);
        default:
            throw mapperErrorMessage(type, 'Passing');
    }
}

const typeToMediaTimeWrapper = {
    [PPA]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [PPS]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [SUCCESS_PASSES]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [UNSUCCESS_PASSES]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType)
};

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const passing = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default passing;
