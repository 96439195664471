import styled from 'styled-components/macro';

export const RecaptcherWrapper = styled('section')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    min-height: 6rem;
    padding: 0.5rem;
`;
