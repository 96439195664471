export const homePage = 'home';

export const rawVideoPage = 'raw';

export const analyticsIndex = 'analytics';

export const manageFavoritesPage = 'manage-favorites';

export const analyticsPages = {
    summary: 'summary',
    powerPlay: 'powerplay',
    penaltyKill: 'penaltykill',
    gameScore: 'gamescore',
    playersPage: 'players-page',
    configurator: 'configurator',
    skaters: 'skaters'
};

export const statisticsIndex = 'statistics';
export const conservatorIndex = 'playlists';
export const scheduleIndex = 'schedule';
export const productDescriptionIndex = 'products';
export const customPlaylistsIndex = 'custom-playlists';
export const teamLanderIndex = 'team';

export const preScoutPages = {
    summary: 'summary',
    goaltenders: 'goalkeepers',
    shifts: 'shifts'
};

export const statisticsPages = {
    shooting: 'shooting',
    goaltenders: 'goalkeepers',
    passing: 'passing',
    entries: 'entries',
    exits: 'exits',
    possession: 'possession',
    skating: 'skating',
    shifts: 'shifts',
    playerComparison: 'player-comparison'
};

export const clientSchedulePages = {
    calendarPage: 'calendar',
    requestedGames: 'requested-games',
    scheduledGames: 'scheduled-games',
    comments: 'comments'
};

export const clientProductDescriptionPages = {
    preScoutDescriptionPage: 'pre-scout',
    scoutDescriptionPage: 'scout'
};

export const serviceIndex = 'support';

export const whatsNewIndex = 'whats-new';

export const servicePages = {
    glossary: 'glossary',
    videoManuals: 'video-manuals'
};

export const scoutIndex = 'scout-product';

export const scoutPages = {
    favorites: 'favorites',
    reports: 'reports',
    teams: 'teams',
    players: 'players',
    playerTeams: 'teams',
    playerContracts: 'contracts',
    playerSkills: 'skills',
    playerVideos: 'videos',
    playerEvaluation: 'evaluation',
    playerNotes: 'notes',
    playerCombine: 'combine',
    playerSchedule: 'schedule',
    playlists: 'playlists'
};

export const connectPage = 'connect';

/// Admin

export const scoutAdminIndex = {
    root: 'scout-admin',
    leagues: 'leagues',
    seasons: 'seasons',
    teams: 'teams',
    scoutGames: 'scout-games', // moved to `scheduleAdminIndex.database`
    players: 'players',
    gamesUpload: 'games-upload',
    playersTPE: 'players-tpe'
};

export const clientsAdminIndex = {
    root: 'clients-admin',
    clients: 'clients',
    users: 'users',
    clientUsers: 'client-users',
    clientReports: 'client-reports',
    clientGames: 'client-games',
    contracts: 'contracts',
    deals: 'deals',
    scoutContracts: 'scout-contracts',
    favoriteTeams: 'favorite-teams',
    clientRooms: 'client-rooms',
    scoutParticipants: 'scout-participants',
    csvClientUser: 'csv-client-user'
};

export const scheduleAdminIndex = {
    root: 'schedule-admin',
    games: 'games',
    database: 'database',
    shifts: 'shifts',
    videoCrop: 'video-crop'
};

/// Collector

export const verificationIndex = {
    root: 'verification',
    faceOffs: 'face-offs',
    strengths: 'strengths',
    shots: 'shots',
    events: 'events',
    shifts: 'shifts',
    jerseys: 'jerseys'
};

export const collectorPartsIndex = 'parts';

export const collectorStorageIndex = 'storage';

export const collectorGameTrashIndex = 'trash';

export const collectorGameCommentsIndex = 'comments';

/// Player's account

export const playersCabinet = {
    root: 'players-cabinet',
    myPage: 'my-page',
    myOrders: 'my-orders'
};

export const statsIndex = 'stats';

export const shiftsIndex = 'shifts';

export const goaliesIndex = 'goalies';

export const cloudVideoCreator = 'create';
