import React, { useEffect } from 'react';
import styles from './styles.module.css';
import cx from 'classnames';

const omResizeSetGlobalStyleVar = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const PageContainer = props => {
    const { children, withPadding, withBottomPadding, prospectMode, isContrast } = props;

    useEffect(() => {
        omResizeSetGlobalStyleVar();
        // We listen to the resize event
        window.addEventListener('resize', omResizeSetGlobalStyleVar);

        return () => {
            window.removeEventListener('resize', omResizeSetGlobalStyleVar);
        };
    }, []);

    if (window.location.pathname === '/report') {
        return null;
    }

    return (
        <div
            className={cx(prospectMode ? styles.panelProspectMode : styles.panel, {
                [styles.contrastPanel]: isContrast
            })}
        >
            <div
                className={cx(styles.panelBody, {
                    [styles.withPadding]: withPadding,
                    [styles.withBottomPadding]: withBottomPadding
                })}
            >
                {children}
            </div>
        </div>
    );
};

export default PageContainer;
