import React, { useCallback, useState } from 'react';

import { Img, List, Row, FavButtonWrap, PlayerLink, ControlsTab } from '../top-search-panel.styled';
import Star from 'components/star/star';
import { httpMethods, useFetchData } from 'utils/hooks/use-fetch-data';
import * as endpoints from 'utils/api/endpoints';
import { useHistory, useLocation } from 'react-router-dom';

export const SearchResult = ({
    isPlayers,
    isTeams,
    players,
    teams,
    onTeamSelect,
    isFavorite,
    favPlayerList,
    favTeamList,
    onFavoriteToggle
}) => {
    const history = useHistory();
    const location = useLocation();
    const [addRecentItem, setAddRecentItem] = useState(null);

    const handleFavoriteToggle = useCallback(
        (deleteId, newFavorite) => {
            onFavoriteToggle(deleteId, newFavorite);
        },
        [onFavoriteToggle]
    );

    const handleTeamSelect = useCallback(
        teamId => {
            onTeamSelect(teamId);
        },
        [onTeamSelect]
    );

    const handleAddRecentData = useCallback((itemId, type) => {
        setAddRecentItem({ id: itemId, type: type });
    }, []);

    const handleRedirect = useCallback(() => {
        if (addRecentItem.type === 'TEAM') {
            handleTeamSelect(addRecentItem.id);
        } else {
            if (location.pathname.includes('/scout/players/')) {
                window.location.assign(`/scout/players/${addRecentItem.id}`);
            } else {
                history.push(`/scout/players/${addRecentItem.id}`);
            }
        }
        setAddRecentItem(null);
    }, [addRecentItem, history, location]);

    useFetchData({
        method: httpMethods.post,
        url: endpoints.recentSearch,
        initialCondition: !!addRecentItem && !!addRecentItem.id,
        payload: { itemEntityId: addRecentItem?.id, itemType: addRecentItem?.type },
        responseHandler: handleRedirect
    });

    return (
        <List>
            {isPlayers &&
                players.map(
                    ({
                        firstNameEn,
                        lastNameEn,
                        lastTeam,
                        photoHref,
                        // born, playerPosition, shoots, playerPositionAdv
                        playerProfileId
                    }) => {
                        const favPlayer =
                            isFavorite && favPlayerList?.find(item => item.itemEntityId === playerProfileId);
                        return (
                            <Row key={playerProfileId}>
                                <ControlsTab>
                                    <Img alt="" src={photoHref} />
                                    <PlayerLink onClick={() => handleAddRecentData(playerProfileId, 'SCOUT_PLAYER')}>
                                        {!!firstNameEn ? firstNameEn : ''}&nbsp;{!!lastNameEn ? lastNameEn : ''}
                                    </PlayerLink>
                                    {!!lastTeam && lastTeam !== 'null' ? lastTeam : '-'}
                                </ControlsTab>
                                {isFavorite && (
                                    <FavButtonWrap
                                        onClick={() => {
                                            handleFavoriteToggle(favPlayer?.id, {
                                                id: playerProfileId,
                                                type: 'SCOUT_PLAYER'
                                            });
                                        }}
                                    >
                                        <Star active={!!favPlayer?.id} height={20} width={20} />
                                    </FavButtonWrap>
                                )}
                            </Row>
                        );
                    }
                )}
            {isTeams &&
                teams.map(({ acronymEn, logoHref, nameEn, teamId }) => {
                    const favTeam = isFavorite && favTeamList?.find(item => item.itemEntityId === teamId);

                    return (
                        // eliteProspectsTeamId, shortName, shortNameEn
                        <Row hovered key={teamId}>
                            <ControlsTab>
                                <Img
                                    alt={acronymEn}
                                    src={logoHref}
                                    onClick={() => handleAddRecentData(teamId, 'TEAM')}
                                />
                                <span onClick={() => handleAddRecentData(teamId, 'TEAM')}>
                                    {!!nameEn ? nameEn : '-'}
                                </span>
                            </ControlsTab>
                            {isFavorite && (
                                <FavButtonWrap
                                    onClick={() => handleFavoriteToggle(favTeam?.id, { id: teamId, type: 'TEAM' })}
                                >
                                    <Star active={!!favTeam?.id} height={20} width={20} />
                                </FavButtonWrap>
                            )}
                        </Row>
                    );
                })}
        </List>
    );
};
