import { deviceTypes, mobileMaxWidth } from '../../constants/device-parameters';

export const getDeviceType = () => {
    const width =
        window.innerWidth && document.documentElement.clientWidth
            ? Math.min(window.innerWidth, document.documentElement.clientWidth)
            : window.innerWidth ||
              document.documentElement.clientWidth ||
              document.getElementsByTagName('body')[0].clientWidth;

    const isMobile =
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i) ||
        width <= mobileMaxWidth;
    return isMobile ? deviceTypes.MOBILE : deviceTypes.DESKTOP;
};

export const desktopMediaCondition = `screen and (min-width: ${mobileMaxWidth + 1}px)`;
export const mobileMediaCondition = `screen and (max-width: ${mobileMaxWidth}px)`;
