import { Component } from 'react';
import isFunction from 'lodash/isFunction';
import queryHoc from './query-hoc';
import { notificationTypes } from '../../redux/modules/notifications';
import { DEFAULT_FAILURE_NOTIFICATION, DEFAULT_SUCCESS_NOTIFICATION } from './constants';

class ActionComponent extends Component {
    actionWasInvoked = false;

    componentDidUpdate(prevProps) {
        const { processing, error, notify, onLoading } = this.props;

        if (prevProps.processing && !processing) {
            if (error) {
                const { onFailure, notifyOnFailure = true, failureNotification } = this.props;

                const errorFromResponse = error && error.response && error.response.data && error.response.data.message;

                if (notifyOnFailure || (failureNotification && notifyOnFailure !== false)) {
                    const message = failureNotification
                        ? isFunction(failureNotification)
                            ? failureNotification(error)
                            : failureNotification
                        : errorFromResponse
                        ? errorFromResponse
                        : DEFAULT_FAILURE_NOTIFICATION;
                    notify(message, notificationTypes.ERROR);
                }
                onFailure && onFailure(error);
            } else {
                const { onSuccess, data, notifyOnSuccess, successNotification } = this.props;
                if (notifyOnSuccess || (successNotification && notifyOnSuccess !== false)) {
                    const message = successNotification
                        ? isFunction(successNotification)
                            ? successNotification(data)
                            : successNotification
                        : DEFAULT_SUCCESS_NOTIFICATION;
                    notify(message, notificationTypes.SUCCESS);
                }
                onSuccess && onSuccess(data);
            }
        }

        !prevProps.processing && processing && onLoading && onLoading();
    }

    componentWillUnmount() {
        const { queryId, cancel } = this.props;
        if (this.actionWasInvoked) cancel(queryId);
    }

    fetchQuery = dynamicVariables => {
        this.actionWasInvoked = true;
        const { variables, queryId, request, query } = this.props;
        const payload = isFunction(query) ? query(variables || dynamicVariables) : query;
        request({ queryId, payload });
    };

    render() {
        const { processing = false, data, error, children } = this.props;

        if (!isFunction(children)) {
            throw new Error('Action component expect exact one function children');
        }

        return children({ processing, data, error, performAction: this.fetchQuery });
    }
}

const Action = queryHoc(ActionComponent);

export default Action;
