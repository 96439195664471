import { REQUEST, SUCCESS, FAILURE, CANCEL, CANCELLED } from './redux-types';
import omit from 'lodash/omit';
import merge from 'lodash/merge';

function request({ queryId, payload }) {
    return { type: REQUEST, payload, queryId };
}

function cancel(queryId) {
    return { type: CANCEL, queryId };
}

export const actions = { request, cancel };

export const selector = state => state.restQueries;

const initialState = {
    entities: {},
    responses: {},
    payloads: {}
};

const moduleRestQuery = (state = initialState, action) => {
    const { type } = action;
    const { entities, responses, payloads } = state;
    switch (type) {
        case REQUEST:
            return {
                entities,
                payloads: { ...payloads, [action.queryId]: action.payload },
                responses: { ...responses, [action.queryId]: { ...responses[action.queryId], processing: true } }
            };
        case SUCCESS:
            return {
                entities: action.entities ? merge({}, entities, action.entities) : entities,
                payloads,
                responses: { ...responses, [action.queryId]: { data: action.data, processing: false } }
            };
        case FAILURE:
            return {
                entities,
                payloads,
                responses: { ...responses, [action.queryId]: { error: action.error, processing: false } }
            };
        case CANCELLED:
            return {
                entities,
                responses: omit(state.responses, action.queryId),
                payloads: omit(state.payloads, action.queryId)
            };
        default:
            return state;
    }
};
export default moduleRestQuery;
