export const STORAGE_AUTH_KEY = 'auth-key';
export const STORAGE_THEME = 'theme';
export const STORAGE_DESIGN = 'design';
export const STORAGE_LANGUAGE = 'language';
export const STORAGE_METRIC_UNITS = 'metric-units';
const STORAGE_SHARED_SESSION = 'shared-session';
export const AUTH_TOKEN_NAME = 'X-Auth-Token';

const getTempKey = () => sessionStorage.getItem(STORAGE_AUTH_KEY);
export const getAuthKey = () => getTempKey() || localStorage.getItem(STORAGE_AUTH_KEY);

export const hasAuthKey = () => getAuthKey() !== null;
export const hasTemporaryKey = () => getTempKey() !== null;

export const setupAuthKey = (token, isRemembered) => {
    (isRemembered ? localStorage : sessionStorage).setItem(STORAGE_AUTH_KEY, token);
};

const setupTempKey = token => {
    sessionStorage.setItem(STORAGE_AUTH_KEY, token);
};

export const openNewTabUnderUserKey = userKey => {
    setupTempKey(userKey);
    window.open('/');
    window.sessionStorage.removeItem(STORAGE_AUTH_KEY);
};

const resetPreferencesTemp = () => {
    window.sessionStorage.removeItem(STORAGE_AUTH_KEY);
    window.sessionStorage.removeItem(STORAGE_THEME);
    window.sessionStorage.removeItem(STORAGE_DESIGN);
    window.sessionStorage.removeItem(STORAGE_LANGUAGE);
    window.sessionStorage.removeItem(STORAGE_METRIC_UNITS);
};

const resetPreferencesHard = () => {
    window.localStorage.removeItem(STORAGE_AUTH_KEY);
    window.localStorage.removeItem(STORAGE_THEME);
    window.localStorage.removeItem(STORAGE_DESIGN);
    window.localStorage.removeItem(STORAGE_LANGUAGE);
    window.localStorage.removeItem(STORAGE_METRIC_UNITS);
};

export const joinByOriginKey = () => {
    resetPreferencesTemp();
};

export const clearStoredUserData = () => {
    resetPreferencesTemp();
    resetPreferencesHard();
};

/**
 * @return {boolean} true if session is shared, which means we shouldn't request logout endpoint if it ends
 */
export const isSharedSession = () => {
    return Boolean(window.localStorage.getItem(STORAGE_SHARED_SESSION));
};

/**
 * setup shared session flag @see `isSharedSession`
 */
export const setupSharedSession = () => {
    window.localStorage.setItem(STORAGE_SHARED_SESSION, true);
};
