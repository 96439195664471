import React, { Component } from 'react';
import styleBinder from 'classnames/bind';
import Icon from '../../components/icon';
import style from './button.module.css';

const DefaultButton = ({
    className,
    color = 'primary',
    block = false,
    isLoading = false,
    outline = false,
    innerRef,
    children,
    disabled,
    withoutBorder,
    ...attrs
}) => {
    const cx = styleBinder.bind(style);

    const buttonClassname = cx(className, {
        btn: true,
        'btn-block': block,
        [`btn-${color}`]: !outline,
        [`btn-withoutBorder`]: withoutBorder,
        [`btn-outline-${color}`]: outline
    });

    return (
        <button {...attrs} ref={innerRef} className={buttonClassname} disabled={disabled || isLoading}>
            {isLoading && <Icon type="spinner" wrapped={false} pulse />}
            {isLoading && ' '}
            {children}
        </button>
    );
};

const isHotkeyMatched = (event, hotkey) =>
    hotkey.code === event.code &&
    (hotkey.ctrlKey === undefined || hotkey.ctrlKey === event.ctrlKey) &&
    (hotkey.shiftKey === undefined || hotkey.shiftKey === event.shiftKey);

class ButtonWithHotkeys extends Component {
    keyPressHandler = event => {
        const { hotkeys, onClick, disabled, isLoading } = this.props;
        const enabled = !disabled && !isLoading;

        if (enabled && onClick && hotkeys.some(hotkey => isHotkeyMatched(event, hotkey))) {
            onClick();
        }
    };

    componentDidMount() {
        if (this.props.hotkeys) {
            window.addEventListener('keypress', this.keyPressHandler, false);
        }
    }

    componentWillUnmount() {
        if (this.props.hotkeys) {
            window.removeEventListener('keypress', this.keyPressHandler, false);
        }
    }

    render = () => {
        const { hotkeys, ...restProps } = this.props;
        return <DefaultButton {...restProps} />;
    };
}

const Button = ({ hotkeysDisabled, hotkeys, ...props }) =>
    hotkeys && !hotkeysDisabled ? <ButtonWithHotkeys hotkeys={hotkeys} {...props} /> : <DefaultButton {...props} />;

export default Button;
