import { processingState, createEpisodeIdWrapper, createMediaTimeWrapper } from '../../episode-utils';
import { ppItemsSelector } from '../../../../../containers/stats-filter/selectors';
import { FAVORITE_PP_TEAM } from '../../favorite-types';
import { createSelector } from 'reselect';

const POWER_PLAY = 'POWER_PLAY';

const types = { POWER_PLAY };

const selector = ppItemsSelector;

export const createSpecialTeamsSelector = () =>
    createSelector(
        st => st.data,
        data => ({ data: data.map(d => ({ st: true, ...d })) })
    );

const ppSelector = createEpisodeIdWrapper(POWER_PLAY, createSpecialTeamsSelector());

function mapper(specialTeams) {
    if (specialTeams.processing) return processingState;
    return ppSelector(specialTeams);
}

const favoriteType = FAVORITE_PP_TEAM;

const getTime = ({ startTime, endTime }) => ({ startTime, endTime, time: startTime, hasPadding: false });

const mediaTimeWrapper = createMediaTimeWrapper(getTime);

function getTimedGroup(group, periods) {
    return mediaTimeWrapper(group, periods);
}

export const powerPlay = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
