import React from 'react';

import { ControlsTab, TabButton } from '../top-search-panel.styled';

export const Controls = props => (
    <ControlsTab>
        <TabButton isActive={props.isPlayers} onClick={props.onPlayers}>
            Players
        </TabButton>
        <TabButton isActive={props.isTeams} onClick={props.onTeams}>
            Teams
        </TabButton>
    </ControlsTab>
);
