import React from 'react';

export const CloseIcon = ({ stroke = '#D54866' }) => (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line
            x1="0.5"
            y1="-0.5"
            x2="12.5"
            y2="-0.5"
            transform="translate(13.4351 4.24265) rotate(135)"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="0.5"
            y1="-0.5"
            x2="12.5"
            y2="-0.5"
            transform="translate(4.24268 4.94974) rotate(45)"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
