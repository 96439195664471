import isArray from 'lodash/isArray';

export const isInteger = value => parseInt(value) === value;

export const percentage = (value, precision) => `${(value * 100).toFixed(precision || 0)}%`;

export const percentageFormat = value => (isInteger(value) ? percentage(value, 0) : percentage(value, 1));

export const format = value =>
    isInteger(value) ? value : typeof value === 'number' && isFinite(value) ? value.toFixed(1) : '_';

export function integer() {
    let value = 0;
    return function(target, key, descriptor) {
        descriptor.get = () => value;
        descriptor.set = d => (value = parseInt(d) || 0);
        return descriptor;
    };
}

/**
 * @param to - excluded top limit
 * @param from - included bottom limit
 * @return {number}
 */
export const getRandomUniformInt = (to, from = 0) => from + parseInt(Math.random() * (to - from));

export const getIndexer = (transform = i => i) => {
    let localId = 0;
    return () => transform(++localId);
};

export const restrict = (v, min = 0, max = 1) =>
    Math.max(Math.min(v, ...(isArray(max) ? max : [max])), ...(isArray(min) ? min : [min]));

export const getCounterId = prefix => {
    let i = 0;
    return () => prefix + ++i;
};

export const decSecToSecFixed = (decSec = 0, fixedDigits = 3) => (decSec / 10).toFixed(fixedDigits);
