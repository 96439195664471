import { makePrefixAdder } from '../utils/type-utils';

const addPrefixTo = makePrefixAdder('WAITER_OF_PROCESS');
const IS_ACTIVE = addPrefixTo('IS_ACTIVE');
const IS_DISABLE = addPrefixTo('IS_DISABLE');
const SET_CHILDREN = addPrefixTo('SET_CHILDREN');
const SET_CHILDREN_PROCESSING = addPrefixTo('SET_CHILDREN_PROCESSING');

export const waiterTypes = {
    videoPlayer: 'videoPlayer'
};

const initialState = {
    isVisible: false,
    waiterChildren: {},
    waiterChildrenTypes: {},
    waiterChildrenIsProcessing: {}
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_ACTIVE:
            return {
                ...state,
                isVisible: true
            };
        case IS_DISABLE:
            return {
                ...state,
                isVisible: false
            };
        case SET_CHILDREN: {
            return {
                ...state,
                waiterChildren: { ...state.waiterChildren, [action.childrenId]: action.children },
                waiterChildrenTypes: { ...state.waiterChildrenTypes, [action.childrenId]: action.waiterType }
            };
        }
        case SET_CHILDREN_PROCESSING: {
            return {
                ...state,
                waiterChildrenIsProcessing: {
                    ...state.waiterChildrenIsProcessing,
                    [action.childrenId]: action.processingStatus
                }
            };
        }
        default:
            return state;
    }
};

const activateWaiter = () => {
    return { type: IS_ACTIVE };
};

const deActivateWaiter = () => {
    return { type: IS_DISABLE };
};

const setConfigWaiter = ({ children, childrenId, waiterType }) => {
    return {
        type: SET_CHILDREN,
        children: children,
        childrenId: childrenId,
        waiterType: waiterType
    };
};

const setLoadingConfigWaiter = ({ childrenId, processingStatus }) => {
    return {
        type: SET_CHILDREN_PROCESSING,
        childrenId: childrenId,
        processingStatus: processingStatus
    };
};

export const actions = {
    activateWaiter,
    deActivateWaiter,
    setConfigWaiter,
    setLoadingConfigWaiter
};

export const waiterVisibilitySelector = state => state.waiterOfProcess.isVisible;
//todo replace from children in state to configs
export const waiterChildrenSelector = state => state.waiterOfProcess.waiterChildren;

export const waiterChildrenAreExistSelector = state => {
    const waiterState = state.waiterOfProcess;

    const { waiterChildren } = waiterState;

    return Boolean(Object.values(waiterChildren).filter(children => children).length);
};

export const videoWaiterBusySelector = state => {
    const waiterState = state.waiterOfProcess;

    const { waiterChildren, waiterChildrenTypes, waiterChildrenIsProcessing } = waiterState;
    const activeChildes = Object.keys(waiterChildren).filter(childrenKey => {
        const element = waiterChildren[childrenKey];
        const elementType = waiterChildrenTypes[childrenKey];
        const progressOfElement = waiterChildrenIsProcessing[childrenKey];

        return !!element && elementType === waiterTypes.videoPlayer && progressOfElement;
    });

    return activeChildes.length > 0;
};
