import { httpMethods, useFetchData } from '../../../utils/hooks/use-fetch-data';
import * as endpoints from '../../../utils/api/endpoints';

export const useFetchFavorites = ({ initialCondition, favType, isAllTeams = false }) => {
    const { data: favItems, processing: favProcessing } = useFetchData({
        method: httpMethods.get,
        url: `${endpoints.favoriteItems}?itemType=${favType}`,
        initialCondition
    });

    const { data: allTeams, processing: teamProcessing } = useFetchData({
        method: httpMethods.get,
        url: `${endpoints.teams}`,
        initialCondition: isAllTeams
    });

    const processing = teamProcessing || favProcessing;

    return { data: { favItems, allTeams }, processing };
};
