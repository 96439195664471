import React, { useRef } from 'react';
import { connect } from 'react-redux';
import {
    waiterVisibilitySelector,
    waiterChildrenSelector,
    actions as waiterActions,
    waiterChildrenAreExistSelector
} from '../../redux/modules/waiter-of-process';

import { WaiterOfProcessWrapper, WaiterOfProcessContentWrapper } from './waiter-of-process-styled-components';
import { FullScreenDialogWrapper } from '../video-player/styled-components/playlist-styled-components';
import { useOutsideClick } from '../video-player/hooks/playlist-outside-click';

const mapStateToProps = state => {
    return {
        isVisible: waiterVisibilitySelector(state),
        waiterChildren: waiterChildrenSelector(state),
        waiterChildrenAreExist: waiterChildrenAreExistSelector(state)
    };
};

const mapDispatchToProps = {
    deactivateWaiter: waiterActions.deActivateWaiter
};

const WaiterOfProcess = ({ isVisible, deactivateWaiter, waiterChildren, waiterChildrenAreExist }) => {
    const wrapperRef = useRef(null);

    const handleClosePopup = () => {
        deactivateWaiter();
    };

    useOutsideClick({ ref: wrapperRef, callback: handleClosePopup });

    return (
        <FullScreenDialogWrapper interfaceIsHidden={!isVisible} noBackground={true}>
            <WaiterOfProcessWrapper
                ref={wrapperRef}
                style={{
                    display: !!isVisible && !!waiterChildrenAreExist ? 'grid' : 'none'
                }}
            >
                <WaiterOfProcessContentWrapper>
                    {!!waiterChildren &&
                        Object.values(waiterChildren).map((children, childrenKey) => (
                            <div style={{ width: '100%', display: children ? 'block' : 'none' }} key={childrenKey}>
                                {children}
                            </div>
                        ))}
                </WaiterOfProcessContentWrapper>
            </WaiterOfProcessWrapper>
        </FullScreenDialogWrapper>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WaiterOfProcess);
