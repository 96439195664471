import styled from 'styled-components/macro';
import Button from '../../components/button/button';

export const StyledError = styled.div`
    margin: 10px 0;
    padding: 0.75rem 1.25rem;

    border: 1px solid transparent;

    border-radius: 4px;

    color: #a94442;
    text-align: center;

    background-color: rgb(242, 222, 222);
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 96px);

    @media (max-width: 768px) {
        min-height: calc(100vh - 5px);
    }
`;

export const StyledForm = styled.form`
    width: 425px;
    margin: 5px 0;
    padding: 10px 40px 20px;

    border: none;
    border-radius: 4px;

    background-color: var(--backgroundColorSecondary);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.1);

    .darcula & {
        border: 1px solid #26333c;

        background-color: #26333c;
    }
`;

export const PseudoStyledForm = styled.div`
    width: 425px;
    margin: 5px 0;
    padding: 10px 40px 20px;

    border: none;
    border-radius: 4px;

    background-color: var(--backgroundColorSecondary);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.1);

    .darcula & {
        border: 1px solid #26333c;

        background-color: #26333c;
    }
`;

export const StyledHeader = styled.h2`
    margin: 15px 0 21px;

    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    text-transform: ${props => (props.ordinary ? '' : 'uppercase')};

    .darcula & {
        color: rgba(255, 255, 255, 0.7);
    }
`;

export const StyledP = styled.p`
    margin: 5px 0;

    font-size: 0.8em;
    text-align: center;
`;

export const StyledButton = styled(Button)`
    width: 100%;

    margin: 10px 0 0;
    padding: 18px;

    border: none;
    border-radius: 3px;

    color: #fff;
    font-weight: 600;
    font-size: 0.85rem;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;

    background-color: var(--uiPrimaryColor);
    outline: none;
    cursor: pointer;

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    user-select: none;

    &:hover,
    &:focus {
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    &:active {
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
`;

export const WithControlButtonWrap = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;
`;

export const ButtonControl = styled(Button)`
    position: absolute;
    top: 5%;
    right: 16px;

    min-width: 5em;

    color: var(--uiPrimaryColor);

    background: transparent;

    &:hover,
    &:focus {
        background: transparent;
    }

    &:active {
        color: var(--uiPrimaryColor);
    }
`;

export const FieldErrorWrapper = styled.div`
    width: 100%;
    min-height: 36px;
`;

export const FieldError = styled.span`
    color: #a94442;
    font-size: 12px;
`;
