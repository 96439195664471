import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeGetFormattedMessageProps } from '../../redux/modules/locale';

class FormattedMessage extends PureComponent {
    render() {
        //noinspection JSUnusedLocalSymbols - omit свойств, которые не относятся к span
        const { id, values, options, message, defaultMessage, dispatch, svg, ...rest } = this.props;
        if (svg) return message;
        return <span {...rest}>{message}</span>;
    }
}

FormattedMessage.propTypes = {
    id: PropTypes.string,
    values: PropTypes.object,

    options: PropTypes.object,
    message: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string
};

export default connect(makeGetFormattedMessageProps)(FormattedMessage);
