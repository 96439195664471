import { urlIsValid } from './regex-utils';

export const appendScript = scriptToAppend => {
    if (!scriptToAppend || typeof scriptToAppend !== 'string' || !urlIsValid(scriptToAppend)) {
        return;
    }
    const script = document.createElement('script');
    script.src = scriptToAppend;
    script.defer = true;
    script.async = true;

    if (document.head) {
        document.head.appendChild(script);
    }
};

export const isAnyScriptPresentOnDocument = regexExpression =>
    Array.from(document.scripts).reduce(
        (isPresent, script) => (isPresent ? isPresent : regexExpression.test(script.src)),
        false
    );
