export const MIN_INPUT_LENGTH = 1;

export const HIDE_DELAY = 300;
export const INPUT_TIMEOUT = 500;

const PLAYERS = 'PLAYERS';
const TEAMS = 'TEAMS';
export const MODE = {
    PLAYERS,
    TEAMS
};
