import { useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';
import { useDispatch, useSelector } from 'react-redux';

import { selector as userSelector } from '../../redux/modules/user';
import { setDeviceType } from '../../redux/modules/device-type';
import { actions as localeActions } from '../../redux/modules/locale';
import { getDeviceType } from '../../utils/helpers/device-utils';
import { roles } from '../../utils/permissions/role-utils';

const DEBOUNCE_RESIZE_MS = 250;

const SettingsWrapper = ({ children }) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);

    const handleRefreshDeviceType = useCallback(() => {
        const deviceType = getDeviceType();
        dispatch(setDeviceType(deviceType));
    }, []);

    const debouncedOnResize = useCallback(throttle(handleRefreshDeviceType, DEBOUNCE_RESIZE_MS), [
        handleRefreshDeviceType
    ]);

    //On resize set device type
    useEffect(() => {
        window.addEventListener('resize', debouncedOnResize);
        return () => {
            window.removeEventListener('resize', debouncedOnResize);
        };
    }, [debouncedOnResize]);

    //Trusted set eng language for clients and if user has not found
    useEffect(() => {
        if (!user || user?.account?.authorities.some(a => [roles.CLIENT].includes(a))) {
            dispatch(localeActions.changeLanguage('en'));
        }
    }, [user]);

    return children;
};

export default SettingsWrapper;
