/**
 * Стилизованный checkbox
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './checkbox.module.css';

export default class Checkbox extends PureComponent {
    static propTypes = {
        /*is checked*/
        checked: PropTypes.bool,
        /*value change handler*/
        onChange: PropTypes.func
    };

    handleChange = () => {
        const { onChange, checked, disabled } = this.props;
        !disabled && onChange && onChange({ returnValue: !checked });
    };

    render() {
        const { className, checked, label, style, disabled } = this.props;

        const checkboxClassName = cx(styles.checkbox, { [styles.checkbox_checked]: checked });
        return (
            <div
                onClick={this.handleChange}
                role="checkbox"
                aria-checked={checked}
                tabIndex="0"
                className={cx(className, { [styles.disabled]: disabled })}
                style={style}
            >
                <div className={checkboxClassName}>
                    {checked && (
                        <svg className={styles.check} viewBox="0 0 20 20">
                            <polyline
                                points="4 11 8 15 16 6"
                                fill="none"
                                stroke="#fff"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </div>
                <label style={{ margin: '0 0 0 0.5em' }}>{label}</label>
            </div>
        );
    }
}
