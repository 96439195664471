const mergeStoredStates = (prevStates, storedStates) => {
    const nextStates = { ...prevStates };

    for (const filterName of Object.keys(storedStates)) {
        nextStates[filterName] = { ...prevStates[filterName], ...storedStates[filterName] };
    }

    return nextStates;
};

export default mergeStoredStates;
