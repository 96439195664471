import {
    shots,
    createGoalsSelector,
    createScoringChancesSelector,
    createBlockedShotsSelector,
    createShotsOnGoalSelector,
    createShotsWithReboundSelector,
    createShotsWithoutReboundSelector
} from './shots';
import { FAVORITE_EVENT } from '../../favorite-types';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { getTimeForMediaTimeWrapperType } from '../helpers/episodes-selectors-helpers';

const wrapStat = stat => (!stat ? processingState : stat);

const selector = state => wrapStat(state.widgetsStats.playerShots);

const shotTypeToGameShotType = typeId => `PLAYER_${typeId}`;

const PLAYER_GOALS = shotTypeToGameShotType(shots.types.GOALS);
const PLAYER_SCORE_ATTEMPTS = shotTypeToGameShotType(shots.types.SCORE_ATTEMPTS);
const PLAYER_SCORING_CHANCES = shotTypeToGameShotType(shots.types.SCORING_CHANCES);
const PLAYER_BLOCKED_SHOTS = shotTypeToGameShotType(shots.types.BLOCKED_SHOTS);
const PLAYER_SHOTS_ON_GOAL = shotTypeToGameShotType(shots.types.SHOTS_ON_GOAL);
const PLAYER_SHOTS_WITH_REBOUND = shotTypeToGameShotType(shots.types.SHOTS_WITH_REBOUND);
const PLAYER_SHOTS_WITHOUT_REBOUND = shotTypeToGameShotType(shots.types.SHOTS_WITHOUT_REBOUND);

const types = {
    PLAYER_GOALS,
    PLAYER_SCORE_ATTEMPTS,
    PLAYER_SCORING_CHANCES,
    PLAYER_BLOCKED_SHOTS,
    PLAYER_SHOTS_ON_GOAL,
    PLAYER_SHOTS_WITH_REBOUND,
    PLAYER_SHOTS_WITHOUT_REBOUND
};

const typeToSelector = {
    [PLAYER_GOALS]: createEpisodeIdWrapper(PLAYER_GOALS, createGoalsSelector()),
    [PLAYER_SCORING_CHANCES]: createEpisodeIdWrapper(PLAYER_SCORING_CHANCES, createScoringChancesSelector()),
    [PLAYER_BLOCKED_SHOTS]: createEpisodeIdWrapper(PLAYER_BLOCKED_SHOTS, createBlockedShotsSelector()),
    [PLAYER_SHOTS_ON_GOAL]: createEpisodeIdWrapper(PLAYER_SHOTS_ON_GOAL, createShotsOnGoalSelector()),
    [PLAYER_SHOTS_WITH_REBOUND]: createEpisodeIdWrapper(PLAYER_SHOTS_WITH_REBOUND, createShotsWithReboundSelector()),
    [PLAYER_SHOTS_WITHOUT_REBOUND]: createEpisodeIdWrapper(
        PLAYER_SHOTS_WITHOUT_REBOUND,
        createShotsWithoutReboundSelector()
    ),
    [PLAYER_SCORE_ATTEMPTS]: createEpisodeIdWrapper(PLAYER_SCORE_ATTEMPTS, s => s)
};

function mapper(playerShots, type) {
    if (playerShots.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Player Shots');
    return typeToSelector[type](playerShots);
}

const typeToMediaTimeWrapper = Object.keys(typeToSelector)
    .map(key => ({ key, wrapper: createMediaTimeWrapper(getTimeForMediaTimeWrapperType) }))
    .reduce((a, d) => {
        a[d.key] = d.wrapper;
        return a;
    }, {});

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const favoriteType = FAVORITE_EVENT;

export const playerShots = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
