/* eslint-disable eqeqeq */
import { modules } from './permissions/module-masks';

/**
 * this feature means that view data related only to their team, they don't need team based filters
 */
export const SINGLE_TEAM_VIEW = 0;

/**
 * this feature means that clients are not trusted
 */
export const UNTRUSTED = 1;

/**
 * this feature means that for / against colors are inverted in visualizations
 */
export const TOGGLE_COLOR_SCHEME = 2;

/**
 * this feature means that client are able to use unreleased features
 */
export const BETA_FEATURES_ENABLED = 3;

/**
 * this feature means that only shifts & home pages are available for this account
 */
export const SHIFTS_ONLY = 4;

/**
 * this feature means that account is branded for MOCAP
 */
export const BRANDED_BY_MOCAP = 5;

/**
 * this feature means that account is branded for HockeyTech
 */
export const BRANDED_BY_HOCKEYTECH = 6;

/**
 * this feature means that only goalie & home pages are available for this account
 */
export const GOALTENDER_ACCOUNT = 8;

/**
 * this feature means that icePlayer must be hidden for this account
 */
export const VIDEO_PLAYER_HIDDEN = 9;

/**
 * this feature means that icePlayer must be hidden in SCOUT product
 */
export const SCOUT_VIDEO_PLAYER_HIDDEN = 10;

/**
 * this feature means that available more metrics in SCOUT product
 */
export const SCOUT_METRICS_EXTENDED_MODE = 11;

/**
 * this feature means that account is player's
 */
export const NAHL_PLAYER_ACCOUNT = 12;

/**
 * this feature means that account is leagues's
 */
export const NAHL_LEAGUE_ACCOUNT = 13;

/**
 * this feature means that account is scout lite
 */
export const SCOUT_LITE = 14;

/**
 * this feature means that account connect with "The Prospect Exchange" integration
 */
export const PLAYERS_VIEWER_ACCOUNT = 15;

/**
 * @return {Boolean} true if featureMask contains feature, otherwise false
 */
export const isValidMask = mask => Number.isSafeInteger(mask);

/**
 *Important part of feature mechanic - compare like byte
 *
 * @return {Boolean} on compare by bytes and feature from user profile featureMask
 * */
export const hasFeature = (featuresMask, feature) => {
    return (featuresMask & (1 << feature)) !== 0;
};

// todo make function correct
export const toFeatures = mask => {
    const result = Object.entries(modules).find(pair => hasFeature(mask, pair[1]));

    if (!result) {
        return null;
    }

    const [moduleName, shift] = result;

    if (mask ^ (1 << shift)) {
        return `FULL [${mask}]`;
    }

    return `${moduleName} [${mask}]`;
};

export const getFeatureMask = (...features) => {
    if (features.every(feature => Number.isSafeInteger(feature))) {
        return features.reduce((mask, feature) => {
            return mask | (1 << feature);
        }, 0);
    }
};
