import React, { useCallback, useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import request from '../../../utils/api/common-requests/common-requests';
import StyledModal from '../../../components/modal/styled-modal';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';
import * as endpoints from '../../../utils/api/endpoints';
import { IconEyeOff } from '../../../components/svg-icons/eye-off';
import { IconEyeOn } from '../../../components/svg-icons/eye-on';
import { checkPasswordFieldCondition, checkMinFieldCondition } from '../../../utils/helpers/string-utils';
import { notificationTypes, notify } from '../../../redux/modules/notifications';
import { AUTH_TOKEN_NAME, getAuthKey } from '../../../utils/api/auth-utils';

import styles from './edit-email-modal.module.css';

import {
    WithControlButtonWrap,
    ButtonControl,
    FieldError,
    FieldErrorWrapper,
    FormContainer,
    FieldContent
} from './edit-password-modal-styled-components';

const EditPasswordModal = ({ isOpen, onClose }) => {
    const [isError, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const [isVisibleOldPass, setOldPassVisibility] = useState(false);
    const [isVisibleNewPass, setNewPassVisibility] = useState(false);
    const [isVisibleNewPassConfirm, setNewPassConfirmVisibility] = useState(false);

    const dispatch = useDispatch();

    const handleOldPasswordView = useCallback(
        e => {
            e.preventDefault();
            setOldPassVisibility(!isVisibleOldPass);
        },
        [isVisibleOldPass]
    );

    const handleNewPasswordView = useCallback(
        e => {
            e.preventDefault();
            setNewPassVisibility(!isVisibleNewPass);
        },
        [isVisibleNewPass]
    );

    const handleNewPasswordConfirmView = useCallback(
        e => {
            e.preventDefault();
            setNewPassConfirmVisibility(!isVisibleNewPassConfirm);
        },
        [isVisibleNewPass]
    );

    const handleFormSubmit = useCallback(
        async values => {
            const { oldPassword, newPassword } = values;

            setError(null);
            setLoading(true);

            try {
                const { status } = await request.post(
                    endpoints.changePassword,
                    { oldPassword, newPassword },
                    {
                        headers: { 'X-Requested-With': 'XMLHttpRequest', [AUTH_TOKEN_NAME]: getAuthKey() }
                    }
                );

                setLoading(false);

                if (status === 200) {
                    dispatch(notify('Success'));
                    onClose();
                } else {
                    // eslint-disable-next-line
                    console.error('Incorrect response from server.');

                    setError(true);
                }
            } catch (e) {
                // eslint-disable-next-line
                console.error(e);

                setError(true);
                setLoading(false);
            }
        },
        [dispatch, onClose]
    );

    const handleValidateCheck = useCallback(values => {
        const errors = {};

        const { oldPassword, newPassword, newPasswordConfirm } = values;

        const newPasswordPrecondition = checkPasswordFieldCondition(newPassword);

        if (newPasswordPrecondition) {
            errors.newPassword = newPasswordPrecondition;
        }

        const oldPasswordPreconditionConfirm = checkMinFieldCondition(oldPassword);

        if (oldPasswordPreconditionConfirm) {
            errors.oldPassword = oldPasswordPreconditionConfirm;
        }

        const newPasswordPreconditionConfirm = checkPasswordFieldCondition(newPasswordConfirm);

        if (newPasswordPreconditionConfirm) {
            errors.newPasswordConfirm = newPasswordPreconditionConfirm;
        }

        if (newPassword !== newPasswordConfirm) {
            errors.passwordConfirm = 'Must be equal';
        }

        return errors;
    }, []);

    useEffect(() => {
        if (!isError) {
            return;
        }

        dispatch(notify('Error on set password.', notificationTypes.ERROR));
        setError(false);
    }, [isError, dispatch]);

    return (
        <StyledModal
            contentCss={`border-radius: 8px; box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);`}
            isOpen={isOpen}
            disableScroll
            onRequestClose={onClose}
        >
            <div className={styles.main}>
                <Form
                    onSubmit={handleFormSubmit}
                    validate={handleValidateCheck}
                    render={({ handleSubmit, submitting, errors }) => (
                        <form onSubmit={handleSubmit}>
                            <FormContainer>
                                <Field name="oldPassword">
                                    {({ input, meta }) => (
                                        <FieldContent>
                                            <WithControlButtonWrap>
                                                <Input
                                                    {...input}
                                                    type={isVisibleOldPass ? 'text' : 'password'}
                                                    placeholder="Old password"
                                                />

                                                <ButtonControl onClick={handleOldPasswordView}>
                                                    {isVisibleOldPass ? <IconEyeOff /> : <IconEyeOn />}
                                                </ButtonControl>
                                            </WithControlButtonWrap>
                                            {meta.error && meta.touched && (
                                                <FieldErrorWrapper>
                                                    <FieldError>{meta.error}</FieldError>
                                                </FieldErrorWrapper>
                                            )}
                                        </FieldContent>
                                    )}
                                </Field>

                                <Field name="newPassword">
                                    {({ input, meta }) => (
                                        <FieldContent>
                                            <WithControlButtonWrap>
                                                <Input
                                                    {...input}
                                                    type={isVisibleNewPass ? 'text' : 'password'}
                                                    placeholder="New password"
                                                />

                                                <ButtonControl onClick={handleNewPasswordView}>
                                                    {isVisibleNewPass ? <IconEyeOff /> : <IconEyeOn />}
                                                </ButtonControl>
                                            </WithControlButtonWrap>
                                            {meta.error && meta.touched && (
                                                <FieldErrorWrapper>
                                                    <FieldError>{meta.error}</FieldError>
                                                </FieldErrorWrapper>
                                            )}
                                        </FieldContent>
                                    )}
                                </Field>

                                <Field name="newPasswordConfirm">
                                    {({ input, meta }) => (
                                        <FieldContent>
                                            <WithControlButtonWrap>
                                                <Input
                                                    {...input}
                                                    type={isVisibleNewPassConfirm ? 'text' : 'password'}
                                                    placeholder="Confirm new password"
                                                />

                                                <ButtonControl onClick={handleNewPasswordConfirmView}>
                                                    {isVisibleNewPassConfirm ? <IconEyeOff /> : <IconEyeOn />}
                                                </ButtonControl>
                                            </WithControlButtonWrap>
                                            {meta.error && meta.touched && (
                                                <FieldErrorWrapper>
                                                    <FieldError>{meta.error}</FieldError>
                                                </FieldErrorWrapper>
                                            )}
                                        </FieldContent>
                                    )}
                                </Field>

                                <Button
                                    className={styles.save}
                                    type="submit"
                                    disabled={submitting || Object.keys(errors).length > 0}
                                    isLoading={isLoading}
                                >
                                    <span className={styles.buttonText}>Save</span>
                                </Button>

                                <Button className={styles.cancel} onClick={onClose}>
                                    <span className={styles.buttonText}>Cancel</span>
                                </Button>
                            </FormContainer>
                        </form>
                    )}
                />
            </div>
        </StyledModal>
    );
};

export default EditPasswordModal;
