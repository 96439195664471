import { denormalize } from 'normalizr';
import { gameSchema } from '../../utils/schemas';
import { createSkipMarkedArgsSelector, markSelector } from '../../utils/selectors';

export const dateComparator = (a, b) => 2 * (b.date > a.date) - 1;

export const fetchedGamesSelector = createSkipMarkedArgsSelector(
    state => state.statsFilter.fetchedGameIds,
    markSelector(state => state.entities),
    (gameIds, entities) => denormalize(gameIds, [gameSchema], entities).sort(dateComparator)
);

export const statFilterGames = createSkipMarkedArgsSelector(
    state => state.statsFilter.gameIds,
    markSelector(state => state.entities),
    state => state.statsFilter.fetchedGameIds,
    (state, props) => props && props.games,
    (gameIds, entities, _, games) => games || denormalize(gameIds || [], [gameSchema], entities).sort(dateComparator)
);
