import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TooltipContent from './tooltip-content';
import { placements } from './attachments';

const DEFAULT_DELAYS = {
    show: 0,
    hide: 250
};

const propTypes = {
    placement: PropTypes.oneOf(placements),
    tether: PropTypes.object,
    target: PropTypes.string.isRequired,
    delay: PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number })
};

const defaultProps = {
    placement: 'bottom',
    delay: DEFAULT_DELAYS,
    autohide: true
};

class MountedTooltip extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: false };

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.mouseOverTooltip = this.mouseOverTooltip.bind(this);
        this.mouseLeaveTooltip = this.mouseLeaveTooltip.bind(this);
        this.mouseOverTooltipContent = this.mouseOverTooltipContent.bind(this);
        this.mouseLeaveTooltipContent = this.mouseLeaveTooltipContent.bind(this);
    }

    componentDidMount() {
        const { target } = this.props;
        this._target = document.querySelector(target);
        this.addTargetEvents();
    }

    componentWillUnmount() {
        this.removeTargetEvents();
    }

    mouseOverTooltip() {
        if (this._hideTimeout) {
            this.clearHideTimeout();
        }
        this._showTimeout = setTimeout(this.show, this.getDelay('show'));
    }

    mouseLeaveTooltip() {
        if (this._showTimeout) {
            this.clearShowTimeout();
        }
        this._hideTimeout = setTimeout(this.hide, this.getDelay('hide'));
    }

    mouseOverTooltipContent() {
        if (this._hideTimeout) {
            this.clearHideTimeout();
        }
    }

    mouseLeaveTooltipContent() {
        if (this._showTimeout) {
            this.clearShowTimeout();
        }
        this._hideTimeout = setTimeout(this.hide, this.getDelay('hide'));
    }

    getDelay(key) {
        const { delay } = this.props;
        return isNaN(delay[key]) ? DEFAULT_DELAYS[key] : delay[key];
    }

    show() {
        if (this.state.isOpen) return;
        this.clearShowTimeout();
        this.toggle();
    }

    hide() {
        if (!this.state.isOpen) return;
        this.clearHideTimeout();
        this.toggle();
    }

    clearShowTimeout() {
        clearTimeout(this._showTimeout);
        this._showTimeout = null;
    }

    clearHideTimeout() {
        clearTimeout(this._hideTimeout);
        this._hideTimeout = null;
    }

    handleDocumentClick(e) {
        if (e.target !== this._target && !this._target.contains(e.target)) return;
        if (this._hideTimeout) this.clearHideTimeout();
        if (!this.props.isOpen) this.toggle();
    }

    addTargetEvents() {
        this._target.addEventListener('mouseover', this.mouseOverTooltip, true);
        this._target.addEventListener('mouseout', this.mouseLeaveTooltip, true);
        document.addEventListener('click', this.handleDocumentClick, true);
    }

    removeTargetEvents() {
        this._target.removeEventListener('mouseover', this.mouseOverTooltip, true);
        this._target.removeEventListener('mouseout', this.mouseLeaveTooltip, true);
        document.removeEventListener('click', this.handleDocumentClick, true);
    }

    toggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const { children, target, placement, tether } = this.props;
        if (!this.state.isOpen) return null;
        return (
            <TooltipContent
                opened={this.state.isOpen}
                target={target}
                placement={placement}
                tether={tether}
                onMouseOverTooltipContent={this.mouseOverTooltipContent}
                onMouseLeaveTooltipContent={this.mouseLeaveTooltipContent}
                onToggle={this.toggle}
            >
                {children}
            </TooltipContent>
        );
    }
}

MountedTooltip.propTypes = propTypes;
MountedTooltip.defaultProps = defaultProps;

export default MountedTooltip;
