/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import playerHighlightedReducer from 'components/svg-icons/players-module';
import localeReducer from 'redux/modules/locale';
import userReducer, { unitsReducer } from 'redux/modules/user';
import { reducer as designReducer } from 'redux/modules/design';
import { reducer as deviceTypeReducer } from 'redux/modules/device-type';
import { reducer as redirectPropsReducer } from 'redux/modules/redirect-props';
import { reducer as configurationReducer } from 'redux/modules/configurator';
import { reducer as themeReducer } from 'redux/modules/theme';
import { reducer as localConfigurationReducer } from 'redux/modules/local-configuration';
import { reducer as preferencesReducer } from 'redux/modules/preferences';
import { reducer as ratingSystemReducer } from 'redux/modules/rating-system';
import { reducer as notificationsReducer } from 'redux/modules/notifications';
import { reducer as favouritesReducer } from 'redux/modules/favourites';
import { reducer as whatsNewReducer } from 'redux/modules/whats-new';
import { reducer as secondaryNavReducer } from 'redux/modules/secondary-nav';
import { reducer as tableBuilderReducer } from 'redux/modules/table-builder';
import { reducer as versionSwitcherReducer } from 'redux/modules/version-switcher';
import { reducer as prospectIntegrationReducer } from 'redux/modules/prospect-integration';
import { resettableBy } from 'redux/utils/resettable';
import {
    actionTypes as productActionTypes,
    reducer as productReducer
} from 'containers/product-initializer/product-module';
import tooltipReducer from 'containers/tooltip/module';
import { reducer as entitiesReducer } from 'redux/modules/entities';
import { reducer as statsFilterReducer } from 'containers/stats-filter/module';
import statsFilterSeasonReducer from 'containers/stats-filter/season-module';
import widgetsStatsReducer from 'containers/widgets-stats/module';
import restQueriesReducer from 'containers/rest-query/module';
import icePlayerReducer from 'widgets/ice-player/ice-player-container/module';
import teamsReducer from 'pages/support/module';
import { LOGOUT } from 'containers/app/module';
import { reducer as warningToUserReducer } from 'redux/modules/warning-for-user';
import { reducer as waiterOfProcessReducer } from 'redux/modules/waiter-of-process';

const chartsReducer = combineReducers({ highlightedPlayers: playerHighlightedReducer });

const productPersistentReducers = {
    locale: localeReducer,
    user: userReducer,
    portalUnits: unitsReducer,
    design: designReducer,
    deviceType: deviceTypeReducer,
    redirectProps: redirectPropsReducer,
    configurator: configurationReducer,
    theme: themeReducer,
    localConfiguration: localConfigurationReducer,
    versionSwitcher: versionSwitcherReducer,
    preferences: preferencesReducer,
    ratingSystem: ratingSystemReducer,
    notifications: notificationsReducer,
    favourites: favouritesReducer,
    whatsNew: whatsNewReducer,
    secondaryNav: secondaryNavReducer,
    tableBuilder: tableBuilderReducer,
    restQueries: restQueriesReducer,
    warningToUser: warningToUserReducer,
    waiterOfProcess: waiterOfProcessReducer,
    prospectIntegration: prospectIntegrationReducer
};

const productResettable = reducer => resettableBy(productActionTypes.INITIALIZE_PRODUCT)(reducer);

const productPrivateReducers = {
    tooltip: productResettable(tooltipReducer),
    entities: productResettable(entitiesReducer),

    //Fetching
    statsFilter: productResettable(statsFilterReducer),
    statsFilterSeason: productResettable(statsFilterSeasonReducer),
    widgetsStats: productResettable(widgetsStatsReducer),

    //OTHER
    charts: productResettable(chartsReducer),
    icePlayer: productResettable(icePlayerReducer),
    teams: productResettable(teamsReducer)
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createRootReducer(injectedReducers = {}) {
    const rootReducer = combineReducers({
        product: productReducer,
        ...productPersistentReducers,
        ...productPrivateReducers,
        ...injectedReducers
    });

    return resettableBy(LOGOUT)(rootReducer);
}
