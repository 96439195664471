import {
    LITE,
    PRE_SCOUT,
    PREMIUM,
    PREMIUM_PLUS,
    PRO,
    productToPath,
    SCOUT,
    SUPER_LITE
} from '../containers/product-initializer/products';
import { roles } from '../utils/permissions/role-utils';
import { whatsNewIndex } from './page-names';

export const AVAILABLE_CLIENT_PRODUCTS = [PRO, SUPER_LITE, PREMIUM, SCOUT];
export const HIDDEN_INDEXES = [/*serviceIndex,*/ whatsNewIndex];
export const LOCATION_LINKS = [
    ...[
        {
            to: productToPath[PRO].path,
            children: 'PROFESSIONAL',
            isVisible: ({ hasAnyRole, hasProduct /*, shiftsOnly, goalieOnly, prospectMode*/ }) =>
                hasAnyRole([roles.CLIENT, roles.ANALYST]) && hasProduct(PRO),
            //(hasProduct(PRO) || !(shiftsOnly || goalieOnly || prospectMode)),
            isEnabled: ({ hasProduct }) => hasProduct(PRO),
            product: PRO
        },
        {
            to: productToPath[PREMIUM_PLUS].path,
            children: 'PREMIUM+',
            isVisible: ({ hasAnyRole, trusted, hasProduct, shiftsOnly, goalieOnly, prospectMode }) =>
                hasAnyRole([roles.CLIENT, roles.ANALYST]) &&
                trusted &&
                (hasProduct(PREMIUM_PLUS) || !(shiftsOnly || goalieOnly || prospectMode)),
            isEnabled: ({ hasProduct }) => hasProduct(PREMIUM_PLUS),
            product: PREMIUM_PLUS
        },
        {
            to: productToPath[PREMIUM].path,
            children: 'PREMIUM',
            isVisible: ({ hasAnyRole, trusted, hasProduct, shiftsOnly, goalieOnly, prospectMode }) =>
                hasAnyRole([roles.CLIENT, roles.ANALYST]) &&
                trusted &&
                (hasProduct(PREMIUM) ||
                    hasProduct(PREMIUM_PLUS) ||
                    hasProduct(LITE) ||
                    !(shiftsOnly || goalieOnly || prospectMode)),
            isEnabled: ({ hasProduct }) =>
                hasProduct(PREMIUM) || hasProduct(PREMIUM) || hasProduct(PREMIUM_PLUS) || hasProduct(LITE),
            product: PREMIUM
        },
        //todo: change PREMIUM to PRE_SCOUT once its implemented
        {
            to: productToPath[SUPER_LITE].path,
            children: 'SUPER-LITE',
            isVisible: ({ hasAnyRole, trusted, hasProduct }) =>
                hasAnyRole([roles.CLIENT, roles.ANALYST]) && hasProduct(SUPER_LITE) && trusted,
            product: SUPER_LITE
        },
        {
            to: productToPath[PRE_SCOUT].path,
            children: 'PRE-SCOUT',
            isVisible: ({ hasAnyRole, trusted, hasProduct }) =>
                hasAnyRole([roles.CLIENT, roles.ANALYST]) && hasProduct(PRE_SCOUT) && trusted,
            product: PRE_SCOUT
        },
        {
            to: productToPath[SCOUT].path,
            children: 'SCOUT',
            isVisible: ({ hasAnyRole, hasProduct }) => hasAnyRole([roles.CLIENT, roles.ANALYST]) && hasProduct(SCOUT),
            isEnabled: ({ hasProduct }) => hasProduct(SCOUT),
            product: SCOUT
        }
    ].filter(({ product }) => AVAILABLE_CLIENT_PRODUCTS.includes(product)),
    {
        to: '/schedule-admin',
        children: 'ADMIN',
        isVisible: ({ hasAnyRole, accessAnalystToAdmin }) =>
            !(hasAnyRole([roles.CLIENT]) || (hasAnyRole([roles.ANALYST]) && !accessAnalystToAdmin)) //&&
        //!hasAnyRole([roles.NFT_MODERATOR, roles.NFT_DESIGNER, roles.NFT_VERIFIER, roles.NFT_MANAGER])
    }
];
