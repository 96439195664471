import React from 'react';
import styles from './icon.module.css';
import cx from 'classnames';

const Search = ({ style, disableDefaultStyle, color, ...rest }) => {
    return (
        <svg
            className={!disableDefaultStyle && cx(styles.icon, styles.search)}
            viewBox="0 0 64 64"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            fill={disableDefaultStyle ? 'currentColor' : 'none'}
            {...rest}
        >
            <g transform="translate(-26 82)" fill={color || (disableDefaultStyle ? 'currentColor' : '#D6DAE3')}>
                <path
                    transform="translate(26 -82)"
                    d="M 44.9573 25.2885C 44.9573 36.1513 36.1513 44.9573 25.2885 44.9573L 25.2885 50.577C 39.2549 50.577 50.577 39.255 50.577 25.2885L 44.9573 25.2885ZM 25.2885 44.9573C 14.4257 44.9573 5.61966 36.1513 5.61966 25.2885L 0 25.2885C 0 39.255 11.322 50.577 25.2885 50.577L 25.2885 44.9573ZM 5.61966 25.2885C 5.61966 14.4257 14.4257 5.61967 25.2885 5.61967L 25.2885 0C 11.322 0 0 11.3221 0 25.2885L 5.61966 25.2885ZM 25.2885 5.61967C 36.1513 5.61967 44.9573 14.4257 44.9573 25.2885L 50.577 25.2885C 50.577 11.3221 39.2549 0 25.2885 0L 25.2885 5.61967Z"
                />
                <path
                    transform="translate(64.1738 -43.8262)"
                    d="M -7.86321e-08 7.94745L 17.8789 25.8262L 25.8262 17.8787L 7.94736 6.8921e-08L -7.86321e-08 7.94745Z"
                />
            </g>
        </svg>
    );
};
export default Search;
