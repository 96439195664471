import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Input from 'components/input/input';
import Recaptcher, { CHECK_RECAPTCHA, CHINA_USERNAMES, recaptchaKey } from 'components/recaptcher/recaptcher';
import FormattedMessage from 'components/formatted-message';
import { RecaptcherWrapper } from 'components/recaptcher/recaptcher-styled-components';
import PropsFormatter, { tr } from 'containers/props-formatter';
import { languageSelectorForRecaptcha } from 'redux/modules/locale';
import { notificationTypes, notify } from 'redux/modules/notifications';
import { setupAuthKey } from 'utils/api/auth-utils';
import { encodeSearchUrl } from 'utils/api/path-utils';
import * as endpoints from 'utils/api/endpoints';
import request from 'utils/api/common-requests/common-requests';
import { notEmptyPredicate } from 'utils/helpers/check-condition-utils';
import { transformSecToHhMmSs } from 'utils/helpers/time-utils';

import { StyledButton, StyledContainer, StyledError, StyledForm, StyledHeader } from './auth-styled-components';
import { AuthOptionLinks, RESET_PASSWORD_AUTH_LINK, SIGN_UP_AUTH_LINK } from './auth-option-links';

const INITIAL_CAPTCHA_VALUE = '';

const Login = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [validationMode, setValidationMode] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(INITIAL_CAPTCHA_VALUE);

    const [attemptCount, setAttempts] = useState(0);
    const [waiterTimeS, setWaiterTimeS] = useState(0);

    const captchaRef = useRef();

    const { hl } = props;

    useEffect(
        () => {
            window.addEventListener('unhandledrejection', function(event) {
                /*eslint-disable*/
                console.error('unhandledrejection!!! Reason: ' + event.reason);
                console.error(event);
                /*eslint-enable*/
            });
        },
        /*eslint-disable*/ [] /*eslint-enable*/
    );

    //Handle check error on attempt count
    useEffect(() => {
        if (!error) {
            return;
        }

        const { notify } = props;

        if (
            !!error?.response?.data?.message &&
            +error.response.status === 401 &&
            !Object(error.response.data).hasOwnProperty('attempt') &&
            !Object(error.response.data).hasOwnProperty('wait_for_retry')
        ) {
            notify(error?.response?.data?.message, notificationTypes.ERROR);
            return;
        }

        if (
            (!error ||
                !error.response ||
                !error.response.status ||
                +error.response.status !== 401 ||
                !error.response.data ||
                !Object(error.response.data).hasOwnProperty('attempt') ||
                !Object(error.response.data).hasOwnProperty('wait_for_retry')) &&
            !CHINA_USERNAMES.includes(username)
        ) {
            /*eslint-disable*/
            console.error('Precondition failed for handle no auth.');
            /*eslint-enable*/

            return;
        }

        const { attempt, wait_for_retry } = error.response.data;

        !!attempt && setAttempts(+attempt);

        if (+wait_for_retry === 0) {
            setWaiterTimeS(0);
            return;
        }

        setWaiterTimeS(+wait_for_retry);

        //Unmount effect
        return () => {
            setAttempts(0);
            setWaiterTimeS(0);
            !!captchaRef && !!captchaRef.current && captchaRef.current._resetRecaptcha();
        };
    }, [error]);

    //Notification effect
    useEffect(
        () => {
            const { notify } = props;

            if (attemptCount > 0 && attemptCount < 3) {
                notify(`Attempts left before the temporary blocking occurs: ${attemptCount}.`, notificationTypes.ERROR);
                return;
            }
            if (waiterTimeS > 0) {
                notify(
                    `Until the end of the temporary blocking of authorization attempts, please wait ${transformSecToHhMmSs(
                        waiterTimeS,
                        true
                    )} (mm:ss).`,
                    notificationTypes.ERROR
                );
            }
        },
        /*eslint-disable*/ [attemptCount, waiterTimeS] /*eslint-enable*/
    );

    const submitIsEnable = useMemo(() => notEmptyPredicate(username) && notEmptyPredicate(password), [
        username,
        password
    ]);

    const loginIsDisable = useMemo(() => {
        return (
            (!!validationMode && !submitIsEnable) ||
            (!CHINA_USERNAMES.includes(username) &&
                CHECK_RECAPTCHA &&
                (!recaptchaToken || recaptchaToken === INITIAL_CAPTCHA_VALUE)) ||
            (!!validationMode && CHINA_USERNAMES.includes(username) && !submitIsEnable)
        );
    }, [validationMode, submitIsEnable, recaptchaToken, username]);

    const handleReset = () => {
        setAttempts(0);
        setWaiterTimeS(0);
    };

    const handleCaptchaReady = resp => {
        setRecaptchaToken(resp);
    };
    const handleCaptchaError = respError => {
        /*eslint-disable*/
        console.error(`handleCaptchaError: ` + respError);
        /*eslint-enable*/
        setRecaptchaToken(INITIAL_CAPTCHA_VALUE);
    };

    const handleCaptchaExpire = () => {
        /*eslint-disable*/
        console.error('Captcha expire!');
        /*eslint-enable*/
        setRecaptchaToken(INITIAL_CAPTCHA_VALUE);
    };

    const onSubmit = useCallback(
        e => {
            e.preventDefault();

            if (!submitIsEnable) {
                setValidationMode(true);
                return;
            }

            if (
                CHECK_RECAPTCHA &&
                (!recaptchaToken || recaptchaToken === INITIAL_CAPTCHA_VALUE) &&
                !CHINA_USERNAMES.includes(username)
            ) {
                return;
            }

            setLoading(true);
            setError(null);
            setRedirectToReferrer(false);

            request
                .post(endpoints.login, encodeSearchUrl({ username, password, recaptchaToken }))
                .then(response => {
                    setupAuthKey(response.data.token, true);
                    handleReset();
                    setLoading(false);
                    setError(null);

                    !CHINA_USERNAMES.includes(username) &&
                        !!captchaRef &&
                        !!captchaRef.current &&
                        captchaRef.current._resetRecaptcha();

                    setRedirectToReferrer(true);
                })
                .catch(e => {
                    setLoading(false);
                    setError(e);
                    handleCaptchaExpire();
                    !CHINA_USERNAMES.includes(username) &&
                        !!captchaRef &&
                        !!captchaRef.current &&
                        captchaRef.current._resetRecaptcha();

                    setRedirectToReferrer(false);
                });
        },
        /*eslint-disable*/ [submitIsEnable, username, password, recaptchaToken] /*eslint-enable*/
    );

    const { from } = props.location.state || { from: { pathname: '/' } };

    if (redirectToReferrer) {
        return <Redirect to={from} />;
    }

    return (
        <StyledContainer>
            <StyledForm>
                <StyledHeader>
                    <FormattedMessage id="login.title" />
                </StyledHeader>

                <PropsFormatter
                    validationPredicate={validationMode && notEmptyPredicate}
                    placeholder={tr('login.username')}
                >
                    <Input type="text" value={username} onChange={input => setUsername(input.target.value)} unbounded />
                </PropsFormatter>

                <PropsFormatter
                    validationPredicate={validationMode && notEmptyPredicate}
                    placeholder={tr('login.password')}
                >
                    <Input
                        type="password"
                        value={password}
                        onChange={input => setPassword(input.target.value)}
                        unbounded
                    />
                </PropsFormatter>

                <StyledButton disabled={loginIsDisable} onClick={onSubmit} isLoading={loading}>
                    <FormattedMessage id="login.button" />
                </StyledButton>

                {error && (
                    <StyledError>
                        <FormattedMessage id="login.error" />
                    </StyledError>
                )}
            </StyledForm>

            {!!CHECK_RECAPTCHA && !CHINA_USERNAMES.includes(username) && (
                <RecaptcherWrapper>
                    <Recaptcher
                        ref={captchaRef}
                        recaptchaKey={recaptchaKey}
                        onVerify={handleCaptchaReady}
                        onError={handleCaptchaError}
                        onExpire={handleCaptchaExpire}
                        hl={hl}
                        id={`Recaptcher-Login`}
                    />
                </RecaptcherWrapper>
            )}

            <AuthOptionLinks linksToShow={[SIGN_UP_AUTH_LINK, RESET_PASSWORD_AUTH_LINK]} />
        </StyledContainer>
    );
};

const mapStateToProps = state => {
    const hl = languageSelectorForRecaptcha(state);

    return {
        hl
    };
};

const mapDispatchToProps = {
    notify
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
