import React from 'react';
import { Content as ContentLayout } from 'components/layouts/content';
import { connect } from 'react-redux';
import { isProspectMode } from 'selectors/user/user-features-selector';
import { NavBarWrapper } from './nav-bar.styled';

const NavBar = ({ children, prospectMode, ...properties }) => {
    return (
        <NavBarWrapper prospectMode={prospectMode} {...properties}>
            {!!prospectMode ? (
                <ContentLayout>{children}</ContentLayout>
            ) : (
                <ContentLayout inline fixed>
                    {children}
                </ContentLayout>
            )}
        </NavBarWrapper>
    );
};

const mapStateToProps = state => ({
    prospectMode: isProspectMode(state)
});

export default connect(mapStateToProps)(NavBar);
