import { makeBundle, makePrefixAdder } from '../../redux/utils/type-utils';
import requestBuilder from '../../redux/utils/request-builder';
import { teamSchema } from '../../utils/schemas';

const addPrefixTo = makePrefixAdder('support');
const teamsBundle = makeBundle(addPrefixTo('TEAMS'));

export function getTeams() {
    return requestBuilder(teamsBundle)
        .addSchema([teamSchema])
        .get('/api/teams');
}

const supportModule = (state = { teamIds: [] }, action) => {
    switch (action.type) {
        case teamsBundle.REQUEST:
            return {
                ...state,
                processing: true
            };
        case teamsBundle.SUCCESS:
            return {
                ...state,
                processing: false,
                teamIds: action.response.result
            };
        default:
            return state;
    }
};

export default supportModule;
