import { makePrefixAdder } from 'redux/utils/type-utils';

const addPrefixTo = makePrefixAdder('restQuery');

export const REQUEST = addPrefixTo('REQUEST');

export const SUCCESS = addPrefixTo('SUCCESS');
export const FAILURE = addPrefixTo('FAILURE');

export const CANCEL = addPrefixTo('CANCEL');
export const CANCELLED = addPrefixTo('CANCELLED');
