import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyledP } from './auth-styled-components';
import FormattedMessage from '../../components/formatted-message';

export const SIGN_UP_AUTH_LINK = 'SIGN_UP_AUTH_LINK';
export const LOGIN_AUTH_LINK = 'LOGIN_AUTH_LINK';
export const RESET_PASSWORD_AUTH_LINK = 'RESET_PASSWORD_AUTH_LINK';
export const LICENSE_AUTH_LINK = 'LICENSE_AUTH_LINK';

export const SIGN_UP_IS_AVAILABLE = false;
const SHOW_RESET_LINK = false;

export const AuthOptionLinks = ({ linksToShow }) => {
    if (!linksToShow || (Array.isArray(linksToShow) && linksToShow.length === 0)) {
        return <div />;
    }

    return (
        <Fragment>
            {linksToShow.map((linkEl, linkKey) => {
                if (linkEl === LOGIN_AUTH_LINK) {
                    return (
                        <StyledP key={`linkKey_${linkKey}`}>
                            <FormattedMessage id="signUp.haveAnAccount" />{' '}
                            <a href={'/login'}>
                                <FormattedMessage id="signUp.loginHere" />
                            </a>
                        </StyledP>
                    );
                }

                if (linkEl === SIGN_UP_AUTH_LINK) {
                    return (
                        <StyledP key={`linkKey_${linkKey}`}>
                            {!!SIGN_UP_IS_AVAILABLE && (
                                <a href={'/sign-up'}>
                                    <FormattedMessage id="signUp.title" />
                                </a>
                            )}
                        </StyledP>
                    );
                }
                if (linkEl === RESET_PASSWORD_AUTH_LINK) {
                    return (
                        <StyledP key={`linkKey_${linkKey}`}>
                            {!!SHOW_RESET_LINK && (
                                <a href={'/reset'}>
                                    <FormattedMessage id="resetPassword.title" />
                                </a>
                            )}
                        </StyledP>
                    );
                }

                if (linkEl === LICENSE_AUTH_LINK) {
                    return (
                        <StyledP key={`linkKey_${linkKey}`}>
                            <FormattedMessage id="signUp.BySingingUpYouAgreeToOur" />{' '}
                            <a href={'/license'} target="_blank" rel="noopener noreferrer">
                                <FormattedMessage id="signUp.termsAndConditions" />
                            </a>
                        </StyledP>
                    );
                }

                return <div key={`linkKey_${linkKey}`} />;
            })}
        </Fragment>
    );
};

AuthOptionLinks.propTypes = {
    linksToShow: PropTypes.array.isRequired
};
