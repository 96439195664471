import React from 'react';
import styled from 'styled-components/macro';
import { successPageContent, failurePageContent } from './content';
import Button from '../../components/button/button';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 600px;
    height: 250px;
    /* 100vh - header - footer - margin */
    margin: auto;
    padding: 20px;

    border-radius: 10px;

    text-align: center;

    background: white;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 1.5em;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    /* 100vh - header - footer */
    height: calc(100vh - 2 * 48px);
`;

const StyledButton = styled(Button)`
    width: min-content;
`;

const ReportPurchaseStatus = ({ success }) => (
    <Container>
        <Layout>
            <Title>{success ? successPageContent.title : failurePageContent.title}</Title>
            <div>{success ? successPageContent.text : failurePageContent.text}</div>
            <StyledButton
                onClick={() => window.location.assign(success ? '/' : `/player-report${window.location.search}`)}
            >
                OK
            </StyledButton>
        </Layout>
    </Container>
);

export default ReportPurchaseStatus;
