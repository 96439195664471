import { linesPKSelector } from '../../../../../pages/game-center/analytics/special-teams/private/special-teams-selector';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { ppLines, createLinesTypeSelector } from './pp-lines';

const ppTypeToPkType = typeId => `OPPONENT_${typeId}`;
const pkTypeToPPType = typeId => typeId.substring(9);

const OPPONENT_RUSH = ppTypeToPkType(ppLines.types.RUSH);
const OPPONENT_REGROUP = ppTypeToPkType(ppLines.types.REGROUP);
const OPPONENT_OZIFORMATION = ppTypeToPkType(ppLines.types.OZIFORMATION);
const OPPONENT_OZOFORMATION = ppTypeToPkType(ppLines.types.OZOFORMATION);

const types = { OPPONENT_RUSH, OPPONENT_REGROUP, OPPONENT_OZIFORMATION, OPPONENT_OZOFORMATION };

const selector = linesPKSelector;

const typeToSelector = {
    [OPPONENT_RUSH]: createEpisodeIdWrapper(OPPONENT_RUSH, createLinesTypeSelector(pkTypeToPPType(OPPONENT_RUSH))),
    [OPPONENT_REGROUP]: createEpisodeIdWrapper(
        OPPONENT_REGROUP,
        createLinesTypeSelector(pkTypeToPPType(OPPONENT_REGROUP))
    ),
    [OPPONENT_OZIFORMATION]: createEpisodeIdWrapper(
        OPPONENT_OZIFORMATION,
        createLinesTypeSelector(pkTypeToPPType(OPPONENT_OZIFORMATION))
    ),
    [OPPONENT_OZOFORMATION]: createEpisodeIdWrapper(
        OPPONENT_OZOFORMATION,
        createLinesTypeSelector(pkTypeToPPType(OPPONENT_OZOFORMATION))
    )
};

function mapper(lines, type) {
    if (lines.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Penalty Kill Lines');
    return typeToSelector[type](lines);
}

const getTime = ({ startTime, endTime }) => ({
    startTime,
    endTime,
    time: startTime,
    hasPadding: true,
    leftPadding: 3,
    rightPadding: 2
});

const typeToMediaTimeWrapper = Object.keys(typeToSelector)
    .map(key => ({ key, wrapper: createMediaTimeWrapper(getTime) }))
    .reduce((a, d) => {
        a[d.key] = d.wrapper;
        return a;
    }, {});

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

export const pkLines = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType: null
};
