import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import * as endpoints from '../../../utils/api/endpoints';
import Loading from '../../../components/loading/loading';
import Checkbox from '../../../components/checkbox/labeled-checkbox';
import Action from '../../rest-query/action';
import { apiBuilder } from '../../../utils/api/common-requests/common-requests';
import Button from '../../../components/button/button';
import Input from '../../../components/input/input';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    background-color: var(--backgroundColorSecondary);

    & .darcula {
        background-color: #26333c;
    }
`;

const CategoriesContainer = styled.div`
    display: grid;
    grid-gap: 3px;
    padding: 15px;
    overflow-y: scroll;
`;

const StyledCheckbox = styled(Checkbox)`
    color: black;
    font-weight: bold;
    font-size: 0.8em;
`;

const updateUserCategory = ({ categoryType, freezed, checked }) => ({
    method: 'PUT',
    url: endpoints.scoutUserCategories,
    data: { categoryType, freezed, checked }
});

const createUserCategory = categoryType => ({
    method: 'POST',
    url: endpoints.scoutUserCategories,
    data: { categoryType, freezed: false, checked: true }
});

const CustomizationEvaluationPopup = () => {
    const [userCategories, setUserCategories] = useState(null);
    const [processing, setProcessingStatus] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');

    const loadUserCategories = useCallback(() => {
        setProcessingStatus(true);
        apiBuilder()
            .get(endpoints.scoutUserCategories)
            .then(({ data }) => {
                setUserCategories(data);
                setProcessingStatus(false);
            });
    }, [setUserCategories]);

    useEffect(loadUserCategories, []);

    return (
        <Wrapper>
            <Action query={updateUserCategory} onSuccess={loadUserCategories}>
                {({ performAction: updateCategory }) => (
                    <Action
                        query={createUserCategory}
                        onSuccess={() => {
                            loadUserCategories();
                            setNewCategoryName('');
                        }}
                    >
                        {({ performAction: createCategory }) =>
                            !userCategories ? (
                                <Loading />
                            ) : (
                                [
                                    <CategoriesContainer>
                                        {userCategories.map(({ categoryType, freezed, checked }) => (
                                            <StyledCheckbox
                                                disabled={freezed || processing}
                                                checked={checked}
                                                onChange={() => {
                                                    setProcessingStatus(true);
                                                    updateCategory({ categoryType, freezed, checked: !checked });
                                                }}
                                                label={categoryType}
                                            />
                                        ))}
                                    </CategoriesContainer>,
                                    <div
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        <Input
                                            disabled={processing}
                                            value={newCategoryName}
                                            onChange={e => {
                                                setNewCategoryName(e.target.value);
                                            }}
                                        />
                                        <Button
                                            disabled={
                                                processing ||
                                                !newCategoryName ||
                                                userCategories.some(
                                                    ({ categoryType }) => newCategoryName === categoryType
                                                )
                                            }
                                            onClick={() => {
                                                setProcessingStatus(true);
                                                createCategory(newCategoryName);
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                ]
                            )
                        }
                    </Action>
                )}
            </Action>
        </Wrapper>
    );
};

export default CustomizationEvaluationPopup;
