import React, { useCallback, useReducer } from 'react';

import FormattedMessage from 'components/formatted-message/index';
import Button from 'components/button/button';
import { DownloadIcon } from 'components/svg-icons/playlist-icons';
import { scoutEvaluationTemplateIndex } from 'pages/game-center/scout/player-page/player-evaluation/scout-evaluation-template';

import CustomizationEvaluationPopup from '../../customization-evaluation-popup';
import { PREMIUM, PREMIUM_PLUS, productToPath } from '../../../../product-initializer/products';

import { MetricToggle, ThemeToggle, RatingSystemSelector } from './togglers';
import styles from './profile.module.css';
import { manageFavoritesPage } from 'constants/page-names';
import { useHistory } from 'react-router-dom';

const IS_MINIMAL_MODE = true;
const IS_VISIBLE_UPLOADS = false;

const SvgIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.0367 0.781647C14.4962 0.260549 13.9051 -5.17041e-07 13.2618 -5.17041e-07C12.5352 -5.17041e-07 11.8919 0.281851 11.3318 0.843916L1.45998 10.7186C1.39794 10.7808 1.35548 10.8644 1.33589 10.9693C1.29507 11.1151 0.859115 12.5735 0.0280239 15.3445C-0.0340223 15.5526 0.00679755 15.7296 0.152116 15.8755C0.256615 15.959 0.380707 16 0.526026 16H0.681141C2.83969 15.2708 4.27165 14.8021 4.97865 14.594C5.0407 14.5727 5.10274 14.5317 5.16479 14.4695C11.2878 8.40639 14.5893 5.12577 15.066 4.62597C15.6881 3.98034 16 3.32323 16 2.65793C15.9804 1.98935 15.6587 1.36501 15.0367 0.781647ZM11.1751 2.43835C11.6943 2.56288 12.1825 2.85457 12.6381 3.3134C13.074 3.75092 13.3434 4.25072 13.4479 4.81278C12.0372 6.22859 9.28596 8.96846 5.19744 13.0307C4.99008 12.5309 4.70924 12.1032 4.35656 11.7493C3.9206 11.3118 3.4324 10.9791 2.89357 10.7497L5.25949 8.37526L8.69978 4.92257L11.1751 2.43835ZM2.2078 11.5936C2.72703 11.7181 3.20381 12.0098 3.63976 12.4687C3.99244 12.8226 4.24226 13.2388 4.38758 13.719C4.18022 13.7812 3.68711 13.9484 2.90827 14.2188C2.12943 14.4891 1.56448 14.6776 1.2118 14.7808C1.3163 14.4678 1.48611 13.9271 1.72613 13.1553C1.96452 12.3851 2.12453 11.864 2.2078 11.5936ZM14.3509 3.9066L14.2578 4C14.0504 3.4576 13.75 2.98894 13.3549 2.59402C12.9401 2.1778 12.483 1.85498 11.985 1.62556L12.016 1.59443L12.047 1.56329C12.4209 1.18804 12.8259 1.00123 13.2618 1.00123C13.6357 1.00123 13.9982 1.17821 14.3509 1.53216C14.7656 1.92872 14.973 2.3138 14.973 2.68906C14.973 3.06432 14.7672 3.46907 14.3509 3.9066Z"
            fill="#4A90E2"
        />
    </svg>
);

export const ProfileControls = ({
    logout,
    handleUploadClientGameClick,
    brandedByHockeyTech,
    product,
    switchRatingSystem,
    ratingSystem,
    emails,
    onEditEmails,
    onEditPassword
}) => {
    const [customizationPopupOpened, toggleCustomizationPopup] = useReducer(s => !s, false);
    const history = useHistory();

    const downloadEvaluationTemplate = () => {
        window.open(`${productToPath.SCOUT.path}/${scoutEvaluationTemplateIndex}`);
    };

    const handleEditEmail = useCallback(
        email => () => {
            onEditEmails?.(email);
        },
        [onEditEmails]
    );

    const handleRedirectManageFavorite = useCallback(() => {
        history.push(`${productToPath[product === PREMIUM_PLUS ? PREMIUM : product].path}/${manageFavoritesPage}`);
    }, [history]);

    return (
        <div className={styles.profileControls}>
            <div className={styles.profileProp}>
                <span className={styles.propTitle}>
                    <FormattedMessage id="appContainer.profile.email" />
                </span>

                <div className={styles.emails}>
                    {!emails?.length ? (
                        <div className={styles.email} style={{ justifyContent: 'flex-end', width: '100%' }}>
                            <Button outline withoutBorder onClick={handleEditEmail('')}>
                                <SvgIcon />
                            </Button>
                        </div>
                    ) : (
                        emails?.slice(0, 1)?.map(email => (
                            <div key={email} className={styles.email}>
                                <span className={styles.emailLabel}>{email}</span>
                                <Button outline withoutBorder onClick={handleEditEmail(email)}>
                                    <SvgIcon />
                                </Button>
                            </div>
                        ))
                    )}
                </div>
            </div>

            <div className={styles.profileProp}>
                <span className={styles.propTitle}>
                    <FormattedMessage id="appContainer.profile.password" />
                </span>

                <div className={styles.password}>
                    <Button onClick={onEditPassword} style={{ width: '70%' }}>
                        <FormattedMessage id="appContainer.profile.password.change" />
                    </Button>
                </div>
            </div>

            <div className={styles.profileProp}>
                <span className={styles.propTitle}>
                    <FormattedMessage id="appContainer.profile.units" />
                </span>
                <MetricToggle />
            </div>

            {!brandedByHockeyTech && (
                <div className={styles.profileProp}>
                    <span className={styles.propTitle}>
                        <FormattedMessage id="appContainer.profile.mode" />
                    </span>
                    <ThemeToggle />
                </div>
            )}

            {product === 'SCOUT' && !IS_MINIMAL_MODE ? (
                <div className={styles.profileProp}>
                    <span className={styles.propTitle}>
                        <FormattedMessage id="appContainer.profile.ratingSystem" />
                    </span>
                    <RatingSystemSelector switchRatingSystem={switchRatingSystem} ratingSystem={ratingSystem} />
                </div>
            ) : null}

            {product === 'SCOUT' && !IS_MINIMAL_MODE ? (
                <div className={styles.profileProp}>
                    <span className={styles.propTitle}>
                        <FormattedMessage id="appContainer.profile.evaluation" />
                    </span>
                    <Button
                        style={{
                            marginLeft: 'auto',
                            width: '100px',
                            textTransform: 'none'
                        }}
                        onClick={toggleCustomizationPopup}
                    >
                        <FormattedMessage id="appContainer.profile.customization" />
                    </Button>
                    <DownloadIcon
                        style={{
                            margin: '0 4.5px'
                        }}
                        color="var(--uiPrimaryColorActive)"
                        onClick={downloadEvaluationTemplate}
                    />
                </div>
            ) : null}

            {customizationPopupOpened ? <CustomizationEvaluationPopup /> : null}

            {IS_VISIBLE_UPLOADS ? (
                <div className={styles.uploadClientGameButton}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={handleUploadClientGameClick}>
                        <FormattedMessage id="appContainer.profile.addGame" />
                    </a>
                </div>
            ) : null}

            <div className={styles.favoriteWrap}>
                <span className={styles.propTitle}>
                    <FormattedMessage id="appContainer.profile.manageFavorites.Favorites" />
                </span>
                <div className={styles.password}>
                    <Button onClick={handleRedirectManageFavorite} className={styles.manageFavorite}>
                        <FormattedMessage id="appContainer.profile.manageFavorites.manage" />
                    </Button>
                </div>
            </div>

            <div className={styles.controlsButtons}>
                <div className={styles.btnLogout} onClick={logout}>
                    <FormattedMessage id="appContainer.profile.signOut" />
                </div>
            </div>
        </div>
    );
};
