import React, { useEffect } from 'react';
import axios from 'axios';
import * as endpoints from 'utils/api/endpoints';
import Loading from '../../components/loading/loading';

const queryParams = new URLSearchParams(window.location.search);
const token = queryParams.get('token');

const EmailUpdateByToken = () => {
    useEffect(() => {
        if (!token) {
            return;
        }

        axios
            .get(endpoints.confirmEmail(token))
            .then(resp => {
                if (resp.status === 200) {
                    localStorage.setItem('UPDATE_EMAIL', 'SUCCESS');
                } else {
                    localStorage.setItem('UPDATE_EMAIL', 'FAIL');
                }
            })
            .catch(err => {
                // eslint-disable-next-line no-console
                console.error(err);
                localStorage.setItem('UPDATE_EMAIL', 'FAIL');
            })
            .finally(() => {
                window.location = '/login';
            });
    }, []);

    return <Loading spinner />;
};

export default EmailUpdateByToken;
