/* eslint-disable */

const znDictionary = {
    /***********************************************Entities***************************************************************/
    'entities.player.fullName':
        '${values.lastNameZh && values.firstNameZh ?' +
        '(values.lastNameZh +  values.firstNameZh) :' +
        '((values.firstNameEn ? values.firstNameEn[0] : "") + ". " + values.lastNameEn)}',
    'entities.player.lastName':
        '${values.lastNameZh && values.firstNameZh ?' +
        '(values.lastNameZh +  values.firstNameZh) :' +
        '((values.firstNameEn ? values.firstNameEn[0] : "") + ". " + values.lastNameEn) }',
    'entities.player.longName':
        '${values.lastNameZh && values.firstNameZh ?' +
        '(values.lastNameZh +  values.firstNameZh) :' +
        '(values.firstNameEn + " " + values.lastNameEn )}',
    'entities.player.lastNameWithInitial':
        '${values.lastNameZh && values.firstNameZh ?' +
        '(values.lastNameZh +  values.firstNameZh) :' +
        '(values.lastNameEn + " " + (values.firstNameEn ? values.firstNameEn[0] : "") + ".")}',

    'entities.team.name': '{nameEn}',
    'entities.season': '{nameEn}',
    'game.period.fullPresentation': '{period, selectordinal, one {第一} two {第二} few {第三} other {#th}} 节',
    'game.ot.fullPresentation': '{ot, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} 加时',

    /***********************************************Ice Player************************************************************/
    'icePlayer.widget': '冰场播放器',
    'icePlayer.playlistMode': null,
    'icePlayer.fullVideoMode': null,
    'icePlayer.ANIMATION': '动画',
    'icePlayer.VIDEO': '视频',
    'icePlayer.SYNC': '同步',
    'icePlayer.jumpForward': null,
    'icePlayer.jumpBackward': null,
    'icePlayer.episodes.RECOVERY': '获得球权',
    'icePlayer.episodes.GIVEAWAY': '失误',
    'icePlayer.episodes.TAKEAWAY': '抄截',
    'icePlayer.episodes.FACE_OFF': '争球',
    'icePlayer.episodes.SINGLE_SCORE_ATTEMPTS': '尝试射门',
    'icePlayer.episodes.SINGLE_SCORING_CHANCES': '进球机会',
    'icePlayer.episodes.SINGLE_GIVEAWAY': '失误',
    'icePlayer.episodes.SINGLE_TAKEAWAY': '抢断',
    'icePlayer.episodes.GAME_RECOVERY': '获得球权',
    'icePlayer.episodes.GAME_GIVEAWAY': '失误',
    'icePlayer.episodes.GAME_TAKEAWAY': '抄截',
    'icePlayer.episodes.GAME_FACE_OFF': '争球',
    'icePlayer.episodes.GAME_CARRY_IN': '带球进入攻区',
    'icePlayer.episodes.GAME_CARRY_OUT': '带球离开攻区',
    'icePlayer.episodes.GAME_SHOTS_ON_GOAL': '射正',
    'icePlayer.episodes.GAME_GOALS': '进球数',
    'icePlayer.episodes.GAME_SCORE_ATTEMPTS': '尝试射门',
    'icePlayer.episodes.GAME_SCORING_CHANCES': '进球机会',
    'icePlayer.episodes.GAME_BLOCKED_SHOTS': '封堵射门',
    'icePlayer.episodes.GAME_SHOTS_WITH_REBOUND': '反弹射门',
    'icePlayer.episodes.GAME_SHOTS_WITHOUT_REBOUND': '未形成反弹球的射门',
    'icePlayer.episodes.CARRY_IN': '带球进入攻区',
    'icePlayer.episodes.DUMP_IN': '下底传入攻区',
    'icePlayer.episodes.CARRY_OUT': '带球离开攻区',
    'icePlayer.episodes.DUMP_OUT': '下底传出攻区',
    'icePlayer.episodes.PASS_IN': '传球',
    'icePlayer.episodes.PASS_OUT': '传球',
    'icePlayer.episodes.CONTROLLED_IN': '在控制下 进入攻区',
    'icePlayer.episodes.UNCONTROLLED_IN': '球在非控制状态下的进入攻区',
    'icePlayer.episodes.CONTROLLED_OUT': '球在控制状态下的离开守区',
    'icePlayer.episodes.UNCONTROLLED_OUT': '非控制的方式离开',
    'icePlayer.episodes.BLOCKED_SHOTS': '封堵射门',
    'icePlayer.episodes.SCORING_CHANCES': '进球机会',
    'icePlayer.episodes.SCORE_ATTEMPTS': '尝试射门',
    'icePlayer.episodes.SHOTS_ON_GOAL': '射正',
    'icePlayer.episodes.GOALS': '进球数',
    'icePlayer.episodes.MISSED_SHOTS': '失球',
    'icePlayer.episodes.GAME_MISSED_SHOTS': '射偏',
    'icePlayer.episodes.SHOTS_WITH_REBOUND': '反弹射门',
    'icePlayer.episodes.SHOTS_WITHOUT_REBOUND': '未形成反弹球的射门',
    'icePlayer.episodes.GOALTENDERS_BLOCKED_SHOTS': '封堵射门',
    'icePlayer.episodes.GOALTENDERS_SCORING_CHANCES': '进球机会',
    'icePlayer.episodes.GOALTENDERS_SCORE_ATTEMPTS': '尝试射门',
    'icePlayer.episodes.GOALTENDERS_SHOTS_ON_GOAL': '射正',
    'icePlayer.episodes.GOALTENDERS_GOALS': '进球数',
    'icePlayer.episodes.GOALTENDERS_SHOTS_WITH_REBOUND': '反弹射门',
    'icePlayer.episodes.GOALTENDERS_SHOTS_WITHOUT_REBOUND': '未形成反弹球的射门',
    'icePlayer.episodes.GOALTENDERS_MISSED_SHOTS': '射偏数',
    'icePlayer.episodes.PP_RUSH': '前场进攻',
    'icePlayer.episodes.PP_REGROUP_DZ': '重组阵型',
    'icePlayer.episodes.PP_OZ_IN_FORM': '进攻阵型在攻区',
    'icePlayer.episodes.PP_OZ_OUT_FORM': '非进攻阵型在攻区',
    'icePlayer.episodes.OPPONENT_PP_RUSH': '前场进攻',
    'icePlayer.episodes.OPPONENT_PP_REGROUP_DZ': '重组阵型',
    'icePlayer.episodes.OPPONENT_PP_OZ_IN_FORM': '防守阵型在守区',
    'icePlayer.episodes.OPPONENT_PP_OZ_OUT_FORM': '非防守阵型在守区',
    'icePlayer.episodes.POWER_PLAY': '多打少',
    'icePlayer.episodes.PENALTY_KILL': '少防多',
    'icePlayer.episodes.GAME_SHIFTS': '全部轮换',
    'icePlayer.episodes.INDIVIDUAL_SHIFTS': '全部轮换',
    'icePlayer.episodes.SEASONS': '{nameEn} {dateBegin}',
    'icePlayer.episodes.game': '{homeTeamEn}-{awayTeamEn}',
    'icePlayer.episodes.GIVE_AWAYS': '失误数',
    'icePlayer.episodes.NEUTRAL_PUCKS': '获得球权',
    'icePlayer.episodes.REBOUND_RECOVERIES': '取得反弹球',
    'icePlayer.episodes.STICK_CHECKS': '球杆阻截',
    'icePlayer.episodes.BODY_CHECKS': '身体阻截',
    'icePlayer.episodes.FACE_OFFS': '争球',
    'icePlayer.episodes.POSSESSIONS': '球权数',
    'icePlayer.episodes.TAKE_AWAYS': '抄截数',
    'icePlayer.episodes.PPA': '多打少助攻',
    'icePlayer.episodes.PPS': '第二潜在助攻',
    'icePlayer.episodes.SUCCESS_PASSES': '成功传球',
    'icePlayer.episodes.UNSUCCESS_PASSES': '失败传球数',
    'icePlayer.episodes.PENALTY': null,
    'icePlayer.episodes.all': '全部',
    'icePlayer.episodes.SINGLE_GOALS': null,
    'icePlayer.episodes.SINGLE_SHOTS_ON_GOAL': null,
    'icePlayer.episodes.SINGLE_FACE_OFF': null,
    'icePlayer.mediaDisabled': '无文件',

    /***********************************************Components*************************************************************/
    //sign-up
    'signUp.title': null,
    'signUp.button': null,
    'signUp.firstName': null,
    'signUp.lastName': null,
    'signUp.email': null,
    'signUp.password': null,
    'signUp.haveAnAccount': null,
    'signUp.loginHere': null,
    'signUp.BySingingUpYouAgreeToOur': null,
    'signUp.termsAndConditions': null,
    'signUp.error': null,
    'signUp.emailExists': null,
    // login
    'login.title': '注册',
    'login.button': '登录',
    'login.error': '错误的用户名或密码',
    'login.username': '用户名',
    'login.email': '邮件',
    'login.password': '密码',
    'login.remember': '记住我',

    'resetPassword.title': null,
    'resetPassword.login': null,
    'resetPassword.button': null,
    'resetPassword.emailNotification': null,
    'resetPassword.successNotification': null,
    'resetPassword.newPassword': null,
    //confirmation
    'confirmation.thankYou': null,
    'confirmation.checkEmail': null,
    'confirmation.resend': null,
    'confirmation.checkSpam': null,

    //dashboard
    'dashboard.summary': '概要',
    'dashboard.skaters': '球员',
    'dashboard.powerPlay': '多打少',
    'dashboard.penaltyKill': '少防多',
    'dashboard.playersPage': '球员个人页',
    'dashboard.statistics': '数据统计',
    'dashboard.deprecated': '旧版本',
    'dashboard.gameScore': '比赛评分',
    'dashboard.configurator': null,
    'dashboard.schedule': 'Schedule',
    'dashboard.prescout': 'Pre-scout',
    'dashboard.superLite': 'Super-lite',
    'dashboard.playersComparison': 'Players comparison',
    playerSelect: 'Select Player',
    'dashboard.scout': 'Scout',
    //widgets
    'widget.viewDetails': '详细数据',
    //event-list
    'eventList.goals': '进球数',
    'eventList.scoreAttempts': '尝试射门',
    'eventList.carriesIn': '带球进入攻区',
    'eventList.dumpsIn': '下底传入攻区',
    'eventList.carriesOut': '带球离开攻区',
    'eventList.dumpsOut': '下底传出攻区',
    'eventList.scoringChances': '进球机会',
    'eventList.blockedShots': '封堵射门',
    'eventList.passes': '传球',
    'eventList.recovery': '取得球权',
    'eventList.takeaway': '抄截数',
    'eventList.giveaway': '失误数',
    'eventList.faceoff': '争球',
    'eventList.shotOnGoal': '射正',
    'eventList.shifts': '轮换',
    'eventList.rush': '前场进攻',
    'eventList.regroup': '重组阵型',
    'eventList.oziformation': '进攻阵型在攻区',
    'eventList.ozoformation': '以非进攻阵型在攻区',
    'eventList.pp': '多打少',
    //breadCrumbs
    'breadCrumbs.versus': ' VS ',
    'components.notFound.oops': 'Oops!',
    'components.notFound.errorMessage': '你所查询的页面没有找到',
    'components.notImplemented.errorMessage': null,
    'components.licenseDeclined.title': null,
    'components.licenseDeclined.errorMessage': null,
    'components.badLink.title': null,
    'components.badLink.errorMessage': null,
    'components.noGames.errorMessage': null,
    'components.noGames.info': null,
    'components.notFound.errorCode': '错误代码: 404',
    'components.error.errorMessage': null,
    'components.error.info': null,
    'components.error.reloadPage': null,
    'measures.m': '米',
    'measures.km': '公里',
    'measures.mPerSec': '米/秒',
    'measures.mPerSec2': '米/秒²',
    'measures.ft': 'ft',
    'measures.mile': 'mi',
    'measures.mph': '英里/小时',

    /******************************************************Containers******************************************************/
    //filter
    'filter.lastGame': '上一场',
    'filter.add': '加',
    'filter.clear': '清理',
    'filter.apply': '应用',
    'filter.selectAll': '选择全部',
    'filter.deselectAll': '取消选择',
    'filter.search.placeholder': '类型',
    'filter.periods.one': '{period} 节',
    'filter.periods.many': '{periods} 节',
    'filter.periods.overtime': '{period} 加时',
    'filter.strength.ev': '场上人数相等',
    'filter.strength.pk': '少防多',
    'filter.strength.pp': '多打少',
    'filter.strength.st': '特勤组',
    'filter.strength.en': null,
    'filter.strength.enTeam': null,
    'filter.strength.enOpponent': null,
    'filter.strength.mix': '{strengths} 场上人数形势',
    'filter.seasons.many': '{seasons} 赛季',
    'filter.teams.many': '{teams} 球队',
    'filter.teams.presentation': '{nameEn} ({acronymEn})',
    'filter.games.date': '{date, date, shortUTC}',
    'filter.games.empty': '无比赛',
    'filter.games.mixed': '{available} 赛事 (of {total})',
    'filter.games.scoutGame': '{homeTeamNameEn}-{awayTeamNameEn}',
    'filter.games.scoutGame.teamsName': '{homeFullNameEn}-{awayFullNameEn}',
    'filter.games.teamStatus': ' { isHome, select, true {vs} other {@} } ',
    'filter.games.opponentName': '{acronymEn} {teamScore}-{opponentScore}',
    'filter.teamStatus': '{ teamStatus, select, TEAM {我的球队} OPPONENT {对手} other {All teams} }',
    'filter.games.one': '{acronymEn}',
    'filter.games.status': '{ isHome, select, true {H} other {A} }{year}',
    'filter.games.many': '{games} 比赛',
    'filter.games.selectAll': '选择全部',
    'filter.games.deselect': '取消选择',
    'filter.position.D': '防守',
    'filter.position.F': '前锋',
    'filter.position.G': '门将',
    'filter.position.not.D': '非防守',
    'filter.position.not.F': '非前锋',
    'filter.position.not.G': '非门将',
    'filter.position.all': '所有位置',
    'filter.success.success': '成功',
    'filter.success.unsuccess': '失败',
    'filter.success.all': '所有赛果',
    'filter.entryType.pass': '传球',
    'filter.entryType.dump': '抛球',
    'filter.entryType.carry': '带球',
    'filter.entryType.notPass': '非传球',
    'filter.entryType.notDump': '非抛球',
    'filter.entryType.notCarry': '非带球',
    'filter.entryType.all': '所有类型',
    'filter.possession.offensive': '进攻的',
    'filter.possession.neutral': '中',
    'filter.possession.defensive': '防守的',
    'filter.possession.notOffensive': '非进攻的',
    'filter.possession.notNeutral': '非中区',
    'filter.possession.notDefensive': '非防守的',
    'filter.possession.all': '全部区域',
    'filter.pp.many': '{pps} pps',
    'filter.pk.many': '{pks} pks',
    // app-container
    'appContainer.supportRequest': null,
    'appContainer.supportRequest.reason': null,
    'appContainer.supportRequest.email': null,
    'appContainer.supportRequest.submit': null,
    'appContainer.supportRequest.success.p1': null,
    'appContainer.supportRequest.success.p2': null,
    'appContainer.supportRequest.dataVideoIssue': null,
    'appContainer.supportRequest.dataVideoIssue.description': null,
    'appContainer.supportRequest.missingGamesIssue': null,
    'appContainer.supportRequest.missingGamesIssue.description': null,
    'appContainer.supportRequest.logInIssue': null,
    'appContainer.supportRequest.logInIssue.description': null,
    'appContainer.supportRequest.verificationRequest': null,
    'appContainer.supportRequest.verificationRequest.description': null,
    'appContainer.supportRequest.financialIssue': null,
    'appContainer.supportRequest.financialIssue.description': null,
    'appContainer.supportRequest.hardwareSystemIssue': null,
    'appContainer.supportRequest.hardwareSystemIssue.description': null,

    'appContainer.logout': '登出',
    'appContainer.dashboard': '分析',
    'appContainer.scouting': '考察',
    'appContainer.scoutAdmin': null,
    'appContainer.clientsAdmin': null,
    'appContainer.scheduleAdmin': null,
    'appContainer.players': '球员',
    'appContainer.whatsNew': null,
    'appContainer.teams': '球队',
    'appContainer.glossary': '词汇表',
    'appContainer.profile.signOut': '退出',
    'appContainer.profile.units': '单位',
    'appContainer.profile.mode': '主题',
    'appContainer.profile.ratingSystem': null,
    'appContainer.profile.ratingSystem.FIVE_POINT': null,
    'appContainer.profile.ratingSystem.TEN_POINT': null,
    'appContainer.profile.ratingSystem.HUNDRED_POINT': null,
    'appContainer.profile.ratingSystem.LETTER': null,
    'appContainer.profile.addGame': '上传视频',
    'appContainer.toggleRadio.METRIC': '公制',
    'appContainer.toggleRadio.IMPERIAL': '英制',
    'appContainer.toggleRadio.classic': '典型的',
    'appContainer.toggleRadio.darcula': '晚间',
    //match-switcher
    'matchSwitcher.january': '1月',
    'matchSwitcher.february': '2月',
    'matchSwitcher.march': '3月',
    'matchSwitcher.april': '4月',
    'matchSwitcher.may': '5月',
    'matchSwitcher.june': '6月',
    'matchSwitcher.july': '7月',
    'matchSwitcher.august': '8月',
    'matchSwitcher.september': '9月',
    'matchSwitcher.october': '10月',
    'matchSwitcher.november': '11月',
    'matchSwitcher.december': '12月',
    //tooltip
    'tooltip.video': '视频',
    //upload-client-game-popup
    'uploadClientGamePopup.title': '上传视频',
    'uploadClientGamePopup.gameDate': '日期',
    'uploadClientGamePopup.homeTeam': '主队',
    'uploadClientGamePopup.awayTeam': '客队',
    'uploadClientGamePopup.dragHere': '拖拽文件上传',
    'uploadClientGamePopup.filesToUpload': '上传的文件',
    'uploadClientGamePopup.filesToUploadPlaceholder': '任何视频格式',
    'uploadClientGamePopup.uploading': '上传...',
    'uploadClientGamePopup.success': '文件上传成功',
    'uploadClientGamePopup.error': '无法上传文件，请稍后重新尝试',
    'uploadClientGamePopup.upload': '上传',
    'uploadClientGamePopup.cancelUpload': '取消上传',
    'uploadClientGamePopup.uploadMore': '上传更多',

    /***************************************************************Widgets************************************************/
    'shotMap.xG': 'XG',
    'shotMap.moment': '时刻',
    'shotMap.time': '时间',
    'shotMap.shotAttempts': '射门',
    'shotMap.scoringChances': '进球机会',
    'shotMap.firstPeriod': '第一节',
    'shotMap.secondPeriod': '第二节',
    'shotMap.thirdPeriod': '第三节',
    'shotMap.somePeriod': '第一加时',
    'shotMap.lowerRight': '右下',
    'shotMap.upperLeft': '左上',
    'shotMap.upperMiddle': '中上',
    'shotMap.upperRight': '右上',
    'shotMap.lowerLeft': '左下',
    'shotMap.lowerMiddle': '中下',
    'shotMap.blocked': '被封堵的',
    'shotMap.deflected': '变向的',
    'shotMap.overTheNet': '高出球门',
    'shotMap.wideRight': '右远',
    'shotMap.wideMiddle': '中远',
    'shotMap.wideLeft': '左远',
    'shotMap.goal': '进球',
    'shotMap.shotOnGoal': '射正',
    'shotMap.scoreAttempt': '尝试射门',
    'shotMap.strength': '场上人数形势',
    'shotMap.goalkeeper': '门将',
    'shotMap.pulled': 'Pulled',
    'shotMap.shotSpeed': '速度',
    'shotMap.MPH': '米/秒',
    'shotMap.game': '比赛',
    'shotMap.title': '射门图',
    'shotMap.location': '射门位置',
    'shotMap.heatMap': '热区图',
    'score.OT': '加时',
    'score.SO': '点球',
    'score.opponent': '对手',

    /***************************************************************Pages**************************************************/
    'noGamesPage.title': '无比赛可显示',
    'home.gameScore.preseason': '季前赛',
    'home.pastGames': '已赛',
    'home.restGames': 'Rest games',
    'home.favoriteGames': 'Favorite games',
    'summary.summary': '概要',
    'summary.versus': ' VS ',
    'summary.gameStatistics': '比赛数据统计',
    'summary.score': '得分',
    'summary.xGMap': '进球期望值值图',
    'summary.xGTimeline': 'XG时间线',
    'summary.skaters': '全场最佳',
    'summary.animation': '动画',
    'summary.video': '视频',
    'summary.playList': '球员列表',
    'summary.infobar.shotAttempts': '尝试射门',
    'summary.infobar.faceoffs': '争球',
    'summary.infobar.scoringChances': '进球机会',
    'summary.infobar.shotsOnGoal': '射正',
    'summary.infobar.possession': '球权',
    'summary.infobar.interceptions': '抄截数',
    'summary.infobar.less': '%\u00A0less',
    'summary.infobar.more': '%\u00A0more',
    'summary.infobar.then': '上一场',
    'summary.infobar.for': '本方',
    'summary.infobar.against': '对方的',
    'summary.infobar.puckTimeByZone': '球在攻区时间%',
    'summary.score.goals': '进球数',
    'summary.score.shotsOnGoal': '射正数',
    'summary.score.bodyChecks': '身体阻截',
    'summary.score.passing': '传球%',
    'summary.score.timeOfPossessions': '控球时间',
    'summary.score.distance': '滑冰距离,',
    'summary.score.hits': '冲撞',
    'summary.score.recoveries': '获得球权',
    'summary.score.reportPostgame': null,
    'summary.score.downloadRawData': null,
    'summary.score.scoringChancesPecentage': null,
    'summary.score.shotsAccuracy': null,
    'summary.xGTimeline.shotAttempts': '射门',
    'summary.xGTimeline.scoringChances': '进球机会',
    'summary.xGTimeline.firstPeriod': '第一节',
    'summary.xGTimeline.secondPeriod': '第二节',
    'summary.xGTimeline.thirdPeriod': '第三节',
    'summary.xGTimeline.lowerRight': '右下',
    'summary.xGTimeline.upperLeft': '左上',
    'summary.xGTimeline.upperMiddle': '中上',
    'summary.xGTimeline.upperRight': '右上',
    'summary.xGTimeline.lowerLeft': '左下',
    'summary.xGTimeline.lowerMiddle': '中下',
    'summary.xGTimeline.blocked': '被封堵的',
    'summary.xGTimeline.deflected': '变向的',
    'summary.xGTimeline.overTheNet': '高出球门',
    'summary.xGTimeline.wideRight': '右远',
    'summary.xGTimeline.wideMiddle': '中远',
    'summary.xGTimeline.wideLeft': '左远',
    'summary.xGTimeline.goal': '进球',
    'summary.xGTimeline.shotOnGoal': '射正',
    'summary.xGTimeline.scoreAttempt': '尝试进球',
    'summary.xGTimeline.teamXG': 'XG',
    'summary.xGTimeline.moment': '时刻',
    'summary.xGTimeline.time': '时间',
    'summary.xGTimeline.overtime': '加时',
    'summary.skaters.onIceXG': '在场上时的进球期望值值',
    'summary.skaters.totalXG': '总XG',
    'summary.skaters.gameScore': '比赛评分',
    'summary.skaters.OZoneTimeOnIce': '球在攻区时间',
    'summary.skaters.shotAttempts': '尝试射门%',
    'summary.skaters.potentialAssists': '潜在助攻',
    'summary.skaters.takeaways': '抄截数',
    'summary.skaters.save': '扑救',
    'summary.skaters.rebound': '反弹球',
    'summary.skaters.recoveries': '取得球权',
    'summary.skaters.shotAngle': '射门角度',
    'summary.skaters.goalsAGST': '失球数',
    'summary.skaters.xgAGST': '对手XG',
    'skaters.efficiency': '效率',
    'skaters.compareLines': '组合比较',
    'skaters.xgTopSkaters': '球员的XG排行',
    'skaters.efficiency.timeOnIce': '上场时间',
    'skaters.efficiency.shotsFor': '本方射门',
    'skaters.efficiency.shotsAgainst': '对手射门',
    'skaters.efficiency.dull': '少变的',
    'skaters.efficiency.good': 'GOOD',
    'skaters.efficiency.fun': '多变的',
    'skaters.efficiency.bad': 'BAD',
    'skaters.compareLines.line': '组',
    'skaters.compareLines.xG': 'XG%',
    'skaters.compareLines.forwards': '前锋',
    'skaters.compareLines.defenders': '后卫',
    'skaters.compareLines.pair': '双',
    'skaters.compareLines.combination': '组合',
    'skaters.compareLines.toi': {
        message: '上场时间',
        title: '整组球员的总上场时间'
    },
    'skaters.compareLines.possessionTime': {
        message: '球权 %',
        title: '一组球员在场上时球队的控球率'
    },
    'skaters.compareLines.possessionTimeFor': '本方球权',
    'skaters.compareLines.possessionTimeAgainst': '对手控球',
    'skaters.compareLines.OZpuckTime': {
        message: '球在攻区时间%',
        title: '一组球员在场上时，球停留在攻区的时间占比'
    },
    'skaters.compareLines.puckTimeOZFor': '球在攻区时间%',
    'skaters.compareLines.puckTimeOZAgainst': '守区的控球时间',
    'skaters.compareLines.onIceXgRelativeFor': '本方进球期望值值',
    'skaters.compareLines.onIceXgRelativeAgainst': '对手进球期望值',
    'skaters.compareLines.xgFor': '本方XG',
    'skaters.compareLines.xgAgainst': '对手XG',
    'skaters.compareLines.infobar': '数据统计',
    'skaters.compareLines.table': '列表',
    'skaters.compareLines.onIceXg': {
        message: '进球期望值值%',
        title: '本队进球期望值除以两队总进球期望值'
    },
    'skaters.compareLines.corsi': {
        message: '尝试射门%',
        title: '当球员在场上时,总射门数中的本队尝试射门数占比'
    },
    'skaters.compareLines.shotsFor': '本方射门',
    'skaters.compareLines.shotsAgainst': '对手射门',
    'skaters.compareLines.toiTotal': '总上场时间',
    'skaters.compareLines.possessionFor': '本方球权',
    'skaters.compareLines.possessionAgainst': '对手控球',
    'skaters.compareLines.gameTime': '比赛时间',
    'skaters.xGTopSkaters.timeOnIce': {
        message: '上场时间',
        title: '球员的总上场时间'
    },
    'skaters.xGTopSkaters.possession': {
        message: '控球%',
        title: '球员在场上时的控球率'
    },
    'skaters.xGTopSkaters.puckTimeInOZ': {
        message: '球停留在攻区%',
        title: '球员在场上时，球留在攻区的平均时间占比'
    },
    'skaters.xGTopSkaters.corsi': {
        message: '尝试射门%',
        title: '当球员在场上时，总射门数中的本队尝试射门占比'
    },
    'skaters.xGTopSkaters.scoringChances': {
        message: '进球机会%',
        title: '球员在场上时，球队的进球机会和双方进球机会的比'
    },
    'skaters.xGTopSkaters.onIcexG': {
        message: 'XG%',
        title: '球员在场上时，球队的进球期望值值和双方总进球期望值的比'
    },
    'skaters.xGTopSkaters.gameScore': {
        message: '比赛评分',
        title: '根据球权的场上位置，上场时间，以及他在进攻端和防守端的表现所得出的球队赢球概率'
    },
    'skaters.xGTopSkaters.totalxG': '总XG',
    'skaters.xGTopSkaters.shots': '射门',
    'skaters.xGTopSkaters.recoveries': '取得球权',
    'skaters.xGTopSkaters.skaters': '球员',
    'skaters.xGTopSkaters.skatersAll': '全部球员',
    'skaters.xGTopSkaters.infobar': '表 平均',
    'skaters.xGTopSkaters.table': '球员列表',
    'skaters.xGTopSkaters.possessionTimeFor': '本方球权',
    'skaters.xGTopSkaters.puckTimeOZFor': '球在攻区时间%',
    'skaters.xGTopSkaters.shotsFor': '本方射门',
    'skaters.xGTopSkaters.scoringChanceFor': '本方进球机会',
    'skaters.xGTopSkaters.onIceXgRelativeFor': '在场上时本方的XG',
    'skaters.xGTopSkaters.possessionTimeAgainst': '对手控球',
    'skaters.xGTopSkaters.puckTimeOZAgainst': '守区的控球时间',
    'skaters.xGTopSkaters.shotsAgainst': '对手射门',
    'skaters.xGTopSkaters.scoringChanceAgainst': '对手进球机会',
    'skaters.xGTopSkaters.onIceXgRelativeAgainst': '在场上时对手的XG',
    'skaters.xGTopSkaters.toi': '总上场时间',
    'skaters.xGTopSkaters.possessionFor': '本方球权',
    'skaters.xGTopSkaters.possessionAgainst': '对手控球',
    'skaters.xGTopSkaters.faceoffs': {
        message: '区域轮换',
        title: '在攻区的轮换数除以在攻区和守区的总轮换数'
    },
    'skaters.xgTopSkaters.dzFaceOffs': '守区',
    'skaters.xgTopSkaters.nzFaceOffs': '中区',
    'skaters.xgTopSkaters.ozFaceOffs': '攻区',
    'skaters.xGTopSkaters.gameTime': '比赛时间',
    'powerPlay.infobar': '列表总计/平均',
    'powerPlay.linesTable': '强打组',
    'powerPlay.donutChart': '多打少数据',
    'powerPlay.skatersTable': '球员',
    'powerPlay.shotMap': '射门图',
    'powerPlay.info.totalPPTime': '多打少总时间',
    'powerPlay.info.ozFormationTime': '攻区进攻阵型时间',
    'powerPlay.info.shotsOnGoal': '射正',
    'powerPlay.info.ozEntries': '进入攻区',
    'powerPlay.info.reboundRecoveries': '取得反弹球',
    'powerPlay.info.faceOff%': '争球胜',
    'powerPlay.info.faceOffWon': '争球胜, %',
    'powerPlay.info.faceOffLost': '争球负',
    'powerPlay.players.toi': {
        message: '上场时间',
        title: '球员多打少的总上场时间'
    },
    'powerPlay.players.ozTime': {
        message: '攻区时间',
        title: '球员在场上时，球停留在攻区的时间'
    },
    'powerPlay.players.ozfPerOz': {
        message: '在攻区保持阵型时间%',
        title: '球员在场上时，球队以进攻阵型进攻时间在总进攻时间中的占比'
    },
    'powerPlay.players.shots': {
        message: '射门',
        title: '球员在场上时，本方球队在多打少中的总尝试射门数'
    },
    'powerPlay.players.scoringChances': {
        message: '进球机会',
        title: '球员在场上时，本方球队在多打少时间内的总进球机会'
    },
    'powerPlay.players.scoringChancesPerShots': '进球机会%',
    'powerPlay.players.reboundRecoveries': '取得反弹球',
    'powerPlay.players.successZoneEntries': '成功的进入区域',
    'powerPlay.players.potentialAssists': '潜在助攻',
    'powerPlay.players.giveawaysPercent': '失误',
    'powerPlay.players.avgRushSpeed': '平均rush速度',
    'powerPlay.players.giveaways': '失误数',
    'powerPlay.players.possessionCount': '球权数',
    'powerPlay.players.ozFormationTime': '以进攻阵型在攻区的时间',
    'powerPlay.players.ozOnIceTime': '攻区时间',
    'powerPlay.lines.lines': '组',
    'powerPlay.lines.shots': {
        message: '射门',
        title: '一组球员在场上时，球队多打少时的总尝试射门数'
    },
    'powerPlay.lines.shotsOnGoal': '射正',
    'powerPlay.lines.shotsBlocked': '封堵射门',
    'powerPlay.lines.shotsMissed': '射偏',
    'powerPlay.lines.scoringChances': {
        message: '进球机会',
        title: '一组球员在场上时，球队多打少时的总进球机会'
    },
    'powerPlay.lines.ozfTime': '以进攻阵型在攻区的时间',
    'powerPlay.lines.ozTime': {
        message: '攻区时间',
        title: '一组球员在场上时球在攻区的时间'
    },
    'powerPlay.lines.ozfTimeOfOzTime': {
        message: '在攻区保持阵型的时间%',
        title: '一组球员在场上时，进攻阵型攻击时间在总进攻时间中的占比'
    },
    'powerPlay.lines.toi': {
        message: '上场时间',
        title: '一组球员同时上场的多打少的总时间'
    },
    'powerPlay.chart.ppXG': '多打少XG',
    'powerPlay.chart.rush': '快攻',
    'powerPlay.chart.oziFormation': '进攻阵型在攻区',
    'powerPlay.chart.ozoFormation': '以非进攻阵型在攻区',
    'powerPlay.chart.regrouping': '重组阵型',
    'penaltyKill.chart.rushToOZConversion': '攻区转换',
    'penaltyKill.chart.rushToRegroupConversion': '重组阵型转换',
    'penaltyKill.chart.rushToPKConversion': '少防多转换',
    'penaltyKill.chart.rushes': '快攻',
    'penaltyKill.chart.entries': '进入攻区',
    'penaltyKill.chart.carries': '带球',
    'penaltyKill.chart.dumps': '抛球数',
    'statistics.entries.dumpsIn': '下底传入攻区',
    'statistics.entries.dumpsOut': '下底传出攻区',
    'penaltyKill.chart.passes': '传球',
    'penaltyKill.info.totalPkTime': '少防多时间',
    'penaltyKill.info.dzTimePercent': '守区时间, %',
    'penaltyKill.info.regroupTimePerRush': '每次冲刺重组阵型的时间',
    'penaltyKill.info.clearancesPerDzPossessions': '解围, %',
    'penaltyKill.info.shotsPerPk': '尝试射门',
    'penaltyKill.info.blockPercentage': '封堵射门, %',
    'penaltyKill.info.scoringChancesPerShots': '进球机会/尝试射门. %',
    'penaltyKill.info.faceOffPercentage': '争球成功率',
    'penaltyKill.info.faceOffWon': '争球胜, %',
    'penaltyKill.info.faceOffLost': '争球负',
    'penaltyKill.info.dzTime': '守区时间',
    'penaltyKill.info.regroupTime': '重组阵型时间',
    'penaltyKill.info.rushes': '快攻',
    'penaltyKill.info.clearances': '解围',
    'penaltyKill.info.possessionsInDz': '# 守区控球',
    'penaltyKill.info.blockedShots': '封堵射门',
    'penaltyKill.info.shots': '尝试射门',
    'penaltyKill.info.scoringChances': '进球机会',
    'penaltyKill.lines.lines': '组',
    'penaltyKill.lines.toi': {
        message: '总上场时间',
        title: '本组球员同时在场的总时间'
    },
    'penaltyKill.lines.dzTime': {
        message: '守区时间',
        title: '每组球员在场上时，球在守区的时间'
    },
    'penaltyKill.lines.dzFormationTimeOfDzTime': {
        message: '守区时间, %',
        title: '一组球员在场上时，球在守区的时间占比'
    },
    'penaltyKill.lines.dzfTime': '防守阵型在守区的时间',
    'penaltyKill.lines.crossIcePasses': {
        message: '传中',
        title: '对手在本方守区的总传中数[按组分]'
    },
    'penaltyKill.lines.shotsOnGoal': {
        message: '射正',
        title: '对手的总射正数'
    },
    'penaltyKill.lines.scoringChances': {
        message: '进球机会',
        title: '对手的总进球机会'
    },
    'penaltyKill.lines.recoveryPercentage': {
        message: '取得反弹球, %',
        title: '总反弹球中，被队友获取球权的数量的占比'
    },
    'penaltyKill.lines.recoveries': '取得反弹球',
    'penaltyKill.players.dzFormationOfDzTime': '在守区保持阵型的时间, %',
    'penaltyKill.players.dzFormationTime': '防守阵型在守区的时间',
    'penaltyKill.players.loosePuckRecoveries': '获得球权',
    'penaltyKill.players.reboundRecoveriesPersonal': '取得反弹球',
    'penaltyKill.players.clearancesPerPossessions': '解围, %',
    'penaltyKill.players.clearances': '解围',
    'penaltyKill.players.dzPossessions': '# 守区控球',
    'penaltyKill.players.blockedShotsPerPotentiallyBlocked': '封堵射门, %',
    'penaltyKill.players.blockedShots': '封堵射门',
    'penaltyKill.players.potentiallyBlockedShots': '潜在封堵射门',
    'penaltyKill.players.avgDistanceToTargetInFormation': '守区的间隔, ',
    'penaltyKill.players.toi': {
        message: '总上场时间',
        title: '在少防多时，球员在场上的总时间'
    },
    'penaltyKill.players.dzTimePercentage': {
        message: '守区时间, %',
        title: '球员在场上时，球留在守区的时间占比'
    },
    'penaltyKill.players.dzTime': '守区时间',
    'penaltyKill.players.dzfTimePercentage': {
        message: '在守区保持阵型的时间, %',
        title: '对手以进攻阵型在攻区的时间比，并且战术意图非常明确'
    },
    'penaltyKill.players.dzfTime': '防守阵型在守区的时间',
    'penaltyKill.players.shots': {
        message: '射门',
        title: '球员在场上时，对手的射门数'
    },
    'penaltyKill.players.scoringChances': {
        message: '进球机会',
        title: '球员在场上时，对手的进球机会'
    },
    'penaltyKill.players.crossIcePasses': {
        message: '传中',
        title: '球员在场上时，对手在本方守区进行的传中总数'
    },
    'penaltyKill.linesTable': '少防多列表',
    'penaltyKill.donutChart': '攻击类型',
    'statistics.passing': '传球',
    'statistics.shooting': '射门',
    'statistics.entries': '进入攻区',
    'statistics.exits': '离开攻区',
    'statistics.possession': '球权',
    'statistics.goalkeepers': '门将',
    'statistics.skating': '滑冰',
    'statistics.title': '数据统计',
    'statistics.shifts': '轮换',
    'statistics.playerComparison': '球员对比',
    'statistics.shooting.shotAttempts': {
        message: '尝试射门',
        title: '射偏，被封堵，变向，被扑救，或转化为进球的尝试射门数'
    },
    'statistics.shooting.shotsOnGoal': {
        message: '射正',
        title: '直接射门或折射数，不论被扑救或转化为进球'
    },
    'statistics.shooting.shooting': {
        message: '射门命中率',
        title: '进球数除以射正数'
    },
    'statistics.shooting.goals': {
        message: '进球数',
        title: '当球整体穿过球门线'
    },
    'statistics.shooting.scoringChances': {
        message: '进球机会',
        title:
            '满足以下至少一种情形: 1. 来自危险区域的尝试射门； 2. 来自穿越中线的潜在助攻的尝试射门；3. 连续的未被封堵的尝试射门'
    },
    'statistics.shooting.rebound': {
        message: '反弹球%',
        title: '反弹球射门数除以总射正'
    },
    'statistics.shooting.avgShotSpeed': {
        message: '平均射门速度',
        title: 'Average value for the metric "shot speed"'
    },
    'statistics.shooting.overall': '射门总览',
    'statistics.shooting.shotMaxSpeed': {
        message: '最大射门速度',
        title: 'Maximum value for the metric "shot speed"'
    },
    'statistics.shooting.infobarTitle': '列表总计/平均',
    'statistics.shooting.shotMap': '射门图',
    'statistics.shooting.table': '射门列表',
    'statistics.shooting.avgShotAttempts': '平均尝试射门',
    'statistics.shooting.avgShotsOnGoal': '平均射正数',
    'statistics.shooting.avgScoringChances': '平均进球机会',
    'statistics.shooting.avgGoals': '平均进球',
    'statistics.shooting.avgXg': '平均XG',
    'statistics.shooting.reboundTooltip': '反弹球',
    'statistics.shooting.timeOnIce': 'Time on Ice',
    'statistics.shooting.assists': 'Assists',
    'statistics.shooting.shotAccuracy': 'Shot accuracy %',
    'statistics.shooting.shotsFromDangerZone': 'Shots from danger zone',
    'statistics.shooting.shotsAfterRebounds': 'Shots after rebounds',
    'statistics.shooting.faceoffs': 'faceoffs %',
    'statistics.shooting.penaltyMinutes': 'Penalty minutes',
    'statistics.shooting.xg': {
        message: 'XG',
        title: '球员射门转化为进球的几率，根据射门的特性（距离，角度等），射门人和队友，对手的位置等'
    },
    'statistics.goalkeepers.save': {
        message: '扑救%',
        title: '扑救数除以射正数'
    },
    'statistics.goalkeepers.rebound': {
        message: '反弹球%',
        title: '总射正中的被扑救后形成反弹的射门占比'
    },
    'statistics.goalkeepers.toi': {
        message: '上场时间',
        title: "Total amount of time the goaltender's skates were on the ice during live action"
    },
    'statistics.goalkeepers.overall': '总览',
    'statistics.goalkeepers.gamesPlayed': {
        message: '已赛场次',
        title: '球员出赛数'
    },
    'statistics.goalkeepers.shotsOnGoal': {
        message: '射正',
        title: '直接射门或者折射，被扑救或者转化为进球'
    },
    'statistics.goalkeepers.goals': {
        message: '进球数',
        title: '当球整体穿过球门线'
    },
    'statistics.goalkeepers.scoringChances': {
        message: '进球机会',
        title:
            '满足以下至少一种情形: 1. 来自危险区域的尝试射门； 2. 来自穿越中线的潜在助攻的尝试射门；3. 连续的未被封堵的尝试射门'
    },
    'statistics.goalkeepers.avgShotDistance': {
        message: '平均射门距离',
        title: '对手球员中的平均射门距离'
    },
    'statistics.goalkeepers.avgAngleOfDeflection': {
        message: '平均折射角度',
        title: '射门后球的方向和中线的角度'
    },
    'statistics.goalkeepers.maxShotSpeed': {
        message: '最大射门速度,',
        title: 'Maximum metric for the value "shot speed" among the players of the opponent team'
    },
    'statistics.goalkeepers.leagueAverage': '联盟平均',
    'statistics.goalkeepers.infobar': '列表总计/平均',
    'statistics.goalkeepers.table': '门将',
    'statistics.goalkeepers.gateChart': '门将图表',
    'statistics.goalkeepers.shotMap': '射门图',
    'statistics.goalkeepers.zonesMap': '区域图',
    'statistics.goalkeepers.rebounds': '反弹球',
    'statistics.goalkeepers.reboundsAgainst': null,
    'statistics.goalkeepers.totalShotsOnGoal': '总射正',
    'statistics.goalkeepers.totalGoals': '总进球数',
    'statistics.passing.passes': '传球',
    'statistics.passing.completedPasses': '传球完成数',
    'statistics.passing.team': '球队',
    'statistics.passing.overallAverage': {
        message: '平均, %',
        title: '全部区域的传球成功率'
    },
    'statistics.passing.players': '球员',
    'statistics.passing.defensive': {
        message: '守区, %',
        title: '在守区的传球成功率'
    },
    'statistics.passing.neutral': {
        message: '中区, %',
        title: '在中区的传球成功率'
    },
    'statistics.passing.offensive': {
        message: '攻区, %',
        title: '攻区的传球成功率'
    },
    'statistics.passing.overall': '球员综合统计',
    'statistics.passing.ppa': {
        message: '潜在助攻 (第一)',
        title: '尝试射门前的最后一次传球（传球和射门中没有发生球权转换）'
    },
    'statistics.passing.spa': {
        message: '潜在助攻 (第二)',
        title: '第一潜在助攻前的最后一次传球（在第二潜在助攻，第一潜在助攻中没有发生球权转换）'
    },
    'statistics.passing.potentialAssist': '潜在助攻',
    'statistics.passing.successful': '成功传球',
    'statistics.passing.failed': '传球失败',
    'statistics.passing.bad': 'BAD',
    'statistics.passing.good': 'GOOD',
    'statistics.passing.infobar': '列表总计/平均',
    'statistics.passing.table': '传球列表',
    'statistics.passing.chart': '潜在助攻',
    'statistics.passing.avgPAP': '平均潜在助攻 (第一)',
    'statistics.passing.avgPAS': '平均潜在助攻 (第二)',
    'statistics.entries.entries': '进入攻区',
    'statistics.entries.exits': '离开守区',
    'statistics.entries.passes': '传球',
    'statistics.entries.controlled': '在控制下',
    'statistics.entries.uncontrolled': '非控制下的',
    'statistics.entries.ppStatistics': '进入 / 离开',
    'statistics.entries.entriesExitsAnimation': '进入攻区/离开守区动画',
    'statistics.entries.playList': '球员列表',
    'statistics.entries.shots': '尝试射门',
    'statistics.entries.avgDuration': '平均进攻用时',
    'statistics.entries.carries': '带球',
    'statistics.entries.carriesIn': '带球进入攻区',
    'statistics.entries.carriesOut': '带球离开攻区',
    'statistics.entries.dumps': '抛球',
    'statistics.entries.carriesRate': '成功带球',
    'statistics.entries.passesRate': '成功传球',
    'statistics.entries.dumpsRate': '抛球成功',
    'statistics.entries.avgTimeCarries': '平均带球时间',
    'statistics.entries.avgTimePasses': '平均传球时间',
    'statistics.entries.avgTimeDumps': '平均抛球时间',
    'statistics.entries.successRate': '成功率',
    'statistics.entries.positiveRate': null,
    'statistics.entries.Xg': 'XG',
    'statistics.entries.info.totalEntries': '总共',
    'statistics.entries.info.avgDurationTime': '平均用时',
    'statistics.entries.info.avgXg': '平均XG',
    'statistics.entries.infobar': '总/平均',
    'statistics.entries.info.successRate': '成功率',
    'statistics.entries.info.positiveRate': null,
    'statistics.entries.info.distribution': '分布',
    'statistics.possession.bodyChecks': '身体阻截',
    'statistics.possession.checks': {
        message: '阻截',
        title: '用球杆/身体来将球从带球人的球杆下拨开，导致转换球权的次数'
    },
    'statistics.possession.faceOffWon': {
        message: '争球胜',
        title: '若球队或球员 (争球球员) 在哨响后的头2秒内保持球权的控制则赢得争球'
    },
    'statistics.possession.faceOffWon%': {
        message: '争球成功率',
        title: '争球成功率'
    },
    'statistics.possession.faceOffLost': '争球负',
    'statistics.possession.giveaways': '失误数',
    'statistics.possession.giveawaysPercent': {
        message: '球权丢失百分比',
        title: '球员控球、带球、传球过程中丢球的次数除以球权数'
    },
    'statistics.possession.possessionCount': {
        message: '球权数',
        title: '球队第一次触球的次数. 计算球队的球权数.'
    },
    'statistics.possession.possessionPlayerValue': '球员球权数',
    'statistics.possession.possessionTeamValue': '球队球权数',
    'statistics.possession.giveawaysPlayerValue': '球员球权丢失数',
    'statistics.possession.giveawaysTeamValue': '球队球权丢失数',
    'statistics.possession.possessionTime': {
        message: '球权时间',
        title: '球员带球后作出击墙传球，并且之后获得球权'
    },
    'statistics.possession.recoveries': {
        message: '获得球权',
        title: '当球员取得不属于任何一方球权的球'
    },
    'statistics.possession.reboundRecoveries': {
        message: '取得反弹球',
        title: '球被门将扑出后形成反弹后球员拿到球的次数'
    },
    'statistics.possession.takeaways': {
        message: '抄截数',
        title: '导致球权转换的被抄截的直接传球数'
    },
    'statistics.possession.timePerPossession': {
        message: '每次球权所用时间',
        title: '控球时间除以控球次数'
    },
    'statistics.possession.percentTeamPossessions': '球队球权数占比',
    'statistics.possession.stickChecks': '球杆阻截',
    'statistics.possession.infobar': '表 平均',
    'statistics.possession.table': '球权列表',
    'statistics.possession.possessionTimeAVG': '平均控球',
    'statistics.possession.possessionCountAVG': '平均球权数',
    'statistics.possession.takeawaysAVG': '平均抄截数',
    'statistics.possession.recoveriesAVG': '平均取得球权',
    'statistics.possession.reboundRecoveriesAVG': '平均反弹球数',
    'statistics.possession.stickChecksAVG': '平均球杆阻截',
    'statistics.possession.bodyChecksAVG': '平均身体阻截数',
    'statistics.possession.faceOffWonAVG': '平均争球胜',
    'statistics.possession.faceOffLostAVG': '平均争球负',
    'statistics.skating.heatmap': '热区图',
    'statistics.skating.skating': '滑冰',
    'statistics.skating.byPlayers': 'by球员',
    'statistics.skating.players': '球员',
    'statistics.skating.playtime': '比赛时间',
    'statistics.skating.distance': {
        message: '距离',
        title: '覆盖的距离'
    },
    'statistics.skating.speed': {
        message: '速度',
        title: '球员在场上时的平均滑冰速度'
    },
    'statistics.skating.possesionSpeed': '平均球权速度，',
    'statistics.skating.acceleration': {
        message: '加速度, 米/秒²',
        title: '根据时间计算的球员的变速率'
    },
    'statistics.skating.pace': {
        message: '高速滑冰%',
        title: '总滑冰距离内，高速滑冰的距离占比（20公里/小时以上）'
    },
    'statistics.skating.backchecking': {
        message: '高速回防%',
        title: '对手在进攻时，往本方守区回防的滑冰距离除以20公里/小时的高速滑冰距离'
    },
    'statistics.skating.shift': '轮换',
    'statistics.skating.throughout': '贯穿在轮换中',
    'statistics.skating.secondsIntoTheShift': '轮换上场时间（秒）',
    'statistics.skating.chart': '轮换体能',
    'statistics.skating.chartPace': 'PACE',
    'statistics.skating.timeOnIce': {
        message: '上场时间',
        title: "Total time when the player's skates were on the ice during live action"
    },
    'statistics.skating.speedWithThePuck': {
        message: '带球速度, ',
        title: '球员带球时的平均速度'
    },
    'statistics.skating.shiftTime': {
        message: '平均轮换长度',
        title: '平均轮换用时. 轮换从赛中第一次触冰开始计算'
    },
    'statistics.skating.avgRestTime': '平均休息时间',
    'statistics.skating.infobar': '表 平均',
    'statistics.skating.table': '滑冰数据列表',
    'statistics.skating.totalDistance': '总距离',
    'statistics.skating.totalShiftsLength': '总轮换时长',
    'statistics.skating.shifts': '轮换',
    'statistics.shifts.chart': '轮换图',
    'statistics.shifts.table': '轮换列表',
    'statistics.shifts.statistics': null,
    'statistics.shifts.startShiftTime': '开始时间',
    'statistics.shifts.endShiftTime': '结束时间',
    'statistics.shifts.eventType': null,
    'statistics.shifts.strengths': '场上人数形势',
    'statistics.shifts.OZtimeFor': '球在攻区时间%',
    'statistics.shifts.OZtimeAgainst': '守区的控球时间',
    'statistics.shifts.shotAttemptsFor': '本方尝试射门',
    'statistics.shifts.shotAttemptsAgainst': '对手尝试射门',
    'statistics.shifts.possessionTime': '球权时间',
    'statistics.shifts.reboundRecoveries': '取得反弹球',
    'statistics.shifts.PPA': '潜在助攻 (第一)',
    'statistics.shifts.SPA': '潜在助攻 (第二)',
    'statistics.shifts.onGoal': '在球门范围内',
    'statistics.shifts.blocked': '被封堵的',
    'statistics.shifts.missed': '偏出',
    'statistics.shifts.shiftLength': {
        message: '轮换上场时长',
        title: '当球员在场上时，轮换的时长会延续下去'
    },
    'statistics.shifts.distance': {
        message: '距离',
        title: '在轮换中的滑冰距离'
    },
    'statistics.shifts.pace': {
        message: '高速滑冰占比',
        title: '轮换中的高速滑冰占比（20公里/小时以上的高速滑冰距离除以总滑冰距离）'
    },
    'statistics.shifts.backcheckPace': {
        message: '高速回防比',
        title: '在轮换中向守区的平均高速回防（以超过20公里/小时的速度滑冰的距离）除以总滑冰距离'
    },
    'statistics.shifts.OZtime': {
        message: '攻区时间, %',
        title: '轮换中球在攻区的时间占比'
    },
    'statistics.shifts.shotAttemptsOnIce': {
        message: '尝试射门, %',
        title: '轮换中球队的尝试射门数在总尝试射门数中的占比'
    },
    'statistics.shifts.possessionCount': {
        message: '球权计算',
        title: '本次轮换中，球队制造的球员球权数.'
    },
    'statistics.shifts.giveaways': {
        message: '失误数',
        title: '轮换中球队的失误数'
    },
    'statistics.shifts.takeaways': {
        message: '抄截数',
        title: '在轮换中球队的抄截数'
    },
    'statistics.shifts.recoveries': {
        message: '取得球权',
        title: '在轮换中本方球队的取得球权数'
    },
    'statistics.shifts.passes': {
        message: '传球',
        title: '本次轮换中，球队制造的传球数'
    },
    'statistics.shifts.shotAttempts': {
        message: '尝试射门',
        title: '轮换中球队的尝试射门数'
    },
    'statistics.shifts.xG': {
        message: 'XG',
        title: '轮换过程中球队制造的总进球期望值'
    },
    'statistics.shifts.shotsOnGoal': {
        message: 'Shots on goal'
    },
    'statistics.shifts.numberOfShifts': {
        message: 'Number of shifts'
    },
    'statistics.shifts.averageShift': {
        message: 'Average shift'
    },
    'statistics.shifts.toi': {
        message: 'TOI'
    },
    'statistics.shifts.period': '节',
    'statistics.shifts.startTime': '开始时间',
    'statistics.shifts.endTime': '结束时间',
    'statistics.shifts.duration': '时长 ',

    /**************************************************************Game score***********************************************/
    'gameScore.playersTable.title': '球员',
    'gameScore.playersTable.gamesCount': '比赛',
    'gameScore.playersTable.totalAvgGameScore': '平均每场总得分',
    'gameScore.playersTable.offensiveAvgGameScore': '比赛的进攻平均得分',
    'gameScore.playersTable.defensiveAvgGameScore': '比赛的防守平均得分',
    'gameScore.playersTable.toi': '总上场时间',
    'gameScore.playersTable.ppToi': '多打少上场时间',
    'gameScore.playersTable.pkToi': '少防多上场时间',
    'gameScore.gameScoreWidget.title': '比赛评分',
    'gameScore.gameScoreWidget.goaltenders': '门将',
    'gameScore.gameScoreWidget.overall': '总览',
    'gameScore.gameScoreWidget.offensive': '前锋',
    'gameScore.gameScoreWidget.defensive': '后卫',
    'gameScore.gameScoreWidget.header.metric': '数据项',
    'gameScore.gameScoreWidget.header.value': '价值',
    'gameScore.gameScoreWidget.header.value.player': '球员',
    'gameScore.gameScoreWidget.header.value.avg': '平均',
    'gameScore.gameScoreWidget.header.delta': '数值',
    'gameScore.gameScoreWidget.header.insight': '描述 (对手平均)',
    'gameScore.metric.relations.more': '更多',
    'gameScore.metric.relations.less': '少于',
    'gameScore.metric.xg.title': 'XG',
    'gameScore.metric.xg.def': '所有球员的射门是 {percentage} {relation} 危险的.',
    'gameScore.metric.goals.title': '进球数',
    'gameScore.metric.goals.def': '球员得分 {percentage} {relation} 进球.',
    'gameScore.metric.xgPP.title': '进球期望值值（多打少）',
    'gameScore.metric.xgPP.def': '球员在多打少中的射门是 {percentage} {relation} 有威胁的.',
    'gameScore.metric.xgPerShot.title': '尝试射门XG',
    'gameScore.metric.xgPerShot.def': '球员的射门是 {percentage} {relation} 危险的.',
    'gameScore.metric.xgPerShotOnGoal.title': '进球期望值 / 尝试射门数 (多打少)',
    'gameScore.metric.xgPerShotOnGoal.def': '球员在多打少中的射门是 {percentage} {relation} 有威胁的.',
    'gameScore.metric.xa.title': '助攻',
    'gameScore.metric.xa.def': '球员的助攻是 {percentage} {relation} 危险的.',
    'gameScore.metric.xaPP.title': '助攻（多打少）',
    'gameScore.metric.xaPP.def': '球员在多打少中的助攻 {percentage} {relation} 是有威胁的.',
    'gameScore.metric.xa2.title': '二次助攻',
    'gameScore.metric.xa2.def': '球员的第二潜在助攻是 {percentage} {relation} 有威胁的.',
    'gameScore.metric.xa2PP.title': '二次助攻（多打少）',
    'gameScore.metric.xa2PP.def': '球员在多打少时的第二助攻数是 {percentage} {relation} 有威胁的.',
    'gameScore.metric.keyPasses.title': '关键传球',
    'gameScore.metric.keyPasses.def': '球员制造 {percentage} {relation} 关键传球 (导致了有威胁的射门).',
    'gameScore.metric.entries.title': '进入攻区',
    'gameScore.metric.entries.def': '球员制造 {percentage} {relation} 进入对手的守区.',
    'gameScore.metric.controlledEntries.title': '在控制下 进入攻区',
    'gameScore.metric.controlledEntries.def':
        '球员制造 {percentage} {relation} 球在控制状态下的进入 (通过带球或者传球) 对手的攻区.',
    'gameScore.metric.successfulEntries.title': '进入攻区成功率%',
    'gameScore.metric.successfulEntries.def': '球员进入攻区 {percentage} {relation} 成功并且在攻区护住球权.',
    'gameScore.metric.controlledSuccessfulEntries.title': '球在控制状态下的进入攻区 SR%',
    'gameScore.metric.controlledSuccessfulEntries.def':
        '球员控球进入攻区 {percentage} {relation} 成功并且在攻区护住球权.',
    'gameScore.metric.xgRebound.title': '进球期望值（反弹球）',
    'gameScore.metric.xgRebound.def': '反弹球球员的总射门是 {percentage} {relation} 有威胁的.',
    'gameScore.metric.recoveries.title': '取得球权',
    'gameScore.metric.recoveries.def': '球员制造 {percentage} {relation} 不属于任何一方球权的球的球权获得.',
    'gameScore.metric.rebounds.title': '反弹球',
    'gameScore.metric.rebounds.def': '球员制造 {percentage} {relation} 反弹球被扑出后的取得球权.',
    'gameScore.metric.recoveriesOz.title': '获得球权（攻区）',
    'gameScore.metric.recoveriesOz.def': '球员 {percentage} {relation} 在攻区接收到不属于任何一方球权的球',
    'gameScore.metric.reboundsOz.title': '反弹球（攻区）',
    'gameScore.metric.reboundsOz.def': '球员制造 {percentage} {relation} 在攻区扑救出来的反弹球的接收.',
    'gameScore.metric.checks.title': '阻截',
    'gameScore.metric.checks.def': '球员制造 {percentage} {relation} 阻截',
    'gameScore.metric.recoveriesDz.title': '获得球权（守区）',
    'gameScore.metric.recoveriesDz.def': '球员制造 {percentage} {relation} 在守区的球权不在任何一方的球的接收.',
    'gameScore.metric.reboundsDz.title': '反弹球（守区）',
    'gameScore.metric.reboundsDz.def': '球员制造 {percentage} {relation} 在守区扑救出来的反弹球的接收.',
    'gameScore.metric.interceptions.title': '抄截数',
    'gameScore.metric.interceptions.def': '球员制造 {percentage} {relation} 导致球权转换的抄截.',
    'gameScore.metric.interceptionsOz.title': '抄截（攻区）',
    'gameScore.metric.interceptionsOz.def': '球员制造 {percentage} {relation} 在攻区的造成球权转换的抄截.',
    'gameScore.metric.interceptionsDz.title': '抄截（守区）',
    'gameScore.metric.interceptionsDz.def': '球员制造 {percentage} {relation} 在守区的抄截.',
    'gameScore.metric.xgAgainst.title': '进球期望值（对手）',
    'gameScore.metric.xgAgainst.def': '球员 {percentage} {relation} 在场上时对手的危险射门数',
    'gameScore.metric.dispossessed.title': '被抢断',
    'gameScore.metric.dispossessed.def': '球员失去 {percentage} {relation} 对球的球权控制.',
    'gameScore.metric.dispossessedDz.title': '在守区被抢断',
    'gameScore.metric.dispossessedDz.def': '球员失去 {percentage} {relation} 在守区的球权.',
    'gameScore.metric.xgAgainstNearNet.title': '进球期望值（对手，球门附近）',
    'gameScore.metric.xgAgainstNearNet.def': '球员让 {percentage} {relation} 对手在球门前右侧区域做出的危险射门',
    'gameScore.metric.keyPassesAgainst.title': '关键传球（对手）',
    'gameScore.metric.keyPassesAgainst.def':
        '球员让{percentage} {relation} 对手传出的关键球 (passes leads to high danger shot).',
    'gameScore.metric.xgAgainstEntries.title': '对手进球期望值 [进入攻区]',
    'gameScore.metric.xgAgainstEntries.def': '球员让 {percentage} {relation}对手进入攻区3秒内的有威胁射门.',
    'gameScore.metric.blockedShots.title': '封堵射门',
    'gameScore.metric.blockedShots.def': '球员封堵 {percentage} {relation} 尝试射门.',
    'gameScore.metric.xgShotAttemptsAgainst.title': '进球期望值/尝试射门（对手）',
    'gameScore.metric.xgShotAttemptsAgainst.def': '球员让 {percentage} {relation} 在场上时对手的威胁射门.',
    'gameScore.metric.avgSpeed.title': '平均速度',
    'gameScore.metric.avgSpeed.def': '球员的平均速度是 {percentage} {relation}.',
    'gameScore.metric.pace.title': '高速滑冰占比',
    'gameScore.metric.pace.def': '球员 {percentage} {relation} 在进行20公里/小时以上的滑冰距离',
    'gameScore.metric.backCheckPace.title': '高速回防比',
    'gameScore.metric.backCheckPace.def': '球员覆盖 {percentage} {relation} 当对手进攻时本人超过20公里/小时的滑冰距离',
    'gameScore.metric.distanceCovered.title': '覆盖的距离',
    'gameScore.metric.distanceCovered.def': '球员跑动 {percentage} {relation} 距离.',
    'gameScore.metric.acceleration.title': '加速',
    'gameScore.metric.acceleration.def': '球员加速 {percentage} {relation}.',
    'gameScore.metric.xgAgainstShotsOnGoal.title': '进球期望值（对手，射正）',
    'gameScore.metric.xgAgainstShotsOnGoal.def': '门将面对 {value} 危险射正的次数',
    'gameScore.metric.goalsAgainstShotsOnGoal.title': '进球 (对手, 射正)',
    'gameScore.metric.goalsAgainstShotsOnGoal.def': '门将产生 {value} 失球.',
    'gameScore.metric.xgAgainstNearNetGoaltenders.title': '进球期望值值 (对手, 球门附近)',
    'gameScore.metric.xgAgainstNearNetGoaltenders.def': '门将面对 {value} 球门右前方区域的威胁射门.',
    'gameScore.metric.goalsAgainstNearNet.title': '进球 (对手, 球门附近)',
    'gameScore.metric.goalsAgainstNearNet.def': '门将让{value} 来自于门前右侧区域的射门成为进球',
    'gameScore.metric.saves.title': '扑救, % ',
    'gameScore.metric.saves.def': '门将扑救 {value} 射门',
    'gameScore.metric.savesNearNet.title': '扑救, % (球门附近)',
    'gameScore.metric.savesNearNet.def': '门将扑救 {value} 球门右前方区域的射门.',
    'playerPage.modal.title': '选择了少于5场比赛.\u00A0',
    'shiftsPage.modal.title': null,
    'playerPage.modal.description': ' 页面数据可能不精确或有偏差.\u00A0',
    'playerPage.modal.advice': ' 如果可能，为了更精确的分析请选择5场以上的比赛.',
    'playerPage.playerStats.title': '球员数据',
    'playerPage.playerStats.games': '比赛',
    'playerPage.playerStats.avgToi': '平均上场时间',
    'playerPage.playerStats.goals': '进球',
    'playerPage.playerStats.assists': '助攻',
    'playerPage.playerStats.avgGs': 'AVG GS',
    'playerPage.playerStats.gameScoreTrend': '比赛进球趋势',
    'playerPage.playerStats.hotTitle': '与自己的平均表现相比，球员在过去三场比赛里的整体表现有长足的进步.',
    'playerPage.playerStats.coldTitle': '与自己的平均表现相比，球员在过去三场比赛里的整体表现有较大的退步.',
    'playerPage.playerStats.PK': '少防多',
    'playerPage.playerStats.EV': '人数均等',
    'playerPage.playerStats.PP': '多打少',
    'playerPage.playerStats.skillsChart': '技巧图表',
    'playerPage.radar.skills': '技巧',
    'playerPage.radar.stat.successPasses': '传球准确性',
    'playerPage.radar.stat.pace': '高速滑',
    'playerPage.radar.stat.xgPer20': '平均XG / 20分钟',
    'playerPage.radar.stat.giveaways': '控球',
    'playerPage.radar.stat.xgForPer20': '实际XG / 20分钟',
    'playerPage.radar.stat.xgAgainstPer20': '对手XG / 20分钟',
    'playerPage.radar.stat.xgPerShot': 'XG射门值',
    'playerPage.radar.stat.reboundRecoveriesPer20': '取得反弹球 / 20分钟',
    'playerPage.radar.stat.checksPer20': '阻截 / 20分钟',
    'playerPage.radar.stat.speedWithPuck': '速度\u00A0带球',
    'playerPage.radar.stat.xgOnIce': '场上XG',
    'playerPage.radar.stat.xaPer20': '助攻 / 20分钟',
    'playerPage.radar.stat.controlledEntries': '控球进入攻区',
    'playerPage.radar.stat.interceptionsPer20': ' 抄截\u00A0/ 20分钟',
    'playerPage.radar.stat.successExits': '成功离开守区',
    'playerPage.radar.stat.successThroughtPassesPer20': ' 通过传球的成功 / 20分钟',
    'playerPage.radar.stat.reboundRecoveriesDzPer20': '反弹球.\u00A0Rec. DZ\u00A0/\u00A020',
    'playerPage.radar.stat.shotBlocking': '封堵射门',
    'playerPage.radar.stat.shotsPer20': '射门 / 20分钟',
    'playerPage.eventMap.title': '事件图',
    'playerPage.eventMap.tabs.FACE_OFFS': '争球',
    'playerPage.eventMap.tabs.RECOVERIES': '球权获得',
    'playerPage.eventMap.tabs.SHOTS': '射门',
    'playerPage.skillsTable.header': '技巧',
    'playerPage.skillsTable.shooting': '射门',
    'playerPage.skillsTable.shots_on_goal_20': '射正',
    'playerPage.skillsTable.shot_accuracy': '射门准确, %',
    'playerPage.skillsTable.xg_20': 'XG%',
    'playerPage.skillsTable.shots_blocked_20': '被封堵射门',
    'playerPage.skillsTable.shots_lth_20': '在 LTH 传球后的射门',
    'playerPage.skillsTable.xg_lth': '在 LTH pass后的进球期望值值',
    'playerPage.skillsTable.xg_shot_attempt': '每次射门XG',
    'playerPage.skillsTable.shooting_from_danger_zone_percent': '从危险区域的射门, %',
    'playerPage.skillsTable.transition': '转换',
    'playerPage.skillsTable.controlled_entries_percent': '球在控制下进入攻区, %',
    'playerPage.skillsTable.successful_entries_percent': '成功进入攻区, %',
    'playerPage.skillsTable.avg_speed_with_puck_nz': '带球速度 (NZ)',
    'playerPage.skillsTable.successful_passes_into_nz_20': '成功传球到中区',
    'playerPage.skillsTable.controlled_exits_percent': '球在控制下离开守区, %',
    'playerPage.skillsTable.successful_exits_percent': '成功离开守区, %',
    'playerPage.skillsTable.skating': '滑冰',
    'playerPage.skillsTable.avg_pace': '高速滑冰, %',
    'playerPage.skillsTable.backchecking_pace_percent': '高速回防, %',
    'playerPage.skillsTable.avg_speed': '平均速度',
    'playerPage.skillsTable.avg_shifts_length': '平均轮换时长',
    'playerPage.skillsTable.acceleration': '加速度',
    'playerPage.skillsTable.avg_speed_with_puck': '平均带球速度',
    'playerPage.skillsTable.distance': '距离',
    'playerPage.skillsTable.onIce': '上场',
    'playerPage.skillsTable.xg_danger_zone_against': '来自危险区域的进球期望值值',
    'playerPage.skillsTable.puck_time_into_oz_percent': '球在攻区时间%',
    'playerPage.skillsTable.shots_percent': '射门, %',
    'playerPage.skillsTable.xg_percent': 'XG%',
    'playerPage.skillsTable.on_ice_xg_for_20': '本方XG',
    'playerPage.skillsTable.on_ice_xg_against_20': '对手XG',
    'playerPage.skillsTable.passingAndPossession': '传球 & 控球',
    'playerPage.skillsTable.individual': null,
    'playerPage.skillsTable.giveaways_percent': '失误, %',
    'playerPage.skillsTable.interceptions_20': '抄截',
    'playerPage.skillsTable.rebound_recoveries_20': '获得球权',
    'playerPage.skillsTable.checks_20': '阻截',
    'playerPage.skillsTable.possession_time_20': '控球时间',
    'playerPage.skillsTable.blocked_shots_percent': '射门封堵 %',
    'playerPage.skillsTable.dispossessed_dz': '每20分钟被阻截失去球权数 (守区)',
    'playerPage.skillsTable.rebound_recoveries_oz_20': '取得反弹球 (OZ)',
    'playerPage.skillsTable.successful_passes_percent': '成功传球, %',
    'playerPage.skillsTable.xa_20': '助攻',
    'playerPage.skillsTable.xa2_20': '二次助攻',
    'playerPage.skillsTable.successful_through_passes': '通过传球的成功',
    'playerPage.skillsTable.key_passes_20': '关键传球',
    'playerPage.skillsChart.games': '比赛',
    'playerPage.skillsChart.skillsDynamicsChart': '技巧动态图',
    'playerPage.skillsChart.descriptions.shots_on_goal_20': '在场上每20分钟的射正数',
    'playerPage.skillsChart.descriptions.shot_accuracy': '尝试射门进球率',
    'playerPage.skillsChart.descriptions.xg_20':
        '球员在场上时,每20分钟贡献的期望进球值eG. 此算法包含30个维度的相关数据,包括射门角度,门前的人员密集度,传球距离,和门将的移动.',
    'playerPage.skillsChart.descriptions.shots_blocked_20': '球员在场上时,每20分钟内被对手封堵的射门数',
    'playerPage.skillsChart.descriptions.shots_lth_20':
        '展示球员在接到队友的LTH传球后作出的射门数. LTH传球指得是球从球门前后的低位向攻区蓝线方向高位的传球,包括直接传球和击墙传球.',
    'playerPage.skillsChart.descriptions.xg_lth': '接到LTH传球后形成的进球期望值值.',
    'playerPage.skillsChart.descriptions.xg_shot_attempt':
        '期望进球值eG/尝试射门数. 此算法展示的事球员尝试射门的平均威胁度.',
    'playerPage.skillsChart.descriptions.shooting_from_danger_zone_percent':
        '从危险区域射门射正的概率. 危险区域的射门会形成高少防多转换值，更高的射门准确性意味着更大的机会去创造进球或形成反弹.',
    'playerPage.skillsChart.descriptions.controlled_entries_percent':
        '进入攻区，且从中区到攻区之间球权一直在本方控制下. 包括传球和带球两种方式.',
    'playerPage.skillsChart.descriptions.successful_entries_percent': '进入攻区并保持球权控制的占比.',
    'playerPage.skillsChart.descriptions.avg_speed_with_puck_nz':
        '球员在中区控制球时的平均滑冰速度. 中区滑冰速度可以造成区域的球权控制并迫使对手失位.',
    'playerPage.skillsChart.descriptions.successful_passes_into_nz_20': '中区的传球成功率.',
    'playerPage.skillsChart.descriptions.controlled_exits_percent':
        '球队离开守区时保持球权控制的能力. 分为传球和带球两种方式..',
    'playerPage.skillsChart.descriptions.successful_exits_percent': '从守区离开进入中区后仍然保持球权的概率.',
    'playerPage.skillsChart.descriptions.avg_pace':
        '球员在场上时的平均高速滑冰, 计算方法是滑冰速度超过20公里/小时的距离/总滑冰距离',
    'playerPage.skillsChart.descriptions.backchecking_pace_percent':
        '高速回防占比展示的是球员在回防过程中，以超过一个特定速度的滑冰的距离之占比. 数据越高表明球员回防越积极.',
    'playerPage.skillsChart.descriptions.avg_speed': '展示的是球员的平均滑冰速度.',
    'playerPage.skillsChart.descriptions.avg_shifts_length': '球员的平均轮换时长.',
    'playerPage.skillsChart.descriptions.acceleration': '展示的是球员的平均加速度.',
    'playerPage.skillsChart.descriptions.avg_speed_with_puck': '平均带球速度展示的是球员带球滑冰的能力.',
    'playerPage.skillsChart.descriptions.distance': '球员每20分钟的滑冰距离',
    'playerPage.skillsChart.descriptions.xg_danger_zone_against':
        '球员在场上时，每20分钟在危险区域创造的进球期望值值. 在危险区域的射门能够创造更大的进球概率.',
    'playerPage.skillsChart.descriptions.puck_time_into_oz_percent': '球员在场上的这段时间内，球在攻区的停留时间.',
    'playerPage.skillsChart.descriptions.shots_percent':
        '球员在场上时，本方球队的射正比. 此数据超过50%意味着球员在场上时，本方比对手创造出了更多的射正数.',
    'playerPage.skillsChart.descriptions.xg_percent':
        '球员在场上时，本方球队创造出的场上进球期望值值占比. 此数据超过50%意味着球员在场上时，本方比对手创造出了更多的进球期望值值（进球概率）.',
    'playerPage.skillsChart.descriptions.on_ice_xg_for_20': '球员在场上时，本队创造出的总进球期望值值.',
    'playerPage.skillsChart.descriptions.on_ice_xg_against_20': '球员在场上时，对手创造出的总进球期望值值.',
    'playerPage.skillsChart.descriptions.giveaways_percent':
        '球员控球但最终球权归于对手的次数. 这里看的是球员拥有球权的次数和球权的最终归属（不包含尝试射门和尝试进入攻区/离开守区).',
    'playerPage.skillsChart.descriptions.interceptions_20': '球员的抄截数',
    'playerPage.skillsChart.descriptions.rebound_recoveries_20':
        '球员在场上时，每20分钟获得自由球的次数. 意思是当时球不属于任何一方的控制下.',
    'playerPage.skillsChart.descriptions.checks_20': '球员在场上时，每20分钟通过身体阻截或球杆阻截造成的球权转换数.',
    'playerPage.skillsChart.descriptions.possession_time_20': '球员用球杆控球的总时间. 包含所有三个区域. ',
    'playerPage.skillsChart.descriptions.blocked_shots_percent':
        '显示球员封堵对手射门的能力. 概率越高表示球员的封堵能力越强.',
    'playerPage.skillsChart.descriptions.dispossessed_dz':
        ' 球员在守区失去球权的次数. 对防守球员来来,数据越少，显示球员在守区的控球能力越强.',
    'playerPage.skillsChart.descriptions.rebound_recoveries_oz_20':
        '此算法显示球员在场上时，每20分钟的射门反弹的球权获得数.',
    'playerPage.skillsChart.descriptions.successful_passes_percent': '球员的传球成功率. 传球成功/总尝试传球.',
    'playerPage.skillsChart.descriptions.xa_20': '球员第一潜在助攻创造出的进球期望值值.',
    'playerPage.skillsChart.descriptions.xa2_20': '球员的第二潜在助攻创造出的进球期望值值.',
    'playerPage.skillsChart.descriptions.successful_through_passes':
        '每20分钟向对手球门方向的传球数, 有被对手抄截的潜在风险. 此算法显示球员在面对对手防守时的比赛能力.',
    'playerPage.skillsChart.descriptions.key_passes_20':
        '此算法评估球员制造出高进球期望值值的传球能力. 导致高进球期望值值的传球被视为关键传球. ',
    'playerPage.barChart.allGames': '所有比赛',
    'playerPage.barChart.lastTenGames': '过去 10场',
    'playerPage.barChart.tooltip.game': '比赛',
    'playerPage.barChart.tooltip.gameScore': '比分得分',
    'playerComparison.tool.title': '球员对比工具',
    'playerComparison.chart.controls.xAxis': 'X轴',
    'playerComparison.chart.controls.yAxis': 'Y轴',
    'playerComparison.chart.controls.calculation': '计算',
    'playerComparison.chart.controls.apply': '应用',
    'playerComparison.chart.controls.resetZoom': 'Reset Zoom',
    'playerComparison.chart.controls.returnZoom': 'Return Zoom',
    'playerComparison.calculationTypes.RAW': '原始',
    'playerComparison.calculationTypes.PER_20': '每20',
    'playerComparison.calculationTypes.AVG': '平均',
    'playerComparison.chart.controls.jerseyVisibility': '显示球员号码',
    'playerComparison.chart.controls.lastNameVisibility': '显示球员名字',

    'configurator.group.onIce': null,
    'configurator.group.shifts': null,
    'configurator.group.faceoffs': null,
    'configurator.group.possession': null,
    'configurator.group.giveaways': null,
    'configurator.group.xG': null,
    'configurator.group.interceptions': null,
    'configurator.group.recoveries': null,
    'configurator.group.checks': null,
    'configurator.group.entries': null,
    'configurator.group.exits': null,
    'configurator.group.boxscore': null,
    'configurator.group.passing': null,
    'configurator.group.shooting': null,
    'configurator.group.pace': null,

    'playerComparison.metrics.on_ice_xg_for': {
        message: '攻方XG',
        title: '攻方XG'
    },
    'playerComparison.metrics.on_ice_xg_against': {
        message: '守方XG',
        title: '守方XG'
    },
    'playerComparison.metrics.games': {
        message: '赛事',
        title: '赛事'
    },
    'playerComparison.metrics.toi': {
        message: '上场时间',
        title: '上场时间'
    },
    'playerComparison.metrics.avg_rest_time': {
        message: '平均休息时间',
        title: '平均休息时间'
    },
    'playerComparison.metrics.max_shoot_speed': {
        message: 'Max. shoot speed',
        title: 'Max. shoot speed'
    },
    'playerComparison.metrics.avg_shoot_speed': {
        message: '平均射球速度',
        title: '平均射球速度'
    },
    'playerComparison.metrics.scoring_chances': {
        message: 'Scoring chances',
        title: 'Scoring chances'
    },
    'playerComparison.metrics.avgShiftLength': {
        message: '平均轮换时间',
        title: '平均轮换时间'
    },
    'playerComparison.metrics.blocked_shots_by': {
        message: '封堵球',
        title: '封堵球'
    },
    'playerComparison.metrics.body_check': {
        message: '身体拦截',
        title: '身体拦截'
    },
    'playerComparison.metrics.body_check_oz': {
        message: 'Body check, OZ',
        title: 'Body check, OZ'
    },
    'playerComparison.metrics.body_check_dz': {
        message: '身体拦截，危险区',
        title: '身体拦截，危险区'
    },
    'playerComparison.metrics.body_check_nz': {
        message: 'Body check, NZ',
        title: 'Body check, NZ'
    },

    /***************************************************************Pre-scout product**************************************************/
    'summary.stats.table': 'stats',

    /***************************************************************Scout product**************************************************/
    'scout.player.selected': 'Selected player',

    /***************************************************************Widget**************************************************/
    'widget.header.per20View': '每20',
    'widget.header.defaultView': '总共',
    'widget.header.per2Pp': '每多打少',
    'widget.header.per2Pk': '每少防多',
    'widget.header.onIce': '上场',
    'widget.header.individual': '个人',
    'widget.header.favorites': 'FAVORITES',
    'widget.header.metrics': null,
    'widget.header.video': null,

    /****************************************************************Post Game Reports***********************************/
    'postGameReportPage.tables.columns.game': '比赛',
    'postGameReportPage.tables.columns.season': '赛季',
    'postGameReportPage.tables.columns.goal': '目标',
    'postGameReportPage.tables.columns.lineXg': '组 xG %',
    'postGameReportPage.tables.columns.lineToi': '组上场时间',
    'postGameReportPage.tables.rows.possessionTime': '球权时间, %',
    'postGameReportPage.tables.rows.puckToiOz': '攻区时间, %',
    'postGameReportPage.tables.rows.shotAttempts': '射门, %',
    'postGameReportPage.tables.rows.shotAttemptsDangerZone': '守区失球%',
    'postGameReportPage.tables.rows.shotsOnGoal': '射正, %',
    'postGameReportPage.tables.rows.xg': 'xG%',
    'postGameReportPage.tables.rows.giveaways': '失误数, %',
    'postGameReportPage.tables.rows.faceOffWon': '争球胜, %',
    'postGameReportPage.tables.rows.successfulEntries': '进攻区成功率,%',
    'postGameReportPage.tables.rows.successfulExits': '出守区成功率,%',
    'postGameReportPage.tables.rows.savesDangerZone': '危险球扑救 %',
    'postGameReportPage.tables.rows.controlledEntries': 'Controlled entries, %',
    'postGameReportPage.tables.rows.controlledExits': 'Controlled exits, %',
    'postGameReportPage.tables.rows.ppTotal': '多打少次数 / 多打少时间',
    'postGameReportPage.tables.rows.powerPlayGoals': '多打少, %',
    'postGameReportPage.tables.rows.xgForPer2': '本方每2分钟xG',
    'postGameReportPage.tables.rows.reboundRecoveriesOz': '在攻区取得反弹球%',
    'postGameReportPage.tables.rows.pkTotal': '少防多次数 / 少防多时间',
    'postGameReportPage.tables.rows.penaltyKillGoalsAgainst': '少防多, %',
    'postGameReportPage.tables.rows.xgAgainstPer2': '对方每2分钟xG',
    'postGameReportPage.tables.rows.shotAttemptsDangerZoneAgainst': '有威胁射门, %',
    'postGameReportPage.tables.rows.reboundRecoveriesDz': '在守区取得反弹球%',
    'postGameReportPage.titles.evenStrength': '场上人数相等',
    'postGameReportPage.titles.offenseGameScores': 'OFFENSE GAME SCORES',
    'postGameReportPage.titles.defenseGameScores': 'DEFENSE GAME SCORES',
    'postGameReportPage.titles.GameScores': '球员',
    'postGameReportPage.titles.defensemen': '後卫',
    'postGameReportPage.titles.forwards': '前锋',
    'postGameReportPage.titles.powerPlay': '多打少',
    'postGameReportPage.titles.penaltyKill': '少防多',
    'postGameReportPage.titles.shotMap': '射门图',
    'postGameReportPage.labels.shotsFor': '对方射门',
    'postGameReportPage.info.xg': '总xG',
    'postGameReportPage.info.evXg': '场上人数相等 xG',
    'postGameReportPage.info.shotAttempts': '射门',
    'postGameReportPage.info.shotAttemptsFromDangerZone': '守区失球',
    'postGameReportPage.titles.goalie': '门将',
    'postGameReportPage.titles.noDefencemen': 'NO DEFENCEMEN',
    'postGameReportPage.titles.noForwards': 'NO FORWARDS',

    /***************************************************************Help**************************************************/
    'summary.xGMap.helpTitle': '什么是 “期望进球值” (XG)?',
    'summary.xGMap.helpBody':
        'XG, 也叫期望进球值是射门转化为进球的几率. \n' +
        '与进球机会类似，但结果更为精确.  我们通过30个维度的计算（比如射门位置，门前的人数和位置等）来得出数据. \n' +
        '\n' +
        '0.05的期望进球值意思是这次射门有5%的概率转化为进球. 0.00的期望进球值意思是这次射门有1%的概率转化为进球. 数值越大进球机会越大.',
    'summary.xGMap.dz': '守区',
    'summary.xGMap.oz': '攻区',
    'summary.skaters.helpTitle': '什么是三星？',
    'summary.skaters.helpBody':
        '与进球机会类似，但结果更为精确.  我们通过30个维度的计算（比如射门位置，门前的人数和位置等）来得出数据.',
    'summary.xGTimeline.helpTitle': '什么是进球期望值值的时间线？',
    'summary.xGTimeline.helpBody':
        '冰球比赛中有大量的尝试射门. 因此冰球中射门的进球率远远小于其他运动. 这个时间线展示的是随着比赛的进行哪一方球队在进攻上压制住了对手.',
    'skaters.efficiency.helpTitle': '什么是效率图？',
    'skaters.efficiency.helpBody':
        '本图提取了比赛中的Corsi数据（双方的尝试射门对比）并将其做成可视化. 一个Fun球员指得是他在场上时比赛的射门就会多（包括本方和对手）. 一个Dull球员则代表相反的含义. 在图中，球员越接近good位置，说明他在场上时，球队在进攻端和控球上的表现越好.',
    'skaters.xGTopSkaters.helpTitle': '什么是进球期望值值的球员排行？',
    'skaters.xGTopSkaters.helpBody':
        '进球期望值%的计算是本方进球期望值值/（本方进球期望值值+对手进球期望值值）. 这显示了球员在场上时，本队和对手的高质量进球机会的对比.',
    'passing.chart.helpTitle': '什么是传球表？',
    'passing.chart.helpBody':
        '本图通过将潜在助攻/传球比这一数据可视化的方式，来展示球员的组织能力.  球员越多的传球形成潜在助攻，数据就越好. 潜在助攻指得是形成了有质量的尝试射门的传球. ',
    'skating.chart.helpTitle': '什么是滑冰者图表？',
    'skating.chart.helpBody':
        '高速滑冰在比赛中非常重要. 高速滑冰比是滑冰速度超过20公里/小时的距离. 本图显示球员在轮换中的滑冰能力以及最理想的轮换时长. ',

    /******************************************Report**********************************************************/
    'report.section.summary': '概要',
    'report.section.summary-lite': '概要',
    'report.section.skaters': '球员',
    'report.section.shooting': '射门',
    'report.section.passing': '传球',
    'report.section.goaltenders': '门将',
    'report.section.entries': '进入攻区',
    'report.section.entries-lite': '进入攻区',
    'report.section.entriesTitle': '进入攻区',
    'report.section.exits': '离开攻区',
    'report.section.exits-lite': '离开攻区',
    'report.section.possession': '球权',
    'report.section.skating': '滑冰',
    'report.widget.report': '报告',
    'report.panel.printReport': '打印报告',
    'report.panel.strength': '{strength}',

    /******************************************Admin**********************************************************/

    'scoutAdmin.leagues': null,
    'scoutAdmin.seasons': '赛季',
    'scoutAdmin.teams': '球队',
    'scoutAdmin.scoutGames': '比赛 (球探)',
    'scoutAdmin.gamesUpload': null,
    'scoutAdmin.playersTPE': null,

    'scoutAdmin.seasons.nameEn': '姓名（英文）',
    'scoutAdmin.seasons.nameRu': '姓名（Ru）',
    'scoutAdmin.seasons.status': 'Status',
    'scoutAdmin.seasons.dateBegin': '开始日期',
    'scoutAdmin.seasons.dateEnd': '日期截止',
    'scoutAdmin.teams.nameEn': '姓名（英文）',
    'scoutAdmin.teams.nameRu': '姓名（Ru）',
    'scoutAdmin.teams.shortNameEn': '姓名缩写 (英文)',
    'scoutAdmin.teams.shortNameRu': '姓名缩写 (Ru)',
    'scoutAdmin.teams.acronymEn': '首字母 (英)',
    'scoutAdmin.teams.acronymRu': '首字母 (Ru)',
    'scoutAdmin.teams.logo': 'Logo',
    'scoutAdmin.teams.addTeam': null,
    'scoutAdmin.teams.team': null,
    'scoutAdmin.scoutGames.id': null,
    'scoutAdmin.scoutGames.date': '日期',
    'scoutAdmin.scoutGames.time': null,
    'scoutAdmin.scoutGames.timeZone': null,
    'scoutAdmin.scoutGames.dateTime': null,
    'scoutAdmin.scoutGames.homeTeam': '主队',
    'scoutAdmin.scoutGames.awayTeam': '客队',
    'scoutAdmin.scoutGames.homeScore': '主队得分',
    'scoutAdmin.scoutGames.awayScore': '客队进球',
    'scoutAdmin.scoutGames.season': '赛季',
    'scoutAdmin.scoutGames.updateVideoLinks': '更新视频链接',
    'scoutAdmin.scoutGames.dialog.copyPlaceHolder': 'Azure storage file url',
    'scoutAdmin.scoutGames.dialog.copy': '复制',
    'scoutAdmin.scoutGames.dialog.stitchPlaceHolder': 'Azure storage directory url',
    'scoutAdmin.scoutGames.dialog.stitch': 'Stitch',
    'scoutAdmin.scoutGames.dialog.upload': '上传视频',
    'scoutAdmin.scoutGames.dialog.clear': '清理',

    'clientsAdmin.clients': null,
    'clientsAdmin.users': null,
    'clientsAdmin.clientUsers': null,
    'clientsAdmin.clientGames': null,
    'clientsAdmin.contracts': null,
    'clientsAdmin.deals': null,
    'clientsAdmin.scoutContracts': null,
    'clientsAdmin.favoriteTeams': null,
    'clientsAdmin.supportTemple': null,

    'clientsAdmin.clients.name': null,
    'clientsAdmin.users.username': null,
    'clientsAdmin.users.accountType': null,
    'clientsAdmin.users.password': null,
    'clientsAdmin.users.generatePassword': null,
    'clientsAdmin.users.passwordToClipboard': null,
    'clientsAdmin.users.demo': null,
    'clientsAdmin.supportTemple.belongsToValuableCustomer': null,
    'clientsAdmin.supportTemple.shouldVideoBeUploadedBySupport': null,
    'clientsAdmin.clientUsers.client': null,
    'clientsAdmin.clientUsers.user': null,
    'clientsAdmin.clientUsers.team': null,
    'clientsAdmin.clientUsers.analytic': null,
    'clientsAdmin.clientUsers.featuresMask': null,
    'clientsAdmin.clientUsers.emails': null,
    'clientsAdmin.clientUsers.locales': null,
    'clientsAdmin.clientUsers.picture': null,
    'clientsAdmin.clientUsers.rooms': null,
    'clientsAdmin.clientUsers.clearCaches': null,
    'clientsAdmin.clientGames.liteModuleTypes': null,
    'clientsAdmin.clientGames.addingType': null,
    'clientsAdmin.clientGames.client': null,
    'clientsAdmin.clientGames.gameIds': null,
    'clientsAdmin.clientGames.seasonId': null,
    'clientsAdmin.contracts.liteModulesMask': null,
    'clientsAdmin.contracts.team': null,
    'clientsAdmin.contracts.season': null,
    'clientsAdmin.contracts.client': null,
    'clientsAdmin.contracts.dateBegin': null,
    'clientsAdmin.contracts.dateEnd': null,
    'clientsAdmin.contracts.product': null,
    'clientsAdmin.contracts.fulfill': null,
    'clientsAdmin.contracts.dealId': null,
    'clientsAdmin.scoutContracts.client': null,
    'clientsAdmin.scoutContracts.season': null,
    'clientsAdmin.scoutContracts.league': null,
    'clientsAdmin.scoutContracts.team': null,
    'clientsAdmin.scoutContracts.dateStart': null,
    'clientsAdmin.scoutContracts.dateEnd': null,
    'clientsAdmin.favoriteTeams.user': null,
    'clientsAdmin.favoriteTeams.teamId': null,
    'clientsAdmin.favoriteTeams.clientId': null,
    'clientsAdmin.favoriteTeams.userAccountId': null,

    'scheduleAdmin.games': null,
    'scheduleAdmin.noClients': null,
    'scheduleAdmin.database': null,
    'scheduleAdmin.gamesUpload': null,
    'scheduleAdmin.shifts': null,

    'scheduleAdmin.apply': null,
    'scheduleAdmin.clear': null,

    'scheduleAdmin.allGames': null,
    'scheduleAdmin.lite': null,
    'scheduleAdmin.pro': null,
    'scheduleAdmin.scout': null,
    'scheduleAdmin.preScout': null,
    'scheduleAdmin.slowPremium': null,
    'scheduleAdmin.createNewGame': null,
    'scheduleAdmin.TBA': null,
    'scheduleAdmin.NA': null,
    'scheduleAdmin.addComment': null,

    'scheduleAdmin.createGame.apply': null,
    'scheduleAdmin.createGame.clear': null,
    'scheduleAdmin.createGame.fromDB': null,
    'scheduleAdmin.createGame.newGame': null,
    'scheduleAdmin.createGame.game': null,
    'scheduleAdmin.createGame.gameType': null,
    'scheduleAdmin.createGame.overtimeType': null,
    'scheduleAdmin.createGame.support': null,
    'scheduleAdmin.createGame.moderator': null,
    'scheduleAdmin.createGame.verifier': null,
    'scheduleAdmin.createGame.client': null,
    'scheduleAdmin.createGame.bugged': null,
    'scheduleAdmin.createGame.comment': null,
    'scheduleAdmin.createGame.date': null,
    'scheduleAdmin.createGame.season': null,
    'scheduleAdmin.createGame.homeTeam': null,
    'scheduleAdmin.createGame.awayTeam': null,
    'scheduleAdmin.createGame.expectedUploadDateTime': null,
    'scheduleAdmin.createGame.timeZone': null,
    'scheduleAdmin.createGame.time': null,
    'scheduleAdmin.createGame.status': null,

    'scheduleAdmin.attachFile.video': null,
    'scheduleAdmin.attachFile.protocol': null,
    'scheduleAdmin.attachFile.problems': null,
    'scheduleAdmin.attachFile.upload': null,
    'scheduleAdmin.attachFile.uploadVideo': null,
    'scheduleAdmin.attachFile.uploadGameSheet': null,
    'scheduleAdmin.attachFile.delete': null,
    'scheduleAdmin.attachFile.copy': null,
    'scheduleAdmin.attachFile.send': null,
    'scheduleAdmin.attachFile.videoUploaded': null,
    'scheduleAdmin.attachFile': null,
    'scheduleAdmin.attachFile.videoUploadingSuccess': null,
    'scheduleAdmin.attachFile.protocolUploadingSuccess': null,

    'scheduleAdmin.shifts.dateTimeFrom': null,
    'scheduleAdmin.shifts.dateTimeTo': null,
    'scheduleAdmin.shifts.capacity': null,
    'scheduleAdmin.shifts.type': null,
    'scheduleAdmin.shifts.create': null,
    'scheduleAdmin.shifts.delete': null,
    'scheduleAdmin.shifts.apply': null,
    'scheduleAdmin.shifts.gameType': null,
    'scheduleAdmin.shifts.saveShifts': null,
    'scheduleAdmin.shifts.addShift': null,

    /***************************************CrudTable*********************************************************/
    'components.field.create': '制造',
    'components.field.clear': '清理',
    'components.field.created': null,
    'components.field.error': null,
    'components.edit.close': null,
    'components.edit.cancel': null,
    'components.edit.save': null,
    'components.edit.title': null,

    /***************************************Glossary*********************************************************/
    'support.glossary.title': '词汇表',
    'support.glossary.info': '术语和解释清单',
    'support.glossary.entries': '{entries, plural, =1 { record} other { records}}',
    'support.videoManuals.title': '训练',
    'support.videoManuals.info': '视频教学',
    'glossary.components.quickJump': '快速跳过',
    'glossary.term.acc': '加速度, 米/秒²',
    'glossary.def.acc': '平均下来，一个球员在速度和方向上的变化 - 值越高越好（越高的值显示球员的爆发力越强）',
    'glossary.term.aade': '平均进攻用时 [进入攻区]',
    'glossary.def.aade': '当球离开攻区后攻击停止（时间段在球穿过蓝线进入攻区和之后又穿过蓝线离开攻区之间）.',
    'glossary.term.aadx': '平均进攻用时 [离开守区]',
    'glossary.def.aadx': '进攻从进入攻区开始，从离开攻区结束（蓝线）',
    'glossary.term.aar': '平均反弹角度',
    'glossary.def.aar': '球运行向两个目标的中间的反弹的位移矢量和需垂线之间的角度',
    'glossary.term.ape': '平均控球 [进入攻区]',
    'glossary.def.ape': '球离开攻区前的球队控球时间除以进入攻区的次数（球队控球时间从进攻开始时作统计）.',
    'glossary.term.apx': '平均控球 [离开守区]',
    'glossary.def.apx':
        '球从进入攻区到首次离开攻区蓝线之间的球队控球时间除以总离开攻区次数（控球时间从进攻开始时作统计）.',
    'glossary.term.asl': '平均轮换时长',
    'glossary.def.asl': '比赛中的轮换数除以总上场时间',
    'glossary.term.ass': '平均射门速度',
    'glossary.def.ass': '和最大射门速度一样，这里是平均值',
    'glossary.term.bac': '高速回防比',
    'glossary.def.bac': '对手球员进攻且球在中区或本方守区时，球员向守区高速回防的距离除以总回防滑冰距离.',
    'glossary.term.boc': '身体阻截',
    'glossary.def.boc':
        '球员用身体来讲对手和球分开，并导致球权转换的次数. 球之后被此球员或其队友接收. 数据统计在此球员身上.',
    'glossary.term.clr': '解围',
    'glossary.def.clr': '为了阻止对手进攻将球大力抽射出守区',
    'glossary.term.cin': '带球进入攻区 [攻区]',
    'glossary.def.cin': '用球杆带球进入攻区',
    'glossary.term.cout': '带球离开 [守区]',
    'glossary.def.cout': '用球杆带球离开守区（穿越蓝线）.',
    'glossary.term.deff': '防守效率',
    'glossary.def.deff': '根据防守数据计算出的效率值',
    'glossary.term.dzp': '守区传球',
    'glossary.def.dzp': '在守区形成的直接传球或传球失准.',
    'glossary.term.dis': '距离',
    'glossary.def.dis': '球员/球员滑冰距离',
    'glossary.term.din': '抛球进入[攻区]',
    'glossary.def.din': '进攻组将球射入攻区',
    'glossary.term.dout': '抛球离开[守区]',
    'glossary.def.dout': '当球队/球员在穿过守区蓝线前，在本方守区将球射出',
    'glossary.term.OZentry': '进入攻区',
    'glossary.def.OZentry':
        '进入攻区的标识是球被进攻方带过蓝线. 可以是带球，抛球，传球的方式，结果可定义为成功和未成功. ',
    'glossary.term.DZexit': '离开守区',
    'glossary.def.DZexit': '穿过守区蓝线时',
    'glossary.term.fow': '争球胜, %',
    'glossary.def.fow': '若球队或球员 (争球球员) 在哨响后的头2秒内保持球权的控制则赢得争球',
    'glossary.term.fpass': '传球失误',
    'glossary.def.fpass':
        '由球员传给队友的直传失误或击墙传失误导致队友失去球权控制. 传球是被抄截或被对手取得，或形成了死球.',
    'glossary.term.fcin': '带球进入失败 [进入攻区]',
    'glossary.def.fcin': '进入攻区后，球在4秒内又离开了攻区并且没有形成尝试射门.',
    'glossary.term.fdin': '抛球进入失败 [进入攻区]',
    'glossary.def.fdin': '进入攻区后没有拿到球权或者在4秒内球又离开了攻区并且没有产生尝试射门.',
    'glossary.term.fpin': '传球失败 [进入攻区]',
    'glossary.def.fpin': '球通过传给队友的方式穿过蓝线进入攻区（数据分配给传球人）',
    'glossary.term.gs': '比赛评分',
    'glossary.def.gs': '球员评值基于球员在某一段时间内或某几场比赛的数据统计的效率评估',
    'glossary.term.gva': '失误数',
    'glossary.def.gva': '当任何时候一个球队因为传球失误或失去球权导致的球到了对手控制下.',
    'glossary.term.gvap': '失误, %',
    'glossary.def.gvap': '失误数除以球权数',
    'glossary.term.zonegva': '区域失误',
    'glossary.def.zonegva': '失误所发生的区域',
    'glossary.term.zonegvap': '失误, % 在不同区域',
    'glossary.def.zonegvap': '在一个区域的失误数除以同区域的球权数.',
    'glossary.term.goal': '进球',
    'glossary.def.goal': '当球整体穿过球门线',
    'glossary.term.int': '抄截',
    'glossary.def.int': '导致球权转换的非直接传球数.',
    'glossary.term.mss': '最大射门速度',
    'glossary.def.mss': '通过衡量射门点和门将之间的距离除以球运动到门将或终点（射偏）的时间，计算出射门速度的最大评值.',
    'glossary.term.nzp': '中区传球数',
    'glossary.def.nzp': '从中区发生的直接传球或击墙传球',
    'glossary.term.nop': '球权数',
    'glossary.def.nop': '球员/球队的球权数',
    'glossary.term.znop': '不同区域的球权数',
    'glossary.def.znop': '在某一区域内球员的球权次数. 如果球员滑冰先后穿越了三个区域，此数据在每个区域统计一次.',
    'glossary.term.oze': '进攻效率',
    'glossary.def.oze': '根据进攻数据计算出的效率值',
    'glossary.term.ozp': '攻区传球数',
    'glossary.def.ozp': '从攻区产生的直接传球或击墙传球',
    'glossary.term.ozinf': '进攻阵型在攻区',
    'glossary.def.ozinf': '球员以进攻阵型在攻区控制球的时间.',
    'glossary.term.ozoof': '非进攻阵型在攻区时间',
    'glossary.def.ozoof': '进攻去在攻区以非进攻阵型控球的时间（如在蓝线和墙附近产生的争夺）',
    'glossary.term.ozt': '攻区时间 [多打少 - 组]',
    'glossary.def.ozt': '整组球员在场上时，球留在攻区的时间',
    'glossary.term.ozft': '攻区进攻时间比（多打少-组）',
    'glossary.def.ozft': '总多打少时间内的进攻阵型在攻区的时间占比',
    'glossary.term.pacep': '高速滑冰占比',
    'glossary.def.pacep': '高速滑冰距离（20公里/小时以上）除以总滑冰距离.',
    'glossary.term.pass': '传球',
    'glossary.def.pass': '直接传球 (player to player) 或传球失准.',
    'glossary.term.pine': '传进 [进入攻区]',
    'glossary.def.pine': '球通过传给队友的方式穿过蓝线进入攻区（数据分配给传球人）',
    'glossary.term.pout': '传出 [离开守区]',
    'glossary.def.pout': '通过传球的方式离开守区（数据计算在传球人身上）',
    'glossary.term.poss': '球权',
    'glossary.def.poss': '当球员接到球并保持对球的控制.',
    'glossary.term.possp': '球权, %',
    'glossary.def.possp': '当球员/一组球员在场上时，球队的控球率',
    'glossary.term.pot': '球权时间（球员）',
    'glossary.def.pot': '在典型的实时赛中情形下，球员用球杆控制球/掌握球权的时间.',
    'glossary.term.pap': '潜在助攻 (第一)',
    'glossary.def.pap': '在尝试射门前的最后一次射门（在传球和尝试射门中没有形成球权的转换）',
    'glossary.term.pas': '潜在助攻 (第二)',
    'glossary.def.pas': '第一潜在助攻前的最后一次传球（在第二潜在助攻，第一潜在助攻和尝试射门中没有发生球权转换）',
    'glossary.term.pr': '取得球权',
    'glossary.def.pr': '球员取得的球权不属于任何一方或任何一人控制下',
    'glossary.term.ptioz': '球在攻区时间%',
    'glossary.def.ptioz': '球员在场上时，球留在攻区的时间',
    'glossary.term.ptiozp': '球在攻区时间, %',
    'glossary.def.ptiozp': '当一组球员在场上时，球停留在攻区的时间占比',
    'glossary.term.rr': '取得反弹球',
    'glossary.def.rr': '球员在反弹球被扑出后取得球权的次数，包括进攻的或者防守的反弹球',
    'glossary.term.rbp': '反弹, %',
    'glossary.def.rbp': '射门形成反弹球数除以射正数',
    'glossary.term.rgp': '重组阵型',
    'glossary.def.rgp': '进攻组回到中区或守区重新发起进攻所需要的时间',
    'glossary.term.rush': '前场进攻',
    'glossary.def.rush': '进攻组在队形重组后进入攻区所需要的时间',
    'glossary.term.savep': '扑救成功率',
    'glossary.def.savep': '总射正数除以总扑救数',
    'glossary.term.scsa': '进球机会/尝试射门. %',
    'glossary.def.scsa': '确认对手尝试射门的威胁程度（进球机会除以尝试射门）',
    'glossary.term.sc': '进球机会',
    'glossary.def.sc':
        '1. 来自危险区域的尝试射门; 2. 来自潜在助攻的尝试射门 (穿过攻区蓝线的传球) 3. 连续的未被封堵的射门.',
    'glossary.term.shotp': '射门, %',
    'glossary.def.shotp': '进球数除以社正数.',
    'glossary.term.sa': '尝试射门',
    'glossary.def.sa': '尝试射门，或者间接折射，其方向冲着门将或者偏出，被封堵或折向.',
    'glossary.term.sap': '尝试射门, %',
    'glossary.def.sap': '球员/一组球员在场上时，贡献的尝试射门与总尝试射门的占比',
    'glossary.term.sape': '尝试射门[进入攻区]',
    'glossary.def.sape': '在进入攻区然后离开攻区的时间内，球队所创造出的尝试射门数.',
    'glossary.term.sapx': '尝试射门[离开守区]',
    'glossary.def.sapx': '球队在攻区制造的尝试射门数（数据在离开攻区后统计）. 球在进入攻区后的首次离开攻区蓝线后结束.',
    'glossary.term.sog': '射正',
    'glossary.def.sog': '尝试射门，或者折射后碰到门将.',
    'glossary.term.swtp': '带球速度, 米/秒',
    'glossary.def.swtp': '球员在场上拥有球权时的平均滑冰速度',
    'glossary.term.speed': '速度, m/s',
    'glossary.def.speed': '球员在场上时的平均滑冰速度',
    'glossary.term.sch': '球杆阻截',
    'glossary.def.sch': '用球杆把球从对手球杆下拨走，并导致球权转换的次数.',
    'glossary.term.sentry': '成功进入 [进入攻区]',
    'glossary.def.sentry': '进入攻区后，如果球没有再4秒内离开攻区或者4秒内形成了至少一次尝试射门.',
    'glossary.term.sucr': '成功率[进入攻区 / 离开守区]',
    'glossary.def.sucr': '成功进入攻区数除以进入攻区总数',
    'glossary.term.scine': '成功带球 [进入攻区]',
    'glossary.def.scine': '球进入攻区后，在4秒内没有离开攻区或者形成了一次尝试射门',
    'glossary.term.sdine': '抛球进入成功 [进入攻区]',
    'glossary.def.sdine': '抛球进入攻区后获得球权并且在4秒内没有离开攻区或形成了尝试射门',
    'glossary.term.spine': '成功传球[进入攻区]',
    'glossary.def.spine': '进入攻区后球被对手获得，并且球在4秒内没有离开攻区或者形成过一次尝试射门',
    'glossary.term.scoutx': '成功带球离开 [离开守区]',
    'glossary.def.scoutx': '带球离开守区，在3秒内没有再回到守区内.',
    'glossary.term.sdoutx': '成功抛球离开 [离开守区]',
    'glossary.def.sdoutx': '抛球离开守区后拿到球权或者球在10秒内没有回到守区. 底板球不算入统计.',
    'glossary.term.spoutx': '成功传球[离开守区]',
    'glossary.def.spoutx': '从守区传出的球被队友成功接到，并且在3秒内没有再回到守区',
    'glossary.term.spass': '成功传球',
    'glossary.def.spass': '球员通过直传或者击墙传的方式，给队友的传球并被队友成功接收.',
    'glossary.term.tpt': '球队控球时间',
    'glossary.def.tpt': '球队控球时间：包括球员控球，成功传球耗时，进入/离开区域后对球权的取得的耗时',
    'glossary.term.toi': '上场时间',
    'glossary.def.toi': "Total amount of time the player's skates were on the ice during game time.",
    'glossary.term.tpp': '每次球权所用时间',
    'glossary.def.tpp': '球权时间除以球权次数',
    'glossary.term.xge': '进球期望值 [进入攻区]',
    'glossary.def.xge': '进入攻区后到球离开攻区这段时间内球队创造出的平均期望进球值',
    'glossary.term.xgex': '进球期望值 [离开守区]',
    'glossary.def.xgex':
        '球队从守区离开后制造的平均进球期望值值. 当球离开守区进入攻区后，第一次离开攻区蓝线时，次统计截点结束.',
    'glossary.term.xg': '进球期望值',
    'glossary.def.xg':
        '球员的射门转化为进球的概率；由射门的诸多因素构成计算（例如射门距离，角度影响等），同时球员射门的位置，以及当时场上所有人的位置都考虑在内.',
    'glossary.term.zs': '区域轮换, %',
    'glossary.def.zs':
        '攻区区域轮换（当攻区发生争球时球员上场）除以总轮换（攻区轮换/（攻区轮换+守区轮换））.区域轮换显示的是球员上场时所面临的困难度. 数值越高，球员难度越低.',

    /****************************************Video manuals*****************************************************/
    'videoManuals.title.intro': '介绍',
    'videoManuals.link.intro': 'https://www.youtube.com/embed/0AR13Mi44AE',
    'videoManuals.title.filters': '筛选',
    'videoManuals.link.filters': 'https://www.youtube.com/embed/RI8eywnK2Bs',
    'videoManuals.title.video': '视频记录球员',
    'videoManuals.link.video': 'https://www.youtube.com/embed/Zshe6YhU91M',
    'videoManuals.title.skaters': '球员',
    'videoManuals.link.skaters': 'https://www.youtube.com/embed/xpfcolOPzCw',
    'videoManuals.title.pppk': '多打少和少防多',
    'videoManuals.link.pppk': 'https://www.youtube.com/embed/BcElhPv1txg',
    'videoManuals.title.reports': '报告',
    'videoManuals.link.reports': 'https://www.youtube.com/embed/eTpSyE1HBT0',
    'videoManuals.title.stats': '数据统计',
    'videoManuals.link.stats': 'https://www.youtube.com/embed/1JPY1S3ipg8',
    'videoManuals.title.shifts': '轮换',
    'videoManuals.link.shifts': 'https://www.youtube.com/embed/UOTkMfWPly0',

    /****************************************Date values*****************************************************/

    'calendar.view.week': '-',
    'calendar.view.month': '-',

    'calendar.weekday.monday': '-',
    'calendar.weekday.tuesday': '-',
    'calendar.weekday.wednesday': '-',
    'calendar.weekday.thursday': '-',
    'calendar.weekday.friday': '-',
    'calendar.weekday.saturday': '-',
    'calendar.weekday.sunday': '-',

    'calendar.weekday.short.monday': null,
    'calendar.weekday.short.tuesday': null,
    'calendar.weekday.short.wednesday': null,
    'calendar.weekday.short.thursday': null,
    'calendar.weekday.short.friday': null,
    'calendar.weekday.short.saturday': null,
    'calendar.weekday.short.sunday': null,

    'calendar.month.january': '-',
    'calendar.month.february': '-',
    'calendar.month.march': '-',
    'calendar.month.april': '-',
    'calendar.month.may': '-',
    'calendar.month.june': '-',
    'calendar.month.july': '-',
    'calendar.month.august': '-',
    'calendar.month.september': '-',
    'calendar.month.october': '-',
    'calendar.month.november': '-',
    'calendar.month.december': '-',

    /****************************************Clients cabinet values*****************************************************/

    'clientsCabinet.payment.successPayment':
        'Your purchase has been successfully payed. You will be redirected back soon.',
    'clientsCabinet.payment.failedPayment': 'Error occurred while processing your payment',
    'clientsCabinet.payment.goBack': 'GO BACK',
    'clientsCabinet.payment.gamesToBePurchased': 'Games to be purchased',
    'clientsCabinet.payment.pricePerGame': 'Price per game',
    'clientsCabinet.payment.totalAmount': 'Total amount',
    'clientsCabinet.payment.gamesLimitToBePurchased': 'Max. amount of games to be purchased: {games}',
    'clientsCabinet.payment.includingHst': 'Including HST/GST {taxPercent}%',
    'clientsCabinet.payment.payNow': 'PAY NOW',
    'clientsCabinet.payment.close': 'CLOSE',

    'clientsCabinet.calendar.purchaseCredits': null,
    'clientsCabinet.calendar.creditsLeft': null,
    'clientsCabinet.calendar.gamesPurchased': null,

    'clientsCabinet.calendar.videoUploader.placeholder': null,
    'clientsCabinet.calendar.videoUploader.placeholderHint': null,
    'clientsCabinet.calendar.videoUploader.cancelAll': null,
    'clientsCabinet.calendar.videoUploader.upload': null,
    'clientsCabinet.calendar.videoUploader.uploaded': null,
    'clientsCabinet.calendar.videoUploader.uploading': null,
    'clientsCabinet.calendar.videoUploader.rejected': null,
    'clientsCabinet.calendar.videoUploader.pending': null,
    'clientsCabinet.calendar.videoUploader.waiting': null,

    'clientsCabinet.calendar.videoUploader.description.h1': null,
    'clientsCabinet.calendar.videoUploader.description.h2': null,
    'clientsCabinet.calendar.videoUploader.description.h3': null,
    'clientsCabinet.calendar.videoUploader.description.h4': null,
    'clientsCabinet.calendar.videoUploader.description.h5': null,
    'clientsCabinet.calendar.videoUploader.description.h6': null,
    'clientsCabinet.calendar.videoUploader.description.p1': null,
    'clientsCabinet.calendar.videoUploader.description.p2': null,
    'clientsCabinet.calendar.videoUploader.description.p3': null,
    'clientsCabinet.calendar.videoUploader.description.p4': null,
    'clientsCabinet.calendar.videoUploader.description.p5': null,
    'clientsCabinet.calendar.videoUploader.description.p6': null,

    'clientsCabinet.calendar.sheetUploader.description.p1': null,
    'clientsCabinet.calendar.sheetUploader.description.li1': null,
    'clientsCabinet.calendar.sheetUploader.description.li2': null,
    'clientsCabinet.calendar.sheetUploader.description.li3': null,
    'clientsCabinet.calendar.sheetUploader.description.li4': null,
    'clientsCabinet.calendar.sheetUploader.description.p2': null,

    /****************************************Clients cabinet PRE-SCOUT products descriptions*****************************************************/

    'clientsCabinet.productsDescription.preScout.description.h1': 'TPE',
    'clientsCabinet.productsDescription.preScout.description.h2': 'PRE-SCOUT',
    'clientsCabinet.productsDescription.preScout.description.h3': 'Plan.',
    'clientsCabinet.productsDescription.preScout.description.h4': 'Prepare.',
    'clientsCabinet.productsDescription.preScout.description.h5': 'Execute.',

    'clientsCabinet.productsDescription.preScout.description.m1':
        'TPE Sports Analytics Pre-Scout Product provides teams with detailed insights and advanced scouting reports on their upcoming opponents.',

    'clientsCabinet.productsDescription.preScout.description.m2':
        'Our Pre-Scout Technology System will help coaches increase efficiency when preparing for upcoming games by automatically collecting highly relevant statistics and analytics including: ',

    'clientsCabinet.productsDescription.preScout.description.ul1.li1': 'Goals, assists, shots and shot attempts',

    'clientsCabinet.productsDescription.preScout.description.ul1.li2': 'Shifts breakdown by player',

    'clientsCabinet.productsDescription.preScout.description.ul1.li3': 'Face-offs',

    'clientsCabinet.productsDescription.preScout.description.ul1.li4':
        'Special teams play - power play and penalty kill',

    'clientsCabinet.productsDescription.preScout.description.ul1.li5': 'Corresponding video',

    'clientsCabinet.productsDescription.preScout.description.m3':
        'This list of statistics and metrics comes with corresponding video breakdown centered around coaches key points of preparation including: ',

    'clientsCabinet.productsDescription.preScout.description.ul2.li1': 'Special teams formations',

    'clientsCabinet.productsDescription.preScout.description.ul2.li2': 'Face-offs formations and strategy',

    'clientsCabinet.productsDescription.preScout.description.ul2.li3': 'Breakouts / forechecks',

    'clientsCabinet.productsDescription.preScout.description.ul2.li4': 'How / where opponent generates shots from',

    'clientsCabinet.productsDescription.preScout.description.ul2.li5':
        "Advanced scouting reports on the opposition's goaltender",

    'clientsCabinet.productsDescription.preScout.description.m4':
        'The time-saving this product generates will allow teams to realign and focus additional resources on other areas of preparation in advance of their games.',

    'clientsCabinet.productsDescription.preScout.description.m5':
        'Our Pre-Scout Technology System is set to revolutionize how teams and coaches prepare for games. Our  user-friendly portal is easy to navigate and filter by different metrics and events. It comes equipped with a Summary Page, Shifts Page and Goaltenders Page.',

    'clientsCabinet.productsDescription.preScout.description.m6': 'The Shifts Page ',

    'clientsCabinet.productsDescription.preScout.description.m7':
        'shows each individual player’s shifts and is accompanied with corresponding video of those shifts. It is optimal for pre-scouting specific players on the opposition and understanding their tendencies.',

    'clientsCabinet.productsDescription.preScout.description.m8': 'The Summary Page ',

    'clientsCabinet.productsDescription.preScout.description.m9':
        'allows you to filter by team, number of games (or quick button - last 5 games, last 10 games), specific periods and strength. It also comes equipped with: ',

    'clientsCabinet.productsDescription.preScout.description.ul3.li1': 'Shot location maps',

    'clientsCabinet.productsDescription.preScout.description.ul3.li2': 'Heat maps',

    'clientsCabinet.productsDescription.preScout.description.ul3.li3': 'Face-offs maps',

    'clientsCabinet.productsDescription.preScout.description.m10':
        'It includes a table with individual player statistics and metrics including: ',

    'clientsCabinet.productsDescription.preScout.description.m11':
        'goals, assists, shots, shot attempts, shots in the danger zone, hits, time on ice and more',

    'clientsCabinet.productsDescription.preScout.description.m12': 'The Goalies Page ',

    'clientsCabinet.productsDescription.preScout.description.m13': 'includes:',

    'clientsCabinet.productsDescription.preScout.description.ul4.li1':
        'Goalie Chart which highlights the opposing goaltender’s save percentage by shot location on net, allowing teams to easily identify areas of strength and weakness',

    'clientsCabinet.productsDescription.preScout.description.ul4.li2':
        'Shot Location Map which depicts the areas where the goaltender faces shots from',

    'clientsCabinet.productsDescription.preScout.description.ul4.li3':
        'Zones Map that indicates the goaltender’s save percentage on shots from different areas in the offensive zone',

    'clientsCabinet.productsDescription.preScout.description.m14':
        'Lastly, it comes equipped with corresponding video of all goals, shot attempts, scoring chances, shots with rebounds, shots without rebounds, blocked shots and missed shots',

    'clientsCabinet.productsDescription.preScout.description.m15':
        'In hockey’s increasingly competitive environment, access to relevant and accurate information on the opposition is crucial in order to strategize effectively and afford your team the best opportunity to win. TPE’s Pre-Scout Technology System will allow teams to do just that.',

    /****************************************Clients cabinet SCOUT products descriptions*****************************************************/

    'clientsCabinet.productsDescription.scout.description.h1': 'TPE',
    'clientsCabinet.productsDescription.scout.description.h2': 'SCOUT',
    'clientsCabinet.productsDescription.scout.description.h3': 'Identify.',
    'clientsCabinet.productsDescription.scout.description.h4': 'Recruit.',
    'clientsCabinet.productsDescription.scout.description.h5': 'Excel.',

    'clientsCabinet.productsDescription.scout.description.m1':
        'TPE Sports Analytics Scout Technology System is revolutionizing player management and how teams scout, identify, recruit and draft talent.',

    'clientsCabinet.productsDescription.scout.description.m2':
        'Our Scout Technology System provides teams and management with detailed insights and statistics as well as broken down game film on individual player’s throughout the entirety of their career. It will increase team’s scouting departments efficiency and coverage by allowing them to quickly and easily navigate through advanced analytics, statistics and game film on hundreds of players from their personal computers.',

    'clientsCabinet.productsDescription.scout.description.m3': 'Relevant statistics and analytics includes:',

    'clientsCabinet.productsDescription.scout.description.ul1.li1': 'Goals, assists, shots and shot attempts',

    'clientsCabinet.productsDescription.scout.description.ul1.li2': 'Shifts breakdown by player',

    'clientsCabinet.productsDescription.scout.description.ul1.li3': 'Face-offs',

    'clientsCabinet.productsDescription.scout.description.ul1.li4': 'Special teams play - power play and penalty kill',

    'clientsCabinet.productsDescription.scout.description.ul1.li5': 'Corresponding video',

    'clientsCabinet.productsDescription.scout.description.m4':
        'The time-saving this product generates will allow teams to realign and focus additional resources on other areas of hockey operations.',

    'clientsCabinet.productsDescription.scout.description.m5':
        'Our fast and reliable, user-friendly portal makes it easy to filter by player and navigate to an individual player’s profile page. It allows users to filter by team, league, and/or position. From there they are able to ',

    'clientsCabinet.productsDescription.scout.description.m6':
        'identify and sort leaders by specific metrics and statistics including; goals, assists, shots, shot attempts, shots in the danger zone, hits, time on ice and more.',

    'clientsCabinet.productsDescription.scout.description.m7': 'The Players Page ',

    'clientsCabinet.productsDescription.scout.description.m8': 'allows users to filter a player’s stats by:',

    'clientsCabinet.productsDescription.scout.description.ul2.li1': 'Last 5 games',

    'clientsCabinet.productsDescription.scout.description.ul2.li2': 'Last 10 games',

    'clientsCabinet.productsDescription.scout.description.ul2.li3': 'Current season / Career',

    'clientsCabinet.productsDescription.scout.description.ul2.li4': 'Home games / away games',

    'clientsCabinet.productsDescription.scout.description.ul2.li5': 'Game’s team won / lost ',

    'clientsCabinet.productsDescription.scout.description.ul2.li6':
        'Games against specific opponent / group of opponents',

    'clientsCabinet.productsDescription.scout.description.ul2.li7': 'Specific individual games',

    'clientsCabinet.productsDescription.scout.description.m9':
        'Player metrics and statistics in the selected games will be shown ',

    'clientsCabinet.productsDescription.scout.description.m10': 'compared to the league average for that position.',

    'clientsCabinet.productsDescription.scout.description.m11':
        'By selecting an individual metric, users will be able to see said player’s ',

    'clientsCabinet.productsDescription.scout.description.m12':
        'game-by-game tendencies and each corresponding video clip for the selected metric.',

    'clientsCabinet.productsDescription.scout.description.m13':
        'Lastly, the Players Page is equipped with TPE’s Player Comparison Tool which can be used for scouting (to compare different players) or when considering potential trades or call-ups.',

    'clientsCabinet.productsDescription.scout.description.m14':
        'In hockey’s increasingly competitive environment, access to relevant and accurate information on current and potential talent is crucial in order to build dynamic roster. TPE’s Scout Technology System will allow teams to do just that',

    playerProfile:
        '${values && values.firstNameZh && values.firstNameZh.length > 0 && values.firstNameZh[0] ? values.firstNameZh[0] : values && values.firstNameEn && values.firstNameEn.length > 0 && values.firstNameEn[0] ? values.firstNameEn[0] : ""}. ${values && values.lastNameZh && values.lastNameZh.length>0 ? values.lastNameZh : values && values.lastNameEn ? values.lastNameEn : ""}',
    nameOfTeam:
        '${values && values.nameZh && values.nameZh.length > 0 ? values.nameZh : values && values.nameEn ? values.nameEn : ""}',

    'entities.player.fullNameInverted':
        '${values&&values.lastNameZh&&values.lastNameZh.length>0 ? values.lastNameZh : values&&values.lastNameEn ? values.lastNameEn : "" } ${values && values.firstNameZh&&values.firstNameZh.length>0 ? values.firstNameZh[0] : values && values.firstNameEn ? values.firstNameEn[0] : ""}.',
    'scout.suggestion.player.name':
        '{firstNameRu&&firstNameZh.length>0?firstNameZh:firstNameEn?firstNameEn:""} {lastNameRu&&lastNameZh.length>0?lastNameZh:lastNameEn?lastNameEn:""}'
};

export default znDictionary;
