import { shotsSelector } from '../../../../../containers/widgets-stats/selectors/shots';
import {
    shots,
    createGoalsSelector,
    createScoringChancesSelector,
    createBlockedShotsSelector,
    createShotsOnGoalSelector,
    createShotsWithReboundSelector,
    createShotsWithoutReboundSelector,
    createMissedShotsSelector
} from './shots';
import { FAVORITE_EVENT } from '../../favorite-types';
import { processingState, mapperErrorMessage, createEpisodeIdWrapper } from '../../episode-utils';
import { getTypeToMediaTimeWrapper } from '../helpers/episodes-selectors-helpers';

const selector = shotsSelector;

const shotTypeToGameShotType = typeId => `GAME_${typeId}`;

const GAME_GOALS = shotTypeToGameShotType(shots.types.GOALS);
const GAME_SCORE_ATTEMPTS = shotTypeToGameShotType(shots.types.SCORE_ATTEMPTS);
const GAME_SCORING_CHANCES = shotTypeToGameShotType(shots.types.SCORING_CHANCES);
const GAME_BLOCKED_SHOTS = shotTypeToGameShotType(shots.types.BLOCKED_SHOTS);
const GAME_SHOTS_ON_GOAL = shotTypeToGameShotType(shots.types.SHOTS_ON_GOAL);
const GAME_SHOTS_WITH_REBOUND = shotTypeToGameShotType(shots.types.SHOTS_WITH_REBOUND);
const GAME_MISSED_SHOTS = shotTypeToGameShotType(shots.types.MISSED_SHOTS);
const GAME_SHOTS_WITHOUT_REBOUND = shotTypeToGameShotType(shots.types.SHOTS_WITHOUT_REBOUND);

const types = {
    GAME_GOALS,
    GAME_SCORE_ATTEMPTS,
    GAME_SCORING_CHANCES,
    GAME_BLOCKED_SHOTS,
    GAME_SHOTS_ON_GOAL,
    GAME_SHOTS_WITH_REBOUND,
    GAME_SHOTS_WITHOUT_REBOUND,
    GAME_MISSED_SHOTS
};

const typeToSelector = {
    [GAME_GOALS]: createEpisodeIdWrapper(GAME_GOALS, createGoalsSelector()),
    [GAME_SCORING_CHANCES]: createEpisodeIdWrapper(GAME_SCORING_CHANCES, createScoringChancesSelector()),
    [GAME_BLOCKED_SHOTS]: createEpisodeIdWrapper(GAME_BLOCKED_SHOTS, createBlockedShotsSelector()),
    [GAME_SHOTS_ON_GOAL]: createEpisodeIdWrapper(GAME_SHOTS_ON_GOAL, createShotsOnGoalSelector()),
    [GAME_SHOTS_WITH_REBOUND]: createEpisodeIdWrapper(GAME_SHOTS_WITH_REBOUND, createShotsWithReboundSelector()),
    [GAME_SHOTS_WITHOUT_REBOUND]: createEpisodeIdWrapper(
        GAME_SHOTS_WITHOUT_REBOUND,
        createShotsWithoutReboundSelector()
    ),
    [GAME_MISSED_SHOTS]: createEpisodeIdWrapper(GAME_MISSED_SHOTS, createMissedShotsSelector()),
    [GAME_SCORE_ATTEMPTS]: createEpisodeIdWrapper(GAME_SCORE_ATTEMPTS, s => s)
};

function mapper(gameShots, type) {
    if (gameShots.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Game Shots');
    return typeToSelector[type](gameShots);
}

const typeToMediaTimeWrapper = getTypeToMediaTimeWrapper(typeToSelector);

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const favoriteType = FAVORITE_EVENT;

const gameShots = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default gameShots;
