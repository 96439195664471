import { playlistMinHeight, playlistMinWidth, SIZE_DEFAULT } from '../constants/video-player-configs';
import { videoSizePlayerPicker } from './video-player-original-video-size';

//if portrait orientation, fullscreen and mobile - height of playlist === window height - height of video
export const playlistContainerHeight = props => {
    const { originalWidth, originalHeight, fullscreen, parentHeight, parentWidth, interfaceIsHidden, isMobile } = props;

    if (interfaceIsHidden) {
        return '38px';
    }

    const originalVideoAspectRatio = originalWidth / originalHeight;
    const videoSize = videoSizePlayerPicker({
        height: parentHeight,
        width: parentWidth,
        aspectRatio: originalVideoAspectRatio,
        fullscreen
    });

    if (!videoSize) {
        return SIZE_DEFAULT;
    }

    const widthOfElementAtPx = videoSize.video['width'];

    const heightFromWidthPx = Math.floor(widthOfElementAtPx / originalVideoAspectRatio);

    if (fullscreen && !isMobile) {
        return '80vh';
    }

    if (isMobile) {
        const { windowConfig, originalHeight, originalWidth } = props;
        const { height, width, isPortrait } = windowConfig;
        const aspectRatio = originalWidth / originalHeight;

        if (isPortrait) {
            const heightAtPx = Math.floor((height - width / aspectRatio) * 0.95);
            return `${heightAtPx}px`;
        }

        return '70vh';
    }

    if (parentWidth / parentHeight < 1) {
        return '100%';
    }

    if (isMobile) {
        return '100%';
    }

    if (heightFromWidthPx && !isNaN(heightFromWidthPx) && heightFromWidthPx >= 0) {
        return `${heightFromWidthPx}px`;
    }

    //Default case
    //todo refactor
    return props.fullscreen
        ? props.interfaceIsHidden
            ? 'unset'
            : '80vh'
        : props.isMobile
        ? '90vh'
        : props.mounted && !props.iframeMode
        ? '344px'
        : props.parentWidth && props.parentHeight
        ? `${
              videoSizePlayerPicker({
                  height: parentHeight,
                  width: parentWidth,
                  fullscreen
              }).playlist.height
          }px`
        : `${playlistMinHeight}px`;
};
export const playlistContainerWidth = props => {
    const {
        fullscreen,
        isMobile,
        windowConfig,
        originalHeight,
        originalWidth,
        interfaceIsHidden,
        iframeMode,
        parentHeight,
        parentWidth
    } = props;

    if (iframeMode) {
        return '100%';
    }

    if (interfaceIsHidden) {
        return '48px';
    }

    if (!windowConfig) {
        return '100%';
    }

    const { isLandscape, isPortrait } = windowConfig;

    if (fullscreen && !isMobile) {
        return '30vw';
    }

    if (isMobile && !fullscreen && isLandscape) {
        return `${
            videoSizePlayerPicker({
                height: parentHeight,
                width: parentWidth,
                fullscreen,
                isLandscape,
                isPortrait,
                isMobile,
                aspectRatio: originalWidth / originalHeight
            }).playlist.width
        }px`;
    }

    if (fullscreen && isMobile && isLandscape) {
        return '40%';
    }

    if (fullscreen && isMobile && isPortrait) {
        return '100%';
    }

    return props.fullscreen && props.isMobile
        ? '100vw'
        : props.fullscreen
        ? '30vw'
        : props.isMobile
        ? '100%'
        : (props.mounted && !props.iframeMode) || (props.parentWidth && props.parentHeight)
        ? `${
              videoSizePlayerPicker({
                  height: parentHeight,
                  width: parentWidth,
                  fullscreen,
                  isLandscape,
                  isPortrait,
                  isMobile,
                  aspectRatio: originalWidth / originalHeight
              }).playlist.width
          }px`
        : `${playlistMinWidth}px`;
};
//if portrait orientation, fullscreen and mobile - top value === height of video
export const playlistContainerTop = props => {
    const { iframeMode, fullscreen, isMobile, windowConfig, originalHeight, originalWidth, parentWidth } = props;

    if (iframeMode) {
        return 0;
    }

    if (isMobile && windowConfig && windowConfig.isPortrait) {
        return 0;
    }

    if (fullscreen && !isMobile) {
        return '2rem';
    }

    if (isMobile && fullscreen && windowConfig && windowConfig.isLandscape) {
        return '10%';
    }

    if (isMobile) {
        const { width, isPortrait, isLandscape } = windowConfig;
        const aspectRatio = originalWidth / originalHeight;

        const playerWidth = fullscreen ? width : parentWidth;

        if (isPortrait) {
            const heightAtPx = Math.floor(playerWidth / aspectRatio);
            return `${heightAtPx}px`;
        }

        if (isLandscape) {
            return 0;
        }

        return '2rem';
    }

    return 0;
};
export const playlistContainerLeft = props => {
    const {
        originalWidth,
        originalHeight,
        fullscreen,
        windowConfig,
        parentHeight,
        parentWidth,
        isMobile,
        iframeMode
    } = props;

    const { isPortrait, isLandscape } = windowConfig || {
        width: parentWidth,
        height: parentHeight,
        isLandscape: true,
        isPortrait: false
    };

    const originalVideoAspectRatio = originalWidth / originalHeight;
    const videoSize = videoSizePlayerPicker({
        height: parentHeight,
        width: parentWidth,
        aspectRatio: originalVideoAspectRatio,
        isMobile,
        isLandscape,
        isPortrait,
        fullscreen
    });

    if (iframeMode) {
        return 0;
    }

    if (!fullscreen && !isMobile) {
        return `${videoSize['video']['width']}px`;
    }

    if (fullscreen && !isMobile) {
        return `${videoSize['video']['width'] - 0.5 * videoSize['playlist']['width']}px`;
    }

    if (isMobile && isPortrait) {
        return 0;
    }

    if (isMobile && isLandscape) {
        return `calc(${videoSize['video']['width']}px)`;
    }

    //Default value
    return '75%';
};
