/* eslint-disable eqeqeq */
import { eventsTeamsSelector } from '../../../../../containers/widgets-stats/selectors/events';
import { FAVORITE_EVENT } from '../../favorite-types';
import {
    createEpisodeIdWrapper,
    createMediaTimeWrapper,
    mapperErrorMessage,
    processingState
} from '../../episode-utils';
import { createSelector } from 'reselect';
import { combinePossessions, getTimeForMediaTimeWrapperType } from '../helpers/episodes-selectors-helpers';

const POSSESSIONS = 'POSSESSIONS';
const GIVE_AWAYS = 'GIVE_AWAYS';
const TAKE_AWAYS = 'TAKE_AWAYS';
const NEUTRAL_PUCKS = 'NEUTRAL_PUCKS';
const REBOUND_RECOVERIES = 'REBOUND_RECOVERIES';
const STICK_CHECKS = 'STICK_CHECKS';
const BODY_CHECKS = 'BODY_CHECKS';
const FACE_OFFS = 'FACE_OFFS';
const RECOVERIES = 'RECOVERIES';

const types = {
    POSSESSIONS,
    GIVE_AWAYS,
    TAKE_AWAYS,
    NEUTRAL_PUCKS,
    REBOUND_RECOVERIES,
    STICK_CHECKS,
    BODY_CHECKS,
    FACE_OFFS,
    RECOVERIES
};

const selector = eventsTeamsSelector;

const possessionsCombiner = createSelector(
    events => events.data,
    combinePossessions
);

const possessionsSelector = createEpisodeIdWrapper(
    POSSESSIONS,
    createSelector(
        possessionsCombiner,
        data => ({ data })
    )
);

const giveawaysSelector = createEpisodeIdWrapper(
    GIVE_AWAYS,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.giveaway) })
    )
);

const takeawaysSelector = createEpisodeIdWrapper(
    TAKE_AWAYS,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.takeaway) })
    )
);

const neutralPucksSelector = createEpisodeIdWrapper(
    NEUTRAL_PUCKS,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.recovery) })
    )
);

const reboundRecoveriesSelector = createEpisodeIdWrapper(
    REBOUND_RECOVERIES,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.reboundRecovery) })
    )
);

const stickChecksSelector = createEpisodeIdWrapper(
    STICK_CHECKS,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.eventType === 'CHECK_STICK') })
    )
);

const bodyChecksSelector = createEpisodeIdWrapper(
    BODY_CHECKS,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.eventType === 'CHECK_BODY') })
    )
);

const faceOffsSelector = createEpisodeIdWrapper(
    FACE_OFFS,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.eventType === 'FACE_OFF') })
    )
);

const recoveriesSelector = createEpisodeIdWrapper(
    RECOVERIES,
    createSelector(
        events => events.data,
        data => ({
            data: data.filter(
                event => event.recovery && (event.eventType !== 'CHECK_BODY' && event.eventType !== 'CHECK_STICK')
            )
        })
    )
);

function mapper(events, type) {
    if (events.processing) return processingState;
    switch (type) {
        case POSSESSIONS:
            return possessionsSelector(events);
        case GIVE_AWAYS:
            return giveawaysSelector(events);
        case TAKE_AWAYS:
            return takeawaysSelector(events);
        case NEUTRAL_PUCKS:
            return neutralPucksSelector(events);
        case REBOUND_RECOVERIES:
            return reboundRecoveriesSelector(events);
        case STICK_CHECKS:
            return stickChecksSelector(events);
        case BODY_CHECKS:
            return bodyChecksSelector(events);
        case FACE_OFFS:
            return faceOffsSelector(events);
        case RECOVERIES:
            return recoveriesSelector(events);
        default:
            throw mapperErrorMessage(type, 'Possessions');
    }
}

const favoriteType = type => (type !== POSSESSIONS ? FAVORITE_EVENT : null);

const typeToMediaTimeWrapper = {
    [POSSESSIONS]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [GIVE_AWAYS]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [TAKE_AWAYS]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [NEUTRAL_PUCKS]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [REBOUND_RECOVERIES]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [STICK_CHECKS]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [BODY_CHECKS]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [FACE_OFFS]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [RECOVERIES]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType)
};

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

export const possession = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
