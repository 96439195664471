import React from 'react';
import Goaltender from 'components/goaltender/goaltender';
import styled from 'styled-components/macro';

const FallbackWrapper = styled.div`
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 94px);
    min-height: 330px;
`;

const FallbackPage = () => (
    <FallbackWrapper>
        <Goaltender width="360" height="328" backgroundColor="var(--backgroundColor)" contrast />
    </FallbackWrapper>
);

export default FallbackPage;
