/**
 * Created by Lytov on 03.07.2019.
 */
import { makePrefixAdder } from '../utils/type-utils';
import { getReadVersion } from '../../pages/whats-new/whats-new-helpers';

const addPrefixTo = makePrefixAdder('WHATS_NEW');
const MARK_AS_READ = addPrefixTo('MARK_AS_READ');

const markAsRead = version => {
    return { type: MARK_AS_READ, payload: version };
};

const defaultState = {
    lastReadVersion: getReadVersion()
};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case MARK_AS_READ:
            return {
                lastReadVersion: action.payload
            };
        default:
            return state;
    }
}

export const selector = state => state.whatsNew.lastReadVersion;

export const actions = {
    markAsRead
};
