function createWebkitFullScreenChangeEvent() {
    return new CustomEvent('webkitfullscreenchange', { bubbles: true, cancelable: true });
}

let BODY_VISIBILITY_BUFFER;
let BODY_HEIGHT_BUFFER;
let BODY_WIDTH_BUFFER;
let BODY_OVERFLOW_BUFFER;

function pushDocumentProperty(prop, value) {
    const cssValue = document.body.style.getPropertyCSSValue(prop);
    switch (prop) {
        case 'visibility':
            BODY_VISIBILITY_BUFFER = cssValue;
            break;
        case 'height':
            BODY_HEIGHT_BUFFER = cssValue;
            break;
        case 'width':
            BODY_WIDTH_BUFFER = cssValue;
            break;
        case 'overflow':
            BODY_OVERFLOW_BUFFER = cssValue;
            break;
        default:
    }
    document.body.style.setProperty(prop, value);
}

function popDocumentProperty(prop) {
    let cssValue;
    switch (prop) {
        case 'visibility':
            cssValue = BODY_VISIBILITY_BUFFER;
            break;
        case 'height':
            cssValue = BODY_HEIGHT_BUFFER;
            break;
        case 'width':
            cssValue = BODY_WIDTH_BUFFER;
            break;
        case 'overflow':
            cssValue = BODY_OVERFLOW_BUFFER;
            break;
        default:
    }
    document.body.style.setProperty(prop, cssValue);
}

let ELEMENT_VISIBILITY_BUFFER;
let ELEMENT_POSITION_BUFFER;
let ELEMENT_TOP_BUFFER;
let ELEMENT_LEFT_BUFFER;

function pushElementProperty(element, prop, value) {
    const cssValue = element.style.getPropertyCSSValue(prop);
    switch (prop) {
        case 'visibility':
            ELEMENT_VISIBILITY_BUFFER = cssValue;
            break;
        case 'position':
            ELEMENT_POSITION_BUFFER = cssValue;
            break;
        case 'top':
            ELEMENT_TOP_BUFFER = cssValue;
            break;
        case 'left':
            ELEMENT_LEFT_BUFFER = cssValue;
            break;
        default:
    }
    element.style.setProperty(prop, value);
}

function popElementProperty(element, prop) {
    let cssValue;
    switch (prop) {
        case 'visibility':
            cssValue = ELEMENT_VISIBILITY_BUFFER;
            break;
        case 'position':
            cssValue = ELEMENT_POSITION_BUFFER;
            break;
        case 'top':
            cssValue = ELEMENT_TOP_BUFFER;
            break;
        case 'left':
            cssValue = ELEMENT_LEFT_BUFFER;
            break;
        default:
    }
    element.style.setProperty(prop, cssValue);
}

function enableFullScreen() {
    if (document.webkitFullscreenElement) return;
    document.webkitFullscreenElement = this;
    pushDocumentProperty('visibility', 'hidden');
    pushDocumentProperty('height', '0px');
    pushDocumentProperty('width', '0px');
    pushDocumentProperty('overflow', 'hidden');
    pushElementProperty(this, 'visibility', 'visible');
    pushElementProperty(this, 'position', 'fixed');
    pushElementProperty(this, 'top', '0px');
    pushElementProperty(this, 'left', '0px');
    const event = createWebkitFullScreenChangeEvent();
    this.dispatchEvent(event);
}

function disableFullScreen() {
    if (!document.webkitFullscreenElement) return;
    const element = document.webkitFullscreenElement;
    document.webkitFullscreenElement = null;
    popDocumentProperty('visibility');
    popDocumentProperty('height');
    popDocumentProperty('width');
    popDocumentProperty('overflow');
    popElementProperty(element, 'visibility');
    popElementProperty(element, 'position');
    popElementProperty(element, 'top');
    popElementProperty(element, 'left');
    const event = createWebkitFullScreenChangeEvent();
    this.dispatchEvent(event);
}

export default function initFullscreenPolyfill() {
    if (!document.webkitCancelFullScreen && !document.mozCancelFullScreen) {
        document.webkitCancelFullScreen = disableFullScreen;
        Element.prototype.webkitRequestFullScreen = enableFullScreen;
    }
}
