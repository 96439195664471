import React from 'react';

export const LogoTPE = ({ classname, height, width, isLightLogo }) =>
    isLightLogo ? (
        <svg
            width={width || 110}
            height={height || 72}
            xmlns="http://www.w3.org/2000/svg"
            id="prefix__Layer_1"
            x="0"
            y="0"
            enableBackground="new 0 0 200 200"
            version="1.1"
            viewBox="0 0 200 200"
        >
            <path
                d="M56 125.1 56 135.6 54.9 135.6 54.9 125.1 50.9 125.1 50.9 124.2 59.8 124.2 59.8 125.1z"
                className="prefix__st0"
            />
            <path
                d="M86.7 135.6 86.7 124.2 93.2 124.2 93.2 125.1 87.8 125.1 87.8 129.2 92.6 129.2 92.6 130.1 87.8 130.1 87.8 134.6 93.2 134.6 93.2 135.6z"
                className="prefix__st0"
            />
            <path
                d="M122.4 131.1h4.2l-2-5.6-2.2 5.6zm4.6 1H122l-1.3 3.5h-1.2l4.3-11.4h1.2l4.4 11.4h-1.2L127 132z"
                className="prefix__st0"
            />
            <path
                d="M159.1 130.2 163.2 124.2 164.7 124.2 164.7 135.6 163.5 135.6 163.5 125.5 159.6 131.3 158.7 131.3 154.7 125.5 154.7 135.6 153.6 135.6 153.6 124.2 155 124.2z"
                className="prefix__st0"
            />
            <path
                d="M55.3 114.4c1.2 0 2.2-1 2.2-2.2V69h15.7c1.2 0 2.2-1 2.2-2.4 0-1.1-1-2.2-2.2-2.2H37.5c-1.3 0-2.2 1-2.2 2.2 0 1.4.9 2.4 2.2 2.4h15.6v43.2c0 1.3 1 2.2 2.2 2.2zM82 114.4c1.2 0 2.2-1 2.2-2.2V91.7h22c7.5 0 13.7-6.1 13.7-13.6s-6.2-13.7-13.7-13.7H82c-1.3 0-2.2 1-2.2 2.2 0 1.4.9 2.4 2.2 2.4h24.2c5 0 9.1 4 9.1 9s-4 9.2-9 9.2H82c-1.3 0-2.2 1-2.2 2.2V112c0 1.4.9 2.3 2.2 2.3z"
                className="prefix__st0"
            />
            <path
                d="M32.5 32.5a94.7 94.7 0 0 1 67.5-28 94.4 94.4 0 0 1 67.5 28A95 95 0 0 1 188 137.2a94.5 94.5 0 0 1-50.8 50.8 94.5 94.5 0 0 1-74.4 0A94.5 94.5 0 0 1 12 137.2a94.5 94.5 0 0 1 0-74.4 94.5 94.5 0 0 1 20.5-30.3zM100 3.2a95.7 95.7 0 0 0-68.4 28.4A95.9 95.9 0 0 0 3.2 100a95.7 95.7 0 0 0 28.4 68.4 96 96 0 0 0 68.4 28.3 95.9 95.9 0 0 0 68.4-28.3 96.2 96.2 0 0 0 20.7-106 96.2 96.2 0 0 0-51.5-51.5c-12-5.1-24.5-7.7-37.6-7.7z"
                className="prefix__st1"
            />
            <path
                d="M8.6 100a90.4 90.4 0 0 1 26.7-64.7A90.7 90.7 0 0 1 100 8.6a90.4 90.4 0 0 1 64.7 26.7 90.7 90.7 0 0 1 26.7 64.7 90.4 90.4 0 0 1-26.7 64.7 90.7 90.7 0 0 1-64.7 26.7 90.4 90.4 0 0 1-64.7-26.7A90.6 90.6 0 0 1 8.6 100zm25.2-66.2a93 93 0 0 0-20 102.6 92.9 92.9 0 0 0 49.8 49.8 92.6 92.6 0 0 0 72.8 0 92.9 92.9 0 0 0 49.8-49.8 92.6 92.6 0 0 0 0-72.8 93 93 0 0 0-49.8-49.8 92.6 92.6 0 0 0-72.8 0 93 93 0 0 0-29.8 20z"
                className="prefix__st1"
            />
            <path
                fill="#a11d27"
                d="M162.7 91.8h-36.4a2 2 0 0 1-2-2v-.6c0-1.1.9-2 2-2h36.4a2 2 0 0 1 2 2v.6a2 2 0 0 1-2 2z"
            />
            <path
                d="M162.7 114.5h-36.4a2 2 0 0 1-2-2v-.6c0-1 .9-2 2-2h36.4a2 2 0 0 1 2 2v.6a2 2 0 0 1-2 2zM162.7 69h-36.4a2 2 0 0 1-2-2v-.6c0-1 .9-2 2-2h36.4a2 2 0 0 1 2 2v.6a2 2 0 0 1-2 2z"
                className="prefix__st0"
            />
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0,0,1826,1007"
            width={width || 110}
            height={height || 72}
            className={classname}
        >
            <g>
                <path
                    fill="#fefffe"
                    d="M -0.5,41.5 C -0.5,35.5 -0.5,29.5 -0.5,23.5C 3.52747,11.3098 11.8608,3.80984 24.5,1C 196.167,0.333333 367.833,0.333333 539.5,1C 560.684,7.52384 568.851,21.6905 564,43.5C 560.099,54.4009 552.599,61.5675 541.5,65C 465.167,65.5 388.834,65.6667 312.5,65.5C 312.667,271.167 312.5,476.834 312,682.5C 307.506,697.519 297.339,705.519 281.5,706.5C 263.595,705.425 253.095,696.092 250,678.5C 249.5,474.167 249.333,269.834 249.5,65.5C 174.166,65.6667 98.8326,65.5 23.5,65C 10.994,61.6598 2.99403,53.8265 -0.5,41.5 Z"
                />
            </g>
            <g>
                <path
                    fill="#fefffe"
                    d="M 652.5,0.5 C 772.834,0.333335 893.167,0.500001 1013.5,1C 1091.23,9.25653 1146.39,48.7565 1179,119.5C 1206.8,195.015 1194.8,263.348 1143,324.5C 1104.62,364.765 1057.45,385.598 1001.5,387C 897.834,387.5 794.167,387.667 690.5,387.5C 690.667,484.501 690.5,581.501 690,678.5C 686.905,696.092 676.405,705.425 658.5,706.5C 642.668,706.15 632.502,698.484 628,683.5C 627.333,570.833 627.333,458.167 628,345.5C 631.477,331.692 640.311,323.859 654.5,322C 774.5,321.667 894.5,321.333 1014.5,321C 1070.03,312.136 1106.53,280.969 1124,227.5C 1136.43,169.891 1119.26,123.391 1072.5,88C 1052.83,74.8881 1031.16,67.5548 1007.5,66C 888.833,65.6667 770.167,65.3333 651.5,65C 635.363,60.241 627.363,49.4077 627.5,32.5C 627.713,15.3772 636.046,4.7105 652.5,0.5 Z"
                />
            </g>
            <g>
                <path
                    fill="#fefffe"
                    d="M 1280.5,0.5 C 1452.17,0.333334 1623.83,0.500001 1795.5,1C 1815.26,6.78367 1823.76,19.9503 1821,40.5C 1817.6,52.9029 1809.76,61.0696 1797.5,65C 1624.83,65.6667 1452.17,65.6667 1279.5,65C 1263.36,60.241 1255.36,49.4077 1255.5,32.5C 1255.71,15.3772 1264.05,4.7105 1280.5,0.5 Z"
                />
            </g>
            <g>
                <path
                    fill="#fefffe"
                    d="M 1825.5,345.5 C 1825.5,350.833 1825.5,356.167 1825.5,361.5C 1820.99,376.28 1810.99,384.78 1795.5,387C 1625.5,387.667 1455.5,387.667 1285.5,387C 1266.09,384.587 1256.09,373.587 1255.5,354C 1255.45,335.719 1264.45,325.052 1282.5,322C 1454.5,321.333 1626.5,321.333 1798.5,322C 1812.04,324.71 1821.04,332.543 1825.5,345.5 Z"
                />
            </g>
            <g>
                <path
                    fill="#fefffe"
                    d="M 1278.5,643.5 C 1451.5,643.333 1624.5,643.5 1797.5,644C 1818.68,651.865 1825.85,666.698 1819,688.5C 1813.94,698.547 1805.77,704.38 1794.5,706C 1623.83,706.667 1453.17,706.667 1282.5,706C 1260.88,701.638 1252.05,688.472 1256,666.5C 1259.68,654.984 1267.18,647.317 1278.5,643.5 Z"
                />
            </g>
            <g>
                <path
                    fill="#fefffe"
                    d="M 249.5,843.5 C 291.833,843.5 334.167,843.5 376.5,843.5C 376.5,848.167 376.5,852.833 376.5,857.5C 358.5,857.5 340.5,857.5 322.5,857.5C 322.5,906.833 322.5,956.167 322.5,1005.5C 316.833,1005.5 311.167,1005.5 305.5,1005.5C 305.5,956.167 305.5,906.833 305.5,857.5C 286.833,857.5 268.167,857.5 249.5,857.5C 249.5,852.833 249.5,848.167 249.5,843.5 Z"
                />
            </g>
            <g>
                <path
                    fill="#fefffe"
                    d="M 718.5,843.5 C 749.167,843.5 779.833,843.5 810.5,843.5C 810.5,848.167 810.5,852.833 810.5,857.5C 785.5,857.5 760.5,857.5 735.5,857.5C 735.5,876.5 735.5,895.5 735.5,914.5C 757.833,914.5 780.167,914.5 802.5,914.5C 802.5,919.167 802.5,923.833 802.5,928.5C 780.167,928.5 757.833,928.5 735.5,928.5C 735.5,949.5 735.5,970.5 735.5,991.5C 760.5,991.5 785.5,991.5 810.5,991.5C 810.5,996.167 810.5,1000.83 810.5,1005.5C 779.833,1005.5 749.167,1005.5 718.5,1005.5C 718.5,951.5 718.5,897.5 718.5,843.5 Z"
                />
            </g>
            <g>
                <path
                    fill="#fefffe"
                    d="M 1194.5,843.5 C 1201.29,842.899 1207.79,843.566 1214,845.5C 1234.07,898.539 1254.24,951.539 1274.5,1004.5C 1268.5,1005.83 1262.5,1005.83 1256.5,1004.5C 1250.5,988.5 1244.5,972.5 1238.5,956.5C 1215.83,955.167 1193.17,955.167 1170.5,956.5C 1164.1,972.715 1158.1,989.048 1152.5,1005.5C 1146.5,1005.5 1140.5,1005.5 1134.5,1005.5C 1154.5,951.5 1174.5,897.5 1194.5,843.5 Z M 1203.5,866.5 C 1204.5,866.47 1205.16,866.97 1205.5,868C 1214.4,892.205 1223.4,916.372 1232.5,940.5C 1213.51,941.666 1194.51,941.833 1175.5,941C 1185.33,916.354 1194.66,891.52 1203.5,866.5 Z"
                />
            </g>
            <g>
                <path
                    fill="#fefffe"
                    d="M 1825.5,843.5 C 1825.5,897.5 1825.5,951.5 1825.5,1005.5C 1819.83,1005.5 1814.17,1005.5 1808.5,1005.5C 1808.67,958.832 1808.5,912.165 1808,865.5C 1790.33,891.833 1772.67,918.167 1755,944.5C 1749.89,945.804 1744.72,945.804 1739.5,944.5C 1721.67,918.167 1703.83,891.833 1686,865.5C 1685.5,912.165 1685.33,958.832 1685.5,1005.5C 1679.5,1005.5 1673.5,1005.5 1667.5,1005.5C 1667.33,951.832 1667.5,898.166 1668,844.5C 1675.33,843.167 1682.67,843.167 1690,844.5C 1709,872.5 1728,900.5 1747,928.5C 1766.36,900.637 1785.53,872.637 1804.5,844.5C 1811.47,843.503 1818.47,843.17 1825.5,843.5 Z"
                />
            </g>
        </svg>
    );
