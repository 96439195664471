import { createMediaTimeWrapper, processingState, createEpisodeIdWrapper } from '../../episode-utils';
import { FAVORITE_SHIFT } from '../../favorite-types';
import { createSelector } from 'reselect';

const INDIVIDUAL_SHIFTS = 'INDIVIDUAL_SHIFTS';

const types = { INDIVIDUAL_SHIFTS: INDIVIDUAL_SHIFTS };

const processingStat = { processing: true, data: [] };

const selector = state => state.widgetsStats.individualShiftStats || processingStat;

const shiftsDataSelector = createEpisodeIdWrapper(
    INDIVIDUAL_SHIFTS,
    createSelector(
        shifts => shifts.data,
        data => ({ data: data.map(d => ({ isShift: true, ...d })) })
    )
);

function mapper(shifts) {
    if (shifts.processing) return processingState;
    return shiftsDataSelector(shifts);
}

const favoriteType = FAVORITE_SHIFT;

const getTime = ({ startTime, endTime }) => ({ startTime, endTime, time: startTime, hasPadding: false });

const mediaTimeWrapper = createMediaTimeWrapper(getTime);

function getTimedGroup(group, periods) {
    return mediaTimeWrapper(group, periods);
}

const individualShifts = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default individualShifts;
