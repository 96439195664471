import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components/macro';

const StyledScrollBar = styled.div`
    ${({ style }) => style}

    border-radius: inherit;

    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;

    transition: background-color 0.1s linear;

    &:hover {
        background-color: #989898;
    }
`;

const renderThumb = ({ style, ...props }) => {
    return <StyledScrollBar style={{ ...style }} {...props} />;
};

const ScrollArea = props => (
    <Scrollbars renderThumbVertical={renderThumb} renderThumbHorizontal={renderThumb} {...props} />
);

export default ScrollArea;
