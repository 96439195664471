import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NotFound } from '../../components/error/error';
import Loading from '../../components/loading/loading';
import { Redirect } from 'react-router-dom';
import { AUTH_TOKEN_NAME, setupAuthKey } from '../../utils/api/auth-utils';
import axios from 'axios';
import { playerProfile, userProfile } from '../../utils/api/endpoints';
import {
    actions as prospectAuthActions,
    playerProspectIntegrationSelector,
    tokenProspectIntegrationSelector
} from '../../redux/modules/prospect-integration';
import { actions as localeActions } from '../../redux/modules/locale';

const AuthByToken = ({
    setTokenForProspect,
    setPlayerForProspect,
    setPlayerPosition,
    tokenFromProspect,
    playerFromProspect,
    setLocale
}) => {
    const [redirectToMain, setRedirectToMain] = useState(false);
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [redirectToNotFound, setRedirectToNotFound] = useState(false);

    const cancelRedirect = () => {
        setRedirectToMain(false);
        setRedirectToNotFound(false);
        setRedirectToLogin(false);
    };

    const handleSuccessRedirectToMain = () => {
        setRedirectToMain(true);
    };

    const resetToken = () => {
        setupAuthKey(null, false);
    };

    const changeLocaleToClientFromResp = _resp => {
        /*const localeOfClient =
            resp.data && resp.data.profile && resp.data.profile.locales && resp.data.profile.locales[0];
        if (localeOfClient) {
            setLocale(localeOfClient.toLowerCase());
        }*/
        setLocale('en');
    };

    //On mount hook
    useEffect(
        () => {
            const winLocSearch = window.location.search;
            const queryParams = new URLSearchParams(winLocSearch);
            const token = queryParams.get('token');
            const playerID = queryParams.get('player');

            if (!token || !playerID) {
                setRedirectToMain(false);
                setRedirectToNotFound(true);
                return;
            }

            const decryptedPlayerId = +playerID;

            //Check token - fetch ~/me endpoint and make manual auth
            axios
                .create({
                    headers: { 'X-Requested-With': 'XMLHttpRequest', [AUTH_TOKEN_NAME]: token }
                })
                .get(userProfile)
                .then(resp => {
                    //todo make this after decryption
                    changeLocaleToClientFromResp(resp);

                    setTokenForProspect(token);
                    setPlayerForProspect(+decryptedPlayerId);
                    setupAuthKey(token, false);
                })
                .catch(e => {
                    /*eslint-disable*/
                    console.error(e);
                    /*eslint-enable*/
                    cancelRedirect();

                    resetToken();
                });

            //On unmount
            return () => {
                cancelRedirect();
            };
        },
        /*eslint-disable*/ [] /*eslint-enable*/
    );

    useEffect(
        () => {
            if (!tokenFromProspect || !playerFromProspect) {
                return;
            }

            //todo /api/players/profile?playerId={decryptedPlayerId} call

            axios
                .create({
                    headers: { 'X-Requested-With': 'XMLHttpRequest', [AUTH_TOKEN_NAME]: tokenFromProspect }
                })
                .get(`${playerProfile}?playerId=${playerFromProspect}`)
                .then(resp => {
                    const respData = resp.data;

                    const positionOfPlayer = respData.playerPosition;

                    setPlayerPosition(positionOfPlayer);

                    handleSuccessRedirectToMain();
                })
                .catch(e => {
                    /*eslint-disable*/
                    console.error(e);
                    /*eslint-enable*/
                    cancelRedirect();
                    resetToken();
                });
        },
        /*eslint-disable*/ [tokenFromProspect, playerFromProspect] /*eslint-enable*/
    );

    //todo use encrypt/decrypt checks - set player at prospect reducer only after decryption
    //todo add decrypted player id as validPlayerId

    if (redirectToLogin) {
        return <Redirect to={'/login'} />;
    }

    if (!redirectToMain) {
        return (
            <div style={{ background: '#fff', height: '100vh' }}>
                {!redirectToNotFound && <Loading width={'100%'} placeholder />}
                {redirectToNotFound && <NotFound />}
            </div>
        );
    }

    return <Redirect to={'/'} />;
};

const mapStateToProps = state => {
    const tokenFromProspect = tokenProspectIntegrationSelector(state);
    const playerFromProspect = playerProspectIntegrationSelector(state);

    return {
        tokenFromProspect,
        playerFromProspect
    };
};

const mapDispatchToProps = {
    setTokenForProspect: prospectAuthActions.setToken,
    setPlayerForProspect: prospectAuthActions.setPlayer,
    setPlayerPosition: prospectAuthActions.setPosition,
    setLocale: localeActions.changeLanguage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthByToken);
