import { getEpisodesPaddingLeft, getEpisodesPaddingRight } from './storages-utils';
import { transformTime } from '../../pages/post-game-report/utils/report-formatters';
import { twoDigitFormat } from './date-utils';

export const processPadding = realTime => {
    const { hasPadding, time } = realTime;

    let startTime = realTime.startTime || time;
    let endTime = realTime.endTime || time;

    if (hasPadding) {
        const leftPaddingDefault = realTime.leftPadding;
        const rightPaddingDefault = realTime.rightPadding;

        const leftPadding = +getEpisodesPaddingLeft() || leftPaddingDefault;
        const rightPadding = +getEpisodesPaddingRight() || rightPaddingDefault;

        startTime = Math.max(0, startTime - leftPadding);
        endTime += rightPadding;
    }

    return { startTime, endTime, time };
};

// todo: Это не time утилита
export const playlistTimeView = startTime => transformTime(Math.floor(startTime * 10));

export const getTransformedTimeToMoscowTimeZone = created => {
    const MOSCOW_OFFSET_AT_HOURS = 3;
    const MS_AT_MINUTE = 60000;
    const MINUTE_AT_HOUR = 60;

    const newDateFromCreated = new Date(created);
    const newDateToMSK = new Date(
        newDateFromCreated.getTime() + MOSCOW_OFFSET_AT_HOURS * MS_AT_MINUTE * MINUTE_AT_HOUR
    );

    return newDateToMSK.toLocaleString('ru-RU', {
        hour12: false,
        formatMatcher: 'best fit'
    });
};

export const getLocalTimeZone = date => {
    const newDateFromCreated = new Date(date);

    const offsetMilliseconds = new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(newDateFromCreated.getTime() - offsetMilliseconds);
};

export const getTransformedTimeToLocalTimeZone = created => {
    const newDateFromCreated = new Date(created);

    const offsetMilliseconds = new Date().getTimezoneOffset() * 60 * 1000;
    const adjustedDate = new Date(newDateFromCreated.getTime() - offsetMilliseconds);

    // Return the transformed time as a string in 24-hour format
    return adjustedDate.toLocaleString(undefined, {
        hour12: false, // 24-hour format
        formatMatcher: 'best fit' // Choose the best-fit format for the locale
    });
};

export const transformSecToHhMmSs = (timeAtSeconds, onlyMmSs) => {
    const hours = +Math.floor(timeAtSeconds / (60 * 60));
    const minutes = +Math.abs(Math.floor((timeAtSeconds - hours * 60 * 60) / 60));
    const seconds = +Math.abs(Math.floor(+timeAtSeconds - minutes * 60 - hours * 60 * 60));

    if (onlyMmSs) {
        return `${twoDigitFormat(minutes)}:${twoDigitFormat(seconds)}`;
    }

    return `${twoDigitFormat(hours)}:${twoDigitFormat(minutes)}:${twoDigitFormat(seconds)}`;
};
