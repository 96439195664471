import { connect } from 'react-redux';
import { isDesktop, isMobile } from '../../redux/modules/device-type';
import { combinedMapStateToProps } from '../../utils/helpers/redux-utils';
import PropTypes from 'prop-types';

const DeviceSelector = ({ mobile, desktop, mobileLayout = null, desktopLayout = null }) => {
    return !!mobile ? mobileLayout : desktop ? desktopLayout : null;
};

DeviceSelector.propTypes = {
    mobileLayout: PropTypes.element,
    desktopLayout: PropTypes.element
};

export default connect(
    combinedMapStateToProps({
        mobile: isMobile,
        desktop: isDesktop
    })
)(DeviceSelector);
