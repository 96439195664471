import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';

import HelpIcon from 'components/svg-icons/help';
import styled, { css } from 'styled-components/macro';
import StyledModal from 'components/modal/styled-modal';
import FormattedMessage from 'components/formatted-message';
import { fieldTypes } from 'components/crud-table/field-types';
import Field from 'pages/admin/components/field';
import { getFormatMessageFunction } from 'redux/modules/locale';
import CloseIcon from 'components/svg-icons/close';
import Button from 'components/button/button';
import { detectBrowserVersion } from 'utils/detect-browser-version';
import * as endpoints from 'utils/api/endpoints';
import createObservableSubject from 'utils/helpers/create-observable-subject';
import { isEmailValid } from 'utils/regex-utils';

import Action from '../../../rest-query/action';
import PropsFormatter, { tr } from '../../../props-formatter/index';

export const supportRequestNotifier = createObservableSubject();

const StyledIcon = styled(CloseIcon)`
    display: block;
    margin: 10px 10px 0 auto;

    color: var(--uiPrimaryColor);

    cursor: pointer;

    transition: transform 0.2s;

    &:hover {
        transform: scale(1.2);
    }
`;

const contentCss = css`
    width: 470px;
    padding: 0;

    border-radius: 10px;
`;

const IconContainer = styled.div`
    height: 24px;
    padding-inline: 4px;

    cursor: pointer;

    &:hover {
        color: rgb(149, 196, 251);
    }
`;

const Title = styled.div`
    color: var(--uiPrimaryColor);
    font-weight: bold;
    font-size: 16px;
    text-align: center;
`;

const Layout = styled.div`
    padding: 25px 60px;
`;

const FieldContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 15px;
    }
`;

const reasons = [
    'dataVideoIssue',
    'missingGamesIssue',
    'logInIssue',
    'verificationRequest',
    'financialIssue',
    'hardwareSystemIssue',
    'other'
];

const reasonsOptions = reasons.map(r => ({
    label: <FormattedMessage id={`appContainer.supportRequest.${r}`} />,
    value: r
}));

const query = queryVariables => ({
    url: endpoints.acceptSupportRequest,
    method: 'POST',
    data: { ...queryVariables, userAgent: detectBrowserVersion() }
});

const SupportRequest = ({ formatMessage }) => {
    const [dialogOpened, setDialogState] = useState(false);
    const openDialog = useCallback(() => setDialogState(true), []);
    const closeDialog = useCallback(() => setDialogState(false), []);

    useEffect(() => supportRequestNotifier.subscribe(openDialog), [openDialog]);

    const [successNotificationShown, setSuccessNotificationState] = useState(false);
    const showSuccessNotification = useCallback(() => setSuccessNotificationState(true), []);
    const hideSuccessNotification = useCallback(() => setSuccessNotificationState(false), []);

    const [reason, setReason] = useState(null);
    const handleReasonChange = useCallback((a, b) => setReason(b), []);

    const reasonLabel = formatMessage({ id: `appContainer.supportRequest.reason` });

    const [email, setEmail] = useState(null);
    const handleEmailChange = useCallback((a, b) => setEmail(b), []);

    const emailLabel = formatMessage({ id: `appContainer.supportRequest.email` });

    const [description, setDescription] = useState('');
    const handleDescriptionChange = useCallback((a, b) => setDescription(b), []);

    const reasonOption = useMemo(() => reason && formatMessage({ id: `appContainer.supportRequest.${reason}` }), [
        reason,
        formatMessage
    ]);

    const descriptionLabel = useMemo(
        () => reason && formatMessage({ id: `appContainer.supportRequest.${reason}.description` }),
        [formatMessage, reason]
    );

    const queryVariables = useMemo(() => ({ reason: reasonOption, description, email }), [
        reasonOption,
        description,
        email
    ]);

    const submitDisabled = useMemo(() => !reason || !description || !isEmailValid(email), [reason, description, email]);

    const handleSuccess = useCallback(() => {
        closeDialog();
        setReason('');
        setEmail('');
        setDescription('');
        showSuccessNotification();
    }, [closeDialog, setReason, setEmail, setDescription, showSuccessNotification]);

    return (
        <>
            {window.location.pathname !== '/player-report' && (
                <IconContainer onClick={openDialog}>
                    <HelpIcon />
                </IconContainer>
            )}
            {dialogOpened && (
                <StyledModal disableScroll contentCss={contentCss} isOpen={true} onRequestClose={closeDialog}>
                    <StyledIcon onClick={closeDialog} width={10} height={10} />
                    <Layout>
                        <FieldContainer>
                            <Title>
                                <FormattedMessage id="appContainer.supportRequest" />
                            </Title>
                        </FieldContainer>
                        <FieldContainer>
                            <PropsFormatter placeholder={tr('appContainer.supportRequest.reason.placeholder')}>
                                <Field
                                    value={reason}
                                    type={fieldTypes.reactSelect}
                                    options={reasonsOptions}
                                    onChange={handleReasonChange}
                                    fieldName={reasonLabel}
                                    labeled
                                />
                            </PropsFormatter>
                        </FieldContainer>
                        <FieldContainer>
                            <Field
                                style={{ height: '150px' }}
                                value={description}
                                type={fieldTypes.textarea}
                                onChange={handleDescriptionChange}
                                fieldName={descriptionLabel}
                                labeled
                            />
                        </FieldContainer>
                        <FieldContainer>
                            <Field
                                value={email}
                                type={fieldTypes.text}
                                onChange={handleEmailChange}
                                fieldName={emailLabel}
                                labeled
                            />
                        </FieldContainer>
                        <FieldContainer>
                            <Action query={query} variables={queryVariables} onSuccess={handleSuccess}>
                                {({ performAction, processing }) => (
                                    <Button
                                        onClick={performAction}
                                        isLoading={processing}
                                        style={{ width: '113px', margin: '0 auto', display: 'block' }}
                                        disabled={submitDisabled}
                                    >
                                        <FormattedMessage id="appContainer.supportRequest.submit" />
                                    </Button>
                                )}
                            </Action>
                        </FieldContainer>
                    </Layout>
                </StyledModal>
            )}
            {successNotificationShown && (
                <StyledModal
                    disableScroll
                    contentCss={contentCss}
                    isOpen={true}
                    onRequestClose={hideSuccessNotification}
                >
                    <StyledIcon onClick={hideSuccessNotification} width={10} height={10} />
                    <Layout>
                        <FieldContainer>
                            <FormattedMessage id="appContainer.supportRequest.success.p1" />
                        </FieldContainer>
                        <FieldContainer>
                            <FormattedMessage id="appContainer.supportRequest.success.p2" />
                        </FieldContainer>
                    </Layout>
                </StyledModal>
            )}
        </>
    );
};

export default connect(state => ({ formatMessage: getFormatMessageFunction(state) }))(SupportRequest);
