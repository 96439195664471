import { FAVORITE_EVENT } from '../../favorite-types';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { createSelector } from 'reselect';
import { getTimeForMediaTimeWrapperType } from '../helpers/episodes-selectors-helpers';

const GAME_RECOVERY = 'GAME_RECOVERY';
const GAME_GIVEAWAY = 'GAME_GIVEAWAY';
const GAME_TAKEAWAY = 'GAME_TAKEAWAY';
const GAME_FACE_OFF = 'GAME_FACE_OFF';
const GAME_GOAL_EVENT = 'GAME_GOAL_EVENT';

const types = { GAME_RECOVERY, GAME_GIVEAWAY, GAME_TAKEAWAY, GAME_FACE_OFF, GAME_GOAL_EVENT };

const selector = state => (state.widgetsStats.events ? state.widgetsStats.events : processingState);

const gameRecoveriesSelector = createEpisodeIdWrapper(
    GAME_RECOVERY,
    createSelector(
        events => events.data,
        data => ({ data: !!data ? data.filter(e => e.recovery || e.reboundRecovery) : [] })
    )
);

const gameGiveawaysSelector = createEpisodeIdWrapper(
    GAME_GIVEAWAY,
    createSelector(
        events => events.data,
        data => ({ data: !!data ? data.filter(e => e.giveaway) : [] })
    )
);

const gameTakeawaysSelector = createEpisodeIdWrapper(
    GAME_TAKEAWAY,
    createSelector(
        events => events.data,
        data => ({ data: !!data ? data.filter(e => e.takeaway) : [] })
    )
);

const gameFaceoffsSelector = createEpisodeIdWrapper(
    GAME_FACE_OFF,
    createSelector(
        events => events.data,
        data => ({ data: !!data ? data.filter(e => e.eventType === 'FACE_OFF') : [] })
    )
);

const gameGoalsSelector = createEpisodeIdWrapper(
    GAME_GOAL_EVENT,
    createSelector(
        events => events.data,
        data => ({ data: !!data ? data.filter(e => e.eventType === 'GOAL') : [] })
    )
);

function mapper(gameEvents, type) {
    if (gameEvents.processing) return processingState;
    switch (type) {
        case GAME_RECOVERY:
            return gameRecoveriesSelector(gameEvents);
        case GAME_GIVEAWAY:
            return gameGiveawaysSelector(gameEvents);
        case GAME_TAKEAWAY:
            return gameTakeawaysSelector(gameEvents);
        case GAME_FACE_OFF:
            return gameFaceoffsSelector(gameEvents);
        case GAME_GOAL_EVENT:
            return gameGoalsSelector(gameEvents);
        default:
            throw mapperErrorMessage(type, 'Game Events');
    }
}

const typeToMediaTimeWrapper = {
    [GAME_RECOVERY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [GAME_GIVEAWAY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [GAME_TAKEAWAY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [GAME_FACE_OFF]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [GAME_GOAL_EVENT]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType)
};

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const favoriteType = FAVORITE_EVENT;

const gameEvents = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default gameEvents;
