import styled from 'styled-components/macro';

import Input from 'components/input/input';
import SearchIcon from 'components/svg-icons/search';
import { mobileMediaCondition } from 'utils/helpers/device-utils';

export const Tooltip = styled.div`
    position: absolute;
    top: -40px;
    right: -150px;

    width: 600px;
    max-width: 100vw;
    overflow: hidden;

    border: none;
`;

export const SearchPanelFullWrapper = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    height: max-content;
    max-height: 90vh;
    padding: 5px 0;
    @media ${mobileMediaCondition} {
        gap: 4px;
    }

    @media ${mobileMediaCondition} {
        max-width: max(320px, 100vw);
        /*max-height: 90dvh;*/
        margin-top: 48px;
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    flex: 1;
    gap: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const InputWrap = styled.div`
    position: relative;

    width: max-content;
    height: max-content;
`;

export const StyledInput = styled(Input)`
    width: 100%;
    height: 36px;
`;

export const DataWrap = styled.div`
    position: ${({ isSearchPanel }) => (isSearchPanel ? 'absolute' : undefined)};
    top: ${({ isSearchPanel }) => (isSearchPanel ? '50px' : undefined)};
    z-index: ${({ isSearchPanel }) => (isSearchPanel ? 1 : undefined)};

    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-height: ${({ isSearchPanel }) => (isSearchPanel ? '75vh' : undefined)};
    padding: 6px 16px;
    overflow: auto;

    border-radius: 8px;

    background-color: var(--backgroundColorSecondary);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;

        box-shadow: inset 0 0 6px var(--uiPrimaryColor);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;

        box-shadow: inset 0 0 6px var(--uiPrimaryColor);
    }

    @media ${mobileMediaCondition} {
        gap: 8px;
        width: ${({ isSearchPanel }) => (isSearchPanel ? '100%' : undefined)};
    }
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    color: var(--uiPrimaryColor);

    background-color: var(--backgroundColorSecondary);
`;

export const Row = styled.div`
    display: flex;
    gap: 8px;
    justify-content: space-between;
    width: 100%;

    color: var(--defaultColor);

    &:hover {
        background-color: var(--uiPrimaryTextHighlighted);
        cursor: ${({ hovered }) => (hovered ? 'pointer' : 'auto')};
    }
`;

export const ControlsTab = styled.div`
    display: flex;
    gap: 8px;
`;

export const TabButton = styled.button`
    border-top: none;
    border-bottom: ${({ isActive }) =>
        isActive ? '1px solid var(--uiPrimaryColor)' : '1px solid var(--uiSecondaryColor)'};

    color: ${({ isActive }) => (isActive ? 'var(--uiPrimaryColor)' : 'var(--uiSecondaryColor)')};
    font-weight: bold;

    background-color: transparent;
    cursor: pointer;
    border-inline: none;
`;

export const SearchButton = styled.button`
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: min-content;
    height: min-content;
    margin: 0;
    padding: 4px 2px;

    border: none;
    border-radius: 5%;

    color: var(--uiPrimaryColor);

    background-color: var(--uiHeaderColor);
    cursor: pointer;

    &:hover {
        color: var(--backgroundColorSecondaryActive);
    }
`;

export const CloseButtonWrapper = styled.button`
    position: absolute;
    top: 10px;
    right: 24px;

    display: inline-flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: min-content;
    height: min-content;
    margin: 0;
    padding: 4px 2px;

    border: none;
    border-radius: 5%;

    background-color: var(--backgroundColorSecondary);
    cursor: pointer;

    &:hover {
        color: var(--backgroundColorSecondaryActive);
    }

    @media ${mobileMediaCondition} {
        right: 12px;

        margin-top: ${({ isSearchPanel }) => (isSearchPanel ? 0 : '48px')};
    }
`;

export const IconSearchWrap = styled.div`
    position: absolute;
    top: 0;
    right: 12px;
`;

export const StyledSearchIcon = styled(SearchIcon)`
    width: 18px;
    height: 36px;

    transition: margin 150ms, width 150ms;
`;

export const Img = styled.img`
    width: 34px;
    height: 34px;

    border-radius: 10%;
`;

export const FavButtonWrap = styled.div`
    display: flex;
    justify-content: end;
    padding-right: 20px;

    cursor: pointer;
`;

export const PlayerLink = styled.div`
    color: var(--uiPrimaryColor);

    cursor: pointer;

    &:hover {
        color: #014c8c;
        text-decoration: underline;
    }
`;
