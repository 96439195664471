import { playlistMinHeight, playlistMinWidth, SIZE_DEFAULT } from '../constants/video-player-configs';

//todo add tests for function videoSizePlayerPicker
/**
 * Return parameters of video, playlist and video info
 * Will be return sizes of video (connect with playlistMinWidth, playlistMinHeight and size of parent component)
 * @param height {Number} - parent component width px
 * @param width {Number} - parent component height px
 * @param fullscreen {Boolean}
 * @param aspectRatio {Number} - aspect ratio of original video from cloud
 * @param isPortrait {Boolean}
 * @param isLandscape {Boolean}
 * @param isMobile {Boolean}
 * @returns {{playlist: {width: number, height: number}, videoInfo: {height: number}, video: {width: number, height: number}}|{playlist: {width, height: number}, videoInfo: {height: number}, video: {width, height: number}}}
 */
export const videoSizePlayerPicker = ({
    height,
    width,
    fullscreen,
    aspectRatio,
    isPortrait,
    isLandscape,
    isMobile
}) => {
    const videoAspectRatio = aspectRatio || 1.7777;

    if (!fullscreen && isMobile && isPortrait) {
        return {
            video: { width: width, height: width / aspectRatio },
            playlist: {
                width: width,
                height: Math.floor(Math.max(playlistMinHeight, width / aspectRatio))
            },
            videoInfo: {
                height: Math.floor(width / aspectRatio - 30)
            }
        };
    }

    if (!fullscreen && isMobile && isLandscape) {
        return {
            video: { width: height * aspectRatio, height: height },
            playlist: {
                width: Math.floor(Math.max(width - height * aspectRatio, playlistMinWidth)),
                height: Math.floor(Math.max(playlistMinHeight, height))
            },
            videoInfo: {
                height: Math.floor(width / aspectRatio - 30)
            }
        };
    }

    if (fullscreen && !isMobile) {
        const videoWidth = width;
        const videoHeight = Math.floor(videoWidth / videoAspectRatio);

        return {
            video: { width: videoWidth, height: videoHeight },
            playlist: {
                width: playlistMinWidth,
                height: Math.floor(videoHeight - 30)
            },
            videoInfo: {
                height: 30
            }
        };
    }

    //todo remove this magic tricks
    //Horizontal block orientation
    if (+width - height > 0) {
        const videoWidth = width - playlistMinWidth;
        const videoHeight = Math.floor(videoWidth / videoAspectRatio);

        return {
            video: { width: videoWidth, height: videoHeight },
            playlist: {
                width: playlistMinWidth,
                height: videoHeight
            },
            videoInfo: {
                height: Math.floor(videoHeight - 30)
            }
        };
    }

    //Vertical block orientation
    if (+width - height <= 0) {
        const videoHeight = height - playlistMinHeight;
        return {
            video: {
                width,
                height: videoHeight
            },
            playlist: {
                width,
                height: playlistMinHeight
            },
            videoInfo: {
                height: Math.floor(videoHeight * 0.85)
            }
        };
    }
};

export const videoPlayerFlexDirectionGet = props => {
    const { isMobile, windowConfig, parentWidth, parentHeight, iframeMode } = props;

    const { isLandscape, isPortrait } = windowConfig || {
        width: parentWidth,
        height: parentHeight,
        isLandscape: true,
        isPortrait: false
    };

    if ((isMobile && isPortrait) || iframeMode) {
        return 'column';
    }

    if (isMobile && isLandscape) {
        return 'row';
    }

    return !props.mounted ? 'column' : 'row';
};

export const getVideoPlayerWrapperWidth = props => {
    const { fullscreen, isMobile, src } = props;

    if (fullscreen || isMobile || !src) {
        return '100%';
    }

    return props.parentWidth ? `${props.parentWidth}px` : '100%';
};

export const setVideoWrapperWidth = props => {
    const {
        originalWidth,
        originalHeight,
        fullscreen,
        parentHeight,
        parentWidth,
        isMobile,
        windowConfig,
        iframeMode
    } = props;
    const originalVideoAspectRatio = originalWidth / originalHeight;

    const { isLandscape, isPortrait } = windowConfig || {
        width: parentWidth,
        height: parentHeight,
        isLandscape: true,
        isPortrait: false
    };

    return fullscreen || iframeMode
        ? '100%'
        : parentWidth && parentHeight
        ? `${
              videoSizePlayerPicker({
                  height: parentHeight,
                  width: parentWidth,
                  fullscreen,
                  aspectRatio: originalVideoAspectRatio,
                  isLandscape,
                  isPortrait,
                  isMobile
              }).video.width
          }px`
        : `${SIZE_DEFAULT}px`;
};

export const getVideoPlayerWrapperHeight = props => {
    const { fullscreen, windowConfig, isMobile, iframeMode } = props;

    if (iframeMode) {
        return `80%`;
    }

    if (!windowConfig) {
        return '100%';
    }

    const { isLandscape } = windowConfig;

    if (isMobile && fullscreen && isLandscape) {
        return `${window.innerHeight}px !important`;
    }

    return setVideoWrapperHeight(props);
};

export const setVideoWrapperHeight = props => {
    const {
        originalWidth,
        originalHeight,
        fullscreen,
        windowConfig,
        parentHeight,
        parentWidth,
        isMobile,
        iframeMode
    } = props;

    if (!windowConfig || iframeMode) {
        return '100%';
    }

    const { isLandscape, isPortrait, width } = windowConfig;

    if (isMobile && fullscreen && isLandscape) {
        return `${window.innerHeight}px !important`;
    }

    /*if (isMobile && !fullscreen && isLandscape) {

        //todo return here height of video player wrapper

        return `${parentHeight}px !important`;
    }*/

    const originalVideoAspectRatio = originalWidth / originalHeight;
    const videoSize = videoSizePlayerPicker({
        height: Math.floor(parentHeight),
        width: Math.floor(parentWidth),
        aspectRatio: originalVideoAspectRatio,
        isLandscape,
        isPortrait,
        isMobile,
        fullscreen
    });
    const widthOfElementAtPx = videoSize.video['width'];

    const heightFromWidthPx = Math.floor(widthOfElementAtPx / originalVideoAspectRatio);

    if (fullscreen && isPortrait) {
        return `calc(${width}px / ${originalVideoAspectRatio})`;
    }

    if (fullscreen && isLandscape) {
        return `calc( 100% / ${originalVideoAspectRatio})`;
    }

    if (heightFromWidthPx && !isNaN(heightFromWidthPx) && heightFromWidthPx >= 0) {
        return `${heightFromWidthPx}px`;
    }

    if (isMobile) {
        return `${parentHeight}px`;
    }

    //Default case
    //todo refactor
    return props.fullscreen
        ? '100%'
        : props.isMobile
        ? `420px`
        : props.mounted && !props.iframeMode
        ? 'unset'
        : props.parentWidth && props.parentHeight
        ? `${
              videoSizePlayerPicker({
                  height: props.parentHeight,
                  width: props.parentWidth,
                  aspectRatio: props.aspectRatio,
                  fullscreen
              }).video.height
          }px`
        : SIZE_DEFAULT;
};
