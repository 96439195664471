import { makeBundle, makePrefixAdder } from '../utils/type-utils';
import requestBuilder from '../utils/request-builder';
import * as endpoints from '../../utils/api/endpoints';
import { createSelector } from 'reselect';

const addPrefixTo = makePrefixAdder('FAVOURITES');

const initFavouritePlayersBundle = makeBundle(addPrefixTo('INIT_PLAYERS'));
const addFavouritePlayerBundle = makeBundle(addPrefixTo('ADD_PLAYER'));
const removeFavouritePlayerBundle = makeBundle(addPrefixTo('REMOVE_PLAYER'));

const initFavouriteItemsAsPlayerBundle = makeBundle(addPrefixTo('INIT_FAVORITE_ITEMS_AS_PLAYER'));
const addFavouriteItemsAsPlayerBundle = makeBundle(addPrefixTo('ADD_FAVORITE_ITEMS_AS_PLAYER'));
const removeFavouriteItemsAsPlayerBundle = makeBundle(addPrefixTo('REMOVE_FAVORITE_ITEMS_AS_PLAYER'));

const reFetchFavoritesTeamAndPlayerActionType = 'RE_FETCH_FAVORITES';

export const initFavouritePlayers = () => {
    return requestBuilder(initFavouritePlayersBundle).get(endpoints.favoriteItems, {
        params: {
            itemType: 'SCOUT_PLAYER'
        }
    });
};

export const addFavouritePlayer = playerId => {
    return requestBuilder(addFavouritePlayerBundle).post(endpoints.favoriteItems, {
        itemEntityId: playerId,
        itemType: 'SCOUT_PLAYER'
    });
};

export const removePlayerFromFavourites = favouriteId => {
    return requestBuilder(removeFavouritePlayerBundle)
        .withOptions({ id: favouriteId })
        .del(endpoints.favoriteItem(favouriteId));
};

export const initFavouriteItemsAsPlayer = () => {
    return requestBuilder(initFavouriteItemsAsPlayerBundle).get(endpoints.favoriteItems, {
        params: {
            itemType: 'PLAYER'
        }
    });
};

export const addFavouriteItemsAsPlayer = playerId => {
    return requestBuilder(addFavouriteItemsAsPlayerBundle).post(endpoints.favoriteItems, {
        itemEntityId: playerId,
        itemType: 'PLAYER'
    });
};

export const removeFavouriteItemsAsPlayer = favouriteId => {
    return requestBuilder(removeFavouriteItemsAsPlayerBundle)
        .withOptions({ id: favouriteId })
        .del(endpoints.favoriteItem(favouriteId));
};

export const reFetchFavoritesTeamAndPlayerAction = data => {
    return {
        type: reFetchFavoritesTeamAndPlayerActionType,
        payload: data
    };
};

const defaultState = {
    favouritePlayers: null,
    favouritePlayerItems: null,
    isFavoriteListNeedToFetch: true
};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case initFavouritePlayersBundle.SUCCESS:
            return {
                ...state,
                favouritePlayers: action.response.data
            };
        case addFavouritePlayerBundle.SUCCESS:
            return {
                ...state,
                favouritePlayers: [...(state.favouritePlayers || []), action.response.data]
            };
        case removeFavouritePlayerBundle.SUCCESS:
            return {
                ...state,
                favouritePlayers: state.favouritePlayers.filter(f => f.id !== action.id)
            };

        case initFavouriteItemsAsPlayerBundle.SUCCESS:
            return {
                ...state,
                favouritePlayerItems: action.response.data
            };
        case addFavouriteItemsAsPlayerBundle.SUCCESS:
            return {
                ...state,
                favouritePlayerItems: [...(state.favouritePlayerItems || []), action.response.data]
            };
        case removeFavouriteItemsAsPlayerBundle.SUCCESS:
            return {
                ...state,
                favouritePlayerItems: state.favouritePlayerItems.filter(f => f.id !== action.id)
            };
        case reFetchFavoritesTeamAndPlayerActionType:
            return {
                ...state,
                isFavoriteListNeedToFetch: action.payload
            };
        default:
            return state;
    }
}

export const favouritePlayersSelector = state => state.favourites.favouritePlayers;
export const favoriteListFetchSelector = state => state.favourites.isFavoriteListNeedToFetch;

export const buildPlayerIsFavouriteFunction = createSelector(
    favouritePlayersSelector,
    favouritePlayers => playerId => favouritePlayers && favouritePlayers.some(f => f.itemEntityId === playerId)
);

export const buildPlayerIdToFavouriteIdFunction = createSelector(
    favouritePlayersSelector,
    favouritePlayers => playerId => favouritePlayers && favouritePlayers.find(f => f.itemEntityId === playerId)?.id
);

export const favouritePlayerItemsSelector = state => state.favourites.favouritePlayerItems;

export const buildPlayerHasItemFunction = createSelector(
    favouritePlayerItemsSelector,
    playerItems => playerId => playerItems?.some(f => f.itemEntityId === playerId)
);

export const buildPlayerIdToItemIdFunction = createSelector(
    favouritePlayerItemsSelector,
    playerItems => playerId => playerItems?.find(f => f.itemEntityId === playerId)?.id
);
