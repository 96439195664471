const WEEKDAYS_SHORT = {
    ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    it: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']
};
const MONTHS = {
    ru: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
    ],
    it: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
    ]
};

const WEEKDAYS_LONG = {
    ru: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    it: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
};

const FIRST_DAY_OF_WEEK = {
    ru: 1,
    it: 1
};
// Translate aria-labels
const LABELS = {
    ru: { nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц' },
    it: { nextMonth: 'Prossimo mese', previousMonth: 'Mese precedente' }
};

export const DateRangePickerConstant = {
    WEEKDAYS_LONG,
    WEEKDAYS_SHORT,
    MONTHS,
    FIRST_DAY_OF_WEEK,
    LABELS
};
