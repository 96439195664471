import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components/macro';
import Hamburger from '../../../components/hamburger/hamburger';
import { injectRoleManagement } from '../../../utils/permissions/role-utils';
import { NavLink, matchPath } from 'react-router-dom';
import DeviceSelector from '../../../components/device-selector/device-selector';
import { connect } from 'react-redux';
import { selector as linksSelector } from '../../../redux/modules/secondary-nav';
import { desktopMediaCondition, mobileMediaCondition } from '../../../utils/helpers/device-utils';

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    align-items: center;
    max-width: 230px;
`;

const Item = ({ disabled, className, itemIsActive, ...props }) =>
    disabled ? (
        <div className={`${className} nl-disabled`}>{props.children}</div>
    ) : (
        <NavLink className={className} activeClassName="nl-active" active={itemIsActive.toString()} {...props} />
    );

const StyledItem = styled(Item)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 15px;

    color: ${({ itemIsActive }) => (itemIsActive ? 'var(--uiPrimaryColor)' : 'white')};

    font-weight: bold;
    text-decoration: none;

    cursor: pointer;

    &:hover,
    &:focus {
        color: ${({ itemIsActive }) => (itemIsActive ? 'var(--uiPrimaryColor)' : 'white')};
        text-decoration: none;

        background-color: var(--uiPrimaryColorTransparent);
        outline: none;
    }

    &.nl-disabled {
        color: var(--optionsDisabledColor);

        cursor: default;
    }

    &.nl-disabled:hover {
        background-color: unset;
    }
`;

const Popup = styled.div`
    position: absolute;
    top: 48px;

    display: flex;
    flex-direction: column;

    width: 110px;
    height: ${({ opened }) => (opened ? '100vh' : '0')};
    overflow: hidden;

    background-color: var(--uiHeaderColor);
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.25);

    transition: height 250ms;
    @media ${mobileMediaCondition} {
        left: ${props => ((props.level || 1) - 1) * 23}vw;

        width: 23.3vw;

        font-size: 0.7em;
    }
    @media ${desktopMediaCondition} {
        left: ${props => ((props.level || 1) - 1) * 150}px;

        width: 150px;
    }
`;

const Product = styled.div`
    align-self: center;

    font-weight: bold;
    font-size: 0.9em;

    line-height: 14px;

    cursor: pointer;

    @media print {
        display: none;
    }
`;

const ProductSelector = ({ children, products, location, links, prospectExchangeAccount, ...roleProps }) => {
    const [menuOpened, setMenuStatus] = useState(false);

    const containerRef = useRef();

    const visibleProducts = useMemo(() => products.filter(link => link.isVisible(roleProps)), [products]);

    const currentProduct = useMemo(
        () =>
            visibleProducts
                ?.filter(link => matchPath(window.location.pathname, { path: link.to }))
                .sort((a, b) => b.to.length - a.to.length)[0],
        [visibleProducts, window.location.pathname]
    );

    const currentLink = useMemo(
        () =>
            links
                .filter(link => matchPath(window.location.pathname, { path: link.to }))
                .sort((a, b) => b.to.length - a.to.length)[0],
        [links, window.location.pathname]
    );

    const currentSubmenu = useMemo(
        () =>
            currentLink?.submenu
                ?.filter(link => matchPath(window.location.pathname, { path: link.to }))
                .sort((a, b) => b.to.length - a.to.length)[0],
        [currentLink, window.location.pathname]
    );

    const noVisibleProducts = useMemo(() => visibleProducts.length === 0, [visibleProducts]);

    const closeMenu = useCallback(() => setMenuStatus(false), [setMenuStatus]);
    const toggleMenu = useCallback(() => setMenuStatus(s => !s), [setMenuStatus]);

    const handleClickOutside = useCallback(
        e => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                closeMenu();
            }
        },
        [containerRef, closeMenu]
    );

    useEffect(() => {
        if (!menuOpened) return;
        document.addEventListener('mousedown', handleClickOutside);
        //document.addEventListener('scroll', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            //document.removeEventListener('scroll', handleClickOutside);
        };
    }, [handleClickOutside, menuOpened]);

    return (
        <Container ref={containerRef}>
            <Hamburger
                style={{
                    alignSelf: 'center',
                    padding: prospectExchangeAccount ? '5px' : 'none'
                }}
                color={prospectExchangeAccount ? 'var(--uiPrimaryColorDark)' : 'white'}
                hamburgerIsActive={menuOpened}
                onClick={toggleMenu}
            />
            <Popup opened={menuOpened}>
                {visibleProducts.map(link => (
                    <StyledItem
                        itemIsActive={link === currentProduct}
                        key={link.to}
                        to={link.to}
                        disabled={link.isEnabled && !link.isEnabled(roleProps)}
                    >
                        {link.children}
                    </StyledItem>
                ))}
            </Popup>
            <DeviceSelector
                mobileLayout={
                    <>
                        <Popup level={noVisibleProducts ? 1 : 2} opened={menuOpened}>
                            {links.map(link => (
                                <StyledItem
                                    itemIsActive={link === currentLink}
                                    key={link.to}
                                    to={
                                        (link.to.indexOf('analytics') >= 0 || link.to.indexOf('statistics') >= 0) &&
                                        link.submenu
                                            ? link.submenu?.[0]?.to
                                            : link.to
                                    }
                                    disabled={link.isEnabled && !link.isEnabled(roleProps)}
                                >
                                    {link.children}
                                </StyledItem>
                            ))}
                        </Popup>
                        {currentLink && currentLink.submenu && (
                            <Popup level={noVisibleProducts ? 2 : 3} opened={menuOpened}>
                                {currentLink.submenu.map(link => (
                                    <StyledItem
                                        itemIsActive={link === currentSubmenu}
                                        key={link.to}
                                        to={link.to}
                                        disabled={link.isEnabled && !link.isEnabled(roleProps)}
                                    >
                                        {link.children}
                                    </StyledItem>
                                ))}
                            </Popup>
                        )}
                    </>
                }
            />

            <div onClick={toggleMenu}>{children}</div>
            {!!currentProduct && !!currentProduct.children && (
                <Product onClick={toggleMenu}>{currentProduct.children}</Product>
            )}
        </Container>
    );
};

export default connect(state => ({ links: linksSelector(state) }))(injectRoleManagement(ProductSelector));
