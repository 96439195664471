import { shiftsSelector } from '../../../../../pages/game-center/statistics/shifts/private/shifts-selector';
import { createMediaTimeWrapper, processingState, createEpisodeIdWrapper } from '../../episode-utils';
import { FAVORITE_SHIFT } from '../../favorite-types';
import { createSelector } from 'reselect';

const GAME_SHIFTS = 'GAME_SHIFTS';

const types = { GAME_SHIFTS };

const selector = shiftsSelector;

const shiftsDataSelector = createEpisodeIdWrapper(
    GAME_SHIFTS,
    createSelector(
        shifts => shifts.data,
        data => ({ data: data.map(d => ({ isShift: true, ...d })) })
    )
);

function mapper(shifts) {
    if (shifts.processing) return processingState;
    return shiftsDataSelector(shifts);
}

const favoriteType = FAVORITE_SHIFT;

const getTime = ({ startTime, endTime }) => ({ startTime, endTime, time: startTime, hasPadding: false });

const mediaTimeWrapper = createMediaTimeWrapper(getTime);

function getTimedGroup(group, periods) {
    return mediaTimeWrapper(group, periods);
}

export const shifts = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
