import { getEntriesWithPlayerId } from '../../../../../containers/widgets-stats/selectors/entries';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { FAVORITE_ZONE_CHANGE } from '../../favorite-types';
import entries, { createEntryTypeSelector } from './entries';

const entryTypeToGameEntryType = typeId => `GAME_${typeId}`;
const gameEntryTypeToEntryType = typeId => typeId.substring(5);

const GAME_CARRIES_IN = entryTypeToGameEntryType(entries.types.CARRIES_IN);
const GAME_CARRIES_OUT = entryTypeToGameEntryType(entries.types.CARRIES_OUT);

const types = { GAME_CARRIES_IN, GAME_CARRIES_OUT };

const selector = getEntriesWithPlayerId;

const typeToSelector = {
    [GAME_CARRIES_IN]: createEpisodeIdWrapper(
        GAME_CARRIES_IN,
        createEntryTypeSelector(gameEntryTypeToEntryType(GAME_CARRIES_IN))
    ),
    [GAME_CARRIES_OUT]: createEpisodeIdWrapper(
        GAME_CARRIES_OUT,
        createEntryTypeSelector(gameEntryTypeToEntryType(GAME_CARRIES_OUT))
    )
};

function mapper(gameEntries, type) {
    if (gameEntries.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Game Entries');
    return typeToSelector[type](gameEntries);
}

const getTime = ({ endTime, time }) => ({
    startTime: time,
    endTime,
    time,
    hasPadding: true,
    leftPadding: 3,
    rightPadding: 3
});

const typeToMediaTimeWrapper = Object.keys(typeToSelector)
    .map(key => ({ key, wrapper: createMediaTimeWrapper(getTime) }))
    .reduce((a, d) => {
        a[d.key] = d.wrapper;
        return a;
    }, {});

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const favoriteType = FAVORITE_ZONE_CHANGE;

const gameEntries = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default gameEntries;
