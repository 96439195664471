/* eslint-disable eqeqeq */

const identity = d => d;

export const compareFunctions = {
    ascValue: (a, b) => (a > b ? 1 : a === b ? 0 : -1),
    descValue: (a, b) => (a < b ? 1 : a === b ? 0 : -1)
};

const createSortComparator = (valueExtractor = identity, compareFunction = compareFunctions.ascValue) => {
    return (a, b) => compareFunction(valueExtractor(a), valueExtractor(b));
};

export default createSortComparator;
