import { css, createGlobalStyle } from 'styled-components/macro';
import { themes } from '../../../redux/modules/theme';

const FOR = 'for';
const AGAINST = 'against';
const forPrefixSelector = ({ colorSchemeIsToggled }) => (!colorSchemeIsToggled ? FOR : AGAINST);
const againstPrefixSelector = ({ colorSchemeIsToggled }) => (colorSchemeIsToggled ? FOR : AGAINST);

const commonCssVariables = css`
  --uiPrimaryColor: #1D7DFF;
  --uiPrimaryColorActive: #1D7DFF;
  --uiPrimaryColorDark: #1D7DFF;
  --uiPrimaryTextHighlighted: #c2deef;
  --uiPrimaryColorTransparent: rgba(29, 125, 255, 0.15);

  --uiHeaderColor: #0E1D28;

  --uiModeratorColor: #92a5e8;
  --uiSupportColor: #98BE6B;
  --uiVerifierColor: #841e5c;

  --uiLoadColorLow: #F7FFEE;
  --uiLoadColorMedium: rgba(255, 198, 106, 0.15);
  --uiLoadColorHigh: rgba(255, 170, 188, 0.35);

  --successColor: #5cb85c;
  --cancelColor: #d54866;

  --backgroundColor: #ecf0f5;
  --backgroundColorSecondary: white;

  --defaultColor: #0E1D28;

  --${forPrefixSelector}Color: #2967B0;
  --${forPrefixSelector}ColorActive: var(--uiPrimaryColor);
  --${forPrefixSelector}ColorGates: #3779c6;
  --${againstPrefixSelector}Color: #d54866;
  --${againstPrefixSelector}ColorActive: #f44e72;
  --${againstPrefixSelector}ColorGates: #d0021b;

  --firstOption: #DDEDF6;
  --secondOption: #fff;
  --thirdOption: #F6F8FB;

  --optionsPrimaryColor: #6f798b;
  --optionsDisabledColor: #bbc8e2;

  --alternativeBackgroundColor: #d4d5dd;
`;

const themesToCssVariables = {
    [themes.DARCULA]: css`
        --uiPrimaryColor: var(--uiPrimaryColor);
        --uiPrimaryColorActive: #2967b0;
        --uiPrimaryColorDark: #3779c6;
        --uiPrimaryTextHighlighted: #8b99a0;
        --uiPrimaryColorTransparent: rgba(44, 49, 58, 0.3);

        --uiHeaderColor: #141d23;

        --backgroundColor: #172028;
        --backgroundColorSecondary: #26333c;

        --defaultColor: #ffffff;

        --alternativeBackgroundColor: #3f5564;

        --firstOption: #3f5564;
        --secondOption: #657b8b;
        --thirdOption: #607079;
    `,
    [themes.HOCKEY_TECH]: css`
        --blackColor: #000000;
        --darkGrayColor: #282828;
        --mediumGrayColor: #808080;
        --lightGrayColor: #ebebeb;
        --whiteColor: #ffffff;
        --transparentGreenColor1: #a0d0a044;
        --transparentGreenColor2: #a0d0a0aa;
        --transparentGreenColor3: #a0d0a0ff;
        
        --uiHeaderColor: #000000;
    
        --uiPrimaryColor: #00a000;
        --uiPrimaryColorActive: #00e000;
        --uiPrimaryColorDark: #70937c;
        --uiPrimaryTextHighlighted: #a6c4b1;
        --uiPrimaryColorTransparent: var(--transparentGreenColor1);
        
        --backgroundColor: #ecf5f0;
        
        --${forPrefixSelector}Color: #00a000;
        --${forPrefixSelector}ColorActive: #00e000;
        --${forPrefixSelector}ColorGates: #00c000;

        --alternativeBackgroundColor: #f6f8fb;`
};

export default createGlobalStyle`
    :root {
        ${commonCssVariables}
        ${({ appTheme }) => themesToCssVariables[appTheme]}
    }
`;
