import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import Goaltender from '../goaltender/goaltender';

const PlaceholderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const Wrapper = styled.div`
    position: ${props => (props.fill ? 'absolute' : 'initial')};

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Bar = styled.div`
    height: ${props => (props.fill ? '100%' : '2px')};
    overflow-x: hidden;

    background-color: transparent;
`;

const loading = keyframes`
    from {
        left: calc(-1 * var(--gradWidth));
    }
    to {
        left: 100%;
    }
`;

const Slider = styled.div`
    --gradWidth: 80%;

    position: relative;

    width: var(--gradWidth);
    height: 100%;

    background: linear-gradient(to right, transparent, var(--uiPrimaryColor));

    animation: ${loading} 2s linear infinite;
`;

const InfiniteProgressBar = ({ placeholder, fill, style }) => (
    <Wrapper fill={fill} style={style}>
        <Bar fill={fill}>
            <Slider />
        </Bar>
        {placeholder && (
            <PlaceholderWrapper>
                <Goaltender width="360" height="328" contrast />
            </PlaceholderWrapper>
        )}
    </Wrapper>
);

export default InfiniteProgressBar;
