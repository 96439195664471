import { connect } from 'react-redux';
import { getUserProfilePictureHref } from '../../selectors/user/user-profiles-selector';
import Logo from '../../components/logo/logo';

const DEFAULT_CLIENT_PICTURE = 'https://icebergmedia.blob.core.windows.net/logos/default_client_picture.png';

const mapStateToProps = state => {
    return { src: getUserProfilePictureHref(state) || DEFAULT_CLIENT_PICTURE };
};

// What is it for?
const skipDispatchFromProps = () => ({});

const UserPicture = connect(
    mapStateToProps,
    skipDispatchFromProps
)(Logo);

export default UserPicture;
