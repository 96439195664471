import {
    LicenseHeaderContainer,
    ParagraphContent,
    ParagraphContentWithSpace,
    ParagraphTitle
} from './license-content-styled';

export const LicenseContent = () => (
    <>
        <LicenseHeaderContainer>
            <strong>
                <h4>END USER LICENSE AGREEMENT</h4>
            </strong>
        </LicenseHeaderContainer>
        <ParagraphContent>
            <span>
                This End User License Agreement (this &quot;Agreement&quot; or&nbsp;&quot;EULA&quot;),
                is&nbsp;a&nbsp;legally binding agreement between The Prospect Exchange, LLC (also referred
                to&nbsp;as&nbsp;&quot;TPE&quot;,&nbsp;&quot;us&quot;, &quot;we&quot;, &quot;our&quot; or&nbsp;&quot;the
                Company&quot;) and you, the person that accepts this license and the company or&nbsp;entity that you
                represent which has subscribed to services from TPE through a&nbsp;Customer Agreement or&nbsp;Master
                Agreement.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                PLEASE READ THIS END USER LICENSE AGREEMENT (THE &quot;EULA&quot;) CAREFULLY BEFORE ACCEPTING THE TERMS
                AND CONDITIONS OF&nbsp;THIS EULA BETWEEN YOU (REFERRED TO&nbsp;HEREIN AS&nbsp;&quot;YOU,&quot;
                &quot;YOUR&quot;, OR&nbsp;&quot;LICENSEE&quot;) AND TPE. YOU, AS&nbsp;THE LICENSEE, ARE ONLY AUTHORIZED
                TO&nbsp;ACCEPT THE TERMS OF&nbsp;THIS EULA SUBJECT TO&nbsp;A&nbsp;CUSTOMER AGREEMENT OR&nbsp;MASTER
                AGREEMENT OR&nbsp;OTHER AGREEMENT (THE &quot;TPE CUSTOMER AGREEMENT(S)&quot;) THAT AUTHORIZES YOUR USE
                OR&nbsp;THE TPE SOFTWARE, WEBSITE, AND SERVICES (THE &quot;SERVICES&quot;).
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                BY&nbsp;ACCEPTING THIS EULA, YOU ARE AGREEING ON&nbsp;BEHALF OF&nbsp;YOURSELF AND THE ENTITY
                OR&nbsp;ORGANIZATION TO&nbsp;WHICH YOU BELONG THAT YOU ARE AN&nbsp;AUTHORIZED USER UNDER AN&nbsp;ACTIVE
                TPE CUSTOMER AGREEMENT AND THAT YOU HAVE THE AUTHORITY TO&nbsp;BID&nbsp;SUCH ENTITY TO&nbsp;THE TERMS
                OF&nbsp;THIS EULA. IF&nbsp;YOU DO&nbsp;NOT AGREE TO&nbsp;ALL&nbsp;OF THE TERMS OF THE EULA OR,
                IF&nbsp;YOU DO&nbsp;NOT HAVE THE AUTHORITY TO&nbsp;BIND THE ENTITY AUTHORIZING YOUR PURSUANT
                TO&nbsp;A&nbsp;TPE CUSTOMER AGREEMENT, THEN DO&nbsp;NOT ACCESS OR&nbsp;USE THESE SERVICES.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                THIS EULA GOVERNS YOUR USE OF&nbsp;THE SERVICES. TO&nbsp;USE THE SERVICES, YOU MUST INDICATE YOUR ASSENT
                TO THE EULA BY&nbsp;CLICKING &quot;AGREE.&quot; UNLESS AND UNTIL&nbsp;YOU HAVE AGREED
                TO&nbsp;BE&nbsp;BOUND BY&nbsp;ALL&nbsp;OF THE TERMS OF THE EULA, YOU HAVE NOT BECOME A&nbsp;LICENSEE OF,
                AND ARE NOT AUTHORIZED TO&nbsp;USE, THE SERVICES.
            </span>
        </ParagraphContent>

        <ParagraphTitle>1. </ParagraphTitle>
        <u>
            <ParagraphTitle>Definitions</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>
                &quot;Authorized User&quot; means each user of&nbsp;your Entity which will be&nbsp;identified
                by&nbsp;you via the Site or Customer Agreement.
            </span>
        </ParagraphContentWithSpace>
        <ParagraphContent>
            <span>
                &quot;Entity&quot; means the organization, team, league or&nbsp;other entity that you represent when
                establishing an account on&nbsp;the Site.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                &quot;Feedback&quot; means any comments, information, questions, survey data, data, ideas, enhancement
                requests, recommendations, descriptions of&nbsp;processes, or&nbsp;other information concerning the Site
                or&nbsp;the Services, whether solicited by&nbsp;TPE or&nbsp;provided by&nbsp;the Entity or&nbsp;its
                Authorized Users without any such solicitation.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                &quot;Intellectual Property Rights&quot; means any rights under any patent (including patent
                applications and disclosures), copyright, trademark, trade secret, know-how or&nbsp;other intellectual
                property right recognized under applicable law or&nbsp;regulation in&nbsp;any jurisdiction.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                &quot;TPE Representatives&quot; means TPE&rsquo;s officers, directors, employees, licensees,
                sub-licensees, advisors and representatives, and TPE&rsquo;s affiliates and its affiliates&rsquo;
                officers, directors, employees, licensees, sub-licensees, advisors and representatives.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                &quot;Modifications&quot; means any work based on&nbsp;or&nbsp;incorporating all or&nbsp;any portion
                of&nbsp;the Services, including, without limitation, modifications, updates, enhancements,
                customizations, supplements, and any derivative works made to&nbsp;the Services.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                &quot;User&quot; means any individual or&nbsp;entity that accesses or&nbsp;uses the Services, including
                TPE&rsquo;s website.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                &quot;Video&quot; means video clips, video fragments and other game or&nbsp;practice film including the
                voices, performances, poses, acts, plays, appearances pictures, images, likeness, photo groups,
                silhouettes and other reproductions of&nbsp;the physical likeness and sound of&nbsp;the players, and all
                others appearing in&nbsp;such video.
            </span>
        </ParagraphContent>

        <ParagraphTitle>2. </ParagraphTitle>
        <u>
            <ParagraphTitle>License</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>i. </span>
            <span>
                Subject to&nbsp;the terms and conditions of&nbsp;this EULA, TPE grants to&nbsp;you a&nbsp;limited term,
                non-exclusive, non-commercial, non-transferable, and revocable license to&nbsp;access and use the
                Services solely for use in accordance with the TPE Customer Agreements and this EULA.
            </span>
        </ParagraphContentWithSpace>
        <ParagraphContent>
            <span>ii.</span>
            <span>
                The terms and conditions of&nbsp;this EULA applies to&nbsp;any Modifications, unless TPE provides you
                with additional or&nbsp;different terms along with the Modifications.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>iii.</span>
            <span>
                TPE owns all right, title, and interest in&nbsp;the Services, including all copyrights, trademarks,
                patents and trade secrets. You may not: (a) reproduce, display, download, modify, create derivative
                works of&nbsp;or&nbsp;distribute the Services, in&nbsp;whole or&nbsp;in&nbsp;part, or&nbsp;make any
                Modifications; (b) attempt to&nbsp;reverse engineer, decompile, disassemble or&nbsp;access the source
                code associated with any aspect of&nbsp;Services or&nbsp;any component thereof; &copy;&nbsp;share
                or&nbsp;otherwise permit any other party to&nbsp;access the Services; (d) transmit the Services,
                or&nbsp;any software component associated with the Services, in&nbsp;whole or&nbsp;in&nbsp;part,
                electronically by&nbsp;any means; (e) access the Services via any means other than using authorized
                login credentials. Further, access and use of&nbsp;the Services are for non-commercial purposes, which
                includes a&nbsp;prohibition against (i) using the Services to&nbsp;perform services for a&nbsp;fee and
                (ii) using the Services for the production, commercialization, or&nbsp;manufacture of&nbsp;content for
                sale, license, transfer, or&nbsp;distribution to&nbsp;third parties. This EULA does not grant you any
                right to&nbsp;use the Services, or&nbsp;any part of&nbsp;the Services, other than as&nbsp;authorized
                in&nbsp;this EULA and the TPE Customer Agreement.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>iv.</span>
            <span>
                You acknowledge that all license fees due for use of&nbsp;the Services have been fully paid
                in&nbsp;accordance with this EULA and the TPE Customer Agreement.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>v.</span>
            <span>
                TPE retains the right to&nbsp;immediately terminate access to&nbsp;the Services if&nbsp;license fees are
                not paid when due, if&nbsp;there is&nbsp;a&nbsp;breach of&nbsp;the terms of&nbsp;this EULA
                or&nbsp;a&nbsp;TPE Customer Agreement, or&nbsp;if&nbsp;there is&nbsp;a violation of&nbsp;any law
                or&nbsp;code of&nbsp;conduct.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>vi.</span>
            <span>
                You are responsible and liable for all uses of&nbsp;the Services through access thereto provided
                by&nbsp;you, directly or&nbsp;indirectly. Specifically, and without limiting the generality of&nbsp;the
                foregoing, You are responsible and liable for all actions and failures to&nbsp;take required actions
                with respect to&nbsp;the Services by&nbsp;itself or&nbsp;by&nbsp;any other person to&nbsp;whom you may
                provide access to&nbsp;or&nbsp;use of&nbsp;the Services, whether such access or&nbsp;use
                is&nbsp;permitted by&nbsp;or&nbsp;in&nbsp;violation of&nbsp;this EULA or&nbsp;a&nbsp;TPE Customer
                Agreement.
            </span>
        </ParagraphContent>

        <ParagraphTitle>3. </ParagraphTitle>
        <u>
            <ParagraphTitle>Ownership; Feedback; Interruption and Discontinuation of&nbsp;Services</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>
                You agree that TPE owns all rights, title and interests in&nbsp;the Services and any and all
                Modifications thereto, including all intellectual property rights associated therewith or&nbsp;embodied
                therein. TPE shall have the right to&nbsp;contact you as&nbsp;necessary to&nbsp;obtain any suggestions,
                enhancement requests, recommendations, code contributions or&nbsp;other feedback you might have related
                to&nbsp;the use and performance of&nbsp;the Services (&quot;Feedback&quot;). While TPE encourages such
                Feedback as&nbsp;a&nbsp;way to&nbsp;make Modifications or to&nbsp;improve its products and services
                to&nbsp;you and other customers, it&nbsp;has not obligation to&nbsp;respond to&nbsp;act upon such
                Feedback. You acknowledge that TPE retains any and all ownership rights to&nbsp;Feedback, including the
                exclusive right to&nbsp;use and incorporate such Feedback as&nbsp;part of&nbsp;any Modifications
                or&nbsp;into any product or&nbsp;service offered by&nbsp;TPE. Feedback is&nbsp;provided
                at&nbsp;no&nbsp;cost or&nbsp;charge to&nbsp;TPE. You hereby assign and transfer to&nbsp;TPE any and all
                proprietary or&nbsp;intellectual property rights you might have in&nbsp;such Feedback. TPE
                is&nbsp;permitted to&nbsp;discontinue or&nbsp;suspend any feature or&nbsp;aspect of&nbsp;the Services to
                make changes, edits, or&nbsp;for any other reason. There are no&nbsp;assurances of&nbsp;the Services
                or&nbsp;features of&nbsp;the Services being available to&nbsp;access or&nbsp;user, other than those
                assurances provided in&nbsp;a&nbsp;TPE Customer Agreement.
            </span>
        </ParagraphContentWithSpace>
        <ParagraphTitle>4. </ParagraphTitle>
        <u>
            <ParagraphTitle>Support</ParagraphTitle>
        </u>
        <ParagraphContentWithSpace>
            <span>
                TPE may offer support services pursuant to&nbsp;a&nbsp;separate written agreement. No&nbsp;support
                services are offered under the EULA.
            </span>
        </ParagraphContentWithSpace>
        <ParagraphTitle>5. </ParagraphTitle>
        <u>
            <ParagraphTitle>User Code of&nbsp;Conduct</ParagraphTitle>
        </u>
        <ParagraphContentWithSpace>
            <span>You agree not to&nbsp;use the Services to&nbsp;take any action(s) that:</span>
        </ParagraphContentWithSpace>
        <ul>
            <li>
                <span>
                    Are patently offensive and promote racism, bigotry, hatred or&nbsp;physical harm of&nbsp;any kind
                    against any group or&nbsp;individual; harasses or&nbsp;advocates harassment of&nbsp;another person
                    or&nbsp;group; exploits people in a&nbsp;sexual or&nbsp;violent manner; or&nbsp;contains nudity,
                    violence, or&nbsp;offensive subject matter or&nbsp;contains a link to&nbsp;an&nbsp;adult website;
                </span>
            </li>
            <li>
                <span>
                    Solicits personal information; provides any user telephone numbers, street addresses, last names,
                    urls or&nbsp;email addresses; involves the transmission of&nbsp;&quot;junk mail,&quot; &quot;chain
                    letters,&quot; or&nbsp;&quot;unsolicited mass mailing&quot;, &quot;instant messaging&quot;,
                    &quot;phishing&quot;, &quot;spimming&quot; or&nbsp;&quot;spamming&quot;; contains restricted or
                    password only access pages or&nbsp;hidden pages or&nbsp;images (those not linked
                    to&nbsp;or&nbsp;from another accessible page);
                </span>
            </li>
            <li>
                <span>
                    Promotes information that you know is&nbsp;false or&nbsp;misleading or&nbsp;promotes illegal
                    activities or&nbsp;conduct that is&nbsp;abusive, threatening, obscene, defamatory or&nbsp;libelous;
                    promotes an&nbsp;illegal or&nbsp;unauthorized copy of&nbsp;another person&rsquo;s copyrighted work,
                    such as&nbsp;providing pirated computer programs or&nbsp;links to them, providing information
                    to&nbsp;circumvent manufacture-installed copy-protect devices, or&nbsp;providing pirated music
                    or&nbsp;video or&nbsp;links to&nbsp;pirated files;
                </span>
            </li>
            <li>
                <span>
                    Furthers or&nbsp;promotes any criminal activity or&nbsp;enterprise or&nbsp;provides instructional
                    information about illegal activities including, but not limited to&nbsp;making or&nbsp;buying
                    illegal weapons, violating someone&rsquo;s privacy, or&nbsp;providing or&nbsp;creating computer
                    viruses;
                </span>
            </li>
            <li>
                <span>
                    Involves commercial activities and/or sales without our prior written consent such as&nbsp;contests,
                    sweepstakes, barter, advertising, or&nbsp;pyramid schemes;
                </span>
            </li>
            <li>
                <span>Are contrary to&nbsp;TPE&rsquo;s public image, goodwill, or&nbsp;reputation;</span>
            </li>
            <li>
                <span>
                    Infringe on&nbsp;our or&nbsp;any third party&rsquo;s intellectual property rights or&nbsp;rights
                    of&nbsp;publicity or&nbsp;privacy;
                </span>
            </li>
            <li>
                <span>
                    Express or&nbsp;imply that any of&nbsp;your statements, activities or&nbsp;causes are endorsed
                    by&nbsp;us, without our prior written consent in&nbsp;each instance;
                </span>
            </li>
            <li>
                <span>
                    Transmit any trade secret or&nbsp;other material, non-public information about any person, company
                    or entity without the authorization to&nbsp;do&nbsp;so;
                </span>
            </li>
            <li>
                <span>
                    &quot;frame&quot; or&nbsp;&quot;mirror&quot; any part of&nbsp;the site without our prior written
                    authorization;
                </span>
            </li>
            <li>
                <span>
                    Distribute any virus, worm or&nbsp;other similar or&nbsp;deleterious files, scripts
                    or&nbsp;programming routines;
                </span>
            </li>
            <li>
                <span>
                    Interfere with or&nbsp;disrupt any services or&nbsp;equipment with the intent of&nbsp;causing
                    an&nbsp;excessive or disproportionate load on&nbsp;the infrastructure of&nbsp;agile or&nbsp;its
                    licensors or&nbsp;suppliers;
                </span>
            </li>
            <li>
                <span>
                    Forge headers or&nbsp;otherwise manipulate identifiers in&nbsp;order to&nbsp;disguise the origin
                    of&nbsp;any submission;
                </span>
            </li>
            <li>
                <span>
                    Override any security feature or&nbsp;bypass or&nbsp;circumvent any access controls or&nbsp;use
                    limits of&nbsp;the site;
                </span>
            </li>
            <li>
                <span>
                    Rent, lease, loan, trade sell/re-sell or&nbsp;otherwise monetize the services or&nbsp;related data
                    or&nbsp;access to&nbsp;the same, without TPE&rsquo;s advisor; and/or
                </span>
            </li>
            <li>
                <span>
                    Execute any form of&nbsp;network monitoring or&nbsp;run a&nbsp;network analyzer or&nbsp;packet
                    sniffer or&nbsp;other technology to&nbsp;intercept, decode, mine or&nbsp;display any packets used
                    to&nbsp;communicate between the website&rsquo;s servers or&nbsp;any data not intended for you.
                </span>
            </li>
        </ul>
        <ParagraphContent>
            <span>Further, you agree not to&nbsp;use the Services to&nbsp;participate&nbsp;in:</span>
        </ParagraphContent>
        <ul>
            <li>
                <span>
                    Criminal or&nbsp;tortious activity, including child pornography, fraud, trafficking in&nbsp;obscene
                    material, drug dealing, gambling, harassment, stalking, spamming, spimming, sending of&nbsp;viruses
                    or&nbsp;other harmful files, trademark infringement, passing off, copyright infringement, patent
                    infringement, or theft of&nbsp;trade secrets or&nbsp;violation of&nbsp;the privacy or&nbsp;publicity
                    rights of&nbsp;third parties;
                </span>
            </li>
            <li>
                <span>
                    Advertising&nbsp;to, or&nbsp;solicitation&nbsp;of, any user to&nbsp;buy or&nbsp;sell any products
                    or&nbsp;services through the Services. You may not transmit any chain letters or&nbsp;junk email
                    to&nbsp;other Users. It&nbsp;is&nbsp;also a violation of&nbsp;these rules to&nbsp;use any
                    information obtained from the Services in&nbsp;order to&nbsp;contact, advertise&nbsp;to, solicit,
                    or&nbsp;sell to&nbsp;any User without their prior explicit consent. In&nbsp;order to&nbsp;protect
                    our Users from such advertising or&nbsp;solicitation, TPE reserves the right to&nbsp;restrict the
                    number of emails that a&nbsp;User may send to&nbsp;other Users;
                </span>
            </li>
        </ul>
        <ParagraphTitle>6. </ParagraphTitle>
        <u>
            <ParagraphTitle>Third-Party Materials</ParagraphTitle>
        </u>
        <ParagraphContentWithSpace>
            <span>
                The Services may include content, data or&nbsp;other materials, including related documentation
                or&nbsp;videos, that are owned by&nbsp;individuals or&nbsp;legal entities other than TPE and that are
                provided to&nbsp;you on&nbsp;terms that are in&nbsp;addition to&nbsp;and/or different from those
                contained in&nbsp;this EULA or&nbsp;TPE Customer Agreement. You are responsible for compliance with any
                third party licenses and any breach of&nbsp;such third party licenses or&nbsp;misuse of&nbsp;third party
                materials is&nbsp;a&nbsp;breach of&nbsp;this EULA. To&nbsp;the extent you use the Services
                to&nbsp;transmit, manage, or&nbsp;create materials or&nbsp;content you represent and warrant that you
                own or otherwise have the legal rights to&nbsp;use such materials and content and that your use will not
                infringe, misappropriate or&nbsp;otherwise violate any proprietary, intellectual property,
                or&nbsp;privacy right of&nbsp;any third party, or&nbsp;violate any applicable law. You are solely
                responsible for obtaining the legal rights to make use of&nbsp;such content, materials, and any
                derivative works used with the Services.
            </span>
        </ParagraphContentWithSpace>

        <ParagraphTitle>7. </ParagraphTitle>
        <u>
            <ParagraphTitle>Confidentiality</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>
                Neither party nor any of&nbsp;their respective affiliates, if&nbsp;any, shall use or&nbsp;disclose
                to&nbsp;any person, directly or&nbsp;indirectly, any confidential information provided from either party
                to&nbsp;the other, except in performance of&nbsp;the obligations contained in&nbsp;in&nbsp;this EULA
                or&nbsp;any TPE Customer Agreement. However, nothing in&nbsp;this clause shall preclude either party
                or&nbsp;any of&nbsp;their respective affiliates, if&nbsp;any, from disclosing or&nbsp;using confidential
                information&nbsp;if:
            </span>
        </ParagraphContentWithSpace>
        <ParagraphContent>
            <span>
                (i) the confidential information is&nbsp;available to&nbsp;the public or&nbsp;in&nbsp;the public domain
                at&nbsp;the time of&nbsp;such disclosure or&nbsp;use, without breach of&nbsp;these terms or&nbsp;any TPE
                Customer Agreement;
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                (ii) disclosure is&nbsp;required to&nbsp;be&nbsp;made by&nbsp;any law, regulation, governmental body
                or&nbsp;authority or&nbsp;by court order;
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                (iii) disclosure is&nbsp;made in&nbsp;connection with any arbitration pursuant to&nbsp;these terms
                or&nbsp;any TPE Customer Agreement; or
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                (iv) disclosure is&nbsp;made to&nbsp;a&nbsp;court which is&nbsp;determining the rights of&nbsp;the
                parties under these terms or any TPE Customer Agreement.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>
                Each party and their respective affiliates, if&nbsp;any, acknowledge and agree that the obligations
                under this clause are to&nbsp;remain in&nbsp;effect in&nbsp;perpetuity. Data produced pursuant
                to&nbsp;these Terms of&nbsp;Use or&nbsp;any Customer Agreement as&nbsp;part of&nbsp;the Services shall
                not be&nbsp;deemed to&nbsp;be&nbsp;confidential information hereunder.
            </span>
        </ParagraphContent>

        <ParagraphTitle>8. </ParagraphTitle>
        <u>
            <ParagraphTitle>Term and Termination</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>
                This EULA commences on&nbsp;the date upon which you accept these terms and continues until and unless
                otherwise terminated in&nbsp;accordance with its terms or&nbsp;the terms of&nbsp;a&nbsp;TPE Customer
                Agreement.
            </span>
        </ParagraphContentWithSpace>

        <ParagraphTitle>9. </ParagraphTitle>
        <u>
            <ParagraphTitle>Representations and Warranties</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>i.</span>
            <span>
                Each party to&nbsp;this EULA represents and warrants that it&nbsp;has the full right power and authority
                to&nbsp;enter into this EULA and that they will comply will all laws and regulations applicable
                to&nbsp;the access and use of&nbsp;the Services.
            </span>
        </ParagraphContentWithSpace>
        <ParagraphContent>
            <span>ii. </span>
            <span>
                EXCEPT FOR THE LIMITED WARRANTY SET FORTH ABOVE, THE SERVICES ARE PROVIDED &quot;AS-IS&quot; AND WITH
                ALL&nbsp;FAULTS AND DEFECTS WITHOUT WARRANTY OF&nbsp;ANY KIND. TO&nbsp;THE MAXIMUM EXTENT PERMITTED
                UNDER APPLICABLE LAW, TPE, ON&nbsp;ITS OWN BEHALF AND ON&nbsp;BEHALF OT&nbsp;ITS AFFILIATES AND ITS AND
                THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIM&nbsp;ALL&nbsp;WARRANTIES, WHETHER
                WRITTEN, ORAL, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING BUT NOT LIMITED TO&nbsp;THE WARRANTIES
                OF&nbsp;MERCHANTABILITY, FITNESS FOR A&nbsp;PARTICULAR PURPOSE, AND ANY ARISING OUT OF&nbsp;COURSE
                OF&nbsp;DEALING, USAGE OR&nbsp;TRADE.
            </span>
            <span>
                WITHOUT LIMITATION TO&nbsp;THE FOREGOING, TPE PROVIDES NO&nbsp;WARRANTY OR&nbsp;UNDERTAKING, AND MAKES
                NO REPRESENTATION OF&nbsp;ANY KIND THAT THE LICENSED SERVICES WILL&nbsp;MEET THE LICENSEE&rsquo;S
                REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE&nbsp;COMPATIBLE OR&nbsp;WORK WITH ANY OTHER SOFTWARE,
                APPLICATIONS, SYSTEMS OR&nbsp;SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE
                OR&nbsp;RELIABILITY STANDARDS OR&nbsp;BE&nbsp;ERROR FREE OR&nbsp;THAT ANY ERRORS OR&nbsp;DEFECTS CAN
                OR&nbsp;WILL&nbsp;BE CORRECTED.
            </span>
        </ParagraphContent>

        <ParagraphTitle>10. </ParagraphTitle>
        <u>
            <ParagraphTitle>Limitation of&nbsp;Liability</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>TO&nbsp;THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW:</span>
        </ParagraphContentWithSpace>
        <ParagraphContent>
            <span>i.</span>
            <span>
                IN&nbsp;NO&nbsp;EVENT WILL&nbsp;TPE OR&nbsp;ITS AFFILIATES, OR&nbsp;ANY OF&nbsp;ITS OR&nbsp;THEIR
                RESPECTIVE LICENSORS OR&nbsp;SERVICE PROVIDERS, BE&nbsp;LIABLE TO&nbsp;YOU OR&nbsp;ANY THIRD PARTY FOR
                ANY USE, INTERRUPTION, DELAY OR&nbsp;INABILITY TO&nbsp;USE THE SOFTWARE, LOST REVENUES OR&nbsp;PROFITS,
                DELAYS, INTERRUPTION OR&nbsp;LOSS OF&nbsp;SERVICES, BUSINESS OR&nbsp;GOODWILL, LOSS OR&nbsp;CORRUPTION
                OF&nbsp;DATA, LOSS RESULTING FROM SYSTEM OR&nbsp;SYSTEM SERVICE FAILURE, MALFUNCTION OR&nbsp;SHUTDOWN,
                FAILURE TO&nbsp;ACCURATELY TRANSFER, READ OR&nbsp;TRANSMIT INFORMATION, FAILURE TO&nbsp;UPDATE
                OR&nbsp;PROVIDE CORRECT INFORMATION, SYSTEM INCOMPATIBILITY OR&nbsp;PROVISION OF&nbsp;INCORRECT
                COMPATIBILITY INFORMATION OR&nbsp;BREACHES IN SYSTEM SECURITY, OR&nbsp;FOR ANY CONSEQUENTIAL,
                INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR&nbsp;PUNITIVE DAMAGES, WHETHER ARISING OUT
                OF&nbsp;OR&nbsp;IN&nbsp;CONNECTION WITH THIS AGREEMENT, BREACH OF&nbsp;CONTRACT, TORT (INCLUDING
                NEGLIGENCE) OR&nbsp;OTHERWISE, REGARDLESS OF&nbsp;WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER
                OR&nbsp;NOT THE LICENSOR WAS ADVISED OF&nbsp;THE POSSIBILITY OF&nbsp;SUCH DAMAGES.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>ii.</span>
            <span>
                IN&nbsp;NO&nbsp;EVENT WILL&nbsp;LICENSOR&rsquo;S AND ITS AFFILIATES&rsquo;, INCLUDING ANY OF&nbsp;ITS
                OR&nbsp;THEIR RESPECTIVE LICENSORS&rsquo; AND SERVICE PROVIDERS&rsquo;, COLLECTIVE AGGREGATE LIABILITY
                ARISING OUT OF&nbsp;OR&nbsp;RELATED TO&nbsp;THIS AGREEMENT, WHETHER ARISING OUT OF&nbsp;OR&nbsp;RELATED
                TO&nbsp;BREACH OF&nbsp;CONTRACT, TORT (INCLUDING NEGLIGENCE) OR&nbsp;OTHERWISE, EXCEED 50% OF THE FEES
                PAID&nbsp;TO TPE BY&nbsp;YOU PURSUANT TO&nbsp;THESE TERMS OF&nbsp;USE DURING THE TWELVE MONTHS PRECEDING
                THE DATE ON&nbsp;WHICH THE LIABILITY ARISES.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>iii. </span>
            <span>
                THE LIMITATIONS SET FORTH ABOVE SHALL&nbsp;APPLY EVEN IF&nbsp;THE LICENSEE&rsquo;S REMEDIES UNDER THIS
                AGREEMENT FAIL&nbsp;OF THEIR ESSENTIAL PURPOSE.
            </span>
        </ParagraphContent>

        <ParagraphTitle>11. </ParagraphTitle>
        <u>
            <ParagraphTitle>Indemnification</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>
                You shall fully indemnify, defend, and hold harmless TPE and the TPE Representatives from and against,
                any and all claims, losses, liability, damages and expenses, including attorneys&rsquo; fees, arising
                from a third party claim to&nbsp;the extent that such third party claim is&nbsp;base on&nbsp;your use
                of&nbsp;any content or materials, a&nbsp;breach of&nbsp;this EULA or&nbsp;TPE Customer Agreement,
                or&nbsp;your negligence or&nbsp;other act or&nbsp;omission in&nbsp;connection with use of&nbsp;the
                Services.
            </span>
        </ParagraphContentWithSpace>

        <ParagraphTitle>12. </ParagraphTitle>
        <u>
            <ParagraphTitle>Export Restrictions</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>
                You acknowledge that the Services, and associated software, are of&nbsp;U.S. origin. You agree
                to&nbsp;comply with all applicable international and national laws that apply to&nbsp;the Services,
                including the U.S. Export Administration Regulations, as&nbsp;well as&nbsp;end-user, end-use, and
                destination restrictions issued by&nbsp;U.S. and other governments.
            </span>
        </ParagraphContentWithSpace>

        <ParagraphTitle>13. </ParagraphTitle>
        <u>
            <ParagraphTitle>Miscellaneous</ParagraphTitle>
        </u>

        <ParagraphContentWithSpace>
            <span>i.</span>
            <span>
                The waiver of&nbsp;any breach or&nbsp;default under this EULA does not constitute the waiver of&nbsp;any
                subsequent breach or&nbsp;default. If&nbsp;any provision of&nbsp;this EULA is&nbsp;held
                to&nbsp;be&nbsp;illegal or&nbsp;unenforceable, it&nbsp;shall be&nbsp;deemed amended to&nbsp;confirm
                to&nbsp;applicable laws or&nbsp;regulations, or, if&nbsp;it&nbsp;cannot be&nbsp;so&nbsp;amended without
                materially altering the intention of&nbsp;the parties, it&nbsp;shall be&nbsp;stricken and the remainder
                of&nbsp;this EULA shall continue in&nbsp;full force and effect.
            </span>
        </ParagraphContentWithSpace>
        <ParagraphContent>
            <span>ii.</span>
            <span>
                Neither party may assign any of&nbsp;its rights or&nbsp;obligations hereunder, whether by&nbsp;operation
                of&nbsp;law or&nbsp;otherwise, without the prior written consent of&nbsp;the other party, the provision
                of&nbsp;which shall not be unreasonably withheld. Notwithstanding the foregoing, TPE may assign this
                EULA in&nbsp;its entirety, without the consent of&nbsp;the other party, to&nbsp;its affiliates
                or&nbsp;in&nbsp;connection with a&nbsp;merger, acquisition, corporate reorganization, or&nbsp;sale
                of&nbsp;all or&nbsp;substantially all of&nbsp;its assets. Subject to&nbsp;the foregoing, this EULA shall
                bind and inure to&nbsp;the benefit of&nbsp;the parties, their respective successors and permitted
                assigns. Any attempted assignment in&nbsp;breach of&nbsp;this section shall be&nbsp;void.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>iii.</span>
            <span>
                The parties are independent contractors, and nothing in&nbsp;this EULA or&nbsp;any attachment hereto
                will create any partnership, joint venture, agency, franchise, sales representative, or&nbsp;employment
                relationship between the parties. There are no&nbsp;third party beneficiaries to&nbsp;this EULA.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>iv.</span>
            <span>
                The parties agree that any legal action arising under or&nbsp;relating to&nbsp;this EULA must
                be&nbsp;maintained in&nbsp;the state and federal courts located in&nbsp;the State of&nbsp;Massachusetts.
                The laws of&nbsp;the State of Massachusetts govern this EULA, excluding the body of&nbsp;laws concerning
                conflict of&nbsp;laws.
            </span>
        </ParagraphContent>
        <ParagraphContent>
            <span>v.</span>
            <span>
                Notices regarding this EULA shall be&nbsp;in&nbsp;writing and addressed to&nbsp;you at&nbsp;the address
                you provide to&nbsp;TPE or&nbsp;to&nbsp;the address listed in&nbsp;the appropriate TPE Customer
                Agreement. Notices regarding TPE in general may be&nbsp;given by&nbsp;electronic mail to&nbsp;your
                e-mail address on&nbsp;record with TPE.
            </span>
        </ParagraphContent>
        <p>
            <span>vi.</span>
            <span>
                This EULA, along with the TPE Customer Agreement, contains the entire agreement and understanding
                of&nbsp;the parties concerning the subject matter of&nbsp;this EULA. In&nbsp;the event of&nbsp;any
                inconsistency or&nbsp;conflict between this EULA and any TPE Customer Agreement, the terms of&nbsp;the
                TPE Customer Agreement shall prevail as&nbsp;it&nbsp;relates to&nbsp;your use of&nbsp;the Services.
            </span>
        </p>
    </>
);
