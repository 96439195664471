import { makePrefixAdder } from '../utils/type-utils';

const addPrefixTo = makePrefixAdder('WARNING_TO_USER');
const MAKE_READ = addPrefixTo('MAKE_READ');

const initialState = {
    warningWasShowed: false
};

export function reducer(state = initialState, action) {
    switch (action.type) {
        case MAKE_READ:
            return {
                ...state,
                warningWasShowed: true
            };
        default:
            return state;
    }
}

export const warningWasShowedSelector = state => state.warningToUser.warningWasShowed;

const makeWarningRead = () => {
    return { type: MAKE_READ };
};

export const actions = {
    makeWarningRead
};
