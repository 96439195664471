export const notEmptyPredicate = value => !!value;

export const checkStringLength = (checkedString, minLength, maxLength) =>
    notEmptyPredicate(checkedString) &&
    notEmptyPredicate(minLength) &&
    notEmptyPredicate(maxLength) &&
    typeof minLength === 'number' &&
    typeof maxLength === 'number' &&
    typeof checkedString === 'string' &&
    checkedString.length >= minLength &&
    checkedString.length <= maxLength;

const checkObjectValue = (checkedObject, checkedPath) =>
    !!checkedObject && typeof checkedObject === 'object' && checkedObject.hasOwnProperty(checkedPath);

export const checkObject = (checkedObject, checkedPath, defaultValue) =>
    checkObjectValue(checkedObject, checkedPath) ? checkedObject[checkedPath] : defaultValue;
