const TRACKED_EVENTS = {
    APPLY_FILTER: 'ApplyFilter',

    LOGOUT: 'Logout',

    SHOW_PROFILE_POPUP: 'ShowProfilePopup',

    SWITCH_LANG: 'SwitchLang',

    SWITCH_UNITS: 'SwitchUnits',

    SWITCH_SCHEME: 'SwitchScheme',

    SWITCH_DESIGN: 'SwitchDesignVersion',

    SWITCH_RATING_SYSTEM: 'SwitchRatingSystem',

    GO_TO_GLOSSARY: 'GoToGlossary',

    GO_TO_VIDEO_MANUALS: 'GoToVideoManuals',

    GO_TO_SUPPORT: 'GoToSupport',

    PLAYER_COMPARISON_METRICS: 'PlayerComparisonMetrics'
};
export default TRACKED_EVENTS;
