import { createSelector } from 'reselect';
import createStatsSelector from '../private/create-stats-selector';
import { denormalize } from 'normalizr';
import { gameSchema } from '../../../utils/schemas';

const gamesSelector = createSelector(
    state => state.statsFilter.fetchedGameIds,
    state => state.entities,
    state => state.statsFilter.initialized,
    state => state.statsFilter.processing,
    (gameIds, entities, initialized, processing) => {
        return {
            processing: processing || !initialized,
            data: denormalize(gameIds, [gameSchema], entities)
        };
    }
);

const orderedGamesSelector = createStatsSelector(gamesSelector, games =>
    [...(games ?? [])].sort((a, b) => (b.date > a.date ? 1 : b.date === a.date ? 0 : -1))
);

export default orderedGamesSelector;
