import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setImperialUnits, setMetricUnits } from 'redux/modules/user';
import { ratingSystems } from 'redux/modules/rating-system';
import { actions as themeActions, selector as themeSelector, themes } from 'redux/modules/theme';
import { trackEvent, trackedEvents } from 'telemetry/app-analytics';
import FilterSelect from 'components/filter-select/filter-select';
import FormattedMessage from 'components/formatted-message/index';

import styles from './profile.module.css';

const { switchToClassic, switchToDarcula } = themeActions;

const METRIC = 'METRIC';
const IMPERIAL = 'IMPERIAL';
const metricOptions = [IMPERIAL, METRIC];

const toggleClassName = (active, name) => (active === name ? styles.activeRadio : styles.radio);

const ToggleButton = ({ names, toggle, active }) => (
    <div className={styles.radioToggle}>
        <div className={toggleClassName(active, names[0])} onClick={() => toggle(names[0])}>
            <FormattedMessage id={`appContainer.toggleRadio.${names[0]}`} />
        </div>
        <div className={toggleClassName(active, names[1])} onClick={() => toggle(names[1])}>
            <FormattedMessage id={`appContainer.toggleRadio.${names[1]}`} />
        </div>
    </div>
);

export class MetricToggle extends Component {
    toggle = name => {
        trackEvent(trackedEvents.SWITCH_UNITS, {
            properties: {
                unitsType: name
            }
        });

        switch (name) {
            case METRIC:
                this.props.setMetricUnits();
                break;

            case IMPERIAL:
                this.props.setImperialUnits();
                break;

            default:
        }
    };

    render() {
        return <ToggleButton names={metricOptions} toggle={this.toggle} active={this.props.type} />;
    }
}

MetricToggle = connect(
    state => state.portalUnits,
    {
        setImperialUnits,
        setMetricUnits
    }
)(MetricToggle);

const themeOptions = [themes.CLASSIC, themes.DARCULA];

export class ThemeToggle extends Component {
    toggle = name => {
        trackEvent(trackedEvents.SWITCH_SCHEME, {
            properties: {
                scheme: name
            }
        });

        switch (name) {
            case themes.CLASSIC:
                this.props.switchToClassic();
                break;

            case themes.DARCULA:
                this.props.switchToDarcula();
                break;

            default:
        }
    };

    render() {
        return <ToggleButton names={themeOptions} toggle={this.toggle} active={this.props.theme} />;
    }
}

ThemeToggle = connect(
    state => ({ theme: themeSelector(state) }),
    {
        switchToClassic,
        switchToDarcula
    }
)(ThemeToggle);

export class RatingSystemSelector extends Component {
    toggle = (_, value) => {
        trackEvent(trackedEvents.SWITCH_RATING_SYSTEM, {
            properties: {
                scheme: value[0]
            }
        });

        this.props.switchRatingSystem(value[0]);
    };

    render() {
        return (
            <FilterSelect
                single
                style={{ marginLeft: 'auto' }}
                width="125px"
                valuesPresentation={system => (
                    <FormattedMessage id={`appContainer.profile.ratingSystem.${system[0]}`} />
                )}
                optionPresentation={system => <FormattedMessage id={`appContainer.profile.ratingSystem.${system}`} />}
                options={Object.keys(ratingSystems)}
                onChange={this.toggle}
                values={[this.props.ratingSystem]}
            />
        );
    }
}
