import { FAVORITE_EVENT } from '../../favorite-types';
import {
    processingState,
    mapperErrorMessage,
    createEpisodeIdWrapper,
    createMediaTimeWrapper
} from '../../episode-utils';
import { createSelector } from 'reselect';
import { getTimeForMediaTimeWrapperType } from '../helpers/episodes-selectors-helpers';

const SINGLE_RECOVERY = 'SINGLE_RECOVERY';
const SINGLE_GIVEAWAY = 'SINGLE_GIVEAWAY';
const SINGLE_GIVEAWAY_ON_ICE = 'SINGLE_GIVEAWAY_ON_ICE';
const SINGLE_TAKEAWAY = 'SINGLE_TAKEAWAY';
const SINGLE_TAKEAWAY_ON_ICE = 'SINGLE_TAKEAWAY_ON_ICE';
const SINGLE_FACE_OFF = 'SINGLE_FACE_OFF';
const SINGLE_PPA = 'SINGLE_PPA';
const SINGLE_PPS = 'SINGLE_PPS';

const types = {
    SINGLE_RECOVERY,
    SINGLE_GIVEAWAY,
    SINGLE_GIVEAWAY_ON_ICE,
    SINGLE_TAKEAWAY,
    SINGLE_TAKEAWAY_ON_ICE,
    SINGLE_FACE_OFF,
    SINGLE_PPA,
    SINGLE_PPS
};

const selector = state => (state.widgetsStats.eventsSingle ? state.widgetsStats.eventsSingle : processingState);

const gameRecoveriesSelector = createEpisodeIdWrapper(
    SINGLE_RECOVERY,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.recovery || e.reboundRecovery) })
    )
);

const gameGiveawaysSelector = createEpisodeIdWrapper(
    SINGLE_GIVEAWAY,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.giveaway) })
    )
);

const gameTakeawaysSelector = createEpisodeIdWrapper(
    SINGLE_TAKEAWAY,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.takeaway) })
    )
);

const gameFaceoffsSelector = createEpisodeIdWrapper(
    SINGLE_FACE_OFF,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.eventType === 'FACE_OFF') })
    )
);

const gamePPASelector = createEpisodeIdWrapper(
    SINGLE_PPA,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.assistType === 'POTENTIAL_ASSIST_1') })
    )
);

const gamePPSSelector = createEpisodeIdWrapper(
    SINGLE_PPS,
    createSelector(
        events => events.data,
        data => ({ data: data.filter(e => e.assistType === 'POTENTIAL_ASSIST_2') })
    )
);

function mapper(gameEvents, type) {
    if (gameEvents.processing) return processingState;
    switch (type) {
        case SINGLE_RECOVERY:
            return gameRecoveriesSelector(gameEvents);
        case SINGLE_GIVEAWAY:
            return gameGiveawaysSelector(gameEvents);
        case SINGLE_GIVEAWAY_ON_ICE:
            return gameGiveawaysSelector(gameEvents);
        case SINGLE_TAKEAWAY:
            return gameTakeawaysSelector(gameEvents);
        case SINGLE_TAKEAWAY_ON_ICE:
            return gameTakeawaysSelector(gameEvents);
        case SINGLE_FACE_OFF:
            return gameFaceoffsSelector(gameEvents);
        case SINGLE_PPA:
            return gamePPASelector(gameEvents);
        case SINGLE_PPS:
            return gamePPSSelector(gameEvents);
        default:
            throw mapperErrorMessage(type, 'Single Events');
    }
}

const typeToMediaTimeWrapper = {
    [SINGLE_RECOVERY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [SINGLE_GIVEAWAY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [SINGLE_GIVEAWAY_ON_ICE]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [SINGLE_TAKEAWAY]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [SINGLE_TAKEAWAY_ON_ICE]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [SINGLE_FACE_OFF]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [SINGLE_PPA]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType),
    [SINGLE_PPS]: createMediaTimeWrapper(getTimeForMediaTimeWrapperType)
};

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const favoriteType = FAVORITE_EVENT;

export const singleEvent = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
