import { SCOUT } from 'containers/product-initializer/products';
import { selector as getActiveProduct } from 'containers/product-initializer/product-module';
import {
    BETA_FEATURES_ENABLED,
    BRANDED_BY_MOCAP,
    BRANDED_BY_HOCKEYTECH,
    hasFeature,
    SHIFTS_ONLY,
    SINGLE_TEAM_VIEW,
    TOGGLE_COLOR_SCHEME,
    UNTRUSTED,
    GOALTENDER_ACCOUNT,
    VIDEO_PLAYER_HIDDEN,
    SCOUT_VIDEO_PLAYER_HIDDEN,
    SCOUT_METRICS_EXTENDED_MODE,
    NAHL_PLAYER_ACCOUNT,
    NAHL_LEAGUE_ACCOUNT,
    SCOUT_LITE,
    PLAYERS_VIEWER_ACCOUNT
} from '../../utils/user-features';

/**
 * @return {Number} user features mask
 */
export const getFeatureMask = state => {
    const userProfile = (state.user && state.user.profile) || {};
    const { featuresMask = 0 } = userProfile;
    return featuresMask;
};

/**
 * @return {Boolean} true if user has `TOGGLE_COLOR_SCHEME` feature
 */
export const isColorSchemeToggled = state => hasFeature(getFeatureMask(state), TOGGLE_COLOR_SCHEME);

/**
 * @return {Boolean} true if user has `UNTRUSTED` feature
 */
export const isUntrusted = state => hasFeature(getFeatureMask(state), UNTRUSTED);

/**
 * @return {Boolean} true if user has `SINGLE_TEAM_VIEW` feature
 */
export const isSingleTeamView = state => {
    const singleTeamFeatureIsEnabled = hasFeature(getFeatureMask(state), SINGLE_TEAM_VIEW);
    const currentProductIsNotScout = getActiveProduct(state) !== SCOUT;
    const pageIsReport = window.location.pathname === '/report';
    return singleTeamFeatureIsEnabled && currentProductIsNotScout && !pageIsReport;
};

/**
 * @return {Boolean} true if user has `BETA_FEATURES_ENABLED` feature
 */
export const isBetaFeaturesEnabled = state => hasFeature(getFeatureMask(state), BETA_FEATURES_ENABLED);

/**
 * @return {Boolean} true if user has `SHIFTS_ACCOUNT` feature
 */
export const isShiftsOnly = state => hasFeature(getFeatureMask(state), SHIFTS_ONLY);

/**
 * @return {Boolean} true if user has `GOALTENDER_ACCOUNT` feature
 */
export const isGoalieOnly = state => hasFeature(getFeatureMask(state), GOALTENDER_ACCOUNT);

/**
 * @return {Boolean} true if user has `VIDEO_PLAYER_HIDDEN` feature
 */
export const isVideoPlayerHidden = state => hasFeature(getFeatureMask(state), VIDEO_PLAYER_HIDDEN);

/**
 * @return {Boolean} true if user has `BRANDED_BY_MOCAP` feature
 */
export const isBrandedByMocap = state => hasFeature(getFeatureMask(state), BRANDED_BY_MOCAP);

/**
 * @return {Boolean} true if user has `BRANDED_BY_HOCKEYTECH` feature
 */
export const isBrandedByHockeyTech = state => hasFeature(getFeatureMask(state), BRANDED_BY_HOCKEYTECH);

/**
 * @return {Boolean} true if user has `SCOUT_VIDEO_PLAYER_HIDDEN` feature
 */
export const isScoutVideoPlayerHidden = state => hasFeature(getFeatureMask(state), SCOUT_VIDEO_PLAYER_HIDDEN);

/**
 * @return {Boolean} true if user has `SCOUT_METRICS_EXTENDED_MODE` feature
 */
export const isScoutExtended = state => hasFeature(getFeatureMask(state), SCOUT_METRICS_EXTENDED_MODE);

/**
 * @return {Boolean} true if user has `SCOUT_METRICS_EXTENDED_MODE` feature
 */
export const isNahlPlayerAccount = state => hasFeature(getFeatureMask(state), NAHL_PLAYER_ACCOUNT);

/**
 * @return {Boolean} true if user has `SCOUT_METRICS_EXTENDED_MODE` feature
 */
export const isNahlLeagueAccount = state => hasFeature(getFeatureMask(state), NAHL_LEAGUE_ACCOUNT);

/**
 * @return {Boolean} true if user has `SCOUT_LITE` feature
 */
export const isScoutLite = state => hasFeature(getFeatureMask(state), SCOUT_LITE);

/**
 * @return {Boolean} true if user has `PROSPECT_MODE` feature
 */

export const isProspectMode = state => hasFeature(getFeatureMask(state), PLAYERS_VIEWER_ACCOUNT);

export const userAccountIdSelector = state =>
    (!!state && !!state.user && !!state.user.account && state.user.account.id) || null;

export const userHasFeatureMask = state =>
    !(state?.user?.profile?.featuresMask === 0 || state?.user?.profile?.featuresMask === null);

export const userHavePlayerIdAndFeatureMask = state =>
    !(state?.user?.profile?.featuresMask === 0 || state?.user?.profile?.featuresMask === null) &&
    !!state?.user?.profile?.playerId;
