export const STARTING = 'STARTING';

export const PUCK_STRIKE = 'PUCK_STRIKE';

export const SHOT = 'SHOT';

export const OFFENSIVE_HS = 'OFFENSIVE_HS';

export const DEFENSIVE_HS = 'DEFENSIVE_HS';

export const COMPETITIVENESS = 'COMPETITIVENESS';

export const CHARACTER = 'CHARACTER';

export const PLAYER_TYPE = 'PLAYER_TYPE';

export const TALENT = 'TALENT';

export const NHL_POTENTIAL = 'NHL_POTENTIAL';

export const NHL_PROJECTION = 'NHL_PROJECTION';

export const PERFORMANCE = 'PERFORMANCE';
