import { Component, Children } from 'react';
import createObservableSubject from 'utils/helpers/create-observable-subject';

export const observableMount = createObservableSubject();

export const MOUNTED = 'mounted';
export const UNMOUNTED = 'unmounted';

class MountNotifier extends Component {
    componentDidMount() {
        observableMount.notify(MOUNTED);
    }

    componentWillUnmount() {
        observableMount.notify(UNMOUNTED);
    }

    render() {
        return Children.only(this.props.children);
    }
}

export default MountNotifier;
