/* eslint-disable eqeqeq */
import { makeBundle, makePrefixAdder } from '../../../redux/utils/type-utils';
import { EPISODE_TYPES } from '../episodes/selectors/index';
import requestBuilder from '../../../redux/utils/request-builder';
import * as endpoints from '../../../utils/api/endpoints';

const { GAME_SCORE_ATTEMPTS } = EPISODE_TYPES;

const addPrefixTo = makePrefixAdder('iceplayer');

const POP_UP_ICEPLAYER = addPrefixTo('POP_UP_ICEPLAYER');
const POP_DOWN_ICEPLAYER = addPrefixTo('POP_DOWN_ICEPLAYER');
const GET_FAVORITES = addPrefixTo('GET_FAVORITES_REQUEST');
const POST_FAVORITES = addPrefixTo('POST_FAVORITES');
const DELETE_FAVORITES = addPrefixTo('DELETE_FAVORITES');
const SWITCH_TO_DEFAULT = addPrefixTo('SWITCH_TO_DEFAULT');
const SWITCH_TO_PAYLISTS = addPrefixTo('SWITCH_TO_PAYLISTS');
const GET_SCOUT_FAVORITES = addPrefixTo('GET_SCOUT_FAVORITES');

const GET_FAVORITES_BUNDLE = makeBundle(GET_FAVORITES);
const POST_FAVORITES_BUNDLE = makeBundle(POST_FAVORITES);
const DELETE_FAVORITES_BUNDLE = makeBundle(DELETE_FAVORITES);
const GET_SCOUT_FAVORITES_BUNDLE = makeBundle(GET_SCOUT_FAVORITES);

function popUpIcePlayer(eventId, group) {
    //only work for shots now
    return { type: POP_UP_ICEPLAYER, eventId, group };
}

function popDownIcePlayer() {
    return { type: POP_DOWN_ICEPLAYER };
}

function getFavorites(params) {
    return requestBuilder(GET_FAVORITES_BUNDLE).get(endpoints.getFavorites, { params });
}

function getScoutFavorites() {
    return requestBuilder(GET_SCOUT_FAVORITES_BUNDLE).get(endpoints.getScoutFavorites);
}

function postFavorites(entity) {
    return requestBuilder(POST_FAVORITES_BUNDLE)
        .withOptions({ entity })
        .post(endpoints.postFavorites, entity);
}

function deleteFavorites(entity) {
    return requestBuilder(DELETE_FAVORITES_BUNDLE)
        .withOptions({ entity })
        .del(endpoints.deleteFavorites(entity.id));
}

function switchToDefault() {
    return { type: SWITCH_TO_DEFAULT };
}

function switchToPlaylists(playlists) {
    return { type: SWITCH_TO_PAYLISTS, payload: playlists };
}

export const actions = {
    popUpIcePlayer,
    popDownIcePlayer,
    getFavorites,
    postFavorites,
    deleteFavorites,
    switchToDefault,
    switchToPlaylists,
    getScoutFavorites
};

const defaultState = {
    popup: false,
    group: GAME_SCORE_ATTEMPTS,
    eventId: null,
    playlistsMode: false,
    favorites: {
        processing: true,
        data: []
    }
};

const Module = (state = defaultState, action) => {
    switch (action.type) {
        case POP_UP_ICEPLAYER:
            return {
                ...state,
                popup: true,
                group: action.group,
                eventId: action.eventId
            };
        case POP_DOWN_ICEPLAYER:
            return {
                ...state,
                popup: false,
                group: GAME_SCORE_ATTEMPTS,
                eventId: null
            };
        case GET_FAVORITES_BUNDLE.REQUEST:
        case GET_SCOUT_FAVORITES_BUNDLE.REQUEST:
            return {
                ...state,
                favorites: {
                    processing: true
                }
            };
        case GET_FAVORITES_BUNDLE.SUCCESS:
        case GET_SCOUT_FAVORITES_BUNDLE.SUCCESS:
            return {
                ...state,
                favorites: {
                    data: action.response.data
                }
            };
        case DELETE_FAVORITES_BUNDLE.REQUEST:
            return {
                ...state,
                favorites: {
                    data: state.favorites.data.filter(d => d.id !== action.entity.id)
                }
            };
        case DELETE_FAVORITES_BUNDLE.FAILURE:
        case DELETE_FAVORITES_BUNDLE.CANCELLED:
            return {
                ...state,
                favorites: {
                    data: [...state.favorites.data, action.entity]
                }
            };
        case POST_FAVORITES_BUNDLE.REQUEST:
            return {
                ...state,
                favorites: {
                    data: [...state.favorites.data, action.entity]
                }
            };
        case POST_FAVORITES_BUNDLE.SUCCESS: {
            const item = action.response.data;
            return {
                ...state,
                favorites: {
                    data: state.favorites.data.map(f => {
                        return f.itemType === item.itemType && f.itemId === item.itemId ? item : f;
                    })
                }
            };
        }
        case POST_FAVORITES_BUNDLE.FAILURE:
        case POST_FAVORITES_BUNDLE.CANCELLED:
            return {
                ...state,
                favorites: {
                    data: state.favorites.data.filter(f => {
                        return !(f.itemType === action.entity.itemType && f.itemId === action.entity.itemId);
                    })
                }
            };
        case SWITCH_TO_DEFAULT:
            return {
                ...state,
                playlistsMode: false
            };
        case SWITCH_TO_PAYLISTS:
            return {
                ...state,
                playlistsMode: true,
                favorites: action.payload
            };
        default:
            return state;
    }
};

export default Module;
