import React from 'react';
import { useDropzone } from 'react-dropzone';

const DropZone = ({ className, activeClassName, disabledClassName, style, children, ...restProps }) => {
    const { getRootProps, getInputProps } = useDropzone(restProps);

    return (
        <div {...getRootProps({ className, style, activeClassName, disabledClassName })}>
            <input {...getInputProps()} />
            {children}
        </div>
    );
};

export default DropZone;
