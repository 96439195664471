import React, { useMemo } from 'react';
import useQuery from '../../../../../utils/hooks/useQuery';
import * as endpoints from '../../../../../utils/api/endpoints';
import Loading from '../../../../../components/loading/loading';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { ratingSystems, selector as ratingSystemSelector } from 'redux/modules/rating-system';
import { LogoComponent } from 'components/logo/logo-component.js';
import FormattedMessage from 'components/formatted-message/index';
import { PERFORMANCE } from './category-types';
import { putFirst } from '../../../../../utils/arrays-utils';
import usePrint from '../../../../../utils/hooks/usePrint';

export const scoutEvaluationTemplateIndex = 'evaluation-template';

const mapScoresBySystem = {
    [ratingSystems.LETTER]: 'E - A',
    [ratingSystems.FIVE_POINT]: '1-5',
    [ratingSystems.TEN_POINT]: '1-10',
    [ratingSystems.HUNDRED_POINT]: '1-100'
};

const categoriesQuery = {
    method: 'get',
    url: endpoints.scoutUserCategories
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    color: var(--uiHeaderColor);
    font-family: Roboto, serif;
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    padding: 0 0 15px 0;

    border-width: 2px;
    border-style: none none solid;
    border-color: var(--uiHeaderColor);

    font-size: 2em;
    text-transform: uppercase;
    justify-items: center;
`;

const Layout = styled.div`
    display: grid;
    grid-template-columns: 300px 100px 100px 20px 200px 20px 200px 100px 200px 20px 100px;
    grid-template-rows: 30px 30px 30px 30px auto;
    align-items: center;
    margin: 15px auto;

    justify-items: center;
`;

const Title = styled.div`
    font-weight: bold;
    text-transform: uppercase;
`;

const EmptyCell = styled.div`
    width: 100%;
    height: 100%;

    border: 2px var(--uiHeaderColor) solid;

    font-weight: bold;

    font-style: italic;

    text-align: center;
    text-transform: uppercase;
    & + & {
        border-left-width: 0;
    }
`;

const Line = styled.div`
    display: grid;
    grid-column: 1/12;
    grid-template-columns: 300px 100px 100px 20px 200px 20px 200px 100px 200px 20px 100px;

    & + & ${EmptyCell} {
        border-top-width: 0;
    }
`;

const ScoutEvaluationTemplate = ({ ratingSystem }) => {
    const { data: categories, processing: categoriesProcessing } = useQuery(categoriesQuery);

    usePrint(categoriesProcessing);

    const checkedCategoryType = useMemo(() => {
        return categories && putFirst(categories.filter(c => c.checked), c => c.freezed).map(c => c.categoryType);
    }, [categories]);

    if (categoriesProcessing) return <Loading spinner />;

    return (
        <Wrapper>
            <Header>
                <FormattedMessage style={{ justifySelf: 'start' }} id="scout.playerPage.evaluation" />
                <LogoComponent height={70} />
            </Header>
            <Layout>
                <>
                    <Title>
                        <FormattedMessage id="scout.playerPage.evaluation.playerName" />
                    </Title>
                    <Title>
                        <FormattedMessage id="scout.playerPage.evaluation.position" />
                    </Title>
                    <Title>
                        <FormattedMessage id="scout.playerPage.evaluation.jersey" />
                    </Title>
                    <div />
                    <Title>
                        <FormattedMessage id="scout.playerPage.evaluation.gameDateTime" />
                    </Title>
                    <div />
                    <Title>
                        <FormattedMessage id="scout.playerPage.evaluation.homeTeam" />
                    </Title>
                    <Title>
                        <FormattedMessage id="scout.playerPage.evaluation.gameScore" />
                    </Title>
                    <Title>
                        <FormattedMessage id="scout.playerPage.evaluation.awayTeam" />
                    </Title>
                    <div />
                    <Title>
                        <FormattedMessage id="scout.playerPage.evaluation.league" />
                    </Title>
                </>

                <>
                    <EmptyCell />
                    <EmptyCell />
                    <EmptyCell />
                    <div />
                    <EmptyCell>__/__/20__&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__:__</EmptyCell>
                    <div />
                    <EmptyCell />
                    <EmptyCell>__:__</EmptyCell>
                    <EmptyCell />
                    <div />
                    <EmptyCell />
                </>

                <div style={{ gridColumn: '1/12' }} />

                <>
                    <div />
                    <Title style={{ gridColumn: '2/4' }}>
                        <FormattedMessage id="scout.playerPage.evaluation.score" /> ({mapScoresBySystem[ratingSystem]})
                    </Title>
                    <div />
                    <Title style={{ gridColumn: '5/12' }}>
                        <FormattedMessage id="scout.playerPage.evaluation.notes" />
                    </Title>
                </>

                {checkedCategoryType.map(type => (
                    <Line>
                        <EmptyCell style={{ height: '50px', lineHeight: '50px' }}>
                            {type === PERFORMANCE ? (
                                <FormattedMessage id={`scout.evaluation.categoryTypes.PERFORMANCE`} />
                            ) : (
                                type
                            )}
                        </EmptyCell>
                        <EmptyCell style={{ gridColumn: '2/4' }} />
                        <div />
                        <EmptyCell style={{ gridColumn: '5/12' }} />
                    </Line>
                ))}
            </Layout>
        </Wrapper>
    );
};

export default connect(state => ({ ratingSystem: ratingSystemSelector(state) }))(ScoutEvaluationTemplate);

// Отступ снизу, башка, локализация
