import { makePrefixAdder } from '../utils/type-utils';
import { getRatingSystem, setRatingSystem } from '../../utils/helpers/storages-utils';

export const ratingSystems = {
    LETTER: 'LETTER',
    FIVE_POINT: 'FIVE_POINT',
    TEN_POINT: 'TEN_POINT',
    HUNDRED_POINT: 'HUNDRED_POINT'
};

export const ratingSystemToMax = {
    LETTER: 5,
    FIVE_POINT: 5,
    TEN_POINT: 10,
    HUNDRED_POINT: 100
};

const addPrefixTo = makePrefixAdder('RATING_SYSTEM');
const SWITCH_RATING_SYSTEM = addPrefixTo('SWITCH_RATING_SYSTEM');

export const switchRatingSystem = ratingSystem => {
    return { type: SWITCH_RATING_SYSTEM, payload: ratingSystem };
};

const defaultState = {
    ratingSystem: getRatingSystem() || ratingSystems.HUNDRED_POINT
};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case SWITCH_RATING_SYSTEM:
            setRatingSystem(action.payload);
            return {
                ratingSystem: action.payload
            };
        default:
            return state;
    }
}

export const selector = state => state.ratingSystem.ratingSystem;
