import { createMediaTimeWrapper, mapperErrorMessage, processingState } from '../../episode-utils';
import { createSelector } from 'reselect';

const POWER_PLAY_BY_TEAM_STATUS = 'POWER_PLAY_BY_TEAM_STATUS';
const PENALTY_KILL_BY_TEAM_STATUS = 'PENALTY_KILL_BY_TEAM_STATUS';

const types = { POWER_PLAY_BY_TEAM_STATUS, PENALTY_KILL_BY_TEAM_STATUS };

const isPp = ({ homePlayersNumber, awayPlayersNumber }, teamStatus) => {
    if (teamStatus === 'HOME') {
        return homePlayersNumber > awayPlayersNumber;
    } else if (teamStatus === 'AWAY') {
        return awayPlayersNumber > homePlayersNumber;
    } else {
        return false;
    }
};

const isPk = ({ homePlayersNumber, awayPlayersNumber }, teamStatus) => {
    if (teamStatus === 'AWAY') {
        return homePlayersNumber > awayPlayersNumber;
    } else if (teamStatus === 'HOME') {
        return awayPlayersNumber > homePlayersNumber;
    } else {
        return false;
    }
};

const computeInterval = (previousStrengthChange, strengthChange, periodsLength, teamStatus) => {
    const id = `${previousStrengthChange.gameId}-${previousStrengthChange.period}-${previousStrengthChange.time}`;
    if (!strengthChange || previousStrengthChange.period !== strengthChange.period) {
        return {
            ...previousStrengthChange,
            id,
            startTime: previousStrengthChange.time,
            endTime: periodsLength[previousStrengthChange.gameId],
            pk: isPk(previousStrengthChange, teamStatus),
            pp: isPp(previousStrengthChange, teamStatus)
        };
    } else {
        return {
            ...previousStrengthChange,
            id,
            startTime: previousStrengthChange.time,
            endTime: strengthChange.time,
            pk: isPk(previousStrengthChange, teamStatus),
            pp: isPp(previousStrengthChange, teamStatus)
        };
    }
};

const selector = createSelector(
    state => state.widgetsStats.gameStrengthChangeTimes || processingState,
    state => state.widgetsStats.periods,
    (s, { teamStatus }) => teamStatus,
    (strengthChanges, periods, teamStatus) => {
        if (strengthChanges.processing || periods.processing) return processingState;

        return {
            data: strengthChanges.data.map((strengthChangeItem, index, strengthChangeItems) => {
                const nextStrengthChange =
                    strengthChangeItems.length > index + 1 ? strengthChangeItems[index + 1] : null;
                const { gameId } = strengthChangeItem;
                const periodsLength = periods.data[gameId];
                return computeInterval(strengthChangeItem, nextStrengthChange, periodsLength, teamStatus);
            })
        };
    }
);

const powerPlayMapper = createSelector(
    sc => sc.data,
    strengthChanges => ({
        data: strengthChanges.filter(s => s.pp)
    })
);

const penaltyKillMapper = createSelector(
    sc => sc.data,
    strengthChanges => ({
        data: strengthChanges.filter(s => s.pk)
    })
);

function mapper(strength, type) {
    if (strength.processing) return processingState;
    switch (type) {
        case POWER_PLAY_BY_TEAM_STATUS:
            return powerPlayMapper(strength);
        case PENALTY_KILL_BY_TEAM_STATUS:
            return penaltyKillMapper(strength);
        default:
    }
    throw mapperErrorMessage(type, 'Special teams by team status');
}

const getTime = ({ startTime, endTime }) => ({ startTime, endTime, time: startTime, hasPadding: false });

const mediaTimeWrapper = createMediaTimeWrapper(getTime);

const favoriteType = null;

function getTimedGroup(group, periods) {
    return mediaTimeWrapper(group, periods);
}

const specialTeamsByTeamStatus = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};

export default specialTeamsByTeamStatus;
