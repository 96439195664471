import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { gameSchema } from '../../../utils/schemas';
import { createSkipMarkedArgsSelector, markSelector } from '../../../utils/selectors';
import { selector as productSelector } from '../../../containers/product-initializer/product-module';

// todo wrong logic, we should never get random gameId from filter's state
// todo for single game page use `array-utils#unboxSingleValueArray`
export const activeGameSelector = createSkipMarkedArgsSelector(
    state => state.statsFilter.gameIds[0],
    markSelector(state => state.entities),
    (gameId, entities) => denormalize(gameId, gameSchema, entities)
);

const processingState = { processing: true };

const fatActiveGame = createSelector(
    activeGameSelector,
    state => state.entities.gamePlayers,
    state => state.entities.playerProfiles,
    state => state.widgetsStats.periods,
    state => productSelector(state),
    (game, gamePlayers = null, playerProfiles = null, periods, product) => ({
        processing: false,
        game,
        gamePlayers,
        playerProfiles,
        periods,
        product
    })
);

// todo deprecated, use `games-selector#statFilterGames` instead
export const selectedGamesSelector = createSkipMarkedArgsSelector(
    state => state.statsFilter.gameIds,
    markSelector(state => state.entities),
    (gameIds, entities) => denormalize(gameIds, [gameSchema], entities)
);

// todo wrong logic, we should never get random gameId from filter's state
const ActiveGame = (state, props) =>
    !state.statsFilter.initialized || state.statsFilter.processing
        ? { ...processingState, product: productSelector(state) }
        : fatActiveGame(state, props);
export default ActiveGame;

export const gamesInfoCombineSelector = createSelector(
    state => state.entities.games,
    state => state.entities.teams,
    (games, teams) => {
        const output = {};

        if (!games || !teams) {
            return output;
        }

        for (const gameId of Object.keys(games)) {
            const gameData = games[gameId];
            const { homeTeam, awayTeam } = gameData;

            output[gameId] = {
                ...gameData,
                homeTeam: !!teams[homeTeam] ? { ...teams[homeTeam] } : homeTeam,
                awayTeam: !!teams[awayTeam] ? { ...teams[awayTeam] } : awayTeam
            };
        }

        return output;
    }
);
