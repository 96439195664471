import { makeBundle, makePrefixAdder } from '../../redux/utils/type-utils';

const addPrefixTo = makePrefixAdder('widgetsStatsBundles');

export const leagues = makeBundle(addPrefixTo('LEAGUES'));

export const shots = makeBundle(addPrefixTo('SHOTS'));

export const info = makeBundle(addPrefixTo('INFO'));

export const gamePlayers = makeBundle(addPrefixTo('GAME_PLAYERS'));

export const playerProfiles = makeBundle(addPrefixTo('PLAYER_PROFILES'));

export const possessionTeams = makeBundle(addPrefixTo('POSESSION_TEAMS'));

export const skatersCompare = makeBundle(addPrefixTo('SKATERS_COMPARE'));

export const periods = makeBundle(addPrefixTo('PERIODS'));

export const linesXG = makeBundle(addPrefixTo('LINES_XG'));

export const fullLinesXG = makeBundle(addPrefixTo('FULL_LINES_XG'));

export const skatingTeam = makeBundle(addPrefixTo('SKATING_TEAM'));

export const skatingPlayersList = makeBundle(addPrefixTo('SKATING_PLAYERS_LIST'));

export const keeperToi = makeBundle(addPrefixTo('KEEPER_TOI'));

export const leagueStats = makeBundle(addPrefixTo('LEAGUE_STATS'));

export const filteredShots = makeBundle(addPrefixTo('FILTERED_SHOTS'));

export const homeTeamLeft = makeBundle(addPrefixTo('HOME_TEAM_LEFT'));

export const entries = makeBundle(addPrefixTo('ENTRIES'));

export const entriesSingle = makeBundle(addPrefixTo('ENTRIES_SINGLE'));

export const eventsSingle = makeBundle(addPrefixTo('EVENTS_SINGLE'));

export const events = makeBundle(addPrefixTo('EVENTS'));

export const skatingPlayers = makeBundle(addPrefixTo('SKATING_PLAYERS'));

export const skatingPlayersSingle = makeBundle(addPrefixTo('SKATING_PLAYERS_SINGLE'));

export const possessionPlayers = makeBundle(addPrefixTo('POSESSION_PLAYERS'));

export const possessionPlayersSingle = makeBundle(addPrefixTo('POSESSION_PLAYERS_SINGLE'));

export const gameScore = makeBundle(addPrefixTo('GAME_SCORE'));

export const gameScoreSingle = makeBundle(addPrefixTo('GAME_SCORE_SINGLE'));

export const passes = makeBundle(addPrefixTo('PASSES'));

export const passesSingle = makeBundle(addPrefixTo('PASSES_SINGLE'));

export const skatersShots = makeBundle(addPrefixTo('SKATERS_SHOTS'));

export const skatersShotsSingle = makeBundle(addPrefixTo('SKATERS_SHOTS_SINGLE'));

export const shiftsStatsSingle = makeBundle(addPrefixTo('SHIFTS_STATS_SINGLE'));

export const gameStrengthChangeTimes = makeBundle(addPrefixTo('GAME_STRENGTH_CHANGE_TIMES'));

export const gamesPps = makeBundle(addPrefixTo('GAMES_PPS'));

export const gamesPks = makeBundle(addPrefixTo('GAMES_PKS'));

export const ppStats = makeBundle(addPrefixTo('PP_STATS'));

export const pkStats = makeBundle(addPrefixTo('PK_STATS'));

export const storage = makeBundle(addPrefixTo('STORAGE'));

export const seasons = makeBundle(addPrefixTo('SEASONS'));

export const avgRestTime = makeBundle(addPrefixTo('AVG_REST_TIME'));

export const gameScoreStats = makeBundle(addPrefixTo('GAME_SCORE_STATS'));

export const playerStats = makeBundle(addPrefixTo('PLAYER_STATS'));

export const scoutPlayerStats = makeBundle(addPrefixTo('SCOUT_PLAYER_STATS'));

export const scoutLeagueStats = makeBundle(addPrefixTo('SCOUT_LEAGUE_STATS'));

export const playerFaceoffs = makeBundle(addPrefixTo('PLAYER_FACEOFFS'));

export const playerRecoveries = makeBundle(addPrefixTo('PLAYER_RECOVERIES'));

export const playerShots = makeBundle(addPrefixTo('PLAYER_SHOTS'));

export const playerStatsQuantiles = makeBundle(addPrefixTo('PLAYER_STATS_QUANTILES'));

export const playerStatsQuantilesExtended = makeBundle(addPrefixTo('PLAYER_STATS_QUANTILES_EXTENDED'));

export const leagueAvgQuantiles = makeBundle(addPrefixTo('LEAGUE_AVG_QUANTILES'));

export const leagueAvgQuantilesExtended = makeBundle(addPrefixTo('LEAGUE_AVG_QUANTILES_EXTENDED'));

export const individualShiftStats = makeBundle(addPrefixTo('INDIVIDUAL_SHIFTS_STATS'));

export const videoTimecodes = makeBundle(addPrefixTo('VIDEO_TIMECODES'));
