import React from 'react';
import {
    TemporaryUnavailablePageWrapper,
    TemporaryUnavailableText,
    TemporaryUnavailableTitle
} from './temporary-unavailable-page-styled-components';
import FormattedMessage from '../formatted-message';

const TemporaryUnavailablePage = ({ unavailableMessage }) => {
    /*eslint-disable*/
    if (unavailableMessage) {
        console.error(unavailableMessage);
    } else {
        console.error('Error detected.');
    }
    /*eslint-enable*/

    return (
        <TemporaryUnavailablePageWrapper>
            <TemporaryUnavailableTitle>
                <FormattedMessage id={'unavailablePage.title'} />
            </TemporaryUnavailableTitle>
            <svg width="30vh" height="30vh" viewBox="0 0 62 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M61 38C61 54.5685 47.5685 68 31 68C14.4315 68 1 54.5685 1 38C1 21.4315 14.4315 8 31 8"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M46 38L23.5 50.9904L23.5 25.0096L46 38Z"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M32.7071 8.70711C33.0976 8.31658 33.0976 7.68342 32.7071 7.29289L26.3431 0.928932C25.9526 0.538408 25.3195 0.538408 24.9289 0.928932C24.5384 1.31946 24.5384 1.95262 24.9289 2.34315L30.5858 8L24.9289 13.6569C24.5384 14.0474 24.5384 14.6805 24.9289 15.0711C25.3195 15.4616 25.9526 15.4616 26.3431 15.0711L32.7071 8.70711ZM31 9H32V7H31V9Z"
                    fill="#2967B0"
                />
                <line
                    x1="31"
                    y1="13"
                    x2="31"
                    y2="17"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line
                    x1="56"
                    y1="38"
                    x2="52"
                    y2="38"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line
                    x1="44.4142"
                    y1="53"
                    x2="47.2426"
                    y2="55.8284"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line
                    x1="13.4142"
                    y1="20"
                    x2="16.2426"
                    y2="22.8284"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line
                    x1="14"
                    y1="56.2426"
                    x2="16.8284"
                    y2="53.4142"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line
                    x1="45"
                    y1="22.8284"
                    x2="47.8284"
                    y2="20"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line
                    x1="10"
                    y1="39"
                    x2="6"
                    y2="39"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line
                    x1="31"
                    y1="59"
                    x2="31"
                    y2="63"
                    stroke="#2967B0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <TemporaryUnavailableTitle>503</TemporaryUnavailableTitle>
            <TemporaryUnavailableText>
                <FormattedMessage id={'unavailablePage.text'} />
            </TemporaryUnavailableText>
        </TemporaryUnavailablePageWrapper>
    );
};

export default TemporaryUnavailablePage;
