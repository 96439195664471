import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { AUTH_TOKEN_NAME, getAuthKey } from '../api/auth-utils';

export const httpMethods = {
    get: 'get',
    post: 'post',
    put: 'put',
    delete: 'delete'
};

export const useFetchData = ({
    method,
    url,
    params,
    payload = null,
    responseHandler,
    initialCondition,
    processingSetter,
    repeat,
    onRepeat,
    onRequestError,
    fullResp,
    isDisabled
}) => {
    const [data, setData] = useState(null);
    const [isInit, setInit] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);

    const controller = new AbortController();

    const handleRepeat = useCallback(() => {
        controller.abort();

        if (isDisabled) {
            return;
        }

        setProcessing(false);
        setInit(true);
        setData(null);
        setError(false);
        onRepeat?.();
    }, [onRepeat, isDisabled]);

    const handleCancel = useCallback(() => {
        if (!controller) {
            return;
        }

        controller.abort();
    }, []);

    useEffect(() => {
        if (!repeat || isDisabled) {
            return;
        }

        handleRepeat?.();
    }, [repeat, isDisabled]);

    //On mount effect
    useEffect(
        () => {
            if (initialCondition !== undefined || isDisabled) {
                return;
            }

            setInit(true);
        },
        /*eslint-disable*/ [initialCondition, isDisabled] /*eslint-enable*/
    );

    //Processing status for request
    useEffect(
        () => {
            if (typeof processing !== 'boolean' || !processingSetter || typeof processingSetter !== 'function') {
                return;
            }

            processingSetter(processing);
        },
        /*eslint-disable*/ [processing] /*eslint-enable*/
    );

    useEffect(
        () => {
            if (processing || isDisabled) {
                return;
            }

            if (initialCondition) {
                setData(null);
                setInit(true);
            }
        },
        /*eslint-disable*/ [initialCondition, isDisabled] /*eslint-enable*/
    );

    useEffect(
        () => {
            if (error && !processing) {
                //eslint-disable-next-line
                console.log('Error on request: ', url, ' ; at time : ', new Date());
            }
        },
        /*eslint-disable*/ [error] /*eslint-enable*/
    );

    useEffect(
        () => {
            if (!isInit || data !== null || isDisabled || !setData) {
                return;
            }

            setError(false);

            if (!url || url.indexOf('null') >= 0 || url.indexOf('undefined') >= 0) {
                /*eslint-disable*/
                console.error('URL contain errors: ', url);
                /*eslint-enable*/
                setInit(false);
                setError(true);
                return;
            }

            setInit(false);
            setProcessing(true);

            axios({
                headers: { 'X-Requested-With': 'XMLHttpRequest', [AUTH_TOKEN_NAME]: getAuthKey() },
                method: method,
                url: url,
                params: params,
                data: payload,
                signal: controller.signal
            })
                .then(response => {
                    setProcessing(false);

                    if (!response?.data) {
                        //eslint-disable-next-line
                        console.warn('Response data is empty.');
                        return;
                    }

                    const resp =
                        (!!response.data.content && (fullResp ? response.data : response.data.content)) ||
                        response.data;

                    if (resp) {
                        responseHandler?.(resp);
                        setData(resp);
                    }
                })
                .catch(err => {
                    //eslint-disable-next-line
                    console.error('error', err);
                    setProcessing(false);
                    setError(true);

                    !!onRequestError && typeof onRequestError === 'function' && onRequestError();
                });
        },
        /*eslint-disable*/ [isInit, isDisabled, payload, params, url] /*eslint-enable*/
    );

    //Unmount
    useEffect(() => {
        return () => {
            setInit(false);
            setProcessing(false);
            setData(null);
            setError(false);
            processingSetter?.(false);
            // Abort the request before component unmounts
            controller.abort();
        };
    }, []);

    return { processing: isInit || processing, data, error, handleCancel, handleRepeat };
};
