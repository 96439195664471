import { makePrefixAdder } from '../utils/type-utils';
import omit from 'lodash/omit';

const addPrefixTo = makePrefixAdder('TABLE_BUILDER_PRESETS');
const ADD_PRESET = addPrefixTo('ADD_PRESET');
const DELETE_PRESET = addPrefixTo('DELETE_PRESET');
const SWITCH_PRESET = addPrefixTo('SWITCH_PRESET');

const addPreset = (pageName, presetName, columns) => {
    return { type: ADD_PRESET, payload: { pageName, presetName, columns } };
};

const deletePreset = (pageName, presetName) => {
    return { type: DELETE_PRESET, payload: { pageName, presetName } };
};

const switchPreset = (pageName, presetName) => {
    return { type: SWITCH_PRESET, payload: { pageName, presetName } };
};

const defaultState = localStorage.getItem('table-builder') ? JSON.parse(localStorage.getItem('table-builder')) : {};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case ADD_PRESET: {
            const { pageName, presetName, columns } = action.payload;
            const newState = {
                ...state,
                [pageName]: {
                    activePreset: presetName,
                    presets: { ...(state[pageName] ? state[pageName].presets : {}), [presetName]: columns }
                }
            };
            localStorage.setItem('table-builder', JSON.stringify(newState));
            return newState;
        }
        case DELETE_PRESET: {
            const { pageName, presetName } = action.payload;
            const newState = {
                ...state,
                [pageName]: {
                    activePreset: null,
                    presets: { ...(state[pageName] ? omit(state[pageName].presets, [presetName]) : {}) }
                }
            };
            localStorage.setItem('table-builder', JSON.stringify(newState));
            return newState;
        }
        case SWITCH_PRESET: {
            const { pageName, presetName } = action.payload;
            const newState = {
                ...state,
                [pageName]: {
                    activePreset: presetName,
                    presets: { ...(state[pageName] ? state[pageName].presets : {}) }
                }
            };
            localStorage.setItem('table-builder', JSON.stringify(newState));
            return newState;
        }
        default:
            return state;
    }
}

export const presetsSelector = (state, props) =>
    state.tableBuilder[props.pageName] ? state.tableBuilder[props.pageName].presets : {};
export const activePresetSelector = (state, props) =>
    state.tableBuilder[props.pageName] ? state.tableBuilder[props.pageName].activePreset : null;

export const makeAddPreset = ({ pageName }) => (presetName, columns) => addPreset(pageName, presetName, columns);
export const makeDeletePreset = ({ pageName }) => presetName => deletePreset(pageName, presetName);
export const makeSwitchPreset = ({ pageName }) => presetName => switchPreset(pageName, presetName);
