import { makePrefixAdder } from '../../redux/utils/type-utils';

const elementIcon = makePrefixAdder('ELEMENT_ICON');
const ELEMENT_HOVER = elementIcon('ELEMENT_HOVER');
const ELEMENT_UNHOVER = elementIcon('ELEMENT_UNHOVER');
const ELEMENT_FIX = elementIcon('ELEMENT_FIX');
const ELEMENT_UNFIX = elementIcon('ELEMENT_UNFIX');

export function hover(elementId, id) {
    return { id, elementId, type: ELEMENT_HOVER };
}

export function fix(id) {
    return { id, type: ELEMENT_FIX };
}

export function unfix(id) {
    return { id, type: ELEMENT_UNFIX };
}

export function unhover(id) {
    return { id, type: ELEMENT_UNHOVER };
}

const defaultState = {};

const modulePlayers = (state = defaultState, action) => {
    switch (action.type) {
        case ELEMENT_HOVER:
            return { ...state, [action.id]: { id: action.elementId } };
        case ELEMENT_UNHOVER:
            if (state[action.id].fixed) return state;
            return { ...state, [action.id]: undefined };
        case ELEMENT_FIX:
            return { ...state, [action.id]: { ...state[action.id], fixed: true } };
        case ELEMENT_UNFIX:
            return { ...state, [action.id]: { ...state[action.id], fixed: false } };
        default:
            return state;
    }
};
export default modulePlayers;
