import React, { useState } from 'react';
import {
    FullPageMessageWrapper,
    InfoWarningWrapper,
    MessageApproveButton,
    MessageText,
    MessageWrapper,
    WarningIcon,
    MessageTextH1,
    MessageTextH2,
    ImgWrap
} from './app-private-styled-components';
import { connect } from 'react-redux';
import logoImg from '../../../containers/app/TPE_black.svg';
import { warningWasShowedSelector, actions as warningActions } from '../../../redux/modules/warning-for-user';
import FormattedMessage from '../../../components/formatted-message';

const InfoWarning = ({ warningWasShowed, readWarning }) => {
    const [messageVisibility, setMessageVisibility] = useState(false);

    const handleShowMessage = () => {
        setMessageVisibility(true);
    };

    const handleHideMessage = () => {
        setMessageVisibility(false);
        readWarning();
    };

    return (
        <InfoWarningWrapper>
            <WarningIcon showed={warningWasShowed} onClick={handleShowMessage} />
            {messageVisibility && (
                <FullPageMessageWrapper>
                    <MessageWrapper>
                        <ImgWrap>
                            <img alt="" src={logoImg} height={40} />
                        </ImgWrap>
                        <MessageTextH1>
                            <FormattedMessage id={'warningForUser.title'} />
                        </MessageTextH1>
                        <MessageTextH2>
                            <FormattedMessage id={'warningForUser.date'} />
                        </MessageTextH2>
                        <MessageText>
                            <FormattedMessage id={'warningForUser.messagePartFirst'} />
                        </MessageText>
                        <MessageText>
                            <FormattedMessage id={'warningForUser.messagePartSecond'} />
                        </MessageText>
                        <MessageApproveButton onClick={handleHideMessage}>OK</MessageApproveButton>
                    </MessageWrapper>
                </FullPageMessageWrapper>
            )}
        </InfoWarningWrapper>
    );
};

export default connect(
    state => ({ warningWasShowed: warningWasShowedSelector(state) }),
    {
        readWarning: warningActions.makeWarningRead
    }
)(InfoWarning);
