/**
 * переводит настройки позиционирования chartJs в настройки tether
 * (они совпадают кроме позиции по центру: в chartJs - center, а в tether - middle)
 */
function translateAlign(align) {
    return align === 'center' ? 'middle' : align;
}
/**
 * @param chartJsTooltip тултип chartJs
 * @return настройки tether для позиционирования
 */

export function tetherOptionsFromChartJsTooltip(chartJsTooltip) {
    const { xAlign, yAlign, caretX, caretY } = chartJsTooltip;
    return {
        attachment: `${translateAlign(yAlign)} ${translateAlign(xAlign)}`,
        targetAttachment: 'top left',
        targetOffset: `${caretY}px ${caretX}px`
    };
}
