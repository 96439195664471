import { opacityColor } from '../video-controls/video-controls-styled';

export const playlistBackgroundColor = 'var(--backgroundColorSecondary)';
export const playlistIconColor = 'var(--uiPrimaryColor)';
export const playlistSecondaryColor = 'var(--uiSecondaryColor)';
export const playlistBadgesColor = 'rgba(117,122,128,0.9)';
export const playlistFontColor = 'rgba(112,124,147,1)';

const playlistBackgroundTransparentColor = 'rgba(150, 150, 150, 0.1 5)';
const playlistBackgroundTransparentFullscreenColor = 'rgba(150, 150, 150, 0.85)';
const errorColor = 'rgba(226,74,97,0.58)';
const cancelColor = 'rgb(226,135,74)';
const playlistActiveHeaderBackground = 'rgba(74, 144, 226, 0.15)';
const hoverColor = '#dce6f4';
const playlistFontColorLight = '#fff';
const playlistBackgroundOpacityColor = opacityColor;
const playlistBackgroundHiddenColor = 'none';

const borderNonActive = '1px solid rgba(0,0,0,0.15)';
const borderErrorField = `2px solid ${errorColor}`;
const borderIsActive = `1px solid ${playlistIconColor}`;
const rowBorderColor = `solid 1px #a6b1c4`;

const lightFontColor = 'rgb(186, 194, 207)';

const defaultActiveControlColor = 'var(--backgroundColorSecondary)';
const defaultNonActiveControlColor = 'var(--alternativeBackgroundColor)';

const whiteFontColor = 'rgb(255, 255, 255)';

const onHoverColor = '#b1ceee';

const fontBlueColor = '#2967b0';
const playlistFullScreenOnMobileBackgroundColor = 'rgba(41, 103, 176, 0.9)';
const playlistDialogFullscreenBackgroundColor = 'rgba(136,158,184,0.8)';
const videoBackgroundColor = '#000';
const alternativeFontColor = '#fff';

const videoPlayerBackgroundColor = 'var(--backgroundColorSecondary)';

export const videoPlayerPalette = {
    playlistBackgroundTransparentColor,
    playlistBackgroundTransparentFullscreenColor,
    errorColor,
    cancelColor,
    playlistActiveHeaderBackground,
    hoverColor,
    playlistFontColorLight,
    playlistBackgroundOpacityColor,
    playlistBackgroundHiddenColor,
    borderNonActive,
    borderErrorField,
    borderIsActive,
    rowBorderColor,
    lightFontColor,
    defaultActiveControlColor,
    defaultNonActiveControlColor,
    whiteFontColor,
    onHoverColor,
    fontBlueColor,
    videoBackgroundColor,
    alternativeFontColor,
    videoPlayerBackgroundColor,
    playlistFullScreenOnMobileBackgroundColor,
    playlistDialogFullscreenBackgroundColor
};

const activeSelectorColor = 'rgb(170, 170, 170)';
const selectorOfElementColor = '#a6b0c4';
const newPlaylistConfirmColor = 'rgba(175,217,250,0.9)';

const activeSelectorBorder = `1px solid ${activeSelectorColor}`;
const selectorOfElementBorder = `1px solid ${selectorOfElementColor}`;
const newPlaylistConfirmBorder = `1px solid ${newPlaylistConfirmColor}`;

export const videoPlayerBordersPalette = {
    activeSelectorBorder,
    selectorOfElementBorder,
    newPlaylistConfirmBorder
};
