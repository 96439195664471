/* eslint-disable eqeqeq */
import { createSelector } from 'reselect';
import { createSkipMarkedArgsSelector, markSelector } from '../../../utils/selectors';
import { extendedPositionStatusSelector, positionStatusSelector } from '../../stats-filter/selectors';
import { teamsPredicateSelector } from '../../../selectors/predicates/team-predicates';
import { summaryActiveTeamSelector } from '../../../pages/game-center/analytics/summary/summary-selector';
import { getUserProfileTeamId } from '../../../selectors/user/user-profiles-selector';
import { shiftsOnlyPlayerIdSelector } from '../../../redux/modules/user';
import { isProspectMode } from '../../../selectors/user/user-features-selector';
import { playerProspectIntegrationSelector } from '../../../redux/modules/prospect-integration';

const HOME = 'HOME';

/**
 * @return all game players instances unique by playerId (don't filtered by team status filter) without player profiles
 */

export const uniqueGamePlayersSelector = createSkipMarkedArgsSelector(
    state => state.widgetsStats.gamePlayers,
    markSelector(state => ({
        gameIds: state.statsFilter.gameIds
    })),
    markSelector(state => state.entities),
    getUserProfileTeamId,
    (gamePlayers, { gameIds }, entities, userTeamId) => {
        if (!gamePlayers || gamePlayers.processing || !entities.gamePlayers)
            return {
                processing: true,
                data: []
            };

        const entitiesGamePlayers = Object.values(entities.gamePlayers).filter(
            ({ gameId }) => gameIds.indexOf(gameId) >= 0
        );
        const data = {};
        const positionsCount = {
            F: 0,
            D: 0,
            G: 0
        };

        for (const gamePlayer of entitiesGamePlayers) {
            const { gameId, teamStatus, playerId, playerPosition, jersey } = gamePlayer;

            const game = entities.games[gameId];
            const teamId = teamStatus === HOME ? game.homeTeam : game.awayTeam;
            const dataPlayer = data[playerId];

            if (!dataPlayer) {
                positionsCount[playerPosition]++;
                data[playerId] = {
                    ...gamePlayer,
                    teamId,
                    teamIds: [teamId],
                    inUserTeam: teamId === userTeamId
                };
                continue;
            }

            positionsCount[playerPosition]++;
            const isMoreRecentPosition = positionsCount[playerPosition] > positionsCount[dataPlayer.playerPosition];
            if (isMoreRecentPosition) {
                dataPlayer.playerPosition = playerPosition;
            }
            if (!dataPlayer.teamIds.includes(teamId)) {
                dataPlayer.teamIds.push(teamId);
            }
            const isMoreRecentGame = new Date(entities.games[dataPlayer.gameId].date) < new Date(game.date);
            const isJerseyEquals = dataPlayer.jersey === jersey;
            if (isMoreRecentGame && !isJerseyEquals) {
                dataPlayer.jersey = jersey;
            }
            const isTeamIdEquals = dataPlayer.teamId === teamId;

            if (isMoreRecentGame && !isTeamIdEquals) {
                dataPlayer.teamId = teamId;
                dataPlayer.inUserTeam = teamId === userTeamId;
            }
        }

        return {
            processing: false,
            data: Object.values(data)
        };
    }
);

const filterSelector = (_, props) => props && props.filter;

/**
 * @return all gamePlayers includes gamePlayers with same playerId
 */
const rawGamePlayersSelector = createSkipMarkedArgsSelector(
    state => state.widgetsStats.gamePlayers,
    markSelector(state => ({
        gameIds: state.statsFilter.gameIds
    })),
    markSelector(state => state.entities), //todo wrong place to that logic should be removed
    summaryActiveTeamSelector,
    (gamePlayers, { gameIds }, entities, userTeamId) => {
        if (!gamePlayers || gamePlayers.processing || !userTeamId || !entities || !entities.gamePlayers)
            return {
                processing: true,
                data: []
            };

        const entitiesGamePlayers = Object.values(entities.gamePlayers).filter(
            ({ gameId }) => gameIds.indexOf(gameId) >= 0
        );

        const data = entitiesGamePlayers.map(gamePlayer => {
            const game = entities.games[gamePlayer.gameId];
            const teamId = gamePlayer.teamStatus === HOME ? game.homeTeam : game.awayTeam;
            return { ...gamePlayer, teamId, teamIds: [teamId], inUserTeam: teamId === userTeamId };
        });

        return {
            processing: false,
            data
        };
    }
);
/**
 * @return filtered game players instances (filtered by team status filter) without player profiles
 */

export const withoutProfileGamePlayersSelector = createSelector(
    uniqueGamePlayersSelector,
    teamsPredicateSelector,
    filterSelector,
    (gamePlayers, teamPredicate, filter) => {
        const predicate = filter && filter.teamIds ? teamId => filter.teamIds.includes(teamId) : teamPredicate;
        const filteredData = gamePlayers.data.filter(gp => gp.teamIds.some(predicate));

        return {
            processing: gamePlayers.processing,
            data: filteredData
        };
    }
);
export const withoutProfileGamePlayersPositionSelector = createSelector(
    withoutProfileGamePlayersSelector,
    positionStatusSelector,
    (gamePlayers, positionStatus) => {
        return {
            processing: gamePlayers.processing,
            data: gamePlayers.data.filter(gp => positionStatus.includes(gp.playerPosition))
        };
    }
);

const isPlayerProfileProcessing = (state, props) => {
    return (
        (!props || !props.playerProfiles) &&
        (!state.widgetsStats.playerProfiles || state.widgetsStats.playerProfiles.processing)
    );
};

const getProfileInfo = (player, playerProfile) => ({
    playerId: player.playerId,
    teamStatus: player.teamStatus,
    position: player.playerPosition,
    jersey: player.jersey,
    profile: playerProfile,
    inUserTeam: player.inUserTeam,
    gamePlayerId: player.id,
    teamId: player.teamId,
    teamIds: player.teamIds
});

//todo refactor unstable part of code with gamePlayers2 and profiles2
const withProfile = (gamePlayers1, profileProcessing, profiles1, gamePlayers2, profiles2) => {
    if (gamePlayers1.processing && profileProcessing && !gamePlayers2) {
        return {
            processing: true,
            data: []
        };
    }

    if (gamePlayers2) {
        return {
            processing: false,
            data: Object.values(gamePlayers2).map(gp => {
                return getProfileInfo(gp, (profiles2 || profiles1)[gp.playerId]);
            })
        };
    }

    return {
        processing: false,
        data: gamePlayers1.data.map(gp => {
            return getProfileInfo(gp, (profiles2 || profiles1)[gp.playerId]);
        })
    };
};

export const toMapByPlayerId = players => {
    const playerIdToFieldPlayer = {};

    if (!players || !players.data) {
        return {
            processing: players.processing,
            data: playerIdToFieldPlayer
        };
    }

    for (const gp of players.data) {
        playerIdToFieldPlayer[gp.playerId] = gp;
    }

    return {
        processing: players.processing,
        data: playerIdToFieldPlayer
    };
};

/**
 * @return filtered game players instances (filtered by team status filter) with player profiles
 */

export const gamePlayersSelector = createSkipMarkedArgsSelector(
    withoutProfileGamePlayersSelector,
    isPlayerProfileProcessing,
    markSelector(state => state.entities.playerProfiles),
    withProfile
);

export const allGamePlayersWithProfilesSelector = createSkipMarkedArgsSelector(
    rawGamePlayersSelector,
    isPlayerProfileProcessing,
    markSelector(state => state.entities.playerProfiles),
    (state, props) => props && props.gamePlayers,
    (state, props) => props && props.playerProfiles,
    withProfile
);

export const gamePlayersPositionSelector = createSkipMarkedArgsSelector(
    withoutProfileGamePlayersPositionSelector,
    isPlayerProfileProcessing,
    markSelector(state => state.entities.playerProfiles),
    withProfile
);
/**
 * @return filtered field player instance (filtered by team status filter) with player profiles
 */

export const fieldPlayersSelector = createSelector(
    gamePlayersSelector,
    (_, props) => props && props.limitedMode,
    shiftsOnlyPlayerIdSelector,
    (gamePlayers, limitedMode, playerId) => {
        if (limitedMode) {
            const player = gamePlayers.data.find(gp => {
                return gp.playerId === playerId;
            });

            return {
                processing: gamePlayers.processing,
                data: player ? [player] : []
            };
        }

        return {
            processing: gamePlayers.processing,
            data: gamePlayers.data.filter(gp => gp.position !== 'G')
        };
    }
);

const extractedLimitedPositionPlayers = (gamePlayers, playerId, prospectModeId, prospectMode) => {
    const player = gamePlayers.data.find(gp => {
        return gp.playerId === playerId;
    });

    const playerForProspect = gamePlayers.data.find(gp => {
        return gp.playerId === prospectModeId;
    });

    return {
        processing: gamePlayers.processing || (!playerId && !prospectModeId),
        data: prospectMode && !!playerForProspect ? [playerForProspect] : !!player ? [player] : []
    };
};

export const positionPlayersWithGoalieSelector = createSelector(
    gamePlayersSelector,
    positionStatusSelector,
    shiftsOnlyPlayerIdSelector,
    (_, props) => props && props.limitedMode,
    isProspectMode,
    playerProspectIntegrationSelector,
    (gamePlayers, playerId, limitedMode, prospectMode, prospectModeId) => {
        if (!limitedMode) {
            return {
                processing: gamePlayers.processing,
                data: gamePlayers.data
            };
        }

        return extractedLimitedPositionPlayers(gamePlayers, playerId, prospectModeId, prospectMode);
    }
);

export const positionPlayersSelector = createSelector(
    gamePlayersSelector,
    positionStatusSelector,
    shiftsOnlyPlayerIdSelector,
    (_, props) => props && props.limitedMode,
    isProspectMode,
    playerProspectIntegrationSelector,
    (gamePlayers, positions, playerId, limitedMode, prospectMode, prospectModeId) => {
        if (!limitedMode) {
            return {
                processing: gamePlayers.processing,
                data: gamePlayers.data.filter(gp => positions.includes(gp.position))
            };
        }

        return extractedLimitedPositionPlayers(gamePlayers, playerId, prospectModeId, prospectMode);
    }
);

export const extendedPositionPlayersSelector = createSelector(
    gamePlayersSelector,
    extendedPositionStatusSelector,
    shiftsOnlyPlayerIdSelector,
    (_, props) => props && props.limitedMode,
    isProspectMode,
    playerProspectIntegrationSelector,
    (gamePlayers, positions, playerId, limitedMode, prospectMode, prospectModeId) => {
        if (!limitedMode) {
            return {
                processing: gamePlayers.processing,
                data: gamePlayers.data.filter(gp => positions.includes(gp.position))
            };
        }

        return extractedLimitedPositionPlayers(gamePlayers, playerId, prospectModeId, prospectMode);
    }
);

/**
 * @return dictionary (map) playerProfileId to field player instance
 */

export const playerIdToFieldPlayerSelector = createSelector(
    fieldPlayersSelector,
    toMapByPlayerId
);

/**
 * @return dictionary (map) playerProfileId to instance with profile,
 * filtered by `position` and `teamStatus`
 */

export const playerIdToPositionPlayerSelector = createSelector(
    positionPlayersSelector,
    toMapByPlayerId
);

export const extendedPlayerIdToPositionPlayerSelector = createSelector(
    extendedPositionPlayersSelector,
    toMapByPlayerId
);

/**
 * @return unique players with profile aren't filtered by any predicate
 */
export const uniquePlayersWithProfilesSelector = createSkipMarkedArgsSelector(
    uniqueGamePlayersSelector,
    isPlayerProfileProcessing,
    markSelector(state => state.entities.playerProfiles),
    withProfile
);
