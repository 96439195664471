/*Type constants */
export const OFFENSIVE = 'OFFENSIVE';
export const CARRIES_IN = 'CARRY_IN';
export const PASS_IN = 'PASS_IN';

export const PENALTY_EVENT_TYPES = ['PENALTY_2_MIN', 'PENALTY_4_MIN', 'PENALTY_5_MIN'];

/*Time constants*/
export const DELTA_TIME_AFTER_ENTRIES_DS = 8 * 10;
export const DELTA_TIME_AFTER_RECOVERIES_DS = 5 * 10;
export const DELTA_TIME_AFTER_TAKEAWAY_DS = 6 * 10;
export const DELTA_TIME_AFTER_FACE_OFF_DS = 8 * 10;
export const DELTA_TIME_AFTER_FACE_OFF_AND_POSSESSION_DS = 10 * 10;
