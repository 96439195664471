import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormatMessageFunction } from '../../redux/modules/locale';
import { readMarkedProps, isTranslatable } from '../../translation/translation-utils';
export { markAsTranslatable as tr } from '../../translation/translation-utils';

function mapStateToProps(state) {
    return {
        formatMessage: getFormatMessageFunction(state)
    };
}

const invalidStyle = { border: '1px solid var(--cancelColor)', background: '#d5486633' };

/* ============ PropsShifter ============ */
class PropsFormatter extends Component {
    format(prop) {
        const { formatMessage } = this.props;
        if (typeof prop !== 'object' || prop === null) return prop;
        if (isTranslatable(prop)) return formatMessage(readMarkedProps(prop));

        if (prop instanceof Array) {
            return prop.map(value => this.format(value));
        }

        const formattedProps = {};
        Object.keys(prop).map(key => (formattedProps[key] = this.format(prop[key])));
        return formattedProps;
    }
    render() {
        const { children, formatMessage, dispatch, validationPredicate, ...params } = this.props;

        const formattedParams = this.format(params);
        const { value } = children.props;
        const invalid = validationPredicate && !validationPredicate(value);

        return React.cloneElement(React.Children.only(children), {
            ...formattedParams,
            style: { ...formattedParams.style, ...(invalid && invalidStyle) }
        });
    }
}

PropsFormatter = connect(mapStateToProps)(PropsFormatter);
export { PropsFormatter as default };
