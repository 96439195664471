import { individualShotsSelector } from '../../../../../containers/widgets-stats/selectors/shots';
import { shots, createScoringChancesSelector } from './shots';
import { FAVORITE_EVENT } from '../../favorite-types';
import { processingState, mapperErrorMessage, createEpisodeIdWrapper } from '../../episode-utils';
import { getTypeToMediaTimeWrapper } from '../helpers/episodes-selectors-helpers';

const selector = individualShotsSelector;

const shotTypeToGameShotType = typeId => `PERSONAL_${typeId}`;

const PERSONAL_SCORE_ATTEMPTS = shotTypeToGameShotType(shots.types.SCORE_ATTEMPTS);
const PERSONAL_SCORING_CHANCES = shotTypeToGameShotType(shots.types.SCORING_CHANCES);

const types = {
    PERSONAL_SCORE_ATTEMPTS,
    PERSONAL_SCORING_CHANCES
};

const typeToSelector = {
    [PERSONAL_SCORING_CHANCES]: createEpisodeIdWrapper(PERSONAL_SCORING_CHANCES, createScoringChancesSelector()),
    [PERSONAL_SCORE_ATTEMPTS]: createEpisodeIdWrapper(PERSONAL_SCORE_ATTEMPTS, s => s)
};

function mapper(gameShots, type) {
    if (gameShots.processing) return processingState;
    if (!(type in typeToSelector)) throw mapperErrorMessage(type, 'Single Shots');
    return typeToSelector[type](gameShots);
}

const typeToMediaTimeWrapper = getTypeToMediaTimeWrapper(typeToSelector);

function getTimedGroup(group, periods, typeId) {
    return typeToMediaTimeWrapper[typeId](group, periods);
}

const favoriteType = FAVORITE_EVENT;

const individualShots = {
    types,
    selector,
    mapper,
    getTimedGroup,
    favoriteType
};
export default individualShots;
