/**
 * @param index zero based element index
 * @return mask created for this index
 */
export const indexToMask = index => 1 << index;
/**
 * @param mask one
 * @param other mask two
 * @return weather or not masks have intersection
 */

export const isMasksIntersected = (mask, other) => (mask & other) !== 0;

/**
 * @param {array} masks List of masks values
 * @returns {number} United masks value
 */
export const masksUnion = (masks = []) => masks.reduce((sum, mask) => sum | mask, 0);
