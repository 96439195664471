export const PRO = 'PRO';
export const PREMIUM_PLUS = 'PREMIUM_PLUS';
/**
 * @deprecated use `PREMIUM_PLUS` instead
 */
export const LITE = PREMIUM_PLUS;
export const PREMIUM = 'PREMIUM';
export const SUPER_LITE = 'SUPER_LITE';
export const PRE_SCOUT = 'PRE_SCOUT';
export const SCOUT = 'SCOUT';
export const REPORT = 'REPORT';
export const NAHL = 'NAHL';

export const productList = [PRO, PREMIUM, SUPER_LITE, PRE_SCOUT, SCOUT, REPORT, NAHL];

export const productToPath = {
    [PRO]: { path: '/pro' },
    [PREMIUM_PLUS]: { path: '/premium-plus' },
    [PREMIUM]: { path: '/premium' },
    [PRE_SCOUT]: { path: '/pre-scout' },
    [SUPER_LITE]: { path: '/super-lite' },
    [SCOUT]: { path: '/scout' },
    [REPORT]: { path: '/game-reports' }
};
