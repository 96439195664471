/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UploadClientGamePopup from 'components/upload-client-game-popup';

import { trackEvent, trackedEvents } from 'telemetry/app-analytics';

import { getUserProfileTeamId } from 'selectors/user/user-profiles-selector';

import { isBrandedByHockeyTech } from 'selectors/user/user-features-selector';
import { combinedMapStateToProps } from 'utils/helpers/redux-utils';
import { switchRatingSystem } from 'redux/modules/rating-system';

import { selector as ratingSystemSelector } from 'redux/modules/rating-system';

import UserPicture from '../../../user-picture/user-picture';
import { selector as productSelector } from '../../../product-initializer/product-module';

import { ProfileControls } from './profile/controls.js';
import styles from './profile/profile.module.css';

const TeamItem = connect(state => {
    const teamId = getUserProfileTeamId(state);
    if (!teamId) {
        return null;
    }

    const team = state?.entities?.teams?.[teamId] || null;

    return { team };
})(({ team }) =>
    !team ? null : (
        <div className={styles.teamItem}>
            <div className={styles.teamLogoContainer}>
                <img alt="" src={team.logo} className={styles.teamItemLogo} />
            </div>
            <div className={styles.teamName}>{team.nameEn}</div>
        </div>
    )
);

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpened: false,
            uploadClientGamePopupOpen: false
        };
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    toggleDropdown = () => {
        const { isDropdownOpened } = this.state;
        if (isDropdownOpened) {
            if ('ontouchstart' in window) {
                document.removeEventListener('touchstart', this.handleClickOutside, false);
            } else {
                document.removeEventListener('click', this.handleClickOutside, false);
            }
        } else {
            trackEvent(trackedEvents.SHOW_PROFILE_POPUP);
            if ('ontouchstart' in window) {
                document.addEventListener('touchstart', this.handleClickOutside, false);
            } else {
                document.addEventListener('click', this.handleClickOutside, false);
            }
        }

        this.setState({
            isDropdownOpened: !isDropdownOpened
        });
    };

    handleClickOutside = e => {
        if (
            e.target !== this.dropdown &&
            !this.dropdown?.contains(e.target) &&
            e.target !== this.profilePanel &&
            !this.profilePanel?.contains(e.target)
        ) {
            this.toggleDropdown();
        }
    };

    renderDropDown() {
        const {
            logout,
            brandedByHockeyTech,
            product,
            switchRatingSystem,
            ratingSystem,
            user,
            onEditEmails,
            onEditPassword
        } = this.props;
        const { profile } = user || {};
        const { emails } = profile || {};

        return (
            <div className={styles.popupMenu} ref={c => c && (this.dropdown = c)}>
                <TeamItem />
                <ProfileControls
                    emails={emails}
                    onEditEmails={email => {
                        onEditEmails(email);
                        this.toggleDropdown();
                    }}
                    onEditPassword={() => {
                        onEditPassword();
                        this.toggleDropdown();
                    }}
                    product={product}
                    logout={logout}
                    handleUploadClientGameClick={this.handleUploadClientGameClick}
                    brandedByHockeyTech={brandedByHockeyTech}
                    switchRatingSystem={switchRatingSystem}
                    ratingSystem={ratingSystem}
                />
            </div>
        );
    }

    handleUploadClientGameClick = event => {
        this.setState({
            uploadClientGamePopupOpen: true
        });
        this.toggleDropdown();
        event.preventDefault();
    };

    closeUploadClientGamePopup = () => {
        this.setState({
            uploadClientGamePopupOpen: false
        });
    };

    render() {
        const { user } = this.props;
        const { uploadClientGamePopupOpen, isDropdownOpened } = this.state;

        return (
            <div className={styles.profileRoot}>
                <UploadClientGamePopup popupOpen={uploadClientGamePopupOpen} close={this.closeUploadClientGamePopup} />
                <div className={styles.profilePanel} ref={e => e && (this.profilePanel = e)}>
                    <div className={styles.avatar} onClick={this.toggleDropdown}>
                        <UserPicture
                            style={{
                                width: 'auto',
                                height: '34px'
                            }}
                        />
                    </div>
                    <span className={styles.profileName} onClick={this.toggleDropdown}>
                        {user?.account?.username}
                    </span>
                </div>
                {isDropdownOpened && this.renderDropDown()}
            </div>
        );
    }
}

Profile = withRouter(
    connect(
        combinedMapStateToProps({
            product: productSelector,
            brandedByHockeyTech: isBrandedByHockeyTech,
            ratingSystem: ratingSystemSelector
        }),
        { switchRatingSystem }
    )(Profile)
);

Profile.propTypes = {
    logout: PropTypes.func,
    user: PropTypes.object
};

export default Profile;
