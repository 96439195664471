import { makePrefixAdder } from '../utils/type-utils';
import { deviceTypes } from '../../constants/device-parameters';

const addPrefixTo = makePrefixAdder('DEVICE_TYPE');
const SET_DEVICE_TYPE = addPrefixTo('SET_DEVICE_TYPE');

export const setDeviceType = deviceType => {
    return { type: SET_DEVICE_TYPE, payload: deviceType };
};

const initialState = {
    deviceType: deviceTypes.DESKTOP
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEVICE_TYPE:
            return {
                deviceType: action.payload
            };
        default:
            return state;
    }
};

const selector = state => state.deviceType.deviceType;

export const isMobile = state => selector(state) === deviceTypes.MOBILE;
export const isDesktop = state => selector(state) === deviceTypes.DESKTOP;
