import styles from './upload-client-game-popup.module.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Dropzone from 'components/dropzone/dropzone';
import Button from '../button/button';
import FormattedMessage from '../formatted-message';
import PropFormatter, { tr } from '../../containers/props-formatter';
import Input from '../input/input';
import * as endpoints from '../../utils/api/endpoints';
import { apiBuilder } from '../../utils/api/common-requests/common-requests';
import Icon from '../icon';
import throttle from 'lodash/throttle';
import { CancelToken, isCancel } from 'axios';
/**
 * popup component for uploading client scout game videos
 */

export default class UploadClientGamePopup extends Component {
    static propTypes = {
        popupOpen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };
    static initialState = () => ({
        gameDate: '',
        homeTeam: '',
        awayTeam: '',
        uploadPending: false,
        uploadProgress: 0,
        successfulFiles: [],
        failedFiles: [],
        files: []
    });

    constructor(props) {
        super(props);
        this.state = UploadClientGamePopup.initialState();
    }
    /**
     * upload selected files to server
     */

    uploadGameVideoToServer = () => {
        const { files, successfulFiles } = this.state;
        const filesToUpload = files.filter(file => !successfulFiles.includes(file));
        filesToUpload.reduce(
            (previousUploadPromise, file) => previousUploadPromise.then(() => this.uploadGameFile(file)),
            Promise.resolve()
        );
        this.onUploadStart();
    };
    uploadGameFile = file => {
        if (!this.state.uploadPending) {
            return;
        }

        const requestData = new FormData();
        requestData.append('file', file);
        const { gameDate, homeTeam, awayTeam } = this.state;
        requestData.append('gameDate', gameDate);
        requestData.append('homeTeam', homeTeam.trim());
        requestData.append('awayTeam', awayTeam.trim());
        this.cancelUploadToken = CancelToken.source();
        const config = {
            onUploadProgress: this.uploadProgressHandler,
            cancelToken: this.cancelUploadToken.token
        };
        return apiBuilder()
            .post(endpoints.clientGameVideos, requestData, config)
            .then(() => this.onUploadSuccess(file))
            .catch(error => this.onUploadError(file, error));
    };
    /**
     * updates loading progress, throttled to limit updates
     */

    uploadProgressHandler = throttle(({ loaded, total }) => {
        const uploadProgress = this.calculateUploadProgress(loaded, total);
        this.setState({
            uploadProgress: (uploadProgress * 100).toFixed(2)
        });
    }, 50);
    calculateUploadProgress = (fileLoaded, fileTotal) => {
        const { successfulFiles, failedFiles, files } = this.state;
        const finishedFileCount = successfulFiles.length + failedFiles.length;
        const totalFileCount = files.length;
        const fileProgress = fileLoaded / fileTotal;
        return (finishedFileCount + fileProgress) / totalFileCount;
    };
    /**
     * adds files passed to react drop zone
     * @param files from dropzone
     */

    onFilesDrop = files => {
        this.setState(prevState => ({
            files: [...prevState.files, ...files]
        }));
    };
    onGameDateChange = ({ target }) => {
        this.setState({
            gameDate: target.value
        });
    };
    onHomeTeamChange = ({ target }) => {
        this.setState({
            homeTeam: target.value
        });
    };
    onAwayTeamChange = ({ target }) => {
        this.setState({
            awayTeam: target.value
        });
    };
    onUploadStart = () => {
        this.setState({
            uploadPending: true,
            failedFiles: [],
            uploadProgress: 0
        });
    };
    onUploadSuccess = file => {
        this.setState(({ successfulFiles, failedFiles, files }) => {
            const newSuccessful = [...successfulFiles, file];
            const uploadPending = this.isUploadPending(newSuccessful, failedFiles, files);
            return {
                successfulFiles: newSuccessful,
                uploadPending
            };
        });
    };
    onUploadError = (file, error) => {
        if (isCancel(error)) {
            this.onUploadCancel();
        } else {
            this.setState(({ successfulFiles, failedFiles, files }) => {
                const newFailed = [...failedFiles, file];
                const uploadPending = this.isUploadPending(successfulFiles, newFailed, files);
                return {
                    failedFiles: newFailed,
                    uploadPending
                };
            });
        }
    };
    isUploadPending = (successfulFiles, failedFiles, files) => {
        return successfulFiles.length + failedFiles.length !== files.length;
    };
    onUploadCancel = () => {
        this.setState({
            successfulFiles: [],
            failedFiles: [],
            uploadPending: false
        });
    };
    /**
     * @returns {boolean} true if game data is ready to be uploaded
     */

    isReadyForUpload = () => {
        return (
            this.isGameInfoSet() && this.state.files.length > 0 && !this.state.uploadPending && !this.uploadSuccessful()
        );
    };
    /**
     * @returns {boolean} true if upload is successful
     */

    uploadSuccessful = () => {
        return this.state.successfulFiles.length > 0 && this.state.failedFiles.length === 0;
    };
    /**
     * @returns {boolean} true if game info is filled
     */

    isGameInfoSet() {
        return this.state.homeTeam.trim() && this.state.awayTeam.trim() && this.state.gameDate;
    }
    /**
     * @param fileToRemove file
     * @returns {Function} function to remove this file from selected
     */

    createRemoveFileHandler = fileToRemove => {
        return event => {
            this.setState(prevState => ({
                files: prevState.files.filter(f => f !== fileToRemove),
                failedFiles: prevState.failedFiles.filter(f => f !== fileToRemove)
            }));
            event.preventDefault();
            event.stopPropagation();
        };
    };
    /**
     * prepare form for new game upload
     */

    onUploadMore = event => {
        this.setState(UploadClientGamePopup.initialState());
        event.preventDefault();
        event.stopPropagation();
    };
    /**
     * cancel upload request
     */

    cancelPendingUpload = () => {
        this.cancelUploadToken.cancel();
    };
    /**
     * renders input for game data
     */

    renderGameInfoInputs = () => (
        <div className={styles.gameInfoInputContainer}>
            <PropFormatter placeholder={tr('uploadClientGamePopup.gameDate')}>
                <Input
                    type="date"
                    className={styles.input}
                    value={this.state.gameDate}
                    required="true"
                    onChange={this.onGameDateChange}
                />
            </PropFormatter>
            <PropFormatter placeholder={tr('uploadClientGamePopup.homeTeam')}>
                <Input
                    type="text"
                    className={styles.input}
                    value={this.state.homeTeam}
                    required="true"
                    onChange={this.onHomeTeamChange}
                />
            </PropFormatter>
            <PropFormatter placeholder={tr('uploadClientGamePopup.awayTeam')}>
                <Input
                    type="text"
                    className={styles.input}
                    value={this.state.awayTeam}
                    required="true"
                    onChange={this.onAwayTeamChange}
                />
            </PropFormatter>
        </div>
    );
    renderDropZone = () => (
        <Dropzone
            onDrop={this.onFilesDrop}
            className={styles.dropzone}
            disabled={this.state.uploadPending || this.uploadSuccessful()}
            disablePreview={true}
            activeClassName={styles.dropzoneDragActive}
        >
            {this.renderFileContainer()}
        </Dropzone>
    );
    /**
     * renders file container depending on upload state
     */

    renderFileContainer = () => {
        if (this.state.uploadPending) {
            return this.renderFileContainerPending();
        }

        if (this.state.successfulFiles.length > 0 || this.state.failedFiles.length > 0) {
            return this.renderFileContainerUploadFinished();
        }

        if (this.state.files.length === 0) {
            return this.renderFileContainerEmpty();
        }

        return this.renderFileContainerReady();
    };
    renderFileContainerReady = () => (
        <div>
            <FormattedMessage id={'uploadClientGamePopup.filesToUpload'} className={styles.filesToUploadLabel} />
            {this.renderFiles(this.state.files, true)}
        </div>
    );
    renderFileContainerEmpty = () => (
        <div>
            <FormattedMessage id={'uploadClientGamePopup.dragHere'} className={styles.filesToUploadLabel} />
            <FormattedMessage
                id={'uploadClientGamePopup.filesToUploadPlaceholder'}
                className={styles.filesToUploadPlaceholder}
            />
        </div>
    );
    renderFileContainerPending = () => (
        <div>
            <FormattedMessage id={'uploadClientGamePopup.uploading'} className={styles.filesToUploadLabel} />
            {this.renderFiles(this.state.files, false)}
            <div className={`progress ${styles.progress}`}>
                <div
                    className={`progress-bar ${styles.progressBar}`}
                    style={{
                        width: `${this.state.uploadProgress}%`
                    }}
                    role="progressbar"
                />
            </div>
            <Button onClick={this.cancelPendingUpload} className={`${styles.button} ${styles.cancel}`}>
                <FormattedMessage id={'uploadClientGamePopup.cancelUpload'} />
            </Button>
        </div>
    );
    renderFileContainerUploadFinished = () => (
        <div>
            {this.state.successfulFiles.length > 0 && this.renderFileContainerSuccess()}
            {this.state.failedFiles.length > 0 && this.renderFileContainerError()}
        </div>
    );
    renderFileContainerSuccess = () => (
        <div>
            <FormattedMessage id={'uploadClientGamePopup.success'} className={styles.filesToUploadLabel} />
            {this.renderFiles(this.state.successfulFiles, false)}
            <Button onClick={this.onUploadMore} className={styles.button}>
                <FormattedMessage id={'uploadClientGamePopup.uploadMore'} />
            </Button>
        </div>
    );
    renderFileContainerError = () => (
        <div>
            <FormattedMessage id={'uploadClientGamePopup.error'} className={styles.filesToUploadLabel} />
            {this.renderFiles(this.state.failedFiles, true)}
        </div>
    );
    renderFiles = (files, canEdit) => (
        <div>
            {files.map((file, i) => (
                <div key={i} className={styles.filesToUpload}>
                    {file.name}
                    {canEdit && (
                        <span onClick={this.createRemoveFileHandler(file)}>
                            <Icon type="cancel" wrapped={false} />
                        </span>
                    )}
                </div>
            ))}
        </div>
    );

    render() {
        return (
            <Modal
                isOpen={this.props.popupOpen}
                ariaHideApp={false}
                className={styles.modal}
                onRequestClose={this.props.close}
                overlayClassName={styles.overlay}
            >
                <FormattedMessage id={'uploadClientGamePopup.title'} className={styles.title} />
                {this.renderGameInfoInputs()}
                {this.renderDropZone()}
                <Button
                    color="primary"
                    onClick={this.uploadGameVideoToServer}
                    className={styles.button}
                    disabled={!this.isReadyForUpload()}
                >
                    <FormattedMessage id={'uploadClientGamePopup.upload'} />
                </Button>
            </Modal>
        );
    }
}
