import { connect } from 'react-redux';
import nanoid from 'nanoid';
import { actions, selector } from 'containers/rest-query/module';
import { notify } from '../../redux/modules/notifications';

const makeMapStateToProps = () => {
    const generatedQueryId = nanoid();

    return (state, ownProps) => {
        const { responses, entities } = selector(state);
        const queryId = ownProps.queryId || generatedQueryId;
        return { queryId, entities, ...responses[queryId] };
    };
};

const queryHoc = connect(
    makeMapStateToProps,
    { ...actions, notify }
);

export default queryHoc;
