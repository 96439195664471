import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { analyticsIndex, analyticsPages, statisticsIndex, statisticsPages } from 'constants/page-names';
import { isGoalieOnly, isProspectMode, isShiftsOnly } from 'selectors/user/user-features-selector';
import { playerIsGoalieProspectIntegrationSelector } from 'redux/modules/prospect-integration';
import { selector as productSelector } from 'containers/product-initializer/product-module';
import { actions as filterActions } from 'containers/stats-filter/module';
import { PREMIUM, productToPath, SUPER_LITE, PRO, PREMIUM_PLUS } from 'containers/product-initializer/products';

export const getNextPage = ({
    shiftsOnly = false,
    goalieOnly = false,
    superLite = false,
    prospectMode,
    prospectPlayerIsGoalie
}) => {
    if (superLite && shiftsOnly) {
        return `${statisticsIndex}/${statisticsPages.shifts}`;
    }

    if (superLite && goalieOnly) {
        return `${statisticsIndex}/${statisticsPages.goaltenders}`;
    }

    if (superLite) {
        return `dashboard/${analyticsPages.summary}`;
    }

    if (shiftsOnly || (prospectMode && !prospectPlayerIsGoalie)) {
        return `${statisticsIndex}/${statisticsPages.shifts}`;
    }

    if (goalieOnly || (prospectMode && prospectPlayerIsGoalie)) {
        return `${statisticsIndex}/${statisticsPages.goaltenders}`;
    }

    return `${analyticsIndex}/${analyticsPages.summary}`;
};

export const useSummaryRedirect = ({ teamIds, seasonIds, leagueIds, gameIds, onRedirect, isDisabled = false }) => {
    const product = useSelector(productSelector);
    const shiftsOnly = useSelector(isShiftsOnly);
    const goalieOnly = useSelector(isGoalieOnly);
    const prospectMode = useSelector(isProspectMode);
    const prospectPlayerIsGoalie = useSelector(playerIsGoalieProspectIntegrationSelector);

    const dispatch = useDispatch();
    const history = useHistory();

    const nextPage = getNextPage({ shiftsOnly, goalieOnly, prospectMode, prospectPlayerIsGoalie });

    const isReady = teamIds.length && seasonIds.length && leagueIds.length && gameIds.length;

    const updateFilterStat = {
        gameIds: {
            base: {
                gameIds,
                ignoredGameIds: []
            }
        },
        teamIds: {
            base: {
                teamIds
            }
        },
        seasonIds: {
            base: {
                seasonIds
            }
        },
        leagueIds: {
            base: {
                leagueIds
            }
        }
    };

    const handleRedirect = useCallback(() => {
        const redirectedProduct = ![PREMIUM, PREMIUM_PLUS, SUPER_LITE, PRO].some(el => el === product)
            ? PREMIUM
            : product;

        const isRedirectWithQueryParams = ![PREMIUM, PREMIUM_PLUS].some(el => el === product);

        let redirectUrl = '/';

        if (!!productToPath?.[redirectedProduct]?.path) {
            const redirectUrlBase = `${productToPath[redirectedProduct].path}/${nextPage}`;
            const redirectUrlQueryParams = `leagueId=${leagueIds[0]}&seasonId=${seasonIds[0]}&teamId=${
                teamIds[0]
            }&gameId=${gameIds[0]}`;

            redirectUrl = isRedirectWithQueryParams ? `${redirectUrlBase}/?${redirectUrlQueryParams}` : redirectUrlBase;
        }

        // TODO persist for filters or check why reset from scout redirect OR defaultSelector
        dispatch(filterActions.updateStoredStates(updateFilterStat));

        history.push(redirectUrl);
    }, [dispatch, updateFilterStat]);

    useEffect(() => {
        if (!isReady || isDisabled) {
            return;
        }

        handleRedirect?.();
        onRedirect?.();
    }, [handleRedirect, onRedirect, isReady, isDisabled]);
};
