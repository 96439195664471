import React from 'react';
import style from './star.module.css';

const Star = ({ active, width = 11, height = 11 }) => (
    <svg height={height} width={width} viewBox="0,0 230,230">
        <polygon
            points="100,10 122,78 190,78 136,121 160,198 100,150 40,198 64,122 10,78 78,78"
            className={active ? style.activeStar : style.star}
            strokeLinejoin="round"
            transform="translate(20,10)"
        />
    </svg>
);
export default Star;
