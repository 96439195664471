/**
 * Simple password generator
 * uses lowercase latin chars and numbers
 * password mask looks like <letter * lettersLength><number * numbersLength>
 *
 * @param {number} lettersLength Number of latin chars in password. Default = 6
 * @param {number} numbersLength Number of number chars in password. Default = 2
 * @returns {string} Generated password
 */
import { checkStringLength } from './helpers/check-condition-utils';

export const generateSimplePassword = (lettersLength = 6, numbersLength = 2) => {
    if (typeof lettersLength !== 'number' || typeof numbersLength !== 'number')
        throw new TypeError('Wrong attribute type');

    const charsetLetters = 'abcdefghijklmnopqrstuvwxyz';
    const charsetNumbers = '1234567890';

    const passwordCharsRandom = (charset, length) => {
        let chars = '';
        for (let i = 0; i < length; ++i) {
            chars += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return chars;
    };

    return passwordCharsRandom(charsetLetters, lettersLength) + passwordCharsRandom(charsetNumbers, numbersLength);
};

export const isPasswordValid = value => checkStringLength(value, 4, 50);
