export const extractedShiftsWithUniteDuplicates = outPutShiftsData => {
    const shiftsIds = outPutShiftsData.map(({ id }) => id);

    const duplicateIds = shiftsIds.filter((id, idIndex) => shiftsIds.indexOf(id) < idIndex);

    const shiftsWithDuplicates = outPutShiftsData.filter(({ id }) => duplicateIds.includes(id));
    const shiftsWithoutDuplicates = outPutShiftsData.filter(({ id }) => !duplicateIds.includes(id));

    const uniteData = [];

    for (const singleDuplicate of shiftsWithDuplicates) {
        const { id } = singleDuplicate;
        const existShift = uniteData.find(el => el.id === id);
        if (!existShift) {
            uniteData.push(singleDuplicate);
            continue;
        }

        const updatedShift = {};

        for (const shiftValuePath of Object.keys(existShift)) {
            updatedShift[shiftValuePath] =
                typeof singleDuplicate[shiftValuePath] === 'number' &&
                singleDuplicate[shiftValuePath] > existShift[shiftValuePath]
                    ? singleDuplicate[shiftValuePath]
                    : existShift[shiftValuePath];
        }

        const existIndex = uniteData.indexOf(existShift);

        uniteData[existIndex] = { ...updatedShift };
    }

    return [...(shiftsWithoutDuplicates ?? []), ...(uniteData ?? [])].sort();
};
