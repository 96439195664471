import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components/macro';
import Icon from '../../../components/icon';
import {
    selector as notificationsSelector,
    notificationTypes,
    destroy as destroyNotificationById
} from 'redux/modules/notifications';
import { mobileMediaCondition } from '../../../utils/helpers/device-utils';
import { zIndexes } from '../../../constants/global-style-constants';

const DURATION = 3000;
const FADE_IN_DURATION = 1000;
const FADE_OUT_DURATION = 1000;

const notificationTypeToColor = type => {
    switch (type) {
        case notificationTypes.SUCCESS:
            return '#acc895';
        case notificationTypes.ERROR:
            return '#ffa6a6';
        case notificationTypes.INFO:
            return '#dde9ff';
        default:
            return 'white';
    }
};

const notificationTypeToIcon = {
    [notificationTypes.SUCCESS]: 'ok',
    [notificationTypes.ERROR]: 'cancel',
    [notificationTypes.INFO]: 'info'
};

const Container = styled.div`
    position: fixed;
    right: 2px;
    bottom: 2px;
    z-index: ${zIndexes.notificationPanel};

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.9;
  }
`;

const NotificationWrapper = styled.div`
    display: flex;
    align-items: baseline;
    width: 400px;
    min-height: 100px;
    margin: 3px;
    padding: 6px 16px;

    border: 1px solid #fff8;
    border-radius: 10px;

    color: ${({ type }) => notificationTypeToColor(type)};

    background: #1c344e;

    opacity: ${props => (props.show ? 0.9 : 0)};

    transition: opacity ${FADE_OUT_DURATION}ms, background-color 200ms, border 200ms;
    transition-timing-function: ease-in;

    animation-name: ${fadeIn};
    animation-duration: ${FADE_IN_DURATION}ms;
    animation-timing-function: ease-out;

    user-select: none;

    &:hover {
        border: 1px solid #ffff;

        background: #17273c;
    }

    @media ${mobileMediaCondition} {
        width: unset;
    }
`;

class Notification extends Component {
    state = { show: true };

    hideTimerId = null;
    destroyTimerId = null;

    setTimeouts = () => {
        this.hideTimerId = setTimeout(() => this.setState({ show: false }), FADE_IN_DURATION + DURATION);
        this.destroyTimerId = setTimeout(() => this.props.destroy(), FADE_IN_DURATION + DURATION + FADE_OUT_DURATION);
    };

    clearTimeouts = () => {
        clearTimeout(this.hideTimerId);
        clearTimeout(this.destroyTimerId);
        this.hideTimerId = null;
        this.destroyTimerId = null;
    };

    componentDidMount() {
        this.setTimeouts();
    }

    mouseEnterHandler = () => {
        !this.state.show && this.setState({ show: true });
        this.clearTimeouts();
    };

    mouseLeaveHandler = () => {
        this.setTimeouts();
    };

    renderIcon() {
        const { type } = this.props;
        const imgStyle = { width: '16px', margin: '0 16px' };

        return <Icon style={imgStyle} wrapped={false} type={notificationTypeToIcon[type]} />;
    }

    render() {
        const { show } = this.state;
        const { type, content, destroy } = this.props;

        return (
            <NotificationWrapper
                type={type}
                show={show}
                onClick={destroy}
                onMouseEnter={this.mouseEnterHandler}
                onMouseLeave={this.mouseLeaveHandler}
            >
                {type && this.renderIcon()}
                {content}
            </NotificationWrapper>
        );
    }
}

const NotificationsPanel = ({ notifications, destroyNotificationById }) => {
    if (notifications.length === 0) {
        return null;
    }

    return (
        <Container>
            {notifications.map(({ type, content, id }) => (
                <Notification key={id} type={type} content={content} destroy={() => destroyNotificationById(id)} />
            ))}
        </Container>
    );
};

export default connect(
    state => ({ notifications: notificationsSelector(state) }),
    { destroyNotificationById }
)(NotificationsPanel);
