import React, { createRef, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import request from '../../utils/api/common-requests/common-requests';
import Input from '../../components/input/input';
import FormattedMessage from '../../components/formatted-message';
import PropsFormatter, { tr } from '../../containers/props-formatter';
import { StyledButton, StyledContainer, StyledError, StyledForm, StyledHeader } from './auth-styled-components';
import { isEmailValid } from '../../utils/regex-utils';
import { encodeSearchUrl } from '../../utils/api/path-utils';
import * as endpoints from '../../utils/api/endpoints';
import { notEmptyPredicate } from '../../utils/helpers/check-condition-utils';
import { isPasswordValid } from '../../utils/password-utils';
import Recaptcher, { recaptchaKey } from '../../components/recaptcher/recaptcher';
import { RecaptcherWrapper } from '../../components/recaptcher/recaptcher-styled-components';
import { AuthOptionLinks, LICENSE_AUTH_LINK, LOGIN_AUTH_LINK } from './auth-option-links';
import { languageSelectorForRecaptcha } from '../../redux/modules/locale';

const SignUp = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [validationMode, setValidationMode] = useState(false);
    const [captchaMode, setCaptchaMode] = useState(false);

    const captchaRef = createRef();

    const { hl } = props;

    const submitEnabled = useMemo(
        () =>
            notEmptyPredicate(firstName) &&
            notEmptyPredicate(lastName) &&
            isEmailValid(email) &&
            isPasswordValid(password),
        [firstName, lastName, email, password]
    );

    const onConfirm = useCallback(
        e => {
            e.preventDefault();
            if (submitEnabled) {
                setCaptchaMode(true);
            } else {
                setValidationMode(true);
            }
        },
        /*eslint-disable*/ [submitEnabled] /*eslint-enable*/
    );

    const handleRecaptchaUpdate = () => {
        if (!!captchaRef && !!captchaRef.current) {
            captchaRef.current.reset();
        }
    };

    const handleCaptchaVerify = useCallback(
        recaptchaToken => {
            setLoading(true);
            setError(null);
            setRedirectToReferrer(false);

            request
                .post(endpoints.signUp, encodeSearchUrl({ firstName, lastName, email, password, recaptchaToken }))
                .then(() => {
                    setLoading(false);
                    setError(null);
                    handleRecaptchaUpdate();
                    setRedirectToReferrer(true);
                })
                .catch(e => {
                    handleRecaptchaUpdate();
                    setLoading(false);
                    setError(e);
                    setRedirectToReferrer(false);
                });
        },
        [firstName, lastName, email, password]
    );

    if (redirectToReferrer) {
        return <Redirect to={`/confirmation?login=${email}`} />;
    }

    return (
        <StyledContainer>
            <StyledForm>
                <StyledHeader>
                    <FormattedMessage id="signUp.title" />
                </StyledHeader>
                {captchaMode ? (
                    <RecaptcherWrapper>
                        <Recaptcher
                            ref={captchaRef}
                            recaptchaKey={recaptchaKey}
                            onVerify={handleCaptchaVerify}
                            hl={hl}
                            id={`Recaptcher-Sign-Up`}
                        />
                    </RecaptcherWrapper>
                ) : (
                    <>
                        <PropsFormatter
                            validationPredicate={validationMode && notEmptyPredicate}
                            placeholder={tr('signUp.firstName')}
                        >
                            <Input
                                type="text"
                                value={firstName}
                                onChange={input => setFirstName(input.target.value)}
                                unbounded
                            />
                        </PropsFormatter>
                        <PropsFormatter
                            validationPredicate={validationMode && notEmptyPredicate}
                            placeholder={tr('signUp.lastName')}
                        >
                            <Input
                                type="text"
                                value={lastName}
                                onChange={input => setLastName(input.target.value)}
                                unbounded
                            />
                        </PropsFormatter>
                        <PropsFormatter
                            validationPredicate={validationMode && isEmailValid}
                            placeholder={tr('signUp.email')}
                        >
                            <Input
                                type="text"
                                value={email}
                                onChange={input => setEmail(input.target.value)}
                                unbounded
                            />
                        </PropsFormatter>
                        <PropsFormatter
                            validationPredicate={validationMode && isPasswordValid}
                            placeholder={tr('signUp.password')}
                        >
                            <Input
                                type="password"
                                value={password}
                                onChange={input => setPassword(input.target.value)}
                                unbounded
                            />
                        </PropsFormatter>
                        <StyledButton
                            disabled={validationMode && !submitEnabled}
                            onClick={onConfirm}
                            isLoading={loading}
                        >
                            <FormattedMessage id="signUp.button" />
                        </StyledButton>
                    </>
                )}
                {error && (
                    <StyledError>
                        {error.response.status === 409 ? (
                            <FormattedMessage id="signUp.emailExists" />
                        ) : (
                            <FormattedMessage id="signUp.error" />
                        )}
                    </StyledError>
                )}
            </StyledForm>
            <AuthOptionLinks linksToShow={[LOGIN_AUTH_LINK, LICENSE_AUTH_LINK]} />
        </StyledContainer>
    );
};

const mapStateToProps = state => {
    const hl = languageSelectorForRecaptcha(state);

    return {
        hl
    };
};

export default connect(mapStateToProps)(SignUp);
