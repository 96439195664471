import { makeBundle, makePrefixAdder } from '../utils/type-utils';
import { getConfiguration, setConfiguration } from '../../utils/helpers/storages-utils';
import requestBuilder from '../utils/request-builder';
import * as endpoints from '../../utils/api/endpoints';
import omit from 'lodash/omit';
import createSortComparator from '../../utils/helpers/sort-utils';

const addPrefixTo = makePrefixAdder('CONFIGURATOR');
const CONFIGURE = addPrefixTo('CONFIGURE');
const SET_PRESET = addPrefixTo('SET_PRESET');

const initPresetsBundle = makeBundle(addPrefixTo('INIT_PRESETS'));
const savePresetBundle = makeBundle(addPrefixTo('SAVE_PRESET'));
const deletePresetBundle = makeBundle(addPrefixTo('DELETE_PRESET'));

export const buildInitPresets = configurationId => (staticPresets = {}) => {
    return requestBuilder(initPresetsBundle)
        .withOptions({ configurationId, staticPresets })
        .get(endpoints.gridPresets, { params: { tableId: configurationId } });
};

export const buildSavePreset = configurationId => (preset, columns) => {
    return requestBuilder(savePresetBundle)
        .withOptions({ configurationId })
        .post(endpoints.gridPresets, {
            preset,
            tableId: configurationId,
            columns
        });
};

export const buildDeletePreset = configurationId => preset => {
    return requestBuilder(deletePresetBundle)
        .withOptions({ preset, configurationId })
        .del(endpoints.gridPresets, { params: { tableId: configurationId, preset } });
};

export const buildSetColumnsAction = configurationId => configuration => {
    return { type: CONFIGURE, payload: { configurationId, configuration } };
};

export const buildSetPresetAction = configurationId => presetName => {
    return { type: SET_PRESET, payload: { configurationId, presetName } };
};

const defaultState = getConfiguration() || {};

const transformColumns = columns =>
    [...(columns ?? [])].sort(createSortComparator(c => c.position)).map(({ columnName }) => columnName);

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case initPresetsBundle.REQUEST:
        case savePresetBundle.REQUEST:
        case deletePresetBundle.REQUEST:
            return {
                ...state,
                [action.configurationId]: {
                    ...state[action.configurationId],
                    processing: true
                }
            };
        case initPresetsBundle.SUCCESS: {
            return {
                ...state,
                [action.configurationId]: {
                    ...state[action.configurationId],
                    processing: false,
                    presets: Object.assign(
                        {},
                        action.staticPresets,
                        ...action.response.data.map(({ preset, columns }) => ({ [preset]: transformColumns(columns) }))
                    )
                }
            };
        }
        case savePresetBundle.SUCCESS: {
            return {
                ...state,
                [action.configurationId]: {
                    ...state[action.configurationId],
                    processing: false,
                    presets: {
                        ...(state[action.configurationId] && state[action.configurationId].presets),
                        [action.response.data.preset]: transformColumns(action.response.data.columns)
                    }
                }
            };
        }
        case deletePresetBundle.SUCCESS: {
            return {
                ...state,
                [action.configurationId]: {
                    ...state[action.configurationId],
                    presetName: undefined,
                    processing: false,
                    presets: omit(state[action.configurationId].presets, action.preset)
                }
            };
        }
        case CONFIGURE: {
            const newState = {
                ...state,
                [action.payload.configurationId]: {
                    ...state[action.payload.configurationId],
                    columns: action.payload.configuration
                }
            };
            setConfiguration(newState);
            return newState;
        }
        case SET_PRESET: {
            const newState = {
                ...state,
                [action.payload.configurationId]: {
                    ...state[action.payload.configurationId],
                    presetName: action.payload.presetName
                }
            };
            setConfiguration(newState);
            return newState;
        }
        default:
            return state;
    }
}

export const columnsSelector = (state, configurationId) =>
    state.configurator[configurationId] && state.configurator[configurationId].columns;
export const presetsSelector = (state, configurationId) =>
    state.configurator[configurationId] && state.configurator[configurationId].presets;
export const presetNameSelector = (state, configurationId) =>
    state.configurator[configurationId] && state.configurator[configurationId].presetName;
export const presetsProcessingSelector = (state, configurationId) =>
    state.configurator[configurationId] && state.configurator[configurationId].processing;
